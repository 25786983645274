import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export class ApiService {

  ApiEndPoint: string;
  BaseUrl: string;
  constructor(baseUrl: string) {
    this.BaseUrl = baseUrl;
    this.ApiEndPoint = baseUrl + 'api/';
  }

  errorHandler(Er: HttpErrorResponse) {
    let message = "";
    if (Er.status !== 500 && Er.error) {
      if (Er.error.errors) {
        for (let er in Er.error.errors) {
          message += er + ": " + Er.error.errors[er].join("<br/>") + "</br>";
        }
      } else {
        message = Er.error;
      }
    }
    else {
      message = "An error occurred while processing your request, pls try again or contact support";
    }
    if (Er.status === 304) {
      return throwError(Er.status.toString());
    }
    if (Er.status === 303) {
      return throwError(Er.status.toString());
    } if (Er.status === 409) {
      return throwError(Er.status.toString());
    }
    return throwError(message || "An error occurred while processing your request, pls try again or contact support");
  }
}
