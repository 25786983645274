import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { SalesChannelBase } from '../models/SalesChannelBase';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class SaleschannelService extends ApiService {
	SalesChannel: SalesChannelBase[] = [];
	constructor(private http: HttpClient, @Inject("BASE_URL") base: string) {
		super(base);
		this.ApiEndPoint = base + "api/SalesChannel";
	}

	getSalesChannels(): Observable<Array<SalesChannelBase>> {
		return this.http.get<Array<SalesChannelBase>>(this.ApiEndPoint)
			.pipe(catchError(this.errorHandler));
	}

	getSalesChannelsByLocationID(): Observable<Array<SalesChannelBase>> {
		return this.http.get<Array<SalesChannelBase>>(this.ApiEndPoint)
			.pipe(catchError(this.errorHandler));
	}
}
