import { Component, ViewEncapsulation, HostListener, OnInit, OnDestroy, TemplateRef, ViewChild, Inject } from '@angular/core';
import { Subject, Observable, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { createOutput } from '@angular/compiler/src/core';
import Client from "../../helper/services/payment/client";
import Logger from "../../helper/services/payment/logger";
import { PaymentService } from "../../helper/services/payment/payment.service";
import { PaymentMode } from 'src/app/helper/models/SalesOrder';
import { LoaderService } from '../../helper/services/loader/loader.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-layout',
  templateUrl: './kiosk-layout.component.html',
  styleUrls: ['./kiosk-layout.component.css', '../../../assets/kiosk-pos/css/kiosk-pos.css'],
  encapsulation: ViewEncapsulation.None
})
export class KioskLayoutComponent implements OnInit, OnDestroy {
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  onEvents(e) {
    this.resetTimer();
    this._userActionOccured.next();
  }
  @ViewChild('timingModel', { static: false }) modalTemplate: TemplateRef<any>;
  timingModel: BsModalRef;
  config: ModalOptions = {
    animated: false,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'common-modal in modal-lg'
  };
  minutesDisplay = 0;
  secondsDisplay = 0;
  endTime = 5;
  ApiBaseUrl;
  apiurl: any;
  terminal: any;
  client: any;
  unsubscribe$: Subject<void> = new Subject();
  timerSubscription: Subscription;
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };

  constructor(@Inject('BASE_URL') baseUrl: string, private PaymentService: PaymentService, private modalService: BsModalService, public router: Router, public kioskPOSService: KioskPOSService, public loaderService: LoaderService,private es:ErrorMessagesService) {
    this.ApiBaseUrl = baseUrl + 'api/';
  }

  ngOnInit() {
    this.resetTimer();
    var url = this.ApiBaseUrl + 'SalesOrder/GetStripeConnection'
    this.apiurl = url;
    this.loadStripe(url);
    //adding class for printing
    //don't remove this
    $("body").addClass('kiosk');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.timerSubscription.unsubscribe();
  }

  resetTimer(endTime: number = this.endTime) {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(take(duration)).subscribe(value =>
      this.render((duration - +value) * interval),
      err => {

      }, () => {
        /*5 Minutes of inactivity Redirect To Home Screen*/
        this.timingModel.hide();
        this.kioskPOSService.unsetFinalData(['MovieId', 'PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems']);
        this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
        /*5 Minutes of inactivity Redirect To Home Screen*/
      })
  }

  private render(count) {
    if (count == 60000 && this.router.url.indexOf('/kiosk/home/') == -1) {
      this.timingModel = this.modalService.show(this.modalTemplate, { class: this.config.class + ' select-time-model' });
    }
    this.secondsDisplay = this.getSeconds(count);
    this.minutesDisplay = this.getMinutes(count);
  }

  private getSeconds(ticks: number) {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(seconds);
  }

  private getMinutes(ticks: number) {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  closeModal(template: TemplateRef<any>, isExtend: string = 'n') {
    if (isExtend == 'y') {
      this.resetTimer();
      this._userActionOccured.next();
    }
    this.timingModel.hide();
  }
  loadStripe(url) {
    this.client = new Client(url);
    this.terminal = (<any>window).StripeTerminal.create({
      onFetchConnectionToken: async () => {
        let connectionTokenResult = await this.client.createConnectionToken();
        return connectionTokenResult.secret;
      },
      onUnexpectedReaderDisconnect: async () => {
        let onUnexpectedReaderDisconnect = await this.client.onUnexpectedReaderDisconnect();
        return onUnexpectedReaderDisconnect;
      }
    });
    Logger.watchObject(this.client, "backend", {
      createConnectionToken: {
        docsUrl: this.apiurl
      },
      onUnexpectedReaderDisconnect: {
        docsUrl: this.apiurl
      },
      // createPaymentIntent: {
      //   docsUrl: this.apiurl
      // },
    });
    this.connectToSimulator();
    this.discoverReaders();
  }
  setState = async (attr) => {
    return attr;
  };
  discoverReaders = async () => {
    this.setState({
      discoveryWasCancelled: false
    });
    const discoverResult = await this.terminal.discoverReaders();
    this.kioskPOSService.isSwipeReaderExist = discoverResult.discoveredReaders.length > 0 ? true : false;
    console.log("discoverReaders", discoverResult);
    if (discoverResult.error) {
      console.log("Failed to discover: ", discoverResult.error);
      return discoverResult.error;
    } else {
      this.setState({
        discoveredReaders: discoverResult.discoveredReaders
      });
      return discoverResult.discoveredReaders;
    }
  };
  connectToSimulator = async () => {
    const simulatedResult = await this.terminal.discoverReaders({
      simulated: true
    });
    // console.log("simulatedResult",simulatedResult);
    await this.connectToReader(simulatedResult.discoveredReaders[0]);
  };

  connectToReader = async selectedReader => {
    // 2b. Connect to a discovered reader.
    const connectResult = await this.terminal.connectReader(selectedReader);
    if (connectResult.error) {
      console.log("Failed to connect:", connectResult.error);
    } else {
      this.setState({
        status: "workflows",
        discoveredReaders: [],
        reader: connectResult.reader
      });
      return connectResult;
    }
  };

  disconnectReader = async () => {
    // 2c. Disconnect from the reader, in case the user wants to switch readers.
    await this.terminal.disconnectReader();
    this.setState({
      reader: null
    });
  };
  CollectPaymentMethod = async (Orderresponse) => {
    this.loaderService.show();
    if (Orderresponse.CreditCardinfo) {
      var processPaymentResult, paymentIntentId;
      let collectPaymentMethodResult = await this.terminal.collectPaymentMethod(Orderresponse.CreditCardinfo);
      console.log("collectPaymentMethodResult", collectPaymentMethodResult);
      processPaymentResult = await this.terminal.processPayment(collectPaymentMethodResult.paymentIntent).then(result => {
        console.log("processPaymentResultresult", result);
        paymentIntentId = result && result.paymentIntent ? result.paymentIntent.id : '';
      }, error => {
        console.log("processPaymentResulterror", error);
      });
      console.log("paymentIntentId", paymentIntentId);
      // if(paymentIntentId){
      var paymentFlag = paymentIntentId ? true : false;
      paymentIntentId = paymentIntentId ? paymentIntentId : null;

      if (Orderresponse.CardNumber && Orderresponse.CardNumber.toString().length == 16)
        var createPaymentIntent = await this.PaymentService.passPaymentIntentIdGiftcard(Orderresponse.ConfirmationCode, paymentIntentId, paymentFlag).toPromise();
      else
        var createPaymentIntent = await this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode, paymentIntentId, paymentFlag, PaymentMode.CC).toPromise();
      // }
      console.log("createPaymentIntent", createPaymentIntent);
      return paymentFlag;
    }
    this.loaderService.hide();
  };
  registerAndConnectNewReader = async (label, registrationCode) => {
    try {
      let reader = await this.client.registerDevice({
        label,
        registrationCode
      });
      // After registering a new reader, we can connect immediately using the reader object returned from the server.
      await this.connectToReader(reader);
      console.log("Registered and Connected Successfully!");
    } catch (e) {
    }
  };
}
