import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { CommonService } from 'src/app/helper/services/common.service';
import * as moment from 'moment';
import { LoaderService } from 'src/app/helper/services/loader/loader.service';
import Swal from 'sweetalert2';
import { MerchantService } from 'src/app/merchants/merchant.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-home',
  templateUrl: './kiosk-home.component.html',
  styleUrls: ['./kiosk-home.component.css']
})
export class KioskHomeComponent implements OnInit {
  private subscribed: any = [];

  constructor(private merchantService: MerchantService, private CommonService: CommonService, private loaderServ: LoaderService, public kioskPOSService: KioskPOSService, public router: Router,private es:ErrorMessagesService) {
    this.kioskPOSService.clearPreviousData();
    delete sessionStorage.LocationMovieDate;
    this.kioskPOSService.locationId = this.kioskPOSService.getLocationId();
  }

  async ngOnInit() {
    this.kioskPOSService.currentMerchantDetails = await this.merchantService
      .getCompanyMerchant()
      .toPromise();
    this.kioskPOSService.selectedMerchant = this.kioskPOSService.currentMerchantDetails.MerchantName;
  }

  public getMovieData() {
    this.kioskPOSService.unsetFinalData(['PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems']);
    this.CommonService.GetDateTimeByTimeZone().subscribe(date => {
      let Dates = this.kioskPOSService.checkLocationMoviDate(date);
      let getMovieData = this.kioskPOSService.SubmitGetFormData('Kiosk/' + Dates.startDateTime + '/' + Dates.endDateTime).subscribe((response) => {
        this.kioskPOSService.movieData = response;
      }, (error) => {
        console.log(error);
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
      });
      this.subscribed.push(getMovieData);
    });
  }

  ngOnDestroy() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  openMovieList() {
    //this.loaderServ.show();
    this.router.navigate(['/kiosk/movie-list/', this.kioskPOSService.locationId]);
    //this.CommonService.GetDateTimeByTimeZone().subscribe(tz => {

    //      this.kioskPOSService.setLocationMovieDate(moment(tz).format("YYYY-MM-DDTHH:mm:ss.000[Z]"));

    //      delete sessionStorage.LocationMovieDate;

    //       this.kioskPOSService.getLocationMoviesData(true).then(res=>{
    //          this.kioskPOSService.movieData = res;
    //          this.loaderServ.hide();
    //          this.router.navigate(['/kiosk/movie-list/', this.kioskPOSService.locationId]);
    //      }).catch(res => {
    //          this.loaderServ.hide();
    //          this.kioskPOSService.showSnackBar('Some error occured!!.', 'close');
    //      });
    //  },err=> Swal.fire('Failed',err,'error'));

    //this.loaderServ.show();
    //this.CommonService.GetDateTimeByTimeZone().subscribe(tz => {

    //  this.kioskPOSService.setLocationMovieDate(moment(tz).format("YYYY-MM-DDTHH:mm:ss.000[Z]"));

    //  delete sessionStorage.LocationMovieDate;

    //  this.kioskPOSService.getLocationMoviesData(true).then(res => {
    //    this.kioskPOSService.movieData = res;
    //    this.loaderServ.hide();
    //    this.router.navigate(['/kiosk/movie-list/', this.kioskPOSService.locationId]);
    //  }).catch(res => {
    //    this.loaderServ.hide();
    //    this.kioskPOSService.showSnackBar('Some error occured!!.', 'close');
    //  });
    //}, err => Swal.fire('Failed', err, 'error'));
  }
}
