import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { setTheme } from "ngx-bootstrap/utils";
import { GiftCardSales } from '../giftcard/giftcardsales';
import { GiftcardService } from '../giftcard/giftcard.service';
import { PrintService, Receipt, MovieTicket } from '../helper/services/print.service';
import * as moment from 'moment';
import { SalesOrder, SalesOrderConcession, SalesOrderTicket, SalesStatus, PaymentMode, SalesAdditionalCharges, SalesOrderGiftCard, SalesSplit, WorldpayTransactionDetails, DiscountType } from '../helper/models/SalesOrder';
import { CloneObject, RoundAmount } from '../helper/models/AddHelperModal';
import { CommonService } from '../helper/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KioskPOSService } from '../kiosk-pos/services/kiosk-pos.service';
import Swal from 'sweetalert2';
import { AdditionalCharges } from '../price-card/price-card';
import { SalesChannelBase } from '../helper/models/SalesChannelBase';
import { SalesChannel } from '../saleschannel/saleschannel';
import { ToastrService } from 'ngx-toastr';
//import { Location } from '@angular/common';
import { DineinService } from "src/app/DineIn/dinein.service";
import { PaymentoptionService } from '../paymentoption/paymentoption.service';
import { GiftCard } from '../giftcard/giftcard';
import { ScreenService } from "../screens/screen.service";
import { Screen, Seat } from "../screens/screen";
import { Location } from '../locations/location';
import { LocationsService } from '../locations/locations.service';
import { POSService } from '../pos/pos.service';
import { PlanorderService } from '../helper/services/planorder.service';
import { ParkingLotOrder } from '../helper/models/ParkingLotOrder';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css'],
  //encapsulation: ViewEncapsulation.Emulated
})

export class PrintComponent implements OnInit {
  LocName = JSON.parse(sessionStorage.location).Location_Name;
  logourl = JSON.parse(sessionStorage.location).LogoImage;
  LocAddress1 = JSON.parse(sessionStorage.location).Address;
  LocAddress2 = JSON.parse(sessionStorage.location).City + ', ' + JSON.parse(sessionStorage.location).State + '  ' + JSON.parse(sessionStorage.location).Zipcode;
  locPhone = 'Phone: ' + JSON.parse(sessionStorage.location).PhoneNumber;
  Sale: GiftCardSales = null;
  qrData: any;
  qrLoc: any;
  screenqrData: any;
  seatqrData: any;
  selectedSeatQrData: any
  selectedScreenQrData: any;
  qrLocation: any;
  qrordering:any;
  qrTable:any;
  locationDetail = JSON.parse(sessionStorage.location);

  SalesOrderData: Array<SalesOrder> = null;
  receipts: Array<Receipt> = new Array<Receipt>();
  moment = moment;
  Number = Number;

  SalesOrder: SalesOrder = null;
  SalesStatus = SalesStatus;
  paymentMode: string;
  receiptTicket: Receipt = {
    Tickets: new Array<MovieTicket>(), Concessions: Array<SalesOrderConcession>(), AdditionalCharges: Array<SalesAdditionalCharges>(),
    GiftCardSale: new Array<SalesOrderGiftCard>(), PaymentMode: 0, CCLastFour: "", MerchantName: "",
    WorldpayTransactionDetails: new WorldpayTransactionDetails(), SalesSplit: Array<SalesSplit>(),
    UniqueOrderNumber:null
  };
  finalAmount: number = 0;
  locationDate: Date;
  ccFinalAmount: number = 0;
  cashFinalAmount: number = 0;
  gcFinalAmount: number = 0;
  gcBalance: number = 0;
  gcNumber: string = "";
  gcDetails: Map<string, number> = new Map<string, number>();
  kitchenSalesOrder: any = {};
  kitchenSalesOrderLoaded: Promise<boolean>;
  vendorCopyFlag: boolean = false;
  // Need to show all addon item price to print
  addonConcesstions: any = [];
  qrcodePrint: any = null;
  unPaidSalesOrder: any = null;
  showDebitRefundDetails = true;
  authPaidSalesOrder: boolean = false;
  gcBalanceObject: GiftCard = null;
  authPaymentMode: PaymentMode = null;
  authGiftCardNumber: any;
  authGiftCardBalance: any;
  screenid: Screen = null;
  SeatNum: Seat = null;
  Seatno: any;
  printScreenid: Screen['Screen_ID'] = null;
  printAllScreenIds: Screen['Screen_ID'][] = [];
  printSeatid: Seat['SeatNumber'] = null;
  printScreenname: Screen['Screen_Name'] = null;
  printAllScreen: Screen[] = [];
  printAllSeats: Seat['SeatNumber'][] = [];
  printSeatScreenName: Screen['Screen_Name'] = null;
  printAllScreendiv: Screen[] = null;
  printAllSeatdiv: Seat['SeatNumber'][] = null;
  sreenItemsList = [];
  seatItemsList = [];
  imageList = [];
  printAllScreenDiv: boolean = false;
  printAllSeatDiv: boolean = false;
  printloc: boolean = false;
  printqr:boolean=false;
  printlocation: Location['Location_ID'] = null;
  printlocationdiv: Location['Location_ID'] = null;
  tableItemsList = [];
  printAllTablediv:any = null;
  printHallName:any=null;
  displayTableNumber: any = null;
  amountdueorpay: boolean = false;
  duplicateFlag: boolean = false;
  loyalityrefund: number = 0;
  ParkingOrderData: Array<ParkingLotOrder> = null;
  parkingreciept: any[] = [];
  ParkingLotSalesOrder: any = null;
  ParkqrData: any;
  constructor(public posService: POSService,private router: Router, public dineinService: DineinService, private toastService: ToastrService, private location: LocationsService, private GCS: GiftcardService,
    public printService: PrintService, private CS: CommonService, private activeRot: ActivatedRoute, private pos: KioskPOSService, private scService: ScreenService,
    public paymentOptionService: PaymentoptionService,public planordservice:PlanorderService) {
    setTheme("bs3");
    //this.getGCBalance();
    setTimeout(async () => { await this.printKitchen(); }, 0);
    setTimeout(async () => { await this.printUnPaidSalesOrder(); }, 0);
    // let PrintUrlFLag = this.router.url.split('(').length > 1;
    // if(PrintUrlFLag){
    setTimeout(async () => { await this.GCPrint(); }, 0);
    setTimeout(async () => { await this.printRefundTicket(); }, 0);
    setTimeout(async () => { await this.printTicket(); }, 0);
    // setTimeout(async () => { await this.printQrCode(); }, 0);
    setTimeout(async () => { await this.printLocQrCode(); }, 0);
    setTimeout(async () => { await this.printQrorderCode(); }, 0);

    setTimeout(async () => { await this.printQrCodeforScreen(this.printScreenid); }, 0);
    setTimeout(async () => { await this.printAllCheckedforScreen(); }, 0);
    setTimeout(async () => { await this.printQrCodeforSeat(this.printSeatid); }, 0);
    setTimeout(async () => { await this.printAllCheckedforSeat(); }, 0);

    setTimeout(async () => { await this.printQrCodeforTable(); }, 0);
    setTimeout(async () => { await this.printAllCheckedforTable(); }, 0);

    setTimeout(async () => { await this.printParkingLot(); }, 0);

    setTimeout(async () => { await this.printRefundParkingLot(); }, 0);

    // }
  }

  async GCPrint() {
    if (this.printService.printGCS) {
      this.printService.printModeEnabled = true;
      this.Sale = this.printService.printGCS;
      this.cashFinalAmount = 0;
      this.ccFinalAmount = 0;
      if (this.Sale.SalesSplit && this.Sale.SalesSplit.length > 0) {
        this.Sale.SalesSplit?.forEach(f => {
          if (f.PaymentMode == PaymentMode.Cash) {
            this.cashFinalAmount += f.TotalAmount;
          }

          if (f.PaymentMode == PaymentMode.CC || f.PaymentMode == PaymentMode.ManualCC) {
            this.ccFinalAmount += f.TotalAmount;
          }
        });
      }
      this.SalesOrderData = null;
      this.SalesOrder = null;
      this.GCS.getQr(this.Sale.TransactionId).subscribe(data => {
        this.qrData = 'data:image/png;base64,' + data.Image;
        this.printService.printModeEnabled = false;
        this.printService.onDataReady();
      });
      this.printService.printModeEnabled = false;
    }
  }

  async printRefundTicket() {
    this.CS.GetDateTimeByTimeZone().subscribe(async (result) => {
      this.locationDate = result;
      this.printService.printModeEnabled = true;
      if (this.printService.RefundSO.length != 0) {
        this.Sale = null;
        this.SalesOrder = null;
        this.SalesOrderData = this.printService.RefundSO;
        this.SalesOrderData.forEach(SO => {

          this.pos.getSalesOrder(SO.ConfirmationCode).subscribe(async res => {
            if (res) {
              this.SalesOrder = res;
              if (this.SalesOrder.PaymentMode == PaymentMode.GiftCard) {
                this.gcNumber = this.SalesOrder.GiftCardNumber;
                var gc = await this.GCS.getGiftCardbyCardNumber(this.SalesOrder.GiftCardNumber);
                this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
              }
              else if (this.SalesOrder.PaymentMode == PaymentMode.Split) {
                this.SalesOrder.SalesSplit.forEach(async sp => {
                  if (sp.PaymentMode == PaymentMode.GiftCard) {
                    var gc = await this.GCS.getGiftCardbyCardNumber(sp.GiftCardNumber);
                    this.gcDetails.set(sp.GiftCardNumber, Math.round(gc.TotalBalance * 100) / 100);
                  }
                })
              }
            }
          });

          this.CS.GetQr(SO.ConfirmationCode).subscribe(data => {
            let receipt: Receipt = {
              Tickets: new Array<MovieTicket>(), Concessions: Array<SalesOrderConcession>(), AdditionalCharges: Array<SalesAdditionalCharges>(),
              finalAmount: 0, GiftCardSale: Array<SalesOrderGiftCard>(), PaymentMode: 0, CCLastFour: "", MerchantName: "",
              WorldpayTransactionDetails: new WorldpayTransactionDetails(), SalesSplit: Array<SalesSplit>(), UniqueOrderNumber: null
            };
            receipt.Concessions = SO.Concession;
            receipt.PaymentMode = SO.PaymentMode;
            receipt.CCLastFour = SO.CCLastFour;
            receipt.MerchantName = SO.MerchantName;
            receipt.WorldpayTransactionDetails = SO.WorldpayTransactionDetails;
            receipt.SalesSplit = SO.SalesSplit;
            receipt.TotalRefund = SO.TotalRefund;
            receipt.ActualAmount = SO.ActualAmount + SO.ActualTax;

            // Removed concession price from combo ticket
            //if (SO.Tickets.find(type => type.ComboID != "")) {
            //  receipt.Concessions = receipt.Concessions.map(c => {
            //    if (c.ComboID) {
            //      c.Price = 0,
            //        c.Tax = 0
            //      return c;
            //    } else {
            //      return c;
            //    }
            //  });
            //}
            SO.Tickets = SO.Tickets.filter(t => (t.Status != SalesStatus.Void && t.Status != SalesStatus.Failed));
            SO.AdditionalCharges = SO.AdditionalCharges.filter(x => x.Index == 11);
            if (SO.AdditionalCharges && SO.AdditionalCharges?.length > 0) {
              SO.AdditionalCharges.forEach(x => {
                //let ac = new SalesAdditionalCharges();
                //ac.Index = x.Index;
                //ac.AdditionalPrice = x.AdditionalPrice;
                //ac.NumberOfTicketSold = x.NumberOfTicketSold;
                //ac.Name = x.Name;
                //ac.Tax = x.Tax;
                //ac.Status = x.Status;
                //ac.SalesChannel = x.SalesChannel;
                receipt.AdditionalCharges.push(x);
                let totalAmount = this.getTotalAmount(x.NumberOfTicketSold, x.AdditionalPrice + x.Tax);
                receipt.finalAmount = (receipt.finalAmount + totalAmount);
              });
            }

            SO.Tickets?.forEach((f :any)=> {
              let tc = receipt.Tickets.find(s => s.TicketType.TicketType === f.TicketType);
              if (!tc) {
                tc = {
                  TicketType: new SalesOrderTicket(),
                  Amount: 0,
                  Tax: 0,
                  Date: '',
                  Seats: [],
                };
                tc.TicketType = f;
                tc.TicketType.TicketType = f.TicketType;
                tc.TicketType.Price = f.Price;
                tc.TicketType.Tax = f.Tax;
                tc.Date = moment(f.Showdate, "DD/MM/YYYY").format("ddd MMM DD") + " " + f.Showtime;
                receipt.Tickets.push(tc);
              }
              tc.Seats.push(f.SeatNumber?.toString());
              tc.Amount += f.ActualAmount != 0 ? f.ActualAmount : f.Price;
              tc.Tax += f.ActualTax != 0 ? f.ActualTax : f.Tax;
              receipt.finalAmount = receipt.finalAmount + (RoundAmount(f.Price) + f.Tax);
             /* if (f.ComboID && f.concecssionDetail && f.concecssionDetail.length > 0) {
                var condetails = CloneObject(f.concecssionDetail);
                condetails.forEach((y: any) => {
                  y.Price += RoundAmount(y.Price - y.Tax);
                  y.NumberSold = 1;
                  // x.Tax = x.Tax += RoundAmount(y.Tax);
                  receipt.Concessions.push(y);

                })
              } */
            });

            // Add-on functionality implementated for dine-in
            var hasAddonConcessionItems = receipt.Concessions?.filter(x => x.AddonConcessionName != null).length > 0;
            var addonConcessionItems = [];
            this.addonConcesstions = [];

            receipt.Concessions?.forEach(f => {
              if (!hasAddonConcessionItems) {
                var cTTax = f.Tax * f.NumberSold;
                receipt.finalAmount = receipt.finalAmount + ((RoundAmount(f.Price) * f.NumberSold) + cTTax);
              }
              else if (f.AddonConcessionName == null) {
                var ctaxAdd = f.Tax * f.NumberSold;
                receipt.finalAmount += ((RoundAmount((f.Price == 0 ? f.ActualAmount : f.Price) + (f.Tax == 0 ? f.ActualTax : ctaxAdd))));

                var addonConcession = receipt.Concessions.filter(x => x.AddonConcessionName != null &&
                  x.AddonConcessionName.indexOf(f.ConcessionName.split('\n')[0]) !== -1 &&
                  (addonConcessionItems.filter(a => a == x.AddonConcessionName).length == 0));

                // Need to check if we have two concession and diffrent addon items
                var addonItems = [];
                if (addonConcession.length > 0) {
                  addonConcessionItems.push(addonConcession[0].AddonConcessionName);
                  addonItems = receipt.Concessions.filter(x => x.AddonConcessionName == addonConcession[0].AddonConcessionName);

                  for (let item in addonItems) {
                    receipt.finalAmount = RoundAmount(receipt.finalAmount + addonItems[item].Price + addonItems[item].Tax);
                  }

                  f.ConcessionName += " \n" + addonConcession[0].AddonConcessionName;

                  // Add all addon items to show price on print
                  if (addonItems.length > 0)
                    this.addonConcesstions.push({ ConcessionNames: f.ConcessionName, AddonItems: addonItems });
                }
              }

            });

            receipt.CfmCode = SO.ConfirmationCode;
            this.cashFinalAmount = 0;
            this.ccFinalAmount = 0;
            this.gcFinalAmount = 0;
            if (SO.SalesSplit && SO.SalesSplit.length > 0) {
              SO.SalesSplit?.forEach(f => {
                if (f.PaymentMode == PaymentMode.Cash) {
                  this.cashFinalAmount += f.TotalAmount;
                }
                if (f.PaymentMode == PaymentMode.GiftCard) {
                  this.gcFinalAmount += f.TotalAmount
                }
                if (f.PaymentMode == PaymentMode.CC || f.PaymentMode == PaymentMode.ManualCC) {
                  this.ccFinalAmount += f.TotalAmount;
                }
              });
            }
            if (SO.DiscountType && SO.DiscountType.length > 0) {
              var loyaltyDiscount = SO.DiscountType.filter(k => k == 2);

              if (loyaltyDiscount && loyaltyDiscount.length > 0) {
                var ttlActual = 0;
                var reedimLP = 0;
                var realPrice = 0;


                if (SO.Tickets && SO.Tickets.length) {
                  SO.Tickets.forEach((x: any) => {
                    if (x.ComboID && x.concecssionDetail && x.concecssionDetail.length > 0) {
                      var condetails = CloneObject(x.concecssionDetail);
                      var tickActualAmount = x.ActualAmount;
                      var ticketATax = x.ActualTax;
                      condetails.forEach((y: any) => {
                          x.Price = x.Price += RoundAmount(y.Price);
                         // x.Tax = x.Tax += RoundAmount(y.Tax);
                        tickActualAmount = tickActualAmount + RoundAmount(y.ActualAmount);
                        ticketATax = ticketATax + RoundAmount(y.ActualTax);
                       
                        })
                      
                      reedimLP += (RoundAmount(tickActualAmount) + RoundAmount(ticketATax)) - (RoundAmount(x.Price) + RoundAmount(x.Tax));
                      realPrice += RoundAmount(x.Price) + RoundAmount(x.Tax);
                      ttlActual += RoundAmount(tickActualAmount) + RoundAmount(ticketATax)

                    } else {
                      reedimLP += (x.ActualAmount + x.ActualTax) - (x.Price + x.Tax);
                      realPrice += (x.Price + x.Tax);
                      ttlActual += (x.ActualAmount + x.ActualTax)
                    }

                  });

                }
                if (SO.Concession && SO.Concession.length) {
                  SO.Concession.forEach((x) => {
                    if (!x.ComboID) {
                      reedimLP += ((x.ActualAmount + x.ActualTax) - (x.Price + x.Tax)) * x.NumberSold;
                      realPrice += (x.Price + x.Tax) * x.NumberSold;
                      ttlActual += (x.ActualAmount + x.ActualTax) * x.NumberSold
                    }
                  });
                }
                if (SO.AdditionalCharges && SO.AdditionalCharges.length) {
                  var realPriceA = 0;
                  var ttlActualA = 0;
                  var reedmpA = 0;
                  SO.AdditionalCharges.forEach((x) => {
                    reedmpA += ((x.ActualAmount + x.ActualTax) - (x.AdditionalPrice + x.Tax)) * x.NumberOfTicketSold;
                    realPriceA += (x.AdditionalPrice + x.Tax) * x.NumberOfTicketSold;
                    ttlActualA += (x.ActualAmount + x.ActualTax) * x.NumberOfTicketSold;
                  });
                  reedimLP += RoundAmount(reedmpA);
                  realPrice += RoundAmount(realPriceA);
                  ttlActual += RoundAmount(ttlActualA);
                }
                receipt.finalAmount = ttlActual;
                receipt.RefundLoyaltyPoints = reedimLP;
                receipt.RefundActualPrice = realPrice;



                /*     receipt.RefundLoyaltyPoints=(receipt.finalAmount+ SO.TotalRefund)- (  SO.TotalAmount+SO.TotalTax);
                    receipt.RefundActualPrice=receipt.finalAmount- receipt.RefundLoyaltyPoints;
                     if( receipt.RefundActualPrice<=0){
     
                       if ((receipt.finalAmount + SO.TotalRefund) >= receipt.ActualAmount) {
                         receipt.RefundActualPrice = 0;
                         receipt.RefundLoyaltyPoints = receipt.finalAmount;
                       } else if (receipt.RefundActualPrice == 0) {
                         receipt.RefundActualPrice = 0;
                         receipt.RefundLoyaltyPoints = receipt.finalAmount;
                       } else if (SO.TotalAmount + SO.TotalTax == 0 && receipt.RefundActualPrice <= 0) {
                         receipt.RefundActualPrice = 0;
                         receipt.RefundLoyaltyPoints = receipt.finalAmount;
                       } else {
                         
                           receipt.RefundActualPrice = receipt.finalAmount;
     
                         
                       }
                      
                     }else if((  SO.TotalAmount+SO.TotalTax)==0){
                       receipt.RefundActualPrice=0;
                       receipt.RefundLoyaltyPoints=receipt.finalAmount;
                     } else if(Number((receipt.finalAmount+ SO.TotalRefund).toFixed(2))==Number((SO.TotalAmount+SO.TotalTax).toFixed(2))){
                       if((Number((receipt.finalAmount+SO.TotalRefund).toFixed(2)))==( Number( (SO.TotalAmount+SO.TotalTax).toFixed(2)))){
                         receipt.RefundLoyaltyPoints=0;
                         receipt.finalAmount=receipt.finalAmount;
                       }else
                       if(Number((receipt.finalAmount+ SO.TotalRefund).toFixed(2))<( Number(( SO.ActualAmount+SO.ActualTax).toFixed(2)))){
                         receipt.RefundLoyaltyPoints=(SO.ActualAmount+SO.ActualTax)-(receipt.finalAmount+ SO.TotalRefund);
                         receipt.finalAmount=(SO.ActualAmount+SO.ActualTax)
                       }
     
                     }else
                     if(receipt.RefundLoyaltyPoints<0){
                       receipt.RefundActualPrice+=receipt.RefundLoyaltyPoints;
                     }
                   
                   }else{
                     receipt.RefundActualPrice = 0;
                     receipt.RefundLoyaltyPoints = 0;
                   } */
              }
            }
            else {
              receipt.RefundActualPrice = 0;
              receipt.RefundLoyaltyPoints = 0;
            }
            this.qrData = 'data:image/png;base64,' + data.Image;
            this.receipts.push(receipt);
            setTimeout(async () => this.printService.printModeEnabled = false, 2000);
          }, err => {
            this.printService.printModeEnabled = false;
          });
        });
        // this.printService.onDataReady();
        this.vendorCopyFlag = false;
        // console.log("FLAG1",this.vendorCopyFlag);
        await this.printService.onDataReadyWithoutClear();
        setTimeout(async () => {
          this.vendorCopyFlag = true;
          // console.log("FLAG2",this.vendorCopyFlag);
          await this.printService.onDataReady();
        }, 2500);
      }
      this.printService.RefundSO.splice(0, this.printService.RefundSO.length);
    });
  }

  async printTicket() {
    this.printService.printModeEnabled = true;
    // && this.activeRot.snapshot.children[0].url[0].path == 'ticket'
    if (!this.printService.isparkingPrinting) {
      if (this.activeRot.snapshot.children[0].params.id) {
        this.pos.getSalesOrderwithCategory(this.activeRot.snapshot.children[0].params.id).subscribe(async res => {
          if (res) {
            res.Concession = await res.ConcessionVm.sort((a, b) => {
              const titleA = a.ConcessionSubCategory.toLowerCase();
              const titleB = b.ConcessionSubCategory.toLowerCase();

              if (titleA < titleB) {
                return -1;
              }
              if (titleA > titleB) {
                return 1;
              }
              return 0;
            });
            this.Sale = null;
            this.SalesOrderData = null;
            this.SalesOrder = res;
            console.log(res);
            this.SalesOrder.Tickets.forEach(Ticket => {
              if (Ticket.SeatNumber != null) {
                let a = Ticket.SeatNumber.replace(/[0-9]/g, "");
                let b = Ticket.SeatNumber.replace(/[A-Z]|[a-z]/g, "");
                Ticket.SeatNumber = a + "-" + b;
              }
            })

            this.pos.getQr(this.SalesOrder.ConfirmationCode).subscribe(async (data) => {
              this.qrData = 'data:image/png;base64,' + data.Image;

              if (this.SalesOrder.PaymentMode == PaymentMode.GiftCard) {
                this.gcNumber = this.SalesOrder.GiftCardNumber;
                var gc = await this.GCS.getGiftCardbyCardNumber(this.SalesOrder.GiftCardNumber);
                this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
              }
              else if (this.SalesOrder.PaymentMode == PaymentMode.Split) {
                this.SalesOrder.SalesSplit.forEach(async sp => {
                  if (sp.PaymentMode == PaymentMode.GiftCard) {
                    var gc = await this.GCS.getGiftCardbyCardNumber(sp.GiftCardNumber);
                    this.gcDetails.set(sp.GiftCardNumber, Math.round(gc.TotalBalance * 100) / 100);
                  }
                })
              }

              res.Tickets = res.Tickets.filter(t => (t.Status != SalesStatus.Void && t.Status != SalesStatus.Failed));
              res.AdditionalCharges = res.AdditionalCharges.filter(x => x.Index == 11);
              // res.Tickets = res.Tickets.filter(t => (t.Status == SalesStatus.Reserved));
              if (res.AdditionalCharges && res.AdditionalCharges?.length > 0) {
                res.AdditionalCharges.forEach(x => {
                  //let ac = new SalesAdditionalCharges();
                  //ac.Index = x.Index;
                  //ac.AdditionalPrice = x.AdditionalPrice;
                  //ac.NumberOfTicketSold = x.NumberOfTicketSold ? x.NumberOfTicketSold : res.Tickets.length;
                  //ac.Name = x.Name;
                  //ac.Tax = x.Tax;
                  //ac.Status = x.Status;
                  //ac.SalesChannel = x.SalesChannel;
                  this.receiptTicket.AdditionalCharges.push(x);
                  let totalvlue = (x.ActualAmount == 0 ? x.ActualAmount : x.AdditionalPrice) + (x.Tax == 0 ? x.ActualTax : x.Tax);
                  let totalAmount = this.getTotalAmount(x.NumberOfTicketSold, totalvlue);
                  this.finalAmount = (this.finalAmount + totalAmount);
                });
              }
              //var temp = [];
              //res.Concession.forEach((o: any) => {
              //  var ci = temp?.find((c) => c.ConcessionName == o.ConcessionName && o.Price == c.Price);
              //  if (!ci) {
              //    temp.push(o);
              //  } else {
              //    var inde = temp.indexOf(ci);
              //    if (inde !== -1) {
              //      temp[inde].NumberSold += 1;
              //    }
              //  }

              //})
              this.receiptTicket.Concessions = res.Concession;
              this.receiptTicket.UniqueOrderNumber = res.UniqueOrderNumber;
              this.receiptTicket.Concessions = this.receiptTicket.Concessions.filter(c => (c.NumberSold != 0));
              res.Tickets?.forEach(f => {
                if (f.Status === SalesStatus.Void || f.Status === SalesStatus.Failed)
                  return;
                let tc = this.receiptTicket.Tickets.find(s => s.TicketType.TicketType === f.TicketType && s.TicketType.ComboID == f.ComboID);

                if (!tc) {
                  tc = {
                    TicketType: new SalesOrderTicket(),
                    Amount: 0,
                    Tax: 0,
                    Date: '',
                    Seats: []
                  };
                  tc.TicketType = f;
                  tc.Amount = 0;
                  tc.TicketType.Price = f.Price == 0 ? f.ActualAmount : f.Price;
                  tc.TicketType.Tax = f.Tax == 0 ? f.ActualTax : f.Tax;
                  tc.Date = moment(f.Showdate, "DD/MM/YYYY").format("ddd MMM DD") + " " + f.Showtime;
                  this.receiptTicket.Tickets.push(tc);
                }
                tc.Seats.push(f.SeatNumber?.toString());
                tc.Amount += f.ActualAmount != 0 ? f.ActualAmount : f.Price;
                tc.Tax += f.ActualTax != 0 ? f.ActualTax : f.Tax;
                this.finalAmount = (this.finalAmount + ((f.Price == 0 ? f.ActualAmount : f.Price) + (f.Tax == 0 ? f.ActualTax : f.Tax)));
              });

              // Add-on functionality implementated for dine-in
              var hasAddonConcessionItems = this.receiptTicket.Concessions?.filter(x => x.AddonConcessionName != null).length > 0;
              var addonConcessionItems = [];
              this.addonConcesstions = [];

              this.receiptTicket.Concessions?.forEach(f => {
                if (!hasAddonConcessionItems) {
                  //f.Tax = (f.Tax==0?f.ActualTax:f.Tax) * f.NumberSold;
                  this.finalAmount = RoundAmount(this.finalAmount + ((f.Price == 0 ? f.ActualAmount : f.Price) * f.NumberSold) + (f.Tax));
                }
                else if (f.AddonConcessionName == null) {
                  //f.Tax = (f.Tax==0?f.ActualTax:f.Tax) * f.NumberSold;
                  this.finalAmount = RoundAmount(this.finalAmount + ((f.Price == 0 ? f.ActualAmount : f.Price) * f.NumberSold) + (f.Tax));

                  var addonConcession = this.receiptTicket.Concessions.filter(x => x.AddonConcessionName != null &&
                    x.AddonConcessionName.indexOf(f.ConcessionName) !== -1 &&
                    (addonConcessionItems.filter(a => a == x.AddonConcessionName).length == 0));

                  // Need to check if we have two concession and diffrent addon items
                  var addonItems = [];
                  if (addonConcession.length > 0) {
                    addonConcessionItems.push(addonConcession[0].AddonConcessionName);
                    addonItems = this.receiptTicket.Concessions.filter(x => x.AddonConcessionName == addonConcession[0].AddonConcessionName);

                    for (let item in addonItems) {
                      this.finalAmount = RoundAmount(this.finalAmount + addonItems[item].Price + addonItems[item].Tax);
                    }

                    f.ConcessionName += " \n" + addonConcession[0].AddonConcessionName;

                    // Add all addon items to show price on print
                    if (addonItems.length > 0)
                      this.addonConcesstions.push({ ConcessionNames: f.ConcessionName, AddonItems: addonItems });
                  }
                }
              })

              this.paymentMode = this.getPaymentMode(this.SalesOrder.PaymentMode);
              this.cashFinalAmount = 0;
              this.ccFinalAmount = 0;
              this.gcFinalAmount = 0;
              if (res.SalesSplit && res.SalesSplit.length > 0) {
                res.SalesSplit?.forEach(f => {
                  if (f.PaymentMode == PaymentMode.Cash) {
                    this.cashFinalAmount += f.TotalAmount;
                  }
                  if (f.PaymentMode == PaymentMode.GiftCard) {
                    this.gcFinalAmount += f.TotalAmount
                  }
                  if (f.PaymentMode == PaymentMode.CC || f.PaymentMode == PaymentMode.ManualCC) {
                    this.ccFinalAmount += f.TotalAmount;
                  }
                });
              }
              this.receiptTicket.GiftCardSale = res.GiftCardSale;
              this.receiptTicket.GiftCardSale?.forEach(go => {
                this.finalAmount = (this.finalAmount + go.Amount);
              });
              if (res.TotalAmount == 0) {
                this.finalAmount = 0;
              }
              if (this.finalAmount != (res.TotalAmount + res.TotalTax))
                this.finalAmount = res.TotalAmount + res.TotalTax;

              if (this.receiptTicket.Tickets.length > 0 || this.receiptTicket.Concessions.length > 0 || this.receiptTicket.GiftCardSale.length > 0) {
                if (this.receiptTicket.Tickets.length > 0) {
                  if (this.receiptTicket.Tickets[0].TicketType.Status == 2) {
                    this.duplicateFlag = !this.duplicateFlag;
                  }
                }
                this.vendorCopyFlag = false;
                console.log("this.finalAmount", this.finalAmount);
                if (this.SalesOrder.Refund.length > 0 && this.SalesOrder.TotalAmount == 0 && this.SalesOrder.DiscountType[0] == 2) {
                  this.SalesOrder.Refund.forEach((s: any) => {
                    if (s.Concession.length > 0) {
                      s.Concession.map((c: any) => {
                        this.loyalityrefund += c.ActualAmount;
                        this.loyalityrefund += c.ActualTax;
                      })
                    }
                    if (s.Tickets.length > 0) {
                      s.Tickets.map((t: any) => {
                        this.loyalityrefund += t.ActualAmount;
                        this.loyalityrefund += t.ActualTax;
                      })
                    }
                  })
                }
                //if (this.finalAmount > 50 && !this.duplicateFlag) {
                //  await this.printService.onDataReadyWithoutClear();
                //  setTimeout(async () => {
                //    this.vendorCopyFlag = true;
                //    // console.log("FLAG2",this.vendorCopyFlag);
                //    await this.printService.onDataReady();
                //  }, 2500);
                //} else {
                await this.printService.onDataReady();
                // }
                if (sessionStorage.MemberDetail) {
                  var isloyaltyused = false;
                  var saleso = res;
                  if (saleso) {
                    if (saleso.DiscountType) {
                      let LoyaltyPoint = saleso.DiscountType.find(x => x == DiscountType.LoyaltyPoint)
                      if (LoyaltyPoint) {
                        isloyaltyused = true;
                      }
                    }

                  }
                  let memberid = JSON.parse(sessionStorage.MemberDetail).MemberNumber;
                  let memeberreward = res.OrderLoyaltyCredit;
                  let companyid = JSON.parse(sessionStorage.location).Company_ID;
                  if (!isloyaltyused) {
                    console.log('memberid, memeberreward, companyid', memberid, memeberreward, companyid);
                    this.posService.AddMemberRewards(memberid, memeberreward, companyid).subscribe(f => {
                      console.log("Response", f);
                      sessionStorage.removeItem('MemberDetail');
                    }, err => {

                    });
                  }
                }
              }
              if (this.receiptTicket.Tickets.length == 0 && this.receiptTicket.Concessions.length == 0 && this.receiptTicket.GiftCardSale.length == 0) {

                Swal.fire("No Records Found", "", "error");
                this.SalesOrder = null;
                // this.location.back();
              }
              setTimeout(async () => this.printService.printModeEnabled = false, 2000);
            }, err => { });
          } else {
            Swal.fire('Print Ticket', 'No record found', 'info');
            this.printService.onDataFailed();
            this.printService.printModeEnabled = false;
          }
        });
      }
    }
   
  }

  async printAllCheckedforScreen() {
    this.sreenItemsList = [];
    this.printService.printModeEnabled = true;

    if (this.printService.printAllScreen && this.printService.printAllScreen.length > 0) {
      
     
      this.printAllScreenDiv = true;
      this.printAllScreendiv = this.printService.printAllScreen;

      // this.printAllScreen = this.printService.printAllScreen;      
      this.scService.GetOnlineQrCodeByAllScreen(this.locationDetail.Location_ID, this.locationDetail.Company_ID, this.printAllScreendiv).then(async qr => {
        for (let i = 0; i < qr.Image.length; i++) {
          var data = qr.Image[i]
          this.sreenItemsList.push(data);
        }
        // this.printService.printlocation.slice(0, this.printService.printlocation.length);
        this.printService.printAllScreen.splice(0, this.printService.printAllScreen.length);
        setTimeout(async () => {
          //   this.printloc = false; 
          if (this.printService.screenqrflag == true) {
            this.printService.onDataReady();

          }
        }, 2500);

       

       // this.printService.printModeEnabled = false;

      });
      //this.printService.printModeEnabled = false;
    }
    // console.log('routeparam', routeparam, this.qrcodePrint);
  }

  async printAllCheckedforSeat() {
    this.seatItemsList = [];
    this.printService.printModeEnabled = true;
    // this.printService.printAllScreenDiv = false; 
    
    if (this.printService.printAllSeats && this.printService.printAllSeats.length>0) {
      
     
      this.printAllSeatDiv = true;
      this.printSeatScreenName = this.printService.printSeatScreenName;
      this.printAllSeatdiv = this.printService.printAllSeats;
      this.seatItemsList = this.printService.seatItemsList;
      // this.printAllSeats = this.printService.printAllSeats;
      //this.scService.GetOnlineQrCodeByAllSeat(this.locationDetail.Location_ID, this.locationDetail.Company_ID, this.printSeatScreenName, this.printAllSeatdiv).then(qr => {
      //  for (let i = 0; i < qr.Image.length; i++) {
      //    // this.selectedSeatQrData = 'data:image/png;base64,' + qr.Image[i].Value;
      //    var data = qr.Image[i];
      //    this.seatItemsList.push(data);
      //  }
      //  this.printService.printAllSeats.splice(0, this.printService.printAllSeats.length);

        setTimeout(async () => {
          if (this.printService.seatqrflag == true) {
            this.printService.onDataReady();
          }
        }, 2500);

      //  this.printService.printModeEnabled = false;
      //});
     // this.printService.printModeEnabled = false;
    }
    // console.log('routeparam', routeparam, this.qrcodePrint);
  }


  printQrCodeforScreen(printScreenid) {
    this.printService.printModeEnabled = true;
    if (this.printService.printScreenid && this.printService.printScreenid.length >0) {
       
      this.printScreenid = this.printService.printScreenid;
      this.printScreenname = this.printService.printScreenname;
      this.scService.GetOnlineQrCodeByScreen(this.locationDetail.Location_ID, this.locationDetail.Company_ID, this.printScreenid).subscribe(qr => {
        
        this.printAllScreenDiv = true;
        this.screenqrData = 'data:image/png;base64,' + qr.Image;
        //  this.printService.printScreenid.slice(0, this.printService.printScreenid.length);
        //this.printService.printModeEnabled = false;
        setTimeout(async () => {
          //   this.printloc = false; 
          if (this.printService.screenqrflag == true) {
            this.printService.onDataReady();

          }
        }, 2500);
      }, error => {
        Swal.fire('', 'Please upload the location or company logo to continue', 'error')
      });
     // this.printService.printModeEnabled = false;
    }
  }

  printQrCodeforSeat(printSeatid) {
    this.printService.printModeEnabled = true;
    if (this.printService.printSeatid && this.printService.printSeatid.length>0) {
    
      this.scService.GetOnlineQrCodeBySeat(this.locationDetail.Location_ID, this.printService.printScreenidSeat, this.locationDetail.Company_ID, this.printService.printSeatid).subscribe(qr => {
       
        this.printSeatid = this.printService.printSeatid;
        this.printSeatScreenName = this.printService.printSeatScreenName;
        this.seatqrData = 'data:image/png;base64,' + qr.Image;
        setTimeout(async () => {
          if (this.printService.seatqrflag == true) {
            this.printService.onDataReady();
          }
        }, 2500);
      }, error => {
        Swal.fire('', 'Please upload the location or company logo to continue', 'error')
      });
    }
  }

  printQrCodeforTable() {
    this.printService.printModeEnabled = true;
    if (this.printService.tableqrFlag && this.printService.printTableId) {
      this.printHallName = this.printService.printDisplayHallName;
      this.displayTableNumber = this.printService.printdisplayTableNumber;
      this.scService.GetOnlineQrCodeByTable(this.locationDetail.Location_ID, this.printService.printHallName, this.locationDetail.Company_ID, this.printService.printTableId).subscribe(qr => {
        this.seatqrData = 'data:image/png;base64,' + qr.Image;
        this.qrTable = 'data:image/png;base64,' + qr.Image;
        setTimeout(async () => {
          if (this.printService.tableqrFlag == true) {
            this.printService.onDataReady();
          }
        }, 2500);
      }, error => {
        Swal.fire('', 'Please upload the location or company logo to continue', 'error')
      });
    }
  }
  filterSubtitute(fooditems: any[]): any[] {
    return fooditems.filter(h => h.FoodType == 1);
  }
  gettablenameBytableId(TableId){
    return this.printService.HallTableDetailList.find(d=>d.TableId==TableId).TableNumber;
  }

  async printAllCheckedforTable() {
    this.tableItemsList = [];
    this.printService.printModeEnabled = true;
    // this.printService.printAllScreenDiv = false; 
    
    if (this.printService.printAllTable && this.printService.printAllTable.length>0) {
      this.printHallName = this.printService.printDisplayHallName;
      this.printAllTablediv = this.printService.printAllTable;
      this.tableItemsList = this.printService.tableItemsList;

        setTimeout(async () => {
          if (this.printService.tableqrFlag == true) {
            this.printService.onDataReady();
          }
        }, 2500);
    }
  }

  /*  printQrCode() {
     // let routeparam = this.activeRot.snapshot.children[0].routeConfig.path.split('/')[0];
      //if (routeparam == "qr") {
     
        this.CS.GetOnlineQrCode(this.printlocationdiv, this.locationDetail.Company_ID).subscribe(qr => {
          this.qrLoc = 'data:image/png;base64,' + qr.Image;      
        
          this.printService.printModeEnabled = false;    
        },
          error => {
          });
        this.printService.printModeEnabled = false;
      
     // }
     // console.log('routeparam', routeparam, this.qrcodePrint);
    }*/

  async printLocQrCode() {
    this.planordservice.GetPlanOrder(this.locationDetail.Company_ID, this.locationDetail.Location_ID).subscribe((res: any) => {
      this.location.GetDineIn().subscribe(async (id) => {
        if (this.printService.QRC == false) {

    this.printService.printModeEnabled = true;
    if (this.printService.printlocation && this.printService.printlocation.length>0  ) {
     
      this.printlocationdiv = this.printService.printlocation;
      this.scService.GetLocOnlineQrCode(this.printlocationdiv, this.locationDetail.Company_ID).subscribe(async qr => {
        
        this.printloc = true;
        this.qrLocation = 'data:image/png;base64,' + qr.Image;
        this.printService.printlocation.slice(0, this.printService.printlocation.length);
        //  this.printService.printScreenid.slice(0, this.printService.printScreenid.length);
        Promise.resolve(true);
        this.printService.printlocation.slice(0, this.printService.printlocation.length);
        setTimeout(async () => {
          this.printloc = true;
          if (this.printService.locationqrflag == true) {
            await this.printService.onDataReady();
          }
        }, 2500);

       // this.printService.printModeEnabled = false;
      },
        error => {
        });
     // this.printService.printModeEnabled = false;
    }

  }
}) 
})
  }
  async printQrorderCode() {
    this.planordservice.GetPlanOrder(this.locationDetail.Company_ID, this.locationDetail.Location_ID).subscribe((res: any) => {
      this.location.GetDineIn().subscribe(async (id) => {
        if (this.printService.QRC == true) {

          this.printService.printModeEnabled = true;
    if (this.printService.printlocation && this.printService.printlocation.length > 0) {

      this.printlocationdiv = this.printService.printlocation;
      this.scService.GetOnlineQROrderingByLoc(this.printlocationdiv, this.locationDetail.Company_ID).subscribe(async qr => {
        this.printqr = true;
        this.qrordering = 'data:image/png;base64,' + qr.Image;
        this.printService.printlocation.slice(0, this.printService.printlocation.length);
        //  this.printService.printScreenid.slice(0, this.printService.printScreenid.length);
        Promise.resolve(true);
        this.printService.printlocation.slice(0, this.printService.printlocation.length);
        setTimeout(async () => {
          this.printqr = true;
          if (this.printService.locationqrflag == true) {
            await this.printService.onDataReady();
          }
        }, 2500);

        // this.printService.printModeEnabled = false;
      },
        error => {
        });
      // this.printService.printModeEnabled = false;
    }
        }
      }) 
    })
   
  }

  async printKitchen() {
    console.log(this.activeRot.snapshot.children[0].params.type);
    let routeparam = this.activeRot.snapshot.children[0].params.type;
    if (routeparam) {
      let selectePrepArea = routeparam.split('#')[0];
      let SalesOrderId = routeparam.split('#')[1];
      this.dineinService.getKitchenOrdersBySalesOrderId(SalesOrderId).subscribe(async (res: any) => {
        res = await res.filter(ci => ci.PrepAreaName == selectePrepArea);
        res = await res.filter(t => t.Status != SalesStatus.Void && t.Status != SalesStatus.Failed);
        if (res.length > 0) {
          // this.kitchenSalesOrder={};
          this.kitchenSalesOrder.orderArray = res;
          this.kitchenSalesOrder.display = true;
          this.kitchenSalesOrder.orderArray.forEach(ko => {
            if (ko.Food.split('\n').length > 1) {
              ko.addon = ko.Food.split('\n');
            } else {
              ko.addon = [];
            }
          });
          this.kitchenSalesOrder.selectePrepArea = selectePrepArea;
          this.kitchenSalesOrder.AreaName = this.kitchenSalesOrder.orderArray[0].ScreenNumber;
          this.kitchenSalesOrder.SeatingName = this.kitchenSalesOrder.orderArray[0].SeatNumber;
          this.kitchenSalesOrder.DateTimeSold = this.kitchenSalesOrder.orderArray[0].DateTimeSold;
          this.kitchenSalesOrder.UniqueOrderNumber = this.kitchenSalesOrder.orderArray[0].UniqueOrderNumber;
          this.kitchenSalesOrder.SalesChannel = this.kitchenSalesOrder.orderArray[0].SalesChannel;
          console.log("before print ondataready", this.kitchenSalesOrder, 'Time---Print Component----------', new Date());
          this.kitchenSalesOrderLoaded = Promise.resolve(true);
          setTimeout(() => {
            this.printService.onDataReady();
          }, 1000);
        }
      });
    }
  }

  async printUnPaidSalesOrder() {
    let routeparam = this.activeRot.snapshot.children[0].params.type;
    if (routeparam) {
      this.authPaidSalesOrder = this.activeRot.snapshot.children[0].routeConfig.path.indexOf("authPaid") != -1;
      if (this.authPaidSalesOrder) {
        await this.paymentOptionService.KitchenPostObject.subscribe(async (success: any) => {
          if (success.servedSalesOrderId) {
            if (success && success.saleskitchenOrders && success.saleskitchenOrders.length > 0) {
              this.authPaymentMode = success.saleskitchenOrders[0].PaymentMode;
              if (this.authPaymentMode == PaymentMode.GiftCard) {
                this.authGiftCardNumber = success.saleskitchenOrders[0].GiftCardNumber;
                await this.GCS.getGiftCardbyCardNumber(this.authGiftCardNumber).then((res: GiftCard) => {
                  if (res != null) {
                    this.authGiftCardBalance = "$" + res.TotalBalance.toFixed(2);
                  }
                });
              }
            }
          }
        });
      }
      let unPaidOrderStr = routeparam.split('#')[0];
      if (unPaidOrderStr === "salesOrder") {
        let SalesOrderId = routeparam.split('#')[1];
        let selectePrepArea = routeparam.split('#')[2];
        this.pos.getSalesOrder(SalesOrderId).subscribe(async (res: any) => {
          console.log(res);
          if (res.SalesChannel == "QR ordering" && selectePrepArea != undefined && selectePrepArea!="" ) {  
            var temp = []
            temp = await res.Concession.filter(ci => ci.PrepAreaName == selectePrepArea && ci.AddonConcessionName == null);
            res.Concession.forEach(f => {
              if (f.AddonConcessionName != null) {
                var result = f.AddonConcessionName.slice(0, f.AddonConcessionName.indexOf('_'));
                console.log(result)
                 var indexValue = temp.findIndex((c) => c.ConcessionName === result );
                if (indexValue > -1) {
                  temp.push(f)
                }
              }
            })

            res.Concession = temp;
          }
          this.unPaidSalesOrder = res;
          
          var pay = res.PaymentID;
          var giftcard = res.GiftCardNumber;
          if (pay != null || (giftcard != null && giftcard !=0)) {
            this.amountdueorpay = true;
          }
          this.unPaidSalesOrder.Concessions = res.Concession.filter(t => t.Status != SalesStatus.Void && t.Status != SalesStatus.Failed);
          this.unPaidSalesOrder.Concessions = this.unPaidSalesOrder.Concessions.filter(c => (c.NumberSold != 0));
          // Add-on functionality implementated for dine-in
          var hasAddonConcessionItems = this.unPaidSalesOrder.Concessions?.filter(x => x.AddonConcessionName != null).length > 0;
          var addonConcessionItems = [];
          this.addonConcesstions = [];

          this.unPaidSalesOrder.Concessions?.forEach(f => {
            if (!hasAddonConcessionItems) {
              f.Tax = f.Tax * f.NumberSold;
              this.finalAmount = RoundAmount(this.finalAmount + (f.Price * f.NumberSold) + f.Tax);
            }
            else if (f.AddonConcessionName == null) {
              f.Tax = f.Tax * f.NumberSold;
              this.finalAmount = RoundAmount(this.finalAmount + (f.Price * f.NumberSold) + f.Tax);

              var addonConcession = this.unPaidSalesOrder.Concessions.filter(x => x.AddonConcessionName != null &&
                x.AddonConcessionName.indexOf(f.ConcessionName) !== -1 &&
                (addonConcessionItems.filter(a => a == x.AddonConcessionName).length == 0));

              // Need to check if we have two concession and diffrent addon items
              var addonItems = [];
              if (addonConcession.length > 0) {
                addonConcessionItems.push(addonConcession[0].AddonConcessionName);
                addonItems = this.unPaidSalesOrder.Concessions.filter(x => x.AddonConcessionName == addonConcession[0].AddonConcessionName);

                for (let item in addonItems) {
                  this.finalAmount = RoundAmount(this.finalAmount + addonItems[item].Price + addonItems[item].Tax);
                }

                f.ConcessionName += " \n" + addonConcession[0].AddonConcessionName;

                // Add all addon items to show price on print
                if (addonItems.length > 0)
                  this.addonConcesstions.push({ ConcessionNames: f.ConcessionName, AddonItems: addonItems });
              }
            }
          });

          console.log("UnPaid SalesOrder before print ondataready", this.kitchenSalesOrder, 'Time---Print Component----------', new Date());
          this.kitchenSalesOrderLoaded = Promise.resolve(true);
          setTimeout(() => {
            this.printService.onDataReady();
          }, 1000);
        });
      }
    }
  }

  getPaymentMode(paymentModeNo) {
    if (paymentModeNo != null || paymentModeNo != undefined) {
      var mode = '';
      if (paymentModeNo == 1) {
        mode = "Cash";
      } else if (paymentModeNo == 0 || paymentModeNo == 2) {
        mode = "Credit Card";
      } else if (paymentModeNo == 3) {
        mode = "Gift Card";
      }
      else if (paymentModeNo == 4) {
        mode = "Split";
      }

      return mode;
    }
  }

  //getGCBalance() {
  //  if (this.activeRot.snapshot.children[0].params.id) {
  //    this.pos.getSalesOrder(this.activeRot.snapshot.children[0].params.id).toPromise().then(async res => {
  //      if (res) {
  //        if (res.PaymentMode == PaymentMode.GiftCard) {
  //          var gc = await this.GCS.getGiftCardbyCardNumber(res.GiftCardNumber);
  //          this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
  //        }
  //        else if (res.PaymentMode == PaymentMode.Split) {
  //          res.SalesSplit.forEach(async sp => {
  //            if (sp.PaymentMode == PaymentMode.GiftCard) {
  //              var gc = await this.GCS.getGiftCardbyCardNumber(sp.GiftCardNumber);
  //              this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
  //            }
  //          })
  //        }
  //      }
  //    });
  //  }
  //}

 async checkurl() {
    if (this.logourl == "") {
      var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
      if (locationDetail && locationDetail.Company_ID) {
        await this.location.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((res: any) => {
          this.logourl = res.LogoImage;
        });
      } else {
        Swal.fire('OOPS! Session expired.', ' Please select location again.')
        this.router.navigate(['/locations']);
      }
    }
  }

  ngOnInit() {
    this.checkurl();
  }

  ngAfterViewInit(): void {
    // this.printService.onDataReady();
  }

  getSeatString(seats: string[]) {
    var newList = '';
    var row = "<br/>";

    for (var i = 0; i < seats.length; i++) {
      if (i != 0 && i % 3 == 0 && seats[i] != undefined) { // every 3rd one we're going to start a new row        
        newList += row;
      }

      if (seats[i] != undefined) {
        newList += seats[i]; // add each item to the row
        if (i != seats.length - 1) {
          newList += ',';
        }
      }
    }

    return newList;
  }

  getTotalAmount(qty, price) {
    let total = 0;
    return total = qty * price;
  }




  printParkingLot() {

    this.printService.printModeEnabled = true;
    this.Sale = null;
    this.SalesOrderData = null;
    this.SalesOrder = null;
    if (this.printService.isparkingPrinting) {
      this.pos.getParkingOrderByParkingConf(this.activeRot.snapshot.children[0].params.id).subscribe(async res => {
        this.printService.isparkingPrinting = false;
        this.ParkingLotSalesOrder = res;
        this.ParkingLotSalesOrder.recieptParkingSlot = [];
        this.paymentMode = this.getPaymentMode(this.ParkingLotSalesOrder.PaymentMode);
        this.pos.getQr(this.ParkingLotSalesOrder.ParkingLotConfirmationCode).subscribe(async (data) => {
          this.ParkqrData = 'data:image/png;base64,' + data.Image;
          if (this.ParkingLotSalesOrder.PaymentMode == PaymentMode.GiftCard) {
            this.gcNumber = this.ParkingLotSalesOrder.GiftCardNumber;
            var gc = await this.GCS.getGiftCardbyCardNumber(this.ParkingLotSalesOrder.GiftCardNumber);
            this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
          }
          const slotmovie = [];
          //this.ParkingLotSalesOrder.ParkingSlots.forEach(k => {
          //  if (!slotmovie.find(l => l.MovieName == k.MovieName && l.ShowId == k.ShowId)) {
          //    var mdf = [];
          //    mdf.push(k);
          //    let hj = {
          //      MovieName: k.MovieName,
          //      Showtime: k.Showtime,
          //      Showdate: k.Showdate,
          //      ShowId: k.ShowId,
          //      ParkingLotName: k.ParkingLotName,
          //      SlotList: mdf
          //    }
          //    slotmovie.push(hj);
          //  } else {
          //    const sl = slotmovie.findIndex(l => l.MovieName == k.MovieName && l.ShowId == k.ShowId);
          //    if (sl > -1) {
          //      slotmovie[sl].SlotList.push(k);
          //    }
          //  }
          //})
          //this.ParkingLotSalesOrder.recieptParkingSlot = slotmovie;
          var groupdata = this.groupByParkingLot(this.ParkingLotSalesOrder.ParkingSlots);
          console.log("groupby", groupdata);
          this.ParkingLotSalesOrder.recieptParkingSlot = groupdata;
        });
        setTimeout(() => {
          this.printService.onDataReady();
        }, 1000);
      })
    }
    
  }


  groupByParkingLot(movies: any[]): any[] {
    const parkingLotGroups: any[] = [];

    movies.forEach(movie => {
      const existingParkingLot = parkingLotGroups.find(pl => pl.ShowId === movie.ShowId && pl.MovieName === movie.MovieName && pl.ParkingLotName === movie.ParkingLotName);
      if (existingParkingLot) {
        const existingGroup = existingParkingLot.Groups.find(g => g.GroupName === movie.GroupName);
        if (existingGroup) {
          const existingPriceGroup = existingGroup.PriceGroups.find(pg => pg.PriceName === movie.PriceName);
          if (existingPriceGroup) {
            existingPriceGroup.Movies.push(movie);
          } else {
            var mv = [];
            mv.push(movie);
            existingGroup.PriceGroups.push({
              PriceName: movie.PriceName,
              Movies: mv
            });
          }
        } else {
          var mv = [];
          mv.push(movie);
          existingParkingLot.Groups.push({
            GroupName: movie.GroupName,
            PriceGroups: [{
              PriceName: movie.PriceName,
              Movies: mv
            }]
          });
        }
      } else {
        var mv = [];
        mv.push(movie);
        parkingLotGroups.push({
          MovieName: movie.MovieName,
          Showtime: movie.Showtime,
          Showdate: movie.Showdate,
          ShowId: movie.ShowId,
          ParkingLotName: movie.ParkingLotName,
          Groups: [{
            GroupName: movie.GroupName,
            PriceGroups: [{
              PriceName: movie.PriceName,
              Movies: mv
            }]
          }]
        });
      }
    });

    return parkingLotGroups;
  }



  async printRefundParkingLot() {
    this.CS.GetDateTimeByTimeZone().subscribe(async (result) => {
      this.locationDate = result;
      this.printService.printModeEnabled = true;
      if (this.printService.RefundSParkO.length != 0) {
        this.Sale = null;
        this.SalesOrder = null;
        this.SalesOrderData = null;
        this.ParkingOrderData = this.printService.RefundSParkO;
        this.ParkingOrderData.forEach(SO => {

          this.pos.getParkingOrderByParkingConf(SO.ParkingLotConfirmationCode).subscribe(async res => {
            if (res) {
              // this.SalesOrder = res;
              if (res.PaymentMode === 3) {
                this.gcNumber = res.GiftCardNumber;
                var gc = await this.GCS.getGiftCardbyCardNumber(res.GiftCardNumber);
                console.log("gcbal", gc);
                this.gcBalance = Math.round(gc.TotalBalance * 100) / 100;
              }

            }
          });

          this.CS.GetQr(SO.ParkingLotConfirmationCode).subscribe(data => {

            let receipt: any = {}

            receipt.PaymentMode = SO.PaymentMode;
            receipt.CCLastFour = SO.CCLastFour;
            receipt.MerchantName = SO.MerchantName;
            receipt.WorldpayTransactionDetails = SO.WorldpayTransactionDetails;

            receipt.TotalRefund = SO.TotalRefund;
            // receipt.ActualAmount = SO.ActualAmount + SO.ActualTax;

            SO.ParkingSlots = SO.ParkingSlots.filter(t => (t.Status != SalesStatus.Void && t.Status != SalesStatus.Failed));


            receipt.ParkingSlots = SO.ParkingSlots;
            receipt.TotalFinalAmount = 0;
            if (receipt.ParkingSlots && receipt.ParkingSlots?.length > 0) {
              receipt.ParkingSlots.forEach(x => {


                let totalAmount = RoundAmount(receipt.TotalFinalAmount + x.Price + x.Tax);
                receipt.TotalFinalAmount = totalAmount;
              });
            }
            receipt.recieptParkingSlot = [];
            const slotmovie = [];
            //receipt.ParkingSlots.forEach(k => {
            //  if (!slotmovie.find(l => l.MovieName == k.MovieName && l.ShowId == k.ShowId)) {
            //    var mdf = [];
            //    mdf.push(k);
            //    let hj = {
            //      MovieName: k.MovieName,
            //      Showtime: k.Showtime,
            //      Showdate: k.Showdate,
            //      ShowId: k.ShowId,
            //      ParkingLotName: k.ParkingLotName,
            //      SlotList: mdf
            //    }
            //    slotmovie.push(hj);
            //  } else {
            //    const sl = slotmovie.findIndex(l => l.MovieName == k.MovieName && l.ShowId == k.ShowId);
            //    if (sl > -1) {
            //      slotmovie[sl].SlotList.push(k);
            //    }
            //  }
            //})
            //receipt.recieptParkingSlot = slotmovie;
            var groupdata = this.groupByParkingLot(receipt.ParkingSlots);
            console.log("grouprefund", groupdata);
            receipt.recieptParkingSlot = groupdata;
            // Add-on functionality implementated for dine-in

            // receipt.TotalAmount = SO.TotalAmount + SO.TotalTax;
            receipt.CfmCode = SO.ParkingLotConfirmationCode;
            this.cashFinalAmount = 0;
            this.ccFinalAmount = 0;
            this.gcFinalAmount = 0;


            this.qrData = 'data:image/png;base64,' + data.Image;
            this.parkingreciept.push(receipt);
            setTimeout(async () => this.printService.printModeEnabled = false, 2000);
          }, err => {
            this.printService.printModeEnabled = false;
          });
        });
        // this.printService.onDataReady();
        this.vendorCopyFlag = false;
        // console.log("FLAG1",this.vendorCopyFlag);
      
         
          // console.log("FLAG2",this.vendorCopyFlag);
          await this.printService.onDataReadyWithoutClear();
       
        setTimeout(async () => {
          this.vendorCopyFlag = true;
          // console.log("FLAG2",this.vendorCopyFlag);
          await this.printService.onDataReady();
        }, 2500);
      }
      this.printService.RefundSParkO.splice(0, this.printService.RefundSParkO.length);
    });
  }
}
