import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { ApplicationInsightsService } from "./applicationInsights.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  errorHandlerService: any;
  constructor(private applicationInsightsService: ApplicationInsightsService) {
    super();
  }

  handleError(error: Error) {
    this.applicationInsightsService.logException(error); // Manually log exception
  }

}
