import { LocationBase } from "../helper/models/LocationBase";
import { SalesChannelBase } from "../helper/models/SalesChannelBase";

export interface TicketTagTypePC {
    display: string,
    value: string,
    price: number
}

export class PriceCard extends LocationBase {
    PriceCardId: string = '';
    PriceCardName: string = '';
    Tickets: Array<PriceCardTicket> = new Array<PriceCardTicket>();
    ValidDaysTime: ValidDaysTime = new ValidDaysTime();
    SalesChannel: Array<SalesChannelBase> = new Array<SalesChannelBase>();
    PriceCardCombo: Array<PriceCardCombo> = new Array<PriceCardCombo>();
    AdditionalCharges: Array<AdditionalCharges> = new Array <AdditionalCharges>();
    IsDefaultPricecard :boolean = false;
}
class PriceCardCombo {
    ComboName: string;
    TotalPrice: number;
    // PriceWithTax: number = 0;
    TotalTax:number;
    Ticket: Array<PriceCardTicket>;
    Concession: Array<PriceCardConcession>;
    Complimentary?: boolean = false;
}

export class AdditionalCharges {
  public Name: string;
  public AdditionalPrice: number;
  public TotalTax: string;
  public NumberOfTicketSold: number;
  public Actual: number;
  public SalesChannel: Array<SalesChannelBase> = new Array<SalesChannelBase>();
  public Status: number;
  public IncludeTicket: boolean = false;
  public ShareableRevenue: boolean = false;
}

export class PriceCardTicket {
    DisplayName: string = '';
    TicketId: string = '';
    Price: number = 0;
    // PriceWithTax: number = 0;
    TaxId:string = '';
    Tax?:number = 0;
    TaxName:string = '';
    LoyaltyPoints?:number = 0;
    Complimentary?: boolean = false;
    SeatGroupId: string = '';
}
export class PriceCardConcession {
    ConcessionName: string = '';
    ConcessionId: string = '';
    Price: number = 0;
    // PriceWithTax: number = 0;
    Tax?:number = 0;
    TaxId:string = '';
    TaxName:string = '';
    Complimentary?: boolean = false;
  //TotalQuantityInHand: number;
  ConcessionCategory: string = '';
}
export class ValidDaysTime {
    Days: Array<string> = new Array<string>();
    StartTime: string = '';
    EndTime: string = '';
}
export interface ValidDays {
    Mon: boolean;
    Tue: boolean;
    Wed: boolean;
    Thu: boolean;
    Fri: boolean;
    Sat: boolean;
    Sun: boolean;
}
//test
