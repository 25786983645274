import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../helper/services/api.service';
import { HttpClient } from '@angular/common/http';
import { Tax } from './Tax';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaxService extends ApiService {

  constructor(private http: HttpClient, @Inject('BASE_URL') base_url: string) {
    super(base_url);

    this.ApiEndPoint += "Tax";
  }

  getTaxs(): Observable<Array<Tax>> {
    return this.http.get<Array<Tax>>(this.ApiEndPoint)
      .pipe(catchError(this.errorHandler));
  }
  getTaxsByLocationId(): Observable<Array<Tax>> {
    return this.http.get<Array<Tax>>(this.ApiEndPoint + "/GetByLocationId")
      .pipe(catchError(this.errorHandler));
  }
  getTaxById(ID: string): Observable<Tax> {
    return this.http.get<Tax>(this.ApiEndPoint + "/" + ID)
      .pipe(catchError(this.errorHandler));
  }

  addTax(TT: Tax): Observable<any> {
    return this.http.post(this.ApiEndPoint, TT)
      .pipe(catchError(this.errorHandler));
  }

  editTax(TT: Tax): Observable<any> {
    return this.http.put(this.ApiEndPoint + "/" + TT.TaxId, TT)
      .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: number, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiEndPoint + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }
}
