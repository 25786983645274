import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private _cacheUrls: string[] = [];
  public _cachedImages: CachedImage[] = [];
  private dbName = 'imageDatabase';
  private dbVersion = 1;
  db!: IDBDatabase;
  private intervalTime =  30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.initDatabase();
  }

  initDatabase() {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onupgradeneeded = (event) => {
      this.db = (event.target as any).result;

      // Create object store if it doesn't exist
      if (!this.db.objectStoreNames.contains('images')) {
        this.db.createObjectStore('images', { keyPath: 'url' });

      }
      return;

    };

    request.onsuccess = (event) => {
      this.db = (event.target as any).result;
      return;

    };

    request.onerror = (event) => {
      console.error('Error opening IndexedDB', event);
      return;

    };
  }
  getImage(url: string): Observable<any> {
    if (this.db) {
      const transaction = this.db.transaction(['images'], 'readonly');
      const imageStore = transaction.objectStore('images');
      const getRequest = imageStore.get(url);

      return new Observable((observer) => {
        getRequest.onsuccess = (event) => {
          const existingImage = (event.target as any).result;

          if (existingImage) {
            observer.next(existingImage.blob);
            observer.complete();
          } else {
            // If the image doesn't exist in IndexedDB, fetch it from the server
            const timestamp = new Date().getTime();
            this.http.get(url, { responseType: 'blob' }).pipe(
              tap((blob) => {
                observer.next(blob);
                this.checkAndCacheImage(url, blob, timestamp);
              })
            ).subscribe(
              () => observer.complete(),
              (error) => {
                console.error('Error fetching image from server', error);
                observer.complete();
              }
            );
          }
        };

        getRequest.onerror = (event) => {
          console.error('Error checking image in IndexedDB', event);
          observer.complete();
        };
      });
    }
    else {
      // If IndexedDB is not properly initialized, fetch the image from the server
      const timestamp = new Date().getTime();

      return this.http.get(url, { responseType: 'blob' }).pipe(
        tap((blob) => this.checkAndCacheImage(url, blob, timestamp))
      );
    }
  }

  async checkAndCacheImage(url: string, blob: Blob, timestamp: number) {
    if (this.db == undefined) {
      await this.initDatabase();
    }
    const transaction = this.db.transaction(['images'], 'readwrite');
    const imageStore = transaction.objectStore('images');

    const request = imageStore.put({ url, blob, timestamp });

    request.onsuccess = () => {
    };

    request.onerror = (event) => {
      console.error('Error storing image in IndexedDB', event);
    };

  }
  async clearData() {
    this.initDatabase();
    setTimeout(() => {
      this.deleteOldData();
    }, 200);
  }
  private deleteOldData() {
    const transaction = this.db.transaction(['images'], 'readwrite');
    const objectStore = transaction.objectStore('images');
    const currentTime = new Date().getTime();
    const cursorRequest = objectStore.getAll();
    cursorRequest.onsuccess = (event: any) => {
      const results = event.target.result;
      results.forEach((element: any) => {
        const timestamp = element.timestamp;
        if (currentTime - timestamp > this.intervalTime) {
          // Delete the record if it's older than the specified time
          objectStore.delete(element.url);
        }
      });

    };
  }
}
interface CachedImage {
  url: string;
  blob: Blob;
  timestamp: number;

}
