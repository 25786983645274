import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { Tax } from 'src/app/tax/Tax';
import { TaxService } from 'src/app/tax/Tax.service';
import { PriceCardService } from '../../price-card/price-card.service';
import { ScreenService } from '../../screens/screen.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-add-tickets',
  templateUrl: './add-tickets.component.html',
  styleUrls: ['./add-tickets.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddTicketsComponent implements OnInit {

  selectedTicketsData = new selectedTicketsData(0, []);

  public movieData: any = [];
  public moviewScreenData: any = {};
  public priceCardData: any = { Tickets: [] };
  public FinalTicketsData: any = { Tickets: [], TotalPrice: 0, TotalTickets: 0, AdditionalCharges: [] };
  public subscribed: any = [];
  public displayTotalPrice: number = 0;
  public TotalSeatsForShow: number = 0;
  public TotalBookedSeatsForShow: number = 0;
  public TotalRemaininSeatsForShow: number = 0;
  public Taxes: Array<Tax>;

  constructor(public router: Router, public kioskPOSService: KioskPOSService, public priceCardService: PriceCardService, public screenService: ScreenService, private TaxService: TaxService,private es:ErrorMessagesService) {
    this.movieData = this.kioskPOSService.movieData;
    this.getScreensDataForMovie();
    this.getpriceCardDataForShow();

    if (isNaN(this.TotalRemaininSeatsForShow && this.TotalRemaininSeatsForShow))
      this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  public getScreensDataForMovie() {
    var $this = this;
    this.movieData.forEach((item, index) => {
      if (item.LocationId == $this.kioskPOSService.getLocationId() && item.MovieID == $this.kioskPOSService.getMovieId()) {
        this.moviewScreenData = item;
        this.moviewScreenData.ShowProperties = this.kioskPOSService.getShowProperties();
      }
    });
  }

  public async getpriceCardDataForShow() {
    this.Taxes = (await this.TaxService.getTaxsByLocationId().toPromise()).filter(f => f.Visible == 1);
    let getpriceCardDataForShow = this.priceCardService.getKioskPriceCards(this.kioskPOSService.getPriceCardId()).subscribe((response) => {
      this.priceCardData = response;
      if (this.priceCardData.length == 0 || (this.priceCardData.PriceCardName && (this.priceCardData.PriceCardName === null || this.priceCardData.PriceCardName == 'undefined' || this.priceCardData.PriceCardName == ''))) {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[66].message, 'close');
        this.router.navigate(['/kiosk/show-time/' + this.kioskPOSService.getLocationId()]);
        return false;
      }
      else {
        // not required now
        // if (this.priceCardData.Tax && this.priceCardData.Tax !== null && this.priceCardData.Tax != undefined && this.priceCardData.Tax != '') {
        //     this.kioskPOSService.setTax(this.priceCardData.Tax);
        // }

        this.priceCardData.Tickets = this.priceCardData.Tickets.filter(a => a.Price != 0 && a.TaxAmount != 0);
        this.priceCardData.PriceCardCombo = this.priceCardData.PriceCardCombo.filter(a => a.TotalPrice != 0 && a.TotalTax != 0);

        console.log("Filter", this.priceCardData);
        this.selectedTicketsData.selectedTickets = this.priceCardData.Tickets;

        this.selectedTicketsData.selectedTickets.forEach((item, index) => {
          console.log("itemTICKET", item);
          item.TotalPrice = 0;
          item.Qty = 0;
          item.IsPriceCardCombo = 'n';
          item.Tax = this.Taxes.find(f => f.TaxId === item.TaxId)?.Percent || 0;
          item.TaxAmount = (item.Price * item.Tax) / 100;
          let getTickets = this.kioskPOSService.getTickets();
          if (getTickets.Tickets.length > 0) {
            getTickets.Tickets.forEach((ii, i) => {
              if (item.TicketId == ii.TicketId && ii.IsPriceCardCombo == 'n') {
                item.Qty = ii.Qty;
              }
            });
            this.calculateTotalPrice();
          }
        });
        /*Add Combo Tickets To Main Tickets*/
        if (this.priceCardData.PriceCardCombo && this.priceCardData.PriceCardCombo.length > 0) {
          this.priceCardData.PriceCardCombo.forEach((item, index) => {
            console.log("COMBO", item);
            const ComboID = Math.random().toString().split('.')[1].substr(0, 7);
            if (item.Ticket.length > 0) {
              item.Ticket.forEach((tItem, tIndex) => {
                tItem.TotalPrice = 0;
                tItem.Qty = 0;
                tItem.ComboID = ComboID;
                tItem.IsPriceCardCombo = 'y';
                tItem.ComboName = item.ComboName;
                tItem.Tax = this.Taxes.find(f => f.TaxId === tItem.TaxId)?.Percent || 0;
                tItem.ComboTotalPrice = item.TotalPrice;
                tItem.TaxAmount = (item.TotalPrice * item.TotalTax) / 100;
                /*PriceCard Combo Check*/
                let getTickets = this.kioskPOSService.getTickets();
                if (getTickets.Tickets.length > 0) {
                  getTickets.Tickets.forEach((ii, i) => {
                    if (tItem.TicketId == ii.TicketId && ii.IsPriceCardCombo == 'y' && ii.ComboName == tItem.ComboName) {
                      tItem.Qty = ii.Qty;
                    }
                  });
                }
                this.selectedTicketsData.selectedTickets.push(tItem);
                this.calculateTotalPrice();
                /*PriceCard Combo Check*/
              });
            }
            if (item.Concession.length > 0) {
              this.kioskPOSService.finalSelectedMovieData.IsFoodItemsAvailable = (this.kioskPOSService.finalSelectedMovieData.IsFoodItemsAvailable == 'n' ? 'y' : this.kioskPOSService.finalSelectedMovieData.IsFoodItemsAvailable);
              item.Concession.forEach((cItem, cIndex) => {
                cItem.TotalPrice = 0;
                cItem.Qty = 0;
                cItem.IsPriceCardCombo = 'y';
                cItem.ComboName = item.ComboName;
                cItem.ComboID = ComboID;
                cItem.ComboTotalPrice = item.TotalPrice;
                cItem.TotalPrice = cItem.Price;
                cItem.FoodTotalPrice = 0;
                cItem.Qty = 0;
                cItem.cIndex = 0;
                cItem.ComboTax = this.Taxes.find(f => f.TaxId === cItem.TaxId)?.Percent || 0;
                cItem.PriceCardComboQty = 0;
                cItem.TaxAmount = (item.TotalPrice * item.TotalTax) / 100;
                cItem.PriceCardComboUnitPrice = cItem.Price;
                /*PriceCard Combo Check*/
                let is_push: string = 'y';
                let getFoodItems = this.kioskPOSService.getFoodItems();
                if (getFoodItems.length > 0) {
                  getFoodItems.forEach((ii, i) => {
                    if (cItem.ConcessionId == ii.ConcessionId && ii.ComboName == cItem.ComboName) {
                      cItem.Qty = ii.Qty;
                      cItem.PriceCardComboQty = ii.PriceCardComboQty;
                      cItem.FoodTotalPrice = ((cItem.Qty * ii.PriceCardComboUnitPrice));
                      is_push = 'n';
                    }
                  });
                }
                if (is_push == 'y') {
                  this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.push(cItem);
                  this.calculateTotalPrice();
                }
                /*PriceCard Combo Check*/
              });
            }
          });
        }
        /*Ger Sales Order Total Booked Tickets*/
        /*if(this.kioskPOSService.getSeating() == 'open'){*/
        this.getSalesOrdersForShow();
        /*}*/
        /*Ger Sales Order Total Booked Tickets*/
      }
    }, (error) => {
      console.log(error);
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
    });
    this.subscribed.push(getpriceCardDataForShow);
  }

  public getSalesOrdersForShow() {
    let getScreenSeatsData = this.screenService.getScreenByID(this.kioskPOSService.getScreenId()).subscribe((response) => {
      if (response.Screen_ID || response.Screen_ID !== null || response.Screen_ID !== undefined || response.Screen_ID != '') {
        this.TotalSeatsForShow = response.Total_Seats;
        this.TotalBookedSeatsForShow = response.House_Seats.length + response.Broken_Seats.length;
        let getSalesOrdersForShow = this.kioskPOSService.SubmitGetFormData('Kiosk/GetSalesOrderByShowId/' + this.kioskPOSService.getShowId()).subscribe((SalesOrderResponse) => {
          if (SalesOrderResponse != "[]" && SalesOrderResponse.length > 0) {
            SalesOrderResponse.forEach((SalesOrderItem, SalesOrderIndex) => {
              if (SalesOrderItem && SalesOrderItem.Tickets && SalesOrderItem.Tickets.length > 0) {
                this.TotalBookedSeatsForShow += SalesOrderItem.Tickets.filter(f => f.ShowId === this.kioskPOSService.getShowId()).length
              }
            });
          }
          //this.TotalRemaininSeatsForShow = ((this.TotalSeatsForShow - this.TotalBookedSeatsForShow) - this.FinalTicketsData.TotalTickets);
          this.TotalRemaininSeatsForShow = (this.kioskPOSService.getAvailableSeats() - this.FinalTicketsData.TotalTickets);
          if (isNaN(this.TotalRemaininSeatsForShow))
            this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
        }, (error) => {
          console.log(error);
        });
        this.subscribed.push(getSalesOrdersForShow);
      }
    }, (error) => {
      console.log(error);
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
      this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
      return false;
    });
    this.subscribed.push(getScreenSeatsData);
  }

  public getPriceCardComboQty(combo: any, index: number = 0) {
    let id = '';
    let isTicket = 'n';
    let isConcession = 'n';
    let Qty = 0;
    if (this.priceCardData.PriceCardCombo[index].Ticket.length > 0) {
      id = this.priceCardData.PriceCardCombo[index].Ticket[0].TicketId;
      isTicket = 'y';
    }
    if (id == '' && this.priceCardData.PriceCardCombo[index].Concession.length > 0) {
      id = this.priceCardData.PriceCardCombo[index].Concession[0].ConcessionId;
      isConcession = 'y';
    }
    if (isTicket == 'y') {
      for (var i = 0; i <= (this.selectedTicketsData.selectedTickets.length - 1); i++) {
        if (this.selectedTicketsData.selectedTickets[i].TicketId == id && this.selectedTicketsData.selectedTickets[i].IsPriceCardCombo == 'y' && this.selectedTicketsData.selectedTickets[i].ComboName == combo.ComboName) {
          Qty = this.selectedTicketsData.selectedTickets[i].Qty;
          break;
        }
      }
    }
    else if (isConcession == 'y') {
      for (var i = 0; i <= (this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.length - 1); i++) {
        if (this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[i].ConcessionId == id && this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[i].ComboName == combo.ComboName) {
          Qty = this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[i].PriceCardComboQty;
          break;
        }
      }
    }
    else {
    }
    return Qty;
  }

  public plusTicket(ticket: any, index: number = 0) {
    //if(this.selectedTicketsData.selectedTickets[index].Qty < 10){
    /*if(this.kioskPOSService.getSeating() == 'open'){*/
    if (this.TotalRemaininSeatsForShow > 0) {
      if (this.getTotalTicketsSelected() < 10) {
        this.selectedTicketsData.selectedTickets[index].Qty++;
        this.TotalRemaininSeatsForShow--;
        this.calculateTotalPrice();
      }
      else {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[67].message, 'close');
      }
    }
    else {
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[68].message, 'close');
    }
    /*}
    else{
        this.selectedTicketsData.selectedTickets[index].Qty++;
        this.TotalRemaininSeatsForShow--;
        this.calculateTotalPrice();
    }*/
    //}
    //else{
    //    this.kioskPOSService.showSnackBar('You can select max 10 tickets!','close');
    //}
  }

  public minusTicket(ticket: any, index: number = 0) {
    if (this.selectedTicketsData.selectedTickets[index].Qty > 0) {
      this.selectedTicketsData.selectedTickets[index].Qty--;
      this.TotalRemaininSeatsForShow++;
    }
    else {
      this.selectedTicketsData.selectedTickets.totalPrice = 0;
      // this.kioskPOSService.showSnackBar('Minimum value should zero!', 'close');
    }
    this.calculateTotalPrice();
  }

  async plusPriceCardCombo(combo: any, index: number = 0) {
    let Qty = this.getPriceCardComboQty(combo, index);
    let flaginventorycheck = true;
    await combo.Concession.forEach(c_item => {
      if (c_item.TotalQuantityInHand < Qty + 1 && c_item.ConcessionCategory != "food") {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[69].message + c_item.TotalQuantityInHand + " now!! ", 'close');
        flaginventorycheck = false;
        return true;
      }
    });
    if (flaginventorycheck) {
      let t_count = this.priceCardData.PriceCardCombo[index].Ticket.length;
      if ((this.getTotalTicketsSelected() + t_count) > 10) {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[67].message, 'close');
        return false;
      }
      //if(Qty < 10){
      let is_ok = true;
      if (this.kioskPOSService.getSeating() == 'open') {
        if (this.TotalRemaininSeatsForShow < this.priceCardData.PriceCardCombo[index].Ticket.length) {
          is_ok = false
        }
      }
      if (is_ok) {
        this.priceCardData.PriceCardCombo[index].Ticket.forEach((pItem, pIndex) => {
          this.selectedTicketsData.selectedTickets.forEach((item, index) => {
            if (item.TicketId == pItem.TicketId && item.IsPriceCardCombo == 'y' && item.ComboName == combo.ComboName) {
              if (this.getTotalTicketsSelected() < 10) {
                this.selectedTicketsData.selectedTickets[index].Qty++;
                this.TotalRemaininSeatsForShow--;
              }
              else {
                this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[67].message, 'close');
              }
            }
          });
        });

        this.priceCardData.PriceCardCombo[index].Concession.forEach((pItem, pIndex) => {
          this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.forEach((item, index) => {
            if (item.ConcessionId == pItem.ConcessionId && item.ComboName == combo.ComboName) {
              this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].Qty++;
              this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboQty++;
              this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].FoodTotalPrice = (parseFloat(this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboUnitPrice) * parseFloat(this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboQty));
            }
          });
        });
        this.calculateTotalPrice();
      }
      else {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[68].message, 'close');
      }
      /* }
       else{
           this.kioskPOSService.showSnackBar('You can select max 10 tickets!','close');
       }*/
    }
  }

  public minusPriceCardCombo(combo: any, index: number = 0) {
    let Qty = this.getPriceCardComboQty(combo, index);
    if (Qty > 0) {
      this.priceCardData.PriceCardCombo[index].Ticket.forEach((pItem, pIndex) => {
        this.selectedTicketsData.selectedTickets.forEach((item, index) => {
          if (item.TicketId == pItem.TicketId && item.IsPriceCardCombo == 'y' && item.ComboName == combo.ComboName) {
            this.selectedTicketsData.selectedTickets[index].Qty--;
            this.TotalRemaininSeatsForShow++;
          }
        });
      });
      this.priceCardData.PriceCardCombo[index].Concession.forEach((pItem, pIndex) => {
        this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.forEach((item, index) => {
          if (item.ConcessionId == pItem.ConcessionId && item.ComboName == combo.ComboName) {
            this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].Qty--;
            this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboQty--;
            this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].FoodTotalPrice = (parseFloat(this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboUnitPrice) * parseFloat(this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].PriceCardComboQty));
            if (this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[index].Qty == 0) {
              /*this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.splice(index,1);*/
            }
          }
        });
      });
      this.calculateTotalPrice();
    }
    else {
      // this.kioskPOSService.showSnackBar('Minimum value should zero!', 'close');
    }
  }

  public getTotalTicketsSelected() {
    let count = 0;
    this.selectedTicketsData.selectedTickets.forEach((item, index) => {
      if (item.Qty > 0) {
        count += parseInt(item.Qty);
      }
    });
    return count;
  }

  public calculateTotalPrice() {
    this.selectedTicketsData.totalPrice = 0;
    this.FinalTicketsData.Tickets = [];
    this.FinalTicketsData.TotalTickets = 0;
    this.selectedTicketsData.selectedTickets.forEach((item, index) => {
      if (item.Qty > 0) {
        this.FinalTicketsData.TotalTickets = parseInt(this.FinalTicketsData.TotalTickets) + parseInt(item.Qty);
        item.TotalPrice = (parseFloat(item.Price) * parseFloat(item.Qty));;
        this.FinalTicketsData.Tickets.push(item);
        this.selectedTicketsData.totalPrice += (parseFloat(item.Price) * parseFloat(item.Qty));
      }
    });
    this.FinalTicketsData.TotalPrice = this.selectedTicketsData.totalPrice;
    /*Check Price CardCombo Concession Items*/
    this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalFoodItems = 0;
    this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalPrice = 0;
    this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.forEach((item, index) => {
      this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalFoodItems = parseInt(this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalFoodItems) + parseInt(item.Qty);
      this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalPrice += (parseFloat(item.PriceCardComboUnitPrice) * parseFloat(item.PriceCardComboQty));
    });
    /*Check Price CardCombo Concession Items*/
    console.log("this.selectedTicketsData.totalPrice", this.selectedTicketsData)
    // this.selectedTicketsData.totalPrice = this.selectedTicketsData.totalPrice + this.selectedTicketsData.TaxAmount;
    this.displayTotalPrice = this.selectedTicketsData.totalPrice + parseFloat(this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalPrice);
  }

  public selectSeatsForShow(e) {
    // if(this.selectedTicketsData.totalPrice > 0){
    if (this.priceCardData.AdditionalCharges) {
      this.FinalTicketsData.AdditionalCharges = [];
      this.priceCardData.AdditionalCharges.filter(x => x.SalesChannel.findIndex(x => x.SalesChannelName == "Kiosk") != -1).forEach((ac) => {
        let ticIndex = 1;
        let revIndex = 1;
        if (ac.IncludeTicket) {
          ticIndex = 2;
        }
        if (ac.ShareableRevenue) {
          revIndex = 2;
        }
        this.FinalTicketsData.AdditionalCharges.push({
          Index: ticIndex.toString() + revIndex.toString(),
          Name: ac.Name,
          AdditionalPrice: ac.AdditionalPrice,
          Tax: this.Taxes.find(f => f.TaxId === ac.TotalTax)?.Percent || 0,
          SalesChannel: ac.SalesChannel,
          NumberOfTicketSold: ac.NumberOfTicketSold
        });
      });
    }
    this.kioskPOSService.setTickets(this.FinalTicketsData);
    this.kioskPOSService.setFoodItems(this.kioskPOSService.finalSelectedMovieData.FoodItems);
    if (this.kioskPOSService.getSeating() == 'reserved') {
      this.router.navigate(['/kiosk/select-seats/' + this.kioskPOSService.getLocationId()]);
    } else {
      this.router.navigate(['/kiosk/add-food-packs/' + this.kioskPOSService.getLocationId()]);
    }
    // }
    // else{
    //     this.kioskPOSService.showSnackBar('Plese select atleast one ticket!.','close');
    // }
  }

  public backButton(e) {
    this.kioskPOSService.unsetFinalData(['PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'IsFoodItemsAvailable', 'FoodItems']);
    this.router.navigate(['/kiosk/show-time/' + this.kioskPOSService.getLocationId()]);
  }
}


export class selectedTicketsData {
  constructor(
    public totalPrice: number = 0,
    public selectedTickets: any = []
  ) { }
}
