import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

import { AddHelperMethods, ImageType, RoundAmount, CloneObject } from './../helper/models/AddHelperModal';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { ContactService } from '../contact/contact.service';
import { Contact } from '../contact/contact';
import { SupportEmail } from '../contact/support';
import { Router } from '@angular/router';
import { CommonSettings } from '../helper/commonSettings';
import { ErrorMessagesService } from '../helper/services/error-messages.service';
import { CompanyService } from '../company/company.service';
import { StyleService } from '../helper/services/docloadings/style.service';
import { ToastrService } from 'ngx-toastr';
import { LocationsService } from '../locations/locations.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent extends AddHelperMethods implements OnInit {
  @ViewChild('SubscribeForm', { static: true }) SubscribeForm: NgForm;

  @ViewChild('ContactForm', { static: true }) ContactForm: NgForm;
  clientContact: Contact = new Contact();
  useremail:any="";
 
  supportEmails:any;
 
  error: string = '';
  formsubmitted: boolean = false;

  constructor(public localtionService: LocationsService,private toastrService: ToastrService,private stSer: StyleService,private clientContactService: ContactService,private router: Router,private clientCompanyService: CompanyService,private es:ErrorMessagesService) { 
    super(); 
    this.stSer.addStyle('bt', require('../../assets/css/bootstraplanding.min.css'));
    this.stSer.addStyle('bt', require('../../assets/css/all.min.css'));
    this.stSer.addStyle('all', require('../../assets/css/jquery.fancybox.min.css'));
    this.stSer.addStyle('all', require('../../assets/bootstrap-icons-1.9.1/bootstrap-icons.css'));

  }

  ngOnInit() {
    setTimeout(() => {
      $(".mat-form-field-flex").css("background-color","rgb(6 9 34)");
      $(".mat-form-field-flex").css("border","2px solid #45475a");
      $(".mat-form-field-flex").css("border-radius","0px");
    }, 1000);
   this.clientCompanyService.getsupportinfo().subscribe((res: any) => {
    //console.log("hello", res);
    if (res) {
      this.supportEmails = res[0].Contactus; 
    }

  });
  }

  Cancel() {
    Swal.fire({
      title: 'Are you sure you want to cancel?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/']);
      }
    })
    
  }

  /*changedSupport() {
    if ($selected != "" && $selected != undefined) this.clientContact.contactsupport = $selected;
  }*/
  setEmailSub(e) {
    console.log(e, "e")
    this.clientContact.contactsupport = e.value
    this.clientContact.Emailsubject = e.Emailsubject;

    setTimeout(() => {
      $(".mat-select-value-text").css("font-family","'Jost','sans-serif'");
      $(".mat-select-value-text").css("color","white");
      $(".mat-select-value-text").css("font-size","1rem");
      $(".mat-form-field-flex").css("border","2px solid #45475a");
      $("mat-label").css("color","white");
    }, 1000);
  }


  OnSubmit() {
   // this.formsubmitted = true;
    console.log('submitting the form');
    if (this.ContactForm.form.valid) {
      this.error = '';
    }

    if (!this.clientContact.emailaddress.trim())
      this.error = 'Please enter valid email';
    else if (this.clientContact.contactsupport == "")
      this.error = 'Please select a contact support';
    else if (!this.clientContact.name.trim())
      this.error = 'Please enter valid Name';
    else if (!this.clientContact.phonenumber.trim())
      this.error = 'Please enter valid phone number';
    else if (!this.clientContact.message.trim())
      this.error = 'Please enter message';


    console.log('error: '+this.error);
    console.log(this.clientContact)
    if (this.ContactForm.form.valid && this.error === '') {
      //this.ContactForm.ngSubmit.emit()
      this.sendContact()
    } else {
      Swal.fire("Oops..!", this.error)
    }
    //console.log(this.supportEmails)
  }

  sendContact() {
    this.clientContactService.sendContactInfo(this.clientContact).subscribe(
      response => {
        console.log('response')
        console.log(response)
        if (response) {
          Swal.fire({
            text: 'Your query was sent successfully. We will get back to you as soon as possible.',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/']);
              window.location.reload();
            }
          })
        }
      },
      error => {
        this.error = error;
        console.log(this.error);
        Swal.fire("OOPs..!", "error found")
      }
    );
  }

  goToRegistrationPage() {
    this.router.navigate(['/registration']);
  }
  subscribeemail() {
    console.log("RES",this.useremail);
    if(!this.useremail  || this.useremail  == "" || this.useremail  ==null){
      return;
    }
    if (!this.SubscribeForm.form.valid) {
     return;
    }
    let bodyobj = {
      UserId :"",
      UserEmail:this.useremail
    }
    this.localtionService.postNewsletterUser(bodyobj).subscribe(res => {
      this.useremail = "";
      if(res && res.length >0){
        this.toastrService.success(
          ("Successfully subscribed to our Newsletter"),
          "Success"
        );
      }else{
        this.toastrService.warning(
          ("You are already subscribed to our newsletter") + "",
          ""
        );
       
      }
      console.log("RES",res);
    }, error => {
     console.log(error)
    });
  }
  openevent(e){
    console.log("openevent",e);
    $(".mat-form-field-flex").css("border","2px solid #0c8ab5");
    $(".mat-focused.mat-form-field-flex").css("border-bottom","3px solid #0c8ab5");
    
    $("mat-label").css("color","#0c8ab5");
  }
  closeevent(e){
    console.log("closeevent",e);
    $(".mat-form-field-flex").css("border","2px solid #45475a");
    $("mat-label").css("color","white");
  }
}
