import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inventory } from './inventory';
import { Receivables } from './receivables';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { StorageRoom } from './storageRoom';
import { InventoryOrderReceivable } from './inventory'
@Injectable({
  providedIn: 'root'
})
export class InventoryService extends ApiService {
  Inventories: Inventory[] = [];
  ApiBaseUrl: string;
  BaseUrl: string;
  UomApi;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/Inventory";
  }

  getInventories(): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetByLocationId')
      .pipe(catchError(this.errorHandler));
  }
  getInventoriesSellableAndVisibleBylocationId(): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetSellableAndVissibleInvByLocationId')
      .pipe(catchError(this.errorHandler));
  }

  getReportInventories(): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetInventoryWithStorageRoomNamesBylocationId')
      .pipe(catchError(this.errorHandler));
  }

  getInventoryById(ID: string): Observable<Inventory> {
    return this.http.get<Inventory>(this.ApiBaseUrl + "/" + ID)
      .pipe(catchError(this.errorHandler));
  }

  addInventory(Inventory: Inventory): Observable<any> {
    return this.http.post(this.ApiBaseUrl, Inventory)
      .pipe(catchError(this.errorHandler));
  }

  editInventory(Inventory: Inventory): Observable<any> {
    return this.http.put(this.ApiBaseUrl + "/" + Inventory.InventoryId, Inventory)
      .pipe(catchError(this.errorHandler));
  }
  
 /* putReceivable(Inventories: Array<Inventory>): Observable<any> {
    return this.http.put(this.ApiBaseUrl + "/PutReceivables", Inventories)
      .pipe(catchError(this.errorHandler));
  }*/

  AddReceivables(Receivables: Array<Receivables>): Observable<any> {
    return this.http.post<Receivables>(this.ApiBaseUrl + "/AddReceivables", Receivables)
      .pipe(catchError(this.errorHandler));
  }

  AddReceivablesCorrection(Receivables: Array<Receivables>): Observable<any> {
    return this.http.post<Array<Receivables>>(this.ApiBaseUrl + "/AddReceivablesCorrection", Receivables);
  }

  setVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  checkInventoryUsedInConcession(inventoryId:string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiBaseUrl + '/CheckInventoryUsedInConcession/' + inventoryId)
      .pipe(catchError(this.errorHandler));
  }
  setInActiveInventoryWithConcessionAndPriceCard(inventoryId: string): Observable<Array<Inventory>> {
    return this.http.patch<Array<Inventory>>(this.ApiBaseUrl + '/SetInActiveInventoryWithConcessionAndPriceCard/' + inventoryId,null)
      .pipe(catchError(this.errorHandler));
  }

  getInventoriesbyStatus(visible:number): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetInventoriesbyStatus/' + visible)
      .pipe(catchError(this.errorHandler));
  }
 
  AddInventoryOrderList(inventoryorder: any[]): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + "/AddInventoryOrderList", inventoryorder)
      .pipe(catchError(this.errorHandler));
  }
  GetInventoryOrderPendingByLocationId(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiBaseUrl + "/GetInventoryOrderPendingByLocationId")
      .pipe(catchError(this.errorHandler));
  }

  AddReceivablesByInvenotryOrder(inventoryorder: any[]): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + "/AddReceivablesByInvenotryOrder", inventoryorder)
      .pipe(catchError(this.errorHandler));
  }
  UpdateInventories(inventory: any[]): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + "/UpdateInventoriesStockLevelAndLowCount", inventory)
      .pipe(catchError(this.errorHandler));
  }
  SendInventoryPendingOrderPDFToUserEmail(): Observable<string> {
    return this.http.get<string>(this.ApiBaseUrl + '/SendInventoryPendingOrderPDFToUserEmail')
      .pipe(catchError(this.errorHandler));
  }
  GetInventoryOrderInventoryByLocationId(): Observable<any[]> {
    return this.http.get<any[]>(this.ApiBaseUrl + "/GetInventoryOrderInventoryByLocationId")
      .pipe(catchError(this.errorHandler));
  }
  DeleteInventoryPendingOrder(inventoryorderId): Observable<any> {
    return this.http.delete<any>(this.ApiBaseUrl + "/DeleteInventoryPendingOrder/"+inventoryorderId)
      .pipe(catchError(this.errorHandler));
  }


  //storage
  AddStorageRoom(storageroom: StorageRoom): Observable<any> {
    return this.http.post<Receivables>(this.ApiBaseUrl + "/AddStorageRoom", storageroom)
      .pipe(catchError(this.errorHandler));
  }
  
  getAllStoredRoomByLocationId(): Observable<Array<StorageRoom>> {
    return this.http.get<Array<StorageRoom>>(this.ApiBaseUrl + '/GetAllStorageRoomsByLocationId')
      .pipe(catchError(this.errorHandler));
  }
  getActiveStoredRoomByLocationId(): Observable<Array<StorageRoom>> {
    return this.http.get<Array<StorageRoom>>(this.ApiBaseUrl + '/GetStorageRoomsByLocationId')
      .pipe(catchError(this.errorHandler));
  }
  setStoreRoomVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/SetStoreRoomVisible/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }
  GetInventoryStorageRoomByLocationId(): Observable<Array<InventoryOrderReceivable>> {
    return this.http.get<Array<InventoryOrderReceivable>>(this.ApiBaseUrl + '/GetInventoryStorageRoomByLocationId')
      .pipe(catchError(this.errorHandler));
  }
 
//   GetStorageRoomsByLocationId(): Observable<any>  {
//     return this.http.get<StorageRoom>(this.ApiBaseUrl + '/GetStorageRoomsByLocationId')
//     .pipe(catchError(this.errorHandler));
// }





  getInventoriesbystoreroomIdfortransfer(storageroomid:string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetInventoriesStorageRoomByLocationId/'+storageroomid)
      .pipe(catchError(this.errorHandler));
  }
  AddInventoryStorageRoom(transferInventory: any): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + "/AddInventoryInnStorageRoom", transferInventory)
      .pipe(catchError(this.errorHandler));
  }
  
  getInventoriesByStorageRoomIds(rtoreroomids:string[]): Observable<Array<Inventory>> {
    return this.http.post<Array<Inventory>>(this.ApiBaseUrl + '/GetInventoriesStorageRoomByStorageRoomId',rtoreroomids)
      .pipe(catchError(this.errorHandler));
  }

  getActiveInventoriesStorageRoomByStorageRoomId(storageroomId: string): Observable<Array<Inventory>> {
    return this.http.get<Array<Inventory>>(this.ApiBaseUrl + '/GetActiveInventoriesStorageRoomByStorageRoomId/' + storageroomId)
      .pipe(catchError(this.errorHandler));
  }

  getInventoryStorageRoomByStorageRoomIdInventoryId(inventoryId:string,rtoreroomids:string[]): Observable<Inventory> {
    return this.http.post<Inventory>(this.ApiBaseUrl + '/GetInventoryStorageRoomByStorageRoomIdInventoryId/'+inventoryId,rtoreroomids)
      .pipe(catchError(this.errorHandler));
  }
  GetInventoryStorageRoomNamesByStorageRoomIdInventoryId(inventoryId:string,rtoreroomids:string[]): Observable<string> {
    return this.http.post<string>(this.ApiBaseUrl + '/GetInventoryStorageRoomNamesByStorageRoomIdInventoryId/'+inventoryId,rtoreroomids)
      .pipe(catchError(this.errorHandler));
  }
  checkinvetorystoredinroom(streroomId:string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiBaseUrl + '/CheckStorageRoomHadItems/' + streroomId)
      .pipe(catchError(this.errorHandler));
  }
  GetInventoryStorageRoomByInventoryId(inventoryId: string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetInventoryStorageRoomByInventoryId/' + inventoryId )
      .pipe(catchError(this.errorHandler));
  }

  GetConcessionsByInvenotryId(inventoryId: string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetConcessionsByInvenotryId/' + inventoryId)
      .pipe(catchError(this.errorHandler));
  }
  UpdateUOM(uommodel: any): Observable<any> {
    return this.http.patch<any>(this.ApiBaseUrl + '/UpdateUOM', uommodel)
      .pipe(catchError(this.errorHandler));
  }
}
