import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { PlanOrder } from '../models/PlanOrder';
import { PlanSales } from '../models/PlanSales';


@Injectable({
  providedIn: 'root'
})
export class PlanorderService extends ApiService {
  ApiBaseUrl: string;
  httpBackedurl: HttpClient;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/PlanOrder";
    this.ApiEndPoint = baseUrl + "api";
  }
 
  GetPlanOrder(companyId: string, locationId: string): Observable<PlanOrder> {
    return this.http.get<PlanOrder>(this.ApiEndPoint + "/PlanOrder/GetPlanOrder/" + companyId + "/" + locationId).pipe(catchError(this.errorHandler));
  }
  GetPlanSales(planSalesId: string): Observable<PlanSales> {
    return this.http.get<PlanSales>(this.ApiEndPoint + "/PlanOrder/GetPlanSales/" + planSalesId).pipe(catchError(this.errorHandler));
  }
  
}
