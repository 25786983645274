import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SalesOrder, SalesOrderConcession, SalesOrderTicket, SalesAdditionalCharges, SalesOrderGiftCard, SalesSplit, WorldpayTransactionDetails, PaymentMode } from '../models/SalesOrder';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { GiftcardService } from '../../giftcard/giftcard.service';
import { GiftCardSales } from '../../giftcard/GiftCardSales';
import { ScreenService } from '../../screens/screen.service';
import { AdditionalCharges } from '../../price-card/price-card';
import { Table } from 'src/app/table/tablemodel';
import { Seat, Screen } from "../../screens/screen";
import { Location } from '../../locations/location';
import { ParkingLotOrder } from '../models/ParkingLotOrder';
@Injectable({
  providedIn: 'root'
})

export class PrintService {

  private isPrintingObservable = new BehaviorSubject(false);
  isPrinting = this.isPrintingObservable.asObservable();

  printModeEnabled: boolean = false;
  public RefundSO: Array<SalesOrder> = [];
  public RefundSParkO: Array<ParkingLotOrder> = [];
  printGCS: GiftCardSales = null;
  countDown: Subscription;
  isDineInOrder: boolean = false;
  tick = 5000;
  kitchenOrderPrint: any = null;
  table: Table = null;
  qrData: any = null;

  locationqrflag: boolean;
  screenqrflag: boolean;
  seatqrflag: boolean;

  QRC: boolean = false;
  printHallName: any = null;
  printDisplayHallName: any = null;
  printdisplayTableNumber: any = null;
  printTableId: any = null;
  tableqrFlag: boolean;
  HallTableDetailList: any = null;

  public tableItemsList = [];

  public printAllTable: any = null;


  public printScreenid: Screen['Screen_ID'] = null;
  public printScreenidSeat: Screen['Screen_ID'] = null;


  public printScreenname: Screen['Screen_Name'] = null;
  public printAllScreenIds: Screen['Screen_ID'][] = [];
  public printAllScreen: Screen[] = [];
  public printSeatid: Seat['SeatNumber'] = null;
  public printAllSeats: Seat['SeatNumber'][] = null;
  public printSeatScreenName: Screen['Screen_Name'] = null;
  public printSeatScreenId: Screen['Screen_ID'] = null;
  public printlocation: Location['Location_ID'] = null;
  public seatItemsList = [];
  isparkingPrinting: boolean = false;
  constructor(private router: Router, private GCS: GiftcardService, private SCR: ScreenService) { }

  print() { }

  ngOnInit() {
    this.countDown = timer(5000, this.tick)
      .subscribe(() => {
        this.router.navigate([{ outlets: { print: null } }]).then(r => {
          this.isPrintingObservable.next(false);
        });
      }
      );
  }

  printDocument(documentName: string, documentData: string[] = null): Observable<boolean> {
    this.printModeEnabled = true;
    this.isPrintingObservable.next(true);
    let outlt = ['print', documentName];
    if (documentData)
      outlt.push(documentData.join());
    this.router.navigate([{
      outlets: {
        'print': outlt
      }
    }
    ]);
    return this.isPrinting;
  }


  onDataReady() {
    setTimeout(() => {
      // window.print();
      window.print();
      this.router.navigate([{ outlets: { print: null } }]).then(r => {
        this.isPrintingObservable.next(false);
        this.printModeEnabled = false;
        this.locationqrflag = false;
        this.screenqrflag = false;
        this.seatqrflag = false;
        this.kitchenOrderPrint = null;
        this.printlocation = null;
        this.printAllScreenIds = [];
        this.seatItemsList = [];
        this.printAllSeats = [];
        this.printScreenid = null;
        this.printScreenname = null;
        this.printSeatid = null;

        this.printHallName = null;
        this.printTableId = null;
        this.tableqrFlag = false;
        this.tableItemsList = [];
        this.printAllTable = null;
        $('body').attr('style', 'overflow:inherit !important');
        $('.ticket').attr('style', 'overflow:inherit !important');
      });
    }, 2000);
  }
  async onDataReadyWithoutClear() {
    setTimeout(() => {
      window.print();
      this.isPrintingObservable.next(false);
      this.printModeEnabled = false;
      this.kitchenOrderPrint = null;
      $('.ticket').attr('style', 'overflow:inherit !important');
      return true;
    }, 2000);
  }


  onDataFailed() {
    setTimeout(() => {
      this.router.navigate([{ outlets: { print: null } }]).then(r => {
        this.isPrintingObservable.next(false);
        $('body').attr('style', 'overflow:inherit !important');

      });;
    });
  }
}

export interface MovieTicket {
  TicketType: SalesOrderTicket,
  Seats: string[],
  Date: string,
  Tax: number,
  Amount: number,
}

export interface Receipt {
  Concessions: Array<SalesOrderConcession>,
  Tickets: Array<MovieTicket>,
  AdditionalCharges: Array<SalesAdditionalCharges>
  finalAmount?: number,
  CfmCode?: string,
  GiftCardSale: Array<SalesOrderGiftCard>,
  PaymentMode: PaymentMode,
  CCLastFour: string,
  MerchantName: string,
  WorldpayTransactionDetails: WorldpayTransactionDetails,
  SalesSplit: Array<SalesSplit>,
  UniqueOrderNumber: any,
  ActualAmount?: number,
  RefundLoyaltyPoints?: number,
  TotalRefund?: number,
  RefundActualPrice?: number
}

