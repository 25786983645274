import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { MovieScheduleVM, MovieScheduleVM_DummyData } from '../viewmodels/KioskPosVM';
import * as moment from 'moment';
import { CommonService } from 'src/app/helper/services/common.service';
import { LoaderService } from 'src/app/helper/services/loader/loader.service';
import Swal from 'sweetalert2';
import { SalesorderService } from 'src/app/helper/services/salesorder.service';
import { POSService } from 'src/app/pos/pos.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';
import { MovieService } from 'src/app/movies/movie.service';

@Component({
  selector: 'app-kiosk-show-time',
  templateUrl: './kiosk-show-time.component.html',
  styleUrls: ['./kiosk-show-time.component.css']
})

export class KioskShowTimeComponent implements OnInit, AfterViewInit {

  public movieScheduleVM_DummyData: MovieScheduleVM_DummyData = null;
  public movieData: any = [];
  public moviewScreenData: any = {};
  public showData: any;
  public displayShows = [];
  currentTime: Date;
  locDate: Date;
  loading = true;
  movie: any;

  constructor(public kioskPOSService: KioskPOSService,
    private loaderService: LoaderService,
    private PosService: POSService,private MovServ: MovieService,
    private commonServ: CommonService, private router: Router,private es:ErrorMessagesService) {
    this.movie = this.kioskPOSService.getMovie();
  }
  ngAfterViewInit(): void {
    this.loaderService.show();
     this.MovServ.getAllMovieRatings().subscribe(res => {
      this.kioskPOSService.Ratings = res;
       
      });
    this.kioskPOSService.getLocationMoviesData(false).then(res => {
      this.movieData = res;
      this.getScreensDataForMovie();
    }).catch(res => {
      this.loaderService.hide();
      Swal.fire(this.es.kioskErrorMsgList[35].message, this.es.kioskErrorMsgList[37].message, 'error')
    });
  }

  Shows = {};
  objectKeys = Object.keys;
  ngOnInit() {
  }

  public getScreensDataForMovie() {
    var $this = this;
    let showIds: Array<string> = new Array<string>();

    this.moviewScreenData = this.movieData.find(item => item.LocationId == $this.kioskPOSService.getLocationId() && item.MovieID == $this.kioskPOSService.getMovieId());
    let showCount = 0;
    if (!this.moviewScreenData) {
      this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
      this.loaderService.hide();
      return;
    }
    this.moviewScreenData.Screens.forEach((item, index) => {
      item.ScreenInfo = (item.ScreenInfo && item.ScreenInfo.length > 0) ? item.ScreenInfo : ['2D'];
      showCount += item.ShowTimes.length;

      item.ShowTimes?.forEach(f => {
        let Information = f.ScreenInfo?.join(' ') || '2D';
        if (!this.Shows[Information])
          this.Shows[Information] = [];
        this.commonServ.GetDateTimeByTimeZone().subscribe(dt => {
          this.locDate = new Date(dt);
          let curDate = new Date(moment(this.locDate).format("YYYY-MM-DD HH:mm"));
          curDate.setMinutes(curDate.getMinutes() - 15);
          let curTimeStamp = moment(curDate);
          let showTimeStamp = moment(f.ShowTime);
          if (showTimeStamp >= curTimeStamp) {
            f.Disabled = false;
          } else {
            f.Disabled = true;
          }
        });
        if (!f.Disabled) {
          showIds.push(f.ShowID);
        }
        f.ShowProperties = f.ShowProperties;
        this.Shows[Information].push(f);
      })
    });

    for (let info in this.Shows) {
      this.Shows[info].sort((a, b) => {
        return moment(a.ShowTime).diff(b.ShowTime, 'minutes');
      })
    }
    this.PosService.postGetSeatsCounts(showIds).subscribe(res => {
      let ticketCountByShowId: any = res;
      for (let inf in this.Shows) {
        for (let show of this.Shows[inf]) {
          var tk_c = ticketCountByShowId.find(f => f.ShowId == show.ShowID);
          if (tk_c) {
            var count = Number(tk_c.AvailableSeats) - (tk_c.TicketsSold + tk_c.OnHold);
            show.Soldout = count <= 0 ? true : false;
            show.availableseatcount = count;
          }
        }
      }
      this.loading = false;
      this.loaderService.hide();
    }, err => {
      this.loaderService.hide();
      Swal.fire(this.es.kioskErrorMsgList[21].message, err, 'error')
    });
    if (showCount == 0) {
      this.router.navigate(['/kiosk/movie-list/' + this.kioskPOSService.getLocationId()]);
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[62].message, 'close');
    }
  }

  public selectShow(show) {
    this.kioskPOSService.unsetFinalData(['ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'IsFoodItemsAvailable', 'FoodItems']);
    this.showData = show;
    this.kioskPOSService.setShowId(show.ShowID);
    this.kioskPOSService.setShowTime(show.ShowTime);
    this.kioskPOSService.setScreenId(show.ScreenID);
    this.kioskPOSService.setShowProperties(show.ShowProperties);
    this.kioskPOSService.setAvailableSeats(show.availableseatcount);
    let Seating = (show.Seating !== null && show.Seating !== undefined && show.Seating != '' && show.Seating.toLowerCase() == 'open' ? 'open' : 'reserved');
    this.kioskPOSService.setSeating(Seating);
    if (show.PriceCardId && show.PriceCardId !== null && show.PriceCardId != undefined && show.PriceCardId != '') {
      this.kioskPOSService.setPriceCardId(show.PriceCardId);
      this.addTickets();
    }
    else {
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[63].message, 'close');
    }
  }

  public addTickets() {
    if (this.kioskPOSService.getShowId() != '') {
      this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()], {});
      return false;
    }
    else {
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[64].message, 'close');
    }
  }
}
