import { InventoryService } from './../../inventory/inventory.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { TaxService } from 'src/app/tax/Tax.service';
import { Tax } from 'src/app/tax/Tax';
import { ConcessionService } from '../../concession/concession.service';
import Swal from 'sweetalert2';
import { LoaderService } from '../../helper/services/loader/loader.service';
import { CloneObject } from 'src/app/helper/models/AddHelperModal';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-food-packs',
  templateUrl: './kiosk-food-packs.component.html',
  styleUrls: ['./kiosk-food-packs.component.css']
})
export class KioskFoodPacksComponent implements OnInit {

  private subscribed: any = [];
  public foodData: any = [];
  public activeCategory = '';
  selectedFoodData = new selectedFoodData(0, []);
  public moviewScreenData: any = {};
  public FinalFoodItemData: any = { selectedFoodItems: [], TotalPrice: 0, TotalFoodItems: 0 };
  public Taxes: Array<Tax>;
  public loadFoods: boolean = false;
  //  public errorList=errorMsgList; 
  constructor(public router: Router, public kioskPOSService: KioskPOSService,
    private concessionService: ConcessionService, private TaxService: TaxService, private inventoryService: InventoryService, private loaderService: LoaderService,
    private es:ErrorMessagesService) {
    this.getFoodData();
    this.getScreensDataForMovie();
  }

  ngOnInit() {
  
    
  }

  ngOnDestroy() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  public getScreensDataForMovie() {
    this.kioskPOSService.movieData.forEach((item, index) => {
      if (item.LocationId == this.kioskPOSService.getLocationId() && item.MovieID == this.kioskPOSService.getMovieId()) {
        this.moviewScreenData = item;
      }
    });
  }

  public getFoodData() {
    this.TaxService.getTaxsByLocationId().subscribe(res => {
      this.Taxes = res.filter(f => f.Visible == 1);
      this.loaderService.show();
      let getFoodData = this.concessionService.getKioskConcessionByLocationId().subscribe((response) => {
        if (response.length > 0) {
          this.foodData = response.filter(x => x.ConcessionCategory != 'addon');
          this.kioskPOSService.setIsFoodItemsAvailable('y');
          this.activeCategory = this.foodData[0].ConcessionCategory + '0';

          this.selectedFoodData.selectedFoods = [];
          this.foodData.forEach((cItem, cIndex) => {
            this.selectedFoodData.selectedFoods[cIndex] = [];
            cItem.ConcessionDetails.forEach((fItem, fIndex) => {
              fItem.FoodTotalPrice = 0;
              fItem.Qty = 0;
              fItem.cIndex = cIndex;
              fItem.IsPriceCardCombo = 'n';
              fItem.PriceCardComboQty = 0;
              // if(fItem.ConcessionCategory == 'drink') {
              //     this.inventoryService.getInventoryById(fItem.ParentType['Inventory'][0].InventoryId).subscribe(data => {
              //         fItem.TotalInvtoryQty = data.TotalQuantityInHand;
              //     });
              // }
              fItem.TotalPrice = Number(fItem.TotalPrice.toFixed(2));
              fItem.Tax = this.Taxes.find(f => f.TaxId === fItem.TaxId)?.Percent || 0;
              fItem.PriceCardComboUnitPrice = Number(fItem.TotalPrice.toFixed(2));
              fItem.TaxAmount = Number(((fItem.TotalPrice * fItem.Tax) / 100).toFixed(2));
              let getFoodItems = this.kioskPOSService.getFoodItems();
              if (getFoodItems.length > 0) {
                getFoodItems.forEach((ii, i) => {
                  if (fItem.ConcessionId == ii.ConcessionId) {
                    fItem.Qty += ii.Qty;
                    fItem.PriceCardComboQty += ii.PriceCardComboQty;
                    if (ii.PriceCardComboQty > 0) {
                      fItem.PriceCardComboUnitPrice = ii.PriceCardComboUnitPrice;
                    }
                    fItem.IsPriceCardCombo = ii.IsPriceCardCombo;
                    fItem.ComboName = ii.ComboName;
                    fItem.ComboID = ii.ComboID;
                    if (ii.ComboTotalPrice)
                      fItem.ComboTotalPrice = Number(ii.ComboTotalPrice.toFixed(2));
                    fItem.TaxAmount = Number(((fItem.TotalPrice * fItem.Tax) / 100).toFixed(2));
                    fItem.FoodTotalPrice += ((fItem.Qty * (fItem.TotalPrice)));


                  }
                });
              }
              // if ((fItem.ConcessionCategory == "drink" || fItem.ConcessionCategory == "snack") && fItem.TotalInventoryQty < 1) {

              // }
              // else
              this.selectedFoodData.selectedFoods[cIndex].push(fItem);

            });
          });
          this.calculateTotalPriceForFoodItems();
          this.loadFoods = true;
          this.loaderService.hide();
        }
        else {
          this.kioskPOSService.setIsFoodItemsAvailable('n');
          this.router.navigate(['/kiosk/checkout/' + this.kioskPOSService.getLocationId()]);
          return false;
        }
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
        this.router.navigate(['/kiosk/checkout/' + this.kioskPOSService.getLocationId()]);
        return false;
      });
      this.subscribed.push(getFoodData);
    }, (error) => {
      console.log(error);
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
      this.router.navigate(['/kiosk/checkout/' + this.kioskPOSService.getLocationId()]);
      return false;
    });
  }

  public minusFoodItem(foodItem: any, categoryIndex: number = 0, foodIndex: number = 0) {
    if (this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty > 0) {
      if (this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty > this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].PriceCardComboQty) {
        this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty--;
        this.calculateTotalPriceForFoodItems();
      }
      else {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[61].message, 'close');
      }
    }
    else {
      this.selectedFoodData.selectedFoods.totalPrice = 0;
      // this.kioskPOSService.showSnackBar('Minimum value should zero!', 'close');
    }
  }

  public plusFoodItem(foodItem: any, categoryIndex: number = 0, foodIndex: number = 0) {
    if (this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty < 10) {
      if ((foodItem.ConcessionCategory == "drink" || foodItem.ConcessionCategory == "snack" || foodItem.ConcessionCategory == "combo") && this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].TotalInventoryQty == 0) {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[2].message, 'close');
      } else if ((foodItem.ConcessionCategory == "drink" || foodItem.ConcessionCategory == "snack" || foodItem.ConcessionCategory == "combo") && (this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty + 1) > this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].TotalInventoryQty) {
        // this.kioskPOSService.showSnackBar('Inventory for this item is out of stock!', 'close');
        this.kioskPOSService.showSnackBar("Only " + this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].TotalInventoryQty + this.es.kioskErrorMsgList[10].message, 'close');

      }
      else {
        this.selectedFoodData.selectedFoods[categoryIndex][foodIndex].Qty++;
      }
    }
    else {
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[1].message, 'close');
    }
    this.calculateTotalPriceForFoodItems();
  }

  public calculateTotalPriceForFoodItems() {
    this.selectedFoodData.totalPrice = 0;
    this.FinalFoodItemData.selectedFoodItems = [];
    this.FinalFoodItemData.TotalFoodItems = 0;
    this.FinalFoodItemData.TotalPrice = 0;
    this.selectedFoodData.selectedFoods.forEach((cItem, cIndex) => {
      this.selectedFoodData.selectedFoods[cIndex].forEach((fItem, fIndex) => {
        if (fItem.Qty > 0) {
          console.log("fItem", fItem);
          fItem.FoodTotalPrice = 0;
          fItem.FoodTotalPrice = (parseFloat(fItem.TotalPrice) * parseFloat(fItem.Qty));
          this.selectedFoodData.totalPrice += (parseFloat(fItem.TotalPrice) * parseFloat(fItem.Qty));
          this.FinalFoodItemData.TotalFoodItems = parseInt(this.FinalFoodItemData.TotalFoodItems) + parseInt(fItem.Qty);
          this.FinalFoodItemData.selectedFoodItems.push(fItem);
        }
      });
    });
    console.log(" this.selectedFoodData.totalPrice", this.selectedFoodData.totalPrice);
    this.FinalFoodItemData.TotalPrice = this.selectedFoodData.totalPrice;
  }

  public backButton(e) {
    if (this.kioskPOSService.getSeating() == 'open') {
      this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
    }
    else {
      this.router.navigate(['/kiosk/select-seats/' + this.kioskPOSService.getLocationId()]);
      this.removeAllHolds();
    }
  }
  public removeAllHolds() {
    if (this.kioskPOSService.userHoldSeats.length > 0) {
      let body = [];
      this.kioskPOSService.userHoldSeats.forEach((i, index) => {
        body.push(i.HoldSeatId);
      });
      console.log(body);
      this.kioskPOSService.SubmitPostFormData('HoldSeats/DeleteHoldSeats', body).subscribe((response) => {
        this.kioskPOSService.userHoldSeats = [];
      });

    }
  }
  public checkPriceCardFoodItem(foodItem: any, categoryIndex: number = 0, foodIndex: number = 0) {
    let boolean: boolean = true;
    /*let  FoodItems = this.kioskPOSService.getFoodItems();
    if(FoodItems.length > 0){
        for(var i = 0; i <= (FoodItems.length - 1); i++){
            if(FoodItems[i].ConcessionId == foodItem.ConcessionId && FoodItems[i].IsPriceCardCombo == 'y'){
                boolean = false;
            }
        }
    }*/
    return boolean;
  }

  public addFoodItemsForShow(e) {
    // text: items + isAre + "out of stock in the inventory",
    let clonefi = CloneObject(this.FinalFoodItemData.selectedFoodItems)
    this.kioskPOSService
      .checkConcessionInventory(clonefi)
      .subscribe(
        (res: any) => {
          if (res.length > 0) {
            // let isAre = res.length == 1 ? " is " : " are ";
            // let items = res.join(", ");
            // Swal.fire({
            //   title: "OOPS..!",
            //   text: "You can pick only " + res.length + " " + items + " now.  Alter your selection accordingly ",
            //   showCancelButton: false,
            //   type: "warning",
            // });
            let items = res.join(", ");
            console.log("items", items)
            let scentence = [];
            for (var i = 0; i <= (res.length - 1); i++) {
              scentence.push(res[i])
            }
            let fullScentence = '';
            scentence.forEach((f, i) => {
              f = f.split('_')
              fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
            });
            Swal.fire({
              title: this.es.kioskErrorMsgList[32].message,
              text:this.es.kioskErrorMsgList[33].message + fullScentence + this.es.kioskErrorMsgList[34].message,
              showCancelButton: false,
              type: "warning",
            });
            // this.router.navigate([
            //   "/kiosk/add-food-packs/" +
            //   this.kioskPOSService.getLocationId(),
            // ]);
          } else {
            this.kioskPOSService.setFoodItems(this.FinalFoodItemData);
            this.router.navigate(['/kiosk/checkout/' + this.kioskPOSService.getLocationId()]);
          }
        });
  }
}

export class selectedFoodData {
  constructor(
    public totalPrice: number = 0,
    public selectedFoods: any = []
  ) { }
}

