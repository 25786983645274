import { Injectable, Inject } from '@angular/core';
import { GiftCard } from './giftcard';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { GiftCardSales } from './GiftCardSales';
import { ApiService } from '../helper/services/api.service';
import { catchError } from 'rxjs/operators';
import { SalesOrder } from '../helper/models/SalesOrder';
import { Paging } from './list/paging';
import { GiftCardPaging } from './list/giftcardpaging';
import { GiftCardSalesPaging } from './giftcardtransaction/giftcardsalespaging';

@Injectable({
  providedIn: 'root'
})
export class GiftcardService extends ApiService{
  ApiBaseUrl: string;
  giftCardObservable = new BehaviorSubject<GiftCardSales[]>([]);

  giftCard = this.giftCardObservable.asObservable();
  totalPriceObservable = new BehaviorSubject(0);
  totalPrice = this.totalPriceObservable.asObservable();
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api';
  }
  public getQr(ID: String): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/Qr/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  /*async getGiftCards(): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return await this.http
      .get<GiftCard[]>(this.ApiBaseUrl + "/GiftCard/GetByCompanyId/all?pageNumber=1&pageSize=50", httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }*/


  getGiftCards(paging: Paging): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<GiftCardPaging>(this.ApiBaseUrl + "/GiftCard/GetByCompanyId/all", paging, httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }

  async getGiftCardSales(companyid: string, paging: Paging): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return await this.http
      .post<GiftCardSalesPaging>(this.ApiBaseUrl + "/GiftCardSales/GetByCompanyId", paging, httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }

  async getGiftCard(id: string): Promise<any> {
    return await this.http
      .get<GiftCard>(this.ApiBaseUrl + "/GiftCard/" + id)
      .pipe(catchError(this.errorHandler)).toPromise();
  }
 /* async getGiftCardSales(companyid: string): Promise<any> {
    return await this.http
      .get<GiftCardSales[]>(this.ApiBaseUrl + "/GiftCardSales/GetByCompanyId")
      .pipe(catchError(this.errorHandler)).toPromise();
  }*/
  async getGiftCardSalesbyGiftCardId(giftcardid: string): Promise<any> {
    return await this.http
      .get<GiftCardSales[]>(this.ApiBaseUrl + "/GiftCardSales/GetByGiftCardId/" + giftcardid)
      .pipe(catchError(this.errorHandler)).toPromise();
  }
  async getGiftCardbyCardNumber(cardnumber: string): Promise<any> {
    return await this.http.get<GiftCard>(this.ApiBaseUrl + "/GiftCard/GetByCardNumber/" + cardnumber).pipe(catchError(this.errorHandler)).toPromise();
  }
  async addGiftCardSales(item: GiftCardSales): Promise<any> {
    return await this.http.post<GiftCardSales>(this.ApiBaseUrl + "/GiftCardSales", item).pipe(catchError(this.errorHandler)).toPromise();
  }
  async patchGiftCard(cardnumber: string, amount: number): Promise<any> {
    return await this.http.patch<boolean>(this.ApiBaseUrl + "/GiftCard/DebitGiftCard/"+cardnumber+"/"+amount,{}).pipe(catchError(this.errorHandler)).toPromise();
  }

  async patchGiftCardRefund(cardnumber: string, amount: number): Promise<any> {
    return await this.http.patch<boolean>(this.ApiBaseUrl + "/GiftCard/CreditGiftCard/"+cardnumber+"/"+amount,{}).pipe(catchError(this.errorHandler)).toPromise();
  }

  async addGiftCard(item: Array<GiftCard>): Promise<any> {
    return await this.http.post<GiftCard>(this.ApiBaseUrl + "/GiftCard", item).pipe(catchError(this.errorHandler)).toPromise();
  }
  async addNewGiftCard(item: GiftCard): Promise<any> {
    return await this.http.post<GiftCard>(this.ApiBaseUrl + "/GiftCard/AddNewGiftCard", item).pipe(catchError(this.errorHandler)).toPromise();
  }

  GiftCardToCart(postCard: GiftCardSales, operation: string) {
    let existingCards = [], totalPrice = 0;
    this.giftCard.subscribe(success => existingCards = success);
    this.totalPrice.subscribe(success => totalPrice = success);
    if (operation === 'plus') {
      existingCards.push(postCard)
      totalPrice += Number(postCard.Amount);
    } else {
      existingCards = existingCards.filter(ec => (ec.CardNumber).toString() !== (postCard.CardNumber).toString());
      totalPrice -= Number(postCard.Amount);
    }
    this.giftCardObservable.next(existingCards);
    this.totalPriceObservable.next(totalPrice);
  }  
  GiftCardToCartWithoutPrice(postCard: GiftCardSales, operation: string) {
    let existingCards = [];
    this.giftCard.subscribe(success => existingCards = success);
    if (operation === 'plus') {
      existingCards.push(postCard)
    } else {
      existingCards = existingCards.filter(ec => ec.CardNumber !== postCard.CardNumber);
    }
    this.giftCardObservable.next(existingCards);
  }  
}
