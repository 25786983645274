import { Concession } from 'src/app/concession/concession';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpParams, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ApiService } from '../../helper/services/api.service';
import { SalesOrder, SalesStatus } from '../../helper/models/SalesOrder';
import { FileUploadService } from '../../helper/services/file-upload.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';
import { MovieMovieRating } from 'src/app/movies/movie';
import { ParkingLotOrder } from '../../helper/models/ParkingLotOrder';

@Injectable({
  providedIn: 'root'
})
export class KioskPOSService extends ApiService {
  locationId: string = null;
  pickupTicketData: any;
  private headers: HttpHeaders;
  private options: any;
  API: string;
  httpBackedurl: HttpClient;
  movieData: any = [];
  constants: any = [];
  ShowTime: string;
  userHoldSeats = [];
  Ratings:MovieMovieRating[]=[]
  isSwipeReaderExist = false;
  currentMerchantDetails: any;
  selectedMerchant: string;
  finalSelectedMovieData: any = {
    LocationMovieDate: '',
    LocationId: '',
    MovieId: '',
    PriceCardId: '',
    ScreenId: '',
    ShowId: '',
    Tax: '',
    Seats: [],
    ShowProperties: [],
    Seating: SalesStatus.Reserved,
    Tickets: { Tickets: [], TotalPrice: 0, TotalTickets: 0, AdditionalCharges: [] },
    IsFoodItemsAvailable: 'y',
    FoodItems: { selectedFoodItems: [], TotalPrice: 0, TotalFoodItems: 0 },
  };
  public salesOrderData: any = [];
  ApiEndPoint: string;

  ApiParkingEndPoint: string;

  async load(): Promise<any> {

    ((sessionStorage.getItem("LocationMovieDate") && sessionStorage.getItem("LocationMovieDate") !== null && sessionStorage.getItem("LocationMovieDate") !== undefined && sessionStorage.getItem("LocationMovieDate") != '')) ? this.setLocationMovieDate(sessionStorage.getItem("LocationMovieDate")) : '';
    ((sessionStorage.getItem("LocationId") && sessionStorage.getItem("LocationId") !== null && sessionStorage.getItem("LocationId") !== undefined && sessionStorage.getItem("LocationId") != '')) ? this.setLocationId(sessionStorage.getItem("LocationId")) : '';
    ((sessionStorage.getItem("MovieId") && sessionStorage.getItem("MovieId") !== null && sessionStorage.getItem("MovieId") !== undefined && sessionStorage.getItem("MovieId") != '')) ? this.setMovieId(sessionStorage.getItem("MovieId")) : '';
    ((sessionStorage.getItem("PriceCardId") && sessionStorage.getItem("PriceCardId") !== null && sessionStorage.getItem("PriceCardId") !== undefined && sessionStorage.getItem("PriceCardId") != '')) ? this.setPriceCardId(sessionStorage.getItem("PriceCardId")) : '';
    ((sessionStorage.getItem("ScreenId") && sessionStorage.getItem("ScreenId") !== null && sessionStorage.getItem("ScreenId") !== undefined && sessionStorage.getItem("ScreenId") != '')) ? this.setScreenId(sessionStorage.getItem("ScreenId")) : '';
    ((sessionStorage.getItem("ShowId") && sessionStorage.getItem("ShowId") !== null && sessionStorage.getItem("ShowId") !== undefined && sessionStorage.getItem("ShowId") != '')) ? this.setShowId(sessionStorage.getItem("ShowId")) : '';
    ((sessionStorage.getItem("ShowTime") && sessionStorage.getItem("ShowTime") !== null && sessionStorage.getItem("ShowTime") !== undefined && sessionStorage.getItem("ShowTime") != '')) ? this.setShowTime(sessionStorage.getItem("ShowTime")) : '';
    /*((sessionStorage.getItem("Tickets") && sessionStorage.getItem("Tickets") !== null && sessionStorage.getItem("Tickets") !== undefined && sessionStorage.getItem("Tickets") != '')) ? this.setTickets(sessionStorage.getItem("Tickets")) : {Tickets : [],TotalPrice : 0,TotalTickets : 0};*/
    var TotalTickets = sessionStorage.getItem("TotalTickets");
    var TotalPrice = sessionStorage.getItem("TotalPrice");
    if ((TotalTickets && TotalTickets !== null && TotalTickets !== undefined && parseFloat(TotalTickets) > 0) && (TotalPrice && TotalPrice !== null && TotalPrice !== undefined && parseFloat(TotalPrice) > 0)) {
      this.setTickets({ Tickets: [], TotalPrice: 0, TotalTickets: 0, AdditionalCharges: [] });
    }
    else {
      this.setTickets({ Tickets: [], TotalPrice: 0, TotalTickets: 0, AdditionalCharges: [] });
    }

    var TotalFoodItems = sessionStorage.getItem("TotalFoodItems");
    var TotalPrice = sessionStorage.getItem("FoodItemsTotalPrice");
    if ((TotalFoodItems && TotalFoodItems !== null && TotalFoodItems !== undefined && parseFloat(TotalFoodItems) > 0) && (TotalPrice && TotalPrice !== null && TotalPrice !== undefined && parseFloat(TotalPrice) > 0)) {
      this.setFoodItems({ selectedFoodItems: [], TotalPrice: 0, TotalFoodItems: 0 });
    }
    else {
      this.setFoodItems({ selectedFoodItems: [], TotalPrice: 0, TotalTickets: 0 });
    }
    ((sessionStorage.getItem("Seating") && sessionStorage.getItem("Seating") !== null && sessionStorage.getItem("Seating") !== undefined && sessionStorage.getItem("Seating") != '')) ? this.setSeating(sessionStorage.getItem("Seating")) : '';
    ((sessionStorage.getItem("isFoodItemsAvailable") && sessionStorage.getItem("isFoodItemsAvailable") !== null && sessionStorage.getItem("isFoodItemsAvailable") !== undefined && sessionStorage.getItem("isFoodItemsAvailable") != '')) ? this.setIsFoodItemsAvailable(sessionStorage.getItem("isFoodItemsAvailable")) : '';
    ((sessionStorage.getItem("Seats") && sessionStorage.getItem("Seats") !== null && sessionStorage.getItem("Seats") !== undefined && sessionStorage.getItem("Seats") != '')) ? this.setSeats(sessionStorage.getItem("Seats").split(",")) : '';

    /*this.generateRequestHeaders();
    if (this.getLocationId() != '') {
        let promise = new Promise((resolve, reject) => {
            this.httpClient.get(this.API+ 'Kiosk/' + this.getLocationId(),this.options)
            .toPromise().then((res:any)=>{this.movieData = (res != "[]" && res.length > 0 ? res : []);resolve();}),catchError((error:any)=>this.handleError(error));
        });
        return promise;
    }*/
  }

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') apiUrl: string, public snackBarRef: MatSnackBar,private es:ErrorMessagesService, @Inject("BASE_URL") base: string, private http: HttpClient, private backend: HttpBackend) {
    super(base);
    this.API = base + 'api/';
    this.constants = {
      apiUrl: base + 'api/',
      siteUrl: base,
      siteName: environment.siteName,
      siteLogo: environment.siteLogo
    };
    this.ApiEndPoint = base + "api/SalesOrder";
    this.httpBackedurl = new HttpClient(backend);
    this.ApiParkingEndPoint = base + "api/ParkingLotOrder";
  }

  /*Common Api Functions*/

  public generateRequestHeaders(headers_para: object[] = [], params_para: object = {}, reportprogress_para: boolean = false, responsetype_para: string = 'json', withCredentials_para: boolean = false) {
    this.headers = new HttpHeaders();
    this.headers.set('Accept', 'application/json');
    this.headers.set('Cache-Control', 'no-cache');
    this.headers.set('Access-Control-Allow-Origin', '*');
    this.headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    this.headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    /*if(sessionStorage.getItem('login_trader_user_token') && sessionStorage.getItem('login_trader_user_token') !== null && sessionStorage.getItem('login_trader_user_token') !== undefined && sessionStorage.getItem('login_trader_user_token') != ''){
        this.headers.append('token',sessionStorage.getItem('login_trader_user_token'));
    }
    this.headers.append('Call-Type','API_CALL');*/
    if (headers_para.length > 0) {
      headers_para.forEach((item: any, index) => {
        if (this.headers.has(item.name)) {
          this.headers.set(item.name, item.value);
        }
        else {
          this.headers.set(item.name, item.value);
        }
      });
    }
    params_para = (this.isEmptyObject(params_para) == 0) ? null : params_para;
    this.options = { headers: this.headers, observe: "response", params: params_para, reportProgress: reportprogress_para, responseType: responsetype_para, withCredentials: withCredentials_para };
    return this.options;
  }
  public getShowProperties() {
    var show_id = this.finalSelectedMovieData?.ShowProperties
      ? this.finalSelectedMovieData.ShowProperties
      : [];
    return show_id;
  }
  public setShowProperties(properties) {
    this.finalSelectedMovieData.ShowProperties = properties;
    sessionStorage.setItem('ShowProperties', properties);
  }
  public SubmitPostFormData(url: string, body: any, options: any = null) {
    return this.httpClient.post<Observable<any>>(this.API + url, body, (options === null ? this.options : options))
      .pipe(map((res: HttpResponse<any>) => { return this.extractData(res) }), catchError((error: any) => this.handleError(error)));
  }

  public SubmitGetFormData(url: string, options: any = null) {
    return this.httpClient.get<Observable<any>>(this.API + url, this.options)
      .pipe(map((res: HttpResponse<any>) => { return this.extractData(res) }), catchError((error: any) => this.handleError(error)));
  }

  public SubmitDeleteFormData(url: string, body: any, options: any = null) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: body
    };
    return this.httpClient.delete<Observable<any>>(this.API + url, httpOptions).subscribe(res => {
      return this.extractData(res);
    }, (err: any) => {
      this.handleError(err);
    });
  }

  private extractData(response: any) {
    /*if(response.status == 200){*/
    return response;
    /*}
    else{
        this.showSnackBar('Some error occurerd!','close');
    }  */
  }

  private handleError(error: HttpErrorResponse) {
    let errMsg = '';
    if (error.error instanceof Error) {
      errMsg = error.message || 'Client-side error occured.';
    } else {
      errMsg = error.message || 'Server-side error occured.';
    }
    this.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
    return Observable.throw(errMsg); // returns the message in a new, failed observable
  }
  /*Common Api Functions*/
  public getSalesOrder(ID: String): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiEndPoint + "/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderwithCategory(ID: String, location=null, userEmail=null): Observable<any> {
      if (ID && ID.match(/^[0-9a-fA-F]{24}$/).length > 0) {
        return this.http.get<any>(this.ApiEndPoint + "/GetSalesOrderwithCategory/" + ID.trim().toString() + "/" + location + "/" + userEmail)
          .pipe(catchError(this.errorHandler));
      }
    }
  public getParkingOrderByParkingConf(ID: String): Observable<any> {
    // ID.trim().toString()

    if (ID && ID.match(/^[0-9a-fA-F]{24}$/).length > 0) {
      return this.http.get<any>(this.ApiParkingEndPoint + "/GetParkingLotOrderByParkingConfirmation/" + ID.trim().toString() )
        .pipe(catchError(this.errorHandler));
    }
   

  }
  public GetParkingLotByParkinlotOrderIdOrSalesOrderId(ID: String): Observable<any> {
    // ID.trim().toString()

    if (ID && ID.match(/^[0-9a-fA-F]{24}$/).length > 0) {
      return this.http.get<any>(this.ApiParkingEndPoint + "/GetParkingLotByParkinlotOrderIdOrSalesOrderId/" + ID.trim().toString())
        .pipe(catchError(this.errorHandler));
    }


  }
  public getParkingOrderBySalesOrderId(ID: String): Observable<any> {
    // ID.trim().toString()

    if (ID && ID.match(/^[0-9a-fA-F]{24}$/).length > 0) {
      return this.http.get<any>(this.ApiParkingEndPoint + "/GetParkingLotOrderBysalesOrderId/" + ID.trim().toString())
        .pipe(catchError(this.errorHandler));
    }


  }
  public getQr(ID: String): Observable<any> {
    return this.http.get<any>(this.API + "Qr/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByCreditCard(ID: String): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiEndPoint + "/GetByCreditCardInfo/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getParkOrderByCreditCard(ID: String): Observable<ParkingLotOrder> {
    return this.http.get<ParkingLotOrder>(this.ApiParkingEndPoint + "/GetByCreditCardInfo/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByUserName(FirstName: string, LastName: string): Observable<SalesOrder> {
    let params = new HttpParams();
    if (FirstName) {
      params = params.append("FirstName", FirstName);
    }
    if (LastName) {
      params = params.append("LastName", LastName);
    }
    return this.http.get<SalesOrder>(this.ApiEndPoint + "/GetByFirstLastName", { params: params })
      .pipe(catchError(this.errorHandler));
  }

  public getParkOrderByUserName(FirstName: string, LastName: string): Observable<ParkingLotOrder> {
    let params = new HttpParams();
    if (FirstName) {
      params = params.append("FirstName", FirstName);
    }
    if (LastName) {
      params = params.append("LastName", LastName);
    }
    return this.http.get<ParkingLotOrder>(this.ApiParkingEndPoint + "/GetByFirstLastName", { params: params })
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByPhoneNumber(phone: String): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiEndPoint + "/GetByPhone/" + phone)
      .pipe(catchError(this.errorHandler));
  }
  public getParkOrderByPhoneNumber(phone: String): Observable<ParkingLotOrder> {
    return this.http.get<ParkingLotOrder>(this.ApiParkingEndPoint + "/GetByPhone/" + phone)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByEmail(email: String): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiEndPoint + "/GetByEmail/" + email)
      .pipe(catchError(this.errorHandler));
  }
  public getParkOrderByEmail(email: String): Observable<ParkingLotOrder> {
    return this.http.get<ParkingLotOrder>(this.ApiParkingEndPoint + "/GetByEmail/" + email)
      .pipe(catchError(this.errorHandler));
  }
  public changeTicketStatus(ID: String, seatnumber: String, status: number): Observable<SalesOrder> {
    return this.http.patch<SalesOrder>(this.ApiEndPoint + "/ChangeTicketStatus/" + ID + "/" + seatnumber + "/" + status, null)
      .pipe(catchError(this.errorHandler));
  }
  public changeStatusParkingLotPrint(ID: String): Observable<ParkingLotOrder> {
    return this.http.patch<ParkingLotOrder>(this.ApiParkingEndPoint + "/ChangeStatusParkingLotPrint/" + ID , null)
      .pipe(catchError(this.errorHandler));
  }
  public isEmptyObject(obj: object = {}) {
    if (obj)
      return Object.keys(obj).length;
    else
      return 0;
  }

  public getImageUrl(imageUrl: string = '', type: string = 'movie') {
    if (imageUrl != '' && imageUrl != null && imageUrl.indexOf("https://") == -1) {
      // having only image name
      return FileUploadService.getImageUrl(imageUrl);
    }
    else if (imageUrl != '' && imageUrl != null && imageUrl.indexOf("https://") > -1) {
      // having path of image
      return imageUrl;
    }
    else {
      // having empty or null string
      return '../../../assets/images/noimgport.png';
    }
  }

  public covertTimeToFormat(dateTime: string = '', format: string = '') {
    if (format != '' && dateTime != '')
      return moment.utc(dateTime).format(format);
    else
      return '';
  }

  public showSnackBar(message: string = '', action: string = 'close', data: any = {}) {
    let config = new MatSnackBarConfig();
    // config.duration = 3000;
    config.duration = 10000;
    config.verticalPosition = 'top';
    config.horizontalPosition = 'end';
    config.panelClass = ['warning-snackbar'];
    this.snackBarRef.open(message, action, config);
  }

  public getLocationMoviesData(force: boolean = false): Promise<any> {
    if (this.getLocationId() != '' && force) {
      let Dates = this.checkLocationMoviDate(null);
      return new Promise((resolve, reject) => {
        this.httpBackedurl.get(this.API + 'Kiosk/' + Dates.startDateTime + '/' + Dates.endDateTime, this.options)
          .toPromise().then((res: any) => {
            //assigning new movie data because push make multiple movies
            this.movieData = res.filter(s => s.SalesChannel.find(f => f.SalesChannelName === "Kiosk"))
            //old code
            // res.forEach(f => {
            //     var isKIOSKMovie = f.SalesChannel.find(s => s.SalesChannelName == "Kiosk");
            //     if(isKIOSKMovie){
            //         this.movieData.push(f);
            //     }
            // });
            resolve(this.movieData);
          }), catchError((error: any) => this.handleError(error));
      });
    }
    else {
      return new Promise((res, rej) => {
        res(this.movieData);
      });
    }
  }

  public checkLocationId(LocationId: string = '') {
    if (this.getLocationId() != '') {
      this.setLocationId(LocationId);
      /*this.setMovieId('');
      this.setPriceCardId('');
      this.setScreenId('');
      this.setShowId('');
      this.setTickets({Tickets : [],TotalPrice : 0,TotalTickets : 0});*/
      /*let promise = new Promise((resolve, reject) => {
          this.httpClient.get(this.API + 'Kiosk/' + this.getLocationId(),this.options)
          .toPromise().then((res:any)=>{this.movieData = res;resolve();}),catchError((error:any)=>this.handleError(error));
      });
      return promise;*/
    }
    else {
      this.setLocationId(LocationId);
    }
  }

  checkConcessionInventory(concessions): Observable<Array<Object>> {
    let postObj = new Array<Object>();
    concessions.forEach((el) => {
      if (!el.Quantity){
        el.Quantity= el.Qty;
      }
      if (el.Quantity)
        el.NumberSold = el.Quantity;
      if (el.Concession_ID && el.ConcessionId.indexOf && el.ConcessionId.indexOf('-') != -1) {
        el.ConcessionId = el.Concession_ID;

      }
      postObj.push(el);
    });
    return this.http.post<Array<Object>>("/api/Concession/CheckConcessionInventory", postObj).pipe(catchError(this.errorHandler));
  }
  public getReviewText(rating: number = 0) {
    let review: string = '';
    var movierating = this.Ratings.find(k => k.value == rating);
    if (movierating){
      review = movierating.name;
    } else {
      review = 'N/A';
      }
      return review;
  }

  // public getReviewText(rating: number = 0) {
  //   let review: string = '';
  //   if (rating == 6)
  //     review = 'NR';
  //   else if (rating == 5)
  //     review = 'NC17';
  //   else if (rating == 4)
  //     review = 'R';
  //   else if (rating == 3)
  //     review = 'PG13';
  //   else if (rating == 2)
  //     review = 'PG';
  //   else if (rating == 1)
  //     review = 'G';
  //   else
  //     review = 'N/A';
  //   return review;
  // }

  public unsetFinalData(data: any = []) {
    data.forEach((item, index) => {
      if (item == 'LocationId')
        this.setLocationId('');
      if (item == 'LocationMovieDate')
        this.setLocationMovieDate('');
      if (item == 'MovieId')
        this.setMovieId('');
      if (item == 'PriceCardId')
        this.setPriceCardId('');
      if (item == 'ScreenId')
        this.setScreenId('');
      if (item == 'ShowId') {
        sessionStorage.removeItem('ShowId');
        sessionStorage.removeItem('ShowProperties');
      }
      if (item == 'Tax')
        this.setTax('');
      if (item == 'Seats')
        this.setSeats([]);
      if (item == 'Seating')
        this.setSeating('reserved');
      if (item == 'Tickets')
        this.setTickets({ Tickets: [], TotalPrice: 0, TotalTickets: 0 });
      if (item == 'IsFoodItemsAvailable')
        this.setIsFoodItemsAvailable('y');
      if (item == 'FoodItems')
        this.setFoodItems({ selectedFoodItems: [], TotalPrice: 0, TotalFoodItems: 0 });
    });
  }

  public setLocationId(LocationId: string = '') {
    this.finalSelectedMovieData.LocationId = LocationId;
    sessionStorage.setItem("LocationId", LocationId);
  }

  public getUserHoldSeats() {
    return this.userHoldSeats;
  }

  public setUserHoldSeats(userHoldSeats = []) {
    this.userHoldSeats = userHoldSeats;
  }

  public getLocationId() {
    return this.finalSelectedMovieData.LocationId;
  }

  public setLocationMovieDate(LocationMovieDate: string = '') {
    this.finalSelectedMovieData.LocationMovieDate = LocationMovieDate;
    sessionStorage.setItem("LocationMovieDate", LocationMovieDate);
  }

  public getLocationMovieDate() {
    return this.finalSelectedMovieData.LocationMovieDate;
  }

  public checkLocationMoviDate(date: Date) {
    let returnObj = { startDateTime: '', endDateTime: '' };
    returnObj.startDateTime = this.getLocationMovieDate();
    if (returnObj.startDateTime && returnObj.startDateTime != '') {
      returnObj.endDateTime = returnObj.startDateTime.split('T')[0] + 'T' + '23:59:59.000Z';
    }
    else {
      var date = new Date(date);
      const strDate = moment(date).format();
      returnObj.startDateTime = strDate.split("T")[0] + "T00:00:00.000Z";
      returnObj.endDateTime = strDate.split("T")[0] + "T23:59:59.999Z";
      this.setLocationMovieDate(returnObj.startDateTime);
    }
    console.log(returnObj);
    return returnObj;
  }

  public setMovieId(Movie) {
    if (Movie instanceof String) {
      Movie = JSON.parse(Movie.toString());
    }
    this.finalSelectedMovieData.Movie = Movie;
    sessionStorage.setItem("MovieId", JSON.stringify(Movie));
  }

  public getMovie() {
    return this.finalSelectedMovieData.Movie;
  }

  public getMovieId() {
    return this.finalSelectedMovieData.Movie.MovieID;
  }

  public setSeating(Seating: string = '') {
    if (Seating !== null && Seating !== undefined && Seating !== '') {
      this.finalSelectedMovieData.Seating = Seating.toLowerCase();
      sessionStorage.setItem("Seating", Seating);
    }
    else {
      this.finalSelectedMovieData.Seating = 'reserved';
      sessionStorage.setItem("Seating", Seating);
    }
  }

  public getSeating() {
    return this.finalSelectedMovieData.Seating.toLowerCase();
  }

  public setPriceCardId(PriceCardId: string = '') {
    this.finalSelectedMovieData.PriceCardId = PriceCardId;
    sessionStorage.setItem("PriceCardId", PriceCardId);
  }

  public getPriceCardId() {
    return this.finalSelectedMovieData.PriceCardId;
  }

  public setScreenId(ScreenId: string = '') {
    this.finalSelectedMovieData.ScreenId = ScreenId;
    sessionStorage.setItem("ScreenId", ScreenId);
  }
  public getScreenId() {
    return this.finalSelectedMovieData.ScreenId;
  }
  public setAvailableSeats(seatCount: number = 0) {
    this.finalSelectedMovieData.availableSeatCount = seatCount;
  }

  public getAvailableSeats() {
    return this.finalSelectedMovieData.availableSeatCount;
  }


  public setShowId(ShowId: string = '') {
    this.finalSelectedMovieData.ShowId = ShowId;
    sessionStorage.setItem("ShowId", ShowId);
  }

  public getShowId() {
    return this.finalSelectedMovieData.ShowId;
  }

  public setShowTime(ShowTime: string = '') {
    this.ShowTime = ShowTime;
    sessionStorage.setItem("ShowTime", ShowTime);
  }

  public getShowTime() {
    return this.ShowTime
  }

  public setTax(Tax: string = '') {
    this.finalSelectedMovieData.Tax = Tax;
  }

  public getTax() {
    return this.finalSelectedMovieData.Tax;
  }

  public setTickets(Tickets: any = {}) {
    this.finalSelectedMovieData.Tickets.Tickets = Tickets.Tickets;
    this.finalSelectedMovieData.Tickets.TotalPrice = Tickets.TotalPrice;
    this.finalSelectedMovieData.Tickets.TotalTickets = Tickets.TotalTickets;
    this.finalSelectedMovieData.Tickets.AdditionalCharges = Tickets.AdditionalCharges;
    sessionStorage.setItem("Tickets", Tickets.Tickets);
    sessionStorage.setItem("TotalPrice", Tickets.TotalPrice);
    sessionStorage.setItem("TotalTickets", Tickets.TotalTickets);
    sessionStorage.setItem("AdditionalCharges", Tickets.AdditionalCharges);

  }

  public getTickets() {
    return this.finalSelectedMovieData.Tickets;
  }

  public getTicketsTotalPrice() {
    return parseFloat(this.finalSelectedMovieData.Tickets.TotalPrice);
  }

  public getAdditionalCharges() {
    return this.finalSelectedMovieData.Tickets.AdditionalCharges;
  }

  public getTicketsTotalTickets() {
    return this.finalSelectedMovieData.Tickets.TotalTickets;
  }

  public setSeats(Seats: any = []) {
    this.finalSelectedMovieData.Seats = Seats;
    sessionStorage.setItem("Seats", Seats);
  }

  public getSeats() {
    return this.finalSelectedMovieData.Seats;
  }

  public setIsFoodItemsAvailable(IsFoodItemsAvailable: string = 'y') {
    this.finalSelectedMovieData.IsFoodItemsAvailable = IsFoodItemsAvailable;
    sessionStorage.setItem("IsFoodItemsAvailable", IsFoodItemsAvailable);
  }

  public getIsFoodItemsAvailable() {
    return this.finalSelectedMovieData.IsFoodItemsAvailable;
  }

  public setFoodItems(FoodItems: any = {}) {
    this.finalSelectedMovieData.FoodItems.selectedFoodItems = FoodItems.selectedFoodItems;
    this.finalSelectedMovieData.FoodItems.TotalPrice = FoodItems.TotalPrice;
    this.finalSelectedMovieData.FoodItems.TotalFoodItems = FoodItems.TotalFoodItems;
    sessionStorage.setItem("selectedFoodItems", FoodItems.selectedFoodItems);
    sessionStorage.setItem("FoodItemsTotalPrice", FoodItems.TotalPrice);
    sessionStorage.setItem("TotalFoodItems", FoodItems.TotalFoodItems);
  }

  public getFoodItems() {
    return this.finalSelectedMovieData.FoodItems.selectedFoodItems;
  }

  public getFoodItemsTotalPrice() {
    return parseFloat(this.finalSelectedMovieData.FoodItems.TotalPrice);
  }

  public getFoodItemsTotalFoodItems() {
    return parseFloat(this.finalSelectedMovieData.FoodItems.TotalFoodItems);
  }

  public setSalesOrderData(salesOrderData: any) {
    this.salesOrderData = salesOrderData;
  }

  public getSalesOrderData() {
    return this.salesOrderData;
  }

  public selectedSeats: any = [];
  public clearPreviousData() {
    if (this.userHoldSeats.length > 0) {
      let body = [];
      this.userHoldSeats.forEach((i, index) => {
        body.push(i.HoldSeatId);
      });
      if (body.length > 0) {
        this.SubmitPostFormData('HoldSeats/DeleteHoldSeats', body).subscribe((response) => {
        }, (error) => {
          console.log(error);
        });
      }
    }

    this.pickupTicketData = [];
    this.unsetFinalData(['MovieId', 'PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems', 'LocationMovieDate']);
  }
}
