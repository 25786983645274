import { CompanyComponent } from './company/company.component';
//import { ClientCompanyModule } from './client-company/client-company.module';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { EmptyLayoutComponent } from "./empty-layout/empty-layout.component";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { KioskLayoutComponent } from "./kiosk-pos/kiosk-layout/kiosk-layout.component";
import { PosLayoutComponent } from "./pos/pos-layout/pos-layout.component";
import { PrintComponent } from "./print/print.component";
import { AuthGuard } from "./auth-guard.service";
import { NavMenuErrorComponent } from "./error-page/nav-menu-error/nav-menu-error.component";
import { KioskResolver } from "./kiosk-pos/resolver/kiosk-resolver";
import { DineinHomeComponent } from "./DineIn/dinein-home/dinein-home.component";
import { BannersdisplayComponent } from './bannersdisplay/bannersdisplay.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { WikiComponent } from './wiki/wiki.component';
import { SuccesspaymentComponent } from './successpayment/successpayment.component';
import { CompanydashboardComponent } from './companydashboard/companydashboard.component';
import { ContactComponent } from './contact/contact.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { TcppComponent } from './tcpp/tcpp.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CompanydashboardpreviewComponent } from './companydashboardpreview/companydashboardpreview.component';
// import { AdminrefundComponent } from './adminrefund/adminrefund.component';
//import { ClientCompanyComponent } from "./client-company/client-company.component";

const routes: Routes = [
  {
    path: "print",
    outlet: "print",
    component: PrintComponent,
    children: [
      { path: '', component: PrintComponent, pathMatch: "full", },
      { path: 'giftcardrecharge', component: PrintComponent },
      { path: 'ticket/:id', component: PrintComponent },
      { path: 'kitchen/:type', component: PrintComponent },
      { path: 'refund', component: PrintComponent },
      { path: 'qr/:locationId', component: PrintComponent },
      { path: 'unPaid/:type', component: PrintComponent },
      { path: 'authPaid/:type', component: PrintComponent },
      { path: 'parkingLotPrint/:id', component: PrintComponent },
      { path: 'Parkrefund', component: PrintComponent },
    ]
  },
  {
    path: "", component: EmptyLayoutComponent,
    children: [
      { path: "", component: HomeComponent, pathMatch: "full", data: [] },
      { path: "login", component: LoginComponent, pathMatch: "full", data: [] },
      { path: "signup", component: SignupComponent, pathMatch: "full", data: [] },
      { path: "home", component: HomeComponent, pathMatch: "full", data: [] },
      { path: "features", component: BannersdisplayComponent, pathMatch: "full", data: [] },
      { path: "contact", component: ContactComponent, pathMatch: "full", data: [] },
      { path: "aboutus", component: AboutusComponent, pathMatch: "full", data: [] },   
      // { path: "adminrefund", component: AdminrefundComponent, pathMatch: "full", data: [] },
      // { path: "informations", component: WikiComponent, pathMatch: "full", data: [] },
    ]
  },
  {
    path: "companydashboard", component: AdminLayoutComponent,
    children: [
      { path: "", component: CompanydashboardComponent, pathMatch: "full", data: [] },
    ]
  },
  {
    path: "companydashboardPreview", component: AdminLayoutComponent,
    children: [
      { path: "", component: CompanydashboardpreviewComponent, pathMatch: "full", data: [] },
    ]
  },
  
  {
    path: "POS", component: PosLayoutComponent,
    loadChildren: () => import('./pos/pos.module').then(m => m.POSModule)
  },
  {
    path: "kiosk", component: KioskLayoutComponent,
    loadChildren: () => import('./kiosk-pos/kiosk.module').then(m => m.KioskModule)
  },
  
  {
    path: "", component: AdminLayoutComponent,
    data: { breadcrumb: '' },
    children: [
      {
        path: "dashboard",
        redirectTo: '/locations',
        //component: DashboardComponent,
        pathMatch: "full",
        data: { breadcrumb: '' }
      },
      {
        path: "movies",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        loadChildren: () => import('./movies/movie.module').then(m => m.MovieModule),
        data: { breadcrumb: "Movies" }
      },
      {
        path: "employeeFeatures",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        loadChildren: () => import('./employee-feature/employee-feature.module').then(m => m.EmployeeFeatureModule),
        data: { breadcrumb: "EmployeeFeature" }
      },
      {
        path: "giftcards",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        loadChildren: () => import('./giftcard/giftcard.module').then(m => m.GiftCardModule),
        data: { breadcrumb: "GiftCards" }
      },
      {
        path: "locations",
        component: EmptyLayoutComponent,
        data: { breadcrumb: "Locations" },
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationModule)
      },
      {
        path: "merchants",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        data: { breadcrumb: "Merchants" },
        loadChildren: () => import('./merchants/merchant.module').then(m => m.MerchantModule)
      },
      {
        path: "companyAdmin",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        data: { breadcrumb: "CompanyAdmin" },
        loadChildren: () => import('./company-admin/company-admin.module').then(m => m.CompanyAdminModule)
      },
      {
        path: "editCompany",
        // canActivate: [AuthGuard],
        component: EditCompanyComponent,
        data: { breadcrumb: "Edit Company" },
        //loadChildren: () => import('./company-admin/company-admin.module').then(m => m.CompanyAdminModule)
      },
      {
        path: "realeasenotes",
        //canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        loadChildren: () => import('./release-notes/release-note.module').then(m => m.ReleaseNoteModule),
        data: { breadcrumb: "Release Notes" }
      },
      { 
        path: "downloadwrapper",
        //canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        loadChildren: () => import('./download-wrapper/download-wrapper.module').then(m => m.DownloadWrapperModule),
        data: { breadcrumb: "DownloadWrapper" }
      },
      {
        path: "promote",
        canActivate: [AuthGuard],
        component: EmptyLayoutComponent,
        data: { breadcrumb: "promote" },
        loadChildren: () => import('./promote-movies/promote.module').then(m => m.PromoteModule)
      }, 
      {
        path: "feature-denied", component: NavMenuErrorComponent,
      }
    ]
  },
  //{
  //  path: "DineIn/:activeTab", component: DineinHomeComponent,
  //},
  {
    path: "registration", component: CompanyComponent,
  },
  { path: 'restaurant/:LocationId', loadChildren: () => import('./restaurant/restaurant.module').then(m => m.RestaurantModule) },
  //{ path: "**", redirectTo: '/feature-denied' }
  {
    path: 'successpayment',
    component: SuccesspaymentComponent
  },
  {
    path: "tcpp/:companyId",
    component: TcppComponent,
  },
  {
    path: "termsconditions",
    component: TermsconditionsComponent,
  },
  {
    path: "privacypolicy",
    component: PrivacypolicyComponent,
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled',anchorScrolling: 'enabled' })],
  providers: [
    KioskResolver
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
