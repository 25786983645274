import { Pipe, PipeTransform } from '@angular/core';

/*
 * Usage:
 *   value | paren
 * Example:
 *   {{ 'foo' | paren }}
 *   formats to: '（foo）'
*/
@Pipe({
  name: 'varienceformat'
})
export class VarienceFormatPipe implements PipeTransform {
    transform(value: number): string {
        if (value === null || value === undefined) {
          return ''; // or '0' if you want to display zero
        }
        if (value > 0) {
          return `(+)${Math.abs(value)}`;
        } else if (value < 0) {
          return `(-)${Math.abs(value)}`;
        } else {
          return '0';
        }
      }
}
