import { formatDate } from "@angular/common";
import { FileUploadService } from "../services/file-upload.service";
export interface ImageType {

  url: any;
  file: File;
  progress: number;
}

export function CloneObject(source) {
  return JSON.parse(JSON.stringify(source));
}
export function RoundAmount(val: number): number {
  return Number(val.toFixed(2));
}
export function TaxAmountCalculation(taxpercent: number, itemPrice: number): number {
  return itemPrice - Number(itemPrice / (1 + taxpercent / 100));
}

// Added for discount
export function PercentageAmountCalculation(taxpercent: number, itemPrice: number): number {
  return RoundAmount(itemPrice - Number((itemPrice * taxpercent) / 100));
}

export function GetPercentageByAmount(discountAmount, totalPrice) {
  return RoundAmount((discountAmount * 100) / totalPrice);
}

export function roundHalf(num) {
  let rounded = Math.floor(num * 20) / 20; // Multiply by 20 to move the 2 decimal places to the left and round down to the nearest integer, then divide by 20 to move the decimal point back
  return Number(rounded.toFixed(2)); // Round to 2 decimal places and return as a string
}
export abstract class AddHelperMethods {
  fileError: string = '';
  dateChange(value: Date, dtObj: Object, dataKey: string): void {
    if (value !== null) {
      value.setHours(0, 0, 0, 0);
      dtObj[dataKey] = AddHelperMethods.enDateFormat(value);
    }
  }
  loadCasts(cast: string): Array<Object> {
    if (!cast) {
      return [];
    }
    return cast.split(",").map(c => {
      return { display: c, value: c };
    })
  }
  getCasts(cast: Array<Object>): string {
    return cast.map(c => {
      return c['value'];
    }).join(",");
  }

  addAvtarImage(file: any, image: ImageType, errObj: Object) {
    this.fileError = "";

    errObj['error'] = "";
    var data = file.target.files.item(0);
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (file.target.files.item(0).type.includes('gif')) {
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";

      return;
    }
    const reader = new FileReader();
    let maxheight = 500;
    let maxwidth = 500;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height > maxheight || width > maxwidth) {
          // errObj['error'] = "Please upload the correct size and format.";
          this.fileError = "Please upload the correct size and format.";
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
        }
      };
    };

  }

  addImage(file: any, image: ImageType, errObj: Object) {
    errObj['error'] = "";
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      errObj['error'] = "Only images are allowed for uploading graphic.";
      return;
    }
    image.file = <File>file.target.files[0];
    this.previewImage(image);
  }

  addMovieImage(file: any, image: ImageType, errObj: Object) {
    this.fileError = "";

    errObj['error'] = "";
    var data = file.target.files.item(0);
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (!data  || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (file.target.files.item(0).type.includes('gif')) {
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";

      return;
    }
    const reader = new FileReader();
    let maxheight = 860;
    let maxwidth = 600;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height > maxheight || width > maxwidth) {
         // errObj['error'] = "Please upload the correct size and format.";
          this.fileError = "Please upload the correct size and format.";
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
        }
      };
    };

  }

  addMovieWallpaperImage(file: any, image: ImageType, errObj: Object) {

    this.fileError = "";
    errObj['error'] = "";
    var data = file.target.files.item(0);
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (file.target.files.item(0).type.includes('gif')) {
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    const reader = new FileReader();
    let minheight = 665;
    let minwidth = 1280;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height < minheight || width < minwidth) {

         // errObj['error'] = "Please upload the correct size and format.";
          this.fileError = "Please upload the correct size and format.";
          return;
        }
        else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
        }
      };
    };

  }
  addScreenInfoImage(file: any, image: ImageType, errObj: Object) {
    this.fileError = "";

    errObj['error'] = "";
    var data = file.target.files.item(0);
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (file.target.files.item(0).type.includes('gif')) {
      //errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";

      return;
    }
    const reader = new FileReader();
    let maxheight = 86;
    let maxwidth = 484;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height > maxheight || width > maxwidth) {
          // errObj['error'] = "Please upload the correct size and format.";
          this.fileError = "Please upload the correct size and format.";
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
          this.fileError = '';
        }
      };
    };

  }
  addConcessionImage(file: any, image: ImageType, errObj: Object) {
    this.fileError = "";

    errObj['error'] = "";
    var data = file.target.files.item(0);
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
     // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    if (file.target.files.item(0).type.includes('gif')) {
      //errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";

      return;
    }
    const reader = new FileReader();
    let maxheight = 255;
    let maxwidth = 450;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height > maxheight || width > maxwidth) {
         // errObj['error'] = "Please upload the correct size and format.";
          this.fileError = "Please upload the correct size and format.";
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
          this.fileError = '';
        }
      };
    };

  }
  addCompanyCoverImage(file: any, image: ImageType, errObj: Object, CompanyBannerImage) {
    console.log("FILE", file);
    const reader = new FileReader();
    let minheight = 320;
    let minwidth = 800;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height < minheight && width < minwidth) {
          errObj['error'] = "Please upload good quality image.";
          if (!CompanyBannerImage)
            this.fileError = 'Please upload Company Banner image with minimum 800 × 320 resolution'
          return;
        }
      };
    };
    errObj['error'] = "";
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      errObj['error'] = "Only images are allowed for uploading graphic.";
      return;
    }
    image.file = <File>file.target.files[0];
    this.previewImage(image);
    this.fileError = '';
  }


  addDigitalImage(file: any, image: ImageType, errObj: Object) {
    console.log("FILE", file);
    this.fileError = '';
    var data = file.target.files.item(0);
    errObj['error'] = "";
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      this.fileError = "Only images are allowed for uploading graphic.";
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    const reader = new FileReader();
    let minheight = 600;
    let minwidth = 800;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', width, height);
        if (height < minheight || width < minwidth) {
          // errObj['error'] = "Please upload good quality image.";
          this.fileError = 'Please upload the correct size and format.'
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
          this.fileError = '';
        }
      };
    };


  }
  addOnlineBanner(file: any, image: ImageType, errObj: Object) {
    console.log("FILE", file);
    this.fileError = '';
    errObj['error'] = "";
    var data = file.target.files.item(0);
    if(file.target.files.item(0).type.includes('gif')){
     // errObj['error'] = "Only images are allowed for uploading graphic.";
      this.fileError = "Please upload the correct size and format.";
      this.removeImage(image);
      return;
    }
    if (file.target.files.item(0).type.match(/image\/*/) == null) {
      //errObj['error'] = "Only images are allowed for uploading graphic.";
      this.fileError = "Please upload the correct size and format.";
      this.removeImage(image);
      return;
    }
    if (!data || data.type != 'image/png') {
      // File is a PNG image, you can proceed with further processing
      // errObj['error'] = "Please upload the correct size and format.";
      this.fileError = "Please upload the correct size and format.";
      return;
    }
    const reader = new FileReader();
    let minheight = 665;
    let minwidth = 1280;
    reader.readAsDataURL(<File>file.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        console.log('Width and Height', height, minheight, width, minwidth);
        if (height < minheight || width < minwidth) {
          //errObj['error'] = "Please upload good quality image.";
          this.fileError = 'Please upload the correct size and format.';
          this.removeImage(image);
          return;
        } else {
          image.file = <File>file.target.files[0];
          this.previewImage(image);
          this.fileError = '';
        }
      };
    };

  }
  removeImage(image: ImageType): any {
    var reader = new FileReader();
    reader.readAsDataURL(image.file);
    reader.onload = _event => {
      image.url = null;
    };
  }
  async uploadImage(image: ImageType, old_filename: string, fileUploadServ: FileUploadService, errObj: Object, service?: string | 'blob') {
    if (image.file === null) {
      return;
    }
    if(service == 'blob'){
      const formData = new FormData();
      formData.append("Image", image.file, image.file.name);
      formData.append("OldFilename", old_filename);

      await fileUploadServ.uploadFile(formData, image).catch(
        error => {
          errObj['error'] = error;
        }
      );
    }
    else{
      await fileUploadServ.uploadFileToCloudFlare(image, old_filename, errObj )
    }
  }

  async uploadCompanyLogoImage(image: ImageType, old_filename: string, fileUploadServ: FileUploadService, errObj: Object, newHeight, newWidth, service?: string | 'blob') {
    if (image.file === null) {
      return;
    }
    if(service == 'blob'){
      const formData = new FormData();
      formData.append("Image", image.file, image.file.name);
      formData.append("OldFilename", old_filename);
      formData.append("newHeight", newHeight);
      formData.append("newWidth", newWidth);

      await fileUploadServ.uploadCompanyLogoFile(formData, image).catch(
        error => {
          errObj['error'] = error;
        }
      );
    }
    else{
      await fileUploadServ.uploadCompanyLogoFileToCloudFlare(image, old_filename, newHeight, newWidth, errObj)
    }
  }

  static getOldFileName(value): string {
    if (value === '' || value === null || value === undefined) {
      return '';
    }
    value = value.split('?')[0];
    value = value.substr(value.search(/\/[0-9]/) + 1);
    return value;
  }

  previewImage(image: ImageType): any {
    var mimeType = image.file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(image.file);
    reader.onload = _event => {
      image.url = reader.result;
    };
  }

  static enDateFormat(value) {
    if (value !== null && value !== undefined && value !== '')
      return formatDate(value, 'MM/dd/yyyy', 'en');
    else
      return '';
  }

  static enTimeFormat(value) {
    if (value !== null && value !== undefined && value !== '')
      return formatDate(value, 'HH:mm', 'en');
    else
      return '';
  }

  IsTimeValid(event: boolean, obj: Object) {
    // this.TimeValid = event;
    obj['error'] = !event ? "Please enter valid time" : '';
  }

}
