import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';
import { Subject } from 'rxjs';
import { PaymentoptionService } from '../../../paymentoption/paymentoption.service';
import { PaymentMode } from '../../models/SalesOrder';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent{
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  remainingTime: string;
  timerOn: boolean;
  cancelButton: boolean = false;
  nonSplitPayment: boolean = false;
  timeOutRef: any;
  timerTime = 30;


  constructor(private loaderService: LoaderService, private paymentOptionService: PaymentoptionService) {
    this.loaderService.isTimer.subscribe((res: any) => {
      console.log('res', res)
      if (res == true) {
        clearTimeout(this.timeOutRef)
        this.timer(this.timerTime);
      } else {
        clearTimeout(this.timeOutRef)
      }
    })
    this.paymentOptionService.paymentType.subscribe((result: any) => {
      console.log('payment type', result)
      if (result != PaymentMode.Split) {
        this.nonSplitPayment = true;
      }
      else {
        this.nonSplitPayment = false;
      }
    })
  }
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoadingReports: Subject<boolean> = this.loaderService.isLoadingReports;
  isLoadingPayment: Subject<boolean> = this.loaderService.isLoadingPayment;


  timer(remaining) {
    this.timerOn = true;
    let m, s;
    m = Math.floor(remaining / 60);
    s = remaining % 60;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    this.remainingTime = m + ':' + s;
    remaining -= 1;

    if (remaining >= 0 && this.timerOn) {
      this.timeOutRef = setTimeout(() => {
        this.timer(remaining);
      }, 1000);
      return;
    }

    if (!this.timerOn) {
      return;
    }
    this.timerOn = false;
  }
  onCancel() {
    this.loaderService.popOpen();
  }


}
