import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var $: any;
declare var jsKeyboard: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    jsKeyboard.init("virtualKeyboard");

    //first input focus
    var $firstInput = $(':input').first().focus();
    jsKeyboard.currentElement = $firstInput;
    jsKeyboard.currentElementCursorPosition = 0;
  }

}
