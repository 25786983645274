import { Component, OnInit, ViewEncapsulation, ViewChildren } from '@angular/core';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { Router } from '@angular/router';
import Keyboard from "simple-keyboard";
import { CommonSettings } from 'src/app/helper/commonSettings';
import {SalesStatus} from 'src/app/helper/models/SalesOrder';
import Swal from 'sweetalert2';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
	selector: 'app-kiosk-pickup-barcode',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './kiosk-pickup-barcode.component.html',
	styleUrls: ['./kiosk-pickup-barcode.component.css', '../../../../node_modules/simple-keyboard/build/css/index.css']
})
export class KioskPickupBarcodeComponent implements OnInit {
	CCode: string;
	SalesOrder: any = [];
	error: string = null;
	count: number = 0;
	keyboard: Keyboard;
	@ViewChildren('kioskbcinput') kioskbcinput: any;
	
	constructor(public kioskPOSService: KioskPOSService, public router: Router,private es:ErrorMessagesService) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
		this.keyboard = new Keyboard({
			onChange: input => this.onChange(input),
			layout: {
				default: CommonSettings.keybSetting.ANKeyDef,
			},
			theme: CommonSettings.keybSetting.theme
		});

		const input: HTMLInputElement = this.kioskbcinput.first.nativeElement as HTMLInputElement;
        input.focus();
	}

	onChange = (input: any) => {
		this.CCode = input;
	};
	onInputChange = (event: any) => {
		this.keyboard.setInput(event.target.value);
	};

	Verify() {
		this.kioskPOSService.getSalesOrder(this.CCode).subscribe(res => {
		  this.SalesOrder = res;
		  if (this.SalesOrder.ConfirmationCode != null) {
			for (const ticket of this.SalesOrder.Tickets) {
			  if (ticket.Status != SalesStatus.Printed) {
				this.count++;
			  }
			}
			if (this.count !== 0) {
			  this.kioskPOSService.pickupTicketData = this.SalesOrder;
			  this.router.navigate(['/kiosk/pickup/print/' + this.kioskPOSService.getLocationId()])
			}
			else {
			  Swal.fire(this.es.kioskErrorMsgList[35].message,this.es.kioskErrorMsgList[6].message,'error');
			}
		  }
		  else {
			Swal.fire(this.es.kioskErrorMsgList[9].message,'','error')
		  }
		},ee=>{
		  Swal.fire(this.es.kioskErrorMsgList[65].message,'','error')
		});
	
	  }
	
}
