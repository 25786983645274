import { Injectable, Inject, EventEmitter } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { SalesOrder } from '../models/SalesOrder';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SignalrService extends ApiService {
  foodchanged$ = new Subject();
  messageReceived$ = new Subject<SalesOrder>();
  newCpuValue$ = new Subject<number>();
  connectionEstablished$ = new BehaviorSubject<boolean>(false);

  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;
  invokeUpdateFirstComponentFunction = new EventEmitter();
  updatesubsVar: Subscription;

  private hubConnection: HubConnection;

  constructor(@Inject('BASE_URL') url: string, private activeRot: ActivatedRoute) {
    super(url);

    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.BaseUrl + "KitchenHub")
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
  }

  private startConnection() {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }

    this.hubConnection.start().then(
      () => {
        console.log('Hub connection started!');
        this.connectionEstablished$.next(true);
      },
      error => console.error(error)
    );
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('ReceivedKitchenOrder', (data: string) => {
      var kitchenOrder = JSON.parse(data);
      console.log('Posted Kitchen Order', kitchenOrder);
      // kitchen
      if (this.activeRot.snapshot.children[0].params.activeTab == "dinein" || this.activeRot.snapshot.children[0].params.activeTab == "kitchen") {
        this.invokeFirstComponentFunction.emit(kitchenOrder);
      }
    });
  }

}
