import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CommonSettings } from '../../helper/commonSettings';
import { AddHelperMethods, ImageType } from '../../helper/models/AddHelperModal';
import { ErrorMessagesService } from '../../helper/services/error-messages.service';
import { FileUploadService } from '../../helper/services/file-upload.service';
import { LocationsService } from '../../locations/locations.service';
import { Company } from '../company';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent extends AddHelperMethods implements OnInit {

  @ViewChild('RegistrationForm', { static: true }) RegistrationForm: NgForm;
  clientCompany: Company = new Company();
  //restaurantOption: RestaurantOptions = new RestaurantOptions();
  types = Company.getCompanyTypeName();
  //showRestaurantOptions: boolean = false;
  clientCompanyMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  clientCompanyBannerMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  clientOnlinePWAMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  error: string = '';
  Company_ID: any;
  constructor(private locationService: LocationsService ,private clientCompanyService: CompanyService, private fileUploadServ: FileUploadService, private router: Router, private es: ErrorMessagesService) { super(); }

  ngOnInit() {
    this.clientCompanyService.getCompanyIdByContext().subscribe((res) => {
      this.Company_ID = res
      if (this.Company_ID) {
        this.locationService.getCompanyByCompanyId(this.Company_ID).subscribe((company) => {
          this.clientCompany = company;
          this.clientCompanyMedia.url = this.clientCompany.LogoImage || '';
          this.clientCompanyBannerMedia.url = this.clientCompany.CompanyBannerImage || '';
          this.clientOnlinePWAMedia.url = this.clientCompany.PWAImage || '';
        })
      } else {
        Swal.fire({
          html: "OOPS! Session expired. Please login again.<br><br>" +
            '<a class="swal2-confirm swal2-styled" href="/authorization/signin">LOGOUT</a>',
          showCancelButton: false,
          showConfirmButton: false,
          type: "warning",
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    }, error => {
      this.error = error;
    });
  }
  setCompanyType() {
    //if (this.clientCompany.CompanyTypeName == "restaurant") {
    //  this.showRestaurantOptions = true;
    //} else
    if (this.clientCompany.CompanyTypeName == "cinemas") {
      // this.showRestaurantOptions = false;
      this.clientCompany.CompanyType.Restaurant = false;
      this.clientCompany.CompanyType.Cinemas = true;
    }
    else if (this.clientCompany.CompanyTypeName == "restaurant") {
      this.clientCompany.CompanyType.Restaurant = true;
      this.clientCompany.CompanyType.Cinemas = false;
    }
  }
  onSubmit() {
    console.log('submit');

    if (this.RegistrationForm.form.valid) {
      this.error = '';
    }

    if (!this.clientCompany.Company_Name.trim())
      this.error = 'Please enter valid Name';
    else if (!this.clientCompany.Address.trim())
      this.error = 'Please enter valid Address';
    else if (!this.clientCompany.City.trim())
      this.error = 'Please enter valid City';
    else if (!this.clientCompany.State.trim())
      this.error = 'Please enter valid State';
    else if (!this.clientCompany.Country.trim())
      this.error = 'Please enter valid Country';
    else if (!this.clientCompany.PostalCode.trim())
      this.error = 'Please enter valid Postal Code';
    else if (parseFloat(this.clientCompany.LoyaltyPointsPerCurrency) < 0)
      this.error = 'Please enter valid loyalty points per currency';
    else if (parseFloat(this.clientCompany.DefaultLoyaltyPoints) < 0)
      this.error = 'Please enter valid Default loyalty points assigned to Concession and Ticket type';
    else if (parseInt(this.clientCompany.RegistrationLoyaltyPoints) < 0)
      this.error = 'Please enter valid Registration bonus loyalty points';
    else if (!this.clientCompany.Email.trim())
      this.error = 'Company Email is Required';

    if (!this.clientCompany.LogoImage && this.clientCompanyMedia.file == null) {
      if (this.clientCompanyMedia.file == null || this.clientCompanyMedia.url == "" || this.clientCompanyMedia.file == undefined) {
        this.error = this.es.adminErrorMsgList[109].message;
        return;
      }
    }

    //if (this.clientCompany.CompanyTypeName == "restaurant") {
    //    if(this.restaurantOption.IsCreditCardAllowed == false && this.restaurantOption.IsDeliveryAllowed == false &&
    //        this.restaurantOption.IsDineInAllowed == false && this.restaurantOption.IsTakeOutAllowed == false) {
    //        this.error = 'Please select atleast one of the restaurant options.';
    //        return;
    //    }
    //} else
    //if (this.clientCompany.CompanyTypeName == "cinemas") {
    //  this.restaurantOption.IsCreditCardAllowed == false;
    //  this.restaurantOption.IsDeliveryAllowed == false;
    //  this.restaurantOption.IsDineInAllowed == false;
    //  this.restaurantOption.IsTakeOutAllowed == false;
    //}

    if (this.clientCompany.IsAgreeableToTermsAndConditions == false) {
      this.error = this.es.adminErrorMsgList[110].message;
      return;
    }

    if (this.RegistrationForm.form.valid && this.error === '') {
      this.registerCompany();
    }
  }
  async registerCompany() {
    //this.clientCompany.CompanyType.Restaurant = this.restaurantOption;
    this.clientCompany.LogoImage = '';
    if (this.clientCompanyMedia.file) {
      await this.uploadCompanyLogoImage(this.clientCompanyMedia, "", this.fileUploadServ, this, 90, 155, 'cloudflare');
    }
    if (this.clientCompanyBannerMedia.file) {
      await this.uploadImage(this.clientCompanyBannerMedia, "", this.fileUploadServ, this, 'cloudflare');
    }
    if (this.clientOnlinePWAMedia.file) {
      await this.uploadCompanyLogoImage(this.clientOnlinePWAMedia, "", this.fileUploadServ, this, 512, 512, 'cloudflare');
    }

    if (this.error === "") {

      if (this.clientCompanyMedia.url && !this.clientCompany.LogoImage)
        this.clientCompany.LogoImage = this.clientCompanyMedia.url;
      if (this.clientCompanyBannerMedia.url)
        this.clientCompany.CompanyBannerImage = this.clientCompanyBannerMedia.url;
      if (this.clientOnlinePWAMedia.url)
        this.clientCompany.PWAImage = this.clientOnlinePWAMedia.url;
      console.log('this.clientCompany',this.clientCompany);

      this.clientCompanyService.editCompany(this.clientCompany).subscribe(
        async company => {
          if (company) {
            await this.clientCompanyService.getCompanyLogoUrl();
            this.clientCompanyMedia.progress = 0;
            this.clientCompanyBannerMedia.progress = 0;
            Swal.fire("", this.es.adminErrorMsgList[214].message);
          }
          //   this.router.navigate(['']);
        },
        error => {
          this.error = error;
          console.log(this.error);
          Swal.fire("OOPs..!", this.es.adminErrorMsgList[67].message)
        }
      );
    }
  }

  CheckclientCompanyMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientCompanyMedia = {
        url: this.clientCompany.LogoImage || '',
        file: null,
        progress: 0
      };
    }
  }

  CheckclientCompanyBannerMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientCompanyBannerMedia = {
        url: this.clientCompany.CompanyBannerImage || '',
        file: null,
        progress: 0
      };
    }
  }

  CheckOnlinePWAMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientOnlinePWAMedia = {
        url: this.clientCompany.PWAImage || '',
        file: null,
        progress: 0
      };
    }
  }

  async Cancel() {
    Swal.fire(CommonSettings.cancelSwal).then(f => {
      if (f.value) {
        this.router.navigate(['']);
      }
    });
  }
}
