import { Injectable, Inject } from "@angular/core";
import { RoundAmount, TaxAmountCalculation } from "../helper/models/AddHelperModal";
import { BehaviorSubject, Observable, ObservableInput } from "rxjs";
import { SalesOrder } from "../helper/models/SalesOrder";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import * as uuid from 'uuid';
import { ConcessionFoodItem } from "../concession/concession";


@Injectable({
  providedIn: "root",
})
export class DineinService {
  foodItemsInCartObservable = new BehaviorSubject([]);
  foodItemsInCart = this.foodItemsInCartObservable.asObservable();
  totalPriceObservable = new BehaviorSubject(0);
  totalPrice = this.totalPriceObservable.asObservable();
  totalCountObservable = new BehaviorSubject({});
  itemCount = this.totalCountObservable.asObservable();
  selectedItemCount:number;
  dineinSelectedItemCount = 0;
  httpBackedurl: HttpClient;
  ApiBaseUrl: string;
  isShiftOpen:boolean;
  errorHandler: (
    err: any,
    caught: Observable<SalesOrder>
  ) => ObservableInput<any>;

  constructor(
    @Inject("BASE_URL") baseUrl: string,
    private http: HttpClient,
    private backend: HttpBackend
  ) {
    this.selectedItemCount=0;
    this.ApiBaseUrl = baseUrl + "api/";
    this.httpBackedurl = new HttpClient(backend);

    if(sessionStorage.getItem("shiftData") && JSON.parse(sessionStorage.getItem("shiftData")).ShiftId){
      this.isShiftOpen=true;
    }else{
      this.isShiftOpen=false;
    }
  }
  changeFoodItemsInCart(operation, foodItem) {
    let existingFoodItems = [],
    totalPrice = 0 ,itemCount = {};
    this.foodItemsInCart.subscribe((success) => (existingFoodItems = success));
    this.totalPrice.subscribe((success) => {
      totalPrice += success;
    });
    this.itemCount.subscribe((success) => {
      itemCount = success;
    });
    // Concession id is not unique, we need unique id to develop Add-on functionality
    let item = existingFoodItems.findIndex((f) => f.itemUniqueId === foodItem.itemUniqueId );
    if (operation === "minus") {
      this.dineinSelectedItemCount = this.dineinSelectedItemCount - 1;

      // totalPrice = RoundAmount(
      //   totalPrice -
      //     (foodItem.TotalPrice +
      //       RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100)))
      // );

      totalPrice -= foodItem.TotalPrice;

      existingFoodItems[item].FinalPrice = RoundAmount(
        existingFoodItems[item].FinalPrice - foodItem.TotalPrice
      );
      // foodItem.taxAmount = RoundAmount(
      //   foodItem.taxAmount -
      //     RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100))
      // );
      foodItem.taxAmount = RoundAmount(
        foodItem.taxAmount -
        RoundAmount(TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice))
      );
      itemCount[foodItem.ConcessionId]--;
      this.selectedItemCount = this.selectedItemCount-1;
            // let food_item = existingFoodItems.findIndex((f) => f.ConcessionId === foodItem.ConcessionId && f.itemUniqueId == foodItem.itemUniqueId );
      // existingFoodItems[food_item].Quantity = itemCount[foodItem.ConcessionId];
      existingFoodItems.splice(item, 1);
    }else{
      foodItem.FinalPrice = foodItem.TotalPrice;
      if (existingFoodItems.find((ef) => ef.ConcessionId === foodItem.ConcessionId)) {
        itemCount[foodItem.ConcessionId]++;
        // existingFoodItems[item].Quantity = existingFoodItems.length;
      }else{
        // foodItem.Quantity = 1;
        itemCount[foodItem.ConcessionId] = 1;
      }
      // foodItem.unitConcessionTaxAmount = RoundAmount(
      //   foodItem.TotalPrice * (foodItem.Tax / 100)
      // );
      foodItem.unitConcessionTaxAmount = RoundAmount(TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice))
      // foodItem.taxAmount = RoundAmount(
      //   foodItem.FinalPrice * (foodItem.Tax / 100)
      // );
      foodItem.taxAmount = RoundAmount(TaxAmountCalculation(foodItem.Tax, foodItem.FinalPrice))
      foodItem.itemUniqueId = uuid.v4();
      existingFoodItems.push(foodItem);
      this.dineinSelectedItemCount++;
      this.foodItemsInCartObservable.next([...existingFoodItems]);

      // totalPrice = RoundAmount(
      //   totalPrice +
      //     (foodItem.FinalPrice +
      //       RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100)))
      // );

      totalPrice += foodItem.TotalPrice;

      this.selectedItemCount++;
    }
    this.totalPriceObservable.next(totalPrice);
  }
  postSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }

  patchSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .patch<SalesOrder>("/api/SalesOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }

  postOrderToKitchen(kitchenOrder): Observable<Object> {
    return this.httpBackedurl
      .post<Object>("/api/Kitchen", kitchenOrder)
      .pipe(catchError(this.errorHandler));
  }
  //getSalesOrderByShowID(showID) {
  //  return this.httpBackedurl
  //    .get<Array<SalesOrder>>(
  //      this.ApiBaseUrl + "SalesOrder/GetByShowId/" + showID
  //    )
  //}

  getKitchenOrders(startDate, endDate): Observable<Object>  {
    return this.httpBackedurl
      .get('/api/Kitchen/GetKitchenByLocationDate/'+startDate+'/'+endDate)
      .pipe(catchError(this.errorHandler));
  }
  getKitchenOrdersBySalesOrderId(SalesOrderId): Observable<Object>  {
    return this.httpBackedurl
      .get('/api/Kitchen/GetKitchenListBySalesOrderId/'+SalesOrderId)
      .pipe(catchError(this.errorHandler));
  }
  updateKitchenStatus(id: string, status: number,bodyObj): Observable<any> {
    return this.httpBackedurl
      .patch<boolean>(this.ApiBaseUrl + 'Kitchen/UpdateKitchen/' + id +'/' + status, bodyObj)
  }

  updateKitchenSalesOrderIdsForSplitByItem(salesOrderId, kitchenItems): Observable<any> {
    return this.httpBackedurl
      .patch<any>(this.ApiBaseUrl + 'Kitchen/UpdateKitchenSalesOrderIdsForSplitByItem/' + salesOrderId, kitchenItems)
  }

  getAmountBySalesOrderId(id: string): Observable<Object> {
    return this.httpBackedurl
      .get("/api/SalesOrder/" + id )
      .pipe(catchError(this.errorHandler));
  }

  UpdateAuthPaymentDetailsInKitchen(kitchenItem) {
    return this.httpBackedurl.patch<any>(this.ApiBaseUrl + 'Kitchen/UpdateAuthPaymentDetailsInKitchen', kitchenItem)
  }

  authorizeGCPaymentForDineIn(CardNumber, totalPrice) {
    return this.httpBackedurl.get<any>(this.ApiBaseUrl + 'Kitchen/AuthorizeGCPaymentForDineIn/' + CardNumber + "/" + totalPrice)
  }

  updateKitchenOrderComplteStatus(status: number, bodyObj): Observable<any> {
    return this.httpBackedurl
      .patch<boolean>(this.ApiBaseUrl + 'Kitchen/UpdateKitchenOrderStatus/' + status, bodyObj)
  }

  SendOrderNotification(salesOrderId, prepAreaId, allItems) {
    return this.http.post<any>(
      this.ApiBaseUrl + 'Kitchen/SendOrderNotification/' + salesOrderId + "/" + prepAreaId + "/" + allItems, "").pipe(catchError(this.errorHandler));;
  }

  GetKitchenListByPrepArea(SalesOrderId, prepAreaId): Observable<Object> {
    return this.httpBackedurl
      .get('/api/Kitchen/GetKitchenListByPrepArea/' + SalesOrderId + "/" + prepAreaId)
      .pipe(catchError(this.errorHandler));
  }


 }
