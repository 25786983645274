import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Merchant } from 'src/app/merchants/merchant';

@Injectable({
	providedIn: 'root'
})
export class PaymentService extends ApiService {
	ApiBaseUrl;
	httpBackedurl: HttpClient;
	stripeSecretToken: string = '';
	constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient, private backend: HttpBackend) {
		super(baseUrl);
		this.ApiBaseUrl = baseUrl + 'api/';
		this.httpBackedurl = new HttpClient(backend);

  }
	fetchConnectionToken(): Observable<any> {
		return this.http.get<any>(this.ApiBaseUrl + 'SalesOrder/GetStripeConnection').pipe(catchError(this.errorHandler));
	}
	GetIntentSecretToken(cccode): Observable<any> {
		return this.http.get<any>(this.ApiBaseUrl + 'SalesOrder/getClientSecret/' + cccode).pipe(catchError(this.errorHandler));
	}
	passPaymentIntentIdGiftcard(salesOrderid, paymentIntentid, status): Observable<any> {
		return this.httpBackedurl.patch<any>(this.ApiBaseUrl + 'GiftCardSales/ConfirmPayment/' + salesOrderid + '/' + paymentIntentid + '/' + status, null).pipe(catchError(this.errorHandler));
	}
	passPaymentIntentId(salesOrderid, paymentIntentid, status, paymentmode): Observable<any> {
		return this.httpBackedurl.patch<any>(this.ApiBaseUrl + 'SalesOrder/ConfirmPayment/' + salesOrderid + '/' + paymentIntentid + '/' + status + '/' + paymentmode, null).pipe(catchError(this.errorHandler));
  }
  passPaymentIntentIdAuthorize(salesOrderid, paymentIntentid, status, cclastfour): Observable<any> {
    return this.httpBackedurl.patch<any>(this.ApiBaseUrl + 'SalesOrder/ConfirmAuthPayment/' + salesOrderid + '/' + (paymentIntentid ? paymentIntentid : 0) + '/' + (cclastfour == "" ? "NA" : cclastfour) + '/' + status, null).pipe(catchError(this.errorHandler));
	}
}

