import { Injectable } from "@angular/core";

declare var $: any;

@Injectable()
export class CommonSettings {

  public static keybSetting = {
    numpadDef: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
    ExpryDef: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
    ANKeyDef: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      'a b c d e f g h i',
      'j k l m n o p q r',
      's t u v w x y z',
      '{space}'
    ],
    Payment: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      'a b c d e f g h i',
      'j k l m n o p q r',
      's t u v w x y z .',
      '{space}'
    ],
    EmailKeyDef: [
      '@ . 1 2 3 4 5 6 7 8 9 0',
      'a b c d e f g h i',
      'j k l m n o p q r',
      's t u v w x y z',
      '{bksp}',
      '{space}'
    ],
    theme: "hg-theme-default hg-layout-numeric numeric-theme"

  }

  public static dtOptions: DataTables.Settings = {
    responsive: {
      details: {
        renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
      }
    },
    pagingType:'simple_numbers',
    autoWidth: false,
    columnDefs: [
      { targets: [-1], orderable: false, width: "100px", contentPadding: '0' },
      { targets: [0], type: 'natural-ci' }
    ],
    stateSave: true
  };
  public static deleteSwal: Object = {
    title: 'Are you sure?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static cancelSwal: Object = {
    title: 'Are you sure you want to cancel?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static activeSwal: Object = {
    title: 'Active',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static inactiveSwal: Object = {
    title: 'Inactive ',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static movieScheduleSwal: Object = {
    title: 'Are you sure?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }
  public static movieMessageSwal: Object = {
    title: 'Are you sure?',
    type: 'info',
    showConfirmButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'OK',
    // closeOnConfirm: true,
    allowOutsideClick: false
  }

  public static swapSeatSwal: Object = {
    title: 'Are you sure?',
    type: 'info',
    showConfirmButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'OK',
    //closeOnConfirm: true,
    allowOutsideClick: false
  }

  public static errorSwal: Object = {
    title: 'Oops..! Something went wrong',
    text: "",
    type: 'error',
    showConfirmButton: true,
    showCancelButton: false,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'No'
  }

  public static commonErrorSwal: Object = {
    title: 'An error occurred while processing your request, pls try again or contact support',
    text: "",
    type: 'error',
    showConfirmButton: true,
    showCancelButton: false,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Ok',
    cancelButtonText: 'No'
  }

  public static warningSwal: Object = {
    title: 'Are you sure?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static copyTicketTypeSwal: Object = {
    title: 'Ticket type already exist',
    type: 'warning',
    showConfirmButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'OK',
    //closeOnConfirm: true,
    allowOutsideClick: false
  }

  public static laneUsingInPOSTypeSwal: Object = {
    title: 'Please delete the lane on the associated Sales Channel to deactivate this lane',
    type: 'warning',
    showConfirmButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'OK',
    //closeOnConfirm: true,
    allowOutsideClick: false
  }

  public static deleteMerchantCredentialSwal: Object = {
    title: 'Are you sure you want to delete this merchant credential & associate lanes?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static deleteLaneSwal: Object = {
    title: 'Are you sure you want to delete this lane?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static companyHasJobEmail: Object = {
    title: 'You have not provided any Jobs Email ID.  Please set it up in the Company Edit section and come back here to proceed.',
    type: 'warning',
    showConfirmButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'OK',
    //closeOnConfirm: true,
    allowOutsideClick: false
  }

  public static deletePromoteMovieSwal: Object = {
    title: 'Are you sure you want to remove this Promoted Movie?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }

  public static promoteScheduledMovieSwal: Object = {
    title: 'Are you sure you want to Promote this Movie?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }
  public static ConcessionCustomize: Object = {
    title: 'If you exit now you will lose all data. Are you sure you want to proceed?',
    type: 'warning',
    showConfirmButton: true,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }




}
