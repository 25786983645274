import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Screen,Seat } from "./screen";
import { catchError } from "rxjs/operators";
import { ApiService } from "../helper/services/api.service";

@Injectable({
    providedIn: "root"
})
export class ScreenService extends ApiService{
    ApiBaseUrl: string;
  Screens: Screen[] = [];
  Seat: Seat[] = [];
 // printAllScreenQR: Array<Screen['Screen_ID']> = [];
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        super(baseUrl);
      this.ApiBaseUrl = baseUrl + "api/Screen";
      this.ApiEndPoint = baseUrl + "api";
    }

    getScreens(): Observable<Screen[]> {
        return this.http
            .get<Screen[]>(this.ApiBaseUrl + '/GetByLocationId')
            .pipe(catchError(this.errorHandler));
    }

    getScreenByID(id: string): Observable<Screen> {
        return this.http
            .get<Screen>(this.ApiBaseUrl + "/" + id)
            .pipe(catchError(this.errorHandler));
  }

    GetScreen(id: string): Observable<Screen> {
    return this.http
      .get<Screen>(this.ApiBaseUrl + "/" + id)
      .pipe(catchError(this.errorHandler));
  }

  GetOnlineQrCodeByScreen(Id: string,companyId:string,screenid: string): Observable<any> {
    return this.http.get<Screen>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByScreen/" + Id + "/" + companyId +"/" + screenid).pipe(catchError(this.errorHandler));
  }
  GetOnlineQrCodeByTable(Id: string, hallId: string, companyId: string, tableid: string): Observable<any> {
    return this.http.get<Seat>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByTableNumber/" + Id + "/" + hallId + "/" + companyId + "/" + tableid).pipe(catchError(this.errorHandler));
  }
  GetOnlineQrCodeByAllTable(Id: string, companyId: string, hallId: string, printAllSeats: string[]): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByAllTable/" + Id + "/" + companyId + "/" + hallId, printAllSeats, httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }
 /* GetOnlineQrCodeByAllScreen(printAllScreenIds:string[]): Observable<any> {
    return this.http
      .get<Screen[]>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByAllScreen/" + printAllScreenIds)
      .pipe(catchError(this.errorHandler));
  }*/
  GetLocOnlineQrCode(Id: string, companyId: string) {
    return this.http.get<any>(this.ApiEndPoint + '/Screen/GetLocOnlineQrCode/' + Id + "/" + companyId).pipe(catchError(this.errorHandler));
  }

  GetOnlineQrCodeByAllScreen(Id: string, companyId: string, printAllScreen: Screen[]): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByAllScreen/" + Id + "/" + companyId, printAllScreen, httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }

  GetOnlineQrCodeBySeat(Id: string, screenId: string, companyId: string, SeatNum: string): Observable<any> {
    return this.http.get<Seat>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeBySeat/" + Id + "/" + screenId + "/" + companyId + "/" + SeatNum).pipe(catchError(this.errorHandler));
  }

  GetOnlineQrCodeByAllSeat(Id: string, companyId: string, printSeatScreenId: string, printAllTables: string[]): Promise<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http
      .post<any>(this.ApiEndPoint + "/Screen/GetOnlineQrCodeByAllSeat/" + Id + "/" + companyId + "/" + printSeatScreenId, printAllTables, httpOptions)
      .pipe(catchError(this.errorHandler)).toPromise();
  }

  GetOnlineQROrderingByLoc(Id: string, companyId: string) {
    return this.http.get<any>(this.ApiEndPoint + '/Screen/GetOnlineQROrderingByLoc/' + Id + "/" + companyId).pipe(catchError(this.errorHandler));
  }

 /* GetOnlineQrCodeBySeat(SeatNum: Seat): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + "/" + SeatNum.SeatNumber, Screen)
      .pipe(catchError(this.errorHandler));
  }*/

    ScreenHasActiveShows(id: string): Observable<Boolean> {
        return this.http
            .get<Boolean>(this.ApiBaseUrl + "/ScreenHasActiveShows/" + id)
            .pipe(catchError(this.errorHandler));
    }

    addScreen(Screen: Screen): Observable<any> {
        return this.http
            .post(this.ApiBaseUrl, Screen)
            .pipe(catchError(this.errorHandler));
    }

    editScreen(Screen: Screen): Observable<any> {
        return this.http
            .put(this.ApiBaseUrl + "/" + Screen.Screen_ID, Screen)
            .pipe(catchError(this.errorHandler));
    }

    setVisible(ID: number, visible: number): Observable<any> {
        return this.http
            .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
            .pipe(catchError(this.errorHandler));
    }
}
