import { PaymentMode } from "./PlanOrder";

export class SalesDiscount {
    SalesDiscountId: string;
    SalesOrderId: string;
    LocationId: string;
    PhoneholdId:string;
    ShowId: string;
    DiscountDetails: Array<DiscountDetails> = new Array<DiscountDetails>();   
}

export class DiscountDetails{
  SalesDiscountType: SalesDiscountType;
  ManagerPin:number;
  ManagerEmail: string;
  MainReason:string;
  ReasonNote :string;
  ActualAmount:number;
  TotalAmount :number;   //this will store the price paid after discount or refund
  AppliedDate:any;
  OriginalSeats:  Array<string> =[];
  FinalSeats: Array<string>=[];
  OriginalSeatNumbers:number;
  TotalSeatNumbers:number;
  Released: boolean;
  PaymentMode: PaymentMode;
  SplitPayment: Array<SplitPayment> = new Array<SplitPayment>();
}

export enum  SalesDiscountType{
    Discount,
    Refund,
    PhoneHold
}

export class SplitPayment {
  PaymentMode: PaymentMode;
  Amount: number;
}
