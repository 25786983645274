import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { LocationsService } from '../locations/locations.service';

@Component({
  selector: 'app-termsconditions',
  templateUrl: './termsconditions.component.html',
  styleUrls: ['./termsconditions.component.css']
})

export class TermsconditionsComponent implements OnInit {
  tcctemp: string = "";

  constructor(private activatedRoute: ActivatedRoute, private companyService: CompanyService, private locationService: LocationsService, private titleService: Title) {
    this.locationService.IshomePage = false;
  }

  ngOnInit(): void {
    this.locationService.getTermsConditions().subscribe((tccpp) => {
      if (tccpp) {
        this.tcctemp = tccpp.TermsAndCondition;
      }
    });
  }
}
