import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { CustomerInfo, PaymentMode, SalesOrder, SalesSplit, SalesStatus, DiscountType, WorldpayTransactionDetails } from '../helper/models/SalesOrder';
import { ErrorMessagesService } from "src/app/helper/services/error-messages.service";
import { CommonSettings } from "src/app/helper/commonSettings";
import Keyboard from "simple-keyboard";
import { PaymentoptionService } from './paymentoption.service';
import { NgForm } from '@angular/forms';
import { ShiftService } from '../shift/shift.service';
import { PrintService } from '../helper/services/print.service';
import { LoaderService } from '../helper/services/loader/loader.service';
import { Shift } from '../shift/shift';
import { Router } from '@angular/router';
import { CloneObject, RoundAmount, PercentageAmountCalculation, GetPercentageByAmount, roundHalf } from '../helper/models/AddHelperModal';
import { KioskPOSService } from '../kiosk-pos/services/kiosk-pos.service';
import { POSService } from '../pos/pos.service';
import { MerchantService } from '../merchants/merchant.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GiftcardService } from '../giftcard/giftcard.service';
import * as moment from 'moment';
import { CommonService } from '../helper/services/common.service';
import { GiftCard, GiftCardStatus } from '../giftcard/giftcard';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { PaymentService } from '../helper/services/payment/payment.service';
import { StyleService } from '../helper/services/docloadings/style.service';
import { DineinService } from '../DineIn/dinein.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConcessionModule } from '../concession/concession.module';
import { Location } from '../../app/locations/location';
import { CompanyService } from '../company/company.service';
import { CurrentUserService } from '../helper/services/current-user.service';
import { SalesDiscount } from '../helper/models/SalesDiscount';
import { EmployeeService } from '../employee/employee.service';
import { LocationsService } from '../locations/locations.service';
declare var $: any;

@Component({
  selector: 'app-paymentoption',
  templateUrl: './paymentoption.component.html',
  styleUrls: ['./paymentoption.component.css']
})
export class PaymentoptionComponent implements OnInit {
  SalesOrderPayment: any = new SalesOrder();
  originalSalesOrderPayment: any = new SalesOrder();

  PreviousSalesOrder: any = null;// use for only redeemption functionality;

  @ViewChild("ccForm", { static: true }) ccForm: NgForm;
  @ViewChild("gcposinput", { static: false }) gcposinput: any;
  @ViewChild("posmeinput", { static: false }) posmeinput: any;
  @ViewChild("ccAuthForm", { static: false }) ccAuthForm: NgForm;

  @ViewChild('dinetipsinput', { static: false }) TipAmountOther;
  ccFormDisabled: boolean = false;
  CardNumber: string = null;
  PayMode = PaymentMode;
  paymentMode: PaymentMode = null;
  selectedMerchant: string;
  ccAuthorize: any = {};
  cc_auth_card_number: any;
  amountSuggestions = [];
  isOtherSelectedFLag = false;
  selectedInput: any;
  keyboard: Keyboard;
  paymentCCInnerMode: string = null;
  customerGivenCash = 0;
  chargeDifference = 0;
  paymentError: string = "";
  actualTotalPrice: number = 0;
  totalPrice: number = 0;
  math = Math;
  shiftData: Shift = new Shift();
  Location: Location = new Location();
  LocationId: string = "";
  posData: any;
  merchantInternalId: any;
  currentMerchantDetails: any;
  cc_last_four: any;
  transactionid: string = null;
  cardTypeWorldpay: any = null;
  worldpayTransactionDetails: WorldpayTransactionDetails;
  dtOptionsFood: DataTables.Settings = {};
  dtTriggerFood: Subject<any> = new Subject<any>();
  splitPaymentType: string = "";
  dtElementFood: DataTableDirective;
  //manualcciframevariables
  isViewMannualiframe: boolean = false;
  url: any = null;

  isSplitByItemOpen: boolean = false; //for update sales order by LoyaltyDiscount
  //splitbyamountvariables
  KitchenPostObjectSubscription: Subscription;
  voidSalesOrderArray = [];
  voidAmount = 0;
  numberOfSplit: number = 0;
  splitArray: any = []
  selectedSplitTobePaidIndex: number = null;
  splitPaymentMode: any = PaymentMode;
  AmountToBeSatteled: number = 0;
  paymentModeArray = [
    { name: "CC", value: 0 },
    { name: "Cash", value: 1 },
    { name: "ManualCC", value: 2 },
    { name: "GiftCard", value: 3 }
  ];
  splitAmountTobeSettled: number = 0;
  SalesStatus = SalesStatus;
  priceKitchenItem: number = 0;
  activeTipsButton: string = "";
  dineInOrderType: any = null;
  @ViewChild("poscashinput", { static: false }) poscashinput: any;
  @ViewChild("discountinput", { static: false }) discountinput: any;
  timer;
  hasDiscountModeOn: boolean = false;
  discountType: number = 0;
  discountTypes = [];
  discountAmount: number = 0;
  disableAfterOnePayment: boolean = false;
  hasLoyaltyModeOn: boolean = false;

  memberdata:any = null;
  loyaltyPoints: number = 0;
  isMemberVerified: boolean = false;
  isSplitByItemMemberVerified: boolean = false;
  IsLoyaltyProgramEnable: boolean = false;
  loyaltysearchby: any = null;
  ispartialRedeem: string = '';
  partialredeempoints: number = 0;
  CompanyDetails: any;
  actualAmount: number = 0;
  actualTax: number = 0;
  UsedLoyaltyPoints: number = 0;
  EarnRedeemPoints: number = 0;
  loyaltyAmount: number = 0;
  total: number = 0;
  IsOriginaldiscountAdded: boolean = false;
  isCancelOrder: boolean;
  userEmail: String = null;
  tipsPercentage: any;
  tipsAmount: any;
  AddChargeLoyalty = 0;

  discountDisable = false; //not in use
  //splitByItemVariable
  // splitByItemPaymentMode = PaymentMode.Cash;
  discountReason:string="";
  ReasonNote:string="";
  OtherReasonNote:string="";
  
  employeelist:any=[];
  SalesDiscount:SalesDiscount = new SalesDiscount();
  DiscountReasonValidFlag:boolean=false;
  timeZone:any;
  ManagerPin: any = "";
  constructor(
    private employeeService: EmployeeService, 
    private es: ErrorMessagesService,
    public companyservice: CompanyService,
    public dineinService: DineinService,
    private paymentOptionService: PaymentoptionService,
    public shiftService: ShiftService,
    public printService: PrintService,
    private LoaderService: LoaderService,
    private router: Router,
    private kioskPOSService: KioskPOSService,
    public posService: POSService,
    private merchantService: MerchantService,
    private toastrService: ToastrService,
    private giftCardService: GiftcardService,
    private commonService: CommonService,
    private PaymentService: PaymentService,
    private stSer: StyleService,
    public currentUserService: CurrentUserService, 
    private dom: DomSanitizer,
    private locationService: LocationsService,
  ) {
    this.LoaderService.isCancelOrder.subscribe((res: any) => {
      this.isCancelOrder = res
    })
    this.currentUserService.get().subscribe(result => {
      console.log("======res",result);
      this.userEmail = result.Email;
    });

    this.stSer.addStyle('pt', require('../../assets/kiosk-pos/css/bootstrap.min.css'));
    this.paymentOptionService.salesOrderObject.subscribe((success) => {
      if (success.length != 0) {
        this.SalesOrderPayment = success;
        this.actualTotalPrice = this.SalesOrderPayment.TotalAmount + this.SalesOrderPayment.TotalTax;
        this.actualTotalPrice = this.SalesOrderPayment.TotalAmount;
        this.totalPrice = this.SalesOrderPayment.TotalAmount;
        this.total = this.SalesOrderPayment.TotalAmount;
        this.CardNumber = null;
        this.customerGivenCash = null;
        this.discountAmount = 0;
        this.SalesOrderPayment.isDineInOrder = false;
        this.AmountToBeSatteled = this.totalPrice;
        this.paymentMode = null;
        this.isMemberVerified = false;
        this.totalPrice = Number(this.totalPrice.toFixed(2));
        this.originalSalesOrderPayment = CloneObject(this.SalesOrderPayment);

        console.log("this.SalesOrderPayment---------------------------------", this.SalesOrderPayment);
      }
      // this.PaymentMode(PaymentMode.Cash);
      // this.getCashChangePosibilities();
    });

    this.Location = JSON.parse(sessionStorage.getItem("location"));
    this.LocationId = JSON.parse(sessionStorage.getItem("location")).Location_ID;
    this.posData = JSON.parse(sessionStorage.getItem("posData"));
    this.merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));
    this.shiftData = JSON.parse(sessionStorage.getItem("shiftData"));
    if (this.dineinService.isShiftOpen) {
      this.posData = JSON.parse(sessionStorage.getItem("posData"));
      this.shiftData = JSON.parse(sessionStorage.getItem("shiftData"));
      this.shiftData.StartTime = new Date(this.shiftData?.StartTime);
      this.shiftData.EndTime = new Date(this.shiftData?.EndTime);
    }
    this.discountAmount = 0;
    // Add dicount type
    this.discountTypes.push({ name: 'Percentage', value: 0 });
    this.discountTypes.push({ name: 'Amount', value: 1 });
  }
  async salesOrderProcess(success) {
    let res = success.saleOrderPay;
    if (res.ConfirmationCode) {
      console.log("res.", res);
      console.log("success.", success);
      this.loyaltyPoints = res.OrderLoyaltyCredit;
      this.isMemberVerified = false;
      this.loyaltysearchby = null;
      this.totalPrice = 0;      
      this.SalesOrderPayment = res;     
      this.SalesOrderPayment.Kitchen_Id = success.Kitchen_Id;
      this.SalesOrderPayment.concessionPaymentItem = success.concessionPaymentItem;
      this.SalesOrderPayment.concessionPaymentItemCopy = CloneObject(success.concessionPaymentItem);
      this.SalesOrderPayment.concessionPaymentItemCopy2 = CloneObject(success.concessionPaymentItem);  // This is only for Showing Concession On Tips PopUp for SplitBy Item to restore
      // this.actualTotalPrice = this.SalesOrderPayment.TotalAmount + this.SalesOrderPayment.TotalTax;
      this.CardNumber = null;
      this.customerGivenCash = null;
      this.isSplitByItemMemberVerified=false;
      this.memberdata=null;
      this.discountAmount = 0;
      this.SalesOrderPayment.isDineInOrder = true;
      this.SalesOrderPayment.saleskitchenOrders = success.saleskitchenOrders;
      await this.SalesOrderPayment.Concession.forEach((co, index) => {
        this.SalesOrderPayment.Concession[index].Price += this.SalesOrderPayment.Concession[index].Tax;
      });
      // await this.SalesOrderPayment.concessionPaymentItem.forEach((co) => {
      //   co.Price += co.Tax;
      // });
      let concessionPaymentItem = this.SalesOrderPayment.concessionPaymentItem.filter(
        (i) => i.Status == SalesStatus.OnHold
      );
      var addonConcessionItems = [], onholdKitchenPrice = 0, onholdKitchenTax = 0;
      for (let p of concessionPaymentItem) {
        onholdKitchenPrice += Number(
          ((p["Price"]) * p["NumberSold"]).toFixed(2)
        );
        onholdKitchenTax += Number(
          (p["Tax"] * p["NumberSold"]).toFixed(2)
        );
        // onholdKitchenPrice -= onholdKitchenTax;
        if (p.AddonConcessionName == null && p.NumberSold == 1) {
          var addonConcession = concessionPaymentItem.filter(
            (x) =>
              x.AddonConcessionName != null &&
              x.AddonConcessionName.indexOf(p.ConcessionName) !== -1 &&
              addonConcessionItems.filter((a) => a == x.AddonConcessionName)
                .length == 0
          );
          // Need to check if we have two concession and diffrent addon items
          if (addonConcession.length > 0) {
            addonConcessionItems.push(addonConcession[0].AddonConcessionName);
            p.addonItems = concessionPaymentItem.filter(
              (x) =>
                x.AddonConcessionName ==
                addonConcession[0].AddonConcessionName
            );
          }
        }
      }
      // this.PaymentMode(PaymentMode.Cash);
      // onholdKitchenPrice -= onholdKitchenTax;
      console.log("onholdKitchenPrice", onholdKitchenPrice);
      this.paymentMode = null;
      // this.totalPrice = this.SalesOrderPayment.TotalAmount + this.SalesOrderPayment.TotalTax;

      this.totalPrice = Number(onholdKitchenPrice.toFixed(2));
      this.SalesOrderPayment.TotalAmount = this.totalPrice;
      this.SalesOrderPayment.TotalTax = onholdKitchenTax;
      // this.totalPrice = Number(this.totalPrice.toFixed(2));
      this.AmountToBeSatteled = this.totalPrice;
      this.actualTotalPrice = this.totalPrice;
      this.originalSalesOrderPayment = CloneObject(this.SalesOrderPayment);
      this.originalSalesOrderPayment.TotalAmount = Number(this.totalPrice.toFixed(2));
    }
  }
  async ngOnInit() {
    var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
    if (locationDetail && locationDetail.Company_ID) {
      await this.locationService.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((res: any) => {
        console.log("company", res);
        this.IsLoyaltyProgramEnable = res.IsLoyaltyProgramEnable;
      })
    } else {
      Swal.fire('OOPS! Session expired.', ' Please select location again.')
      this.router.navigate(['/locations']);
    }
    console.log("ON INIT PAY OPTION");

    this.KitchenPostObjectSubscription = this.paymentOptionService.KitchenPostObject.subscribe((success: any) => {
      if (success.servedSalesOrderId) {
        // await this.kioskPOSService.getSalesOrder(success.servedSalesOrderId).subscribe(
        //   async (res) => {
        this.salesOrderProcess(success)
        // },
        // (error) => {
        //   Swal.fire(this.es.posErrorMsgList[10].message, this.es.posErrorMsgList[9].message, "error");
        // });

        // this.getCashChangePosibilities();
      }
    });
    this.getMerchantProviderByCompanyId();
    this.dtOptionsFood = {
      responsive: true,
      autoWidth: false,
      info: false,
      paging: false,
      searching: false,
      // pageLength: 100,
      order: [[0, "dsc"]],
      columnDefs: [
        { targets: [0], orderable: false, width: "10px", contentPadding: "0" },
        { targets: [1], orderable: false, width: "10px", contentPadding: "0" },
        { targets: [2], orderable: false, width: "10px", contentPadding: "0" },
      ],
    };
    var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
    if (locationDetail && locationDetail.Company_ID) {
      await this.locationService.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((company) => {
        if (company) {
          this.CompanyDetails = company;
        }
      });
    } else {
      Swal.fire('OOPS! Session expired.', ' Please select location again.')
      this.router.navigate(['/locations']);
    }
    this.employeeService.getEmployeesWithRoles().subscribe(async(Employee) => {
      console.log(Employee);
      // this.employeelist = await Employee.filter(a => a.SelectedLocationAndRoles.filter(location => location.LocVisible));
      Employee.forEach(e=>{
        if(e.SelectedLocationAndRoles.filter(lid=>lid.LocationId== this.LocationId && lid.LocVisible).length > 0){
          this.employeelist.push(e);
        }    
      });
      console.log("EMPLOYEE LIST",this.employeelist);
    }, error => {
    });
    this.commonService.GetDateTimeByTimeZone().subscribe((tz) => {
      this.timeZone = tz;
    });
  }
  async getMerchantProviderByCompanyId() {
    this.currentMerchantDetails = await this.merchantService
      .getCompanyMerchant()
      .toPromise();
    console.log("companyDetail", this.currentMerchantDetails);
    this.selectedMerchant = this.currentMerchantDetails.MerchantName;
  }
    UpdateSalesPendingStatus(type: PaymentMode){
    console.log("PaymentMode------",type);
    let orderstatus ;
    if(type == PaymentMode.CC || type == PaymentMode.ManualCC){
      orderstatus = 1;
    }else if (type == PaymentMode.Cash){
      orderstatus = 2;
    }else if (type == PaymentMode.GiftCard){
      orderstatus = 3;
    }else if (type == PaymentMode.Split){
      orderstatus = 5;
    }
    this.posService.UpdatePenddingSalesOrder(this.originalSalesOrderPayment,orderstatus,this.LocationId,this.userEmail).subscribe(
      async (result) => {
      }, (err) => {
        Swal.fire(
          "",
          err,
          "error"
        );
        this.LoaderService.hidePaymentloader();
      });

  }
  PaymentMode(type: PaymentMode) {
    console.log('PaymentMode', type);
    this.UpdateSalesPendingStatus(type);
    this.paymentOptionService.paymentType.next(type);
    if (this.SalesOrderPayment.isDineInOrder && type != PaymentMode.Split) {
      $("#kitchenPaymentModal").modal("show");
      this.dineInOrderType = type;
      this.CardNumber = null;
      this.tipsAmount = '';
      this.tipsPercentage = '';
      this.activeTipsButton = "";
      this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
      this.paymentMode = type;
      this.hasDiscountModeOn = false;
      this.hasLoyaltyModeOn = false;
    } else if (this.SalesOrderPayment.isDineInOrder && type == PaymentMode.Split) {
      this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
      this.totalPrice = Number(this.totalPrice.toFixed(2));
      this.CardNumber = null;
      this.paymentMode = type;
      this.hasDiscountModeOn = false;
      this.hasLoyaltyModeOn = false;
      this.tipsAmount = '';
      this.tipsPercentage = '';
      this.activeTipsButton = "";
      this.splitPaymentType = "splitByAmount";
      this.AmountToBeSatteled = this.totalPrice;
      setTimeout(() => {
        $("#payBtnModal").attr("disabled", "true");
      }, 100);
      //$("#payBtnModal").attr("disabled", "true");
    } else {
      this.CardNumber = null;
      this.paymentMode = type;
      this.hasDiscountModeOn = false;
      this.hasLoyaltyModeOn = false;
      if (type == PaymentMode.CC) {
        this.ccFormDisabled = true;
        this.hasDiscountModeOn = false;
        this.hasLoyaltyModeOn = false;
      } else if (type == PaymentMode.Split) {
        this.hasDiscountModeOn = false;
        this.hasLoyaltyModeOn = false;
        this.splitPaymentType = "splitByAmount";
        console.log(this.totalPrice);
        this.AmountToBeSatteled = this.totalPrice;
        setTimeout(() => {
          $("#payBtnModal").attr("disabled", "true");
        }, 100);
        // this.SetSplitPaymentSalesOrderArray();
        // this.totalPrice = 0;
        // this.SetSplitPaymentType(this.splitPaymentType);
      } else if (type == PaymentMode.GiftCard) {
        this.paymentCCInnerMode = null;
        this.loadKeypad();
        this.hasDiscountModeOn = false;
        this.hasLoyaltyModeOn = false;
        setTimeout(() => {
          this.gcposinput.nativeElement.focus();
        }, 10);
      } else if (type == PaymentMode.Cash) {
        this.paymentCCInnerMode = null;
        this.hasDiscountModeOn = false;
        this.hasLoyaltyModeOn = false;
        setTimeout(() => {
          this.getCashChangePosibilities();
          this.poscashinput.nativeElement.focus();
        }, 0);
      }
    }

  }

  PaymentCCInnerMode(type: string) {
    this.paymentCCInnerMode = type;
    if (!this.SalesOrderPayment.isDineInOrder) {
      this.paymentCCInnerMode = type;
      if (type == "CCManualAuthorize" && this.selectedMerchant != 'Worldpay') {
        setTimeout(() => {
          this.posmeinput.nativeElement.focus();
        }, 0);
      }
      if (type == "CCManualAuthorize" && this.selectedMerchant == 'Worldpay') {
        this.getTransactionSetupid(false)
      }
      if (type == "SwipeAuthorize" && this.selectedMerchant == 'Worldpay') {
        this.sendSwipeIndication()
      }
    }

  }

  SetWorldpayTransactionDetails(result, paymentMode) {
    this.worldpayTransactionDetails = new WorldpayTransactionDetails();
    this.worldpayTransactionDetails.LocationAddress = this.Location.Address;
    this.worldpayTransactionDetails.LocationPhoneNumber = this.Location.PhoneNumber;
    this.worldpayTransactionDetails.CardLogo = result.CardLogo ? result.CardLogo : result.cardLogo;
    this.worldpayTransactionDetails.EntryMode = result.entryMode ? result.entryMode : (paymentMode == PaymentMode.ManualCC ? "ManualCC" : "");
    this.worldpayTransactionDetails.ApprovalNumber = result.approvalNumber ? result.approvalNumber : result.ApprovalNumber;

    if (paymentMode == PaymentMode.CC) {
      this.worldpayTransactionDetails.PINVerified = result.pinVerified;

      if (result._processor.hostResponseCode || result._processor.hostResponseMessage) {
        this.worldpayTransactionDetails.ResponseCode = result._processor.expressResponseCode;
        this.worldpayTransactionDetails.ResponseMessage = result._processor.expressResponseMessage;
      }
      else {
        this.worldpayTransactionDetails.ResponseCode = result._processor.hostResponseCode;
        this.worldpayTransactionDetails.ResponseMessage = result._processor.hostResponseMessage;
      }
    }
    else {
      if (result.HostResponseCode || result.HostResponseMessage) {
        this.worldpayTransactionDetails.ResponseCode = result.ExpressResponseCode;
        this.worldpayTransactionDetails.ResponseMessage = result.ExpressResponseMessage;
      }
      else {
        this.worldpayTransactionDetails.ResponseCode = result.HostResponseCode;
        this.worldpayTransactionDetails.ResponseMessage = result.HostResponseMessage;
      }
    }

    if (result.emv && paymentMode == PaymentMode.CC) {
      this.worldpayTransactionDetails.ApplicationId = result.emv.applicationIdentifier;
      this.worldpayTransactionDetails.ApplicationLabel = result.emv.applicationLabel;
      this.worldpayTransactionDetails.ApplicationPreferredName = result.emv.applicationPreferredName;
      this.worldpayTransactionDetails.Cryptogram = result.emv.cryptogram;
    }

    // Set "****" for null values
    var emptyValue = "****";
    this.worldpayTransactionDetails.LocationAddress = this.worldpayTransactionDetails.LocationAddress ? this.worldpayTransactionDetails.LocationAddress : emptyValue;
    this.worldpayTransactionDetails.LocationPhoneNumber = this.worldpayTransactionDetails.LocationPhoneNumber ? this.worldpayTransactionDetails.LocationPhoneNumber : emptyValue;
    this.worldpayTransactionDetails.CardLogo = this.worldpayTransactionDetails.CardLogo ? this.worldpayTransactionDetails.CardLogo : emptyValue;
    this.worldpayTransactionDetails.EntryMode = this.worldpayTransactionDetails.EntryMode ? this.worldpayTransactionDetails.EntryMode : emptyValue;
    this.worldpayTransactionDetails.ApprovalNumber = this.worldpayTransactionDetails.ApprovalNumber ? this.worldpayTransactionDetails.ApprovalNumber : emptyValue;
    this.worldpayTransactionDetails.ResponseCode = this.worldpayTransactionDetails.ResponseCode ? this.worldpayTransactionDetails.ResponseCode : emptyValue;
    this.worldpayTransactionDetails.ResponseMessage = this.worldpayTransactionDetails.ResponseMessage ? this.worldpayTransactionDetails.ResponseMessage : emptyValue;
    this.worldpayTransactionDetails.ApplicationId = this.worldpayTransactionDetails.ApplicationId ? this.worldpayTransactionDetails.ApplicationId : emptyValue;
    this.worldpayTransactionDetails.ApplicationLabel = this.worldpayTransactionDetails.ApplicationLabel ? this.worldpayTransactionDetails.ApplicationLabel : emptyValue;
    this.worldpayTransactionDetails.ApplicationPreferredName = this.worldpayTransactionDetails.ApplicationPreferredName ? this.worldpayTransactionDetails.ApplicationPreferredName : emptyValue;
    this.worldpayTransactionDetails.Cryptogram = this.worldpayTransactionDetails.Cryptogram ? this.worldpayTransactionDetails.Cryptogram : emptyValue;
  }

  sendSwipeIndication() {
    if (this.selectedMerchant == 'Worldpay') {
      let lane_Id = this.posData?.LaneId ? this.posData.LaneId : this.SalesOrderPayment.LaneId;
      let merchantId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
      this.merchantService.WorldpayLaneCheckConnectionStatus(merchantId, lane_Id).subscribe(helthCheckResult => {
        if (helthCheckResult == "Open") {
          this.LoaderService.showPaymentloader();
          this.merchantService.WorldpaySale(merchantId, lane_Id, Number(this.totalPrice.toFixed(2)), this.SalesOrderPayment.SalesChannel, this.SalesOrderPayment.ConfirmationCode).subscribe(result => {
            if (result.statusCode == "Approved") {
              this.transactionid = result.transactionId;
              this.cardTypeWorldpay = result.paymentType;
              this.SetWorldpayTransactionDetails(result, PaymentMode.CC);
              this.cc_last_four = result.binValue + result.accountNumber.substring(result.accountNumber.length - 4);
              this.SalesOrderPayment.CCLastFour = this.cc_last_four;
              this.paymentOptionService.salesObj.next([this.SalesOrderPayment]);
              if (!this.isCancelOrder) {
                if (this.SalesOrderPayment.PaymentMode != PaymentMode.Split) {
                  this.placeOrder();
                } else {
                  this.splitOrderPay()
                }
              } else {
                this.LoaderService.hidePaymentloader();
                this.LoaderService.isCancelOrder.next(false);
              }
             
            } else if (result.statusCode.indexOf("insufficient") !== -1 || result.statusCode.indexOf("Merchant Credential") !== -1) {
              Swal.fire("Transaction failed", result.statusCode, "error");
              this.postFailedSlaesOrder();
              this.LoaderService.hidePaymentloader();
              this.LoaderService.popClose();
            } else {
              // Swal.fire("Error", result.statusCode, "error");
              Swal.fire(this.es.posErrorMsgList[41].message, this.es.posErrorMsgList[42].message, "error");
              if (this.SalesOrderPayment.PaymentMode != PaymentMode.Split) {
                this.postFailedSlaesOrder();
                this.LoaderService.hidePaymentloader();
                this.LoaderService.popClose();
              } else {
                // this.splitArray[this.selectedSplitTobePaidIndex].Status = SalesStatus.Failed;
                // this.selectedSplitTobePaidIndex = null;
                this.LoaderService.hidePaymentloader();
                this.LoaderService.popClose();
              }
            }
          }, err => {
            Swal.fire(this.es.posErrorMsgList[41].message, this.es.posErrorMsgList[42].message, "error");
            if (this.SalesOrderPayment.PaymentMode != PaymentMode.Split) {
              this.postFailedSlaesOrder();
            } else {
              this.splitArray[this.selectedSplitTobePaidIndex].Status = SalesStatus.Failed;
              this.selectedSplitTobePaidIndex = null;
            }
            this.LoaderService.hidePaymentloader();
          });
        }
        else {
          Swal.fire("", this.es.posErrorMsgList[119].message, "warning");
          this.LoaderService.hidePaymentloader();
        }
      }, err => {
        Swal.fire("", this.es.posErrorMsgList[119].message, "warning");
        this.LoaderService.hidePaymentloader();
      });
    }
  }
  async postFailedSlaesOrder() {
    //if (this.SalesOrderPayment.isDineInOrder) {
    //  if (this.SalesOrderPayment.Concession.length > 0) {
    //    this.SalesOrderPayment.Concession.forEach((co, index) => {
    //      this.SalesOrderPayment.Concession[index].Status = SalesStatus.Failed;
    //    });
    //  }
    //  if (this.SalesOrderPayment.Tickets.length > 0) {
    //    this.SalesOrderPayment.Tickets.forEach((co, index) => {
    //      this.SalesOrderPayment.Tickets[index].Status = SalesStatus.Failed;
    //    });
    //  }
    //  if (this.SalesOrderPayment.GiftCardSale.length > 0) {
    //    this.SalesOrderPayment.GiftCardSale.forEach((co, index) => {
    //      this.SalesOrderPayment.GiftCardSale[index].Status = SalesStatus.Failed;
    //    });
    //  }
    //  this.posService.postSalesOrder(this.SalesOrderPayment).subscribe(
    //    async (response) => {
    //      this.posService.cancelSales(response).subscribe(
    //        async (result) => {

    //        })
    //    }, (err) => {
    //      this.LoaderService.hidePaymentloader();
    //      Swal.fire(this.es.posErrorMsgList[34].message, err, "error");
    //      this.cc_last_four = "";
    //      this.cc_auth_card_number = "";
    //      this.printService.printModeEnabled = false;
    //    })
    //}
  }
  PaymentInputFocus(event) {
    this.selectedInput = `#${event.target.id}`;
    event.target.addEventListener("input", this.onInputChange);
    let def = CommonSettings.keybSetting.Payment;
    if (event.target.getAttribute("ktype") == "number")
      def = CommonSettings.keybSetting.numpadDef;
    else if (event.target.getAttribute("ktype") == "email")
      def = CommonSettings.keybSetting.EmailKeyDef;
    else if (event.target.getAttribute("ktype") == "expiryDate")
      def = CommonSettings.keybSetting.ExpryDef;

    this.keyboard?.setOptions({
      layout: {
        default: def,
      },

      inputName: event.target.id,
    });
    $(".hg-theme-default .hg-button").css("background", "#76c3eb52");
    $(".hg-theme-default .hg-button").css("color", "#000");
    $(".hg-theme-default .hg-button").css("border", "0");
  }
  onInputChange = (event: any) => {
    this.keyboard?.setInput(event.target.value, event.target.id);
  };
  public onBlurCardNumber() {
    if (
      this.cc_auth_card_number &&
      this.cc_auth_card_number.endsWith("?") &&
      this.cc_auth_card_number.split("?").length >= 3
    ) {
      var details = this.cc_auth_card_number.split(";");
      var cardnumber = details[1]?.split("=")[0];
      var cc = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
      var testflag = details[1] ? cc.test(cardnumber) : (testflag = false);
      if (!testflag) {
        Swal.fire({
          title: this.es.posErrorMsgList[72].message,
          type: "warning",
        });
        this.cc_auth_card_number = "";
        $("#auth_card_number_swipe").val('');
        return null;
      }
      if (this.SalesOrderPayment.PaymentMode != PaymentMode.Split) {
        this.placeOrder();
      } else {
        this.splitOrderPay()
      }
    } else {
      this.cc_auth_card_number = "";
    }
  }
  async getCashChangePosibilities() {
    this.ccForm?.form.reset();
    this.ccAuthForm?.form.reset();
    this.paymentError = "";
    this.loadKeypad();
    this.amountSuggestions = [];
    this.customerGivenCash = null;
    this.amountSuggestions.push(this.totalPrice);
    if (Math.ceil(this.totalPrice) != this.totalPrice) {
      this.amountSuggestions.push(Math.ceil(this.totalPrice));
    }
    if (this.totalPrice > 0) {
      if (Math.ceil(this.totalPrice) % 2 != 0) {
        var nextMultipleof_two = this.totalPrice + (2 - (this.totalPrice % 2));
        if (this.amountSuggestions.indexOf(nextMultipleof_two) == -1) {
          nextMultipleof_two
            ? this.amountSuggestions.push(nextMultipleof_two)
            : "";
        }
      }
      var nextMultipleof_five = Math.ceil(this.totalPrice / 5) * 5;
      if (this.amountSuggestions.indexOf(nextMultipleof_five) == -1) {
        nextMultipleof_five
          ? this.amountSuggestions.push(nextMultipleof_five)
          : "";
      }
      var nextMultipleof_ten = this.totalPrice + (10 - (this.totalPrice % 10));
      if (this.amountSuggestions.indexOf(nextMultipleof_ten) == -1) {
        nextMultipleof_ten
          ? this.amountSuggestions.push(nextMultipleof_ten)
          : "";
      }
      var nextMultipleof_fifty =
        this.totalPrice + (50 - (this.totalPrice % 50));
      if (this.amountSuggestions.indexOf(nextMultipleof_fifty) == -1) {
        nextMultipleof_fifty
          ? this.amountSuggestions.push(nextMultipleof_fifty)
          : "";
      }
      var nextMultipleof_hundred =
        this.totalPrice + (100 - (this.totalPrice % 100));
      if (this.amountSuggestions.indexOf(nextMultipleof_hundred) == -1) {
        nextMultipleof_hundred
          ? this.amountSuggestions.push(nextMultipleof_hundred)
          : "";
      }
      var nextMultipleof_twenty =
        this.totalPrice + (20 - (this.totalPrice % 20));
      if (
        this.amountSuggestions.indexOf(nextMultipleof_twenty) == -1 &&
        nextMultipleof_twenty < nextMultipleof_hundred
      ) {
        nextMultipleof_twenty
          ? this.amountSuggestions.push(nextMultipleof_twenty)
          : "";
      }
      this.amountSuggestions.sort(function (a, b) {
        return a - b;
      });
      this.amountSuggestions.push("Other");
      if (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay") {
        // this.paymentMode = PaymentMode.Cash;
      }
    }
  }
  async placeOrder() {
    if (!this.SalesOrderPayment.isDineInOrder) {
      $("#payBtnModal").attr("disabled", "true");
      this.printService.printModeEnabled = true;
      this.LoaderService.showPaymentloader();
      this.shiftService
        .getShiftById(this.shiftData.ShiftId)
        .subscribe(async (res: Shift) => {
          if (res.Status != "Open") {
            this.LoaderService.hidePaymentloader();
            Swal.fire({
              title: this.es.posErrorMsgList[43].message,
              text: this.es.posErrorMsgList[44].message,
              showCancelButton: false,
              type: "warning",
            }).then(() => {
              this.router.navigate([
                `/locations/setting/${this.LocationId}/pos-shift`,
                this.LocationId,
              ]);
              this.printService.printModeEnabled = false;
            });
          } else {
            if (this.CardNumber) this.SalesOrderPayment.GiftCardNumber = this.CardNumber;
            if (this.SalesOrderPayment.Tickets?.length > 0) {
              await this.SalesOrderPayment.Tickets.forEach(async (g, index) => {
                this.SalesOrderPayment.Tickets[index].Status = this.paymentMode == PaymentMode.CC ? SalesStatus.OnHold : SalesStatus.Reserved
              });
            }
            if (this.SalesOrderPayment.GiftCardSale?.length > 0) {
              await this.SalesOrderPayment.GiftCardSale.forEach(async (g, index) => {
                this.SalesOrderPayment.GiftCardSale[index].Status = this.paymentMode == PaymentMode.CC ? GiftCardStatus.Pending : GiftCardStatus.Sold
              });
            }
            if (this.SalesOrderPayment.Concession?.length > 0) {
              await this.SalesOrderPayment.Concession.forEach(async (g, index) => {
                this.SalesOrderPayment.Concession[index].Status = this.paymentMode == PaymentMode.CC ? SalesStatus.OnHold : SalesStatus.Reserved
              });
            }
            if (this.paymentMode == PaymentMode.Cash) {
              this.SalesOrderPayment.PaymentMode = this.paymentMode;
              if (
                !this.customerGivenCash.toString().match("^[0-9]*\\.?[0-9]*$") ||
                this.customerGivenCash.toString().indexOf(".") == 0
              ) {
                Swal.fire("Error", this.es.posErrorMsgList[46].message, "error");
                this.LoaderService.hidePaymentloader();
                this.printService.printModeEnabled = false;
                return;
              }
            }
            if (this.paymentMode == PaymentMode.CC) {
              if (this.paymentCCInnerMode == "SwipeAuthorize") {
                if (this.selectedMerchant == "Authorize.net") {
                  this.SalesOrderPayment.CreditCardinfo = this.cc_auth_card_number;
                  this.SalesOrderPayment.PaymentMode = PaymentMode.CC;
                  this.cc_last_four = this.cc_auth_card_number
                    ?.split(";")[1]
                    ?.split("=")[0];
                  this.cc_last_four = this.cc_last_four?.substring(
                    this.cc_last_four.length - 4
                  );
                }
                if (this.selectedMerchant == "Worldpay") {
                  this.SalesOrderPayment.CreditCardinfo = this.cc_auth_card_number;
                  this.SalesOrderPayment.PaymentMode = PaymentMode.CC;
                  this.SalesOrderPayment.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
                  this.SalesOrderPayment.LaneId = this.posData.LaneId;
                  this.SalesOrderPayment.MerchantName = "Worldpay";
                  this.SalesOrderPayment.PaymentID = this.transactionid;
                  this.SalesOrderPayment.PaymentType = this.cardTypeWorldpay;
                  this.SalesOrderPayment.CCLastFour = this.cc_last_four;
                  this.SalesOrderPayment.WorldpayTransactionDetails = this.worldpayTransactionDetails;
                }
              } else if (this.paymentCCInnerMode == "CCManualAuthorize") {
                if (this.selectedMerchant == "Authorize.net") {
                  if (this.ccAuthForm?.form.invalid) {
                    this.LoaderService.hidePaymentloader();
                    this.paymentError = "Fill Form With Right Value";
                    this.printService.printModeEnabled = false;
                    return;
                  }
                  this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
                    this.ccAuthorize["cardNumber"].length - 4
                  );
                  this.SalesOrderPayment.PaymentMode = PaymentMode.ManualCC;
                  this.SalesOrderPayment.CreditCardinfo =
                    this.ccAuthorize["fullName"].trim() +
                    "^" +
                    this.ccAuthorize["cardNumber"] +
                    "^" +
                    this.ccAuthorize["mm"] +
                    "^" +
                    this.ccAuthorize["yy"] +
                    "^" +
                    this.ccAuthorize["cvv"] +
                    "^" +
                    this.ccAuthorize["zipcode"];
                }
                if (this.selectedMerchant == "Worldpay") {
                  this.SalesOrderPayment.MerchantName = "Worldpay";
                  this.SalesOrderPayment.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
                  //WORLD PAY CUSTOM FORM PARAMS

                  // if (this.ccAuthForm.form.invalid) {
                  //   this.LoaderService.hidePaymentloader();
                  //   this.paymentError = "Fill Form With Right Value";
                  //   this.printService.printModeEnabled = false;
                  //   return;
                  // }
                  // this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
                  //   this.ccAuthorize["cardNumber"].length - 4
                  // );
                  // this.SalesOrderPayment.PaymentMode = PaymentMode.ManualCC;
                  // this.SalesOrderPayment.CreditCardinfo =
                  //   this.ccAuthorize["fullName"].trim() +
                  //   "^" +
                  //   this.ccAuthorize["cardNumber"] +
                  //   "^" +
                  //   this.ccAuthorize["mm"] +
                  //   "^" +
                  //   this.ccAuthorize["yy"] +
                  //   "^" +
                  //   this.ccAuthorize["cvv"] +
                  //   "^" +
                  //   this.ccAuthorize["zipcode"];
                  // this.SalesOrderPayment.CustomerInfo = new CustomerInfo();
                  // this.SalesOrderPayment.CustomerInfo.CardNumber = this.ccAuthorize["cardNumber"];
                  // this.SalesOrderPayment.CustomerInfo.Expiry = this.ccAuthorize["mm"];
                  // this.SalesOrderPayment.CustomerInfo.ExpiryYear = this.ccAuthorize["yy"];
                  // this.SalesOrderPayment.CustomerInfo.Cvv = this.ccAuthorize["cvv"];

                  //*****************************************************
                  // WORLD PAY IFRAME FORM PARAMS
                  this.SalesOrderPayment.PaymentMode = PaymentMode.ManualCC;
                  this.SalesOrderPayment.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
                  this.SalesOrderPayment.PaymentID = this.transactionid;
                  this.SalesOrderPayment.CCLastFour = this.cc_last_four;
                  this.SalesOrderPayment.PaymentType = this.cardTypeWorldpay;
                  this.SalesOrderPayment.MerchantName = "Worldpay";
                  this.SalesOrderPayment.LaneId = this.posData.LaneId;
                  this.SalesOrderPayment.WorldpayTransactionDetails = this.worldpayTransactionDetails;
                }
              }
              this.ccAuthForm?.form.reset();
              this.loadKeypad();

            }
            if (this.paymentMode == PaymentMode.GiftCard) {
              if (
                !this.CardNumber ||
                (this.CardNumber && this.CardNumber.toString().length < 4 || this.CardNumber.toString().split('.').length != 1)
              ) {
                Swal.fire({
                  title: this.es.posErrorMsgList[47].message,
                  text: this.es.posErrorMsgList[48].message,
                  showCancelButton: false,
                  type: "warning",
                });
                this.LoaderService.hidePaymentloader();
                $("#payBtnModal").removeAttr("disabled");
                this.printService.printModeEnabled = false;
                return;
              }
              if (this.SalesOrderPayment.GiftCardSale.length === 0) {

                if (this.CardNumber != null) {
                  const res: GiftCard = await this.giftCardService.getGiftCardbyCardNumber(
                    this.CardNumber
                  );
                  if (res != null) {
                    if (res.Status === GiftCardStatus.Registered) {
                      Swal.fire({
                        title: this.es.posErrorMsgList[49].message,
                        text: this.es.posErrorMsgList[50].message,
                        showCancelButton: false,
                        type: "warning",
                      });
                      this.LoaderService.hidePaymentloader();
                      $("#payBtnModal").removeAttr("disabled");
                      this.printService.printModeEnabled = false;
                      return;
                    } else {
                      this.SalesOrderPayment.PaymentMode = PaymentMode.GiftCard;
                      const patchres = await this.giftCardService.patchGiftCard(
                        this.CardNumber,
                        this.totalPrice
                      );
                      if (!patchres) {
                        Swal.fire({
                          title: this.es.posErrorMsgList[51].message,
                          text: this.es.posErrorMsgList[52].message,
                          showCancelButton: false,
                          type: "warning",
                        });
                        this.LoaderService.hidePaymentloader();
                        $("#payBtnModal").removeAttr("disabled");
                        this.printService.printModeEnabled = false;
                        return;
                      }
                    }
                  } else {
                    Swal.fire({
                      title: this.es.posErrorMsgList[53].message,
                      text: this.es.posErrorMsgList[54].message,
                      type: "error",
                    });
                    this.LoaderService.hidePaymentloader();
                    $("#payBtnModal").removeAttr("disabled");
                    this.printService.printModeEnabled = false;
                    return;
                  }
                }
              }
            }
            this.postSalesOrder()
            this.paymentOptionService.salesObj.next([this.SalesOrderPayment])
          }
        });
    } else {
      // needs to update Discount in SalesOrder Object
      if (!this.isCancelOrder) {
        $("#payBtnModal").attr("disabled", "true");
        // if (this.SalesOrderPayment.IsDiscountedOrder) {
        if (this.selectedMerchant == "Worldpay") {
          this.SalesOrderPayment.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
          this.SalesOrderPayment.LaneId = this.posData && this.posData.LaneId ? this.posData.LaneId : this.SalesOrderPayment.LaneId;
          this.SalesOrderPayment.ShiftId = this.shiftData?.ShiftId ? this.shiftData.ShiftId : this.SalesOrderPayment.ShiftId;

          if (this.paymentMode == PaymentMode.CC || this.paymentMode == PaymentMode.ManualCC)
            this.SalesOrderPayment.WorldpayTransactionDetails = this.worldpayTransactionDetails;
        }
        this.paymentOptionService.salesObj.next([this.SalesOrderPayment]);
        let clonePatchobj = CloneObject(this.SalesOrderPayment)
        await clonePatchobj.Concession.forEach((co, index) => {
          clonePatchobj.Concession[index].Price -= clonePatchobj.Concession[index].Tax;

          // Needs to Substract ActualTax from ActualAmount
          if (clonePatchobj.IsDiscountedOrder) {
            clonePatchobj.Concession[index].ActualAmount -= clonePatchobj.Concession[index].ActualTax;
          }
        });

        // Need to save correst In DB
        if (clonePatchobj.TipAmount > 0) {
          clonePatchobj.TotalAmount -= clonePatchobj.TotalTax;
          clonePatchobj.TotalAmount -= clonePatchobj.TipAmount;
        }
        else {
          clonePatchobj.TotalAmount -= clonePatchobj.TotalTax;
        }

        // Needs to Substract ActualTax from ActualAmount
        if (clonePatchobj.IsDiscountedOrder)
          clonePatchobj.ActualAmount -= clonePatchobj.ActualTax;


        if (!this.isMemberVerified) {
          clonePatchobj.OrderLoyaltyCredit = 0;
          await clonePatchobj.Concession.forEach((ci, index) => {
            ci.ItemLoyaltyCredit = 0;
          });
          await clonePatchobj.Tickets.forEach((ti, index) => {
            ti.ItemLoyaltyCredit = 0;
          });
        } else {
          clonePatchobj.OrderLoyaltyCredit = this.loyaltyPoints;
          if (this.UsedLoyaltyPoints > 0) {
            clonePatchobj.OrderLoyaltyCredit = 0;
            await clonePatchobj.Concession.forEach((ci, index) => {
              ci.ItemLoyaltyCredit = 0;
            });
            await clonePatchobj.Tickets.forEach((ti, index) => {
              ti.ItemLoyaltyCredit = 0;
            });
          }
        }
        await this.posService.patchSalesOrder(clonePatchobj).subscribe(async (res) => {

          var postObj: any = {};
          postObj = this.SalesOrderPayment;

          // Print not working in Dine-In
          postObj.servedSalesOrderId = postObj.servedSalesOrderId ? postObj.servedSalesOrderId : postObj.ConfirmationCode;

          if (
            this.paymentMode == PaymentMode.CC &&
            (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")
          ) {
            postObj.MerchantName = this.selectedMerchant;
            if (this.selectedMerchant == "Worldpay") {
              postObj.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
              postObj.LaneId = this.posData && this.posData.LaneId ? this.posData.LaneId : this.SalesOrderPayment.LaneId;
              //already passed laneId while posting from dine in
              postObj.CustomerInfo = new CustomerInfo();
            }
            if (this.paymentCCInnerMode == "SwipeAuthorize") {
              postObj.PaymentMode = PaymentMode.CC;
              if (this.selectedMerchant == "Authorize.net") {
                postObj.CreditCardinfo = this.cc_auth_card_number;
                // postObj.TotalAmount = this.priceKitchenItem;
                this.cc_last_four = this.cc_auth_card_number
                  ?.split(";")[1]
                  ?.split("=")[0];
                this.cc_last_four = this.cc_last_four?.substring(
                  this.cc_last_four.length - 4
                );
              } else {
                postObj.MerchantName = this.selectedMerchant;
                postObj.CreditCardinfo = this.transactionid;
                postObj.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
                postObj.LaneId = this.posData && this.posData.LaneId ? this.posData.LaneId : this.SalesOrderPayment.LaneId;
                //already passed laneId while posting from dine in
                postObj.PaymentID = this.transactionid;
                postObj.PaymentType = this.cardTypeWorldpay;
                postObj.CCLastFour = this.cc_last_four;
              }
              this.proccesscreditcardPayment(postObj, postObj.Kitchen_Id);
            } else if (this.paymentCCInnerMode == "CCManualAuthorize") {
              if (this.selectedMerchant == "Authorize.net") {
                if (this.ccAuthForm?.form.invalid) {
                  $("#payBtnModal").removeAttr("disabled");
                  this.LoaderService.hidePaymentloader();
                  this.paymentError = this.es.dineInErrorMsgList[50].message;
                  return;
                }
                postObj.CustomerInfo.CardNumber = this.ccAuthorize["cardNumber"];
                postObj.CustomerInfo.Expiry = this.ccAuthorize["mm"];
                postObj.CustomerInfo.ExpiryYear = this.ccAuthorize["yy"];
                postObj.CustomerInfo.Cvv = this.ccAuthorize["cvv"];
                this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
                  this.ccAuthorize["cardNumber"].length - 4
                );
                postObj.PaymentMode = PaymentMode.ManualCC;
                postObj.TotalAmount = postObj.TotalAmount;
                this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
                  this.cc_last_four.length - 4
                );
                postObj.CreditCardinfo =
                  this.ccAuthorize["fullName"].trim() +
                  "^" +
                  this.ccAuthorize["cardNumber"] +
                  "^" +
                  this.ccAuthorize["mm"] +
                  "^" +
                  this.ccAuthorize["yy"] +
                  "^" +
                  this.ccAuthorize["cvv"] +
                  "^" +
                  this.ccAuthorize["zipcode"];

              }
              if (this.selectedMerchant == "Worldpay") {
                postObj.MerchantName = "Worldpay";
                // let merchantId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;

                postObj.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
                //*****************************************************
                // WORLD PAY IFRAME FORM PARAMS
                postObj.PaymentMode = PaymentMode.ManualCC;
                postObj.PaymentID = this.transactionid;
                postObj.CCLastFour = this.cc_last_four;
                postObj.PaymentType = this.cardTypeWorldpay;
                postObj.LaneId = this.posData && this.posData.LaneId ? this.posData.LaneId : this.SalesOrderPayment.LaneId;
                // postObj.LaneId = this.posData.LaneId;
                //already passed laneId while posting from dine in
              }
              this.proccesscreditcardPayment(postObj, postObj.Kitchen_Id);
              this.ccAuthForm?.form.reset();
              await this.updatePOSSalesOrderById(this.SalesOrderPayment);
            }
          }
          if (this.paymentMode == PaymentMode.Cash) {
            if (
              !this.customerGivenCash.toString().match("^[0-9]*\\.?[0-9]*$") ||
              this.customerGivenCash.toString().indexOf(".") == 0
            ) {
              Swal.fire(this.es.dineInErrorMsgList[30].message, this.es.dineInErrorMsgList[32].message);
              this.LoaderService.hidePaymentloader();
              return;
            }
            let res = await this.dineinService
              .updateKitchenStatus(postObj.Kitchen_Id, 4, {
                PaymentMode: this.paymentMode,
                TotalAmount: postObj.TotalAmount,
                TipAmount: postObj.TipAmount,
                ShiftId: this.shiftData?.ShiftId ? this.shiftData.ShiftId : this.SalesOrderPayment.ShiftId,
              })
              .toPromise();
            if (res && res != "success") {
              Swal.fire(this.es.dineInErrorMsgList[30].message, res);
              this.LoaderService.hidePaymentloader();
              return;
            } else {
              $("#payMethodModel").modal("hide");
              $("#kitchenPaymentModal").modal("hide");
              this.activeTipsButton = "";
              this.LoaderService.hidePaymentloader();
            }
            this.usedloyaltyPoints(postObj);
            //call to reload kitchen records
            this.updateCompleteSalesOrderStatus(postObj);
            this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
            await this.updatePOSSalesOrderById(this.SalesOrderPayment);
            // this.toastrService.success(this.es.dineInErrorMsgList[1].message, "Success", {
            //   timeOut: 2000,
            // });
            // this.printService.printDocument("ticket", [postObj.servedSalesOrderId]);
            this.toastrService
              .success(this.es.dineInErrorMsgList[1].message, "Success", { timeOut: 1000 })
              .onHidden.pipe(take(1))
              .subscribe(() => {
                this.printService.printDocument("ticket", [postObj.servedSalesOrderId]);
                this.isMemberVerified = false;
              });

          }
          if (this.paymentMode == PaymentMode.GiftCard) {
            let res = await this.dineinService
              .updateKitchenStatus(postObj.Kitchen_Id, 4, {
                PaymentMode: this.paymentMode,
                CardNumber: this.CardNumber,
                TotalAmount: postObj.TotalAmount,
                //TotalAmount: postObj.TotalAmount,
                TipAmount: postObj.TipAmount,
                ShiftId: this.shiftData?.ShiftId ? this.shiftData.ShiftId : this.SalesOrderPayment.ShiftId,
              })
              .toPromise();
            if (res && res != "success") {
              Swal.fire("", res);
              this.LoaderService.hidePaymentloader();
              $("#payBtnModal").removeAttr("disabled");
              return;
            } else {
              $("#payMethodModel").modal("hide");
              $("#kitchenPaymentModal").modal("hide");
              this.activeTipsButton = "";
              this.LoaderService.hidePaymentloader();
            }
            this.usedloyaltyPoints(postObj);
            //call to reload kitchen records
            if (!this.isCancelOrder) {
              this.LoaderService.popClose();
              this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
              this.toastrService.success(this.es.dineInErrorMsgList[1].message, "Success", {
                timeOut: 2000,
              });
              this.printService.printDocument("ticket", [postObj.servedSalesOrderId]);
              this.updateCompleteSalesOrderStatus(postObj);  
              this.isMemberVerified = false;
            } else {
              this.printService.printModeEnabled = false;
              this.LoaderService.isCancelOrder.next(false);
              this.paymentOptionService.kitchenSuccedObservable.next([]);
            }
            this.discountDisable = false;
            this.hasDiscountModeOn = false;
            this.hasLoyaltyModeOn = false;
            this.isMemberVerified = false;
            await this.updatePOSSalesOrderById(this.SalesOrderPayment);
          }
        });

      } else {
        this.printService.printModeEnabled = false;
        this.LoaderService.isCancelOrder.next(false);
        this.paymentOptionService.kitchenSuccedObservable.next([]);
      }
    }
  }
  async proccesscreditcardPayment(postObj, Kitchen_Id) {
    // postObj.CCLastFour=this.cc_last_four;
    // postObj.TipAmount = postObj.TipAmount;
    //postobj MerchantcredentialId
    if (!this.isCancelOrder) {
    await this.dineinService
      .updateKitchenStatus(Kitchen_Id, 4, postObj)
      .subscribe(
        async (response) => {
          if (response == "success") {
            postObj.TotalAmount = 0;
            this.cc_auth_card_number = "";
            this.cc_last_four = "";
            this.usedloyaltyPoints(postObj);
            this.LoaderService.hidePaymentloader();
            $("#payMethodModel").modal("hide");
            $("#kitchenPaymentModal").modal("hide");
            this.activeTipsButton = "";
            // this.toastrService.success(
            //   this.es.dineInErrorMsgList[51].message,
            //   "Success",
            //   {
            //     timeOut: 2000,
            //   }
            // );
            // this.printService.printDocument("ticket", [
            //   postObj.servedSalesOrderId,
            // ]);
              this.LoaderService.popClose();
              this.toastrService
                .success(this.es.dineInErrorMsgList[51].message, "Success", { timeOut: 1000 })
                .onHidden.pipe(take(1))
                .subscribe(() => {
                  this.isMemberVerified = false;
                  this.printService.printDocument("ticket", [
                    postObj.servedSalesOrderId,
                  ]);
                  this.updateCompleteSalesOrderStatus(postObj);  

                });
            //call to reload kitchen records
            this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
          } else {
            this.cc_auth_card_number = "";
            this.cc_last_four = "";
            Swal.fire(
              this.es.dineInErrorMsgList[39].message,
              this.es.dineInErrorMsgList[40].message,
              "error"
            );
            this.printService.printModeEnabled = false;
            this.LoaderService.hidePaymentloader();
          }
        },
        (err) => {
          this.cc_auth_card_number = "";
          this.cc_last_four = "";
          // Swal.fire("Error", err);
          Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
          this.printService.printModeEnabled = false;
          this.LoaderService.hidePaymentloader();
          return;
        }
      );
    } else {
      await this.dineinService
        .updateKitchenStatus(Kitchen_Id, 5, postObj)
        .subscribe(
          async (response) => {
            if (response == "success") {
              postObj.TotalAmount = 0;
              this.cc_auth_card_number = "";
              this.cc_last_four = "";
              $("#payMethodModel").modal("hide");
              $("#kitchenPaymentModal").modal("hide");
              this.activeTipsButton = "";
            } else {
              this.cc_auth_card_number = "";
              this.cc_last_four = "";
            }
          })
      this.printService.printModeEnabled = false;
      this.LoaderService.isCancelOrder.next(false);
      this.paymentOptionService.kitchenSuccedObservable.next([]);
    }

  }
  postSalesOrder() {
    let clonefi_2 = CloneObject(this.SalesOrderPayment.Concession)
    this.kioskPOSService.checkConcessionInventory(clonefi_2).subscribe(
     async (res: any) => {
        if (res.length > 0) {
          let items = res.join(", ");
          console.log("items", items)
          let scentence = [];
          for (var i = 0; i <= (res.length - 1); i++) {
            scentence.push(res[i])
          }
          let fullScentence = '';
          scentence.forEach((f, i) => {
            f = f.split('_')
            fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
          });
          this.LoaderService.hidePaymentloader();
          Swal.fire({
            title: this.es.posErrorMsgList[36].message,
            text: this.es.posErrorMsgList[37].message + fullScentence + this.es.posErrorMsgList[38].message,
            showCancelButton: false,
            type: "warning",
          });
          $("#payMethodModel").modal("hide");
          this.printService.printModeEnabled = false;
          this.router.navigate(["/POS"]);
        } else {
          if (this.SalesOrderPayment.Tickets.length > 0 || this.SalesOrderPayment.Concession.length > 0 || this.SalesOrderPayment.GiftCardSale.length > 0) {
            if (!this.isMemberVerified) {
              this.SalesOrderPayment.OrderLoyaltyCredit = 0;
              await this.SalesOrderPayment.Concession.forEach((ci, index) => {
                ci.ItemLoyaltyCredit = 0;
              });
              await this.SalesOrderPayment.Tickets.forEach((ti, index) => {
                ti.ItemLoyaltyCredit = 0;
              });
            } else {
              this.SalesOrderPayment.OrderLoyaltyCredit = this.loyaltyPoints;
              if (this.UsedLoyaltyPoints > 0) {
                this.SalesOrderPayment.OrderLoyaltyCredit = 0;
                await this.SalesOrderPayment.Concession.forEach((ci, index) => {
                  ci.ItemLoyaltyCredit = 0;
                });
                await this.SalesOrderPayment.Tickets.forEach((ti, index) => {
                  ti.ItemLoyaltyCredit = 0;
                });
              }
            }
            this.posService.postSalesOrder(this.SalesOrderPayment).subscribe(
              async (response) => {
                if (
                  this.paymentMode == PaymentMode.CC &&
                  (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")
                ) {
                  if (response.CreditCardinfo == "succeeded" && (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")) {
                    await this.updatePOSSalesOrderById(this.SalesOrderPayment);
                    this.ClearDataNPrint(response, this.SalesOrderPayment.holdIds, true);
                    if (this.selectedMerchant == "Worldpay")
                      this.cc_last_four = this.SalesOrderPayment.CCLastFour;

                    this.PaymentService.passPaymentIntentIdAuthorize(
                      response.ConfirmationCode,
                      response.PaymentID,
                      "true",
                      this.cc_last_four.toString()
                    ).toPromise();
                    this.cc_last_four = "";
                    this.cc_auth_card_number = "";
                  } else {
                    this.paymentError = response.CreditCardinfo;
                    this.toastrService.error(this.paymentError);
                    this.printService.printModeEnabled = false;
                    this.LoaderService.hidePaymentloader();
                    this.PaymentService.passPaymentIntentIdAuthorize(
                      response.ConfirmationCode,
                      "failPayment",
                      "false",
                      this.cc_last_four.toString()
                    ).toPromise();
                    this.cc_last_four = "";
                    this.cc_auth_card_number = "";
                  }
                } else {
                  await this.updatePOSSalesOrderById(this.SalesOrderPayment);
                  this.ClearDataNPrint(response, this.SalesOrderPayment.holdIds, true);
                }
              }, (err) => {
                this.LoaderService.hidePaymentloader();
                this.printService.printModeEnabled = false;
                Swal.fire(
                  "",
                  this.es.posErrorMsgList[35].message,
                  "error"
                );
              });
          }
        }
      });
  }
  async updatePOSSalesOrderById(salesOrderPayment) {
    await this.posService.updatePOSSalesOrderById(salesOrderPayment.ConfirmationCode).subscribe(
      async (resultSales) => {
        // this.paymentOptionService.paymentSuccedObservable.next(['Success']);
        if (resultSales) {
          setTimeout(() => {
           // this.paymentOptionService.concessionSuccedObservable.next(['Success']);
          }, 2000);
          if (this.SalesDiscount && this.SalesDiscount.DiscountDetails.length > 0 && salesOrderPayment.IsDiscountedOrder === true){
            this.postSalesDiscount();
          }
          console.log("Giftcard Sales RESPONSE ---", resultSales);
        }
      }, (err) => {
        this.printService.printModeEnabled = false;
        this.LoaderService.hidePaymentloader();
      });
  
  }
  async updateCompleteSalesOrderStatus(salesOrder) {


    this.posService.UpdatePenddingSalesOrder(salesOrder,0,this.LocationId,this.userEmail).subscribe(
      async (result) => {
      }, (err) => {
        Swal.fire(
          "",
          err,
          "error"
        );
        this.LoaderService.hidePaymentloader();
      });
  }
  async ClearDataNPrint(response: SalesOrder, holdIds, printFlag) {
    this.printService.printModeEnabled = true;
    if (holdIds) {
      await this.posService.deleteHoldSeats(holdIds).subscribe(
        () => { },
        (err) => {
          this.LoaderService.hidePaymentloader();
          this.printService.printModeEnabled = false;
          Swal.fire(this.es.posErrorMsgList[56].message, err, "error");
        }
      );
    }
    console.log("-------------ORDER COMPLETED-------------------");
    this.updateCompleteSalesOrderStatus(response);
    //split print    
    this.usedloyaltyPoints(response);
    this.cc_last_four = "";
    this.discountDisable = false;
    this.ccForm?.form.reset();
    this.ccAuthForm?.form.reset();
    this.selectedMerchant = this.currentMerchantDetails.MerchantName;
    this.posService.totalPriceObservable.next(0);
    this.posService.posSelectedItemCount = 0;
    this.posService.movieTicketObservable.next([]);
    this.posService.foodItemsInCartObservable.next([]);
    this.paymentError = "";
    this.LoaderService.hidePaymentloader();
    this.SalesOrderPayment.GiftCardSale.forEach(g => {
      this.giftCardService.GiftCardToCart(g, "minus");
    });
    this.giftCardService.totalPriceObservable.next(0);
    // $("#splitpayBtnModal").removeAttr("disabled");
    // $("#splitPayModel").modal("hide");
    $("#payMethodModel").modal("hide");
    if (printFlag && !this.isCancelOrder) {
      this.LoaderService.popClose();
      this.toastrService
        .success(this.es.posErrorMsgList[96].message, "Success", { timeOut: 1000 })
        .onHidden.pipe(take(1))
        .subscribe(() => {
          this.keyboard.destroy();
          this.printService.printDocument("ticket", [response.ConfirmationCode]);
          this.isMemberVerified = false;
        });
      this.paymentOptionService.paymentSuccedObservable.next(['Success']);
      // this.postSalesDiscount();
    } else {
      this.printService.printModeEnabled = false;
      this.LoaderService.isCancelOrder.next(false);
    }
    $("#PosTab #movie-tab").tab("show");
    this.clearSplitVariables();
  }
  postSalesDiscount(){
    console.log("SalesDiscount---------------------",this.SalesDiscount);
    this.SalesDiscount.LocationId = this.LocationId;
    this.posService.PostSalesDiscount(this.SalesDiscount,this.LocationId,this.userEmail).subscribe(
      async (response) => {
          console.log("SalesDiscount Response----",response)
          this.discountReason = "";
          this.ReasonNote = "";
          this.OtherReasonNote = "";
      }, (err) => {
        console.log(err);
      });  
  }
  async usedloyaltyPoints(response) {
    if (this.isMemberVerified) {
      var member = JSON.parse(sessionStorage.getItem('MemberDetail'));
      if (member && this.CompanyDetails) {
        let loyaltyorder = { LoyaltyMemberId: member.MemberId, LocationId: this.LocationId, CompanyId: this.CompanyDetails.Company_ID, MemberRewards: this.UsedLoyaltyPoints, SalesOrderId: response.ConfirmationCode };
        await this.posService
          .AddLoyaltyOrder(loyaltyorder)
          .subscribe(
            async (response) => {
              console.log("loyaltyOrder", response);
            });
      }
    }

    this.isMemberVerified = false;
    this.ispartialRedeem = '';
    this.partialredeempoints = 0;
    this.PreviousSalesOrder = null;
    this.UsedLoyaltyPoints = 0;

  }

  
  async usedloyaltyPointsSplitByItems(response) {
   
      var member = JSON.parse(sessionStorage.getItem('MemberDetail'));
      if (member && this.CompanyDetails) {
        let usedpoints=(response.ActualAmount+response.ActualTax)-(response.TotalAmount+response.TotalTax)
        usedpoints=usedpoints*this.CompanyDetails.LoyaltyPointsPerCurrency
        usedpoints = usedpoints > 0 ? usedpoints : 0;
          let loyaltyorder = { LoyaltyMemberId: member.MemberId, LocationId: this.LocationId, CompanyId: this.CompanyDetails.Company_ID, MemberRewards: usedpoints, SalesOrderId: response.ConfirmationCode };
          await this.posService
            .AddLoyaltyOrder(loyaltyorder)
            .subscribe(
              async (response) => {
                console.log("loyaltyOrder", response);
              });
        
      
      }
    

    

  }
  onBlurMonth() {
    this.commonService.GetDateTimeByTimeZone().subscribe((tz) => {
      if (
        moment(tz).get("month") + 1 > this.ccAuthorize.mm &&
        this.ccAuthorize.yy &&
        moment(tz).get("year") >= this.ccAuthorize.yy
      ) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      } else {
        if (moment(tz).get("year") > this.ccAuthorize.yy) {
          this.ccAuthForm.controls["auth_yy"].setErrors({ pattern: true });
        } else {
          if (this.ccAuthorize.mm) {
            this.ccAuthForm.controls["auth_mm"].clearAsyncValidators();
            this.ccAuthForm.controls["auth_mm"].setErrors(null);
          }
        }
        if (moment(tz).get("year") == this.ccAuthorize.yy) {
          if (moment(tz).get("month") + 1 > this.ccAuthorize.mm) {
            this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
          }
        }
      }
      // }
      if (this.ccAuthorize.mm > 12) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      }
    });
  }
  onBlurYear() {
    this.commonService.GetDateTimeByTimeZone().subscribe((tz) => {
      if (moment(tz).get("year") > this.ccAuthorize.yy) {
        this.ccAuthForm.controls["auth_yy"].setErrors({ pattern: true });
      } else {
        this.ccAuthForm.controls["auth_mm"].clearAsyncValidators;
        this.ccAuthForm.controls["auth_mm"].setErrors(null);
      }
      if (moment(tz).get("year") == this.ccAuthorize.yy) {
        if (moment(tz).get("month") + 1 > this.ccAuthorize.mm) {
          this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
        }
      }
      if (this.ccAuthorize.mm > 12) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      }
    });
  }
  selectAmount(amount) {
    if (amount == "Other") {
      this.customerGivenCash = null;
      this.isOtherSelectedFLag = true;
    } else {
      this.isOtherSelectedFLag = false;
      this.customerGivenCash = amount;
    }
  }
  loadKeypad() {
    setTimeout(() => {
      this.keyboard = new Keyboard("PaymentKeyboard", {
        onChange: (input) => this.onChange(input),
        layout: {
          default: CommonSettings.keybSetting.numpadDef,
        },
        theme: CommonSettings.keybSetting.theme,
      });
      $(".hg-theme-default .hg-button").css("background", "#76c3eb52");
      $(".hg-theme-default .hg-button").css("color", "#000");
      $(".hg-theme-default .hg-button").css("border", "0");
    }, 1000);
  }
  onChange = (input: any) => {
    let ele = document.querySelector(this.selectedInput);
    if (ele.maxLength !== -1 && input.length > ele.value.length) {
      if (ele.value.length >= ele.maxLength) return;
    }
    $(this.selectedInput).val(input);
    let event: Event = new Event("input");
    ele.dispatchEvent(event);
  };
  SetSplitPaymentSalesOrderArray() {
    if (this.splitPaymentType == "splitByItem") {
      if (this.dtElementFood) {
        this.dtElementFood.dtInstance.then(
          (dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          }
        );
      } else {
        if (this.dtTriggerFood) {
          this.dtTriggerFood.unsubscribe();
          this.dtTriggerFood = null;
        }
      }
    }
  }
  async SetSplitPaymentType(type: string) {
    this.splitPaymentType = type;
    this.SalesOrderPayment.concessionPaymentItemCopy = CloneObject(this.SalesOrderPayment.concessionPaymentItemCopy2);
    if (type == "splitByItem") {
      //   await this.paymentOptionService.salesOrderObject.subscribe((success) => {
      //     this.SalesOrderPayment = success;
      //     this.onCheckboxChangeSalesOrder(false);
      //     this.SalesOrderPayment.Concession.forEach((con) => {
      //       con.ActualQuantity = con.NumberSold;
      //     });
      this.totalPrice = 0;
      if (!this.isSplitByItemOpen) {

       // this.updatesalesorderwithDiscount();
        this.isSplitByItemOpen = true;
      }

      this.splitPaymentMode = PaymentMode.Cash;
      this.SalesOrderPayment.AdditionalCharges.forEach((ac) => {
        ac.ActualQuantity = ac.NumberOfTicketSold;
        ac.Selected = false;
      });
      // });
    } else {
      this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
    }
  }
  GetComboConcession(concessions, comboId) {
    return concessions?.filter((c) => c.ComboID == comboId);
  }
  GetTicketAdditionalCharge(ticket) {
    let cloneObjectAC = CloneObject(this.SalesOrderPayment.AdditionalCharges)
    let filteredAC = cloneObjectAC?.filter((ac) => ac.additionalChargeUniqueId == ticket.additionalChargeUniqueId);
    return filteredAC;
  }
  GetComboConcessionPrice(concessions, comboId) {
    let concessionsArray = concessions?.filter((c) => c.ComboID == comboId);
    let Price = 0;
    concessionsArray.forEach(co => {
      Price += co.Price;
    });
    return Price;
  }
  GetAdditionalPrice(ticket) {
    let additionalChargeArray = this.SalesOrderPayment.AdditionalCharges?.filter((ac) => ac.additionalChargeUniqueId == ticket.additionalChargeUniqueId);
    let Price = 0;
    additionalChargeArray.forEach(co => {
      Price += co.AdditionalPrice;
    });
    return Price;
  }
  manageFoodCount(obj, typeofoperation, isconcession) {
    if (!obj.ActualQuantity)
      obj.ActualQuantity = obj.NumberSold;
    // if (!isconcession)
    //   obj.ActualQuantity = obj.NumberOfTicketSold;
    if (typeofoperation == "remove") {
      if (isconcession) {
        --obj.NumberSold;
      } else {
        --obj.NumberOfTicketSold;
      }
    } else if (obj.ActualQuantity != obj.NumberSold) {
      if (isconcession) {
        ++obj.NumberSold;
      }
      else {
        ++obj.NumberOfTicketSold;
      }
    }
    this.UpdateSplitItemPrice();
  }
  onCheckboxChangeSalesOrder(checked) {
    this.SalesOrderPayment.Tickets.forEach((so) => {
      so.Selected = checked;
    });
    this.SalesOrderPayment.Concession.forEach((so) => {
      so.Selected = checked;
      if (!so.ActualQuantity)
        so.ActualQuantity = so.NumberSold;
    });
    this.SalesOrderPayment.AdditionalCharges.forEach((so) => {
      so.Selected = checked;
    });
    this.SalesOrderPayment.GiftCardSale.forEach((so) => {
      so.Selected = checked;
    });
    this.UpdateSplitItemPrice();
  }
  UpdateSplitItemPrice() {
    var price = 0;
    this.SalesOrderPayment.Tickets.forEach((so) => {
      if (so.Selected) {
        price += ((so.TtlPrice ? so.TtlPrice : so.Price));
        if (so.additionalChargeUniqueId != null) {
          const additionalcharge = this.SalesOrderPayment.AdditionalCharges.filter(l => l.additionalChargeUniqueId == so.additionalChargeUniqueId);
          if (additionalcharge.length > 0) {
            additionalcharge.forEach((so) => {
              if (so.Selected) {
                price += so.AdditionalPrice;
              }
            });
          }

        }
        if (so.ComboID != "") {
          let comboItem = this.SalesOrderPayment.Concession.find(concessionCombo => concessionCombo.ComboID == so.ComboID);
          price += comboItem.Price;
        }
      }
    });
    this.SalesOrderPayment.Concession.forEach((so) => {
      if (so.Selected && so.ComboID == "")
        price += ((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold));
    });
    // this.SalesOrderPayment.AdditionalCharges.forEach((so) => {
    //   if (so.Selected)
    //     // price += ((so.AdditionalPrice + so.Tax) * (so.NumberOfTicketSold));
    //     price += ((so.AdditionalPrice) * (so.NumberOfTicketSold));

    // });
    this.SalesOrderPayment.GiftCardSale.forEach((so) => {
      if (so.Selected)
        price += so.Amount;
    });
    if (this.SalesOrderPayment.isDineInOrder) {
      this.SalesOrderPayment.TipAmount = 0;
      this.activeTipsButton = "";
      this.TipAmountOther.nativeElement.value = ' ';
    }

    this.totalPrice = Number(price.toFixed(2));
    this.getCashChangePosibilities();

  }
  CData() {
    this.ccForm?.form.reset();
    this.ccAuthForm?.form.reset();
    this.keyboard = null;
    this.splitPaymentType = '';
    this.hasDiscountModeOn = false;
    this.hasLoyaltyModeOn = false;
    this.isMemberVerified = false;
    this.ispartialRedeem = '';
    this.partialredeempoints = 0;
    this.UsedLoyaltyPoints = 0;
    this.loyaltysearchby = null;
    this.isSplitByItemOpen = false;
    this.IsOriginaldiscountAdded=false;
    sessionStorage.removeItem('MemberDetail');
    this.discountDisable = false;
    // this.SalesOrderPayment = null;
  }
  backToPaymentModes() {
    this.splitPaymentType = "";
    this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
    this.paymentMode = null;
    this.activeTipsButton = "";
    this.SalesOrderPayment.concessionPaymentItemCopy = CloneObject(this.SalesOrderPayment.concessionPaymentItemCopy2);
   // this.isSplitByItemOpen = false;
    // this.PaymentMode(PaymentMode.Cash);
    this.loadKeypad();
  }
  UpdateItemAndPrice(obj) {
    obj.Selected = !obj.Selected;
    obj.ActualQuantity = obj.NumberSold ? obj.NumberSold : obj.NumberOfTicketSold;
    if (obj.additionalChargeUniqueId != null) {
      this.SalesOrderPayment.AdditionalCharges.forEach((element, index) => {
        if (element.additionalChargeUniqueId == obj.additionalChargeUniqueId) {
          this.SalesOrderPayment.AdditionalCharges[index].Selected = obj.Selected;
        }
      });
    }
    // this.UpdateSplitItemPrice();
  }
  checkAmountValid() {
    if (isNaN(Number(this.customerGivenCash))) {
      Swal.fire({
        title: "",
        text: 'Please Enter A valid Amount',
        showCancelButton: false,
        type: "warning",
      });
      this.customerGivenCash = null;
      $("#customerGivenCash").val('');
      return;
    }
  }

  ngOnDestroy(): void {
    this.stSer.removeStyle('pt');
    this.KitchenPostObjectSubscription.unsubscribe();
  }

  getTransactionSetupid(getTransactionSetupidflag) {
    //as of now static object need to do some analysis with priyanshi to integrate few from this in backend
    if (this.selectedMerchant == 'Worldpay') {
      let transactionAmount: any = 0;
      transactionAmount = this.totalPrice.toFixed(2);
      var currentUrl = window.location.href;
      var splithttpUrl = currentUrl.split("//");
      if (splithttpUrl && splithttpUrl.length > 1) {
        var splitUrl = splithttpUrl[1].split("/");
        if (splitUrl && splitUrl.length > 0) {
          currentUrl = splithttpUrl[0] + "//" + splitUrl[0] + "/successpayment";
        }
      }

      let obj = {
        "TransactionSetup": {
          "TransactionSetupMethod": "1",
          "Embedded": "1",
          "CVVRequired": "1",
          "CompanyName": "Cinema360",
          "LogoURL": '',
          "Tagline": 'Cinema 360',
          "AutoReturn": "1",
          "WelcomeMessage": null,
          "ReturnURL": currentUrl,
          "ReturnURLTitle": null,
          "OrderDetails": null,
          "ProcessTransactionTitle": null,
          "CustomCss": "body {font-family:  'Poppins', sans-serif; font-size: 12px; color: #000;padding:5px;}.tdHeader {border:none}.tdTransactionButtons {border: none;}.content {border: none;}#btnCancel{ Display:None;}.selectOption {font-family:'Poppins', sans-serif;}.inputText {font-family:'Poppins', sans-serif;}#submit{ background-color:#76c3eb;color: #fff ;padding: 12px 20px;border: none;border-radius: 2em;} #cardNumber {background-color: #fff;border: 1px solid #ced4da;border-radius: .25rem;padding: .375rem .75rem;}"
        },
        "Address": {
          "AddressEditAllowed": 1,
        },
        "Transaction": {
          "TransactionAmount": transactionAmount,
          "MarketCode": "3",
          "ReferenceNumber": "",
          "PartialApprovedFlag": "0"
        },
        "Terminal": {
          "CVVPresenceCode": "0",
          "CardPresentCode": "3",
          "CardholderPresentCode": "7",
          "CardInputCode": "4",
          "TerminalCapabilityCode": "5",
          "TerminalEnvironmentCode": "6",
          "MotoECICode": "7",
          "TerminalType": "2"
        }
      }
      // this.merchantInternalId need fix for preparea
      let merchantId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
      this.merchantService.getTransactionsetupid(merchantId, obj).subscribe((result) => {
        if (result?.Response?.PaymentAccount?.TransactionSetupID && result?.Response?.PaymentAccount?.TransactionSetupID != null) {
          this.merchantService.getManualCCTransactionUrl().subscribe((url) => {
            let uri = url + result.Response.PaymentAccount.TransactionSetupID;
            this.url = this.dom.bypassSecurityTrustResourceUrl(uri);
            this.isViewMannualiframe = true;

            this.RecallGetTransactionSetupid();
          });
        } else {
          this.paymentError = result.Response.ExpressResponseMessage;
          Swal.fire("Error", this.paymentError, "error");
        }
      }, err => {
        this.paymentError = err
      });
    }
  }

  //Set timeout 10 minutes for TransactionSetupID, after 10 minutes it will create new TransactionSetupID
  RecallGetTransactionSetupid() {
    if (this.timer)
      clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      console.log('Creting new TransactionSetupID.');
      if (this.isViewMannualiframe && this.selectedMerchant == 'Worldpay') {
        this.getTransactionSetupid(false);
      }
    }, 600000);
  }
  loadiframe(frame) {
    let setupid = frame.src.split('=')[1];
    if (setupid) {
      let obj = {
        "Parameters": {
          "TransactionSetupID": setupid
        }
      }
      console.log("setupid", setupid, obj);
      let merchantId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
      this.merchantService.GetTransactionDetailBySetupid(merchantId, obj).subscribe((result) => {
        var items = result.Response.ReportingData.Items.Item;
        if (items && items.length > 0) {
          if (items[items.length - 1].ExpressResponseMessage == "Partial Approved") {
            Swal.fire("Transaction failed", "This Card has insufficient balance. Please use another card.", "error");
            this.getTransactionSetupid(false);
          }
          else {
            // Get first 6 digit number of card
            var binValue = frame.contentWindow.location.href;
            var splitBinValue = binValue.split("Bin=");
            var firstSixdigit = "";
            if (splitBinValue && splitBinValue.length > 1) {
              var findBinValue = splitBinValue[1].split("&");
              if (findBinValue && findBinValue.length > 0)
                firstSixdigit = findBinValue[0];
            }

            this.isViewMannualiframe = false;
            this.transactionid = items[items.length - 1].TransactionID;
            this.cardTypeWorldpay = items[items.length - 1].CardType;
            this.SetWorldpayTransactionDetails(items[items.length - 1], PaymentMode.ManualCC);
            this.cc_last_four = firstSixdigit + items[items.length - 1].CardNumberMasked.split('-')[3];
            this.SalesOrderPayment.CCLastFour = this.cc_last_four;
            if (this.SalesOrderPayment.PaymentMode != PaymentMode.Split) {
              this.placeOrder();
            } else {
              this.splitOrderPay()
            }
          }
        }
      }, err => {
        this.paymentError = err
      });
    }
  }

  clickOnDiscountTab() {
    // this.employeelist = this.employeelist.sort(function(a, b){
    //   return a.FirstName === b.FirstName ? 0 : a.FirstName < b.FirstName ? -1 : 1;
    // })
    this.discountReason = "";
    this.ReasonNote = "";
    this.OtherReasonNote = "";
    this.DiscountReasonValidFlag = false;
    this.employeelist =this.employeelist.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
    this.hasLoyaltyModeOn = false;
    this.ManagerPin = '';
    this.loadKeypad();
    setTimeout(() => {
      this.discountinput.nativeElement.focus();
    }, 0);
    this.hasDiscountModeOn = true;
    if (!this.SalesOrderPayment.IsDiscountedOrder) {
      this.discountAmount = 0;
    }
  }
  onChangediscountReason(e){
    this.ReasonNote = "";
    this.OtherReasonNote = "";
    this.DiscountReasonValidFlag = false;
  }
  clickOnLoyaltyTab() {
    this.loadKeypad();
    setTimeout(() => {
      this.discountinput.nativeElement.focus();
    }, 0);
    this.hasDiscountModeOn = false;
    this.hasLoyaltyModeOn = true;
  }
  addDiscount() {

    console.log("verifyCode", this.LocationId);
       //checkreasoninputvalid 
      if(this.ReasonNote == 'Any other'){
        this.ReasonNote = this.OtherReasonNote;
      }
      if(this.discountReason != "" && this.ReasonNote != "" ){
        this.DiscountReasonValidFlag = true;
      }
      if(this.DiscountReasonValidFlag){
          // calculate only valid discount
          this.posService.getPinVerify(
            this.LocationId,
            Number(this.ManagerPin)
          ).subscribe(
            (success) => {
              if (success) {

                if (!this.IsValidDiscount()) return;

                var price = 0;
                var discountPercentage = Number(this.discountAmount);
                var GiftCardSaleTotalAmount = 0;
                var AdditionalChargeTotalAmount = 0;
                var additionachargeTax = 0;
                var amount = 0;
      
                var totaltaxremovedaddition = 0;
                if (!this.SalesOrderPayment.IsDiscountedOrder) {
                   totaltaxremovedaddition = this.SalesOrderPayment.TotalTax;
                   amount = this.SalesOrderPayment.TotalAmount;
                } else {
                   totaltaxremovedaddition = this.SalesOrderPayment.ActualTax;
                   amount = this.SalesOrderPayment.ActualAmount;
                }
              //  amount = this.SalesOrderPayment.TotalAmount;
                if (this.SalesOrderPayment.GiftCardSale && this.SalesOrderPayment.GiftCardSale.length > 0) {
                  this.SalesOrderPayment.GiftCardSale.forEach((x) => {
                    GiftCardSaleTotalAmount = x.Amount;
                  })
                  amount -= GiftCardSaleTotalAmount
                }
                if (this.SalesOrderPayment.AdditionalCharges && this.SalesOrderPayment.AdditionalCharges.length > 0) {
      
                  this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
                    AdditionalChargeTotalAmount += (so.AdditionalPrice * so.NumberOfTicketSold);
                    additionachargeTax += (so.Tax * so.NumberOfTicketSold);
                  })
                  amount -= AdditionalChargeTotalAmount;
                }
                if (Number(this.discountType) == DiscountType.Cash) {
                  // var amount = 0;
      
                  // if (!this.SalesOrderPayment.IsDiscountedOrder) {
                  //   amount = this.SalesOrderPayment.TotalAmount;
                  //   if (this.SalesOrderPayment.isDineInOrder)
                  //     amount += this.SalesOrderPayment.TotalTax;
                  // }
                  // else {
                  //   if (this.UsedLoyaltyPoints > 0) {
                  //     amount = this.SalesOrderPayment.TotalAmount;
                  //     if (this.SalesOrderPayment.isDineInOrder)
                  //       amount += this.SalesOrderPayment.TotalTax;
                  //   } else {
                  //     amount = this.SalesOrderPayment.ActualAmount;
                  //     if (this.SalesOrderPayment.isDineInOrder)
                  //       amount += this.SalesOrderPayment.ActualTax;
                  //   }
      
                  // }
      
                  discountPercentage = GetPercentageByAmount(Number(this.discountAmount), amount);
                }
      
                if (!this.SalesOrderPayment.IsDiscountedOrder) {
                  this.SalesOrderPayment.ActualAmount = this.SalesOrderPayment.TotalAmount;
                  this.SalesOrderPayment.ActualTax = this.SalesOrderPayment.TotalTax;
                }
                // Update amount as per discount percentage
                if (this.UsedLoyaltyPoints > 0) {
      
                  this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalAmount);
                  this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
      
                } else {
                  this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.ActualAmount);
                  this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.ActualTax);
      
                }
      
      
                this.SalesOrderPayment.Tickets?.forEach((so) => {
                  if (!this.SalesOrderPayment.IsDiscountedOrder) {
                    so.ActualAmount = so.Price;
                    so.ActualTax = so.Tax;
                  } else {
                    if (this.UsedLoyaltyPoints > 0) {
                      so.ActualAmount = so.Price;
                      so.ActualTax = so.Tax;
                    }
                  }
      
                  // Update amount as per discount percentage
                  so.Price = PercentageAmountCalculation(discountPercentage, so.ActualAmount);
                  so.Tax = PercentageAmountCalculation(discountPercentage, so.ActualTax);
                  so.IsDiscountedOrder = true;
      
                  if (this.SalesOrderPayment.isDineInOrder)
                    price += PercentageAmountCalculation(discountPercentage, so.ActualAmount + so.ActualTax);
                  else
                    price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price)));
                });
                this.SalesOrderPayment.Concession?.forEach((so) => {
                  if (!this.SalesOrderPayment.IsDiscountedOrder) {
                    so.ActualAmount = so.Price;
                    so.ActualTax = so.Tax;
                  } else {
                    if (this.UsedLoyaltyPoints > 0) {
                      so.ActualAmount = so.Price;
                      so.ActualTax = so.Tax;
                    }
                  }
      
                  // Update amount as per discount percentage
                  so.Price = PercentageAmountCalculation(discountPercentage, so.ActualAmount);
                  //if (this.SalesOrderPayment.isDineInOrder) {
                  //  so.Tax = PercentageAmountCalculation(discountPercentage, so.ActualTax);
                  //  so.Price -= so.Tax;
                  //}
                  //else {
                  so.Tax = PercentageAmountCalculation(discountPercentage, so.ActualTax);
                  //}
                  so.IsDiscountedOrder = true;
      
                  if (this.SalesOrderPayment.isDineInOrder)
                    price += (so.Price) * (so.NumberSold);
                  else
                    price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold)));
                });
                // Update this for Dine-In tip amount functionality
                if (this.SalesOrderPayment.isDineInOrder)
                  this.SalesOrderPayment.concessionPaymentItem = this.SalesOrderPayment.Concession;
      
                this.SalesOrderPayment.DiscountType = [];
                this.SalesOrderPayment.DiscountType.push(Number(this.discountType));
                this.SalesOrderPayment.IsDiscountedOrder = true;
                this.SalesOrderPayment.DiscounteRate = discountPercentage;
                this.totalPrice = RoundAmount(price);
                //Manage 0.01 amount for discount
                this.CheckAndUpdateAmountForDiscount();
                var SettledTax = 0;
                this.SalesOrderPayment.Tickets?.forEach((so) => {
      
                  SettledTax += (so.Tax);
      
                  if (so.ComboID) {
                    let comboItem = this.SalesOrderPayment.Concession.find(concessionCombo => concessionCombo.ComboID == so.ComboID);
                    if (comboItem != null) {
      
                      SettledTax += (so.Tax);
                    }
                  }
                });
                this.SalesOrderPayment.Concession?.forEach((so) => {
                  if (!so.ComboID) {
                    // Steeledprice += ((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold));
                    SettledTax += (so.Tax);
                  }
      
                });
      
                this.SalesOrderPayment.TotalAmount = RoundAmount(this.SalesOrderPayment.TotalAmount + AdditionalChargeTotalAmount + GiftCardSaleTotalAmount)
                this.totalPrice = RoundAmount(this.SalesOrderPayment.TotalAmount);
                this.SalesOrderPayment.TotalTax = RoundAmount(SettledTax + additionachargeTax);
                this.IsOriginaldiscountAdded = true;
      
                // Need to update this for Split Payment



                // this.SalesOrderPayment.DiscountType.push(Number(this.discountType));
                // this.SalesOrderPayment.IsDiscountedOrder = true;
                // this.SalesOrderPayment.DiscounteRate = discountPercentage;
                // this.totalPrice = RoundAmount(price);
                this.SalesDiscount.DiscountDetails=[];
                this.SalesDiscount.SalesOrderId = this.SalesOrderPayment.ConfirmationCode;
                this.SalesDiscount.PhoneholdId = "";
                this.SalesDiscount.ShowId = "";
                let DiscountDetails = {
                  SalesDiscountType:0,
                  ActualAmount:this.SalesOrderPayment.ActualAmount,
                  TotalAmount:this.SalesOrderPayment.TotalAmount,
                  MainReason: this.discountReason,
                  ReasonNote: this.ReasonNote,
                  ManagerPin: this.ManagerPin,
                  ManagerEmail:"",
                  OriginalSeats:  [],
                  FinalSeats: [],
                  OriginalSeatNumbers:0,
                  TotalSeatNumbers:0,
                  Released: false,
                  PaymentMode: 0,
                  SplitPayment: [],
                  AppliedDate:moment(this.timeZone).format("YYYY-MM-DD HH:mm:ss")
                }
                this.SalesDiscount.DiscountDetails.push(DiscountDetails);
                this.originalSalesOrderPayment = CloneObject(this.SalesOrderPayment);     
                this.toastrService.success("Discount applied successfully", "Success");
                console.log("Discounted SalesOrderPayment", this.SalesOrderPayment);
               this.discountDisable = true;
                this.discountAmount = 0;
             
      
              } else if (this.ManagerPin == "" || this.ManagerPin == null || this.ManagerPin == "0" || this.ManagerPin == "NaN") {
                Swal.fire("Error", "Please enter Manager pin", "error");
              }
              else {
                Swal.fire("Error", "Invalid pin. Please try again", "error");
              }
            },
            (error) => {
              if (this.ManagerPin == "" || this.ManagerPin == null || this.ManagerPin == "0" || this.ManagerPin == "NaN") {
                Swal.fire("Error", "Please enter Manager pin", "error");
              }
              else {
                Swal.fire("Error", "Invalid pin. Please try again", "error");
              }
            }
          );
      }else{
        this.toastrService.error("Please enter the discount reason details to proceed further.");
      }
  }

  //Manage 0.01 amount for discount
  CheckAndUpdateAmountForDiscount() {
    var totalSalesOrderAmount = 0;

    if (this.SalesOrderPayment.isDineInOrder)
      totalSalesOrderAmount = this.SalesOrderPayment.TotalAmount;
    else
      totalSalesOrderAmount = this.SalesOrderPayment.TotalAmount;

    if (this.totalPrice != totalSalesOrderAmount) {
      var diffAmount = totalSalesOrderAmount - this.totalPrice;

      if (this.SalesOrderPayment.Tickets.length > 0) {
        this.SalesOrderPayment.Tickets[this.SalesOrderPayment.Tickets.length - 1].Price = Number((this.SalesOrderPayment.Tickets[this.SalesOrderPayment.Tickets.length - 1].Price + diffAmount).toFixed(2));
      }
      else if (this.SalesOrderPayment.Concession.length > 0) {
        this.SalesOrderPayment.Concession[this.SalesOrderPayment.Concession.length - 1].Price = Number((this.SalesOrderPayment.Concession[this.SalesOrderPayment.Concession.length - 1].Price + diffAmount).toFixed(2));;
      }
      // else if (this.SalesOrderPayment.AdditionalCharges.Count > 0) {
      //   this.SalesOrderPayment.AdditionalCharges.Last().AdditionalPrice += diffAmount;
      // }
    }
  }

  IsValidDiscount() {
    var isvalid = false;

    if (
      !this.discountAmount.toString().match("^[0-9]*\\.?[0-9]*$") ||
      this.discountAmount.toString().indexOf(".") == 0
    ) {
      Swal.fire("Error", "Please enter a valid discount", "error");
      isvalid = false;
    }
    else {
      isvalid = true;
    }

    if (isvalid) {
      if (Number(this.discountType) == DiscountType.Percentage) {
        if (!(Number(this.discountAmount) <= 100)) {
          Swal.fire("Error", "Please enter a valid percentage", "error");
          isvalid = false;
        }
      }
      else if (Number(this.discountType) == DiscountType.Cash) {
        if (!(Number(this.discountAmount) <= this.actualTotalPrice)) {
          Swal.fire("Error", "Discount amount should be less or equal to total price of order", "error");
          isvalid = false;
        }
      }
    }

    return isvalid;
  }
  updateSplitQuantity(operation) {
    this.updateAmountTobeSetteled();
    // this.numberOfSplit = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length;
    this.selectedSplitTobePaidIndex = null;
    if (operation == 'plus') {
      this.numberOfSplit = this.numberOfSplit + 1;
      let obSalesSplit = new SalesSplit();
      obSalesSplit.CCLastFour = null;
      obSalesSplit.GiftCardNumber = "";
      obSalesSplit.PaymentMode = PaymentMode.Cash;
      obSalesSplit.TotalAmount = 0;
      obSalesSplit.TotalTax = 0;
      obSalesSplit.CreditCardinfo = null;
      obSalesSplit.Status = SalesStatus.Pending;
      obSalesSplit.TipAmount = 0;
      this.loadKeypad();
      this.splitArray.push(obSalesSplit)
    } else {
      if (this.numberOfSplit == 1) {
        return;
      }
      this.numberOfSplit = this.numberOfSplit - 1;
      this.splitArray.splice(this.numberOfSplit, 1);
    }
    let SplitCrossverifyAmount = 0;
    this.splitArray.forEach((so, i) => {
      if (so.Status == SalesStatus.Pending) {
        let localnumberOfSplit = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length;
        this.splitArray[i].TotalAmount = RoundAmount(this.AmountToBeSatteled / localnumberOfSplit);
        SplitCrossverifyAmount = SplitCrossverifyAmount + this.splitArray[i].TotalAmount;
        this.splitArray[i].TotalTax = 0;
      }
    });
    if (SplitCrossverifyAmount > this.AmountToBeSatteled) {
      //Do settlement
      let diff = Number((SplitCrossverifyAmount - this.AmountToBeSatteled).toFixed(2));
      let lastIndex = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length - 1;
      this.splitArray[lastIndex].TotalAmount = Number((this.splitArray[lastIndex].TotalAmount - diff).toFixed(2));
      console.log("diff", diff)
    }
    if (SplitCrossverifyAmount < this.AmountToBeSatteled) {
      //Do settlement
      let diff = Number((this.AmountToBeSatteled - SplitCrossverifyAmount).toFixed(2));
      let lastIndex = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length - 1;
      this.splitArray[lastIndex].TotalAmount = Number((this.splitArray[lastIndex].TotalAmount + diff).toFixed(2));
      console.log("diff", diff)
    }

    console.log("SplitCrossverifyAmount", SplitCrossverifyAmount, "this.AmountToBeSatteled", this.AmountToBeSatteled);
  }
  checkAmountValidSplit(i) {
    // if (isNaN(this.splitArray[i].TotalAmount)) {
    //   Swal.fire({
    //     title: 'Warning',
    //     text: "Please Enter Valid Amount",
    //     showCancelButton: false,
    //     type: 'error'
    //   })
    //   this.splitArray[i].TotalAmount = 0;
    //   $("#amount" + i).val('');
    //   return;
    // }
    this.changeAmountSplit(i);
  }
  async changeAmountSplit(i) {
    this.splitPaymentMode = 0;
    if (isNaN(this.splitArray[i].TotalAmount)) {
      Swal.fire({
        title: 'Warning',
        text: "Please Enter Valid Amount",
        showCancelButton: false,
        type: 'error'
      })
      this.splitArray[i].TotalAmount = 0;
      $("#amount" + i).val('');
      return;
    }
    this.splitArray[i].TotalAmount = Number(this.splitArray[i].TotalAmount);
    let remainingAmount = this.AmountToBeSatteled - Number(this.splitArray[i].TotalAmount)
    let remainingTax = this.SalesOrderPayment.TotalTax - Number(this.splitArray[i].TotalTax)
    if (remainingAmount > 0) {
      // this.splitArray.forEach((so, index) => {
      //   if (i != index) {
      //     this.splitArray[index].TotalAmount = RoundAmount(remainingAmount / (this.numberOfSplit - 1));
      //     this.splitArray[index].TotalTax = RoundAmount(remainingTax / (this.numberOfSplit - 1));
      //   }
      // });
      let SplitCrossverifyAmount = 0;

      await this.splitArray.forEach((so, index) => {
        if (so.Status == SalesStatus.Pending) {
          let localnumberOfSplit = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length;
          if (i != index) {
            this.splitArray[index].TotalAmount = RoundAmount(remainingAmount / (localnumberOfSplit - 1));
            this.splitArray[index].TotalTax = 0;
          }
          SplitCrossverifyAmount = SplitCrossverifyAmount + this.splitArray[index].TotalAmount;
        }
      });
      if (SplitCrossverifyAmount > this.AmountToBeSatteled) {
        //Do settlement
        let diff = Number((SplitCrossverifyAmount - this.AmountToBeSatteled).toFixed(2));
        let lastIndex = this.splitArray.filter(d => d.Status == SalesStatus.Pending).length - 1;
        this.splitArray[lastIndex].TotalAmount = Number((this.splitArray[lastIndex].TotalAmount - diff).toFixed(2));
        console.log("diff", diff)
      }
    }
    if (remainingAmount < 0) {
      Swal.fire('Entered Amount is larger than actual amount to be settled')
      return;
    }
  }


  updatePaymentMode(i, event) {
    this.splitArray[i].PaymentMode = Number(event.target.value)

    // if (this.splitArray[i].PaymentMode == 2 && this.selectedMerchant == 'Worldpay') {
    //   this.getTransactionSetupid(false)
    // }
    // if (this.splitArray[i].PaymentMode == 0 && this.selectedMerchant == 'Worldpay') {
    //   this.sendSwipeIndication()
    // }
  }
  makeUniqueSplitPayment(i) {
    if (this.splitArray[i].PaymentMode == 3) {
      $("#payBtnModal").removeAttr("disabled");
    }
    if (isNaN(this.splitArray[i].TotalAmount)) {
      Swal.fire({
        title: 'Warning',
        text: "Please Enter Valid Amount",
        showCancelButton: false,
        type: 'error'
      })
      this.splitArray[i].TotalAmount = 0;
      $("#amount" + i).val('');
      return;
    }
    this.selectedSplitTobePaidIndex = i;
    this.splitPaymentMode = this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode;
    if (Number(this.splitArray[i].TotalAmount) > this.originalSalesOrderPayment.TotalAmount) {
      Swal.fire('Entered Amount is larger than actual amount to be settled')
      return;
    }
    // this.AmountToBeSatteled < Number(this.splitArray[i].TotalAmount) ||
    if ((Number(this.splitArray[i].TotalAmount) - this.AmountToBeSatteled >= 0.2)) {
      Swal.fire('Entered Amount is larger than actual amount to be settled')
      return;
    }
    this.SalesOrderPayment.PaymentMode = PaymentMode.Split;
    if (!this.SalesOrderPayment.isDineInOrder) {
      this.totalPrice = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount
      this.getCashChangePosibilities();
      if (this.splitPaymentMode == 2 && this.selectedMerchant == 'Worldpay') {
        this.getTransactionSetupid(true)
      }
      if (this.splitPaymentMode == 0 && this.selectedMerchant == 'Worldpay') {
        this.sendSwipeIndication()
      }
    } else {
      $("#kitchenPaymentModal").modal("show");
      this.dineInOrderType = PaymentMode.Split;
      this.totalPrice = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount;
    }

  }

  async makeKitchenPayment() {
    if (this.SalesOrderPayment.isDineInOrder && this.paymentMode != PaymentMode.Split) {
      $("#kitchenPaymentModal").modal("hide");
      this.CardNumber = null;
      this.paymentMode = this.dineInOrderType;
      if (this.dineInOrderType == PaymentMode.CC) {
        this.ccFormDisabled = true;
        if (this.paymentCCInnerMode == "CCManualAuthorize" && this.selectedMerchant != 'Worldpay') {
          setTimeout(() => {
            this.posmeinput.nativeElement.focus();
          }, 0);
        }
        if (this.paymentCCInnerMode == "CCManualAuthorize" && this.selectedMerchant == 'Worldpay') {
          this.getTransactionSetupid(false)
        }
        if (this.paymentCCInnerMode == "SwipeAuthorize" && this.selectedMerchant == 'Worldpay') {
          this.sendSwipeIndication()
        }
      }
      else if (this.dineInOrderType == PaymentMode.GiftCard) {
        setTimeout(() => {
          this.gcposinput.nativeElement.focus();
        }, 0);
      } else if (this.dineInOrderType == PaymentMode.Cash) {
        setTimeout(() => {
          this.getCashChangePosibilities();
          this.poscashinput.nativeElement.focus();
        }, 0);
      }
    } else {
      // this.patchSalesOrder(this.SalesOrderPayment)
      this.getCashChangePosibilities();
      // if (this.splitPaymentType == 'splitByItem') {

      // }else{

      // }
      this.SalesOrderPayment.PaymentMode = PaymentMode.Split;
      if (this.splitPaymentMode == 2 && this.selectedMerchant == 'Worldpay') {
        this.getTransactionSetupid(true)
      }
      if (this.splitPaymentMode == 0 && this.selectedMerchant == 'Worldpay') {
        this.sendSwipeIndication()
      }
      $("#kitchenPaymentModal").modal("hide");
      this.CardNumber = null;
      let pm = this.dineInOrderType;
    }
    this.activeTipsButton = "";

  }

  noTips() {
    if (this.SalesOrderPayment.isDineInOrder && this.paymentMode != PaymentMode.Split) {
      $("#kitchenPaymentModal").modal("hide");
      if (this.SalesOrderPayment.TipAmount > 0) {
        if (this.UsedLoyaltyPoints > 0) {
          this.SalesOrderPayment.TotalAmount -= this.SalesOrderPayment.TotalTax;
          this.SalesOrderPayment.TotalAmount -= this.SalesOrderPayment.TipAmount;
        } else {
          this.SalesOrderPayment.TotalAmount -= this.SalesOrderPayment.TipAmount;
        }
      }
      if (this.SalesOrderPayment.TipAmount > 0) {
        if(this.UsedLoyaltyPoints>0){
       
            this.totalPrice = this.SalesOrderPayment.TotalAmount+=this.SalesOrderPayment.TotalTax;
          
          
        }else{
          this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
        }
      }
      this.SalesOrderPayment.TipAmount = 0;


      // this.totalPrice = this.originalSalesOrderPayment.TotalAmount;
      this.totalPrice = Number(this.totalPrice.toFixed(2));

      if (this.dineInOrderType == PaymentMode.CC) {
        this.ccFormDisabled = true;
        if (this.paymentCCInnerMode == "CCManualAuthorize" && this.selectedMerchant != 'Worldpay') {
          setTimeout(() => {
            this.posmeinput.nativeElement.focus();
          }, 0);
        }
        if (this.paymentCCInnerMode == "CCManualAuthorize" && this.selectedMerchant == 'Worldpay') {
          this.getTransactionSetupid(false)
        }
        if (this.paymentCCInnerMode == "SwipeAuthorize" && this.selectedMerchant == 'Worldpay') {
         // this.sendSwipeIndication()    // commented  sendSwipeIndication calling Twice bug 4245
        }
      } else if (this.dineInOrderType == PaymentMode.GiftCard) {
        setTimeout(() => {
          this.gcposinput.nativeElement.focus();
        }, 0);
      } else if (this.dineInOrderType == PaymentMode.Cash) {
        setTimeout(() => {
          this.getCashChangePosibilities();
          this.poscashinput.nativeElement.focus();
        }, 0);
      }

    
    } else {
      this.getCashChangePosibilities();
      this.SalesOrderPayment.PayMode = PaymentMode.Split;
      if (this.splitPaymentType == 'splitByItem') {
        this.totalPrice = this.totalPrice - this.SalesOrderPayment.TipAmount;
        this.SalesOrderPayment.TipAmount = 0;
      } else {
        // this.SalesOrderPayment.TipAmount = this.originalSalesOrderPayment.TotalAmount - this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
        var paidsplit = this.splitArray.filter(p => p.Status == 4);
        if (!paidsplit || paidsplit.length == 0) {
          this.SalesOrderPayment.TipAmount = 0;
        }
        this.SalesOrderPayment.TipAmount -= this.splitArray[this.selectedSplitTobePaidIndex].TipAmount
        this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = 0;
        this.totalPrice = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount
        this.totalPrice = Number(this.totalPrice.toFixed(2));
      }
      // this.SalesOrderPayment.TotalAmount = this.originalSalesOrderPayment.TotalAmount - this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
      $("#kitchenPaymentModal").modal("hide");
    }
    this.makeKitchenPayment();
    this.activeTipsButton = "";
  }
  addTips(event) {
    this.tipsAmount = "";
    if (this.paymentMode != PaymentMode.Split) {
      this.priceKitchenItem = this.SalesOrderPayment.TotalAmount;
      let priceWithTax = 0;
      let tips = event.target.value;
      if (this.activeTipsButton != "" && this.activeTipsButton == tips) {
        event.target.classList.remove("active");
        tips = "";
      } else {
        this.activeTipsButton = tips;
      }
      let strNum = 0;
      if (tips.indexOf("%") == -1) {
        strNum = Number(tips);
      } else {
        strNum = tips.replace("%", "");
      }
      strNum = Number(strNum);
      for (let p of this.SalesOrderPayment.concessionPaymentItem) {
        priceWithTax +=
          Number((p["Price"]).toFixed(2)) * p["NumberSold"];
      }
      if(this.SalesOrderPayment.TotalAmount!=priceWithTax){
        priceWithTax=this.originalSalesOrderPayment.TotalAmount;
      }
      this.SalesOrderPayment.TipAmount = Number((priceWithTax * (strNum / 100)).toFixed(2));
      this.priceKitchenItem = RoundAmount(Number(priceWithTax) + this.SalesOrderPayment.TipAmount);
      this.SalesOrderPayment.TotalAmount = this.priceKitchenItem;
      this.totalPrice = this.SalesOrderPayment.TotalAmount;
    } else {
      if (this.splitPaymentType == 'splitByItem') {
        this.totalPrice -= this.SalesOrderPayment.TipAmount
        this.SalesOrderPayment.TipAmount = 0;
      }
      // else {
      //   if(this.splitArray[this.selectedSplitTobePaidIndex].TipAmount != 0){
      //     this.SalesOrderPayment.TipAmount -= this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
      //   }
      //   this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = 0;
      // }
      let currentSplitAmount = 0;
      if (this.splitPaymentType == 'splitByItem') {
        currentSplitAmount = this.totalPrice;
      } else {
        currentSplitAmount = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount;
        this.SalesOrderPayment.TipAmount -= this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
        this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = 0;
      }
      let priceWithTax = 0;
      let tips = event.target.value;
      if (this.activeTipsButton != "" && this.activeTipsButton == tips) {
        event.target.classList.remove("active");
        tips = "";
      } else {
        this.activeTipsButton = tips;
      }
      let strNum = 0;
      if (tips.indexOf("%") == -1) {
        strNum = Number(tips);
      } else {
        strNum = tips.replace("%", "");
      }
      strNum = Number(strNum);
      priceWithTax = currentSplitAmount;
      if (this.splitPaymentType == 'splitByItem') {
        this.SalesOrderPayment.TipAmount += Number((priceWithTax * (strNum / 100)).toFixed(2));
        this.totalPrice += Number((priceWithTax * (strNum / 100)).toFixed(2));
      } else {
        this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = Number((priceWithTax * (strNum / 100)).toFixed(2));
        this.SalesOrderPayment.PayMode = PaymentMode.Split;
        this.SalesOrderPayment.TipAmount += this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
        this.totalPrice = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount + this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
      }
      this.totalPrice = Number(this.totalPrice.toFixed(2));
      this.getCashChangePosibilities();
    }
  }
  addTipsbyAmount(event) {
    this.tipsPercentage = "";
    if (this.paymentMode != PaymentMode.Split) {
      this.priceKitchenItem = this.SalesOrderPayment.TotalAmount;
      let priceWithTax = 0;
      let tips = event.target.value;
      if (this.activeTipsButton != "" && this.activeTipsButton == tips) {
        event.target.classList.remove("active");
        this.activeTipsButton = tips = "";
      } else {
        this.activeTipsButton = tips;
      }
      let strNum = tips;
      if (tips.indexOf("$") == -1) {
        strNum = Number(tips);
      } else {
        strNum = tips.replace("$", "");
      }
      strNum = Number(strNum);
      if (strNum < 0) {
        event.target.value = "";
        Swal.fire('Please enter a valid amount.')
        return;
      }

      for (let p of this.SalesOrderPayment.concessionPaymentItem) {
        priceWithTax +=
          Number((p["Price"]).toFixed(2)) * p["NumberSold"];
      }
      if (this.SalesOrderPayment.TotalAmount != priceWithTax) {
        priceWithTax = this.originalSalesOrderPayment.TotalAmount;
      }
      this.SalesOrderPayment.TipAmount = strNum;
      this.priceKitchenItem = RoundAmount(Number(priceWithTax) + this.SalesOrderPayment.TipAmount);
      this.SalesOrderPayment.TotalAmount = this.priceKitchenItem;
      this.totalPrice = this.SalesOrderPayment.TotalAmount;
    } else {
      if (this.splitPaymentType == 'splitByItem') {
        this.totalPrice -= this.SalesOrderPayment.TipAmount
        this.SalesOrderPayment.TipAmount = 0;
      }
      // else {
      //   if(this.splitArray[this.selectedSplitTobePaidIndex].TipAmount != 0){
      //     this.SalesOrderPayment.TipAmount -= this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
      //   }
      //   this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = 0;
      // }
      let currentSplitAmount = 0;
      if (this.splitPaymentType == 'splitByItem') {
        currentSplitAmount = this.totalPrice;
      } else {
        currentSplitAmount = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount;
        this.SalesOrderPayment.TipAmount -= this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
        this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = 0;
      }
      let priceWithTax = 0;
      let tips = event.target.value;
      if (this.activeTipsButton != "" && this.activeTipsButton == tips) {
        event.target.classList.remove("active");
        this.activeTipsButton = tips = "";
      } else {
        this.activeTipsButton = tips;
      }
      let strNum = tips;
      if (tips.indexOf("$") == -1) {
        strNum = Number(tips);
      } else {
        strNum = tips.replace("$", "");
      }
      strNum = Number(strNum);
      priceWithTax = currentSplitAmount;
      if (this.splitPaymentType == 'splitByItem') {
        this.SalesOrderPayment.TipAmount += strNum;
        this.totalPrice += strNum;
      } else {
        this.splitArray[this.selectedSplitTobePaidIndex].TipAmount = strNum;
        this.SalesOrderPayment.PayMode = PaymentMode.Split;
        this.SalesOrderPayment.TipAmount += this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
        this.totalPrice = this.splitArray[this.selectedSplitTobePaidIndex].TotalAmount + this.splitArray[this.selectedSplitTobePaidIndex].TipAmount;
      }
      this.totalPrice = Number(this.totalPrice.toFixed(2));
      this.getCashChangePosibilities();
    }
  }

  async splitOrderPay() {
    console.log(this.SalesOrderPayment)
    $("#payBtnModal").attr("disabled", "true");
    this.printService.printModeEnabled = true;
    this.LoaderService.showPaymentloader();
    this.SalesOrderPayment.PaymentMode = PaymentMode.Split;
    if (!this.SalesOrderPayment.isDineInOrder) {
      await this.shiftService.getShiftById(this.shiftData.ShiftId).subscribe(async (res: Shift) => {
        if (res.Status != "Open") {
          this.LoaderService.hidePaymentloader();
          Swal.fire({
            title: this.es.posErrorMsgList[43].message,
            text: this.es.posErrorMsgList[44].message,
            showCancelButton: false,
            type: "warning",
          }).then(() => {
            this.router.navigate([
              `/locations/setting/${this.LocationId}/pos-shift`,
              this.LocationId,
            ]);
            this.printService.printModeEnabled = false;
            return true;
          });
        }
      });
    }

    if (this.splitPaymentType == "splitByItem") {
      //POS sales Order create logic
      let NewSalesOrderPost = CloneObject(this.SalesOrderPayment);
      if (!this.SalesOrderPayment.isDineInOrder) {
        var price = 0, tax = 0 , actualprice=0,actualtax=0;;
        await this.SalesOrderPayment.Tickets.forEach((so, index) => {
          if (so.Selected) {
            price += so.Price;
            tax += so.Tax;
            actualprice+=so.ActualAmount;
            actualtax+=so.ActualTax;
            if (so.ComboID != "") {
              this.SalesOrderPayment.Concession.forEach((ci, cindex) => {
                if (ci.ComboID == so.ComboID) {
                  NewSalesOrderPost.Concession[cindex].NumberSold = this.SalesOrderPayment.Tickets.filter(td => (td.Selected && ci.ComboID == td.ComboID)).length;
                  // price += (so.Price * (NewSalesOrderPost.Concession[cindex].NumberSold));
                  // tax += (so.Tax * (NewSalesOrderPost.Concession[cindex].NumberSold));
                  price += (ci.Price);
                  tax += (ci.Tax);
                  actualprice+=so.ActualAmount;
                  actualtax+=so.ActualTax;
                } else {
                  NewSalesOrderPost.Concession[cindex].remove = true;
                }
              });
            }
          } else {
            NewSalesOrderPost.Tickets[index].remove = true;
            // if (so.ComboID != "") {
            //   NewSalesOrderPost.Concession.forEach((ce, ci) => {
            //     if (ce.ComboID == so.ComboID && !so.Selected) {
            //       NewSalesOrderPost.Concession[ci].remove = true;
            //     }
            //   });

            this.SalesOrderPayment.Concession.forEach((ce, ci) => {
              if (ce.ComboID != "" && ce.ComboID == so.ComboID) {
                let ticketCloneArray = CloneObject(this.SalesOrderPayment.Tickets)
                let selectedComboTicketCount = ticketCloneArray.filter(ct => ((ct.ComboID == ce.ComboID && !ct.Selected))).length;
                if (ce.NumberSold == selectedComboTicketCount) {
                  NewSalesOrderPost.Concession[ci].remove = true;
                }
              }
            });
            // }
          }
        });
        NewSalesOrderPost.Tickets = NewSalesOrderPost.Tickets.filter(t => !t.remove);
        await this.SalesOrderPayment.Concession.forEach((so, index) => {
          if (so.ComboID == "") {
            if (so.Selected) {
              if (NewSalesOrderPost.Concession[index].ActualQuantity == NewSalesOrderPost.Concession[index].NumberSold) {
                price += (so.Price * (so.NumberSold));
                tax += (so.Tax * (so.NumberSold));
                actualprice+=(so.ActualAmount * (so.NumberSold));
                actualtax+=(so.ActualTax * (so.NumberSold));
                NewSalesOrderPost.Concession[index].remove = false;
              } else {
                let newQuantity = NewSalesOrderPost.Concession[index].ActualQuantity - NewSalesOrderPost.Concession[index].NumberSold;
                price += (so.Price * (NewSalesOrderPost.Concession[index].NumberSold));
                tax += (so.Tax * (NewSalesOrderPost.Concession[index].NumberSold));
                actualprice+=(so.ActualAmount * (NewSalesOrderPost.Concession[index].NumberSold));
                actualtax+=(so.ActualTax * (NewSalesOrderPost.Concession[index].NumberSold));
                NewSalesOrderPost.Concession[index].NumberSold = newQuantity;
                NewSalesOrderPost.Concession[index].ActualQuantity = newQuantity;
                NewSalesOrderPost.Concession[index].remove = false;
              }
            } else {
              NewSalesOrderPost.Concession[index].remove = true;
            }

          }
        });
        NewSalesOrderPost.Concession = NewSalesOrderPost.Concession.filter(c => (!c.remove));
        await NewSalesOrderPost.Concession.forEach((so, index) => {
          so.Price -= so.Tax;
          if (so.ActualAmount) {
            so.ActualAmount -= so.ActualTax;
          }
        });
        await this.SalesOrderPayment.AdditionalCharges.forEach((so, index) => {
          if (so.Selected) {
            let cloneObjectTickets = CloneObject(this.SalesOrderPayment.Tickets)
            let NumberOfSold = cloneObjectTickets.filter(td => (td.Selected && td.additionalChargeUniqueId == so.additionalChargeUniqueId)).length;
            // price += (so.AdditionalPrice * (so.NumberOfTicketSold));
            // tax += (so.Tax * (so.NumberOfTicketSold));
            price += (so.AdditionalPrice * NumberOfSold);
            tax += (so.Tax * NumberOfSold);
            actualprice+=(so.ActualAmount * NumberOfSold);
            actualtax+=(so.ActualTax * NumberOfSold);
            NewSalesOrderPost.AdditionalCharges[index].NumberOfTicketSold = NumberOfSold;
          } else {
            NewSalesOrderPost.AdditionalCharges[index].remove = true;
          }
        });
        NewSalesOrderPost.AdditionalCharges = NewSalesOrderPost.AdditionalCharges.filter(c => !c.remove);
        await this.SalesOrderPayment.GiftCardSale.forEach((so, index) => {
          if (so.Selected) {
            price += so.Amount;
            actualprice+=so.ActualAmount;
          } else {
            NewSalesOrderPost.GiftCardSale[index].remove = true;
          }
        });
        NewSalesOrderPost.GiftCardSale = NewSalesOrderPost.GiftCardSale.filter(c => !c.remove);
        console.log("price-------", price, '==============tax===============', tax);
        NewSalesOrderPost.TotalAmount = price;
        NewSalesOrderPost.TotalTax = tax;
        NewSalesOrderPost.ActualAmount = actualprice?actualprice:0;
        NewSalesOrderPost.ActualTax = actualtax?actualtax:0;
      }
      //cash
      //Swipe SPLIT
      if (this.splitPaymentMode == 0) {
        if (this.selectedMerchant == "Authorize.net") {
          NewSalesOrderPost.CreditCardinfo = this.cc_auth_card_number;
          NewSalesOrderPost.PaymentMode = PaymentMode.CC;
          this.cc_last_four = this.cc_auth_card_number
            ?.split(";")[1]
            ?.split("=")[0];
          this.cc_last_four = this.cc_last_four?.substring(
            this.cc_last_four.length - 4
          );
        }
        if (this.selectedMerchant == "Worldpay") {
          NewSalesOrderPost.CreditCardinfo = "succeeded";;
          NewSalesOrderPost.PaymentMode = PaymentMode.CC;
          NewSalesOrderPost.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
          NewSalesOrderPost.LaneId = this.SalesOrderPayment.LaneId;
          NewSalesOrderPost.PaymentID = this.transactionid;
          NewSalesOrderPost.PaymentType = this.cardTypeWorldpay;
          NewSalesOrderPost.CCLastFour = this.cc_last_four;
          NewSalesOrderPost.WorldpayTransactionDetails = this.worldpayTransactionDetails;
        }
        // }
      }
      //ManualCC SPLIT
      if (this.splitPaymentMode == 2) {
        if (this.selectedMerchant == "Authorize.net") {
          if (this.ccAuthForm?.form.invalid) {
            this.LoaderService.hidePaymentloader();
            this.paymentError = "Fill Form With Right Value";
            this.printService.printModeEnabled = false;
            return;
          }
          this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
            this.ccAuthorize["cardNumber"].length - 4
          );
          NewSalesOrderPost.PaymentMode = PaymentMode.ManualCC;
          NewSalesOrderPost.CreditCardinfo =
            this.ccAuthorize["fullName"].trim() +
            "^" +
            this.ccAuthorize["cardNumber"] +
            "^" +
            this.ccAuthorize["mm"] +
            "^" +
            this.ccAuthorize["yy"] +
            "^" +
            this.ccAuthorize["cvv"] +
            "^" +
            this.ccAuthorize["zipcode"];
        }
        if (this.selectedMerchant == "Worldpay") {
          // WORLD PAY IFRAME FORM PARAMS
          NewSalesOrderPost.Status = SalesStatus.Reserved;
          NewSalesOrderPost.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
          NewSalesOrderPost.PaymentID = this.transactionid;
          NewSalesOrderPost.CCLastFour = this.cc_last_four;
          NewSalesOrderPost.PaymentType = this.cardTypeWorldpay;
          NewSalesOrderPost.LaneId = this.SalesOrderPayment.LaneId;
          NewSalesOrderPost.WorldpayTransactionDetails = this.worldpayTransactionDetails;
        }
      }
      //Giftcard PaymentSplit
      if (this.splitPaymentMode == 3) {
        if (
          !this.CardNumber ||
          (this.CardNumber && this.CardNumber.toString().length < 4 || this.CardNumber.toString().split('.').length != 1)
        ) {
          Swal.fire({
            title: this.es.posErrorMsgList[47].message,
            text: this.es.posErrorMsgList[48].message,
            showCancelButton: false,
            type: "warning",
          });
          this.LoaderService.hidePaymentloader();
          $("#payBtnModal").removeAttr("disabled");
          this.printService.printModeEnabled = false;
          return;
        }
        if (this.CardNumber) NewSalesOrderPost.GiftCardNumber = this.CardNumber;
        // if (this.SalesOrderPayment.GiftCardSale.length === 0) {
        if (this.CardNumber != null) {
          const res: GiftCard = await this.giftCardService.getGiftCardbyCardNumber(
            this.CardNumber
          );
          if (res != null) {
            if (res.Status === GiftCardStatus.Registered) {
              Swal.fire({
                title: this.es.posErrorMsgList[49].message,
                text: this.es.posErrorMsgList[50].message,
                showCancelButton: false,
                type: "warning",
              });
              this.LoaderService.hidePaymentloader();
              $("#payBtnModal").removeAttr("disabled");
              this.printService.printModeEnabled = false;
              return;
            } else {
              const patchres = await this.giftCardService.patchGiftCard(
                this.CardNumber,
                this.totalPrice
              );
              if (!patchres) {
                Swal.fire({
                  title: this.es.posErrorMsgList[51].message,
                  text: this.es.posErrorMsgList[52].message,
                  showCancelButton: false,
                  type: "warning",
                });
                this.LoaderService.hidePaymentloader();
                $("#payBtnModal").removeAttr("disabled");
                this.printService.printModeEnabled = false;
                return;
              }

            }
          } else {
            Swal.fire({
              title: this.es.posErrorMsgList[53].message,
              text: this.es.posErrorMsgList[54].message,
              type: "error",
            });
            this.LoaderService.hidePaymentloader();
            $("#payBtnModal").removeAttr("disabled");
            this.printService.printModeEnabled = false;
            return;
          }
        }
        // }
      }
      if (this.SalesOrderPayment.isDineInOrder) {
        this.dineInSplitItemManage();
      } else {

        this.postSplitItemSalesOrder(NewSalesOrderPost);
      }
    } else {
      //cash
      //Swipe SPLIT
      if (this.splitArray[this.selectedSplitTobePaidIndex] && this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode == 0) {
        // if (this.paymentCCInnerMode == "SwipeAuthorize") {
        if (this.selectedMerchant == "Authorize.net") {
          this.splitArray[this.selectedSplitTobePaidIndex].CreditCardinfo = this.cc_auth_card_number;
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode = PaymentMode.CC;
          this.cc_last_four = this.cc_auth_card_number
            ?.split(";")[1]
            ?.split("=")[0];
          this.cc_last_four = this.cc_last_four?.substring(
            this.cc_last_four.length - 4
          );
        }
        if (this.selectedMerchant == "Worldpay") {
          this.splitArray[this.selectedSplitTobePaidIndex].CreditCardinfo = this.cc_auth_card_number ? this.cc_auth_card_number : this.SalesOrderPayment.CreditCardinfo
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode = PaymentMode.CC;
          this.splitArray[this.selectedSplitTobePaidIndex].MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
          this.splitArray[this.selectedSplitTobePaidIndex].LaneId = this.SalesOrderPayment.LaneId;
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentID = this.transactionid;
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentType = this.cardTypeWorldpay;
          this.splitArray[this.selectedSplitTobePaidIndex].CCLastFour = this.cc_last_four;
          this.splitArray[this.selectedSplitTobePaidIndex].WorldpayTransactionDetails = this.worldpayTransactionDetails;
        }
        // }
      }
      //ManualCC SPLIT
      if (this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode == 2) {
        if (this.selectedMerchant == "Authorize.net") {
          if (this.ccAuthForm?.form.invalid) {
            this.LoaderService.hidePaymentloader();
            this.paymentError = "Fill Form With Right Value";
            this.printService.printModeEnabled = false;
            return;
          }
          this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
            this.ccAuthorize["cardNumber"].length - 4
          );
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode = PaymentMode.ManualCC;
          this.splitArray[this.selectedSplitTobePaidIndex].CreditCardinfo =
            this.ccAuthorize["fullName"].trim() +
            "^" +
            this.ccAuthorize["cardNumber"] +
            "^" +
            this.ccAuthorize["mm"] +
            "^" +
            this.ccAuthorize["yy"] +
            "^" +
            this.ccAuthorize["cvv"] +
            "^" +
            this.ccAuthorize["zipcode"];
        }
        if (this.selectedMerchant == "Worldpay") {
          // WORLD PAY IFRAME FORM PARAMS
          this.splitArray[this.selectedSplitTobePaidIndex].Status = SalesStatus.Reserved;
          this.splitArray[this.selectedSplitTobePaidIndex].MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentID = this.transactionid;
          this.splitArray[this.selectedSplitTobePaidIndex].CCLastFour = this.cc_last_four;
          this.splitArray[this.selectedSplitTobePaidIndex].PaymentType = this.cardTypeWorldpay;
          this.splitArray[this.selectedSplitTobePaidIndex].LaneId = this.SalesOrderPayment.LaneId;
          this.splitArray[this.selectedSplitTobePaidIndex].WorldpayTransactionDetails = this.worldpayTransactionDetails;
        }
      }
      //Giftcard PaymentSplit
      if (this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode == PaymentMode.GiftCard) {
        if (
          !this.CardNumber ||
          (this.CardNumber && this.CardNumber.toString().length < 4 || this.CardNumber.toString().split('.').length != 1)
        ) {
          Swal.fire({
            title: this.es.posErrorMsgList[47].message,
            text: this.es.posErrorMsgList[48].message,
            showCancelButton: false,
            type: "warning",
          });
          this.LoaderService.hidePaymentloader();
          $("#payBtnModal").removeAttr("disabled");
          this.printService.printModeEnabled = false;
          return;
        }
        if (this.SalesOrderPayment.GiftCardSale.length === 0) {
          if (this.CardNumber) this.splitArray[this.selectedSplitTobePaidIndex].GiftCardNumber = this.CardNumber;
          if (this.CardNumber != null) {
            const res: GiftCard = await this.giftCardService.getGiftCardbyCardNumber(
              this.CardNumber
            );
            if (res != null) {
              if (res.Status === GiftCardStatus.Registered) {
                Swal.fire({
                  title: this.es.posErrorMsgList[49].message,
                  text: this.es.posErrorMsgList[50].message,
                  showCancelButton: false,
                  type: "warning",
                });
                this.LoaderService.hidePaymentloader();
                $("#payBtnModal").removeAttr("disabled");
                this.printService.printModeEnabled = false;
                return;
              } else {
                const patchres = await this.giftCardService.patchGiftCard(
                  this.CardNumber,
                  this.totalPrice
                );
                if (!patchres) {
                  Swal.fire({
                    title: this.es.posErrorMsgList[51].message,
                    text: this.es.posErrorMsgList[52].message,
                    showCancelButton: false,
                    type: "warning",
                  });
                  this.LoaderService.hidePaymentloader();
                  $("#payBtnModal").removeAttr("disabled");
                  this.printService.printModeEnabled = false;
                  return;
                }

              }
            } else {
              Swal.fire({
                title: this.es.posErrorMsgList[53].message,
                text: this.es.posErrorMsgList[54].message,
                type: "error",
              });
              this.LoaderService.hidePaymentloader();
              $("#payBtnModal").removeAttr("disabled");
              this.printService.printModeEnabled = false;
              return;
            }
          }
        }
      }
      if (this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode == 1 || this.splitArray[this.selectedSplitTobePaidIndex].PaymentMode == 3) {
        this.splitArray[this.selectedSplitTobePaidIndex].Status = SalesStatus.Reserved;
      }
      if (
        this.SalesOrderPayment.ConfirmationCode &&
        this.SalesOrderPayment.ConfirmationCode != ""
      ) {
        await this.SalesOrderPayment.SalesSplit.push(this.splitArray[this.selectedSplitTobePaidIndex])
        this.patchSalesOrder(this.SalesOrderPayment);
      } else {
        await this.postSplitSalesOrser()
      };
    }
  }
  clearSplitVariables() {
    this.voidSalesOrderArray = [];
    this.voidAmount = 0;
    this.numberOfSplit = 0;
    this.splitArray = []
    this.selectedSplitTobePaidIndex = null;
    this.splitPaymentMode = PaymentMode;
    this.AmountToBeSatteled = 0;
    this.splitAmountTobeSettled = 0;
    this.disableAfterOnePayment = false;
    this.splitPaymentType = "";
    //sessionStorage.removeItem('MemberDetail');
    this.backToPaymentModes();
    $(".modal-backdrop").remove();
  }
  async patchSalesOrder(salesordersplitobject) {
    if (this.SalesOrderPayment.isDineInOrder && !this.disableAfterOnePayment) {
      salesordersplitobject.Concession.forEach((co, index) => {
        salesordersplitobject.Concession[index].Price -= salesordersplitobject.Concession[index].Tax;
        salesordersplitobject.Concession[index].ActualAmount -= salesordersplitobject.Concession[index].ActualTax;
      });
      salesordersplitobject.ActualAmount -= salesordersplitobject.ActualTax;
      salesordersplitobject.TotalAmount -= salesordersplitobject.TotalTax

    }
    if (!this.SalesOrderPayment.isDineInOrder && !this.disableAfterOnePayment) {
      // await this.SalesOrderPayment.AdditionalCharges.forEach((so, index) => {
      //   this.SalesOrderPayment.AdditionalCharges[index].AdditionalPrice = this.SalesOrderPayment.AdditionalCharges[index].AdditionalPrice - this.SalesOrderPayment.AdditionalCharges[index].Tax;
      // });
      await salesordersplitobject.AdditionalCharges.forEach((so, index) => {
        salesordersplitobject.AdditionalCharges[index].AdditionalPrice = salesordersplitobject.AdditionalCharges[index].AdditionalPrice - salesordersplitobject.AdditionalCharges[index].Tax;
      });
    }
    if (!this.isMemberVerified) {
      salesordersplitobject.OrderLoyaltyCredit = 0;
      await salesordersplitobject.Concession.forEach((ci, index) => {
        ci.ItemLoyaltyCredit = 0;
      });
      await salesordersplitobject.Tickets.forEach((ti, index) => {
        ti.ItemLoyaltyCredit = 0;
      });
    } else {
      salesordersplitobject.OrderLoyaltyCredit = this.loyaltyPoints;
      if (this.UsedLoyaltyPoints > 0) {
        salesordersplitobject.OrderLoyaltyCredit = 0;
        await salesordersplitobject.Concession.forEach((ci, index) => {
          ci.ItemLoyaltyCredit = 0;
        });
        await salesordersplitobject.Tickets.forEach((ti, index) => {
          ti.ItemLoyaltyCredit = 0;
        });
      }
    }
    await this.posService.patchSalesOrder(salesordersplitobject).subscribe(
      async (response) => {
        if (response && response.SalesSplit) {
          var errorMessage = "";
          console.log("response", response)
          this.activeTipsButton = "";
          let currentSplitUpdate = response.SalesSplit[response.SalesSplit.length - 1];
          if (currentSplitUpdate.Status == SalesStatus.Reserved) {
            this.splitArray[this.selectedSplitTobePaidIndex].Status = SalesStatus.Reserved;
            this.updateAmountTobeSetteled();
            this.selectedSplitTobePaidIndex = null;
            this.LoaderService.hidePaymentloader();
          }
          if (this.AmountToBeSatteled == 0 || this.AmountToBeSatteled <= 0.2) {
            this.posService.FinalizeSaleOrder(this.SalesOrderPayment).subscribe(
              async (response) => {
                this.updatePOSSalesOrderById(this.SalesOrderPayment);
                this.clearSplitVariables();
                this.ClearDataNPrint(this.SalesOrderPayment, this.SalesOrderPayment.holdIds, true);
                if (this.SalesOrderPayment.isDineInOrder) {
                  this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
                }
              }, (err) => {
              }
            );
          }
        }

      },
      (err) => {
        this.LoaderService.hidePaymentloader();
        Swal.fire(this.es.posErrorMsgList[34].message, err, "error");
        this.cc_last_four = "";
        this.cc_auth_card_number = "";
        this.printService.printModeEnabled = false;
      }
    );
  }
  async dineInSplitItemManage() {
    console.log("before", this.SalesOrderPayment);
    let newDinePostSalesOrder = CloneObject(this.SalesOrderPayment);
    let price = 0, tax = 0, actualprice=0,actualtax=0;;
    await this.SalesOrderPayment.Concession.forEach((so, index) => {
      if (so.Selected) {
        price += (so.Price * (so.NumberSold));
        tax += (so.Tax * (so.NumberSold));
        actualprice +=so.ActualAmount;
        actualtax +=so.ActualTax;
        newDinePostSalesOrder.Concession[index].NumberSold = so.NumberSold;
        newDinePostSalesOrder.Concession[index].Price -= newDinePostSalesOrder.Concession[index].Tax;
        newDinePostSalesOrder.Concession[index].ActualAmount -= newDinePostSalesOrder.Concession[index].ActualTax ;
      } else {
        newDinePostSalesOrder.Concession[index].remove = true;
      }
    });
    newDinePostSalesOrder.Concession = newDinePostSalesOrder.Concession.filter(c => (!c.remove));
    console.log("after", newDinePostSalesOrder);
    newDinePostSalesOrder.ConfirmationCode = null;
    newDinePostSalesOrder.CreditCardinfo = null;
    newDinePostSalesOrder.TotalAmount = price;
    newDinePostSalesOrder.TotalTax = tax;
    newDinePostSalesOrder.ActualAmount=actualprice?actualprice:0;
    newDinePostSalesOrder.ActualTax=actualtax?actualtax:0;
    //Logic Pending



    //Swipe SPLIT
    if (this.splitPaymentMode == 0) {
      if (this.selectedMerchant == "Authorize.net") {
        newDinePostSalesOrder.CreditCardinfo = this.cc_auth_card_number;
        newDinePostSalesOrder.PaymentMode = PaymentMode.CC;
        this.cc_last_four = this.cc_auth_card_number
          ?.split(";")[1]
          ?.split("=")[0];
        this.cc_last_four = this.cc_last_four?.substring(
          this.cc_last_four.length - 4
        );
      }
      if (this.selectedMerchant == "Worldpay") {
        newDinePostSalesOrder.CreditCardinfo = this.cc_auth_card_number;
        newDinePostSalesOrder.PaymentMode = PaymentMode.CC;
        newDinePostSalesOrder.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
        newDinePostSalesOrder.LaneId = this.SalesOrderPayment.LaneId;
        newDinePostSalesOrder.PaymentID = this.transactionid;
        newDinePostSalesOrder.PaymentType = this.cardTypeWorldpay;
        newDinePostSalesOrder.CCLastFour = this.cc_last_four;
        newDinePostSalesOrder.WorldpayTransactionDetails = this.worldpayTransactionDetails;
      }
      // }
    }
    //ManualCC SPLIT
    if (this.splitPaymentMode == 2) {
      if (this.selectedMerchant == "Authorize.net") {
        if (this.ccAuthForm?.form.invalid) {
          this.LoaderService.hidePaymentloader();
          this.paymentError = "Fill Form With Right Value";
          this.printService.printModeEnabled = false;
          return;
        }
        this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
          this.ccAuthorize["cardNumber"].length - 4
        );
        newDinePostSalesOrder.PaymentMode = PaymentMode.ManualCC;
        newDinePostSalesOrder.CreditCardinfo =
          this.ccAuthorize["fullName"].trim() +
          "^" +
          this.ccAuthorize["cardNumber"] +
          "^" +
          this.ccAuthorize["mm"] +
          "^" +
          this.ccAuthorize["yy"] +
          "^" +
          this.ccAuthorize["cvv"] +
          "^" +
          this.ccAuthorize["zipcode"];
      }
      if (this.selectedMerchant == "Worldpay") {
        // WORLD PAY IFRAME FORM PARAMS
        newDinePostSalesOrder.Status = SalesStatus.Reserved;
        newDinePostSalesOrder.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : this.SalesOrderPayment.MerchantCredentialId;
        newDinePostSalesOrder.PaymentID = this.transactionid;
        newDinePostSalesOrder.CCLastFour = this.cc_last_four;
        newDinePostSalesOrder.PaymentType = this.cardTypeWorldpay;
        newDinePostSalesOrder.LaneId = this.SalesOrderPayment.LaneId;
        newDinePostSalesOrder.WorldpayTransactionDetails = this.worldpayTransactionDetails;
      }
    }
    //Giftcard PaymentSplit
    if (this.splitPaymentMode == 3) {
      if (this.SalesOrderPayment.GiftCardSale.length === 0) {
        if (this.CardNumber) newDinePostSalesOrder.GiftCardNumber = this.CardNumber;
      }
    }


    this.postSplitItemSalesOrder(newDinePostSalesOrder);
  }
  postSplitItemSalesOrder(NewSalesOrderPost) {
    NewSalesOrderPost.PaymentMode = this.splitPaymentMode;
    let clonefi_2 = CloneObject(NewSalesOrderPost.Concession)
    this.kioskPOSService.checkConcessionInventory(clonefi_2).subscribe(async(res: any) => {
      if (res.length > 0) {
        let items = res.join(", ");
        console.log("items", items)
        let scentence = [];
        for (var i = 0; i <= (res.length - 1); i++) {
          scentence.push(res[i])
        }
        let fullScentence = '';
        scentence.forEach((f, i) => {
          f = f.split('_')
          fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
        });
        this.LoaderService.hidePaymentloader();
        Swal.fire({
          title: this.es.posErrorMsgList[36].message,
          text: this.es.posErrorMsgList[37].message + fullScentence + this.es.posErrorMsgList[38].message,
          showCancelButton: false,
          type: "warning",
        });
        $("#payMethodModel").modal("hide");
        this.printService.printModeEnabled = false;
        this.router.navigate(["/POS"]);
      } else {
        // NewSalesOrderPost.AdditionalCharges.length > 0 || 
        if (NewSalesOrderPost.Tickets.length > 0 || NewSalesOrderPost.Concession.length > 0 || NewSalesOrderPost.GiftCardSale.length > 0) {
          if (!this.isSplitByItemMemberVerified) {
            NewSalesOrderPost.OrderLoyaltyCredit = 0;
            await NewSalesOrderPost.Concession.forEach((ci, index) => {
              ci.ItemLoyaltyCredit = 0;
            });
            await NewSalesOrderPost.Tickets.forEach((ti, index) => {
              ti.ItemLoyaltyCredit = 0;
            });
          } else {
            let CurselectedLoyaltyOrder = 0;
            await NewSalesOrderPost.Concession.forEach((ci, index) => {
              if (ci.ComboID == "" || !ci.ComboID) {
                if(ci.ItemLoyaltyCredit && ci.ItemLoyaltyCredit > 0 && ci.NumberSold>0){
                  CurselectedLoyaltyOrder += (ci.ItemLoyaltyCredit * ci.NumberSold);
                }
              }
            });
            await NewSalesOrderPost.Tickets.forEach((ti, index) => {
              if(ti.ItemLoyaltyCredit && ti.ItemLoyaltyCredit > 0){
                CurselectedLoyaltyOrder += ti.ItemLoyaltyCredit;
              }
            });
            NewSalesOrderPost.OrderLoyaltyCredit = CurselectedLoyaltyOrder;
            console.log("this.memberdata============================",this.memberdata);
            sessionStorage.setItem("MemberDetail", this.memberdata);
            if (this.UsedLoyaltyPoints > 0) {
              NewSalesOrderPost.OrderLoyaltyCredit = 0;
              await NewSalesOrderPost.Concession.forEach((ci, index) => {
                ci.ItemLoyaltyCredit = 0;
              });
              await NewSalesOrderPost.Tickets.forEach((ti, index) => {
                ti.ItemLoyaltyCredit = 0;
              });
            }
          }
          delete NewSalesOrderPost.ConfirmationCode;
          this.posService.postSalesOrder(NewSalesOrderPost).subscribe(async (response) => {
            if (
              this.splitPaymentMode == PaymentMode.CC &&
              (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")
            ) {
              if (response.CreditCardinfo == "succeeded" && (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")) {
                if (this.SalesOrderPayment.isDineInOrder) {
                  this.posService.ConfirmPaymentSaleOrder(response).subscribe(
                    async (response1) => {
                      NewSalesOrderPost.ConfirmationCode = response.ConfirmationCode;
                      // Update GC Sales & Concession Invetories
                      this.updatePOSSalesOrderById(NewSalesOrderPost);

                    }, (err) => {
                      this.printService.printModeEnabled = false;
                      this.LoaderService.hidePaymentloader();
                    }
                  );
                  this.ClearDineInSplitItemDataPrint(response);
                } else {
                  this.posService.ConfirmPaymentSaleOrder(response).subscribe(
                    async (response1) => {
                      NewSalesOrderPost.ConfirmationCode = response.ConfirmationCode;
                      // Update GC Sales & Concession Invetories
                      this.updatePOSSalesOrderById(NewSalesOrderPost);

                    }, (err) => {
                      this.printService.printModeEnabled = false;
                      this.LoaderService.hidePaymentloader();
                    }
                  );
                  this.ClearSplitByItemDataPrint(response);
                } if (this.selectedMerchant == "Worldpay")
                  this.cc_last_four = NewSalesOrderPost.CCLastFour;

                this.PaymentService.passPaymentIntentIdAuthorize(
                  response.ConfirmationCode,
                  response.PaymentID,
                  "true",
                  this.cc_last_four.toString()
                ).toPromise();
                this.cc_last_four = "";
                this.cc_auth_card_number = "";
              } else {
                this.paymentError = response.CreditCardinfo;
                this.toastrService.error(this.paymentError);
                this.printService.printModeEnabled = false;
                this.LoaderService.hidePaymentloader();
                this.PaymentService.passPaymentIntentIdAuthorize(
                  response.ConfirmationCode,
                  "failPayment",
                  "false",
                  this.cc_last_four.toString()
                ).toPromise();
                this.cc_last_four = "";
                this.cc_auth_card_number = "";
              }
            } else {
              if (this.SalesOrderPayment.isDineInOrder) {
                this.ClearDineInSplitItemDataPrint(response);
                this.posService.ConfirmPaymentSaleOrder(response).subscribe(
                  async (response1) => {
                    this.updatePOSSalesOrderById(response);
                  }, (err) => {
                    this.printService.printModeEnabled = false;
                    this.LoaderService.hidePaymentloader();
                    Swal.fire(
                      "",
                      this.es.posErrorMsgList[35].message,
                      "error"
                    );
                  }
                );

              } else {
                this.updatePOSSalesOrderById(response);
                this.ClearSplitByItemDataPrint(response);
              }
            }
          }, (err) => {
            this.printService.printModeEnabled = false;
            this.LoaderService.hidePaymentloader();
            Swal.fire(
              "",
              this.es.posErrorMsgList[35].message,
              "error"
            );
          });
        }
      }
    });
  }
  async ClearDineInSplitItemDataPrint(response) {
    this.usedloyaltyPointsSplitByItems(response);
    var price = 0, tax = 0;
    await this.SalesOrderPayment.Concession.forEach((so, index) => {
      if (!so.Selected) {
        price += (so.Price * (so.NumberSold));
        tax += (so.Tax * (so.NumberSold));
      } else {
        if (this.SalesOrderPayment.Concession[index].ActualQuantity == this.SalesOrderPayment.Concession[index].NumberSold) {
          this.SalesOrderPayment.Concession[index].remove = true;
        } else {
          let newQuantity = this.SalesOrderPayment.Concession[index].ActualQuantity - this.SalesOrderPayment.Concession[index].NumberSold;
          price += (so.Price * (this.SalesOrderPayment.Concession[index].NumberSold));
          tax += (so.Tax * (this.SalesOrderPayment.Concession[index].NumberSold));
          this.SalesOrderPayment.Concession[index].NumberSold = newQuantity;
          this.SalesOrderPayment.Concession[index].ActualQuantity = newQuantity;
        }
      }
    });
    this.SalesOrderPayment.Concession = this.SalesOrderPayment.Concession.filter(c => !c.remove);
    this.SalesOrderPayment.TipAmount = 0;
    let kitchenArray = [];
    let count =0;
    await response.Concession.map(async (scd, index) => {
      let kitchenconcession = await this.SalesOrderPayment.saleskitchenOrders.find(rc => ((scd.ConcessionName).trim() == (rc.Food.split('\n')[0]).trim()));
      if (kitchenconcession) {
        kitchenArray.push(kitchenconcession)
        let res = await this.dineinService
          .updateKitchenStatus(kitchenconcession.KitchenId, 4, {
            PaymentMode: this.paymentMode,
            TotalAmount: response.TotalAmount,
            TipAmount: response.TipAmount,
            ShiftId: this.shiftData?.ShiftId ? this.shiftData.ShiftId : this.SalesOrderPayment.ShiftId,
          })
          .toPromise();
      }
    });
    await this.dineinService
      .updateKitchenSalesOrderIdsForSplitByItem(response.ConfirmationCode, kitchenArray)
      .subscribe(
        async (updatekitchenresponse) => {
          console.log(updatekitchenresponse);
          if (this.SalesOrderPayment.Tickets.length == 0 && this.SalesOrderPayment.Concession.length == 0 && this.SalesOrderPayment.GiftCardSale.length == 0) {
            this.isMemberVerified = false;
            this.ispartialRedeem = '';
             this.partialredeempoints = 0;
             this.PreviousSalesOrder = null;
             this.UsedLoyaltyPoints = 0;
            this.ClearDataNPrint(response, [], true);
            this.isSplitByItemMemberVerified = false;
            
             
            this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
            this.posService.SplitByItemChangeStatusFailed(this.originalSalesOrderPayment).subscribe(
              async (result) => {
              }, (err) => {
                Swal.fire(
                  "",
                  err,
                  "error"
                );
                this.printService.printModeEnabled = false;
                this.LoaderService.hidePaymentloader();
              });
          } else {
            this.isMemberVerified = false;
            this.printService.printDocument("ticket", [response.ConfirmationCode]);
            this.isMemberVerified = false;
            this.activeTipsButton = "";
            this.LoaderService.hidePaymentloader();
            this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
            this.SalesOrderPayment.TotalAmount = price;
            this.SalesOrderPayment.TotalTax = tax;
            this.disableAfterOnePayment = true;
            this.totalPrice = 0;
            this.keyboard.destroy();
          }
        }, (err) => {
          this.LoaderService.hide();
          $("#payBtnModal").removeAttr("disabled");
          Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
        });
    //create new kitchen with new salesorder id
    //cancel older kitchen id attached with older sales order

  }
  async ClearSplitByItemDataPrint(response) {
    this.usedloyaltyPointsSplitByItems(response);
    var price = 0, tax = 0;
    await this.SalesOrderPayment.Tickets.forEach((so, index) => {
      if (so.Selected) {
        // this.SalesOrderPayment.Tickets.splice(index, 1);
        this.SalesOrderPayment.Tickets[index].remove = true;
        if (so.ComboID != "") {
          let comboItem = this.SalesOrderPayment.Concession.find(concessionCombo => concessionCombo.ComboID == so.ComboID);
          if (comboItem.NumberSold == 1) {
            this.SalesOrderPayment.Concession = this.SalesOrderPayment.Concession.filter(concessionCombo => concessionCombo.ComboID != so.ComboID);
          } else {
            this.SalesOrderPayment.Concession.forEach((ci, cindex) => {
              if (ci.ComboID == so.ComboID) {
                this.SalesOrderPayment.Concession[cindex].NumberSold = this.SalesOrderPayment.Concession[cindex].NumberSold - 1;
                if (this.SalesOrderPayment.Concession[cindex].NumberSold == 0) {
                  this.SalesOrderPayment.Concession[cindex].remove = true;
                }
              }
            });
            this.SalesOrderPayment.Concession = this.SalesOrderPayment.Concession.filter(c => !c.remove);
          }
        }
      } else {
        price += so.Price;
        tax += so.Tax;
      }
    });
    this.SalesOrderPayment.Tickets = this.SalesOrderPayment.Tickets.filter(t => !t.remove);
    await this.SalesOrderPayment.Concession.forEach((so, index) => {
      if (so.ComboID == "") {
        if (!so.Selected) {
          price += (so.Price * (so.NumberSold));
          tax += (so.Tax * (so.NumberSold));
        } else {
          if (this.SalesOrderPayment.Concession[index].ActualQuantity == this.SalesOrderPayment.Concession[index].NumberSold) {
            this.SalesOrderPayment.Concession[index].remove = true;
          } else {
            let newQuantity = this.SalesOrderPayment.Concession[index].ActualQuantity - this.SalesOrderPayment.Concession[index].NumberSold;
            price += (so.Price * (this.SalesOrderPayment.Concession[index].NumberSold));
            tax += (so.Tax * (this.SalesOrderPayment.Concession[index].NumberSold));
            this.SalesOrderPayment.Concession[index].NumberSold = newQuantity;
            this.SalesOrderPayment.Concession[index].ActualQuantity = newQuantity;
          }
        }
      }

    });
    this.SalesOrderPayment.Concession = this.SalesOrderPayment.Concession.filter(c => !c.remove);
    await this.SalesOrderPayment.AdditionalCharges.forEach((so, index) => {
      if (!so.Selected) {
        price += (so.AdditionalPrice * (so.NumberOfTicketSold));
        tax += (so.Tax * (so.NumberOfTicketSold));
      } else {
        let cloneObjectTickets = CloneObject(this.SalesOrderPayment.Tickets)
        let NumberOfSold = cloneObjectTickets.filter(td => (td.Selected && td.additionalChargeUniqueId == so.additionalChargeUniqueId)).length;        //   // price += (so.AdditionalPrice * (so.NumberOfTicketSold));

        if (this.SalesOrderPayment.AdditionalCharges[index].ActualQuantity == NumberOfSold) {
          this.SalesOrderPayment.AdditionalCharges[index].remove = true;
        } else {
          let newQuantity = this.SalesOrderPayment.AdditionalCharges[index].ActualQuantity - NumberOfSold;
          price += (so.Price * (NumberOfSold));
          tax += (so.Tax * (NumberOfSold));
          this.SalesOrderPayment.AdditionalCharges[index].NumberOfTicketSold = newQuantity;
          this.SalesOrderPayment.AdditionalCharges[index].ActualQuantity = newQuantity;
        }
      }
    });
    this.SalesOrderPayment.AdditionalCharges = this.SalesOrderPayment.AdditionalCharges.filter(ap => !ap.remove);
    await this.SalesOrderPayment.GiftCardSale.forEach((so, index) => {
      if (!so.Selected) {
        price += so.Amount;
      } else {
        this.SalesOrderPayment.GiftCardSale[index].remove = true;
      }
    });
    this.SalesOrderPayment.GiftCardSale.forEach(g => {
      if (g.remove) {
        this.giftCardService.GiftCardToCart(g, "minus");
      }
    });
    this.SalesOrderPayment.GiftCardSale = this.SalesOrderPayment.GiftCardSale.filter(gc => !gc.remove);
    this.SalesOrderPayment.TotalAmount = price;
    this.SalesOrderPayment.TotalTax = tax;
    this.disableAfterOnePayment = true;
    this.LoaderService.hidePaymentloader();
    this.totalPrice = 0;
    this.printService.printDocument("ticket", [response.ConfirmationCode]);
    this.isMemberVerified = false;
    this.keyboard.destroy();
    // this.SalesOrderPayment.AdditionalCharges.length == 0 &&
    if (this.SalesOrderPayment.Tickets.length == 0 && this.SalesOrderPayment.Concession.length == 0 && this.SalesOrderPayment.GiftCardSale.length == 0) {
      this.ClearDataNPrint(this.SalesOrderPayment, this.SalesOrderPayment.holdIds, false);
      if (!this.SalesOrderPayment.isDineInOrder) {
        this.posService.SplitByItemChangeStatusFailed(this.originalSalesOrderPayment).subscribe(
          async (result) => {
            console.log("Cancel order for split payment", result)
          },err=>{
            console.log("error",err);
        });
      }
    }
  }
  async closeModalAndClearCart() {
    if (this.SalesOrderPayment.isDineInOrder) {
      Swal.fire({
        title: 'Sorry',
        text: "You Can't exit the proccess in middle of transaction",
        showCancelButton: false,
        type: "warning",
      });
    } else {
      // if(this.SalesOrderPayment.AdditionalCharges.length > 0){
      //   Swal.fire({
      //     title: 'Sorry',
      //     text: "Additional Charges is mendatory to be paid you can't exit the proccess",
      //     showCancelButton: false,
      //     type: "warning",
      //   });
      // }else{
      await this.ClearDataNPrint(this.SalesOrderPayment, this.SalesOrderPayment.holdIds, false);
      this.printService.printModeEnabled = false;
      // }

    }

  }
  postSplitSalesOrser() {
    let clonefi_2 = CloneObject(this.SalesOrderPayment.Concession)
    this.kioskPOSService.checkConcessionInventory(clonefi_2).subscribe(async(res: any) => {
      if (res.length > 0) {
        let items = res.join(", ");
        console.log("items", items)
        let scentence = [];
        for (var i = 0; i <= (res.length - 1); i++) {
          scentence.push(res[i])
        }
        let fullScentence = '';
        scentence.forEach((f, i) => {
          f = f.split('_')
          fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
        });
        this.LoaderService.hidePaymentloader();
        Swal.fire({
          title: this.es.posErrorMsgList[36].message,
          text: this.es.posErrorMsgList[37].message + fullScentence + this.es.posErrorMsgList[38].message,
          showCancelButton: false,
          type: "warning",
        });
        $("#payMethodModel").modal("hide");
        this.printService.printModeEnabled = false;
        this.router.navigate(["/POS"]);
      } else {
        if (this.SalesOrderPayment.Tickets.length > 0 || this.SalesOrderPayment.Concession.length > 0 || this.SalesOrderPayment.GiftCardSale.length > 0) {
          if (!this.isMemberVerified) {
            this.SalesOrderPayment.OrderLoyaltyCredit = 0;
            await this.SalesOrderPayment.Concession.forEach((ci, index) => {
              ci.ItemLoyaltyCredit = 0;
            });
            await this.SalesOrderPayment.Tickets.forEach((ti, index) => {
              ti.ItemLoyaltyCredit = 0;
            });
          } else {
            this.SalesOrderPayment.OrderLoyaltyCredit = this.loyaltyPoints;
            if (this.UsedLoyaltyPoints > 0) {
              this.SalesOrderPayment.OrderLoyaltyCredit = 0;
              await this.SalesOrderPayment.Concession.forEach((ci, index) => {
                ci.ItemLoyaltyCredit = 0;
              });
              await this.SalesOrderPayment.Tickets.forEach((ti, index) => {
                ti.ItemLoyaltyCredit = 0;
              });
            }
          }
          this.posService.postSalesOrder(this.SalesOrderPayment).subscribe(async (response) => {
            this.SalesOrderPayment.ConfirmationCode = response.ConfirmationCode;
            this.SalesOrderPayment.Concession = response.Concession;
            this.SalesOrderPayment.Tickets = response.Tickets;
            this.SalesOrderPayment.GiftCardSale = response.GiftCardSale;
            this.SalesOrderPayment.DateTimeSold = response.DateTimeSold;
            this.SalesOrderPayment.ActualAmount = response.ActualAmount;
            this.SalesOrderPayment.ActualTaxActualTax = response.ActualAmount;
            this.SalesOrderPayment.TotalAmount = response.TotalAmount;
            if (response && response.SalesSplit) {
              await this.SalesOrderPayment.SalesSplit.push(this.splitArray[this.selectedSplitTobePaidIndex])

              await this.patchSalesOrder(this.SalesOrderPayment);
            }
          }, (err) => {
            Swal.fire(
              "",
              err,
              "error"
            );
            this.printService.printModeEnabled = false;
            this.LoaderService.hidePaymentloader();
            this.clearSplitVariables();
          });
        }
      }
    });
  }

  async voidThisOrder() {
    if (this.selectedMerchant == "Worldpay") {
      this.posService.VoidPartialSalesOrder(this.SalesOrderPayment).subscribe(
        async (response) => {
          $("#payMethodModel").modal("hide");
          this.printService.printModeEnabled = false;
          this.clearSplitVariables();
          if (this.SalesOrderPayment.isDineInOrder) {
            this.SalesOrderPayment.saleskitchenOrders.forEach(d => {
              this.dineinService.updateKitchenStatus(d.KitchenId, 5, {}).subscribe(
                (data) => {
                  this.paymentOptionService.kitchenSuccedObservable.next(['Success']);
                  this.clearSplitVariables();
                });
            });
          }
        }, (err) => {
          Swal.fire(
            "",
            this.es.posErrorMsgList[35].message,
            "error"
          );
          this.LoaderService.hidePaymentloader();
          this.printService.printModeEnabled = false;
        }
      );
    }
  }

  updateAmountTobeSetteled() {
    this.AmountToBeSatteled = this.originalSalesOrderPayment.TotalAmount;
    this.splitArray.forEach(obj => {
      if (obj.Status == SalesStatus.Reserved) {
        this.AmountToBeSatteled = this.AmountToBeSatteled - obj.TotalAmount;
      }
    });
    if (this.AmountToBeSatteled < this.SalesOrderPayment.TotalAmount) {
      this.disableAfterOnePayment = true;
    }
  }
  PaySplitByItem(dataObj, index) {
    if (this.SalesOrderPayment.GiftCardSale.length > 0 && this.splitPaymentMode == 3 && this.SalesOrderPayment.GiftCardSale.filter(gs => gs.Selected).length > 0) {
      Swal.fire({
        title: 'Warning',
        text: 'You can not pay giftcard using another giftcard',
        showCancelButton: false,
        type: "warning",
      });
      this.splitPaymentMode = 1;
      return;
    }
    this.UpdateSplitItemPrice();
    if (!this.SalesOrderPayment.isDineInOrder) {
      if (this.splitPaymentMode == 2 && this.selectedMerchant == 'Worldpay') {
        this.getTransactionSetupid(true)
      }
      if (this.splitPaymentMode == 0 && this.selectedMerchant == 'Worldpay') {
        this.sendSwipeIndication()
      }
    } else {
      var selectedconcession = CloneObject(this.SalesOrderPayment.Concession);
      selectedconcession = selectedconcession.filter(c => c.Selected);
      selectedconcession.forEach((c) => {
        c.Price -= c.Tax
      })
      this.SalesOrderPayment.concessionPaymentItemCopy = CloneObject(selectedconcession);

      // this.getCashChangePosibilities();
      $("#kitchenPaymentModal").modal("show");
      this.dineInOrderType = PaymentMode.Split;
    }
  }
  updateItemPaymentMode(type, event) {
    if (this.SalesOrderPayment.GiftCardSale.length > 0 && this.splitPaymentMode == 3 && this.SalesOrderPayment.GiftCardSale.filter(gs => gs.Selected).length > 0) {
      Swal.fire({
        title: 'Warning',
        text: 'You can not pay giftcard using another giftcard',
        showCancelButton: false,
        type: "warning",
      });
      this.splitPaymentMode = 1;
      return;
    }
    this.splitPaymentMode = Number(event.target.value);
    if (this.splitPaymentMode == 3) {
      $("#payBtnModal").removeAttr("disabled");
    }
    this.SalesOrderPayment.PaymentMode = PaymentMode.Split;
    this.getCashChangePosibilities();
  }


  verifyMemberdetail() {
    console.log("loyaltysearchby", this.loyaltysearchby);
    this.posService.checkLoyaltyMember(this.loyaltysearchby.toLowerCase(), this.Location.Company_ID).subscribe(async result => {
      if (result) {
        this.AddChargeLoyalty = 0;
        this.isMemberVerified = true;
        this.isSplitByItemMemberVerified = true;
        this.EarnRedeemPoints = result.MemberRewards;
        var Points = result.MemberRewards;
        if (this.SalesOrderPayment.TipAmount && this.SalesOrderPayment.TipAmount > 0) {
          this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.TotalAmount - this.SalesOrderPayment.TipAmount;
          this.SalesOrderPayment.TipAmount = 0;
          this.totalPrice = this.SalesOrderPayment.TotalAmount;
        }
        this.total = this.SalesOrderPayment.TotalAmount;
        var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
        if (locationDetail && locationDetail.Company_ID) {
          await this.locationService.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((company) => {
            if (company) {
              this.CompanyDetails = company;
              if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
                this.loyaltyAmount = Points / this.CompanyDetails.LoyaltyPointsPerCurrency;
              }
              else {
                this.loyaltyAmount = 0;
              }
            }
          });
        } else {
          Swal.fire('OOPS! Session expired.', ' Please select location  again.')
          this.router.navigate(['/locations']);
        }
          if (this.SalesOrderPayment.AdditionalCharges && this.SalesOrderPayment.AdditionalCharges.length > 0) {
          this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
            this.AddChargeLoyalty += so.AdditionalPrice * so.NumberOfTicketSold;;
          })

        }
        console.log("this.AddChargeLoyalty", this.AddChargeLoyalty);
        this.memberdata = JSON.stringify(result);
        sessionStorage.setItem("MemberDetail", this.memberdata);
        this.loyaltysearchby = null;
        this.ispartialRedeem = '';
        this.partialredeempoints = 0;
      } else {
        Swal.fire({
          title: "No records found.",
          text: "Please check the information you provided for verification and try again",
          showCancelButton: false,
          type: "warning",
        });
      }
    }, error => {
      Swal.fire({
        title: "No records found.",
        text: "Please check the information you provided for verification and try again",
        showCancelButton: false,
        type: "warning",
      });
    });
  }
  async changeRedeemMode() {
    var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
    if (locationDetail && locationDetail.Company_ID) {
      await this.locationService.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((company) => {
        if (company) {
          if (this.PreviousSalesOrder == null) {
            this.PreviousSalesOrder = this.SalesOrderPayment;

          }
          this.CompanyDetails = company;
          var member = JSON.parse(sessionStorage.getItem("MemberDetail"));
          this.EarnRedeemPoints = member.MemberRewards;
          if (this.ispartialRedeem == 'none') {
            if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
              this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
            } else {
              this.loyaltyAmount = 0;
            }
            this.total = this.PreviousSalesOrder.TotalAmount;
            this.totalPrice = this.PreviousSalesOrder.TotalAmount;
          }
          else if (this.ispartialRedeem == 'fullredeem') {
            if (this.PreviousSalesOrder) {
              this.SalesOrderPayment = CloneObject(this.PreviousSalesOrder);
              this.total = this.SalesOrderPayment.TotalAmount;
              this.totalPrice = this.SalesOrderPayment.TotalAmount;
            }
            if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
              var redeemAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
              this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
            }
            else {
              var redeemAmount = 0;
              this.loyaltyAmount = 0;
            }
            // if (this.totalPrice < redeemAmount) {
            //   this.loyaltyAmount = redeemAmount - this.totalPrice;
            //   this.total = 0;
            //   this.EarnRedeemPoints = this.loyaltyAmount * this.CompanyDetails.LoyaltyPointsPerCurrency;
            // }
            // else {
            //   this.total = this.totalPrice - redeemAmount;
            //   this.loyaltyAmount = 0;
            //   this.EarnRedeemPoints = 0;
            // }
          }
          else if (this.ispartialRedeem == 'partialredeem') {
            if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
              this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
            }
            else {
              this.loyaltyAmount = 0;
            }
            this.total = this.PreviousSalesOrder.TotalAmount;
          }
        }

      });
    } else {
      Swal.fire('OOPS! Session expired.', ' Please select location  again.')
      this.router.navigate(['/locations']);
    }
  }
  async RedeemLoyaltyPoints() {
    console.log("loyaltyadded");
    console.log("RedeemDetails");
    var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
    if (locationDetail && locationDetail.Company_ID) {
      await this.locationService.getCompanyByCompanyId(locationDetail.Company_ID).subscribe((company) => {
        if (company) {
          this.CompanyDetails = company;
          if (this.PreviousSalesOrder == null) {
            this.PreviousSalesOrder = this.SalesOrderPayment;

          }
          // let allovertotalprice=0;
          var loaltyamount = 0;
          if (this.CompanyDetails) {

            var member = JSON.parse(sessionStorage.getItem("MemberDetail"));
            if (member) {

              this.actualAmount = this.PreviousSalesOrder.TotalAmount;
              this.actualTax = this.PreviousSalesOrder.TotalTax;
              if (this.ispartialRedeem == 'fullredeem') {
                var GiftCardSaleTotalAmount = 0;
                var AdditionalChargeTotalAmount = 0;
                if (this.PreviousSalesOrder) {
                  this.SalesOrderPayment = CloneObject(this.PreviousSalesOrder);
                  this.totalPrice = this.PreviousSalesOrder.TotalAmount;
                  this.total = this.PreviousSalesOrder.TotalAmount;
                }
                if (this.SalesOrderPayment.GiftCardSale && this.SalesOrderPayment.GiftCardSale.length > 0) {
                  this.SalesOrderPayment.GiftCardSale.forEach((x) => {
                    GiftCardSaleTotalAmount = x.Amount
                  })
                  this.totalPrice -= GiftCardSaleTotalAmount
                  this.total -= GiftCardSaleTotalAmount
                }
                if (this.SalesOrderPayment.AdditionalCharges && this.SalesOrderPayment.AdditionalCharges.length > 0) {
                  this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
                    AdditionalChargeTotalAmount += so.AdditionalPrice * so.NumberOfTicketSold;;
                  })
                  this.totalPrice -= AdditionalChargeTotalAmount
                  this.total -= AdditionalChargeTotalAmount
                }
                if (member.MemberRewards >= this.CompanyDetails.LoyaltyPointsPerCurrency && this.CompanyDetails.LoyaltyPointsPerCurrency > 0) {
                  this.UsedLoyaltyPoints = member.MemberRewards;
                  loaltyamount = member.MemberRewards / this.CompanyDetails.LoyaltyPointsPerCurrency;
                  if (loaltyamount >= this.totalPrice) {
                    var extraprice = RoundAmount(this.totalPrice - loaltyamount);
                    var extrapoints = RoundAmount(extraprice * this.CompanyDetails.LoyaltyPointsPerCurrency);
                    this.UsedLoyaltyPoints = this.UsedLoyaltyPoints + extrapoints;
                    loaltyamount = this.UsedLoyaltyPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;

                  }
                  this.totalPrice = loaltyamount > 0 ? RoundAmount(this.totalPrice - loaltyamount) : this.totalPrice;
                  this.total = loaltyamount > 0 ? RoundAmount(this.total - loaltyamount) : this.totalPrice;
                  this.EarnRedeemPoints = member.MemberRewards - this.UsedLoyaltyPoints;
                  if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
                    this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
                  }
                  else {
                    this.loyaltyAmount = 0;
                  }
                  if (this.totalPrice == 0) {
                    this.hasLoyaltyModeOn = false;
                  }
                  if (AdditionalChargeTotalAmount > 0 && GiftCardSaleTotalAmount == 0) {
                    this.totalPrice += AdditionalChargeTotalAmount
                    this.total += AdditionalChargeTotalAmount
                    RoundAmount(this.totalPrice)
                    RoundAmount(this.total)
                  }
                  else if (GiftCardSaleTotalAmount > 0 && AdditionalChargeTotalAmount == 0) {
                    this.totalPrice += GiftCardSaleTotalAmount
                    this.total += GiftCardSaleTotalAmount
                    RoundAmount(this.totalPrice)
                    RoundAmount(this.total)
                  }
                  else if (GiftCardSaleTotalAmount > 0 && AdditionalChargeTotalAmount > 0) {
                    this.totalPrice += GiftCardSaleTotalAmount
                    this.total += GiftCardSaleTotalAmount
                    this.totalPrice += AdditionalChargeTotalAmount
                    this.total += AdditionalChargeTotalAmount
                    RoundAmount(this.totalPrice)
                    RoundAmount(this.total)
                  }

                }
                else {
                  Swal.fire("", "You do not have enough points. By completing the transaction, you will receive points.", "warning");
                  return;
                }
              }
              if (this.ispartialRedeem == 'partialredeem') {
                var GiftCardSaleTotalAmount = 0;
                var AdditionalChargeTotalAmount = 0;
                if (this.PreviousSalesOrder) {
                  this.SalesOrderPayment = CloneObject(this.PreviousSalesOrder);
                  this.totalPrice = this.PreviousSalesOrder.TotalAmount;
                  this.total = this.PreviousSalesOrder.TotalAmount;
                }

                if (this.SalesOrderPayment.GiftCardSale && this.SalesOrderPayment.GiftCardSale.length > 0) {
                  this.SalesOrderPayment.GiftCardSale.forEach((x) => {
                    GiftCardSaleTotalAmount = x.Amount
                  })
                  this.totalPrice -= GiftCardSaleTotalAmount
                  this.total -= GiftCardSaleTotalAmount
                }
                if (this.SalesOrderPayment.AdditionalCharges && this.SalesOrderPayment.AdditionalCharges.length > 0) {
                  this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
                    AdditionalChargeTotalAmount += so.AdditionalPrice * so.NumberOfTicketSold;
                  })
                  this.totalPrice -= AdditionalChargeTotalAmount
                  this.total -= AdditionalChargeTotalAmount
                }
                if (Number(this.partialredeempoints) >= this.CompanyDetails.LoyaltyPointsPerCurrency) {
                  if (member.MemberRewards >= Number(this.partialredeempoints)) {
                    this.UsedLoyaltyPoints = Number(this.partialredeempoints);
                    loaltyamount = Number(this.partialredeempoints) / this.CompanyDetails.LoyaltyPointsPerCurrency;
                    if (loaltyamount >= this.totalPrice) {
                      var extraprice = RoundAmount(this.totalPrice - loaltyamount);
                      var extrapoints = RoundAmount(extraprice * this.CompanyDetails.LoyaltyPointsPerCurrency);
                      this.UsedLoyaltyPoints = this.UsedLoyaltyPoints + extrapoints;
                      loaltyamount = this.UsedLoyaltyPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
                    }



                    this.totalPrice = loaltyamount > 0 ? RoundAmount(this.totalPrice - loaltyamount) : this.totalPrice;
                    this.total = loaltyamount > 0 ? RoundAmount(this.total - loaltyamount) : this.totalPrice;
                    this.EarnRedeemPoints = member.MemberRewards - this.partialredeempoints;
                    if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
                      this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
                    } else {
                      this.loyaltyAmount = 0;
                    }

                    if (this.totalPrice == 0) {
                      this.hasLoyaltyModeOn = false;
                    }

                    if (AdditionalChargeTotalAmount > 0) {
                      this.totalPrice += AdditionalChargeTotalAmount
                      this.total += AdditionalChargeTotalAmount
                    }
                    if (GiftCardSaleTotalAmount > 0) {
                      this.totalPrice += GiftCardSaleTotalAmount
                      this.total += GiftCardSaleTotalAmount
                    }

                  }
                  else {
                    if (member.MemberRewards < this.CompanyDetails.LoyaltyPointsPerCurrency) {
                      Swal.fire("", "You do not have enough points. By completing the transaction, you will receive points.", "warning");
                    } else {
                      Swal.fire("", "You can use " + member.MemberRewards + " points or below than " + member.MemberRewards, "warning");
                    }
                    return;

                  }
                }
                else {
                  Swal.fire("", "You need to use the minimum points " + this.CompanyDetails.LoyaltyPointsPerCurrency + " for redemption.", "warning");
                  return;
                }

              }

              if (this.ispartialRedeem == 'none') {
                if (this.PreviousSalesOrder) {
                  this.SalesOrderPayment = CloneObject(this.PreviousSalesOrder);
                  this.totalPrice = this.PreviousSalesOrder.TotalAmount;
                  this.total = this.PreviousSalesOrder.TotalAmount;
                  this.UsedLoyaltyPoints = 0
                }

                // this.EarnRedeemPoints = member.MemberRewards;
                // if (this.CompanyDetails.LoyaltyPointsPerCurrency != 0) {
                //   this.loyaltyAmount = this.EarnRedeemPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
                // }
                // else {
                //   this.loyaltyAmount = 0;
                // }             





              }

            }


          }
          if (this.UsedLoyaltyPoints > 0) { // if loyalty points is used then it will add
            this.commonService.GetDateTimeByTimeZone().subscribe((tz) => {
              /// this.SalesOrderPayment.TotalAmount = this.totalPrice;
              //this.originalSalesOrderPayment.TotalAmount = this.totalPrice;


              let loaltyamount = this.UsedLoyaltyPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
              var price = 0;
              var discountPercentage = Number(loaltyamount);
              var GiftCardSaleTotalAmount = 0;
              var AdditionalChargeTotalAmount = 0;
              var additionachargeTax = 0;
              var amount = 0;


              amount = this.SalesOrderPayment.TotalAmount;
              if (this.SalesOrderPayment.GiftCardSale && this.SalesOrderPayment.GiftCardSale.length > 0) {
                this.SalesOrderPayment.GiftCardSale.forEach((x) => {
                  GiftCardSaleTotalAmount = x.Amount;
                })
                amount -= GiftCardSaleTotalAmount
              }
              if (this.SalesOrderPayment.AdditionalCharges && this.SalesOrderPayment.AdditionalCharges.length > 0) {

                this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
                  AdditionalChargeTotalAmount += (so.AdditionalPrice * so.NumberOfTicketSold);
                  additionachargeTax += (so.Tax * so.NumberOfTicketSold);
                })
                amount -= AdditionalChargeTotalAmount;
              }
              discountPercentage = GetPercentageByAmount(Number(loaltyamount), amount);
              if (this.SalesOrderPayment.ActualAmount) {
                let discount = this.SalesOrderPayment.ActualAmount - loaltyamount;
                if (this.SalesOrderPayment.TotalAmount != discount) {
                  let otherDiscount = this.SalesOrderPayment.ActualAmount - loaltyamount - this.SalesOrderPayment.TotalAmount;
                  discountPercentage += GetPercentageByAmount(Number(otherDiscount), amount);
                }
              }
              if (!this.SalesOrderPayment.IsDiscountedOrder) {
                this.SalesOrderPayment.ActualAmount = this.SalesOrderPayment.TotalAmount;
                this.SalesOrderPayment.ActualTax = this.SalesOrderPayment.TotalTax;
              }

              // // Update amount as per discount percentage
              var TotalAmountWithoutGCAmount = 0;
              TotalAmountWithoutGCAmount = PercentageAmountCalculation(discountPercentage, amount);
              // if (GiftCardSaleTotalAmount > 0 && AdditionalChargeTotalAmount == 0) {
              //   this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = TotalAmountWithoutGCAmount + GiftCardSaleTotalAmount;
              //   this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
              // }
              // else if (GiftCardSaleTotalAmount == 0 && AdditionalChargeTotalAmount > 0) {
              //   this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = TotalAmountWithoutGCAmount + AdditionalChargeTotalAmount;
              //   this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
              // }
              // else if (GiftCardSaleTotalAmount > 0 && AdditionalChargeTotalAmount > 0) {
              //   this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = TotalAmountWithoutGCAmount + AdditionalChargeTotalAmount + GiftCardSaleTotalAmount;
              //   this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
              // }
              // else {
              //   this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalAmount);
              //   this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
              // }
              if (GiftCardSaleTotalAmount > 0 || AdditionalChargeTotalAmount > 0) {
                this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, amount);
                this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);

              }
              else {
                this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalAmount);
                this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);
              }
              this.SalesOrderPayment.Tickets?.forEach((so) => {
                if (!this.SalesOrderPayment.IsDiscountedOrder) {
                  so.ActualAmount = so.Price;
                  so.ActualTax = so.Tax;
                }

                //   // Update amount as per discount percentage
                so.Price = PercentageAmountCalculation(discountPercentage, so.Price);
                so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
                // so.Price = roundHalf(so.Price)
                // so.Tax = roundHalf(so.Tax)
                so.IsDiscountedOrder = true;

                if (this.SalesOrderPayment.isDineInOrder)
                  price += PercentageAmountCalculation(discountPercentage, so.ActualAmount + so.ActualTax);
                else
                  price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price)));
              });
              this.SalesOrderPayment.Concession?.forEach((so) => {
                if (!this.SalesOrderPayment.IsDiscountedOrder) {
                  so.ActualAmount = so.Price;
                  so.ActualTax = so.Tax;
                }

                //   // Update amount as per discount percentage
                so.Price = PercentageAmountCalculation(discountPercentage, so.Price);
                // so.Price = RoundAmount(roundHalf(so.Price));
                so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
                //so.Tax = roundHalf(so.Tax);

                //if (this.SalesOrderPayment.isDineInOrder) {
                //  so.Tax = PercentageAmountCalculation(discountPercentage, so.ActualTax);
                //  so.Tax = roundHalf(so.Tax);
                //  so.Price -= so.Tax;
                //}
                //else {
                //  so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
                //  so.Tax = roundHalf(so.Tax);
                //}
                so.IsDiscountedOrder = true;

                if (this.SalesOrderPayment.isDineInOrder)
                  price += RoundAmount((so.Price) * (so.NumberSold));
                else
                  price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold)));
              });
              // // Update this for Dine-In tip amount functionality
              if (this.SalesOrderPayment.isDineInOrder)
                this.SalesOrderPayment.concessionPaymentItem = this.SalesOrderPayment.Concession;

              //this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
              // if (!this.SalesOrderPayment.IsDiscountedOrder) {
              //   so.ActualAmount = so.AdditionalPrice;
              //   so.ActualTax = so.Tax;
              //  }

              // Update amount as per discount percentage
              ///  so.AdditionalPrice = PercentageAmountCalculation(discountPercentage, so.AdditionalPrice);
              // so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
              //so.AdditionalPrice = roundHalf(so.AdditionalPrice);
              // so.Tax = roundHalf(so.Tax);
              //so.IsDiscountedOrder = true;

              //  if (this.SalesOrderPayment.isDineInOrder)
              //   price += (PercentageAmountCalculation(discountPercentage, so.ActualAmount + so.ActualTax) * (so.NumberOfTicketSold));
              //   else
              //   price += RoundAmount(((so.AdditionalPrice) * (so.NumberOfTicketSold)));
              //});

              //this.SalesOrderPayment.GiftCardSale?.forEach((so) => {
              //if (!this.SalesOrderPayment.IsDiscountedOrder) {
              ///   so.ActualAmount = so.Amount;
              //  }

              //  // Update amount as per discount percentage
              //  so.Amount = PercentageAmountCalculation(discountPercentage, so.ActualAmount);
              //   so.IsDiscountedOrder = true;
              // price += RoundAmount(so.Amount);
              //  });
              this.settlement();

              var Steeledprice = 0;
              var SettledTax = 0;
              this.SalesOrderPayment.Tickets?.forEach((so) => {
                // Steeledprice += ((so.TtlPrice ? so.TtlPrice : so.Price));
                SettledTax += (so.Tax);
                // if (so.additionalChargeUniqueId != null) {
                //   const additionalcharge = this.SalesOrderPayment.AdditionalCharges.filter(l => l.additionalChargeUniqueId == so.additionalChargeUniqueId);
                //   if (additionalcharge.length > 0) {
                //     additionalcharge.forEach((so) => {
                //       price += so.AdditionalPrice;
                //     });
                //   }

                // }
                //if (so.ComboID != "") {
                //  let comboItem = this.SalesOrderPayment.Concession.find(concessionCombo => concessionCombo.ComboID == so.ComboID);
                //  if (comboItem != null) {
                //    // Steeledprice += comboItem.Price;
                //    SettledTax += (so.Tax);
                //  }
                //}
              });
              this.SalesOrderPayment.Concession?.forEach((so) => {
                // if (!so.ComboID) {
                // Steeledprice += ((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold));
                SettledTax += (so.Tax);
                // }

              });

              this.SalesOrderPayment.IsDiscountedOrder = true;
              this.SalesOrderPayment.DiscountedAt = (this.SalesOrderPayment.DateTimeSold && this.SalesOrderPayment.DateTimeSold != "") ? moment(tz).format("YYYY-MM-DDTHH:mm:00.000[Z]") : this.SalesOrderPayment.DateTimeSold;
              var discounttype = DiscountType.LoyaltyPoint;
              this.SalesOrderPayment.ActualAmount = this.actualAmount;
              this.SalesOrderPayment.ActualTax = this.actualTax;
              this.SalesOrderPayment.TotalAmount = this.totalPrice;
              this.SalesOrderPayment.TotalTax = RoundAmount(SettledTax + additionachargeTax);
              var discountypelist = this.SalesOrderPayment.DiscountType.filter(l => l == DiscountType.LoyaltyPoint);
              // if(!discountypelist)
              this.SalesOrderPayment.DiscountType.push(discounttype);
              this.isSplitByItemOpen = false;  //for updating split by item functionality

              // Need to update this for Split Payment
              this.originalSalesOrderPayment = CloneObject(this.SalesOrderPayment);

              // this.totalPrice = RoundAmount(price);
              //Manage 0.01 amount for discount
              //  this.CheckAndUpdateAmountForDiscount();







            });


          }
          this.toastrService.success("Redemption of points done successfully", "Success");
        }
      });

    } else {
      Swal.fire('OOPS! Session expired.', ' Please select location  again.')
      this.router.navigate(['/locations']);
    }
  }
  updatesalesorderwithDiscount() {
    if (this.UsedLoyaltyPoints) {

      let loaltyamount = this.UsedLoyaltyPoints / this.CompanyDetails.LoyaltyPointsPerCurrency;
      var price = 0;
      var discountPercentage = Number(loaltyamount);

      var amount = 0;


      amount = this.SalesOrderPayment.ActualAmount;
      // if (this.SalesOrderPayment.isDineInOrder)
      //   amount += this.SalesOrderPayment.TotalTax;
      // }
      // else {
      //   amount = this.SalesOrderPayment.ActualAmount;
      //   // if (this.SalesOrderPayment.isDineInOrder)
      //   //   amount += this.SalesOrderPayment.ActualTax;
      // }

      discountPercentage = GetPercentageByAmount(Number(loaltyamount), amount);
      let discount = this.SalesOrderPayment.ActualAmount - loaltyamount;
      if (this.SalesOrderPayment.TotalAmount != discount) {
        let otherDiscount = this.SalesOrderPayment.ActualAmount - loaltyamount - this.SalesOrderPayment.TotalAmount;
        discountPercentage += GetPercentageByAmount(Number(otherDiscount), amount);
      }


      // if (!this.SalesOrderPayment.IsDiscountedOrder) {
      //   this.SalesOrderPayment.ActualAmount = this.SalesOrderPayment.TotalAmount;
      //   this.SalesOrderPayment.ActualTax = this.SalesOrderPayment.TotalTax;
      // }

      // Update amount as per discount percentage
      this.SalesOrderPayment.TotalAmount = this.SalesOrderPayment.Price = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalAmount);
      this.SalesOrderPayment.TotalTax = this.SalesOrderPayment.Tax = PercentageAmountCalculation(discountPercentage, this.SalesOrderPayment.TotalTax);

      this.SalesOrderPayment.Tickets?.forEach((so) => {
        // if (!this.SalesOrderPayment.IsDiscountedOrder) {
        so.ActualAmount = so.Price;
        so.ActualTax = so.Tax;
        // }

        // Update amount as per discount percentage
        so.Price = PercentageAmountCalculation(discountPercentage, so.Price);
        so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
        //so.Price = roundHalf(so.Price);
        //so.Tax = roundHalf(so.Tax);
        so.IsDiscountedOrder = true;

        if (this.SalesOrderPayment.isDineInOrder)
          price += PercentageAmountCalculation(discountPercentage, so.ActualAmount + so.ActualTax);
        else
          price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price)));
      });
      this.SalesOrderPayment.Concession?.forEach((so) => {
        //if (!this.SalesOrderPayment.IsDiscountedOrder) {
        so.ActualAmount = so.Price;
        so.ActualTax = so.Tax;
        // }

        // Update amount as per discount percentage
        so.Price = PercentageAmountCalculation(discountPercentage, so.Price);

        //so.Price = roundHalf(so.Price);
        //if (this.SalesOrderPayment.isDineInOrder) {
        //  so.Tax = PercentageAmountCalculation(discountPercentage, so.ActualTax);
        //  so.Price -= so.Tax;
        //}
        //else {
        so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
       // so.Tax = roundHalf(so.Tax);
        //}
        so.IsDiscountedOrder = true;

        if (this.SalesOrderPayment.isDineInOrder)
          price += (so.Price) * (so.NumberSold);
        else
          price += RoundAmount(((so.TtlPrice ? so.TtlPrice : so.Price) * (so.NumberSold)));
      });
      // Update this for Dine-In tip amount functionality
      if (this.SalesOrderPayment.isDineInOrder)
        this.SalesOrderPayment.concessionPaymentItem = this.SalesOrderPayment.Concession;

      this.SalesOrderPayment.AdditionalCharges?.forEach((so) => {
        // if (!this.SalesOrderPayment.IsDiscountedOrder) {
        so.ActualAmount = so.AdditionalPrice;
        so.ActualTax = so.Tax;
        // }

        // Update amount as per discount percentage
        so.AdditionalPrice = PercentageAmountCalculation(discountPercentage, so.AdditionalPrice);
        so.Tax = PercentageAmountCalculation(discountPercentage, so.Tax);
        //so.Price = roundHalf(so.AdditionalPrice)
        //so.Tax = roundHalf(so.Tax);
        so.IsDiscountedOrder = true;

        if (this.SalesOrderPayment.isDineInOrder)
          price += (PercentageAmountCalculation(discountPercentage, so.ActualAmount + so.ActualTax) * (so.NumberOfTicketSold));
        else
          price += RoundAmount(((so.AdditionalPrice) * (so.NumberOfTicketSold)));
      });

      this.SalesOrderPayment.GiftCardSale?.forEach((so) => {
        // if (!this.SalesOrderPayment.IsDiscountedOrder) {
        so.ActualAmount = so.Amount;
        // }

        // Update amount as per discount percentage
        so.Amount = PercentageAmountCalculation(discountPercentage, so.ActualAmount);
        //so.Amount = roundHalf(so.Amount);
        so.IsDiscountedOrder = true;
        price += RoundAmount(so.Amount);
      });
      this.settlement();
    }

  }
  settlement() {
    RoundAmount(this.SalesOrderPayment.TotalAmount)
    var price = 0;
    this.SalesOrderPayment.Tickets?.forEach((so) => {
      price += ((so.TtlPrice ? so.TtlPrice : so.Price));
      // if (so.additionalChargeUniqueId != null) {
      //   const additionalcharge = this.SalesOrderPayment.AdditionalCharges.filter(l => l.additionalChargeUniqueId == so.additionalChargeUniqueId);
      //   if (additionalcharge.length > 0) {
      //     additionalcharge.forEach((so) => {
      //       price += so.AdditionalPrice;
      //     });
      //   }

      // }
     // if (so.ComboID != "") {
      //  let comboItem = this.SalesOrderPayment.Concession.find(concessionCombo => concessionCombo.ComboID == so.ComboID);
      //  if (comboItem != null) {
      //    price += comboItem.Price;
      //  }
     // }
    });
    this.SalesOrderPayment.Concession?.forEach((so) => {
     // if (!so.ComboID)
      if (this.SalesOrderPayment.isDineInOrder) {
        price += ((so.TtlPrice ? so.TtlPrice : (so.Price)) * (so.NumberSold));

      } else {
        price += ((so.TtlPrice ? so.TtlPrice : (so.Price + so.Tax)) * (so.NumberSold));
      }
        
    });
    // this.SalesOrderPayment.GiftCardSale?.forEach((so) => {
    //   price += so.Amount;
    // });
    if (this.SalesOrderPayment.Tickets.length > 0 && this.SalesOrderPayment.Concession.length == 0) {
      if (price > this.SalesOrderPayment.TotalAmount) {
        let diff = Number((price - this.SalesOrderPayment.TotalAmount).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Tickets.length - 1;
        this.SalesOrderPayment.Tickets[lastIndex].Price = Number((this.SalesOrderPayment.Tickets[lastIndex].Price - diff).toFixed(2));
        console.log("diff", diff)
      }
      if (price < this.SalesOrderPayment.TotalAmount) {
        let diff = Number((this.SalesOrderPayment.TotalAmount - price).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Tickets.length - 1;
        this.SalesOrderPayment.Tickets[lastIndex].Price = Number((this.SalesOrderPayment.Tickets[lastIndex].Price + diff).toFixed(2));
        console.log("diff", diff)
      }
    }
    if (this.SalesOrderPayment.Tickets.length == 0 && this.SalesOrderPayment.Concession.length > 0) {
      if (price > this.SalesOrderPayment.TotalAmount) {
        let diff = Number((price - this.SalesOrderPayment.TotalAmount).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Concession.length - 1;
        this.SalesOrderPayment.Concession[lastIndex].Price = Number((this.SalesOrderPayment.Concession[lastIndex].Price - diff).toFixed(2));
        console.log("diff", diff)
      }
      if (price < this.SalesOrderPayment.TotalAmount) {
        let diff = Number((this.SalesOrderPayment.TotalAmount - price).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Concession.length - 1;
        this.SalesOrderPayment.Concession[lastIndex].Price = Number((this.SalesOrderPayment.Concession[lastIndex].Price + diff).toFixed(2));
        console.log("diff", diff)
      }
    }
    if (this.SalesOrderPayment.Tickets.length > 0 && this.SalesOrderPayment.Concession.length > 0) {
      if (price > this.SalesOrderPayment.TotalAmount) {
        let diff = Number((price - this.SalesOrderPayment.TotalAmount).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Concession.length - 1;
        this.SalesOrderPayment.Concession[lastIndex].Price = Number((this.SalesOrderPayment.Concession[lastIndex].Price - diff).toFixed(2));
        console.log("diff", diff)
      }
      if (price < this.SalesOrderPayment.TotalAmount) {
        let diff = Number((this.SalesOrderPayment.TotalAmount - price).toFixed(2));
        let lastIndex = this.SalesOrderPayment.Concession.length - 1;
        this.SalesOrderPayment.Concession[lastIndex].Price = Number((this.SalesOrderPayment.Concession[lastIndex].Price + diff).toFixed(2));
        console.log("diff", diff)
      }
    }
  }





  startTimer() {
    this.LoaderService.startTimer();
    console.log('timer start')
    this.LoaderService.isCancelOrder.next(false);
  }
}

