import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KioskPOSService } from '../services/kiosk-pos.service';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

	constructor(private router :Router,private kioskPOSService : KioskPOSService){
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(next.routeConfig.path == 'home/:LocationId'){
      		this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
		}
		else if(next.routeConfig.path == 'movie-list/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
		}
		else if(next.routeConfig.path == 'show-time/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'add-tickets/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'select-seats/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getTicketsTotalTickets() <= 0){
				this.kioskPOSService.showSnackBar('Please add tickets first!.','close');
                this.router.navigate(['/kiosk/add-tickets/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'add-food-packs/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getTicketsTotalTickets() <= 0){
				this.kioskPOSService.showSnackBar('Please add tickets first!.','close');
                this.router.navigate(['/kiosk/add-tickets/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getSeating() != 'open' && this.kioskPOSService.getSeats().length == 0){
				this.kioskPOSService.showSnackBar('Please select seats first!.','close');
                this.router.navigate(['/kiosk/select-seats/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'checkout/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getTicketsTotalTickets() <= 0){
				this.kioskPOSService.showSnackBar('Please add tickets first!.','close');
                this.router.navigate(['/kiosk/add-tickets/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getSeating() != 'open' && this.kioskPOSService.getSeats().length == 0){
				this.kioskPOSService.showSnackBar('Please select seats first!.','close');
                this.router.navigate(['/kiosk/select-seats/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'payment/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getTicketsTotalTickets() <= 0){
				this.kioskPOSService.showSnackBar('Please add tickets first!.','close');
                this.router.navigate(['/kiosk/add-tickets/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getSeating() != 'open' && this.kioskPOSService.getSeats().length == 0){
				this.kioskPOSService.showSnackBar('Please select seats first!.','close');
                this.router.navigate(['/kiosk/select-seats/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else if(next.routeConfig.path == 'confirm-tickets/:LocationId'){
			this.kioskPOSService.checkLocationId(next.params.LocationId);
			if(this.kioskPOSService.getLocationId() == ''){
				this.kioskPOSService.showSnackBar('Please select location first!.','close');
                this.router.navigate(['/']);
                return false;
			}
			if(this.kioskPOSService.getMovieId() == ''){
				this.kioskPOSService.showSnackBar('Please select movie first!.','close');
                this.router.navigate(['/kiosk/movie-list/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getScreenId() == ''){
				this.kioskPOSService.showSnackBar('Please select screen first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getShowId() == ''){
				this.kioskPOSService.showSnackBar('Please select show first!.','close');
                this.router.navigate(['/kiosk/show-time/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getTicketsTotalTickets() <= 0){
				this.kioskPOSService.showSnackBar('Please select seats first!.','close');
                this.router.navigate(['/kiosk/add-tickets/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getSeating() != 'open' && this.kioskPOSService.getSeats().length == 0){
				this.kioskPOSService.showSnackBar('Please select seats first!.','close');
                this.router.navigate(['/kiosk/select-seats/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
			if(this.kioskPOSService.getSalesOrderData().length == 0){
				this.kioskPOSService.showSnackBar('Something went wrong!.','close');
                this.router.navigate(['/kiosk/select-seats/'+this.kioskPOSService.getLocationId()]);
                return false;
			}
		}
		else{
		}
		return true;
	}

}
