import { Component, OnInit, TemplateRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { LocationMovieVM } from '../viewmodels/KioskPosVM';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ScriptService } from 'src/app/helper/services/docloadings/script.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/helper/services/common.service';
import { MovieService } from 'src/app/movies/movie.service';
declare const $: any;

@Component({
  selector: 'app-kiosk-movie-list',
  templateUrl: './kiosk-movie-list.component.html',
  styleUrls: ['./kiosk-movie-list.component.css', '../../../assets/pos/css/slick.css', '../../../assets/pos/css/slick-theme.css'],
  encapsulation: ViewEncapsulation.None
})
export class KioskMovieListComponent implements OnInit, OnDestroy {
  timingModel: BsModalRef;
  config: ModalOptions = {
    animated: false,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'common-modal in modal-lg'
  };
  moment: any = moment;
  displayDate: Date;// = new Date(this.kioskPOSService.checkLocationMoviDate(null).startDateTime.replace("Z", ""));
  selectedDate: moment.MomentInput; // = moment(this.displayDate).format('DD-MM');
  public locationMovieList: LocationMovieVM[] = [];
  public subscribed: any = [];
  public sliderDays = [];
  loading: boolean = true;

  constructor(public kioskPOSService: KioskPOSService,
    private commonServ: CommonService, private router: Router, private scriptLoader: ScriptService, private modalService: BsModalService,private MovServ: MovieService) {
    this.scriptLoader.addScript('slick', '../../../assets/pos/js/slick.js');

    this.commonServ.GetDateTimeByTimeZone().subscribe(date => {
      // set the date only after retrieve the server location date.
      this.displayDate = new Date(this.kioskPOSService.checkLocationMoviDate(date).startDateTime.replace("Z", ""));
      this.selectedDate = moment(this.displayDate).format('DD-MM');
      this.getMovieData();
      this.getDaysInMonth();
    });
  }

  ngOnInit() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.scriptLoader.removeScript('slick');
  }

  public getMoviesListByLocationID() {
    //if (this.kioskPOSService.movieData.length == 0)
      //this.getMovieData();
    //else
    //  this.locationMovieList = this.kioskPOSService.movieData;
  }

  public async getMovieData() {
    this.loading = true;
    this.locationMovieList = [];
    this.kioskPOSService.movieData = [];
   await this.MovServ.getAllMovieRatings().subscribe(res => {
    this.kioskPOSService.Ratings = res;
     
    });
    this.kioskPOSService.getLocationMoviesData(true).then(async result => {
       //sorting movie by movie name logib
       result = await result.sort((a, b) => {
        var textA = a.MovieName.toUpperCase();
        var textB = b.MovieName.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this.locationMovieList = result;
      this.kioskPOSService.movieData = result;
      this.loading = false;
    });
  }

  public selectMovie(e, movie: any, index: number = 0) {
    this.kioskPOSService.unsetFinalData(['PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems']);
    let mv = {
      MovieID: movie.MovieID,
      MovieName: movie.MovieName,
      PosterImage: movie.PosterImage,
      Rating: movie.Rating,
      Review: movie.Review,
      Runtime: movie.Runtime
    }
    this.kioskPOSService.setMovieId(mv);
    this.router.navigate(['/kiosk/show-time/' + this.kioskPOSService.getLocationId() + '/']);
  }

  public getDaysInMonth() {
    this.commonServ.GetDateTimeByTimeZone().subscribe(dt => {
      let startDate = new Date(dt);
      let startDateMoment = moment(startDate);
      let endDateMoment = moment(new Date(dt).setDate(new Date(dt).getDate() + 30));
      while (startDateMoment <= endDateMoment) {
        this.sliderDays.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
        startDateMoment = moment(startDate);
      }
    });
  }

  openModel(template: TemplateRef<any>) {
    this.timingModel = this.modalService.show(template, { class: this.config.class + ' select-time-model' });
    setTimeout(() => {
      $('.select-date-slider').not('.slick-initialized').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [{ breakpoint: 768, settings: { slidesToShow: 3,slidesToScroll: 3 } }]
      });
    }, 50);
  }

  closeModal(template: TemplateRef<any>) {
    this.timingModel.hide();
  }

  getMovieListForDate(e, SelectedDate, timingModel: TemplateRef<any>) {
    let date = moment(SelectedDate).format('YYYY-MM-DD') + "T";
    date += "00:00:00.000";

    this.timingModel.hide();
    this.kioskPOSService.setLocationMovieDate(date);
    this.displayDate = this.kioskPOSService.getLocationMovieDate();
    this.selectedDate = moment(this.displayDate).format('DD-MM');
    this.getMovieData();
  }
}
