import { Component, OnInit, ViewEncapsulation, Renderer2, OnDestroy } from '@angular/core';
import { setTheme } from "ngx-bootstrap/utils";
import { ScriptService } from '../helper/services/docloadings/script.service';
// import "../../assets/js/bootstrap.min.js";
import { StyleService } from '../helper/services/docloadings/style.service';

declare const require: any;
@Component({
  selector: 'app-empty-layout',
  templateUrl: './empty-layout.component.html',
  styleUrls: ['./empty-layout.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class EmptyLayoutComponent implements OnInit,OnDestroy {
  
  constructor(private stser:StyleService) { 

    setTheme("bs3");
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
