import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from '../../helper/services/current-user.service';

@Component({
  selector: 'app-nav-menu-error',
  templateUrl: './nav-menu-error.component.html',
  styleUrls: ['./nav-menu-error.component.css']
})
export class NavMenuErrorComponent implements OnInit {

  locationName = '';

  constructor(private currentUserService: CurrentUserService) { }

  ngOnInit() {
    this.currentUserService.getContextLocationName().subscribe(locName => {
      this.locationName = locName;
    });
  }
}
