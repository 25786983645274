import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../contact/contact';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class ContactService extends ApiService  {

  ApiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Contact';
  }

  sendContactInfo(newClientContact: Contact): Observable<any> {
    //return [];
    return this.http
      .post(this.ApiBaseUrl, newClientContact)
      .pipe(catchError(this.errorHandler));
  }
}