import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dinePipe'
})
export class DinePipePipe implements PipeTransform {

  transform(items: any, status: string): any {
      if (!items || !status) {
          return items;
      }
      // filter items array, items which match and return true will be
      // kept, false will be filtered out
      if (status == 'current') {
        return items.filter(item => item.Status != 3 && item.Status != 4 && item.Status != 5);
      }

      if (status == 'history') {
        return items.filter(item => item.Status == 3 || item.Status == 4);
      }
  }

}
