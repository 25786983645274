import {Injectable, Inject, Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from "../../locations/location";
import { StyleService } from 'src/app/helper/services/docloadings/style.service';
import { ConcessionService } from 'src/app/concession/concession.service';
import { Concession } from 'src/app/concession/concession';
import { PaymentService } from "../../helper/services/payment/payment.service";
import Logger from "../../helper/services/payment/logger";
import Client from "../../helper/services/payment/client";
import{ POSService } from '../pos.service'
// import { Stripe } from ''

import { Pos } from '../pos';
import { PaymentMode } from 'src/app/helper/models/SalesOrder';
declare const require:any;
@Component({
  selector: 'app-pos-layout',
  templateUrl: './pos-layout.component.html'
})
@Injectable({
  providedIn: 'root'
})
export class PosLayoutComponent implements OnInit, OnDestroy {
  static location:Location = new Location();
  // StripeTerminal;
  client: any;
  ApiBaseUrl;
  apiurl:any;
  pendingPaymentIntentSecret:null;
  static Pos:Pos = new Pos();
  static error: any;
  state: any;
  terminal: any;
  stripeSecretToken:string;
  constructor(@Inject('BASE_URL') baseUrl: string,private PaymentService:PaymentService,private nav:Router,private stSer:StyleService,private concession: ConcessionService,private POSService :POSService) {
    this.stSer.addStyle('bt',require('../../../assets/kiosk-pos/css/bootstrap.min.css'));
    this.stSer.addStyle('slk',require('../../../assets/pos/css/slick.css'));
    this.stSer.addStyle('slktm',require('../../../assets/pos/css/slick-theme.css'));
    this.stSer.addStyle('poslay',require('./pos-layout.component.css'));
    this.ApiBaseUrl = baseUrl + 'api/';
    PosLayoutComponent.Pos = JSON.parse(sessionStorage.posData);
    PosLayoutComponent.location = JSON.parse(sessionStorage.location);
  }

  ngOnInit() {
    var url = this.ApiBaseUrl + 'SalesOrder/GetStripeConnection'
    this.apiurl = url;
    this.stripeSecretToken=''
    this.loadStripe(url);


    //removing class for printing
    //don't remove this
    $("body").removeClass('kiosk');
  }

  loadStripe(url) {
    this.client = new Client(url);
    this.terminal = (<any>window).StripeTerminal.create({
      onFetchConnectionToken: async () => {
        let connectionTokenResult = await this.client.createConnectionToken();
        this.stripeSecretToken = connectionTokenResult.secret;
        this.POSService.stripeSecretToken= connectionTokenResult.secret;
//         var stripe = Stripe(this.stripeSecretToken);
//         var elements = stripe.elements();
        return connectionTokenResult.secret;
      },
      onUnexpectedReaderDisconnect: async () => {
        let onUnexpectedReaderDisconnect = await this.client.onUnexpectedReaderDisconnect();
        return onUnexpectedReaderDisconnect;
      }
    });
    Logger.watchObject(this.client, "backend", {
      createConnectionToken: {
        docsUrl: this.apiurl
      },
      onUnexpectedReaderDisconnect: {
          docsUrl: this.apiurl
      },
      // createPaymentIntent: {
      //   docsUrl: this.apiurl
      // },
    });
    this.connectToSimulator();
    this.discoverReaders();
  }
  setState = async (attr) => {
    return attr;
  };
  discoverReaders = async () => {
    this.setState({
      discoveryWasCancelled: false
    });
    const discoverResult = await this.terminal.discoverReaders();
    this.POSService.isSwipeReaderExist = discoverResult.discoveredReaders.length > 0 ? true : false;
    console.log("discoverReaders",discoverResult);
    if (discoverResult.error) {
      console.log("Failed to discover: ", discoverResult.error);
      return discoverResult.error;
    } else {
      this.setState({
        discoveredReaders: discoverResult.discoveredReaders
      });
      return discoverResult.discoveredReaders;
    }
  };

  CollectPaymentGiftcard= async (Orderresponse) => {
    console.log("Orderresponse.CreditCardinfo",Orderresponse.CreditCardinfo);
    if(Orderresponse.CreditCardinfo){
      var processPaymentResult,paymentIntentId,resulterror;
      let collectPaymentMethodResult = await this.terminal.collectPaymentMethod(Orderresponse.CreditCardinfo);
      console.log("collectPaymentMethodResult",collectPaymentMethodResult);
      processPaymentResult = await this.terminal.processPayment(collectPaymentMethodResult.paymentIntent).then(result => {
        console.log("processPaymentResultresult",result);
        paymentIntentId = result && result.paymentIntent ? result.paymentIntent.id : '';
      }, error => {
        resulterror=error.error.message;
        console.log("processPaymentResulterror",error);
      });
      console.log("paymentIntentId",paymentIntentId);
      // if(paymentIntentId){
      var paymentFlag = paymentIntentId ? true : false;
      paymentIntentId = paymentIntentId ? paymentIntentId : null
      var createPaymentIntent = await this.PaymentService.passPaymentIntentIdGiftcard(Orderresponse.ConfirmationCode,paymentIntentId,paymentFlag).toPromise();
      // }
      console.log("createPaymentIntent",createPaymentIntent);
      var returnmessage = paymentIntentId ? true : resulterror;
      return returnmessage;
    }
  };
  CollectPaymentMethod= async (Orderresponse) => {
    console.log("Orderresponse.CreditCardinfo",Orderresponse.CreditCardinfo);
    if(Orderresponse.CreditCardinfo){
      var processPaymentResult,paymentIntentId,resulterror;
      let collectPaymentMethodResult = await this.terminal.collectPaymentMethod(Orderresponse.CreditCardinfo);
      console.log("collectPaymentMethodResult",collectPaymentMethodResult);
      processPaymentResult = await this.terminal.processPayment(collectPaymentMethodResult.paymentIntent).then(result => {
        console.log("processPaymentResultresult",result);
        paymentIntentId = result && result.paymentIntent ? result.paymentIntent.id : '';
      }, error => {
        resulterror=error.error.message;
        console.log("processPaymentResulterror",error);
      });
      console.log("paymentIntentId",paymentIntentId);
      // if(paymentIntentId){
      var paymentFlag = paymentIntentId ? true : false;
      paymentIntentId = paymentIntentId ? paymentIntentId : null
      var createPaymentIntent = await this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode,paymentIntentId,paymentFlag,PaymentMode.CC).toPromise();
      // }
      console.log("createPaymentIntent",createPaymentIntent);
      var returnmessage = paymentIntentId ? true : resulterror;
      return returnmessage;
    }
  };
  CollectCardPayment = async (Orderresponse,card) => {
    console.log("Orderresponse",Orderresponse);
    var paymentFlag = false,resule;
    if(Orderresponse.CreditCardinfo){
      (<any>window).stripe.confirmCardPayment(Orderresponse.CreditCardinfo, {
        payment_method: {
          card: card,
          billing_details: {}
        }
      }).then(function(result) {
        if (result.error) {
          console.log(result.error.message);
          // var createPaymentIntent = this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode,' ','false').toPromise();
          // return result.error.message;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
          paymentFlag = true;
          resule=result;
            // var createPaymentIntent = this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode,result.paymentIntent,'true').toPromise();
            // return true;
          }
        }
      });
    }
    console.log("paymentFlag",paymentFlag);
    if(paymentFlag){
      this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode,resule.paymentIntent,'true',Orderresponse.PaymentMode).toPromise();
      return true;
    }else{
      this.PaymentService.passPaymentIntentId(Orderresponse.ConfirmationCode,' ','false',Orderresponse.PaymentMode).toPromise();
      return false;
    }
  };
  connectToSimulator = async () => {
    const simulatedResult = await this.terminal.discoverReaders({
      simulated: true
    });
    await this.connectToReader(simulatedResult.discoveredReaders[0]);
  };

  connectToReader = async selectedReader => {
    // 2b. Connect to a discovered reader.
    const connectResult = await this.terminal.connectReader(selectedReader);
    if (connectResult.error) {
      console.log("Failed to connect:", connectResult.error);
    } else {
      this.setState({
        status: "workflows",
        discoveredReaders: [],
        reader: connectResult.reader
      });
      return connectResult;
    }
  };

  disconnectReader = async () => {
    // 2c. Disconnect from the reader, in case the user wants to switch readers.
    await this.terminal.disconnectReader();
    this.setState({
      reader: null
    });
  };
  registerAndConnectNewReader = async (label, registrationCode) => {
    try {
      let reader = await this.client.registerDevice({
        label,
        registrationCode
      });
      // After registering a new reader, we can connect immediately using the reader object returned from the server.
      await this.connectToReader(reader);
      console.log("Registered and Connected Successfully!");
    } catch (e) {
    }
  };
  ngOnDestroy(): void {
    this.stSer.removeStyle('bt' );
    this.stSer.removeStyle('slk');
    this.stSer.removeStyle('slktm');
    this.stSer.removeStyle('poslay');
  }

}
