import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';

@Component({
	selector: 'app-kiosk-confirm-ticket',
	templateUrl: './kiosk-confirm-ticket.component.html',
	styleUrls: ['./kiosk-confirm-ticket.component.css']
})
export class KioskConfirmTicketComponent implements OnInit {

	public subscribed : any = [];
	public movieData : any = [];
	public moviewScreenData : any = {};
    public barcode_config : any = {};

	constructor(public router: Router,public kioskPOSService: KioskPOSService){
        this.barcode_config = {
            barcode : 'TEST',
            barcode_format_type : 'CODE128',
            elementType : 'img',
            lineColor : '#aeb2c2',
            width : 2,
            height : 150,
            displayValue : true,
            fontOptions : '',
            font : 'monospace',
            textAlign : 'center',
            textPosition : 'bottom',
            textMargin : 2,
            fontSize : 15,
            background : '#1E2A57',
            margin : 5,
            marginTop : 5,
            marginBottom : 5,
            marginLeft : 5,
            marginRight : 5
        };
        this.movieData = this.kioskPOSService.movieData;
        console.log("this.movieData",this.movieData)
		this.getScreensDataForMovie();
        let SalesOrderData = this.kioskPOSService.getSalesOrderData(); 
        /*this.barcode_config.barcode =  SalesOrderData[(SalesOrderData.length - 1)].ConfirmationCode;*/
        this.barcode_config.barcode =  SalesOrderData.ConfirmationCode;
	}

	ngOnInit() {
	}

	public getScreensDataForMovie(){
        this.movieData.forEach((item,index) => {
            if (item.LocationId == this.kioskPOSService.getLocationId() &&  item.MovieID == this.kioskPOSService.getMovieId()){
                this.moviewScreenData = item;
                this.moviewScreenData.ScreenName = '';
                this.moviewScreenData.ShowTime = '';
                this.moviewScreenData.Seats = '-';
                this.moviewScreenData.ScreenID = this.kioskPOSService.getScreenId();
                this.moviewScreenData.ShowID = this.kioskPOSService.getShowId();
                item.Screens.forEach((screenItem,screenIndex) => {
                	if(screenItem.ScreenID == this.moviewScreenData.ScreenID){
                		this.moviewScreenData.ScreenName = screenItem.ScreenName;
                		screenItem.ShowTimes.forEach((showItem,showIndex) => {
                			if(showItem.ShowID == this.moviewScreenData.ShowID){
                        this.moviewScreenData.ShowTime = this.kioskPOSService.covertTimeToFormat(showItem.ShowTime,'MMMM DD YYYY, hh:mm')+' '+this.kioskPOSService.covertTimeToFormat(showItem.ShowTime,'A');
                			}
                		});
                	}
                });
                let seatsArray = this.kioskPOSService.getSeats();
                if(seatsArray.length > 0){
                    this.moviewScreenData.Seats = seatsArray.join(', ');
                }
            }
        });
        console.log("this.movieData2",this.movieData)
    }

	ngOnDestroy(){
        this.subscribed.forEach(element =>{
          element.unsubscribe();
        });
    }

    public exitPrint(e){
        this.kioskPOSService.unsetFinalData(['MovieId','PriceCardId','ScreenId','ShowId','Tax','Tickets','Seats','Seating','IsFoodItemsAvailable','FoodItems']);
        this.router.navigate(['/kiosk/home/'+this.kioskPOSService.getLocationId()]);
    }
}
