import { Component, OnInit, ViewEncapsulation, ViewChildren } from '@angular/core';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { Router } from '@angular/router';
import Keyboard from "simple-keyboard";
import Swal from 'sweetalert2';
import { CommonSettings } from 'src/app/helper/commonSettings';
import { GiftcardService } from '../../giftcard/giftcard.service';
import { GiftCardStatus } from '../../giftcard/giftcard';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';
@Component({
  selector: 'app-giftcard-find',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './giftcard-find.component.html',
  styleUrls: ['./giftcard-find.component.css', '../../../../node_modules/simple-keyboard/build/css/index.css']
})
export class GiftcardFindComponent implements OnInit {
  error: string = null;
  PNumber: string;
  GiftCardNumber: string;
  SalesOrder: any = [];
  count: number = 0;
  keyboard: Keyboard;
  @ViewChildren('kioskgcninput') kioskgcninput: any;
  
  constructor(public kioskPOSService: KioskPOSService, public router: Router, private GCS: GiftcardService,private es:ErrorMessagesService) {
    this.GiftCardNumber = null;
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      layout: {
        default: CommonSettings.keybSetting.numpadDef,
      },
      maxLength:16,
      theme: CommonSettings.keybSetting.theme
    });
    const input: HTMLInputElement = this.kioskgcninput.first.nativeElement as HTMLInputElement;
    input.focus();
  }

  onChange = (input: any) => {
    if (input && input.length > 16) {
      return;
    }
    else {
      this.GiftCardNumber = input;
    }
  };
  
  changeInput(event) {
  }

  onInputChange = (event: any) => {
    if (event && event.target && event.target.value && event.target.value.length > 16) {
      return;
    }
    else {
      this.keyboard.setInput(event.target.value);
    }
  };

  ngOnInit() {
  }

  verifyGiftCardNumber(giftCardNumber) {
    if (giftCardNumber.length != 16) {
      Swal.fire({
        title: '',
        text: this.es.gcErrorMsgList[2].message,
        showCancelButton: false,
        type: 'error'
      });
    }
    else {
      this.GCS.getGiftCardbyCardNumber(giftCardNumber).then(res => {
        if (res != null) {
          if (res.Status == GiftCardStatus.Registered) {
            Swal.fire({
              title: this.es.gcErrorMsgList[30].message,
              text: this.es.gcErrorMsgList[7].message,
              showCancelButton: false,
              type: 'error'
            });
          }
          else {
            this.router.navigate(['/kiosk/giftcard/' + giftCardNumber]);
          }
        }
        else {
          Swal.fire({
            title: '',
            text: this.es.gcErrorMsgList[0].message,
            showCancelButton: false,
            type: 'error'
          });
          this.router.navigate(['/kiosk/giftcard']);
        }
      });
    }
    
  }

}
