import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';

@Component({
	selector: 'app-kiosk-payment',
	templateUrl: './kiosk-payment.component.html',
	styleUrls: ['./kiosk-payment.component.css']
})
export class KioskPaymentComponent implements OnInit {

	paymentData = new paymentData('cash'/*,'','','',''*/);

	private subscribed : any = [];
	public finalPayment : number = 0;
	public percentageAmount : number = 0;

	constructor(public router: Router,public kioskPOSService: KioskPOSService){
		this.getFinalPrice();
	}

	ngOnInit() {
	}

	ngOnDestroy(){
        this.subscribed.forEach(element =>{
          element.unsubscribe();
        });
    }

    public getFinalPrice(){
    	this.finalPayment = this.kioskPOSService.getTicketsTotalPrice() + this.kioskPOSService.getFoodItemsTotalPrice();
    	// if(this.kioskPOSService.getTax() != ''){
      //   	let percentage = parseFloat(this.kioskPOSService.getTax().replace (/%/g,""));
      //   	this.percentageAmount = 0;
      //   	if(percentage > 0){
      //   		this.percentageAmount = (this.finalPayment * percentage) / 100;
      //   		this.finalPayment  += (this.percentageAmount > 0) ? this.percentageAmount : 0;
      //   	}
      //   }
    }

    public gotToPayment(e){

    }

    public backButton(e){
        this.router.navigate(['/kiosk/checkout/'+this.kioskPOSService.getLocationId()+'/']);
    }
}

export class paymentData {
    constructor(
        public paymentType : string = 'cash'/*,
        public CardOwnerName : string = [],
        public CardOwnerName : string = [],
        public CardOwnerName : string = [],
        public CardOwnerName : string = [],*/
    ){}
}
