import { Component, OnInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { date } from 'timelite';
import { CompanyAdminService } from '../company-admin/company-admin.service';
import { CurrentUserService } from '../helper/services/current-user.service';
import { GetCompanyDashboard } from '../companydashboard/companydashboardmodel';
import { data } from 'jquery';
import { ErrorMessagesService } from '../helper/services/error-messages.service';
import { AddHelperMethods } from '../helper/models/AddHelperModal';
import { LocationsService } from '../locations/locations.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexDataLabels,
  ApexTheme,
} from "ng-apexcharts";

import { LocationMovieService } from '../location-movie/location-movie.service';
import { SaleschannelService } from '../helper/services/saleschannel.service';
import { CompanyService } from '../company/company.service';
import { Router } from '@angular/router';

export type ChartOptions = {
  series: any;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  fill: any;
  theme: ApexTheme;
  colors: any
  plotOptions: any
  legend: any
  tooltip: any
};

@Component({
  selector: 'app-companydashboardpreview',
  templateUrl: './companydashboardpreview.component.html',
  styleUrls: ['./companydashboardpreview.component.css']
})
export class CompanydashboardpreviewComponent implements OnInit {
@ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  companyDashboard: boolean = false;
activeCompanyDashboard: string = '0';
postObj: any = null;
movieObj: any = null;
selectedFromDate: any;
selectedToDate: any;
today: any;
companyId: string = null;
collectionTitle: string = 'Collection Summary '

daterangeOptionArray = ['Today', 'Week', 'Daterange', 'Year']
tabNavBtns: boolean = true
reportdata: any = [];
refundData: any = [];
cashData: any = []
loading: boolean = true;
loadingMovie: boolean = true;
grandtotalObj: any = null;
SelectedSearchType: string = 'Today';
startDate: any = '';
endDate: any = '';
error: any = '';
distTable = false;
locationList: any = [];
selectedLocation: any = [];

MovieList: any = [];
movieTotalObj: any = null;

dropdownSettingsSalesChannel: IDropdownSettings = {
  singleSelection: false,
  idField: 'Location_ID',
  textField: 'Location_Name',
  itemsShowLimit: 3,
  allowSearchFilter: true,
};
displayDateRange: string = null;
moviesDisplayDateRange: string = null;
templateLabel = ['<span style="color: #fff" >WW</span>Loading...<span style="color: #fff" >WWW</span>',
  '<span style="color: #fff" >WW</span>Loading...<span style="color: #fff" >WWW</span>',
  '<span style="color: #fff" >WW</span>Loading...<span style="color: #fff" >WWW</span>',]
templateMovieLabel = ['<span style="color: transparent" >WW</span><span style="color: transparent" >Loading...</span><span style="color: transparent" >WWW</span>',
  '<span style="color: transparent" >WW</span><span style="color: transparent" >Loading...</span><span style="color: transparent" >WWW</span>',
  '<span style="color: transparent" >WW</span><span style="color: transparent" >Loading...</span><span style="color: transparent" >WWW</span>',]

staticConcessionLabels: any = ['29<span style="color: transparent" >WWWWWWWWW</span>',
  '20<span style="color: transparent" >WWWWWWWWW</span>',
  '15<span style="color: transparent" >WWWWWWWWW</span>',
  '35<span style="color: transparent" >WWWWWWWWW</span>',
  '22<span style="color: transparent" >WWWWWWWWW</span>',
  '18<span style="color: transparent" >WWWWWWWWW</span>',
  '50<span style="color: transparent" >WWWWWWWWW</span>',
  '41<span style="color: transparent" >WWWWWWWWW</span>',
  '19<span style="color: transparent" >WWWWWWWWW</span>',
  '45<span style="color: transparent" >WWWWWWWWW</span>']

items: any = [
  {
    title: 'Collection Summary', curr: '$', span: ' ', numbers: [0, 0, 0], labels: this.templateLabel, colors: ['#03045E', '#0077B6', '#faa42b'], total: 0,
    chart: {
      type: "donut",
    },
  },
  {
    title: 'Refunds', curr: '$', span: ' ', numbers: [0, 0, 0], labels: this.templateLabel, colors: ['#E15759', '#F28E2B', '#4E79A7'], total: 0,
    chart: {
      type: "donut",
    },
  },
  {
    title: 'Cash Summary', curr: ' ', span: ' Shifts', numbers: [0, 0, 0], labels: this.templateLabel, colors: ['#70AD07', '#4472C4', '#C00000'], total: 0,
    chart: {
      type: "donut",
    },
  },
  {
    title: '"Top 3 Movies"', curr: '$', span: ' ', numbers: [1, 1, 1], labels: this.templateLabel, colors: ['#808080', '#691c6c', '#f2782b'], total: 0,
    chart: {
      type: "donut",
    },
  },
  //{
  //  title: 'Concession', curr: '$', span: ' ',
  //  numbers: [29, 20, 15, 35, 22, 18, 50, 41, 19, 45],
  //  labels: this.templateMovieLabel,
  //  colors: ['#30619c', '#ac3532', '#7c9e3b', '#5e437f', '#2987a1', '#ca6d1f', '#758fb8', '#c07a79', '#a0b57d', '#9081a7'], total: 0,
  //  chart: {
  //    type: "pie",
  //  },
  //},
]

refundsQtyAmmount: any = []
cashTileArrTotal: any = { shiftsFinalized: 0, cashExpected: 0, cashDeposited: 0, cashDifference: 0, }

currentItemIndex = 0;
sWidth = window.innerWidth
displayedItems: any
itemWidth = this.sWidth <= 500 ? '49%' : '24%';

isCollection: boolean = true
isRefund: boolean = false
isCash: boolean = false
isMovie: boolean = false
tabList = [
  ['Collection Summary', true],
  ['Refunds', false],
  ['Cash Summary', false],
  ['"Top 3 Movies"', false],
  ['Concession', false]
]

isFilter: boolean = false

@ViewChildren(DataTableDirective)
dtElements: QueryList<DataTableDirective>;


@ViewChild("dtElement", { static: false })
@ViewChild(DataTableDirective, { static: true })
dtElement: DataTableDirective;
dtTrigger: Subject<any> = new Subject<any>();
dtOptionsCollection: any

@ViewChild("dtElementRefund", { static: false })
@ViewChild(DataTableDirective, { static: true })
dtElementRefund: DataTableDirective;
dtTriggerRefund: Subject<any> = new Subject<any>();
dtOptionsRefund: any

@ViewChild("dtElementCash", { static: false })
@ViewChild(DataTableDirective, { static: true })
dtElementCash: DataTableDirective;
dtTriggerCash: Subject<any> = new Subject<any>();
dtOptionsCash: any

@ViewChild("dtElementMovie", { static: false })
@ViewChild(DataTableDirective, { static: true })
dtElementMovie: DataTableDirective;
dtTriggerMovie: Subject<any> = new Subject<any>();
dtOptionsMovie: any

@ViewChild("dtElementConcession", { static: false })
@ViewChild(DataTableDirective, { static: true })
dtElementConcession: DataTableDirective;
dtTriggerConcession: Subject<any> = new Subject<any>();
dtOptionsConcession: any

//Movies variables
isMovieFilter: boolean = false
Distributors = [];
SalesChannel = [];
movieStartDate: any = '';
movieEndDate: any = '';
selectedMovieFromDate: any;
selectedMovieToDate: any;
daterangeMovieOptionArray = ['Today', 'Week', 'Daterange', 'Year']
SelectedMovieSearchType: string = 'Today';

//Concession variables
isConcessionFilter: boolean = false
loadingConcession: boolean = true;
concessionList: any = []
concessionTotalObj: any = null;
concessionStartDate: any = '';
concessionEndDate: any = '';
selectedConcessionFromDate: any;
selectedConcessionToDate: any;
daterangeConcessionOptionArray = ['Today', 'Week', 'Daterange', 'Year']
SelectedConcessionSearchType: string = 'Today';
concessionDisplayDateRange: string = null;

companyDetails: any

constructor(private _LS: LocationsService, private router: Router,
  public companyAdminService: CompanyAdminService,
  public currentUserService: CurrentUserService,
  private es: ErrorMessagesService,
  private locationMovieService: LocationMovieService,
  private salesChannelService: SaleschannelService,
  public companyService: CompanyService) {

  this.chartOptions = {
    //series: [[44, 55, 13, 43, 22]],
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        size: '30%',
        dataLabels: {
          offset: '-60%' // set the label width to 60% of the chart radius
        }
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: {
      style: {
        width: "60%",
      }
    },
    theme: {
      palette: "palette2"
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          //chart: {
          //  width: 200
          //},
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return '';
        },
        title: {
          formatter: function (seriesName) {
            return seriesName
          }
        }
      },
    },
  };

}

ngOnInit() {
  this.startDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
  this.endDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
  this.movieStartDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
  this.movieEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
  this.concessionStartDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
  this.concessionEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
  this.displayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";
  this.moviesDisplayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";
  this.concessionDisplayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";
  console.log('textbuildconsole');

  this.LoadLocations();
  this.iWidth()
}

openFilter() {
  if (this.isFilter == true) {
    return this.isFilter = false
  }
  return this.isFilter = true
}
clickTab(obj: any) {
  for (let i = 0; i < this.tabList.length; i++) {
    this.tabList[i][1] = false
  }
  for (let i = 0; i < this.tabList.length; i++) {
    if (this.tabList[i][0] == obj) {
      this.tabList[i][1] = true
    }
  }
}

iWidth() {
  if (this.sWidth > 1572) {
    this.tabNavBtns = false
    this.itemWidth = "24%"
    this.displayedItems = this.items.slice(0, 4)
  } else if (this.sWidth <= 1572 && this.sWidth > 1216) {
    this.itemWidth = "33%"
    this.displayedItems = this.items.slice(0, 3)
  } else if (this.sWidth <= 1216 && this.sWidth > 633) {
    this.itemWidth = "49%"
    this.displayedItems = this.items.slice(0, 2)
  } else if (this.sWidth <= 633 && this.sWidth > 0) {
    this.itemWidth = "99%"
    this.displayedItems = this.items.slice(0, 1)
  }
  this.getCompanyDashboardReport()
}

scrollLeft() {
  if (this.currentItemIndex > 0) {
    this.currentItemIndex--
    if (this.sWidth > 1572) {
      this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 4)
    } else if (this.sWidth <= 1572 && this.sWidth > 1216) {
      this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 3)
    } else if (this.sWidth <= 1216 && this.sWidth > 633) {
      this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 2)
    } else if (this.sWidth <= 633 && this.sWidth > 0) {
      this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 1)
      for (let i = 0; i < this.tabList.length; i++) {
        this.tabList[i][1] = false
      }
      for (let i = 0; i < this.tabList.length; i++) {
        if (this.tabList[i][0] == this.displayedItems[0].title) {
          this.tabList[i][1] = true
        }
      }
    }
  }
}

scrollRight() {
  if (this.sWidth > 1572) {
    if (4 < this.items.length - this.currentItemIndex) {
      this.currentItemIndex++;
    }
    this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 4)
  } else if (this.sWidth <= 1572 && this.sWidth > 1216) {
    if (3 < this.items.length - this.currentItemIndex) {
      this.currentItemIndex++;
    }
    this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 3)
  } else if (this.sWidth <= 1216 && this.sWidth > 633) {
    if (2 < this.items.length - this.currentItemIndex) {
      this.currentItemIndex++;
    }
    this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 2)
  } else if (this.sWidth <= 633 && this.sWidth > 0) {
    if (1 < this.items.length - this.currentItemIndex) {
      this.currentItemIndex++;
    }
    this.displayedItems = this.items.slice(this.currentItemIndex, this.currentItemIndex + 1)
    for (let i = 0; i < this.tabList.length; i++) {
      this.tabList[i][1] = false
    }
    for (let i = 0; i < this.tabList.length; i++) {
      if (this.tabList[i][0] == this.displayedItems[0].title) {
        this.tabList[i][1] = true
      }
    }
  }
}

LoadLocations() {
  this._LS.getLocations().subscribe((locations) => {
    this.locationList = locations.filter(l => l.Visible);
    // this.locationList.push({
    //   Location_ID: 'all',
    //   Location_Name: "All Location"
    // })
  }, error => {
    this.error = error;
  });
}
selectThislocation(location) {
  this.selectedLocation = location;
}
setSearchType(val) {
  this.SelectedSearchType = val;
  this.loading = true;
  if (val == 'Today') {
    this.startDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.endDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.displayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";
    this.getCompanyDashboardReport()
    this.isFilter = false
  } else if (val == 'Week') {

    let lastFriday;
    if (new Date().getDay() == 5) {
      lastFriday = new Date();
    } else {
      let t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
      lastFriday = new Date();
      lastFriday.setDate(t);
    }

    this.startDate = moment(new Date(lastFriday)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.endDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.displayDateRange = "(" + moment(new Date(lastFriday)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.getCompanyDashboardReport()
    console.log(this.startDate, this.endDate);
    this.isFilter = false
  } else if (val == 'Year') {
    // var d = new Date();
    // var pastYear = d.getFullYear() - 1;
    // d.setFullYear(pastYear);
    let d = new Date(new Date().getFullYear(), 0, 1);
    this.startDate = moment(new Date(d)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.endDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.displayDateRange = "(" + moment(new Date(d)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    console.log(this.startDate, this.endDate);
    this.getCompanyDashboardReport()
    this.isFilter = false
  }
  else if (val == 'Daterange') {
    this.loading = false;
    this.displayDateRange = '';
    this.selectedFromDate = undefined;
    this.selectedToDate = undefined;
  }
}

generateReportByDaterange() {
  if (this.selectedFromDate != null && this.selectedToDate != null && !this.error) {
    this.loading = true;
    this.startDate = moment(new Date(this.selectedFromDate)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.endDate = moment(new Date(this.selectedToDate)).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.displayDateRange = "(" + moment(new Date(this.selectedFromDate)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date(this.selectedToDate)).format('ddd, MMM DD, YYYY') + ")";

    this.getCompanyDashboardReport()
    this.isFilter = false
  } else {
    this.error = 'Please search with valid date range .'
  }

}
getCompanyDashboardReport() {
  this.setDtOptions()
  console.log(this.selectedLocation)
  this.currentUserService.get().subscribe(result => {
    this.companyId = result.CompanyId;
    this.postObj = {
      FromDate: this.startDate,
      ToDate: this.endDate,
      CompanyId: this.companyId,
      LocationId: this.selectedLocation.length == 0 ? null : this.selectedLocation.map(l => l.Location_ID)
    }

    console.log('this.postObj', this.postObj, this.selectedLocation);
    this.companyAdminService.GetCompanyDashboardV2(this.postObj).subscribe((result) => {
      this.reportdata = result;
      let dataLength = this.reportdata.length - 1;
      this.grandtotalObj = this.reportdata[dataLength];
      this.reportdata.splice(dataLength, 1);
      this.refundData = this.reportdata
      this.cashData = this.reportdata


      if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
          dtElement.dtInstance.then((dtInstance: DataTables.Api) => { dtInstance.destroy(); });
        });
      }
      this.setCollectionData()
      this.distTable = true;
      this.collectionTitle = 'Collection Summary (' + this.displayDateRange + ')'

    }, error => {
      console.log(error)
    });
  }, error => console.error(error));
}


setCollectionData() {

  let collectionNumArr = []
  let collectionLabelArr = []
  collectionNumArr.push(this.grandtotalObj.BoxOfficeRevenue)
  collectionNumArr.push(this.grandtotalObj.ConcessionRevenue)
  collectionNumArr.push(this.grandtotalObj.TotalGiftCard)
  collectionLabelArr.push(this.labelFunction('Box Office', this.grandtotalObj.BoxOfficeRevenue.toFixed(2).toString()))
  collectionLabelArr.push(this.labelFunction('Concession', this.grandtotalObj.ConcessionRevenue.toFixed(2).toString()))
  collectionLabelArr.push(this.labelFunction('Gift Card', this.grandtotalObj.TotalGiftCard.toFixed(2).toString()))

  let arr = [0, 1, 0]; // or [0] if it's only 0
  let isOnlyZeroes = collectionNumArr.every(element => element === 0);

  if (isOnlyZeroes) {
    this.items[0].numbers = [1, 1, 1]
  } else {
    this.items[0].numbers = collectionNumArr
  }
  this.items[0].labels = collectionLabelArr
  this.items[0].total = this.grandtotalObj.TotalRevenue.toFixed(2)
  this.dtTrigger.next(collectionNumArr);
  this.setRefundData()
}
setRefundData() {

  let refundNumbers = this.getSumCCCashGC(this.reportdata)
  this.refundsQtyAmmount = this.getSumTotalQTYRefundAmt(this.reportdata)
  let refundLabels = []
  refundLabels.push(this.labelFunction('Cash', refundNumbers[0].toFixed(2).toString()))
  refundLabels.push(this.labelFunction('Credit', refundNumbers[1].toFixed(2).toString()))
  refundLabels.push(this.labelFunction('Gift Card', refundNumbers[2].toFixed(2).toString()))

  let isOnlyZeroes = refundNumbers.every(element => element === 0);
  if (isOnlyZeroes) {
    this.items[1].numbers = [1, 1, 1]
  } else {
    this.items[1].numbers = refundNumbers
  }
  this.items[1].labels = refundLabels
  this.items[1].total = this.refundsQtyAmmount[1].toFixed(2)

  this.dtTriggerRefund.next();
  this.setCashData()
}

setCashData() {

  let cashTilesNumbers = this.getSumCashExpectedDepositedDifference(this.reportdata)
  let cashLabels = []
  cashLabels.push(this.labelFunction('Expected', cashTilesNumbers[0].toFixed(2).toString()))
  cashLabels.push(this.labelFunction('Deposited', cashTilesNumbers[1].toFixed(2).toString()))
  cashLabels.push(this.labelFunction('Difference', cashTilesNumbers[2].toFixed(2).toString()))


  let isOnlyZeroes = cashTilesNumbers.every(element => element === 0);
  if (isOnlyZeroes) {
    this.items[2].numbers = [1, 1, 1]
  } else {
    this.items[2].numbers = cashTilesNumbers
    if (this.items[2].numbers[2] < 0) {
      8
      this.items[2].numbers[2] = this.items[2].numbers[2] * -1
    }
  }
  //this.items[2].numbers = cashTilesNumbers
  this.items[2].labels = cashLabels

  this.dtTriggerCash.next();
  this.loading = false;

  //this.items[3].numbers = [1, 1, 1]

  this.items[0].numbers = this.setChartSliceVisible(this.items[0].numbers)
  this.items[1].numbers = this.setChartSliceVisible(this.items[1].numbers)
  this.items[2].numbers = this.setChartSliceVisible(this.items[2].numbers)
  //this.items[3].numbers = this.setChartSliceVisible(this.items[3].numbers
  if (this.loadingMovie == true) {
    this.getMovieSummaryGrid() //to load only oninit
  } else if (this.loadingMovie == false) {
    this.dtTriggerMovie.next() //to generate dtoptions for movie tile
    this.dtTriggerConcession.next() //to generate dtoptions for movie tile
  }
}

setDtOptions() {
  this.dtOptionsCollection = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    //"dom": "<'row'<'col-sm-3'f><'col-sm-9'B>>" +
    //  "<'row'<'col-sm-12'rtip>>",
    "dom": "Bfrtip",
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Collection Summary Preview ',
        title: 'Collection Summary Preview' + this.displayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Collection Summary Preview ',
        title: 'Collection Summary Preview' + this.displayDateRange,
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Collection Summary Preview' + this.displayDateRange,
          author: 'Cinema360',
          subject: 'Collection Summary Preview ' + this.displayDateRange,
        },
        title: 'Collection Summary Preview' + this.displayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.displayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Collection Summary Preview - ' + this.displayDateRange,
      },
    ],
  };

  this.dtOptionsRefund = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    //"dom": "<'row'<'col-sm-3'f><'col-sm-9'B>>" +
    //  "<'row'<'col-sm-12'rtip>>",
    "dom": "Bfrtip",
    "sorting": false, // disable sorting
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Refund Summary Preview',
        title: 'Refund Summary Preview' + this.displayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Refund Summary Preview',
        title: 'Refund Summary Preview' + this.displayDateRange
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Refund Summary Preview' + this.displayDateRange,
          author: 'Cinema360',
          subject: 'Refund Summary Preview' + this.displayDateRange,
        },
        title: 'Refund Summary Preview' + this.displayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.displayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Refund Summary Preview - ' + this.displayDateRange,
      },
    ],
  };

  this.dtOptionsCash = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    //"dom": "<'row'<'col-sm-3'f><'col-sm-9'B>>" +
    //  "<'row'<'col-sm-12'rtip>>",
    "dom": "Bfrtip",
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Cash Summary Preview',
        title: 'Cash Summary Preview' + this.displayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Cash Summary Preview',
        title: 'Cash Summary Preview' + this.displayDateRange
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Cash Summary Preview' + this.displayDateRange,
          author: 'Cinema360',
          subject: 'Cash Summary Preview' + this.displayDateRange,
        },
        title: 'Cash Summary Preview' + this.displayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.displayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Cash Summary - ' + this.displayDateRange,
      },
    ],
  };

  this.dtOptionsMovie = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    "dom": "Bfrtip",
    "sorting": false, // disable sorting
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Movie Preview',
        title: 'Movie Preview' + this.moviesDisplayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Movie Preview',
        title: 'Movie Preview' + this.moviesDisplayDateRange
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Movie Preview' + this.moviesDisplayDateRange,
          author: 'Cinema360',
          subject: 'Movie Preview' + this.moviesDisplayDateRange,
        },
        title: 'Movie Preview ' + this.moviesDisplayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.moviesDisplayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Movie - ' + this.moviesDisplayDateRange,
      },
    ],
  };
}

setDtOptMovies() {
  this.dtOptionsMovie = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    "dom": "Bfrtip",
    "sorting": false, // disable sorting
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Movie Preview',
        title: 'Movie Preview' + this.moviesDisplayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Movie Preview',
        title: 'Movie Preview' + this.moviesDisplayDateRange
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Movie Preview' + this.moviesDisplayDateRange,
          author: 'Cinema360',
          subject: 'Movie Preview' + this.moviesDisplayDateRange,
        },
        title: 'Movie ' + this.moviesDisplayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.moviesDisplayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Movie Preview - ' + this.moviesDisplayDateRange,
      },
    ],
  };
}

setDtOptConcession() {
  this.dtOptionsConcession = {
    "pageLength": 100,
    "lengthMenu": [[50, 100, 150, -1], [50, 100, 150, "All"]],
    "dom": "Bfrtip",
    "sorting": false, // disable sorting
    "buttons": [
      {
        extend: 'excel',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          rows: {
            selector: 'tbody tr:not(.no_export)'
          }
        },
        autoFilter: true,
        sheetName: 'Concession Preview ',
        title: 'Concession Preview' + this.concessionDisplayDateRange
      },
      {
        extend: 'csv',
        messageTop: 'The information in this table is copyright to Cinema360',
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        autoFilter: true,
        fileName: 'Concession Preview',
        title: 'Concession Preview' + this.concessionDisplayDateRange
      },
      {
        extend: 'pdf',
        orientation: 'portrait',
        pageSize: 'LEGAL',
        messageBottom: 'The information in this table is copyright to Cinema360',
        footer: true,
        //download: 'open',
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        info: {
          title: 'Concession Preview' + this.concessionDisplayDateRange,
          author: 'Cinema360',
          subject: 'Concession Preview' + this.concessionDisplayDateRange,
        },
        title: 'Concession Preview' + this.concessionDisplayDateRange
      },
      {
        extend: 'print',
        messageBottom: 'The information in this table is copyright to Cinema360',
        message: 'Report generated from ' + this.concessionDisplayDateRange,
        footer: true,
        exportOptions: {
          columns: [':visible:not(.no_export)']
        },
        customize: function (win) {
          $(win.document.body)
            .css('font-size', '10pt');
          $(win.document.body).find('table')
            .addClass('compact')
            .css('font-size', 'inherit');
        },
        title: 'Concession Preview - ' + this.concessionDisplayDateRange,
      },
    ],
  };
}


setChartSliceVisible(arr: any) {
  let myArray = arr;
  //const sum = myArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  const sum = myArray.reduce((accumulator, currentValue) => {
    if (currentValue > 0) {
      return accumulator + currentValue;
    } else {
      return accumulator;
    }
  }, 0);
  const twoPercent = sum * 0.02;
  myArray.forEach((item, index) => {
    if (item > 0) {
      myArray[index] = item + twoPercent;
    }
  });
  return myArray
}

labelFunction(label: any, number: any) {
  number = Number(number).toLocaleString('en-US')
  return '<span style="text-align: left; display: inline-block; width: 58px;">' +
    label +
    '</span><span style="text-align: right; display: inline-block; width: 60px; padding-left: 5px"><strong>$' +
    number + '</strong></span>'
}
labelFunctionMovie(label: any, number: any) {
  label = label.length > 11 ? label.substr(0, 11) + '..' : label;
  number = Number(number).toLocaleString('en-US')
  return '<span style="text-align: left; display: inline-block; width: 58px;">' +
    label +
    '</span><span style="text-align: right; display: inline-block; width: 60px; padding-left: 5px"><strong>$' +
    number + '</strong></span>'
}

getSumCCCashGC(arr: any) {
  let data = arr
  let res = []
  res.push(data.reduce((acc: any, curr: { TotalCashRefundAmount: any; }) => acc + curr.TotalCashRefundAmount, 0))
  res.push(data.reduce((acc: any, curr: { TotalCCRefundAmount: any; }) => acc + curr.TotalCCRefundAmount, 0))
  res.push(data.reduce((acc: any, curr: { TotalGCRefundAmount: any; }) => acc + curr.TotalGCRefundAmount, 0))
  return res
}
getSumTotalQTYRefundAmt(arr: any) {
  let data = arr
  let res = []
  res.push(data.reduce((acc: any, curr: { TotalQty: any; }) => acc + curr.TotalQty, 0))
  res.push(data.reduce((acc: any, curr: { TotalRefundAmount: any; }) => acc + curr.TotalRefundAmount, 0))
  return res
}
getSumCashExpectedDepositedDifference(arr: any) {
  let data = arr
  let res = []
  let shiftsFinalized = data.reduce((acc: any, curr: { TotalFinilizedShiftCount: any; }) => acc + curr.TotalFinilizedShiftCount, 0)
  let cashDeposited = data.reduce((acc: any, curr: { TotalCashDepoShift: any; }) => acc + curr.TotalCashDepoShift, 0)
  let cashDiff = data.reduce((acc: any, curr: { TotalDiffCash: any; }) => acc + curr.TotalDiffCash, 0)
  let expectedCash = data.reduce((acc: any, curr: { TotalOpeningBalShift: any; }) => acc + curr.TotalOpeningBalShift, 0)
  this.cashTileArrTotal.shiftsFinalized = shiftsFinalized
  //this.cashTileArrTotal.cashExpected = expectedCash
  //this.cashTileArrTotal.cashDeposited = cashDeposited
  this.cashTileArrTotal.cashExpected = expectedCash + cashDeposited
  this.cashTileArrTotal.cashDeposited = expectedCash + cashDeposited + cashDiff
  this.cashTileArrTotal.cashDifference = cashDiff
  res.push(expectedCash + cashDeposited)
  res.push(expectedCash + cashDeposited + cashDiff)
  res.push(cashDiff)
  this.items[2].total = shiftsFinalized
  return res
}
validateDateSelection() {
  this.error = '';
  if (this.selectedToDate && this.selectedFromDate) {
    if (moment(this.selectedFromDate) > moment(this.selectedToDate)) {
      this.error = this.es.adminErrorMsgList[78].message;
    }
  }
}
setFromDate(event) {
  this.distTable = false;
  AddHelperMethods.enDateFormat(event);
  this.validateDateSelection();
}

setToDate(event) {
  this.distTable = false;
  if (AddHelperMethods.enDateFormat(event) > this.selectedFromDate) {
    this.selectedToDate = AddHelperMethods.enDateFormat(event);
  }
  this.validateDateSelection();
}
////////////////////
//Movies(4th tile)//
getMovieSummaryGrid() {
  this.setDtOptMovies()
  let obj = {
    FromDate: this.movieStartDate,
    ToDate: this.movieEndDate,
    CompanyId: this.companyId,
    LocationId: this.selectedLocation.length == 0 ? null : this.selectedLocation.map(l => l.Location_ID)
  }
  this.companyAdminService.GetMovieSummaryGridV2(obj).subscribe((result) => {
    console.log(result)
    let topThree = result.slice(0, 3)
    this.MovieList = topThree
    this.movieTotal()
    this.loadingMovie = false

    this.dtTriggerMovie.next();
  }, error => {
    console.log(error)
  })
}

movieTotal() {
  const cashCollectionTotal = this.MovieList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.CashCollection;
    });
    return acc;
  }, 0);
  const CCCollectionTotal = this.MovieList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.CCCollection;
    });
    return acc;
  }, 0);
  const GCCollectionTotal = this.MovieList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.GCCollection;
    });
    return acc;
  }, 0);
  const ThirdPartyCollectionTotal = this.MovieList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.ThirdPartyCollection;
    });
    return acc;
  }, 0);
  const TotalCollection = this.MovieList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.TotalCollection;
    });
    return acc;
  }, 0);

  this.items[3].total = TotalCollection.toFixed(2)
  let obj = {
    cashCollectionTotal: cashCollectionTotal,
    CCCollectionTotal: CCCollectionTotal,
    GCCollectionTotal: GCCollectionTotal,
    ThirdPartyCollectionTotal: ThirdPartyCollectionTotal,
    TotalCollection: TotalCollection,
  }
  this.movieTotalObj = obj
  this.movieDonutData()
}

movieDonutData() {
  const movieNumbers = this.MovieList.map(item => item.TotalCollection)
  const movieLabels = this.MovieList.map(item => item.MovieName)

  let movieLegends = []
  for (let i = 0; i < movieNumbers.length; i++) {
    movieLegends.push(this.labelFunctionMovie(movieLabels[i], movieNumbers[i].toFixed(2).toString()))
  }
  this.items[3].numbers = this.setChartSliceVisible(movieNumbers)
  this.items[3].labels = movieLegends
  if (this.items[3].numbers.length == 0) {
    this.items[3].numbers = [1, 1, 1]
    this.items[3].labels = [
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>',
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>',
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>'
    ]
  }
  ////////////////////////
  //Load concession grid//
  ////////////////////////
  //if (this.loadingConcession == true) {
  //  this.getConcessionSummaryGrid() //to load only oninit
  //}
}

movieFilter() {
  if (this.isMovieFilter == true) {
    return this.isMovieFilter = false
  }
  return this.isMovieFilter = true
}

LoadMovieDistributors() {
  this.locationMovieService.GetDistributors().subscribe((dist) => {
    this.Distributors = dist;
  }, error => {
    this.error = error;
  });
}

destroyRefundDTElement() {
  this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
    dtElement.dtInstance.then((dtInstance: any) => {
      //console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
      //Do your stuff here when the index or node.id matches your table e.g. dtInstance.destroy();
      if (dtInstance.table().node().id == 'MovieTile') {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        })
      }
    });
  });
}

setMovieSearchType(val) {
  this.SelectedMovieSearchType = val;
  this.loadingMovie = true;
  if (val == 'Today') {
    this.movieStartDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.movieEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.moviesDisplayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyRefundDTElement()

    this.getMovieSummaryGrid()
    this.isMovieFilter = false
  } else if (val == 'Week') {

    let lastFriday;
    if (new Date().getDay() == 5) {
      lastFriday = new Date();
    } else {
      let t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
      lastFriday = new Date();
      lastFriday.setDate(t);
    }

    this.movieStartDate = moment(new Date(lastFriday)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.movieEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.moviesDisplayDateRange = "(" + moment(new Date(lastFriday)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyRefundDTElement()
    this.getMovieSummaryGrid()
    this.isMovieFilter = false
  } else if (val == 'Year') {
    let d = new Date(new Date().getFullYear(), 0, 1);
    this.movieStartDate = moment(new Date(d)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.movieEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.moviesDisplayDateRange = "(" + moment(new Date(d)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyRefundDTElement()
    this.getMovieSummaryGrid()
    this.isMovieFilter = false
  }
  else if (val == 'Daterange') {
    this.loadingMovie = false;
    this.moviesDisplayDateRange = '';
    this.selectedMovieFromDate = undefined;
    this.selectedMovieToDate = undefined;
  }
}

generateMovieReportByDaterange() {
  if (this.selectedMovieFromDate != null && this.selectedMovieToDate != null && !this.error) {
    this.loadingMovie = true;
    this.movieStartDate = moment(new Date(this.selectedMovieFromDate)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.movieEndDate = moment(new Date(this.selectedMovieToDate)).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.moviesDisplayDateRange = "(" + moment(new Date(this.selectedMovieFromDate)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date(this.selectedMovieToDate)).format('ddd, MMM DD, YYYY') + ")";
    this.destroyRefundDTElement()
    this.getMovieSummaryGrid()
    this.isMovieFilter = false
  } else {
    this.error = 'Please search with valid date range .'
  }
}

validateMovieDateSelection() {
  this.error = '';
  if (this.selectedMovieToDate && this.selectedMovieFromDate) {
    if (moment(this.selectedMovieFromDate) > moment(this.selectedMovieToDate)) {
      this.error = this.es.adminErrorMsgList[78].message;
    }
  }
}
setMovieFromDate(event) {
  this.distTable = false;
  AddHelperMethods.enDateFormat(event);
  this.validateMovieDateSelection();
}

setMovieToDate(event) {
  this.distTable = false;
  if (AddHelperMethods.enDateFormat(event) > this.selectedMovieFromDate) {
    this.selectedMovieToDate = AddHelperMethods.enDateFormat(event);
  }
  this.validateMovieDateSelection();
}

///concession///

getConcessionSummaryGrid() {
  this.setDtOptConcession()
  let obj = {
    FromDate: this.concessionStartDate,
    ToDate: this.concessionEndDate,
    CompanyId: this.companyId,
    LocationId: this.selectedLocation.length == 0 ? null : this.selectedLocation.map(l => l.Location_ID)
  }
  this.companyAdminService.GetConcessionSummaryGrid(obj).subscribe((result) => {
    let topTen = result.slice(0, 10)
    this.concessionList = topTen
    this.concessionTotal()

    this.dtTriggerConcession.next();
  }, error => {
    console.log(error)
  })
}

concessionTotal() {
  const cashCollectionTotal = this.concessionList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.CashCollection;
    });
    return acc;
  }, 0);
  const CCCollectionTotal = this.concessionList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.CCCollection;
    });
    return acc;
  }, 0);
  const GCCollectionTotal = this.concessionList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.GCCollection;
    });
    return acc;
  }, 0);
  const ThirdPartyCollectionTotal = this.concessionList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.ThirdPartyCollection;
    });
    return acc;
  }, 0);
  const TotalCollection = this.concessionList.reduce((acc, obj) => {
    obj.CollectionAtLocation.forEach(location => {
      acc += location.TotalCollection;
    });
    return acc;
  }, 0);

  this.items[4].total = TotalCollection.toFixed(2)
  let obj = {
    cashCollectionTotal: cashCollectionTotal,
    CCCollectionTotal: CCCollectionTotal,
    GCCollectionTotal: GCCollectionTotal,
    ThirdPartyCollectionTotal: ThirdPartyCollectionTotal,
    TotalCollection: TotalCollection,
  }
  this.concessionTotalObj = obj
  this.concessionPieData()
}

concessionPieData() {
  const concessionNumbers = this.concessionList.map(item => item.TotalCollection)
  const concessionLabels = this.concessionList.map(item => item.ConcessionName)

  let Legends = []
  for (let i = 0; i < concessionNumbers.length; i++) {
    Legends.push(this.labelFunctionMovie(concessionLabels[i], concessionNumbers[i].toFixed(2).toString()))
  }

  this.items[4].numbers = this.setChartSliceVisible(concessionNumbers)
  this.items[4].labels = Legends
  if (this.items[4].numbers.length == 0) {
    this.items[4].numbers = [1, 1, 1]
    this.items[4].labels = [
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>',
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>',
      '<span style="width:110px; color:transparent">WWWWWWWWWWW</span>',
    ]
  }
  this.loadingConcession = false
}

concessionFilter() {
  if (this.isConcessionFilter == true) {
    return this.isConcessionFilter = false
  }
  return this.isConcessionFilter = true
}
setConcessionSearchType(val) {
  this.SelectedConcessionSearchType = val;
  this.loadingConcession = true;
  if (val == 'Today') {
    this.concessionStartDate = moment(new Date()).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.concessionEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.concessionDisplayDateRange = "(" + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyConcessionDTElement()

    this.getConcessionSummaryGrid()
    this.isConcessionFilter = false
  } else if (val == 'Week') {

    let lastFriday;
    if (new Date().getDay() == 5) {
      lastFriday = new Date();
    } else {
      let t = new Date().getDate() + (6 - new Date().getDay() - 1) - 7;
      lastFriday = new Date();
      lastFriday.setDate(t);
    }

    this.concessionStartDate = moment(new Date(lastFriday)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.concessionEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.concessionDisplayDateRange = "(" + moment(new Date(lastFriday)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyConcessionDTElement()
    this.getConcessionSummaryGrid()
    this.isConcessionFilter = false
  } else if (val == 'Year') {
    let d = new Date(new Date().getFullYear(), 0, 1);
    this.concessionStartDate = moment(new Date(d)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.concessionEndDate = moment(new Date()).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.concessionDisplayDateRange = "(" + moment(new Date(d)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date()).format('ddd, MMM DD, YYYY') + ")";

    this.destroyConcessionDTElement()
    this.getConcessionSummaryGrid()
    this.isConcessionFilter = false
  }
  else if (val == 'Daterange') {
    this.loadingConcession = false;
    this.concessionDisplayDateRange = '';
    this.selectedConcessionFromDate = undefined;
    this.selectedConcessionToDate = undefined;
  }
}

destroyConcessionDTElement() {
  this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
    dtElement.dtInstance.then((dtInstance: any) => {
      //console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
      //Do your stuff here when the index or node.id matches your table e.g. dtInstance.destroy();
      if (dtInstance.table().node().id == 'ConcessionTile') {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        })
      }
    });
  });
}
generateConcessionReportByDaterange() {
  if (this.selectedConcessionFromDate != null && this.selectedConcessionToDate != null && !this.error) {
    this.loadingConcession = true;
    this.concessionStartDate = moment(new Date(this.selectedConcessionFromDate)).format("YYYY-MM-DDT00:00:00.000[Z]");
    this.concessionEndDate = moment(new Date(this.selectedConcessionToDate)).format("YYYY-MM-DDT23:59:59.999[Z]");
    this.concessionDisplayDateRange = "(" + moment(new Date(this.selectedConcessionFromDate)).format('ddd, MMM DD, YYYY') + '  to  ' + moment(new Date(this.selectedMovieToDate)).format('ddd, MMM DD, YYYY') + ")";
    this.destroyConcessionDTElement()
    this.getConcessionSummaryGrid()
    this.isConcessionFilter = false
  } else {
    this.error = 'Please search with valid date range .'
  }
}


LoadSalesChannels() {
  this.salesChannelService.getSalesChannelsByLocationID().subscribe(res => {
    this.SalesChannel = res;
    console.log(this.SalesChannel)
  }, err => this.error = err);
}

radioChange(event) {
  if (event.value === '1') {
    this.companyDashboard = true;
    this.router.navigate(['/companydashboard']);
  } else if (event.value === '0') {
    this.companyDashboard = false;
    this.router.navigate(['/companydashboardPreview']);
  }

}




}

