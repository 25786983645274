import { Component, OnInit } from '@angular/core';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kiosk-pickup',
  templateUrl: './kiosk-pickup.component.html',
  styleUrls: ['./kiosk-pickup.component.css']
})
export class KioskPickupComponent implements OnInit {

  constructor(public kioskPOSService: KioskPOSService, public router: Router) { }

  ngOnInit() {
  }

}
