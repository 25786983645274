import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, CanActivateChild, Router } from "@angular/router";
import { Observable } from "rxjs";
import { CurrentUserService } from "./helper/services/current-user.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})

export class AuthGuard implements CanActivate {
  LocationId: string = '';

  constructor(private router: Router, private currUserService: CurrentUserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // TODO : Must need to move all the location query string values and move to currentuser context claims settings. -- Naina
    var locationId = route.paramMap.get("Location_ID");
    if (undefined == locationId)
      locationId = route.paramMap.get("LocationId");
    // This is for the POS service which does not have the location in context.
    if (locationId == null && sessionStorage.getItem("location")) {
      locationId = JSON.parse(sessionStorage.getItem("location")).Location_ID;
    }
    if (null == locationId) {
      var adminFeatureName = state.url.split('/')[1];
      if (adminFeatureName == "companyAdmin") {
        return this.currUserService.getAdminAccess().pipe(map(hasAccess => {
          if (!hasAccess) {
            this.router.navigate(['/feature-denied']);
          }
          return hasAccess;
        }, error => {
          return false;
        }));
      }

      return this.currUserService.getCompanyFeature(this.getComponentName(adminFeatureName), 'read').pipe(map(hasAccess => {
        if (!hasAccess) {
          this.router.navigate(['/feature-denied']);
        }
        return hasAccess;
      }, error => {
        return false;
      }));
    }

    if (null != locationId) {
      var componentName = state.url.replace('/locations/setting/' + locationId + '/', '').split('/')[0];
      if (componentName == '') {
        componentName = state.url.split('/')[1];
        return this.currUserService.getCompanyFeature(this.getComponentName(componentName), 'read').pipe(map(hasAccess => {
          if (!hasAccess) {
            this.router.navigate(['/feature-denied']);
          }
          return hasAccess;
        }, error => {
          return false;
        }));
      }
      else {
        return this.currUserService.getFeature(this.getComponentName(componentName), 'read').pipe(map(hasAccess => {
          if (!hasAccess) {
            var newUrl = state.url.substring(0, 43);
            this.router.navigate([newUrl + '/feature-denied']);
          }
          return hasAccess;
        }, error => {
          return false;
        }));
      }
    }
  }

  getComponentName(component: string): string {
    var returnValue: string;
    switch (component.toLowerCase()) {
      case 'screens':
        returnValue = 'Screen';
        break;
      case 'movies':
        returnValue = 'Movies';
        break;
      case 'locationmovies':
        returnValue = 'LocationMovie';
        break;
      case 'inventories':
        returnValue = 'Inventory';
        break;
      case 'schedule':
        returnValue = 'MovieSchedule';
        break;
      case 'express':
        returnValue = 'MovieSchedule';
        break;
      case 'ticket-type':
        returnValue = 'TicketType';
        break;
      case 'price-card':
        returnValue = 'PriceCard';
        break;
      case 'kiosk':
        returnValue = 'Kiosk';
        break;
      case 'pos':
        returnValue = 'POSAdmin';
        break;
      case 'food':
        returnValue = 'FoodItem';
        break;
      case 'concession':
        returnValue = 'Concession';
        break;
      case 'concessioncategory':
        returnValue = 'Concession';
        break;
      case 'tax':
        returnValue = 'Tax';
        break;
      case 'employee':
        returnValue = 'Employee';
        break;
      case 'employeerole':
        returnValue = 'EmployeeRole';
        break;
      case 'report':
        returnValue = 'Reports';
        break;
      case 'screen-setting':
        returnValue = 'ScreenSetting';
        break;
      case 'saleschannel':
        returnValue = 'SalesChannel';
        break;
      case 'employeefeatures':
        returnValue = 'EmployeeFeatures';
        break;
      case 'giftcards':
        returnValue = 'GiftCards';
        break;
      case 'employeeschedule':
        returnValue = 'EmployeeSchedule';
        break;
      case 'kiosk':
        returnValue = 'Kiosk';
        break;
      case 'pos-shift':
        returnValue = 'ShiftInformation';
        break;
      case 'kitchens':
        returnValue = 'Kitchen';
        break;
      case 'rentrak':
        returnValue = 'Rentrak';
        break;
      case 'swift':
        returnValue = 'Swift';
        break;
      case 'restaurant':
        returnValue = 'Restaurant';
        break;
      case 'lms':
        returnValue = 'LMS';
        break;
      case 'locations':
        returnValue = 'Locations';
        break;
      case '':
        returnValue = 'Locations';
        break;
      case 'merchants':
        returnValue = 'Merchants';
        break;
      case 'digitaldisplaylink':
        returnValue = 'DigitalDisplay';
        break;
      case 'adminrefund':
        returnValue = 'AdminRefund';
        break;
      case 'seat-group':
        returnValue = 'SeatGroup';
        break;
      case 'promote':
        returnValue = 'EmployeeFeatures';
        break;
      
    }
    return returnValue;
  }
}
