import { Injectable, Inject } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient, HttpHeaders, HttpBackend } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import * as moment from "moment";
import { SalesOrder, SalesOrderStatus, SalesOrderTicket } from "../helper/models/SalesOrder";
import { Pos } from "./pos";
import { PriceCard } from "../price-card/price-card";
import { ApiService } from "../helper/services/api.service";
import { GiftCardSales } from "../giftcard/giftcardsales";
import {
  RoundAmount,
  TaxAmountCalculation,
} from "../helper/models/AddHelperModal";
import { PhoneHoldSeat } from "../helper/models/PhoneHoldSeat";
import { PlanOrder } from "../helper/models/PlanOrder";
import { SalesDiscount } from "../helper/models/SalesDiscount";

@Injectable({
  providedIn: "root",
})
export class POSService extends ApiService {
  ApiBaseUrl: string;
  pos: Pos[] = [];
  BaseUri: string;
  hold: number = 0;
  moment: any = moment;
  httpBackedurl: HttpClient;
  foodItemsInCartObservable = new BehaviorSubject([]);
  foodItemsInCart = this.foodItemsInCartObservable.asObservable();
  movieTicketObservable = new BehaviorSubject([]);
  movieTicket = this.movieTicketObservable.asObservable();
  totalPriceObservable = new BehaviorSubject(0);
  totalPrice = this.totalPriceObservable.asObservable();
  refundSalesOrderObject = new SalesOrder();
  refundSalesOrderArray = [];
  refundServiceAmount = 0;
  refundPrintSalesOrderAr: SalesOrder[] = [];
  isRefundShow = false;
  posSelectedItemCount: number;
  stripeSecretToken: string = "";
  isSwipeReaderExist = false;
  selectedPosList: string[] = [];
  constructor(
    @Inject("BASE_URL") baseUrl: string,
    private http: HttpClient,
    private backend: HttpBackend
  ) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/";
    this.BaseUri = baseUrl;
    this.httpBackedurl = new HttpClient(backend);
    this.posSelectedItemCount = 0;
    console.log("CONSTRUCTOR", this.posSelectedItemCount);
  }
  changeFoodItemsInCart(operation, foodItem, fiIndex = null) {
    console.log("totalPriceObservable1", foodItem, this.posSelectedItemCount);
    let existingFoodItems = [],
      totalPrice = 0;
    this.foodItemsInCart.subscribe((success) => (existingFoodItems = success));
    this.totalPrice.subscribe((success) => {
      totalPrice += success;
    });
    if (existingFoodItems.length > 0) {
      if (
        !existingFoodItems.find(
          (ef) => ef.ConcessionId === foodItem.ConcessionId
        )
      ) {
        foodItem.FinalPrice = foodItem.TotalPrice;
        foodItem.Quantity = 1;
        // foodItem.unitConcessionTaxAmount = RoundAmount(
        //   foodItem.TotalPrice * (foodItem.Tax / 100)
        // );
        foodItem.unitConcessionTaxAmount = RoundAmount(
          TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice)
        );
        // foodItem.taxAmount = RoundAmount(foodItem.FinalPrice * (foodItem.Tax / 100));
        foodItem.taxAmount = RoundAmount(
          TaxAmountCalculation(foodItem.Tax, foodItem.FinalPrice)
        );
        existingFoodItems.push(foodItem);
        this.posSelectedItemCount++;
        this.foodItemsInCartObservable.next([...existingFoodItems]);
        totalPrice += foodItem.FinalPrice;
        // totalPrice = RoundAmount(totalPrice + (foodItem.FinalPrice + RoundAmount((foodItem.TotalPrice * (foodItem.Tax / 100)))));
      } else {
        let item = fiIndex != null ? fiIndex : existingFoodItems.findIndex(
          (f) => f.ConcessionId === foodItem.ConcessionId
        );
        if (operation === "plus") {
          existingFoodItems[item].Quantity++;
          this.posSelectedItemCount++;
          totalPrice += foodItem.TotalPrice;
          existingFoodItems[item].FinalPrice =
            existingFoodItems[item].FinalPrice + foodItem.TotalPrice;
          // foodItem.taxAmount = RoundAmount(
          //   foodItem.taxAmount +
          //     RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100))
          // );
          // foodItem.unitConcessionTaxAmount = RoundAmount(
          //   foodItem.TotalPrice * (foodItem.Tax / 100)
          // );
          foodItem.taxAmount =
            foodItem.taxAmount +
            RoundAmount(
              TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice)
            );
          foodItem.unitConcessionTaxAmount = RoundAmount(
            TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice)
          );
          //           foodItem.taxAmount = RoundAmount(foodItem.FinalPrice * (foodItem.Tax / 100));
          // console.log("foodItem.TotalPrice",foodItem.TotalPrice);
          // console.log("foodItem.FinalPrice",foodItem.FinalPrice);
          // totalPrice = RoundAmount(totalPrice + (foodItem.TotalPrice + RoundAmount((foodItem.TotalPrice * (foodItem.Tax / 100)))));
        } else {
          existingFoodItems[item].Quantity--;
          this.posSelectedItemCount = this.posSelectedItemCount - 1;
          totalPrice -= foodItem.TotalPrice;
          // totalPrice = RoundAmount(totalPrice - (foodItem.TotalPrice + RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100))));
          existingFoodItems[item].FinalPrice = RoundAmount(
            existingFoodItems[item].FinalPrice - foodItem.TotalPrice
          );

          // foodItem.taxAmount = RoundAmount(
          //   foodItem.taxAmount -
          //     RoundAmount(foodItem.TotalPrice * (foodItem.Tax / 100))
          // );
          foodItem.taxAmount =
            foodItem.taxAmount -
            RoundAmount(
              TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice)
            );
        }

        if (existingFoodItems[item].Quantity === 0) {
          existingFoodItems.splice(item, 1);
        }
      }
      this.totalPriceObservable.next(totalPrice);
    } else {
      foodItem.FinalPrice = foodItem.TotalPrice;
      foodItem.Quantity = 1;
      this.foodItemsInCartObservable.next([foodItem]);
      totalPrice += foodItem.FinalPrice;
      // foodItem.unitConcessionTaxAmount = RoundAmount(
      //   foodItem.TotalPrice * (foodItem.Tax / 100)
      // );
      // foodItem.taxAmount = RoundAmount(
      //   foodItem.FinalPrice * (foodItem.Tax / 100)
      // );
      foodItem.unitConcessionTaxAmount = RoundAmount(
        TaxAmountCalculation(foodItem.Tax, foodItem.TotalPrice)
      );
      foodItem.taxAmount = RoundAmount(
        TaxAmountCalculation(foodItem.Tax, foodItem.FinalPrice)
      );
      // totalPrice = RoundAmount(totalPrice + (foodItem.FinalPrice + RoundAmount((foodItem.TotalPrice * (foodItem.Tax / 100)))));
      this.posSelectedItemCount++;
      this.totalPriceObservable.next(totalPrice);
    }
  }

  manageMovieTicket(operation = "plus", movieTicket, index) {
    console.log("movieTicketmanageMovieTicket", this.posSelectedItemCount);
    let existingBookings = [],
      totalPrice = 0;
    this.movieTicket.subscribe((success) => {
      existingBookings = success;
    });
    this.totalPrice.subscribe((success) => {
      totalPrice += success;
    });
    if (existingBookings.length > 0) {
      if (operation === "plus") {
        this.movieTicketObservable.next([...existingBookings, movieTicket]);
        totalPrice += movieTicket.totalAmount;
        var getAddCharges = movieTicket.priceCard.AdditionalCharges.filter(x => x.IncludeTicket == false);
        if (getAddCharges?.length > 0) {
          getAddCharges.forEach((x) => {
            totalPrice += (x.AdditionalPrice * movieTicket.totalSeat);
          });
        }
        // totalPrice += movieTicket.totalAmount + movieTicket.taxAmount;
        this.totalPriceObservable.next(totalPrice);
        this.posSelectedItemCount =
          this.posSelectedItemCount + movieTicket.totalSeat;
      } else {
        var getAddCharges = movieTicket.priceCard.AdditionalCharges.filter(x => x.IncludeTicket == false);
        // tslint:disable-next-line:prefer-const
        // let index = existingBookings.findIndex(eb => (eb.LocationMovieID === movieTicket.LocationMovieID && eb.holdSeatIDS[0] == movieTicket.holdSeatIDS[0]));
        // totalPrice -= movieTicket.totalAmount + movieTicket.taxAmount;
        // let index = existingBookings.findIndex(eb => (eb.LocationMovieID === movieTicket.LocationMovieID && eb.holdSeatIDS[0] == movieTicket.holdSeatIDS[0]));
        if (getAddCharges?.length > 0) {
          getAddCharges.forEach((x) => {
            totalPrice -= (x.AdditionalPrice * movieTicket.totalSeat);
          });
        }
        totalPrice -= movieTicket.totalAmount;
        this.posSelectedItemCount =
          this.posSelectedItemCount - movieTicket.totalSeat;
        existingBookings.splice(index, 1);
        this.movieTicketObservable.next(existingBookings);
        this.totalPriceObservable.next(totalPrice);
      }
    } else {
      var getAddCharges = movieTicket.priceCard.AdditionalCharges.filter(x => x.IncludeTicket == false);
      // totalPrice += movieTicket.totalAmount + movieTicket.taxAmount;
      if (getAddCharges?.length > 0) {
        getAddCharges.forEach((x) => {
          totalPrice += (x.AdditionalPrice * movieTicket.totalSeat);
        });
      }
      totalPrice += movieTicket.totalAmount;
      this.posSelectedItemCount =
        this.posSelectedItemCount + movieTicket.totalSeat;
      this.movieTicketObservable.next([movieTicket]);
      this.totalPriceObservable.next(totalPrice);
    }
  }

  //getSalesOrderByLocationID(locationID) {
  //  return this.http
  //    .get(this.ApiBaseUrl + "SalesOrder/GetByLocationId/" + locationID)
  //    .pipe(catchError(this.errorHandler));
  //}

  getSalesOrderByShowID(showID) {
    return this.http
      .get<Array<SalesOrder>>(
        this.ApiBaseUrl + "SalesOrder/GetByShowId/" + showID
      )
      .pipe(catchError(this.errorHandler));
  }

  getHoldSeatsByShowId(showID) {
    return this.http
      .get("/api/HoldSeats/GetHoldSeatsByShowId/" + showID)
      .pipe(catchError(this.errorHandler));
  }

  postHoldSeats(holdTickets): Observable<Array<Object>> {
    return this.http
      .post<Array<Object>>("/api/HoldSeats", holdTickets)
      .pipe(catchError(this.errorHandler));
  }

  postGetSeatsCounts(MovieIds): Observable<Array<String>> {
    return this.httpBackedurl
      .post<Array<String>>("/api/SalesOrder/GetShowTicketCount", MovieIds)
      .pipe(catchError(this.errorHandler));
  }

  async addGiftCardSales(item: GiftCardSales): Promise<any> {
    return await this.httpBackedurl
      .post<GiftCardSales>(this.ApiBaseUrl + "GiftCardSales", item)
      .pipe()
      .toPromise();
  }
  purchaseGiftcards(giftcards): Observable<Array<Object>> {
    return this.http
      .post<Array<Object>>(this.ApiBaseUrl + "GiftCardSales/PostMultipleGiftCardSales", giftcards)
      .pipe(catchError(this.errorHandler));
  }
  postSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }
  updatePOSSalesOrderById(salesOrderId: string): Observable<SalesOrder> {
    return this.httpBackedurl
      .get<SalesOrder>("/api/SalesOrder/UpdatePOSSalesOrderById/" + salesOrderId , {})
      .pipe(catchError(this.errorHandler));
  }
  FinalizeSaleOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder/FinalizeSaleOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }
  VoidPartialSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .patch<SalesOrder>("/api/SalesOrder/VoidPartialSalesOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }
  
  cancelSales(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder/CancelSales", salesOrder)
      .pipe(catchError(this.errorHandler));
  }
  SplitByItemChangeStatusFailed(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder/SplitByItemChangeStatusFailed", salesOrder)
      .pipe(catchError(this.errorHandler));
  }

  patchSalesOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.httpBackedurl
      .patch<SalesOrder>("/api/SalesOrder", salesOrder)
      .pipe(catchError(this.errorHandler));
  }

  postPhoneHoldSeats(holdTickets): Observable<Array<Object>> {
    return this.http
      .post<Array<Object>>(
        "/api/PhoneHoldSeat/InsertPhoneHoldSeats",
        holdTickets
      )
      .pipe(catchError(this.errorHandler));
  }

  getPhoneHoldSeatsByScreenId(screenId) {
    return this.http
      .get<PhoneHoldSeat>(
        this.ApiBaseUrl +
        "PhoneHoldSeat/GetPhoneHoldSeatsByScreenId/" +
        screenId
      )
      .pipe(catchError(this.errorHandler));
  }

  getPhoneHoldSeatsByShowIds(showIds): Observable<PhoneHoldSeat> {
    return this.http
      .post<PhoneHoldSeat>(
        this.ApiBaseUrl + "PhoneHoldSeat/GetPhoneHoldSeatsByShowIds/",
        showIds
      )
      .pipe(catchError(this.errorHandler));
  }

  // getPhoneHoldSeatsByShowIds(showIds) {
  // 	return this.http.post<PhoneHoldSeat>(this.ApiBaseUrl + 'PhoneHoldSeat/GetPhoneHoldSeatsByShowIds/', showIds).pipe(catchError(this.errorHandler));
  // }

  removePhoneHoldSeatsByScreenIdAndSeatNos(
    phoneHoldSeatScreen
  ): Observable<PhoneHoldSeat> {
    return this.http
      .post<PhoneHoldSeat>(
        this.ApiBaseUrl +
        "PhoneHoldSeat/RemovePhoneHoldSeatsByScreenIdAndSeatNos/",
        phoneHoldSeatScreen
      )
      .pipe(catchError(this.errorHandler));
  }

  removePhoneHoldSeatById(phoneHoldSeatScreen): Observable<PhoneHoldSeat> {
    return this.http
      .post<PhoneHoldSeat>(
        this.ApiBaseUrl + "PhoneHoldSeat/RemovePhoneHoldSeatById/",
        phoneHoldSeatScreen
      )
      .pipe(catchError(this.errorHandler));
  }
  getCompany(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "Company/GetCompanyByCompanyId")
      .pipe(catchError(this.errorHandler));
  }

  deleteHoldSeats(holdTickets) {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: holdTickets,
    };
    return this.httpBackedurl
      .delete("/api/HoldSeats", options)
      .pipe(catchError(this.errorHandler));
  }

  placeOrder(postData) {
    return this.http
      .post(this.ApiBaseUrl + "SalesOrder", postData)
      .pipe(catchError(this.errorHandler));
  }

  async swapSeat(
    confirmationCode,
    seatNumber,
    newTicket: SalesOrderTicket
  ): Promise<SalesOrder> {
    return await this.httpBackedurl
      .patch<SalesOrder>(
        this.ApiBaseUrl +
        "SalesOrder/ChangeTicketSeats/" +
        confirmationCode +
        "/" +
        seatNumber,
        newTicket
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  async changeTicketStatus(
    ID: String,
    seatnumber: String,
    status: number
  ): Promise<any> {
    return this.http
      .patch<SalesOrder>(
        this.ApiBaseUrl +
        "SalesOrder/ChangeTicketStatus/" +
        ID +
        "/" +
        seatnumber +
        "/" +
        status,
        null
      )
      .toPromise()
      .catch(this.errorHandler);
  }

  refundSalesOrder(
    salesOrderObject: Array<SalesOrder>,
    methodname
  ): Observable<Array<SalesOrder>> {
    return this.http
      .patch<Array<SalesOrder>>(
        this.ApiBaseUrl + "SalesOrder/" + methodname,
        salesOrderObject
      )
      .pipe(catchError(this.errorHandler));
  }
  refundParkOrder(
    salesOrderObject: Array<SalesOrder>,
    methodname
  ): Observable<Array<SalesOrder>> {
    return this.http
      .patch<Array<SalesOrder>>(
        this.ApiBaseUrl + "ParkingLotOrder/" + methodname,
        salesOrderObject
      )
      .pipe(catchError(this.errorHandler));
  }
  /**
   * POS Operation
   */

  getPOSTerminal(): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "POS/GetByLocationId")
      .pipe(catchError(this.errorHandler));
  }

  getAllActivePOS(): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "POS/GetAllActivePOS")
      .pipe(catchError(this.errorHandler));
  }

  getPOSById(posId): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "POS/" + posId)
      .pipe(catchError(this.errorHandler));
  }
  setVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + "POS/SetVisible/" + ID + "/" + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  addPOSTerminal(posData: Pos): Observable<Pos> {
    return this.http
      .post<Pos>(this.ApiBaseUrl + "POS", posData)
      .pipe(catchError(this.errorHandler));
  }

  updatePOSTerminal(updatedData: Pos): Observable<Pos> {
    return this.http
      .put<Pos>(this.ApiBaseUrl + "POS/"+updatedData.POSId, updatedData)
      .pipe(catchError(this.errorHandler));
  }

  updatePOSStatus(ID: string, status: any): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + "POS/SetStatus/" + ID + "/" + status, null)
      .pipe(catchError(this.errorHandler));
  }

  getEmpVerify(email: string, pin: string): Observable<any> {
    return this.http
      .get<any>(
        this.ApiBaseUrl + "Employee/PinVerification/" + email + "/" + pin
      )
      .pipe(catchError(this.errorHandler));
  }

  getPinVerify(locationID, pin) {
    console.log("locationID", locationID, this.ApiBaseUrl, pin);
    return this.http
      .get<any>(
        this.ApiBaseUrl +
        "Employee/CheckManagerWithPin/" +
        locationID +
        "/" +
        pin
      )
      .pipe(catchError(this.errorHandler));
  }

  // async getPinVerify(locationID, pin): Promise<any> {
  // 	console.log("locationID", locationID, this.ApiBaseUrl, pin)
  // 	return await this.http.get<any>(this.ApiBaseUrl + 'Employee/CheckManagerWithPin/' + locationID + '/' + pin).pipe(catchError(this.errorHandler));
  // }

  fetchConnectionToken(): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "SalesOrder/GetStripeConnection")
      .pipe(catchError(this.errorHandler));
  }

  getKioskUrl() {
    return this.http
      .get(this.ApiBaseUrl + "POS/GetKioskUrl")
      .pipe(catchError(this.errorHandler));
  }
  updatePosRegisterStatus(posId, flag): Observable<SalesOrder> {
    return this.httpBackedurl
      .patch<SalesOrder>("/api/POSAdmin/SetRegisteredFlag/" + posId + '/' + flag, {})
      .pipe(catchError(this.errorHandler));
  }
  checkLoyaltyMember(MemberId,Companyid): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "Location/GetMemberByPhoneEmailMemberNumber/"+MemberId.toLowerCase() +'/'+Companyid)
      .pipe(catchError(this.errorHandler));
  }
  AddMemberRewards(MemberId,credit,Companyid): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "Location/AddMemberRewards/"+MemberId+'/'+credit+'/'+Companyid)
      .pipe(catchError(this.errorHandler));
  }
  AddLoyaltyOrder(loyaltyOrder:any): Observable<any> {
    return this.http
      .post<any>(this.ApiBaseUrl + "Location/AddLoyaltyOrder",loyaltyOrder)
      .pipe(catchError(this.errorHandler));
  }
  RemoveMemberRewards(MemberId, credit, Companyid): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "Location/RemoveMemberRewards/" + MemberId + '/' + credit + '/' + Companyid)
      .pipe(catchError(this.errorHandler));
  }
  GetLoyaltyOrderBySalesOrderId(salesorderId): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + "Location/GetLoyaltyOrderBySalesOrderId/" + salesorderId)
      .pipe(catchError(this.errorHandler));
  }
  PostSalesDiscount(salesDiscount: SalesDiscount,locId,userEmail): Observable<SalesDiscount> {
    return this.httpBackedurl
      .post<SalesDiscount>("/api/SalesOrder/PostSalesDiscount/"+locId+"/"+userEmail, salesDiscount)
      .pipe(catchError(this.errorHandler));
  }
  ConfirmPaymentSaleOrder(salesOrder: SalesOrder): Observable<SalesOrder> {
    // string salesOrderId, string transactiontId, string ccLastfour, bool paymentSuccess    
    salesOrder.PaymentID = salesOrder.PaymentID ? salesOrder.PaymentID : null;
    salesOrder.CCLastFour = salesOrder.CCLastFour ? salesOrder.CCLastFour : null;
    return this.httpBackedurl
      .patch<SalesOrder>("/api/SalesOrder/ConfirmAuthPayment/" + salesOrder.ConfirmationCode + '/' + salesOrder.PaymentID + '/' + salesOrder.CCLastFour + '/true', {})
      .pipe(catchError(this.errorHandler));
  }
  
  UpdatePenddingSalesOrder(salesOrder: SalesOrder ,OrderStatus:SalesOrderStatus,locationId,userEmail): Observable<SalesOrder> {
    return this.httpBackedurl
      .post<SalesOrder>("/api/SalesOrder/UpdatePenddingSalesOrder/"+locationId+"/"+OrderStatus+"/"+userEmail, salesOrder)
      .pipe(catchError(this.errorHandler));
  }
  UpdatePlanPOS(CompId:string, ID: string, POS: string[]): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + "POS/UpdatePlanPOS/" + CompId + "/" + ID, POS)
      .pipe(catchError(this.errorHandler));
  }
 WorldpayrefundbytransactionId(wordpaytransaction
  ): Observable<any> {
    return this.http
      .post<any>(
        this.ApiBaseUrl + "SalesOrder/WorlpayRefundByTransactionId"  ,
        wordpaytransaction
      )
      .pipe(catchError(this.errorHandler));
  }
  getInternalIdByPaymentId(paymentId: string): Observable<any> {
    return this.http
      .get<any>(
        this.ApiBaseUrl + "SalesOrder/GetMerchantInternalIdByPaymentId/" + paymentId)
      .pipe(catchError(this.errorHandler));
  }

}

export class Booking {
  bookingDate: Date;
  bookingDisplayDate: Date | String;
  showTime: string;
  priceCard: Object = new Object();
  screen: string = "";
  screenName: string = "";
  blockedSeats: Array<string> = new Array<string>();
  ShowId: string;
  priceCardClone: PriceCard = new PriceCard();
  selectedSeat: Array<string> = new Array<string>();
  holdSeatIDS: Array<string> = new Array<string>();
  totalSeat: number = 0;
  isCombo: boolean;
  Complimentary: boolean;
  totalAmount: number;
  Total_Available_Seats: number;
  taxAmount: number = 0;
  unitConcessionTaxAmount: number = 0;
  ShowProperties: any;
}
