import { ViewportScroller } from '@angular/common';
import { Component, ViewEncapsulation ,OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { StyleService } from '../helper/services/docloadings/style.service';
import { LocationsService } from '../locations/locations.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import VanillaTilt from "vanilla-tilt";


declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  useremail:any="";
  @ViewChild('SubscribeForm', { static: true }) SubscribeForm: NgForm;

  constructor(private el: ElementRef,private toastrService: ToastrService,private router: Router,private stSer: StyleService,private viewportScroller: ViewportScroller,public localtionService: LocationsService) {
    this.stSer.addStyle('bt', require('../../assets/css/bootstraplanding.min.css'));
    this.stSer.addStyle('bt', require('../../assets/css/all.min.css'));
    this.stSer.addStyle('all', require('../../assets/css/jquery.fancybox.min.css'));
    this.stSer.addStyle('all', require('../../assets/bootstrap-icons-1.9.1/bootstrap-icons.css'));

  }
  ngOnInit() {
    VanillaTilt.init(
      this.el.nativeElement.querySelectorAll(".VanillaTilt"), { max: 20, speed: 300, scale: 1.05 }
    );
  }
  VideoClose(){
    var lightBoxVideo = $("#VisaChipCardVideo");
    window.scrollTo(0, 0);
    $('#light').css('display','none')
    $('#fade').css('display' , 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpen(event) {
    var lightBoxVideo = $("#VisaChipCardVideo");
    window.scrollTo(0, 0);
    $('#light').css('display','block')
    $('#fade').css('display' , 'block');
    lightBoxVideo[0].play();
  }
  VideoCloseDashboard() {
    var lightBoxVideo = $("#Dashboard");
    window.scrollTo(0, 0);
    $('#lightDashboard').css('display', 'none')
    $('#fadeDashboard').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenDashboard() {
    var lightBoxVideo = $("#Dashboard");
    window.scrollTo(0, 0);
    $('#lightDashboard').css('display', 'block')
    $('#fadeDashboard').css('display', 'block');
    lightBoxVideo[0].play();
  }
  VideoCloseMovieSchedule() {
    var lightBoxVideo = $("#MovieSchedule");
    window.scrollTo(0, 0);
    $('#lightMovieSchedule').css('display', 'none')
    $('#fadeMovieSchedule').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenMovieSchedule() {
    var lightBoxVideo = $("#MovieSchedule");
    window.scrollTo(0, 0);
    $('#lightMovieSchedule').css('display', 'block')
    $('#fadeMovieSchedule').css('display', 'block');
    lightBoxVideo[0].play();
  }
  VideoCloseDAtaAnalysis() {
    var lightBoxVideo = $("#DAtaAnalysis");
    window.scrollTo(0, 0);
    $('#lightDAtaAnalysis').css('display', 'none')
    $('#fadeDAtaAnalysis').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenDAtaAnalysis() {
    var lightBoxVideo = $("#DAtaAnalysis");
    window.scrollTo(0, 0);
    $('#lightDAtaAnalysis').css('display', 'block')
    $('#fadeDAtaAnalysis').css('display', 'block');
    lightBoxVideo[0].play();
  }
  VideoCloseEmpSch() {
    var lightBoxVideo = $("#EmpSch");
    window.scrollTo(0, 0);
    $('#lightEmpSch').css('display', 'none')
    $('#fadeEmpSch').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenEmpSch() {
    var lightBoxVideo = $("#EmpSch");
    window.scrollTo(0, 0);
    $('#lightEmpSch').css('display', 'block')
    $('#fadeEmpSch').css('display', 'block');
    lightBoxVideo[0].play();
  }
  VideoCloseLocation() {
    var lightBoxVideo = $("#Location");
    window.scrollTo(0, 0);
    $('#lightLocation').css('display', 'none')
    $('#fadeLocation').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenLocation() {
    var lightBoxVideo = $("#Location");
    window.scrollTo(0, 0);
    $('#lightLocation').css('display', 'block')
    $('#fadeLocation').css('display', 'block');
    lightBoxVideo[0].play();
  }

  VideoCloseSales1() {
    var lightBoxVideo = $("#Sales1");
    window.scrollTo(0, 0);
    $('#lightSales1').css('display', 'none')
    $('#fadeSales1').css('display', 'none');
    lightBoxVideo[0].pause();
  }
  VideoOpenSales1() {
    var lightBoxVideo = $("#Sales1");
    window.scrollTo(0, 0);
    $('#lightSales1').css('display', 'block')
    $('#fadeSales1').css('display', 'block');
    lightBoxVideo[0].play();
  }



  



  MovieSchedule


  goToRegistrationPage() {
    this.router.navigate(['/registration']);
  }
  goToContactPage() {
    this.router.navigate(['/contact']);
    window.location.reload();
  }
  subscribeemail() {
    console.log("RES",this.useremail);
    if(!this.useremail  || this.useremail  == "" || this.useremail  ==null){
      return;
    }
    if (!this.SubscribeForm.form.valid) {
     return;
    }
    let bodyobj = {
      UserId :"",
      UserEmail:this.useremail
    }
    this.localtionService.postNewsletterUser(bodyobj).subscribe(res => {
      this.useremail = "";
      if(res && res.length >0){
        this.toastrService.success(
          ("Successfully subscribed to our Newsletter"),
          "Success"
        );
      }else{
        this.toastrService.warning(
          ("You are already subscribed to our newsletter") + "",
          ""
        );
       
      }
      console.log("RES",res);
    }, error => {
     console.log(error)
    });
  }
  public onClick(elementId: string): void { 
    this.viewportScroller.scrollToAnchor(elementId);
  }
}


