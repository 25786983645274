import { Injectable, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HttpClient,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CompanyRole } from '../../company/company-role';

@Injectable({
  providedIn: 'root'
})

export class CurrentUserService extends ApiService{
    isPosShift: boolean = false;
  ApiBaseUrl: string;
  BaseUri: string;
  curuser: Curuser = { DisplayName: '', CompanyId: '', Email: '' };
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/currrentuser';
    this.BaseUri = baseUrl;
  }

  get(): Observable<Curuser> {
    return this.http
      .get<Curuser>(this.ApiBaseUrl)
      .pipe(catchError(this.errorHandler));
  }

  getFeature(featureName: string, type: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/' + 'HasFeature' + '/' + featureName + '/' + type)
      .pipe(catchError(this.errorHandler));
  }

  getCompanyFeature(featureName: string, type: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/' + 'HasCompanyFeature' + '/' + featureName + '/' + type)
      .pipe(catchError(this.errorHandler));
  }

  getLocationIds(): Observable<string[]> {
    return this.http
      .get<string[]>(this.ApiBaseUrl + '/GetLocationIds')
      .pipe(catchError(this.errorHandler));
  }

  getAdminAccess(): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/' + 'getAdminAccess')
      .pipe(catchError(this.errorHandler));

  }

  getContextLocationName(): Observable<string> {
    return this.http
      .get<string>(this.ApiBaseUrl + '/' + 'GetContextLocationName')
      .pipe(catchError(this.errorHandler));

  }

  getContextLocationId(): Observable<string> {
    return this.http
      .get<string>(this.ApiBaseUrl + '/' + 'GetContextLocationId')
      .pipe(catchError(this.errorHandler));

  }

  getHelloWorldText(): Observable<string> {
    return this.http
      .get<string>(this.BaseUri + 'api/' + 'WeatherForecast')
      .pipe(catchError(this.errorHandler));

  }

  getLocationAccess(): Observable<LocationAccess[]> {
    return this.http
      .get<LocationAccess[]>(this.ApiBaseUrl + '/' + 'getLocationAccess')
      .pipe(catchError(this.errorHandler));
  }

  getreleasenotes(): Observable<any> {
    return this.http.get<any>(this.BaseUri + 'api/' + 'Release/GetAllReleaseNotes')
      .pipe(catchError(this.errorHandler));
  }

  getpromotemoviesAccess(): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/' + 'GetPromoteMoviesAccess')
      .pipe(catchError(this.errorHandler));

  }
  getWinAppUrl(): Observable<Blob> {
    return this.http
      .get<Blob>(`${this.ApiBaseUrl}/download-zip`, { responseType: 'blob' as 'json' })
      .pipe(catchError(this.errorHandler));
  }



}

interface Curuser {
  DisplayName: string;
  Email: string;
  CompanyId: string;
}

export class LocationAccess {
  LocationId: string = '';
  EditAccess: string = '';
}
