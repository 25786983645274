import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;
  constructor(private commonService: CommonService) {

    this.commonService.getInstrumentationKey().subscribe((instrumentationKey: string) => {
      console.log("Test App", instrumentationKey);
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey,
          enableAutoRouteTracking: true // option to log all route changes
        }
      });
      this.appInsights.loadAppInsights();
    });
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights) this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
