import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
} from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { Merchant, MerchantCredentials, MerchantLanes, MerchantMode, MerchantLaneMarketCode } from './merchant';

@Injectable({
  providedIn: 'root'
})

export class MerchantService extends ApiService {
  ApiBaseUrl: string;
  BaseUri: string;
  Merchants: Merchant[];


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Merchant';
    this.BaseUri = baseUrl;
  }

  getMerchants(): Observable<Merchant[]> {
    return this.http
      .get<Merchant[]>(this.ApiBaseUrl + '/GetAllMerchants')
      .pipe(catchError(this.errorHandler));
  }

  getMerchantCredentials(): Observable<MerchantCredentials[]> {
    return this.http
      .get<MerchantCredentials[]>(this.ApiBaseUrl + '/GetAllMerchantCredentials')
      .pipe(catchError(this.errorHandler));
  }

  getMerchantLanes(merchantCredentialId): Observable<MerchantLanes[]> {
    return this.http
      .get<MerchantLanes[]>(this.ApiBaseUrl + '/GetAllMerchantLanesByCredentialId/' + merchantCredentialId)
      .pipe(catchError(this.errorHandler));
  }

  getActiveMerchantLanes(merchantCredentialId): Observable<MerchantLanes[]> {
    return this.http
      .get<MerchantLanes[]>(this.ApiBaseUrl + '/GetAllActiveMerchantLanesByCredentialId/' + merchantCredentialId)
      .pipe(catchError(this.errorHandler));
  }

  getMerchantLaneMarketCode(): Observable<MerchantLaneMarketCode[]> {
    return this.http
      .get<MerchantLaneMarketCode[]>(this.ApiBaseUrl + '/GetMerchantLaneMarketCode/')
      .pipe(catchError(this.errorHandler));
  }

  getMerchantLanesBySalesChannel(merchantCredentialId, salesChannel: string): Observable<MerchantLanes[]> {
    return this.http
      .get<MerchantLanes[]>(this.ApiBaseUrl + '/GetAllMerchantLanesByCredentialIdAndBySalesChannel/' + merchantCredentialId + '/' + salesChannel)
      .pipe(catchError(this.errorHandler));
  }

  addMerchants(NewMerchants: Merchant): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/AddMerchant', NewMerchants)
      .pipe(catchError(this.errorHandler));
  }

  addMerchantCredentials(NewMerchant: MerchantCredentials): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/AddMerchantCredentials', NewMerchant)
      .pipe(catchError(this.errorHandler));
  }

  addMerchantLanes(NewMerchant: MerchantLanes): Promise<any> {
    return this.http
      .post(this.ApiBaseUrl + '/AddMerchantLanes', NewMerchant)
      .pipe(catchError(this.errorHandler)).toPromise();
  }

  getCompanyMerchant(): Observable<Merchant> {
    return this.http
      .get<Merchant>(this.ApiBaseUrl + '/GetCompanyMerchant')
      .pipe(catchError(this.errorHandler));
  }

  updateCompanyMerchant(merchants: Merchant): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/UpdateCompanyMerchant', merchants)
      .pipe(catchError(this.errorHandler));
  }

  updateMerchantLaneById(merchants: MerchantLanes): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/UpdateMerchantLaneById', merchants)
      .pipe(catchError(this.errorHandler));
  }

  deleteMerchantLaneById(merchants: MerchantLanes): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/DeleteMerchantLaneById', merchants)
      .pipe(catchError(this.errorHandler));
  }

  getMerchantByID(id: string): Observable<MerchantCredentials> {
    return this.http
      .get<MerchantCredentials>(this.ApiBaseUrl + '/' + id)
      .pipe(catchError(this.errorHandler));
  }

  getMerchantLaneByID(id: string): Observable<MerchantLanes> {
    return this.http
      .get<MerchantLanes>(this.ApiBaseUrl + '/GetLane/' + id)
      .pipe(catchError(this.errorHandler));
  }

  editMerchant(EditMerchant: MerchantCredentials): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/' + EditMerchant.MerchantInternalId, EditMerchant)
      .pipe(catchError(this.errorHandler));
  }

  checkIfActiveMerchantCredentialExistsWithModeForSameLocation(merchantName: string, locationId: string, mode: MerchantMode): Observable<MerchantCredentials[]> {
    return this.http
      .get<MerchantCredentials[]>(this.ApiBaseUrl + '/CheckIfActiveMerchantCredentialExistsWithModeForSameLocation/' + merchantName + '/' + locationId + '/' + mode)
      .pipe(catchError(this.errorHandler));
  }

  deactivateExistsMerchantforSameLocationMode(merchant: MerchantCredentials): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + '/DeactivateExistsMerchantforSameLocationMode', merchant)
      .pipe(catchError(this.errorHandler));
  }

  getCompanyMerchantDetails(CompanyId: string, locationId: string, mode: MerchantMode): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + '/GetCompanyMerchantDetails/' + CompanyId + '/' + locationId + '/' + mode)
      .pipe(catchError(this.errorHandler));
  }

  getTransactionsetupid(merchantCredentialId, obj): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTransactionsetupid/' + merchantCredentialId, obj)
      .pipe(catchError(this.errorHandler));
  }
  GetTransactionDetailBySetupid(merchantCredentialId, obj): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/GetTransactionDetailBySetupid/' + merchantCredentialId, obj)
      .pipe(catchError(this.errorHandler));
  }

  WorldpaySale(merchantCredentialId, laneId, totalAmount, salesChannel, salesOrderId): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/WorldpaySale/' + merchantCredentialId + '/' + laneId + '/' + totalAmount + '/' + salesChannel + '/' + salesOrderId)
      .pipe(catchError(this.errorHandler));
  }
  WorldpayAuthSale(merchantCredentialId, laneId, totalAmount, salesChannel, salesOrderId): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/WorldpayAuthSale/' + merchantCredentialId + '/' + laneId + '/' + totalAmount + '/' + salesChannel + '/' + salesOrderId)
      .pipe(catchError(this.errorHandler));
  }
  WorldpayFinalizeSale(merchantCredentialId, laneId, totalAmount, salesChannel, salesOrderId, transactionId): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/WorldpayFinalizeSale/' + merchantCredentialId + '/' + laneId + '/' + totalAmount + '/' + salesChannel + '/' + salesOrderId + '/' + transactionId)
      .pipe(catchError(this.errorHandler));
  }
  getManualCCTransactionUrl(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetManualCCTransactionUrl')
      .pipe(catchError(this.errorHandler));
  }

  checkAndGetMerchantIfExists(merchantCredentialId, laneId, salesChannel): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/CheckAndGetMerchantIfExists/" + merchantCredentialId + "/" + laneId + "/" + salesChannel)
      .pipe(catchError(this.errorHandler));
  }

  WorldpayLaneCheckConnectionStatus(merchantCredentialId, laneId): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/WorldpayLaneCheckConnectionStatus/' + merchantCredentialId + '/' + laneId)
      .pipe(catchError(this.errorHandler));
  }

  getTrasactionDetails(companyId,locationid, salesOrderId): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetTransactionDetailsByTransactionId/' + companyId + '/' + locationid  + '/' + salesOrderId)
      .pipe(catchError(this.errorHandler));
  }
}
