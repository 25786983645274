import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocationMovie } from './location-movie';
import { ApiService } from '../helper/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class LocationMovieService extends ApiService {
  LocationMovies: Array<LocationMovie> = new Array<LocationMovie>();
  ApiBaseUrl: string;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/LocationMovie";
  }

  getLocationMovies(): Observable<Array<LocationMovie>> {
    return this.http
      .get<Array<LocationMovie>>(this.ApiBaseUrl)
      .pipe(catchError(this.errorHandler));
  }

  getLocationMoviesByLocId(): Observable<Array<LocationMovie>> {
    return this.http
      .get<Array<LocationMovie>>(this.ApiBaseUrl + "/GetByLocationId")
      .pipe(catchError(this.errorHandler));
  }

  getLocationMovieByID(id: string): Observable<LocationMovie> {
    return this.http
      .get<LocationMovie>(this.ApiBaseUrl + "/" + id)
      .pipe(catchError(this.errorHandler));
  }

  addLocationMovie(LM: LocationMovie): Observable<any> {
    return this.http.post(this.ApiBaseUrl, LM)
      .pipe(catchError(this.errorHandler));
  }

  editLocationMovie(LocationMovie: LocationMovie): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + "/" + LocationMovie.LocationMovieID, LocationMovie)
      .pipe(catchError(this.errorHandler));
  }

  async updateLocationMovieStatus(LocationMovie: LocationMovie): Promise<any> {
    return this.http.put(this.ApiBaseUrl + "/" + LocationMovie.LocationMovieID, LocationMovie).toPromise().catch(this.errorHandler);
  }

  setVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  GetDistributors(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.ApiBaseUrl + '/GetDistributors')
      .pipe(catchError(this.errorHandler));
  }
}
