import { GiftCardStatus } from "src/app/giftcard/giftcard";
import { LocationBase } from "./LocationBase";
import { SalesChannelBase } from "./SalesChannelBase";

export class SalesOrder extends LocationBase {
  ConfirmationCode: string;
  Tickets: Array<SalesOrderTicket> = new Array<SalesOrderTicket>();
  Concession: Array<SalesOrderConcession> = new Array<SalesOrderConcession>();
  AdditionalCharges: Array<SalesAdditionalCharges> = new Array<SalesAdditionalCharges>();
  CustomerInfo: CustomerInfo;
  TotalAmount: number;
  LocationId: string;
  ShowId: string;
  MerchantName: string;
  MerchantCredentialId: string; //Needs only when use worldpay transaction
  LaneId: number; //Needs only when use worldpay transaction
  LastDigits: string;
  TotalTax: number;
  DateTimeSold: string;
  BarcodeImage: string;
  GiftCardNumber: string;
  PaymentMode: PaymentMode;
  PaymentID: string;
  PaymentType: string;  // It should be card type like "credit" or "debit"
  CurrencyType: string = "usd";
  ShiftId: string;
  RefundReason?: Array<string>;
  Refund?: Array<Refund>;
  SalesChannel: string;
  CCLastFour: string = '';
  CreditCardinfo?: string;
  TotalRefund: number;
  TipAmount: number;
  SalesSplit: Array<SalesSplit> = new Array<SalesSplit>();
  GiftCardSale: Array<SalesOrderGiftCard> = new Array<SalesOrderGiftCard>();
  ActualAmount: number;
  ActualTax: number;
  DiscounteRate: any;
  IsDiscountedOrder: boolean = false;
  DiscountedBy: string;
  DiscountedAt: string;
  // DiscountType: DiscountType;
  DiscountType: Array<DiscountType>=new Array<DiscountType>();
  isDineInOrder: boolean = false;
  WorldpayTransactionDetails: WorldpayTransactionDetails;
  Kitchen_Id: string = null;
  concessionPaymentItem: any = [];
  saleskitchenOrders: any = [];
  //24/03/2022 Added attribute for kitchen order reference
  UniqueOrderNumber: number;
  OrderLoyaltyCredit:number=0;
}

export class WorldpayTransactionDetails {
  LocationAddress: string;
  LocationPhoneNumber: string;
  CardLogo: string;
  EntryMode: string;
  ResponseCode: string;
  ResponseMessage: string;
  ApprovalNumber: string;
  PINVerified: boolean;
  ApplicationId: string;
  ApplicationLabel: string;
  ApplicationPreferredName: string;
  Cryptogram: string;
}

export enum DiscountType {
  Percentage,
  Cash,
  LoyaltyPoint
}

export enum PaymentMode {
  CC,
  Cash,
  ManualCC,
  GiftCard,
  Split
}

export enum SalesStatus {
  OnHold,
  Void,
  Printed,
  Pending,
  Reserved,
  Failed
}



export enum SalesOrderStatus {
  None, 
  PendingCC, 
  PendingCash, 
  PendingGC, 
  PendingExternalCC, 
  PendingSplit,
}
interface Refund {
  ShiftId: string;
  RefundReason: string;
  Amount: number;
  RefundDate: string;
}
export class SalesOrderConcession {
  ConcessionId: string;
  ConcessionName: string;
  ComboID: string = '';
  Price: number;
  Status: SalesStatus;
  RefundPrice: number = 0;
  NumberSold: number;
  Tax: number;
  ActualQuantity: number = 0;
  Complimentary: boolean = false;
  SeatNumbers: string;
  ScreenNumber: string;
  ScreeId: string;
  // FoodNotes:string;
  AddonConcessionName: string;
  PrepAreaId: string = null;
  PrepAreaName: string = null;
  ActualAmount: number;
  ActualTax: number;
  IsDiscountedOrder: boolean = false;
  ItemLoyaltyCredit:number=0;
  //uiRef
  // itemPaymentMode: PaymentMode = PaymentMode.Cash;
}

export class SalesAdditionalCharges {
  public Index: number;
  public Name: string;
  public AdditionalPrice: number;
  public Tax: number;
  public NumberOfTicketSold: number;
  public Actual: number;
  public SalesChannel: Array<SalesChannelBase> = new Array<SalesChannelBase>();
  public Status: number;
  ActualAmount: number;
  ActualTax: number;
  IsDiscountedOrder: boolean = false;
  //uiRef
  additionalChargeUniqueId:any;
  // itemPaymentMode: PaymentMode = PaymentMode.Cash;
}

export class CustomerInfo {
  FName: string;
  LName: string;
  Zip: number;
  Email: string;
  Phone: number;
  CardNumber: any = '';
  Expiry: any = 0;
  ExpiryYear: any = 0;
  Cvv: any = 0;
}

export class SalesOrderTicket {
  MovieId: string;
  MovieName: string;
  ComboID: string = '';
  Selected?: boolean;
  TicketType: string;
  TicketId: string;
  Price: number;
  RefundPrice: number = 0;
  ScreenId: string;
  ScreenName: string;
  SeatNumber: string;
  Status: SalesStatus;
  ShowId: string;
  Showtime: string;
  Showdate: string;
  isConcession?: boolean;
  Tax: number;
  Complimentary: boolean = false;
  ActualAmount: number;
  ActualTax: number;
  IsDiscountedOrder: boolean = false;
  //uiRef
  additionalChargeUniqueId:any;
  ItemLoyaltyCredit:number=0;
  // itemPaymentMode: PaymentMode = PaymentMode.Cash;
}

export class SalesSplit {
  CCLastFour: string;
  GiftCardNumber: string;
  PaymentMode: PaymentMode;
  PaymentID: string;
  TotalAmount: number;
  TotalTax: number;
  TipAmount: number;
  CreditCardinfo?: string;
  Status: SalesStatus;
  RefundAmount: any;
  ErrorMessage: any;
  CustomerInfo: CustomerInfo;
  PaymentType: string;
  MerchantCredentialId: string;
  LaneId: any;
  WorldpayTransactionDetails: WorldpayTransactionDetails;
}

export class SalesOrderGiftCard {
  GiftCardId: string;
  CardNumber: string;
  Amount: number;
  CompanyId: string;
  GiftCardStatus: GiftCardStatus;
  Recharged: boolean
  Expiry: Date;
  ActualAmount: number;
  IsDiscountedOrder: boolean = false;
}
