import { Injectable } from '@angular/core';
import { BehaviorSubject , Subject} from 'rxjs';
import { SalesOrder } from '../helper/models/SalesOrder';

@Injectable({
  providedIn: 'root'
})
export class PaymentoptionService {
  // salesOrderObject = new SalesOrder();
  salesOrderObjectObservable = new BehaviorSubject([]);
  salesOrderObject = this.salesOrderObjectObservable.asObservable();
  paymentSuccedObservable = new BehaviorSubject([]);
  paymentSuccedUpdate = this.paymentSuccedObservable.asObservable();
  
  KitchenPostObjectObservable = new BehaviorSubject([]);
  KitchenPostObject = this.KitchenPostObjectObservable.asObservable();
  salesObj = new BehaviorSubject([]);
  paymentType = new Subject();
  kitchenSuccedObservable = new BehaviorSubject([]);
  kitchenSuccedUpdate = this.kitchenSuccedObservable.asObservable();
  // PosCartUpdateObservable = new BehaviorSubject([]);
  // PosCartUpdate = this.PosCartUpdateObservable.asObservable();

  // To manage auto open tip amount while finalize payment
  hasPaymentModelOpen = false;
  constructor() { 
  }

}
