import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimeout =  10 *60 * 1000; // 10 minutes in milliseconds
  private inactivityTimer: any;
  private inactivitySubject = new Subject<void>();

  constructor() {
    this.setupListeners();
    this.resetTimer();
  }

  private setupListeners(): void {
    window.addEventListener('mousemove', this.resetTimer.bind(this));
    window.addEventListener('keydown', this.resetTimer.bind(this));
  }

  private clearTimer(): void {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
  }

  private setTimer(): void {
    localStorage.setItem('lastActivityTimestamp', Date.now().toString());
    this.inactivityTimer = setTimeout(() => {
      this.inactivitySubject.next();
    }, this.inactivityTimeout);
  }

  resetTimer(): void {
    this.clearTimer();
    this.setTimer();
  }

  inactivityObservable(): Observable<void> {
    return this.inactivitySubject.asObservable();
  }
}
