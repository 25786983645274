import { Injectable, Inject } from '@angular/core';
import { EmployeeSchedule } from './employee-schedule';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { EmpScheduleReportInput } from './manage-employee-schedule/manage-employee-schedule.component';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class EmployeeScheduleService extends ApiService{
  ApiBaseUrl: string;
  BaseUri: string;
  EmployeeSchedules: EmployeeSchedule[];

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, public datepipe: DatePipe) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/EmployeeSchedule';
    this.BaseUri = baseUrl;
  }

  getDecimalHours(finalHours) {
    var minutes = parseInt(((finalHours % 1) * 100).toFixed(2));
    var hours = parseInt(finalHours.toString());

    var decimalHours = parseFloat((minutes / 60).toFixed(2));
    var decimalMinutes = parseInt(((decimalHours % 1) * 100).toFixed(2));

    return parseFloat(hours + "." + (decimalMinutes > 9 ? decimalMinutes : "0" + decimalMinutes));
  }

  getEmployeeSchedules(startDate: string, endDate: string): Observable<EmployeeSchedule[]> {

    return this.http
      .get<EmployeeSchedule[]>(this.ApiBaseUrl + '/GetEmployeeSchedule/' + startDate + '/' + endDate)
      .pipe(catchError(this.errorHandler));
  }
  getEmployeeSchedulesByEmployeeId(startDate: string, endDate: string, employeeId: string): Observable<EmployeeSchedule[]> {
    return this.http
      .get<EmployeeSchedule[]>(this.ApiBaseUrl + '/GetEmployeeSchedule/' + startDate + '/' + endDate + '/' +employeeId )
      .pipe(catchError(this.errorHandler));
  }
  getEmployeeSchedule(id: string): Observable<EmployeeSchedule> {
    return this.http
      .get<EmployeeSchedule>(this.ApiBaseUrl + '/' + id)
      .pipe(catchError(this.errorHandler));
  }

  addEmployeeSchedule(newEmpchedules: EmployeeSchedule[]): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl, newEmpchedules)
      .pipe(catchError(this.errorHandler));
  }

  editEmployeeSchedule(empFeature: EmployeeSchedule): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/' + empFeature.EmpSchId, empFeature)
      .pipe(catchError(this.errorHandler));
  }

  GetEmpScheduleReport(data: EmpScheduleReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetEmpScheduleReport', data)
      .pipe(catchError(this.errorHandler));
  }

  sendEmployeeScheduleEmailToEmployees(startDate: string, endDate: string): Observable<EmployeeSchedule[]> {

    return this.http
      .get<EmployeeSchedule[]>(this.ApiBaseUrl + '/SendEmployeeScheduleEmailToEmployees/' + startDate + '/' + endDate)
      .pipe(catchError(this.errorHandler));
  }   
}
