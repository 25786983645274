import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert'

@NgModule({
  imports: [
    CommonModule,
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    AccordionModule.forRoot()
  ],
  exports: [
    BsDropdownModule,
    ButtonsModule,
    TooltipModule,
    ModalModule,
    AlertModule,
    BsDatepickerModule,
    TimepickerModule,
    ProgressbarModule,
    PopoverModule,
  ]
})
export class AppBootstrapModule { }
