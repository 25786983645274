import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import * as moment from 'moment';
import { delay } from 'rxjs/operators';
import { PrintService } from 'src/app/helper/services/print.service';
import Swal from 'sweetalert2';
import { SalesStatus } from '../../helper/models/SalesOrder';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';
@Component({
  selector: 'app-kiosk-confirmTickets',
  templateUrl: './kiosk-confirm-ticket.component.html',
  styleUrls: ['./kiosk-confirm-ticket.component.css']
})
export class KioskConfirmTicketsComponent implements OnInit {

  public subscribed: any = [];
  public movieData: any = [];
  public moviewScreenData: any = {};
  public barcode_config: any = {};
  public printingNow: boolean = true;

  constructor(public router: Router,
    private printService: PrintService, public kioskPOSService: KioskPOSService,private es:ErrorMessagesService) {
    this.barcode_config = {
      barcode: 'TEST',
      barcode_format_type: 'CODE128',
      elementType: 'img',
      lineColor: '#000000',
      width: 2,
      height: 150,
      displayValue: true,
      fontOptions: '',
      font: 'monospace',
      textAlign: 'center',
      textPosition: 'bottom',
      textMargin: 2,
      fontSize: 15,
      background: '#1E2A57',
      margin: 5,
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5,
      marginRight: 5
    };

    this.movieData = this.kioskPOSService.movieData;
    this.getScreensDataForMovie();
    let SalesOrderData = this.kioskPOSService.getSalesOrderData();
    console.log("SalesOrderData", SalesOrderData)
    /*this.barcode_config.barcode =  SalesOrderData[(SalesOrderData.length - 1)].ConfirmationCode;*/
    this.barcode_config.barcode = SalesOrderData.ConfirmationCode;
    console.log("this.barcode_config", this.barcode_config);
    this.removeAllHolds();

  }
  ngOnInit() {
    delay(1000);

    this.printService.printDocument('ticket', [this.kioskPOSService.getSalesOrderData().ConfirmationCode]).subscribe(res => {
      if (!res) {
        this.kioskPOSService.changeTicketStatus(this.kioskPOSService.getSalesOrderData().ConfirmationCode, "all", SalesStatus.Reserved).subscribe(f => {
          this.exitPrint();
        }, err => {
          // Swal.fire('Failed', err, 'error').then(r => {
            this.exitPrint();
          // });
        });
      }
    });
  }

  public selectedSeats: any = [];
  public removeAllHolds() {
    if (this.kioskPOSService.userHoldSeats.length > 0) {
      let body = [];
      this.kioskPOSService.userHoldSeats.forEach((i, index) => {
        if (i.SeatNumber.indexOf(this.selectedSeats) < 0) {
          body.push(i.HoldSeatId);
        }
      });
      console.log(body);
      if (body.length > 0) {
        this.kioskPOSService.SubmitPostFormData('HoldSeats/DeleteHoldSeats', body).subscribe((response) => {
        }, (error) => {
          console.log(error);
          Swal.fire(this.es.kioskErrorMsgList[19].message,this.es.kioskErrorMsgList[42].message, 'error');
        });
      }
    }
  }

  public getScreensDataForMovie() {
    this.movieData.forEach((item, index) => {
      if (item.LocationId == this.kioskPOSService.getLocationId() && item.MovieID == this.kioskPOSService.getMovieId()) {
        this.moviewScreenData = item;
        this.moviewScreenData.ScreenName = '';
        this.moviewScreenData.ShowTime = '';
        this.moviewScreenData.Seats = '-';
        this.moviewScreenData.ScreenID = this.kioskPOSService.getScreenId();
        this.moviewScreenData.ShowID = this.kioskPOSService.getShowId();
        item.Screens.forEach((screenItem, screenIndex) => {
          if (screenItem.ScreenID == this.moviewScreenData.ScreenID) {
            this.moviewScreenData.ScreenName = screenItem.ScreenName;
            screenItem.ShowTimes.forEach((showItem, showIndex) => {
              if (showItem.ShowID == this.moviewScreenData.ShowID) {
                this.moviewScreenData.ShowTime = this.kioskPOSService.covertTimeToFormat(showItem.ShowTime, 'MMMM DD YYYY, hh:mm') + ' ' + this.kioskPOSService.covertTimeToFormat(showItem.ShowTime, 'A');
              }
            });
          }
        });
        let seatsArray = this.kioskPOSService.getSeats();
        if (seatsArray.length > 0) {
          this.moviewScreenData.Seats = seatsArray.join(', ');
        }
      }
    });

  }

  ngOnDestroy() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  public exitPrint() {
    this.printingNow = false;
    this.kioskPOSService.unsetFinalData(['MovieId', 'PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems']);
    this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
  }
}
