import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { KioskPOSService } from '../services/kiosk-pos.service';

@Injectable()
export class KioskResolver implements Resolve<Observable<string>> {
  constructor(private kioskPOSService : KioskPOSService){
  }

  resolve() {
    return this.kioskPOSService.getLocationMoviesData();
  }
}