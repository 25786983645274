import { LocationBase } from "../helper/models/LocationBase";

export class FoodItem extends LocationBase {
  FoodItemId: string;
  FoodItemName: string;
  Description: string;
  Media: string;
  Price: number = 0;
  FranchiseId: string;
}
