import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocationsService } from '../locations/locations.service';
import { ToastrService } from 'ngx-toastr';
import { StyleService } from '../helper/services/docloadings/style.service';
import { Router } from '@angular/router';
import { CompanyService } from '../company/company.service';
import { ErrorMessagesService } from '../helper/services/error-messages.service';
import { NgForm } from '@angular/forms';
import VanillaTilt from "vanilla-tilt";

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  useremail:any="";
  @ViewChild('SubscribeForm', { static: true }) SubscribeForm: NgForm;

  constructor(private el: ElementRef,public localtionService: LocationsService,private toastrService: ToastrService,private stSer: StyleService,private router: Router,private clientCompanyService: CompanyService,private es:ErrorMessagesService) {    
    this.stSer.addStyle('bt', require('../../assets/css/bootstraplanding.min.css'));
    this.stSer.addStyle('bt', require('../../assets/css/all.min.css'));
    this.stSer.addStyle('all', require('../../assets/css/jquery.fancybox.min.css'));
    this.stSer.addStyle('all', require('../../assets/bootstrap-icons-1.9.1/bootstrap-icons.css'));
  }

  ngOnInit() {
    VanillaTilt.init(
      this.el.nativeElement.querySelectorAll(".VanillaTilt"), { max: 20, speed: 300, scale: 1.05 }
    );
  }
  subscribeemail() {
    console.log("RES",this.useremail);
    if(!this.useremail  || this.useremail  == "" || this.useremail  ==null){
      return;
    }
    if (!this.SubscribeForm.form.valid) {
     return;
    }
    let bodyobj = {
      UserId :"",
      UserEmail:this.useremail
    }
    this.localtionService.postNewsletterUser(bodyobj).subscribe(res => {
      this.useremail = "";
      if(res && res.length >0){
        this.toastrService.success(
          ("Successfully subscribed to our Newsletter"),
          "Success"
        );
      }else{
        this.toastrService.warning(
          ("You are already subscribed to our newsletter") + "",
          ""
        );
       
      }
      console.log("RES",res);
    }, error => {
     console.log(error)
    });
  }

}
