import { Component, OnInit, ViewEncapsulation, ViewChildren } from '@angular/core';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { Router } from '@angular/router';
import Keyboard from "simple-keyboard";
import { CommonSettings } from 'src/app/helper/commonSettings';
import {SalesStatus} from 'src/app/helper/models/SalesOrder';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-pickup-cclastno',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './kiosk-pickup-cclastno.component.html',
  styleUrls: ['./kiosk-pickup-cclastno.component.css','../../../../node_modules/simple-keyboard/build/css/index.css']
})
export class KioskPickupCclastnoComponent implements OnInit {
  CCode: string;
  SalesOrder: any = [];
  error: string = null;
  count: number = 0;
  LocationId: string;
  keyboard: Keyboard;
  @ViewChildren('kioskccldinput') kioskccldinput: any;
  
  constructor(public kioskPOSService: KioskPOSService, public router: Router,private es:ErrorMessagesService) {
    this.LocationId = sessionStorage.getItem("LocationId");
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      layout: {
        default: CommonSettings.keybSetting.numpadDef,
      },
      theme: CommonSettings.keybSetting.theme
    });
    const input: HTMLInputElement = this.kioskccldinput.first.nativeElement as HTMLInputElement;
    input.focus();
  }

  onChange = (input: any) => {
    this.CCode = input;
  };
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  Verify() {
    this.kioskPOSService.getSalesOrderByCreditCard(this.CCode).subscribe(res => {
      this.SalesOrder = res;
      if (this.SalesOrder.ConfirmationCode != null) {
        for (const ticket of this.SalesOrder.Tickets) {
          if (ticket.Status != SalesStatus.Printed) {
            this.count++;
          }
        }
        if (this.count !== 0) {
          this.kioskPOSService.pickupTicketData = this.SalesOrder;
          this.router.navigate(['/kiosk/pickup/print/' + this.kioskPOSService.getLocationId()])
        }
        else {
          this.error = this.es.kioskErrorMsgList[6].message
        }
      }

      else {
        this.error = this.es.kioskErrorMsgList[9].message;
      }
    });

  }

}
