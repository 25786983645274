import { SalesOrder } from "../helper/models/SalesOrder";
import { GiftCardSales } from "../giftcard/GiftCardSales";

export class Shift {
    ShiftId: string = "";
    POSId: string = "";
    EmployeeId: string = "";
    Status: string = "";
    StartTime: Date;
    EndTime: Date;
    FinalizedTime: string = "";
    FinalizedBy: string = "";
    StartBalance: number = 0;
    DifferenceAmount: number = 0;
    EndBalance: number = 0;
    FinalBalance: number = 0;
    Notes: string = "";
    LocationId: string = "";
    LocationName: string = "";
    CreatedBy: string = "";
    //CreatedOn: string = "";
    UpdatedBy: string = "";
    UpdatedOn: string = "";
    Visible: number = 1;
}

export class POSShift extends Shift{
    EmployeeName: string = "";
    POSName:string = "";
  ExpectedBalance: number = 0;
  OpeningBalance: number = 0;
}

export class ActualSummary{
    SalesOrder:Array<SalesOrder>;
    GiftCardSales:Array<GiftCardSales>;
}
export class Summary {
    StartBalance: number = 0;
    CurrentBalance: number = 0;
    TotalTicketsSold: number = 0;
    TotalGiftCardRechargeAmount: number = 0;
    TotalGiftCardSold:number = 0;
    TotalCashTransactions: number = 0;
    TotalCreditCardTransactions: number = 0;
    TotalGiftCardTransactions: number = 0;
    TotalCashRefundAmount: number = 0;
    TotalCCRefundAmount: number = 0;
    TotalGCRefundAmount: number = 0;
    TotalConcessionSold: number = 0;
    TotatRefundIssued: number = 0;
    TipAmount: number = 0;
}

export class FinalizeShift {
    FinalizedBy: string = "";
    FinalBalance: number = 0;
    Notes: string = "";
}
