import { Injectable, Inject } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Location, CountLocationModule, LmsorTms } from "./location";
import { catchError } from "rxjs/operators";
import { Employee } from '../employee/employee';
import { ApiService } from "../helper/services/api.service";
import { PlanOrder } from "../helper/models/PlanOrder";

@Injectable({
  providedIn: "root"
})

export class LocationsService extends ApiService {
  baseUrl: string;
  ApiBaseUrl: string;
  SalesApiUrl: string;
  SalesConsolidatedApiUrl: string;
  StateApiUrl: string;
  apiJobUrl: string;
  ApiAdminRefund: string;
  LmsorTmsApiUrl: string;
  PlanOrderApiUrl: string;
  CompApiUrl: string;
  TermsApiUrl: string;
  ApiLocBaseUrl: string;
  Employees: Employee[];
  loc_list_user_has_rights: Location[] = [];
  ApiUser: string;

  locations: Location[] = [];
  httpBackedurl: HttpClient;
  IshomePage: boolean = false;
  Companylogo: string = "../../assets/images/noimgport.png";
  applecompany: boolean = false;
  companyId: string = "f604d90";// for default company will be apple cinema for default apple cinema company location
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private backend: HttpBackend) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/Location";
    this.SalesApiUrl = baseUrl + "api/SalesOrder";
    this.SalesConsolidatedApiUrl = baseUrl + "api/SalesConsolidated";
    this.StateApiUrl = baseUrl + "api/State";
    this.apiJobUrl = baseUrl + "api/Job";
    this.ApiUser = baseUrl + "api/User";  
    this.baseUrl = baseUrl;
    this.ApiAdminRefund = baseUrl + "api/AdminRefund";
    this.PlanOrderApiUrl = baseUrl + "api/PlanOrder";
    this.CompApiUrl = baseUrl + "api/Company";
    this.TermsApiUrl = baseUrl + "api/TermsConditions";
    this.baseUrl = baseUrl;    
    this.ApiLocBaseUrl = baseUrl + "api/";
    this.httpBackedurl = new HttpClient(backend);
  }

  getLocations(): Observable<Location[]> {
    return this.http
      .get<Location[]>(this.ApiBaseUrl + "/" + "GetLocations")
      .pipe(catchError(this.errorHandler));
  }
  getLocationsByCompanyId(): Observable<Location[]> {
    return this.http
      .get<Location[]>(this.ApiBaseUrl + "/" + "GetLocationsByCompanyId")
      .pipe(catchError(this.errorHandler));
  }

  getLocationByID(id: string): Observable<Location> {
    return this.http
      .get<Location>(this.ApiBaseUrl + "/" + id)
      .pipe(catchError(this.errorHandler));
  }

  // This method gets the current context location.
  getLocation(): Observable<Location> {
    return this.http
      .get<Location>(this.ApiBaseUrl)
      .pipe(catchError(this.errorHandler));
  }

  addLocation(location: Location): Observable<Location[]> {
    return this.http
      .post<Location[]>(this.ApiBaseUrl, location)
      .pipe(catchError(this.errorHandler));
  }

  getStates(obj: string): Observable<Array<Object>> {
    return this.http
      .get<Array<Object>>(this.StateApiUrl + "?name=" + obj)
      .pipe(catchError(this.errorHandler));
  }

  editLocation(location: Location): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + "/" + location.Location_ID, location)
      .pipe(catchError(this.errorHandler));
  }

  async getCount(): Promise<any> {
    return await this.http.get<CountLocationModule>(this.ApiBaseUrl + "/GetCountByLocation").pipe(catchError(this.errorHandler)).toPromise();
  }

  async getDashboardSummary(ds: DaySummaryInput): Promise<any> {
    return await this.http.post<any>(this.SalesApiUrl + "/DaySummary/", ds).pipe(catchError(this.errorHandler)).toPromise();
  }
  async getDashboardSummaryV2(ds: DaySummaryInput): Promise<any> {
    return await this.http.post<any>(this.SalesConsolidatedApiUrl + "/DaySummaryFromSalesConsolidated/", ds).pipe(catchError(this.errorHandler)).toPromise();
  }

  async getDashboardConcessionSummary(ds: DaySummaryInput): Promise<any> {
    return await this.http.post<any>(this.SalesApiUrl + "/DayConcessionSummary/", ds).pipe(catchError(this.errorHandler)).toPromise();
  }

  async getDashboardConcessionSummaryV2(ds: DaySummaryInput): Promise<any> {
    return await this.http.post<any>(this.SalesConsolidatedApiUrl + "/DayCurrentConcessionSummaryFromSalesConsolidated/", ds).pipe(catchError(this.errorHandler)).toPromise();
  }

  GetEmployeeByEmail(email: string): Observable<Employee> {
    return this.http
      .get<Employee>(this.baseUrl + 'api/Employee/GetByEmail/' + email)
      .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: number, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }

  postGetSeatsCounts(MovieIds): Observable<Array<String>> {
    return this.http.post<Array<String>>("/api/SalesOrder/GetShowTicketCount", MovieIds).pipe(catchError(this.errorHandler));
  }

  //start of jobs api
  getJobs(companyId: string): Observable<any> {
    return this.http
      .get<any>(this.apiJobUrl + "/" + "GetAllJobsByCompanyId/" + companyId)
      .pipe(catchError(this.errorHandler));
  }

  GetJobByJobId(jobId: string): Observable<any> {
    return this.http
      .get<any>(this.apiJobUrl + "/" + "GetJobByJobId/" + jobId)
      .pipe(catchError(this.errorHandler));
  }

  postJobss(job): Observable<any> {
    return this.http.post<any>(this.apiJobUrl + "/AddJob", job).pipe(catchError(this.errorHandler));
  }

  editJob(JobId,Job): Observable<any> {
    return this.http
      .put(this.apiJobUrl + "/UpdateJob/" + JobId ,Job)
      .pipe(catchError(this.errorHandler));
  }

  //end of jobs api

  //start of admin refund api
  GetAdminShiftPosByLocationId(): Observable<any> {
    return this.http
      .get<any>(this.ApiAdminRefund + "/GetAdminShiftPosByLocationId")
      .pipe(catchError(this.errorHandler));
  }
  UpdateShiftEndBal(shiftid,balance){
    return this.http
    .patch(this.ApiAdminRefund + '/UpdateShiftEndBal/' + shiftid + '/' + balance, null)
    .pipe(catchError(this.errorHandler));
  } 

  GetPlan(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + '/GetPlan')
      .pipe(catchError(this.errorHandler));
  }
  getverifyPin(confirmationcode, paymentmode, OtpPin, merchantInternalid, postdata) {
    return this.http.post<any>(
      this.PlanOrderApiUrl + `/VerifyPin?planOrderId=${confirmationcode}&paymentType=${paymentmode}&code=${OtpPin}&merchantinernalId=${merchantInternalid}`,
      postdata
    );
  }
 /* PostPlanorderbeforePayment(postdata: PlanOrder): Observable<any> {
    return this.http.post<any>(this.ApiBaseUrl + '/PostPlan', postdata)
      .pipe(catchError(this.errorHandler));
  }
  GetPlanOrder(compId:string, locId:string): Observable<PlanOrder> {
    return this.http.get<PlanOrder>(this.baseUrl + 'api/PlanOrder/GetPlanOrder/' + compId + '/' + locId)
      .pipe(catchError(this.errorHandler));
  }*/

 

  PostPlanorderbeforePayment(postdata: PlanOrder): Observable<any> {
    return this.http.post<any>(this.PlanOrderApiUrl + '/AddPlanOrderBeforePayment', postdata)
      .pipe(catchError(this.errorHandler));
  }
  ReSendOtp(postdata) {
    return this.http.post<any>(
      this.PlanOrderApiUrl + `/ResendPin`,
      postdata

    );
  }
  getCompanyByCompanyId(companyId: string): Observable<any> {
    return this.http.get<any>(this.CompApiUrl + "/" + "GetCompany/" + companyId);
  }
  proceedManualpayment(body: any, companyName: string): Observable<any> {
    let bodyobj = {
      PlanOrder: body,
      PlanCustomerInfo: body.CustomerInfo,
      CompanyUrl: window.location.origin,
      CompanyName: companyName,
    }
    return this.http.post<any>(
      this.PlanOrderApiUrl + '/PlanOrderCardPayment',
      bodyobj
    ).pipe(catchError(this.errorHandler));
  }

  confirmManualpayment(cc_code, lastfour, flag, paymentid) {
    return this.http.patch<any>(
      this.PlanOrderApiUrl +
      '/ConfirmAuthPayment/${cc_code}/${lastfour}/${flag}/${paymentid}',
      null
    );
  }
  postApiResponce(ApiresponceObj): Observable<Object> {
    return this.http.post<Object>(this.baseUrl + "APIResponse", ApiresponceObj)
  }
  public getConfirmationTemplate(body: any, companyName): Observable<any> {
    let bodyobj = {
      PlanOrder: body,
      PlanCustomerInfo: body.CustomerInfo,
      CompanyUrl: window.location.origin,
      CompanyName: companyName,
    }
    return this.http.post<any>(
      this.PlanOrderApiUrl + '/GetTemplate',
      bodyobj
    ).pipe(catchError(this.errorHandler));
  }
  GetMerchantId(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/" + "GetMerchantId")
      .pipe(catchError(this.errorHandler));
  }
  getTCCPPByCompanyId(companyId): Observable<any> {
    return this.http.get<any>(this.CompApiUrl + "/" + "GetTermsConditionAndPrivacyPolicyByCompanyId/" + companyId);   
  }

  getTermsConditions(): Observable<any> {
    return this.http.get<any>(this.TermsApiUrl + "/" + "GetTermsConditionAndPrivacyPolicy");
  }

  postNewsletterUser(email): Observable<Array<any>> {
    return this.http.post<Array<any>>("api/Company/PostNewsletterUser", email).pipe(catchError(this.errorHandler));
  }
  GetDineIn(): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/" + "GetDineIn")
      .pipe(catchError(this.errorHandler));
  }
  SetDineInStatus(id, status): Observable<Location> {
    return this.httpBackedurl
      .patch<Location>("/api/Location/SetDineInStatus/" + id + '/' + status, {})
      .pipe(catchError(this.errorHandler));
  }
  postPlanSalesOrderAsFailed(body: any, companyName: string): Observable<any> {
    let bodyobj = {
      PlanOrder: body,
      PlanCustomerInfo: body.CustomerInfo,
      CompanyUrl: window.location.origin,
      CompanyName: companyName,
    }
    return this.http.post<any>(
      this.PlanOrderApiUrl + '/PlanOrderCardPaymentAsFailed',
      bodyobj
    ).pipe(catchError(this.errorHandler));
  }
 
}

export class DaySummaryInput {
  StartDate: Date | string;
  EndDate: Date | string;
}
