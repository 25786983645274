import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { AddHelperMethods, ImageType, RoundAmount, CloneObject } from './../helper/models/AddHelperModal';
import { NgForm } from '@angular/forms';
import { FileUploadService } from '../helper/services/file-upload.service';
import Swal from 'sweetalert2';
import { CompanyService } from './company.service';
//import { Company, RestaurantOptions } from '../company/company';
import { Company} from '../company/company';
import { NavigationEnd, Router } from '@angular/router';
import { CommonSettings } from '../helper/commonSettings';
import { ErrorMessagesService } from '../helper/services/error-messages.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CompanyComponent extends AddHelperMethods implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    // Reload the page when the user navigates back
    window.location.reload();
  }
  constructor(private clientCompanyService: CompanyService, private fileUploadServ: FileUploadService, private router: Router, private es: ErrorMessagesService) {
    super();
  }

  @ViewChild('RegistrationForm', { static: true }) RegistrationForm: NgForm;
  clientCompany: Company = new Company();
  //restaurantOption: RestaurantOptions = new RestaurantOptions();
  types = Company.getCompanyTypeName();
  //showRestaurantOptions: boolean = false;
  clientCompanyMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  clientCompanyBannerMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  clientOnlinePWAMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  error: string = '';

  ngOnInit() {

  }

  setCompanyType() {
    //if (this.clientCompany.CompanyTypeName == "restaurant") {
    //  this.showRestaurantOptions = true;
    //} else
      if (this.clientCompany.CompanyTypeName == "cinemas") {
        // this.showRestaurantOptions = false;
        this.clientCompany.CompanyType.Restaurant = false;
        this.clientCompany.CompanyType.Cinemas = true;
    }
      else if (this.clientCompany.CompanyTypeName == "restaurant") {
        this.clientCompany.CompanyType.Restaurant = true;
        this.clientCompany.CompanyType.Cinemas = false;
      }
  }

  onSubmit() {
    if (this.RegistrationForm.form.valid) {
      this.error = '';
    }

    if (!this.clientCompany.Company_Name.trim())
      this.error = 'Please enter valid Name';
    else if (!this.clientCompany.Address.trim())
      this.error = 'Please enter valid Address';
    else if (!this.clientCompany.City.trim())
      this.error = 'Please enter valid City';
    else if (!this.clientCompany.State.trim())
      this.error = 'Please enter valid State';
    else if (!this.clientCompany.Country.trim())
      this.error = 'Please enter valid Country';
    else if (!this.clientCompany.PostalCode.trim())
      this.error = 'Please enter valid Postal Code';
    else if (parseFloat(this.clientCompany.LoyaltyPointsPerCurrency) < 0)
      this.error = 'Please enter valid Loyalty Points Per Currency';
    else if (parseFloat(this.clientCompany.DefaultLoyaltyPoints) < 0)
      this.error = 'Please enter valid  Default Loyalty Points';
    else if (parseInt(this.clientCompany.RegistrationLoyaltyPoints) < 0)
      this.error = 'Please enter valid Registration Loyalty Points';

    if(this.clientCompanyMedia.file == null || this.clientCompanyMedia.url == "" || this.clientCompanyMedia.file == undefined) {
      this.error = this.es.adminErrorMsgList[109].message;
      return;
    }

    //if (this.clientCompany.CompanyTypeName == "restaurant") {
    //    if(this.restaurantOption.IsCreditCardAllowed == false && this.restaurantOption.IsDeliveryAllowed == false &&
    //        this.restaurantOption.IsDineInAllowed == false && this.restaurantOption.IsTakeOutAllowed == false) {
    //        this.error = 'Please select atleast one of the restaurant options.';
    //        return;
    //    }
    //} else
      //if (this.clientCompany.CompanyTypeName == "cinemas") {
      //  this.restaurantOption.IsCreditCardAllowed == false;
      //  this.restaurantOption.IsDeliveryAllowed == false;
      //  this.restaurantOption.IsDineInAllowed == false;
      //  this.restaurantOption.IsTakeOutAllowed == false;
      //}

      if(this.clientCompany.IsAgreeableToTermsAndConditions == false) {
        this.error = this.es.adminErrorMsgList[110].message;
        return;
      }

    if (this.RegistrationForm.form.valid && this.error === '') {
      this.registerCompany();
    }
  }

  async registerCompany() {

    this.clientCompanyService.checkEmailIdExistsToAnotherCompany(this.clientCompany.Email).subscribe(async isEmailExists => {

      if (isEmailExists) {
        Swal.fire("OOPs..!", this.es.adminErrorMsgList[218].message)
      }
      else {
        //this.clientCompany.CompanyType.Restaurant = this.restaurantOption;
        this.clientCompany.LogoImage = '';
        if (this.clientCompanyMedia.file) {
          await this.uploadCompanyLogoImage(this.clientCompanyMedia, "", this.fileUploadServ, this, 90, 155, 'cloudflare');
        }
        if (this.clientCompanyBannerMedia.file) {
          await this.uploadImage(this.clientCompanyBannerMedia, "", this.fileUploadServ, this, 'cloudflare');
        }
        if (this.clientOnlinePWAMedia.file) {
          await this.uploadCompanyLogoImage(this.clientOnlinePWAMedia, "", this.fileUploadServ, this, 512, 512, 'cloudflare');
        }

        if (this.error === "") {
          if (this.clientCompanyMedia.url)
            this.clientCompany.LogoImage = this.clientCompanyMedia.url;
          if (this.clientCompanyBannerMedia.url)
            this.clientCompany.CompanyBannerImage = this.clientCompanyBannerMedia.url;
          if (this.clientOnlinePWAMedia.url)
            this.clientCompany.PWAImage = this.clientOnlinePWAMedia.url;

          this.clientCompany.CurrentUrl = (window.location.href).replace("registration", "");

          await this.clientCompanyService.registerCompany(this.clientCompany).subscribe(
            newcompany => {
              if (newcompany) {
                Swal.fire(this.es.adminErrorMsgList[68].message, this.es.adminErrorMsgList[69].message).then(re => {
                  if (re?.value) {
                    this.router.navigate(['']);
                  }
                })
              }
              //   this.router.navigate(['']);
            },
            error => {
              this.error = error;
              console.log(this.error);
              Swal.fire("OOPs..!", this.es.adminErrorMsgList[67].message)
            }
          );
        }
      }
    });
  }

  CheckclientCompanyMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientCompanyMedia = {
        url: this.clientCompany.LogoImage || '',
        file: null,
        progress: 0
      };
    }
  }

  CheckclientCompanyBannerMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientCompanyBannerMedia = {
        url: this.clientCompany.CompanyBannerImage || '',
        file: null,
        progress: 0
      };
    }
  }

  CheckOnlinePWAMediaHasGifFile(file) {
    if (file.name.includes('gif')) {
      Swal.fire("", this.es.adminErrorMsgList[229].message);
      this.clientOnlinePWAMedia = {
        url: this.clientCompany.PWAImage || '',
        file: null,
        progress: 0
      };
    }
  }

  async Cancel() {
    Swal.fire(CommonSettings.cancelSwal).then(f => {
      if (f.value) {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    });
  }
}
