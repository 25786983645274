import { LocationBase } from "../helper/models/LocationBase";
import { Location } from "../locations/location";
import { EmployeeLocationRole } from "../helper/models/EmployeeLocationRole";
import { CompanyRole } from "../company/company-role";

export class Employee extends LocationBase {
  EmployeeId: string;
  FirstName: string;
  LastName: string;
  DisplayName: string;
  Email: string;
  CompanyId: string;
  Pin: number;
  PhoneNumber: string = '';
  AvatarImageURL: string;
  SelectedLocationAndRoles: EmployeeLocationRole[] = new Array();
  SelectedCompanyRoles: CompanyRole[] = new Array();
}

export class EmployeeOverrideAccess {
  Read: number;
  Write: number;
  GPO: number;
}

export class EmployeeLocationSelected extends Location {
  Selected: boolean;
  constructor(location: Location, selected: boolean) {
    super();
    this.Selected = selected;
    this.Location_ID = location.Location_ID;
    this.Location_Name = location.Location_Name;
  }
}

export class EmployeeSelected extends Employee {
  Selected: boolean;
  constructor(employee: Employee, selected: boolean) {
    super();
    this.Selected = selected;
    this.DisplayName = employee.DisplayName;
    this.Email = employee.Email;
    this.SelectedLocationAndRoles = employee.SelectedLocationAndRoles;
  }
}

