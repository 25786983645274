import { Pipe, PipeTransform } from '@angular/core';

/*
 * Usage:
 *   value | paren
 * Example:
 *   {{ 'foo' | paren }}
 *   formats to: '（foo）'
*/
@Pipe({
  name: 'paren'
})
export class ParenthesisPipe implements PipeTransform {
  transform(value: string, zenkaku: boolean = true): string {
    if (value == null || value == undefined || value === '') {
      return '';
    }
    if (zenkaku) {
      var unitSplit = value.split("(");
      if (unitSplit[0] != "") {
        var unitshortForm = unitSplit[1].split(")");
        value = unitshortForm[0];
      }
      return value;
    }
    return value;
  }
}
