export class Screen {
  Screen_ID: string;
  LocationId: string;
  LocationName: string;
  Screen_Name: string;
  Screen_Number: number = 0;
  ScreenInfo: Array<string>;
  Total_Seats: number;
  Total_Rows: number;
  Hadicapped_Seats: Array<string>;
  Broken_Seats: Array<string>;
  House_Seats: Array<string>;
  Rows: Array<string>;
  Aisle: Array<string>;
  Companion: Array<string>;
  Unavailable: Array<string>;
  IsRowsReverse: boolean;
  IsColsReverse: boolean;
  BlankSeats: Array<BlankSeat>;
  UpAisle: Array<string>;
  CreatedBy: string;
  //CreatedOn: string;
  UpdatedBy: string;
  UpdatedOn: string;
  // Visible: boolean;
  Visible: number;
  Seating: string;
  ShowPropertiesList: any;
  ShowProperties: any;
  showpropertystring: any;
  checked?: boolean;
  addScreen;
  SeatGroup: Array<ScreenSeatGroup>;
}

export enum SeatType {
  Available,
  House,
  Broken,
  Handicap,
  Companion,
  Unavailable,
  Selected,
  SelectedHouse,
  SelectedBroken,
  SelectedHandicap,
  SelectedCompanion
}

export interface AsileData {
  name: string;
  row: FromTo<string>;
  col: FromTo<number>;
  location: string;
}

interface FromTo<T> {
  from: T;
  to: T;
}

export interface BlankSeat {
  Row: string,
  SeatNumber: number,
  Count: number
}

export class Seat {
  SeatNumber: string;
  checked?: boolean;
}

export interface ScreenSeatGroup {
  SeatGroupId: string;
  Seats: Array<string>;
}
