export class Pos {
    POSId: string = "";
    POSName: string = "";
    POSType: string = "";
    Status: string = "";
    LocationId: string = "";
    LocationName: string = "";
    CreatedBy: string = "";
    //CreatedOn: Date = new Date();
    UpdatedBy: string = "";
    UpdatedOn: string = "";
    Visible: number = 1;
    LaneId: number;
    IsPosRegistered: boolean = false;   
    MerchantCredentialId: string = null;
    ExpiryDate: string;
    isPreviousPlan: boolean = true;
    static getTypesArray() {
        return [
            { name: "KIOSK", value: "kiosk" },
            { name: "POS", value: "pos" },
            { name: "MOBILE", value: "mobile" }
        ];
    }
}



export enum Status {
    OPEN = "open",
    CLOSED = "closed",
    FINAL = "final"
}

export enum Type {
    KIOSK = "kiosk",
    POS = "pos",
    MOBILE = "mobile"
}
