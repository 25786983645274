import { formatDate } from "@angular/common";
import { LocationBase } from "src/app/helper/models/LocationBase";
import { PriceCard } from "src/app/price-card/price-card";
import { SalesChannelBase } from "src/app/helper/models/SalesChannelBase";

export class Show {
    showID: string = "";
    ScreenID?: string = "";
    ScreenNumber?: number;
    MovieID?: string = "";
    MovieName?: string = "";
    startTime?: string = "";
    endTime?: string = "";
    priceCard: string = "";
    seating: string = "";
    compliment: number = 1;
  salesChannel: Object = {};
    status: string = "";
    trailerDuration: string = "";
    cleanupDuration: string = "";
    showType: string = "";
    report: number = 1;
    title?: any;
    IsDelete: number;
    SelectedScreenFormatList: any;
    ScreenFormat: string = "";
    ScreenFormatList: any;
    ShowProperties: any;
  ShowPropertyList: any;
  PosterImage: string = "";
  WallpaperImage: string = "";
    TotalSeatsSold: any = 0;
}

export class MovieScheduleData {
    show: Show = new Show();
    //CreatedOn?: string = "";
    UpdatedOn?: string = "";
}

export class MovieSchedule extends LocationBase {
    MovieID: string;
    ShowID: string;
    MovieName: string;
    Seating: string;
    StartDateTime: string;
    EndDateTime: string;
    ShowPriceCard: PriceCard
    Rentrack: number;
    SalesChannel: Array<SalesChannelBase>;
    Status: MVStatus;
    ScreenID: string;
    ShowType: string;
    ScreenNumber: number;
    PosterImage: string;
    WallpaperImage: string;
    IsDelete: number;
    PriceCardId: string;
    Compliment: number = 1;
    TrailerDuration: string = "";
    CleanUpDuration: string = "";
    Visible: number = 1;
    ScreenFormat: string;
    ScreenFormatList: any;
    ScreenFormatArray: any;
    ShowProperties: any;
    ShowPropertyList: any;
    TotalSeatsSold: any;
}

export enum MVStatus {
    planned = 1,
    open = 2,
    closed = 3
}

export enum MVCompliment {
    notallowed = 0,
    allowed = 1
}

export class Showproperties {
    PropertyId: string;
    PropertyName: string;
    icon: string;
}

export class CommonStaticContent {
    SeatingOption: SeatingOption[] = [];
    StatusOption: StatusOption[] = [];
    ShowTypeOption: ShowTypeOption[] = [];
}
export class SeatingOption {
    name: string = '';
    StavaluetusOption: string = '';
    Checked: boolean = false;
}
export class StatusOption {
    name: string = '';
    StavaluetusOption: string = '';
    Checked: boolean = false;
}
export class ShowTypeOption {
    name: string = '';
    StavaluetusOption: string = '';
    Checked: boolean = false;
}
export class MSParkingMapping {
  MSParking_ID: string;
  MovieID: string;
  ShowID: string;
  ParkingLot_Id: string;
  ParkingLot_Name: string;
  ParkingLot_Number: number;
}
export class MSCoppyParking {
  MSParking_ID: string;
  MovieID: string;
  ShowID: string;
  ParkingLot_Id: string;
  ParkingLot_Name: string;
  ParkingLot_Number: number;
  StartDateTime: string;
  EndDateTime: string;
}

export class MovieSummaryRequestModel{
    startDateTime: string;  // Initialize with the current date/time or as needed
    endDateTime: string;
    locationId: string;
    movieNames: string[] =[];
    screenFormat: string[]=[]
}

