import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GiftcardService } from '../../giftcard/giftcard.service';
import { GiftCard, GiftCardStatus } from '../../giftcard/giftcard';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { GiftCardSales } from '../../giftcard/GiftCardSales';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import Keyboard from "simple-keyboard";
import { CommonSettings } from 'src/app/helper/commonSettings';
import { KioskLayoutComponent } from '../kiosk-layout/kiosk-layout.component'
import { SalesOrder, PaymentMode, SalesStatus, SalesOrderGiftCard } from 'src/app/helper/models/SalesOrder';
import { PrintService } from 'src/app/helper/services/print.service';
import { LoaderService } from 'src/app/helper/services/loader/loader.service';
import { MerchantService } from '../../merchants/merchant.service';
import { SalesorderService } from 'src/app/helper/services/salesorder.service';
import { CommonService } from 'src/app/helper/services/common.service';
import * as moment from 'moment';
import { PaymentService } from "../../helper/services/payment/payment.service";
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-giftcard-refill',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './giftcard-refill.component.html',
  styleUrls: ['./giftcard-refill.component.css', '../../../../node_modules/simple-keyboard/build/css/index.css']
})
export class GiftcardRefillComponent implements OnInit {
  cardnumber: string = null;
  GiftCard: GiftCard = new GiftCard();
  GiftCardSale: SalesOrder = new SalesOrder();
  // GiftCardSale: GiftCardSales = new GiftCardSales();
  error: string = null;
  PNumber: string;
  SalesOrder: any = [];
  count: number = 0;
  Amount: number = 0;
  Step: number = 0;
  selectedMerchant: string;
  keyboard: Keyboard;
  ccData: string;
  cc_last_four: string;
  transactionid: string = null;
  cardTypeWorldpay: any = null;
  location: any = null;
  constructor(private es:ErrorMessagesService,private PaymentService: PaymentService,private LoaderService: LoaderService, private CommonService: CommonService, private salesOrderService: SalesorderService, private KioskLayoutComponent: KioskLayoutComponent, private toastrService: ToastrService, private loaderServ: LoaderService, private _AR: ActivatedRoute, private GCS: GiftcardService, public kioskPOSService: KioskPOSService, public router: Router, private printService: PrintService, private merchantService: MerchantService) {
    this._AR.paramMap.subscribe(pr => {
      this.cardnumber = pr.get("CardNumber");
    });
    this.GCS.getGiftCardbyCardNumber(this.cardnumber).then(res => {
      this.GiftCard = res;
      this.GiftCard.Expiry = this.GiftCard.Expiry;
    });
    this.getMerchantProviderByCompanyId();
    this.location = JSON.parse(sessionStorage.location);
  }


  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      layout: {
        default: CommonSettings.keybSetting.numpadDef,
      },
      theme: CommonSettings.keybSetting.theme
    });
  }


  onChange = (input: any) => {
    this.Amount = input;
  };
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  public onBlurCardNumber(evt) {
    if (evt && evt.endsWith('?') && evt.split('?').length >= 3) {
      this.ccData = evt;
      this.cc_last_four = this.ccData?.split(';')[1]?.split('=')[0];
      this.cc_last_four = this.cc_last_four?.substring(this.cc_last_four.length - 4)
      this.createGiftCardSales('')
    } else {
      this.ccData = '';
    }
  }

  createGiftCardSales(paymentMode: string) {
    this.loaderServ.showPaymentloader();
    let postObj = new SalesOrder();
    this.CommonService.GetDateTimeByTimeZone().subscribe(
      async (tz) => {
        postObj.Tickets = [];
        postObj.Concession = [];
        postObj.AdditionalCharges = [];
        postObj.TotalAmount = this.Amount;
        postObj.SalesChannel = "Kiosk";
        postObj.TotalTax = 0;
        postObj.CurrencyType = "usd";
        postObj.PaymentMode = PaymentMode.CC;
        postObj.LocationId = this.kioskPOSService.getLocationId();
        postObj.LocationName = this.location.LocationName;
        postObj.DateTimeSold = moment(tz).format("YYYY-MM-DDThh:mm:ss.000[Z]");
        postObj.BarcodeImage = "";
        postObj.GiftCardNumber = "";
        let merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));
        postObj.MerchantName = this.selectedMerchant;
        postObj.MerchantCredentialId = merchantInternalId;

        var giftCardSale = new SalesOrderGiftCard();
        giftCardSale.GiftCardId = this.GiftCard.GiftCardId;
        giftCardSale.CardNumber = this.GiftCard.CardNumber;
        giftCardSale.Amount = this.Amount;
        giftCardSale.CompanyId = this.GiftCard.CompanyId;
        giftCardSale.GiftCardStatus = GiftCardStatus.Sold;
        giftCardSale.Recharged = true;
        giftCardSale.Expiry = this.GiftCard.Expiry;
        postObj.GiftCardSale.push(giftCardSale);

        // this.GiftCardSale.CompanyId = this.GiftCard.CompanyId;
        // this.GiftCardSale.GiftCardId = this.GiftCard.GiftCardId;
        // this.GiftCardSale.CardNumber = this.GiftCard.CardNumber;
        postObj.PaymentMode = PaymentMode.CC;
        // this.GiftCardSale.SalesChannel = 'Kiosk';
        // this.GiftCardSale.MerchantName = this.selectedMerchant;
        if (this.selectedMerchant == "Stripes") {
          if (this.kioskPOSService.isSwipeReaderExist) {
            var amount = (Number(this.Amount) * 100).toFixed()
            var ccData = 'stripes^' + amount + "^usd";
            postObj.CreditCardinfo = ccData;
            // this.GiftCardSale.PaymentMode = "CC";
          } else {
            this.loaderServ.hidePaymentloader();
            Swal.fire({
              title: this.es.gcErrorMsgList[31].message,
              text: this.es.gcErrorMsgList[32].message,
              showCancelButton: false,
              type: "warning",
            })
            return;
          }
          if (paymentMode == "VISA") {
            postObj.LastDigits = "2313";
          }
        } else if (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay") {
          postObj.CreditCardinfo = this.ccData;
          if (this.selectedMerchant == "Worldpay") {
            let posData = JSON.parse(sessionStorage.getItem("posData"));
            postObj.MerchantCredentialId = merchantInternalId;
            postObj.LaneId = posData.LaneId;
            postObj.MerchantName = "Worldpay";
            postObj.PaymentID = this.transactionid;
            postObj.CCLastFour = this.cc_last_four;
            postObj.PaymentType = this.cardTypeWorldpay;
          }
        }
        console.log('posObj', postObj)
        this.salesOrderService
          .PostSalesorderFormData(postObj)
          .subscribe(
            async (response) => {
              this.kioskPOSService.setSalesOrderData(response);
              // this.KioskLayoutComponent.CollectPaymentMethod(response);
              if (response.CreditCardinfo == "succeeded" || response.CreditCardinfo == "Approved") {
                this.LoaderService.hidePaymentloader();
                this.PaymentService.passPaymentIntentIdAuthorize(
                  response.ConfirmationCode,
                  response.PaymentID,
                  "true",
                  this.cc_last_four.toString()
                ).toPromise();
                this.kioskPOSService.pickupTicketData = response;
                this.toastrService
                  .success(
                   this.es.gcErrorMsgList[37].message,
                    "Success"
                  )
                  .onHidden.subscribe((re) => {
                    this.router.navigate([
                      "/kiosk/confirmTickets/" +
                      this.kioskPOSService.getLocationId(),
                    ]);
                  });
                this.cc_last_four = null;
              } else {
                this.PaymentService.passPaymentIntentIdAuthorize(
                  response.ConfirmationCode,
                  "failPayment",
                  "false",
                  this.cc_last_four.toString()
                ).toPromise();
                this.LoaderService.hidePaymentloader();
                this.cc_last_four = null;
                Swal.fire(
                  "",
                  this.es.kioskErrorMsgList[4].message,
                  "error"
                );
                // this.toastrService.error('Request Failed Payment Declined', 'error')
                this.router.navigate([
                  "/kiosk/checkout/" +
                  this.kioskPOSService.getLocationId(),
                ]);
              }
            }, (err) => {
              this.LoaderService.hidePaymentloader();
              Swal.fire(
                this.es.gcErrorMsgList[30].message,
                this.es.gcErrorMsgList[33].message,
                "error"
              );
              this.router.navigate([
                "/kiosk/checkout/" +
                this.kioskPOSService.getLocationId(),
              ]);
            }
          );
        // this.GCS.addGiftCardSales(this.GiftCardSale).then(res => {
        //   if (res) {
        //     // if(this.paymentDetails.isCardPayment == 1){
        //     res.ConfirmationCode = res.TransactionId;
        //     if (this.selectedMerchant == "Authorize.net") {
        //       if (res.Status == 4) {
        //         this.loaderServ.hidePaymentloader();
        //         Swal.fire("Payment failed", 'Error processing your card. Please try again.');
        //         this.ccData = "";
        //       }
        //       else {
        //         this.loaderServ.hidePaymentloader();
        //         this.toastrService.success('Payment Order placed successfully', 'Success').onHidden.subscribe(f => {
        //           this.printService.printGCS = res;
        //           this.printService.printDocument('giftcardrecharge', null).subscribe(res => {
        //             if (!res)
        //               this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
        //           });
        //         });
        //       }
        //       this.ccData = "";
        //     }
        //     else {
        //       if (this.KioskLayoutComponent.CollectPaymentMethod(res)) {
        //         this.loaderServ.hidePaymentloader();
        //         this.toastrService.success('Payment Order placed successfully', 'Success').onHidden.subscribe(f => {
        //           this.printService.printGCS = res;
        //           this.printService.printDocument('giftcardrecharge', null).subscribe(res => {
        //             if (!res)
        //               this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
        //           });
        //         });
        //       } else {
        //         this.ccData = "";
        //         this.loaderServ.hidePaymentloader();
        //         Swal.fire("Payment failed", 'Error processing your card. Please try again.');
        //         // this.toastrService.error("Payment Failed", 'Please contact support.');
        //       }
        //     }
        //     // }
        //   }
        // }).catch(res => {
        //   this.ccData = "";
        //   this.loaderServ.hidePaymentloader();
        //   Swal.fire("Payment failed", 'Error processing your card. Please try again.');
        // });
      });
  }
  ngOnInit() {
  }
  async getMerchantProviderByCompanyId() {
    let currentMerchantDetails = await this.merchantService.getCompanyMerchant().toPromise();
    this.selectedMerchant = currentMerchantDetails.MerchantName;
    console.log("selectedMerchant", this.selectedMerchant);
  }
  amountValidation(amount) {
    if (amount <= 0) {
      Swal.fire({
        title: this.es.gcErrorMsgList[31].message,
        text: this.es.gcErrorMsgList[34].message,
        showCancelButton: false,
        type: "warning",
      })
    } else {
      this.Step = 1;
      if (this.selectedMerchant == 'Worldpay') {
        this.sendSwipeIndication()
      }
    }
  }
  sendSwipeIndication() {
    if (this.selectedMerchant == 'Worldpay') {
      //let posData = JSON.parse(sessionStorage.getItem("posData"));
      //let merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));
      //this.Amount = Number(this.Amount);
      //this.merchantService.WorldpaySale(merchantInternalId, posData.LaneId, Number(this.Amount.toFixed(2)), 'Kiosk').subscribe(result => {
      //  if (result.statusCode == "Approved") {
      //    this.transactionid = result.transactionId;
      //    this.cardTypeWorldpay = result.paymentType;
      //    this.cc_last_four = result.binValue + result.accountNumber.substring(result.accountNumber.length - 4);
      //    this.createGiftCardSales('');
      //  } else if (result.statusCode.indexOf("insufficient") !== -1 || result.statusCode.indexOf("Merchant Credential") !== -1) {
      //    Swal.fire("Transaction failed", result.statusCode, "error");
      //    this.Step = 0;
      //  } else {
      //    // Swal.fire("Error", result.statusCode, "error");
      //    Swal.fire(this.es.gcErrorMsgList[35].message, this.es.gcErrorMsgList[36].message, "error");
      //    this.Step = 0;
      //  }
      //}, err => {
      //  // Swal.fire("Error", err, "error");
      //  Swal.fire(this.es.gcErrorMsgList[35].message, this.es.gcErrorMsgList[36].message, "error");
      //  this.Step = 0;
      //});
    }
  }
}
