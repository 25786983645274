export class ClientCompany {
    CompanyName: string = '';
    Address: string = '';
    LogoImage: string = '';
    Email: string = '';
    Phone: string = '';
    CompanyTypeName: string = '';
    CompanyType: CompanyType = new CompanyType();

    static getCompanyTypeName() {
        return [
            { name: "Cinemas", value: "cinema" },
            { name: "Restaurant", value: "restaurant" }
        ];
    }
}

export class CompanyType {
    Cinemas : CinemaOptions = new CinemaOptions();
    Restaurant : RestaurantOptions = new RestaurantOptions();
}

export class RestaurantOptions {
    isDineInAllowed: boolean = false;

    isDeliveryAllowed: boolean = false;

    isTakeOutAllowed: boolean = false;

    isCreditCardAllowed: boolean = false;
}

export class CinemaOptions {
    Name: string = '';
}
