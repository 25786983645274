import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private navComponentSource = new Subject<boolean>();
  private navComponentSourceD = new Subject<boolean>();
  testComponent$ = this.navComponentSource.asObservable();
  testComponentD$ = this.navComponentSourceD.asObservable();
  constructor() { }
  
  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Is it OK?');
    return of(confirmation);
    
  };
  setEnableIcon(value: boolean): void {
    this.navComponentSource.next(value);
  }
  setEnableIconDownload(value: boolean): void {
    this.navComponentSourceD.next(value);
  }
}
