import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successpayment',
  templateUrl: './successpayment.component.html',
  styleUrls: ['./successpayment.component.css']
})
export class SuccesspaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
