import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesAndOfferService } from '../featureandoffers/feautresandofferService';
import { StyleService } from '../helper/services/docloadings/style.service';
declare const $: any;

@Component({
  selector: 'app-bannersdisplay',
  templateUrl: './bannersdisplay.component.html',
  styleUrls: ['./bannersdisplay.component.css']
})
export class BannersdisplayComponent implements OnInit {
  comanyfeatures: any = null; 
   countryCodeExpression = /loc=([\w]{2})/;
  countryName: any;

  constructor(private featuresandofferservice: FeaturesAndOfferService, private router: Router, private stSer: StyleService, private http: HttpClient) {
    this.stSer.addStyle('bt', require('../../assets/kiosk-pos/css/bootstrap.min.css'));
  }

  ngOnInit() {
    this.featuresandofferservice.getAllCompanyFeatureAndOffer().subscribe(result => {
      this.comanyfeatures = result
      console.log(" this.comanyfeatures", this.comanyfeatures);
    }, error => console.error(error)
    );
    setTimeout(() => {
      $(".sli0").addClass('active')
      $('.carousel').carousel({
        interval: 3000
      })
    }, 3000);
    //get user location 
    $.get("https://www.cloudflare.com/cdn-cgi/trace", (res: any, status) => {
      let a = this.countryCodeExpression.exec(res);
      console.log(res);
      this.countryName= a[1];
  })
  }
   
    
  goToRegistrationPage() {
    this.router.navigate(['/registration']);
  }
  ngOnDestroy(): void {
    this.stSer.removeStyle('bt' );
  }
}
