export class Company {
    Company_ID: string = '';
    Company_Name: string = '';
    Address: string = '';
    City: string = '';
    State: string = '';
    Country: string = '';
    PostalCode: string = '';
    Phone: string = '';
    Email: string = '';
  LogoImage: string = '';
  LoyaltyPointsPerCurrency: string = '0';
  DefaultLoyaltyPoints: string = '0';
  RegistrationLoyaltyPoints: string = '0';
  CompanyBannerImage: string = '';
    CompanyTypeName: string = '';
    CompanyType: CompanyType = new CompanyType();
  IsAgreeableToTermsAndConditions: boolean = false;
  IsLoyaltyProgramEnable: boolean = false;
  PWAImage: string = '';
  JobEmail: string = '';
  SupportEmail: string = '';
  // for UI Only, No need to save in DB
  CurrentUrl: string = '';

    static getCompanyTypeName() {
      return [
        { name: "Theater", value: "cinemas", disabled: false },
        { name: "Restaurant", value: "restaurant", disabled: true }
      ];
    }
}

export class CompanyType {
    Cinemas : boolean = false;
    Restaurant: boolean = false; //RestaurantOptions = new RestaurantOptions();
}

//export class RestaurantOptions {
//    IsDineInAllowed: boolean = false;

//    IsDeliveryAllowed: boolean = false;

//    IsTakeOutAllowed: boolean = false;

//    IsCreditCardAllowed: boolean = false;
//}
