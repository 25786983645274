import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new Subject<boolean>();
  isLoadingReports = new Subject<boolean>();
  isLoadingPayment = new Subject<boolean>();
  isTimer = new Subject<boolean>();
  isCancel = new Subject<boolean>();
  isCancelOrder = new BehaviorSubject<boolean>(false);
  public show() {
    this.isLoading.next(true);
  }
  public hide() {
    this.isLoading.next(false);
  }
  public showReports() {
    this.isLoadingReports.next(true);
  }
  public hideReports() {
    this.isLoadingReports.next(false);
  }
  public showPaymentloader() {
    this.isLoadingPayment.next(true);
  }
  public hidePaymentloader() {
    this.isLoadingPayment.next(false);
  }
  public popOpen() {
    this.isCancel.next(true);
  }
  public popClose() {
    this.isCancel.next(false);
  }
  public startTimer() {
    this.isTimer.next(true);
  }
  public endTimer() {
    this.isTimer.next(false);
  }


}
