import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SharedModule } from '../shared.module';
import { DineinRoutingModule } from './dinein-routing.module';

import { DineincloseshiftComponent } from '../DineIn/dineincloseshift/dineincloseshift.component'


@NgModule({
  declarations: [
    DineincloseshiftComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    DineinRoutingModule,
  ]
})
export class DineinModule { }
