import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import Row from '@fullcalendar/resource-timeline/Row';
import Swal from 'sweetalert2';
import { ScreenService } from '../../screens/screen.service';
import { POSService } from 'src/app/pos/pos.service';
import { LoaderService } from '../../helper/services/loader/loader.service';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-booking',
  templateUrl: './kiosk-booking.component.html',
  styleUrls: ['./kiosk-booking.component.css']
})
export class KioskBookingComponent implements OnInit {
  private subscribed: any = [];
  public moviewScreenData: any = {};
  public imagePaths = {
    available: '../../../assets/kiosk-pos/img/available.svg',
    unavailable: '../../../assets/kiosk-pos/img/unavailable.svg',
    broken: '../../../assets/kiosk-pos/img/broken.svg',
    selected: '../../../assets/kiosk-pos/img/selected.svg',
    house: '../../../assets/kiosk-pos/img/house.png',
    handicap: '../../../assets/kiosk-pos/img/handicap.svg',
    companion: '../../../assets/kiosk-pos/img/companion.svg',
  };
  public seatsData: any = {};
  public selectedSeats: any = [];
  public holdSeats: any = [];
  public nextGapCount: number = 0;
  public previousGapCount: number = 0;
  /*public aisle : any = {
      aisleSeats
  };*/
  isLoading: boolean = false;
  allowTypes = ['available', 'companion', 'handicap'];
  constructor(public router: Router, public kioskPOSService: KioskPOSService, private PosService: POSService, public screenService: ScreenService, public loaderService:LoaderService,private es:ErrorMessagesService) {
    this.selectedSeats = this.kioskPOSService.getSeats();
    this.seatsData = {};
    this.holdSeats = [];
    this.getScreensDataForMovie();
    this.getSeatsData();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscribed.forEach(element => {
      element.unsubscribe();
    });
  }

  public getScreensDataForMovie() {
    this.kioskPOSService.movieData.forEach((item, index) => {
      if (item.LocationId == this.kioskPOSService.getLocationId() && item.MovieID == this.kioskPOSService.getMovieId()) {
        this.moviewScreenData = item;
        this.moviewScreenData.ShowProperties = this.kioskPOSService.getShowProperties();
      }
    });
  }

  public getSeatsData() {
    this.isLoading = true;
    this.loaderService.show();
    let getScreenSeatsData = this.screenService.getScreenByID(this.kioskPOSService.getScreenId()).subscribe((response) => {
      if (!response.Screen_ID || response.Screen_ID === null || response.Screen_ID === undefined || response.Screen_ID == 'undefined') {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[44].message, 'close');
        this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]); this.isLoading = false; this.loaderService.hide();
        return false;
      }
      this.seatsData = response;
      this.seatsData.Unavailable = [];
      if (this.seatsData.IsRowsReverse) {
        this.seatsData.Rows.reverse();
      }
      this.seatsData.displayAisle = [];
      this.seatsData.showAisle = [];
      this.processAisleForSeat();
      /*this.seatsData.Unavailable = ["D12","D13","D14","D19","F10","F20","A1","D1"];
      this.seatsData.House_Seats = ["A12","A13","A14"];*/
      /*Get Unavailable Seats From Sales Order API*/
      this.PosService.postGetSeatsCounts([this.kioskPOSService.getShowId()]).subscribe((res: any) => {
        this.isLoading = false;
        this.loaderService.hide();
        res = res.find(s => s.ShowId == this.kioskPOSService.getShowId())
        if (res.Seats?.length > 0) {
          this.seatsData.Unavailable = res.Seats;
        } else {
          this.seatsData.Unavailable = [];
        }
      }, err => {
          this.loaderService.hide();
        console.log(err);
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
        this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
        return false;
      });
      // let getSalesOrderDataForUnavailableSeats = this.kioskPOSService.SubmitGetFormData('Kiosk/GetSalesOrderByShowId/' + this.kioskPOSService.getShowId()).subscribe((SalesOrderResponse) => {
      //     if (SalesOrderResponse != "[]" && SalesOrderResponse.length > 0) {
      //         SalesOrderResponse.forEach((SalesOrderItem, SalesOrderIndex) => {
      //             if (SalesOrderItem && SalesOrderItem.Tickets && SalesOrderItem.Tickets.length > 0) {
      //                 SalesOrderItem.Tickets.forEach((TicketItem, TicketIndex) => {
      //                     if (TicketItem.SeatNumber && TicketItem.SeatNumber !== null && TicketItem.SeatNumber !== undefined && TicketItem.SeatNumber != '') {
      //                       /*TicketItem.SeatNumber.split(',');*/
      //                       if (TicketItem.Status != "1") {
      //                         if (TicketItem.ShowId == this.kioskPOSService.getShowId()) {
      //                           let UnavailableSeats = [TicketItem.SeatNumber];
      //                           this.seatsData.Unavailable = this.seatsData.Unavailable.concat(UnavailableSeats);
      //                           console.log("this.seatsData.Unavailable",this.seatsData.Unavailable);
      //                         }
      //                       }
      //                     }
      //                 });
      //             }
      //         });
      //     }
      // }, (error) => {
      //     console.log(error);
      //     this.kioskPOSService.showSnackBar('Some error occured!!.', 'close');
      //     this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
      //     return false;
      // });
      // console.log("getSalesOrderDataForUnavailableSeats",getSalesOrderDataForUnavailableSeats);
      // this.subscribed.push(getSalesOrderDataForUnavailableSeats);
      /*Get Unavailable Seats From Sales Order API*/
    }, (error) => {
      console.log(error);
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[45].message, 'close');
      this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
        this.isLoading = false; this.loaderService.hide();
      return false;
    });
    this.subscribed.push(getScreenSeatsData);
  }

  public createRange(number) {
    var items: number[] = [];
    if (this.seatsData.IsColsReverse) {
      for (var i = number; i >= 1; i--) {
        items.push(i);
      }
    }
    else {
      for (i = 1; i <= number; i++) {
        items.push(i);
      }
    }
    return items;
  }

  public createRangeForBlankSeat(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  public checkIsBlankSeat(blank: any, seat: string = '', seatNo: number = 0) {
    if (blank.Row == this.getRowSeatData(seat, 'name') && blank.SeatNumber == seatNo) {
      return true;
    }
    else {
      return false;
    }
  }

  public getRowSeatData(rowString: string = '', type: string = '') {
    if (rowString != '') {
      if (type == 'no')
        return parseInt(rowString.replace(/[^0-9]/g, ''));
      else
        return rowString.replace(/[^a-zA-Z]/g, '');
    }
    else {
      return (type == 'no' ? 0 : '');
    }
  }

  public processAisleForSeat() {
    if (this.seatsData.Aisle.length > 0) {
      this.seatsData.Aisle.forEach((ii, i) => {
        let string = ii.trim();
        let a = string.split(" ");
        let b = (a[0].trim()).split(":");
        let firstChar: any = this.getRowSeatData(b[0], 'name');
        let lastChar: any = this.getRowSeatData(b[1], 'name');
        let firstNo: any = this.getRowSeatData(b[0], 'no');
        let lastNo: any = this.getRowSeatData(b[1], 'no');
        let seats = [];
        if (a[1] == 'R' || a[1] == 'L') {
          for (var ch = firstChar.charCodeAt(0); ch <= lastChar.charCodeAt(0); ch++) {
            seats.push(String.fromCharCode(ch) + firstNo);
            this.seatsData.showAisle.push(String.fromCharCode(ch) + firstNo + a[1]);
          }
        }
        else {
          for (var ch = firstNo; ch <= lastNo; ch++) {
            seats.push(firstChar + ch);
            this.seatsData.showAisle.push(firstChar + ch + a[1]);
          }
        }
        let asileItem = {
          position: a[1],
          location: a[0],
          start: b[0],
          end: b[1],
          firstChar: firstChar,
          lastChar: lastChar,
          firstNo: firstNo,
          lastNo: lastNo,
          seats: seats
        }
        this.seatsData.displayAisle.push(asileItem);
      });
    }
  }

  public isSeatSelected(rowName: string = '', rowNo: number = 0) {
    var str = (rowName + rowNo.toString());
    var index = this.selectedSeats.indexOf(str);
    return (index >= 0 ? true : false);
  }

  public async seatClick(rowName: string = '', rowNo: number = 0, seatType: string = '') {
    if (this.isLoading) return;
    if (this.allowTypes.indexOf(seatType) >= 0) {
      var str = (rowName + rowNo.toString());
      var index = this.selectedSeats.indexOf(str);

      if (index >= 0) {
        this.selectedSeats.splice(index, 1);
        return false;
      }
      if (this.kioskPOSService.getTicketsTotalTickets() > this.selectedSeats.length) {
        if (seatType == 'handicap') {
          //this.kioskPOSService.showSnackBar('You have selected ' + seatType + ' seat.', 'close');
          let isHandicap = await Swal.fire({
            title: this.es.kioskErrorMsgList[14].message,
            text: this.es.kioskErrorMsgList[15].message,
            showConfirmButton: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          });

          if (isHandicap.value) {
            this.selectedSeats.push(str);
          }
          else {
            return false;
          }

        } else if (seatType == 'companion') {
          let isCompanion = await Swal.fire({
            title: this.es.kioskErrorMsgList[14].message,
            text: this.es.kioskErrorMsgList[16].message,
            showConfirmButton: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          });

          if (isCompanion.value) {
            this.selectedSeats.push(str);
          }
          else {
            return false;
          }
        }
        else {
          this.selectedSeats.push(str);
        }
        if (this.kioskPOSService.getTicketsTotalTickets() == this.selectedSeats.length) {
          return false;
        }
        /*console.log(this.selectedSeats);    */
      }
      else {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[46].message + this.kioskPOSService.getTicketsTotalTickets() + ' seats!.', 'close');
      }
    }
    else {
      return false;
    }
  }

  public checkSeatType(rowName: string = '', rowNo: number = 0) {
    var str = (rowName + rowNo.toString());
    if (this.holdSeats && this.holdSeats.indexOf(str) >= 0) {
      return "hold";
    } else if (this.seatsData.Unavailable && this.seatsData.Unavailable.indexOf(str) >= 0) {
      return 'unavailable';
    }
    else if (this.seatsData.Broken_Seats && this.seatsData.Broken_Seats.indexOf(str) >= 0) {
      return 'broken';
    }
    else if (this.seatsData.Hadicapped_Seats && this.seatsData.Hadicapped_Seats.indexOf(str) >= 0) {
      return 'handicap';
    }
    else if (this.seatsData.Companion && this.seatsData.Companion.indexOf(str) >= 0) {
      return 'companion';
    }
    else if (this.seatsData.House_Seats && this.seatsData.House_Seats.indexOf(str) >= 0) {
      return 'house';
    }
    else {
      return 'available';
    }
  }

  public submitSeatSelection(e) {
    if (this.kioskPOSService.getTicketsTotalTickets() == this.selectedSeats.length) {

      let IsolatedSeats = [];
      console.log("this.selectedSeats", this.selectedSeats)
      IsolatedSeats = this.validateSeatSelection();
      if (IsolatedSeats.length > 0) {
        this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[47].message + IsolatedSeats.join() + '.!');
        return false;
      }

      this.kioskPOSService.setSeats(this.selectedSeats);

      let body = [];
      this.selectedSeats.forEach((seat, i) => {
        if ((this.holdSeats.length == 0) || (this.holdSeats.length > 0 && this.holdSeats.indexOf(seat) < 0)) {
          body.push({
            Showid: this.kioskPOSService.getShowId(),
            SeatNumber: seat
          })
        }
      });
      if (body.length > 0) {
        this.kioskPOSService.SubmitPostFormData('HoldSeats', body).subscribe((response: any) => {
          if (response.length > 0) {
            this.kioskPOSService.userHoldSeats = response;
          }

          // navigate only after the hold seats passes.
          this.router.navigate(['/kiosk/add-food-packs/' + this.kioskPOSService.getLocationId()]);
        }, (error) => {
          console.log(error);

          this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[48].message, 'close');
          //this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
        });
      }

      /*Delete All user hold seats*/

      // Commenting the following code to keep the hold available until purchase.
      //if (this.kioskPOSService.userHoldSeats.length > 0) {
      //    let body = [];
      //    this.kioskPOSService.userHoldSeats.forEach((i, index) => {
      //        if (i.SeatNumber.indexOf(this.selectedSeats) < 0) {
      //            body.push(i.HoldSeatId);
      //        }
      //    });
      //    console.log(body);
      //    if (body.length > 0) {
      //        this.kioskPOSService.SubmitPostFormData('HoldSeats/DeleteHoldSeats', body).subscribe((response) => {
      //        });
      //        /*let DeleteHoldSeats = this.kioskPOSService.SubmitDeleteFormData('HoldSeats/DeleteHoldSeats', body);*/
      //    }
      //}
      /*Delete All user hold seats*/
    }
    else {
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[49].message + this.kioskPOSService.getTicketsTotalTickets() + ' seats!.', 'close');
    }
  }

  public backButton(e) {
    /*Delete All user hold seats*/
    if (this.kioskPOSService.userHoldSeats.length > 0) {
      let body = [];
      this.kioskPOSService.userHoldSeats.forEach((i, index) => {
        body.push(i.HoldSeatId);
      });
      console.log(body);
      /*let DeleteHoldSeats = this.kioskPOSService.SubmitDeleteFormData('HoldSeats/DeleteHoldSeats', body);
          this.kioskPOSService.userHoldSeats = [];*/
      this.kioskPOSService.SubmitPostFormData('HoldSeats/DeleteHoldSeats', body).subscribe((response) => {
        this.kioskPOSService.userHoldSeats = [];
      });

    }
    /*Delete All user hold seats*/
    this.kioskPOSService.unsetFinalData(['Seats']);
    this.router.navigate(['/kiosk/add-tickets/' + this.kioskPOSService.getLocationId()]);
  }


  public getHoldSeats() {
    let getHoldSeats = this.kioskPOSService.SubmitGetFormData('HoldSeats/GetHoldSeatsByShowId/' + this.kioskPOSService.getShowId()).subscribe((HoldSeatsResponse) => {
      if (HoldSeatsResponse != "[]" && HoldSeatsResponse.length > 0) {
        HoldSeatsResponse.forEach((HoldSeatsItem, HoldSeatsIndex) => {
          this.holdSeats.push(HoldSeatsItem.SeatNumber);
        });
        console.log(this.holdSeats);
      }
    }, (error) => {
      console.log(error);
    });
    this.subscribed.push(getHoldSeats);
  }

  public validateSeatSelection(): Array<string> {
    let SelectionObj = {};
    const NASeats = [...this.seatsData.Broken_Seats, ...this.seatsData.Hadicapped_Seats, ...this.seatsData.Companion, ...this.holdSeats, ...this.seatsData.Unavailable, ...this.seatsData.House_Seats];
    this.selectedSeats.forEach(s => {
      let row = s.replace(/[0-9]/g, '');
      let col = Number(s.replace(/[a-zA-Z]/g, ''));
      if (!SelectionObj[row]) {
        SelectionObj[row] = [];
      }
      SelectionObj[row].push(col);
    });

    console.log(SelectionObj);
    let IsolatedSeats = [];
    for (const row in SelectionObj) {
      SelectionObj[row].sort((a, b) => { return a - b });
      for (let i = 0; i < SelectionObj[row].length - 1; i++) {
        const col = SelectionObj[row][i];
        const nextCol = SelectionObj[row][i + 1];
        if (Math.abs(col - nextCol) == 2) {
          if (!NASeats.includes(row + (col + 1)))
            IsolatedSeats.push(row + (col + 1));
        }
      }
    }
    return IsolatedSeats;
  }
}
