import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { KioskRoutingModule } from './kiosk-routing.module';
import { KioskHomeComponent } from './kiosk-home/kiosk-home.component';
import { AuthguardGuard } from './authguard/authguard.guard';
import { KioskMovieListComponent } from './kiosk-movie-list/kiosk-movie-list.component';
import { KioskShowTimeComponent } from './kiosk-show-time/kiosk-show-time.component';
import { AddTicketsComponent } from './add-tickets/add-tickets.component';
import { KioskBookingComponent } from './kiosk-booking/kiosk-booking.component';
import { KioskFoodPacksComponent } from './kiosk-food-packs/kiosk-food-packs.component';
import { KioskCheckoutComponent } from './kiosk-checkout/kiosk-checkout.component';
import { KioskPaymentComponent } from './kiosk-payment/kiosk-payment.component';
import { KioskConfirmTicketComponent } from './kiosk-confirm-ticket/kiosk-confirm-ticket.component';
import { KioskConfirmTicketsComponent } from './kiosk-confirmTicket/kiosk-confirm-ticket.component';
import { KioskPickupComponent } from './kiosk-pickup/kiosk-pickup.component';
import { KioskPickupCcodeComponent } from './kiosk-pickup-ccode/kiosk-pickup-ccode.component';
import { KioskPickupCclastnoComponent } from './kiosk-pickup-cclastno/kiosk-pickup-cclastno.component';
import { KioskPickupBarcodeComponent } from './kiosk-pickup-barcode/kiosk-pickup-barcode.component';
import { KioskPickupPnumberComponent } from './kiosk-pickup-pnumber/kiosk-pickup-pnumber.component';
import { KioskPickupPrintComponent } from './kiosk-pickup-print/kiosk-pickup-print.component';
import { GiftcardFindComponent } from './giftcard-find/giftcard-find.component';
import { GiftcardRefillComponent } from './giftcard-refill/giftcard-refill.component';
import { KioskPOSService } from './services/kiosk-pos.service';

import { NgxBarcodeModule } from 'ngx-barcode';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';



@NgModule({
    imports: [
        CommonModule,
        KioskRoutingModule,
        SharedModule,
        NgxBarcodeModule
    ],
    declarations: [
        KioskHomeComponent,        
        KioskMovieListComponent,
        KioskShowTimeComponent,
        AddTicketsComponent,
        KioskBookingComponent,
        KioskFoodPacksComponent,
        KioskCheckoutComponent,
        KioskPaymentComponent,
        KioskConfirmTicketComponent,
        KioskConfirmTicketsComponent,
        KioskPickupComponent,
        KioskPickupCcodeComponent,
        KioskPickupCclastnoComponent,
        KioskPickupBarcodeComponent,
        KioskPickupPnumberComponent,
        KioskPickupPrintComponent,
        GiftcardFindComponent,
        GiftcardRefillComponent,
    ],
    providers:[
        AuthguardGuard,
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class KioskModule { }

export function KioskPOSServiceFactory(kioskPOSService: KioskPOSService): Function {
    return () => kioskPOSService.load();
}