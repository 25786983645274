import { Injectable, Inject } from "@angular/core";
import { Movie, MovieMovieRating, MovieType, PageResult } from "./movie";
import { Observable } from "rxjs"; 
import {
    HttpClient} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { ApiService } from "../helper/services/api.service";

@Injectable({
    providedIn: "root"
})
export class MovieService extends ApiService{
  ApiBaseUrl: string;
  BaseUri: string;
  
  Movies: Movie[] = [];
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
      super(baseUrl);
      this.ApiBaseUrl = baseUrl + "api/Movie";
    this.BaseUri = baseUrl;      
  }

  getMovies(): Observable<Movie[]> {
      return this.http
          .get<Movie[]>(this.ApiBaseUrl)
          .pipe(catchError(this.errorHandler));
  }
  
  getMovieByID(id: string): Observable<Movie> {
      return this.http
          .get<Movie>(this.ApiBaseUrl + "/" + id)
          .pipe(catchError(this.errorHandler));
  }

  getMoviesByName(name: string): Observable<Movie[]> {
    return this.http
      .get<Movie[]>(this.ApiBaseUrl + "/GetMoviesByName/" + name)
      .pipe(catchError(this.errorHandler));
  }

  addMovie(Movie: Movie): Observable<any> {
      return this.http
        .post(this.ApiBaseUrl +"/v1/AddMovieUI", Movie)
          .pipe(catchError(this.errorHandler));
  }

  editMovie(Movie: Movie): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl + "/v1/UpdateMovieUI",Movie)
          .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: number, visible: number): Observable<any> {
      return this.http
          .patch(this.ApiBaseUrl + '/' + ID + '/' + visible, null)
          .pipe(catchError(this.errorHandler));
  }

  getAllMovieRatings(): Observable<MovieMovieRating[]> {
    return this.http
      .get<MovieMovieRating[]>(this.ApiBaseUrl + "/GetAllMovieRatings")
      .pipe(catchError(this.errorHandler));
  }

  getMovieRatingByValue(value:number): Observable<MovieMovieRating> {
    return this.http
      .get<MovieMovieRating>(this.ApiBaseUrl + "/GetMovieRatingByValue/" + value)
      .pipe(catchError(this.errorHandler));
  }

  getAllMovieTypes(): Observable<MovieType[]> {
    return this.http
      .get<MovieType[]>(this.ApiBaseUrl + "/GetAllMovieTypes")
      .pipe(catchError(this.errorHandler));
  }

  getMovieTypeByValue(value: number): Observable<MovieType> {
    return this.http
      .get<MovieType>(this.ApiBaseUrl + "/GetMovieTypeByValue/" + value)
      .pipe(catchError(this.errorHandler));
  }

  getMoviesPaged(dt): Observable<PageResult> {
    if (dt && dt.order && dt.search) {
      return this.http.post<PageResult>(this.ApiBaseUrl + "/v1/GetMoviesPagedUI", dt)
    }
  }

}
