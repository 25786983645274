export class GiftCard {
  GiftCardId: string ='';
  CardNumber: any;
  TotalBalance: number = 0;
  Status: GiftCardStatus = GiftCardStatus.Registered;
  CompanyId: string = '';
  Expiry: Date = null;
  FirstName: string ='';
  LastName: string ='';
  PhoneNumber: string ='';
  Email: string = '';
  LocationId: string;
  LocationName: string;
  CreatedBy: string;
  UpdatedBy: string;
  Visible: number = 0;
  
}
export enum GiftCardStatus {
  Registered, 
  Sold, 
  Expired, 
  Pending, 
  Failed
}
