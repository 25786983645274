import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Keepalive } from '@ng-idle/keepalive';
import { NetworkStatusAngularService } from 'network-status-angular';
import { setTheme } from "ngx-bootstrap/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CurrentUserService } from "./helper/services/current-user.service";
import { PrintService } from "./helper/services/print.service";
import { InactivityService } from "./inactivity.service";
import { ImageService } from "./image.service";
//import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})

export class AppComponent implements OnInit {
  internetStatus = true;
  private destroy$ = new Subject<void>();

  formGroup: FormGroup;
  dateModel: Date = new Date();

  stringDateModel: string = new Date().toString();
  tabInactive: boolean = false;
  //idleState = 'Not started.';
  //timedOut = false;
  //lastPing?: Date = null;
  //title = 'angular-idle-timeout';

  constructor(private router: Router, private networkStatusAngularService: NetworkStatusAngularService,
    public printService: PrintService, /* private idle: Idle, */ private keepalive: Keepalive,
    private currentUserService: CurrentUserService, private inactivityService: InactivityService,private imageservice:ImageService) {
    setTheme("bs3");
    this.inactivityService.inactivityObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.handleInactivity();

      });
    console.log(window.location.protocol + '//' + window.location.host, window.location.href);
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('isLoggedin');
        let homeurl = window.location.protocol + '//' + window.location.host + "/";

        if (token == "false" && homeurl != window.location.href) { 
          // DO LOGOUT FROM THIS TAB AS WELL
          this.router.navigate(['/']).then(() => {
            window.location.reload();
            sessionStorage.clear();
            this.inactivityService.resetTimer();
          })
        }
      }
    }, false);
    this.networkStatusAngularService.status.subscribe(status => {
      this.internetStatus = status;
      if (this.internetStatus) {
        $(".modal-backdrop").remove();
        $("body").css('overflow', 'scroll');
        window.location.reload();
        // setTimeout(() => {
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([this.router.url]); 
        // let isthisPosComponent = this.router.url.split('/').includes("POS");
        // let isthisDoneinComponent = this.router.url.split('/').includes("DineIn") && this.router.url.split('/').includes("dinein");
        // if(!isthisPosComponent && !isthisDoneinComponent){
        // }else{
        //   this.router.onSameUrlNavigation = 'reload';
        // }
        // }, 1500);
      }
    });

    /*
    idle.setIdle(30);
    idle.setTimeout(60);

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();

      this.currentUserService.get().subscribe(result => {
        // do nothing.
        console.log('Keep alive call' + result)
      }, error => console.error(error))
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log('onTimeout Idle state : ' + this.idleState);

      this.currentUserService.get().subscribe(result => {
        // do nothing.
        console.log('Keep alive call' + result)
      }, error => console.error(error));
      this.reset();
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log('onIdleStart :' + this.idleState);

      this.currentUserService.get().subscribe(result => {
        // do nothing.
        console.log('Keep alive call' + result)
      }, error => console.error(error));
      this.reset();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log('onTimeoutWarning ' + this.idleState);

      this.currentUserService.get().subscribe(result => {
        // do nothing.
        console.log('Keep alive call' + result)
      }, error => console.error(error));
      this.reset();
    });
    */

    keepalive.interval(20 * 60);
    keepalive.onPing.subscribe(() => {
      this.keepalive.stop();
      this.currentUserService.get().subscribe(result => {
        // do nothing.
        //console.log('Keep alive call executed.')
        //console.log(result)
        this.keepalive.start();
      }, error => {
        //console.log('Keep alive call executed Error.')
        console.error(error);
        this.keepalive.start();
      })
    });

    this.reset();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  handleInactivity(): void {

    this.tabInactive = false;
    const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
    if (lastActivityTimestamp) {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - parseInt(lastActivityTimestamp, 10);
      const inactivityTimeout = 10 * 60 * 1000; // 10 minutes in milliseconds
      console.log(timeSinceLastActivity, inactivityTimeout)
      if (timeSinceLastActivity > inactivityTimeout) {
        console.log('Inactive for 10 minutes.');
        // Call your desired method here.
        this.inactivityService.resetTimer();

        localStorage.setItem("isLoggedin", "false");
        document.getElementById('signout').click();


      }
    }
  }
  ngOnInit() {
    $.fn.DataTable.ext.pager.numbers_length = 5;
    this.formGroup = new FormGroup({
      activeEndDate: new FormControl(new Date(), { validators: [Validators.required, DateTimeValidator] })
    }, { updateOn: 'change' });
    // interval(10000).subscribe(x => {
    //     this.CurrentUserService.getHelloWorldText().subscribe((data) => {
    //     console.log("DATA",data);
    //     });
    // });
     // Periodically check every second
    console.log("navigator.onLine", navigator.onLine);
    if (navigator) {
      if (navigator.onLine) {
        $(".modal-backdrop").remove();
        $("body").css('overflow', 'scroll');
        this.internetStatus = true;
      } else {
        this.internetStatus = false;
      }
    }
    this.clearOldDataFromIndex();

  }
  clearOldDataFromIndex() {
    this.imageservice.clearData();
  }
  reloadLocation() {
    if (navigator) {
      if (navigator.onLine) {
        window.location.reload();
      }
    }
  }
  reset() {
    //this.idle.watch();
    this.keepalive.start();
    //this.idleState = 'Started.';
    //this.timedOut = false;
  }
}

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};
