import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../locations/locations.service';

@Component({
  selector: 'app-tcpp',
  templateUrl: './tcpp.component.html',
  styleUrls: ['./tcpp.component.css']
})
export class TcppComponent implements OnInit {
  tcctemp:string="";
  pptemp:string="";
  constructor(private activatedRoute: ActivatedRoute, private locationService: LocationsService,private titleService:Title) {
    this.locationService.IshomePage=false;
   }

  ngOnInit(): void {
    if(this.activatedRoute.snapshot.paramMap.get('companyId')){
      sessionStorage.setItem('companyId', JSON.stringify(this.activatedRoute.snapshot.paramMap.get('companyId')));
      this.locationService.getCompanyByCompanyId(this.activatedRoute.snapshot.paramMap.get('companyId')).subscribe(async(company)=>{
        if(company&&company.LogoImage){
          this.locationService.Companylogo=company.LogoImage;
          this.titleService.setTitle(company.Company_Name);
          if(company.Company_ID==this.locationService.companyId){
            this.locationService.applecompany=true;
          }
          this.locationService.getTCCPPByCompanyId(this.activatedRoute.snapshot.paramMap.get('companyId')).subscribe((tccpp)=>{
            if(tccpp){
              this.tcctemp=tccpp.TermsAndCondition;
              this.pptemp=tccpp.PrivacyPolicy;
            }
          });
        }
        var locationDetail = JSON.parse(sessionStorage.getItem("location")!);
        if(locationDetail){
          if(locationDetail.logoImage && locationDetail.logoImage != ""){
            this.locationService.Companylogo= locationDetail.LogoImage;
          }
        }
      });
      
    }
  }

}
