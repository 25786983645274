import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpBackend} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Shift, FinalizeShift, Summary, ActualSummary, POSShift } from "./shift";
import { ApiService } from '../helper/services/api.service';
@Injectable({
	providedIn: 'root'
})
export class ShiftService extends ApiService{
	ApiBaseUrl: string;
	BaseUri: string;
	shift: POSShift[] = [];
  httpBackedurl:HttpClient;

	constructor(@Inject('BASE_URL') baseUrl: string, private http: HttpClient,private backend:HttpBackend) {
		super(baseUrl);
		this.ApiBaseUrl = baseUrl + 'api/ShiftInformation';
    this.BaseUri = baseUrl;
    this.httpBackedurl= new HttpClient(backend);
	}

	getShiftById(Id: string): Observable<Shift> {
		return this.httpBackedurl.get<Shift>(this.ApiBaseUrl + '/' + Id).pipe(catchError(this.errorHandler));
	}

	getPOSShift(): Observable<any> {
		return this.http.get(this.ApiBaseUrl + '/GetByLocationId').pipe(catchError(this.errorHandler));
	}
	IsPrevDayShiftOpen(): Observable<boolean> {
		return this.http.get<boolean>(this.ApiBaseUrl + '/IsPrevDayShiftOpen').pipe(catchError(this.errorHandler));
	}

	getPOSShiftByDate(StartDate: string, EndDate: string): Observable<any> {
		return this.http.get(this.ApiBaseUrl + '/GetByLocationandDateRange/' + StartDate + "/" + EndDate).pipe(catchError(this.errorHandler));
  }
  getShiftInformationListByLocationandDateRange(StartDate: string, EndDate: string): Observable<any> { //bug no 3493 sanmitra for  loading issue
    return this.http.get(this.ApiBaseUrl + '/GetShiftInformationListByLocationandDateRange/' + StartDate + "/" + EndDate).pipe(catchError(this.errorHandler));
  }

	openShift(shiftData): Observable<Shift> {
		return this.http
			.post<Shift>(this.ApiBaseUrl, shiftData).pipe(catchError(this.errorHandler));
	}

	endShift(shiftId: string): Observable<any> {
		return this.http
			.patch(this.ApiBaseUrl + "/EndShift/" + shiftId, null).pipe(catchError(this.errorHandler));
	}

	finalizeShift(shiftId: string, finalShiftData: FinalizeShift): Observable<any> {
		return this.http
			.patch(this.ApiBaseUrl + "/FinalizeShift/" + shiftId, finalShiftData).pipe(catchError(this.errorHandler));
	}

	getShiftSummary(shiftId: string): Observable<ActualSummary> {
		return this.http.get<ActualSummary>(this.ApiBaseUrl + '/ShiftSummary/' + shiftId).pipe(catchError(this.errorHandler));
	}

	getNetShiftSummary(shiftId: string): Observable<Summary> {
		return this.http.get<Summary>(this.ApiBaseUrl + '/getNetShiftSummary/' + shiftId).pipe(catchError(this.errorHandler));
	}

	getCurrentUser(): Observable<any> {
		return this.http.get(this.BaseUri + 'api/currrentuser').pipe(catchError(this.errorHandler));
	}

	getEmpByEmail(email: string): Observable<any> {
		return this.http.get(this.BaseUri + 'api/Employee/GetByEmail/' + email).pipe(catchError(this.errorHandler));
	}

	setVisible(ID: string, visible: number): Observable<any> {
		return this.http
			.patch(this.ApiBaseUrl + '/SetVisible/' + ID + '/' + visible, null)
			.pipe(catchError(this.errorHandler));
  }

  getOpenShiftByEmployeeIdForLocation(): Observable<any> {
    return this.http.get(this.ApiBaseUrl + '/GetOpenShiftByEmployeeIdForLocation').pipe(catchError(this.errorHandler));
  }

  refreshPOS(): Observable<any> {
    return this.http.get(this.ApiBaseUrl + '/RefreshPOS').pipe(catchError(this.errorHandler));
  }
  getAllUnclosedShiftInformationListByLocation(): Observable<any> {
    return this.http.get(this.ApiBaseUrl + '/GetAllUnclosedShiftInformationListByLocation').pipe(catchError(this.errorHandler));
  }
  
}
