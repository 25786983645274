import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DineincloseshiftComponent } from '../DineIn/dineincloseshift/dineincloseshift.component'

const routes: Routes = [
  {
    path: "dineinendshift/:shiftid",
    component: DineincloseshiftComponent,
    data: {}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DineinRoutingModule { }
