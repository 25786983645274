import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { CompanyService } from '../company/company.service';
import Swal from 'sweetalert2';
import { StyleService } from '../helper/services/docloadings/style.service';
import { LocationsService } from '../locations/locations.service';
import { Router } from '@angular/router';

declare const require:any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AdminLayoutComponent implements OnInit,OnDestroy {
  Company_ID: any;
  companyName: string = '';
  constructor(private locationService: LocationsService, private stser: StyleService, private clientCompanyService: CompanyService,private router : Router) {
  }

  ngOnInit() {
    this.clientCompanyService.getCompanyIdByContext().subscribe((res) => {
      this.Company_ID = res
      if (this.Company_ID) {
        this.locationService.getCompanyByCompanyId(this.Company_ID).subscribe((company) => {
          this.companyName = company.Company_Name;
        })
      } else {
        this.router.navigate(['/']).then(() => {
          window.location.reload();
          
        })
      }
    })
  }
  ngOnDestroy(): void {
  }

  getLocationName() {
    var locationName = "";
    var location = sessionStorage.getItem("location");
    if (location) {
      locationName = JSON.parse(sessionStorage.getItem("location")).Location_Name;
    }
    return locationName;
  }

  getLocationPhone() {
    var locationName = "";
    var location = sessionStorage.getItem("location");
    if (location) {
      locationName = JSON.parse(sessionStorage.getItem("location")).PhoneNumber;
    }
    return locationName;
  }

  getLocationAddress() {
    var locationName = "";
    var location = sessionStorage.getItem("location");
    if (location) {
      locationName = JSON.parse(sessionStorage.getItem("location")).Address;
    }
    return locationName;
  }
}
