import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Inject,
  HostListener,
} from "@angular/core";
import { Concession, ConcessionCategory } from "src/app/concession/concession";
import { ConcessionService } from "src/app/concession/concession.service";
import { FileUploadService } from "src/app/helper/services/file-upload.service";
import { Tax } from "src/app/tax/tax";
import { TaxService } from "src/app/tax/tax.service";
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { DineinService } from "src/app/DineIn/dinein.service";
import {
  SalesOrder,
  SalesStatus,
  PaymentMode,
  CustomerInfo,
  SalesOrderConcession,
} from "src/app/helper/models/SalesOrder";
import { RoundAmount, CloneObject } from "src/app/helper/models/AddHelperModal";
import { ToastrService } from "ngx-toastr";
import { take, delay } from "rxjs/operators";
import { PrintService } from "src/app/helper/services/print.service";
import { LoaderService } from "../../helper/services/loader/loader.service";
import { ScreenService } from "src/app/screens/screen.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import * as moment from "moment";
import { CommonService } from "src/app/helper/services/common.service";
import { MovieScheduleService } from "src/app/location-movie-schedule/movie-schedule.service";
import { MVStatus } from "src/app/location-movie-schedule/list/movieScheduleData";
import { DineStatsService } from "../dineStats.service";
import { CommonSettings } from "src/app/helper/commonSettings";
import { kitchenStatus } from "../dineModel";
import Keyboard from "simple-keyboard";
import { NgForm } from "@angular/forms";
import { Movie } from "src/app/movies/movie";
import { FoodItem } from "src/app/food/foodItem";
import { GiftcardService } from "src/app/giftcard/giftcard.service";
import { GiftCardSales } from "src/app/giftcard/GiftCardSales";
import { GiftCard, GiftCardStatus } from "src/app/giftcard/giftcard";
import { MerchantService } from "src/app/merchants/merchant.service";
import Client from "../../helper/services/payment/client";
import Logger from "../../helper/services/payment/logger";
import { Screen, SeatType, AsileData } from "../../screens/screen";
import { POSService } from "src/app/pos/pos.service";
import { ShiftService } from "src/app/shift/shift.service";
import { Shift, Summary } from "src/app/shift/shift";
import { CurrentUserService } from "../../helper/services/current-user.service";
import { KioskPOSService } from "src/app/kiosk-pos/services/kiosk-pos.service";
import { ErrorMessagesService } from "src/app/helper/services/error-messages.service";
import { SignalrService } from "../../helper/services/signalr.service";
import { PaymentoptionService } from '../../paymentoption/paymentoption.service';
import { UserIdleService } from "angular-user-idle";
import { LocationsService } from "../../locations/locations.service";
import { CompanyService } from "../../company/company.service";
import { SalesorderService } from "src/app/helper/services/salesorder.service";
declare var $: any;

@Component({
  selector: "app-dinein-home",
  templateUrl: "./dinein-home.component.html",
  styleUrls: ["./dinein-home.component.css"],
})
export class DineinHomeComponent implements OnInit {
  @ViewChild("ccAuthForm", { static: false }) ccAuthForm: NgForm;
  @ViewChild("myKeyboard", { static: false }) myKeyboard: ElementRef;
  @ViewChild("myInput", { static: false }) myInput: ElementRef;

  @Input() screen: Screen;
  objectKeys = (sc, reverse) => {
    return reverse ? Object.keys(sc).reverse() : Object.keys(sc);
  };
  LocationId: any;
  totalPrice = 0;
  error: String = "";
  isEditOrder: boolean = false;
  kitchenorders: Array<object> = [];
  kitchen: any = [];
  barorders: Array<object> = [];
  drinkItems: Concession[] = new Array<Concession>();
  snackItems: Concession[] = new Array<Concession>();
  comboItems: Concession[] = new Array<Concession>();
  regularFoods: Concession[] = new Array<Concession>();
  addonItems: Concession[] = new Array<Concession>();
  categories: ConcessionCategory[] = [];
  //conCat: Array<string> = Concession.getFilteredSubCategory().map(
  //  (r) => r.value
  //);
  //concatFood: Array<string> = Concession.getfoodSubCategory().map(
  //  (r) => r.value
  //);
  //concatDrink: Array<string> = Concession.getdrinksSubCategory().map(
  //  (r) => r.value
  //);
  MainConcessionArray: any = [];
  DisplayModifier: any = [];
  concatFood: any = [];
  concatDrink: any = [];
  updatedSales: string = "";
  shiftId: string = "";
  poslaneId: number = 0;
  Taxes: Array<Tax>;
  foodItems: any = [];
  CartTotalPrice: number = 0;
  location: any = "";
  locationScreenList: any;
  selectedScreenShowList: any = [];
  selectedScreenId: string = "";
  selectedScreenNumber: string = "";
  selectedScreenSeatList: any = [];
  selectedSeat: string = "";
  selectedShowId: string = "";
  activeTab: string;
  previousActiveTab: string = "kitchen";
  startDateForKitchen: string;
  endDateForKitchen: string;
  filterShift: any;
  filterShedule = {
    startDateTime: moment().toDate(),
    endDateTime: moment().toDate(),
  };
  KitchenStatus: any = kitchenStatus;
  currentOrders: Array<object>;
  historyOrders: Array<object>;
  concessionPaymentItem: any;
  priceKitchenItem: number = 0;
  tipamount: number = 0;
  activeTipsButton: string = "";
  keyboard: Keyboard;
  selectedInput: any;
  customerGivenCash = 0;
  isOtherSelectedFLag = false;
  cc: any = {};
  amountSuggestions = [];
  paymentError: string = "";
  ccFormDisabled: boolean = false;
  CardNumber: string = null;
  PayMode = PaymentMode;
  servedSalesOrderId: string;
  math = Math;
  currentMerchantDetails: any;
  selectedMerchant: string;
  GiftCards: GiftCardSales[] = new Array<GiftCardSales>();
  cc_auth_card_number: any;
  ccAuthorize: any = {};
  cc_last_four: any;
  client: any;
  ApiBaseUrl;
  apiurl: any;
  stripeSecretToken: string;
  terminal: any;
  upAisles: Array<string> = [];
  asile: Array<AsileData> = [];
  blankSeats: Object = {};
  drawScrn = {};
  isRowsReverse: boolean = false;
  isColsReverse: boolean = false;
  rows: number = 0;
  cols: number = 0;
  shiftData: Shift = new Shift();
  posData: any;
  summaryData: Summary = new Summary();
  empData: any = new Object();
  @ViewChild("defaultOpen", { static: true }) defaultOpen: ElementRef<
    HTMLElement
  >;
  @ViewChild("ccForm", { static: true }) ccForm: NgForm;
  locKitchen = false;
  merchantInternalId: any;
  transactionid: string = null;
  cardTypeWorldpay: any = null
  moment: any = moment;
  foodItemsCount: {};
  isSwipeReaderExist: boolean = false;
  @ViewChild("dinegcinput", { static: false }) dinegcinput: any;
  @ViewChild("dinemeinput", { static: false }) dinemeinput: any;
  @ViewChild("dinetipsinput", { static: false }) dinetipsinput: any;
  @ViewChild("dinecashinput", { static: false }) dinecashinput: any;

  // Need to add addon items to perticular fooditem using unique id
  AddonItemUniqueId: number;
  ManagerPin: string;
  cancelOrderId: any = null;
  cancelKitchenObj: any = null;
  SelectedDate: Date | string = '';
  timezoneDate: string | Date;
  previous: Date | string;
  minDate: Date | string;
  maxDate: Date | string;
  endDate: Date | string;
  SelectedDateSplit: Date | string = '';
  MinDateSplit: Date | string = '';
  MaxDateSplit: Date | string = '';
  data: any = '';
  hisitem: any = [];
  currorder: any = [];
  innerActiveTab: any = '';
  currentActiveBarTab: any = '';
  tabName: string = '';
  unpaidOrd: any = [];
  SelectDateTimeSold: any = '';
  prepAreaDrop = [];
  SelectedPrepArea: string = '';

  groupedKitchenOreder: Array<object> = [];
  currentDateTime: Date;
  cancelKOrders = [];
  QrOrderingOrder: any = [];
  allChecked: boolean = false;
  categoryGroup: any = [];
  displayConcession: any = [];
  selectedCategory: any = null;
  //refund
  SalesOrderId: string;
  salesOrderData: any = [];
  RefundArray: any = [];
  tempArr: any = [];
  counter: number = 0;
  constructor(
    private SalesorderService: SalesorderService,
    @Inject("BASE_URL") baseUrl: string,
    private concessionService: ConcessionService,
    private TaxService: TaxService,
    private router: Router,
    public dineinService: DineinService,
    private kioskPOSService: KioskPOSService,
    private toastService: ToastrService,
    private printService: PrintService,
    private LoaderService: LoaderService,
    private screenService: ScreenService,
    private commonServ: CommonService,
    private movieScheduleService: MovieScheduleService,
    private AR: ActivatedRoute,
    private statsService: DineStatsService,
    private giftCardService: GiftcardService,
    private merchantService: MerchantService,
    public posService: POSService,
    public shiftService: ShiftService,
    private es: ErrorMessagesService,
    protected currUserService: CurrentUserService,
    private signalrService: SignalrService,
    private paymentOptionService: PaymentoptionService,
    private userIdle: UserIdleService,
    private locationService: LocationsService,
    private clientCompanyService: CompanyService,
    private currentUserService: CurrentUserService,
  ) {
    // this.concessionService.getConcessionCategory().subscribe((response) => {

    this.LoaderService.isCancel.subscribe((res: any) => {
      if (res == true) {
        $("#cancelPayment").modal("show");
      }
      else if (res == false) {
        $("#cancelPayment").modal("hide");
      }
    })
    this.concessionService.getConcessionsCategory().subscribe((response) => {
      this.categories = response.filter(x => x.Visible == 1);
      // this.categories = response;
      this.categories.filter(x => x.value != 'addon').forEach(c => {
        if (c.SubCategory.length > 0) {
          c.SubCategory.forEach(scd => {
            this.categoryGroup.push({ Displayname: scd.DisplayName, value: scd.value, CategoryId: scd.SubCategoryID })
          })
        } else {
          this.categoryGroup.push({ Displayname: c.DisplayName, value: c.value, CategoryId: c.FoodCategoryId });
        }
      })
     // this.selectedCategory = this.categoryGroup[0].CategoryId;
      // this.categories.forEach(c => {
      //   if (c.value == 'food') {
      //     this.concatFood = c.SubCategory.map(r => r.value);
      //   }
      //   if (c.value == 'drink') {
      //     this.concatDrink = c.SubCategory.map(r => r.value);;
      //   }
      // })
    }, (error) => {
      this.error = error;
      return false;
    });
    this.concessionService.getPrepDetails().subscribe(res => {

      if (res.length > 0) {
        for (var i = 0; i < res.length; i++) {
          if (res[i].Name != "OpenArea") {

            this.prepAreaDrop.push(res[i]);
          }
        }
        // this.concession.PrepAreaId = res[0].PrepAreaId;
        this.SelectedPrepArea = this.prepAreaDrop[0].Name;
      }
    });
    this.location = JSON.parse(sessionStorage.location);
    this.merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));

    this.ApiBaseUrl = baseUrl + "api/";
    if (this.dineinService.isShiftOpen) {
      this.posData = JSON.parse(sessionStorage.getItem("posData"));
      this.shiftData = JSON.parse(sessionStorage.getItem("shiftData"));
      this.shiftData.StartTime = new Date(this.shiftData?.StartTime);
      this.shiftData.EndTime = new Date(this.shiftData?.EndTime);
    }
    this.paymentOptionService.kitchenSuccedUpdate.subscribe((success) => {
      if (success[0] == 'Success') {
        this.statsService.getKOrders(
          this.startDateForKitchen,
          this.endDateForKitchen
        );
        this.paymentOptionService.kitchenSuccedObservable.next([]);
      }
    });

    this.clientCompanyService.getCompanyLogoUrl();
  }



  @HostListener("document:click", ["$event"]) clickedOutside(ev: any) {
    if (this.myInput && this.myKeyboard) {
      if (
        !(
          this.myInput.nativeElement.contains(ev.target) ||
          this.myKeyboard.nativeElement.contains(ev.target)
        )
      ) {
        if (this.keyboard) {
          this.keyboard.destroy();
          this.keyboard = null;
        }
      }
    }
  }
  getStatus(status: number) {
    switch (status) {
      case 0:
        return "Cooking";
      case 1:
        return "Ready To Serve";
      case 2:
        return "Served";
      case 3:
        return "Re-Order";
      case 4:
        return "Paid";
      case 5:
        return "Canceled";
    }
  }
  async setPrintData(salesOrder) {
    let currentActiveTab = $(".KitchenMain").hasClass('active') ? 'Kitchen' : 'Bar';
    let localObj: any = {};
    // this.printService.kitchenOrderPrint = {};
    localObj.orderArray = salesOrder.filter(ci => ci.PrepAreaName == currentActiveTab);
    if (localObj.orderArray.length > 0) {
      // currentActiveTab = currentActiveTab + '#' + salesOrder[0].SalesOrderId;
      if (localObj.orderArray.length > 0) {
        localObj.display = true;
      } else {
        localObj.display = false;
      }
      localObj.orderArray.forEach(ko => {
        if (ko.Food.split('\n').length > 1) {
          ko.addon = ko.Food.split('\n');
        } else {
          ko.addon = [];
        }
      });
      localObj.selectePrepArea = currentActiveTab;
      localObj.DateTimeSold = localObj.orderArray[0].DateTimeSold;
      this.printService.kitchenOrderPrint = CloneObject(localObj);
    }
  }
  async ngOnInit() {
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      this.currentDateTime = new Date(tz);
      this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      this.SelectedDateSplit = this.SelectedDate.split('T')[0];
    });

    // this.paymentOptionService.KitchenPostObjectObservable.next([]);
    this.currUserService
      .getFeature("Kitchen", "write")
      .subscribe(async (hasAccess) => {
        this.locKitchen = hasAccess;
        // SignalR
        if (this.locKitchen) {
          if (this.signalrService.subsVar == undefined) {
            this.signalrService.subsVar = await this.signalrService.invokeFirstComponentFunction.subscribe(async (salesOrder: any) => {
              console.log("SignalR", salesOrder);
              let localsalesOrder = CloneObject(salesOrder);
              if (localsalesOrder && this.previousActiveTab == "kitchen") {
                localsalesOrder = await localsalesOrder.filter(x => x.LocationId == this.location.Location_ID);
                if (localsalesOrder && localsalesOrder.length > 0) {
                  setTimeout(async () => {
                    // let currentActiveTab = this.SelectedPrepArea=='Kitchen' ? 'Kitchen' : 'Bar';
                    let localObj: any = {};
                    // localObj.orderArray = localsalesOrder.filter(ci => ci.PrepAreaName == currentActiveTab);
                    let KitchenOrders = localsalesOrder.filter(ci => ci.PrepAreaName == this.SelectedPrepArea && ci.IsDisplay == true);
                    let barOrders = localsalesOrder.filter(ci => ci.PrepAreaName == this.SelectedPrepArea);
                    // console.log("localObj.orderArray",localObj.orderArray ,'Time-------------', new Date());
                    // if (localObj.orderArray.length > 0) {
                    //   currentActiveTab = currentActiveTab + '#' + localsalesOrder[0].SalesOrderId;
                    //   this.printService.printDocument("kitchen", [currentActiveTab])
                    //

                    // Update Kitchen salesorder on UI
                    await this.statsService.getKOrders(
                      this.startDateForKitchen,
                      this.endDateForKitchen
                    );
                    await this.statsService.getKitchenOrders.subscribe(
                      (data: any) => {
                        this.kitchenorders = data.sort(this.sortKitchenOrders);
                        this.LoaderService.hide();
                        console.log(this.kitchenorders);
                      },
                      (err) => {
                        this.LoaderService.hide();
                      }
                    );
                    if (KitchenOrders.length > 0 && this.sortKitchenOrders) {
                      let param1 = this.SelectedPrepArea + '#' + localsalesOrder[0].SalesOrderId;
                      this.printService.printDocument("kitchen", [param1])
                    }
                    //if (KitchenOrders.length > 0 && currentActiveTab == 'Kitchen') {
                    //  let param1 = 'Kitchen' + '#' + localsalesOrder[0].SalesOrderId;
                    //  this.printService.printDocument("kitchen", [param1])
                    //}
                    //if (barOrders.length > 0 && currentActiveTab == 'Bar') {
                    //  let param2 = 'Bar' + '#' + localsalesOrder[0].SalesOrderId;
                    //  this.printService.printDocument("kitchen", [param2])
                    //}
                  }, 500);
                  // setTimeout(() => {
                  //   let currentActiveTab = $(".KitchenMain").hasClass('active') ? 'Kitchen' : 'Bar';
                  //   this.setPrintData(salesOrder).then(() => {
                  //     if (this.printService.kitchenOrderPrint && this.printService.kitchenOrderPrint.orderArray.length > 0) {
                  //       this.printService.printDocument("kitchen", [currentActiveTab])
                  //     }
                  //   });
                  // }, 500);

                }
              }

            });
          }
        }
       
      });

    this.getMerchantProviderByCompanyId();
    $(".modal-backdrop").remove();
    try {
      this.Taxes = (
        await this.TaxService.getTaxsByLocationId().toPromise()
      ).filter((f) => f.Visible === 1);
    } catch (error) {
      Swal.fire(
        this.es.dineInErrorMsgList[19].message,
        this.es.dineInErrorMsgList[20].message,
        "error"
      );
      this.error =
        this.es.dineInErrorMsgList[20].message;
    }

    this.loadConcession();
    this.dineinService.foodItemsInCart.subscribe(
      (success) => (this.foodItems = success)
    );
    this.dineinService.totalPrice.subscribe((success) => {
      this.CartTotalPrice = Number(success.toFixed(2));
      // this.totalPrice = Number(success.toFixed(2));
    });
    this.currentUserService.getContextLocationId().subscribe(locId => {
      this.LocationId = locId;
      if (this.LocationId) {
        this.screenService.getScreens().subscribe((res) => {
          this.locationScreenList = res.filter((f) => f.Visible);
        });
      } else {
        Swal.fire({
          html: "OOPS! Session expired. Please login again.<br><br>" +
            '<a class="swal2-confirm swal2-styled" href="/authorization/signin">LOGOUT</a>',
          showCancelButton: false,
          showConfirmButton: false,
          type: "warning",
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }

    });
   
    this.dineinService.itemCount.subscribe((success) => {
      this.foodItemsCount = success;
    });
    this.commonServ.GetDateTimeByTimeZone().subscribe((tz) => {
      this.filterShift = {
        startDateTime: new Date(tz),
        endDateTime: new Date(tz),
      };
      this.currentDateTime = new Date(tz);
      console.log(this.filterShift);
      this.startDateForKitchen = moment(this.SelectedDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:00.000[Z]");
      this.endDateForKitchen = moment(this.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:00.000[Z]");
      this.statsService.getKOrders(
        this.startDateForKitchen,
        this.endDateForKitchen
      );

    });

    /*  this.statsService.getKitchenOrders.subscribe(
        (data: any) => {
          this.kitchenorders = data.sort(this.sortKitchenOrders);
          this.LoaderService.hide();
          console.log(this.kitchenorders);
        },
        (err) => {
          this.LoaderService.hide();
        }
      );*/

    this.statsService.getKitchenOrders.subscribe(
      (data: any) => {
        var count = 0;
        this.data = '';
        var endtimeDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
        var endDate = endtimeDate.split('T')[0];
        kitchenorderdata = [];
        this.kitchen = [];
        this.kitchenorders = [];
        this.hisitem = [];
        this.innerActiveTab = '';
        this.currorder = [];
        var kitchenorderdata = data.sort(this.sortKitchenOrders);
        if (kitchenorderdata.length > 0) {
          kitchenorderdata.forEach(orderobject => {
            let index = this.kitchen.findIndex((f) => f.KitchenId === orderobject.KitchenId);
            var orderDate = orderobject.DateTimeSold.split('T')[0];
            if (orderDate == endDate) {
              if (index === -1) {
                this.kitchen.push(orderobject);
                this.kitchenorders = this.kitchen;
              }
              else {
                if (count == 0) {
                  this.data = "No data found";
                  count++;
                }
              }
            }
          });

          if (this.activeTab == "UnpaidOrders") {
            this.kitchenorders = this.kitchenorders.filter((ki: any) => (ki.Status <= 3 && ki.PrepAreaName != "OpenArea" && ki.SalesChannel == "POS") || (ki.Status <= 3 && ki.PrepAreaName != "OpenArea" && ki.SalesChannel == "POS"));
          } else {
            this.kitchenorders = this.kitchen.filter((ki: any) => (ki.Status == 0 && ki.PrepAreaName == this.SelectedPrepArea) || (ki.Status == 1 && ki.PrepAreaName == this.SelectedPrepArea) || (ki.Status == 2 && ki.PrepAreaName == this.SelectedPrepArea));
            this.QrOrderingOrder = this.kitchen.filter((ki: any) => ki.SalesChannel == "QR ordering");
          }
          const groupby: any = [];

          var kitchenData = CloneObject(this.kitchenorders);
          kitchenData.forEach((order: any, index, array: any) => {

            const KOrder = order;
            const existgrouporder = groupby.filter((p: any) => p.SalesOrderId == order.SalesOrderId);

            if (existgrouporder.length == 0) {
              var existingKorder = this.kitchenorders.filter((p: any) => p.SalesOrderId == order.SalesOrderId);

              if (existingKorder) {

                KOrder.KitchenOrder = [];
                for (var i = 0; i < existingKorder.length; i++) {
                  KOrder.KitchenOrder.push(existingKorder[i]);
                }

                groupby.push(KOrder)




              }
            } else {

            }





          });

          // console.log("groupby", groupby);
          this.groupedKitchenOreder = groupby;
        }
        else {
          this.data = "No data found";
        }
        this.innerActiveTab = '';

        this.LoaderService.hide();
        console.log(this.kitchenorders);
      },
      (err) => {
        this.LoaderService.hide();
      }
    );
    // this.statsService.getKitchenOrders.subscribe((data: any) => {
    //   this.kitchenorders=[];
    //   this.barorders=[];
    //   if (data.length > 0) {
    //     data.forEach(orderobject => {
    //       if (orderobject.PrepAreaName == "Bar") {
    //         this.barorders.push(orderobject);
    //       } else if (orderobject.PrepAreaName == null || orderobject.PrepAreaName == "Kitchen") {
    //         this.kitchenorders.push(orderobject);
    //       }
    //     });
    //     if (this.kitchenorders.length > 0) {
    //       this.kitchenorders = this.kitchenorders.sort(this.sortKitchenOrders);
    //     }
    //     if (this.barorders.length > 0) {
    //       this.barorders = this.barorders.sort(this.sortKitchenOrders);
    //     }
    //     this.LoaderService.hide();
    //     console.log(this.kitchenorders, '------------------------------------------', this.barorders);
    //   }
    // },
    //   (err) => {
    //     this.LoaderService.hide();
    //   }
    // );
    this.activeTab = this.AR.snapshot.paramMap.get("activeTab");
    var selectprep = sessionStorage.getItem("SelectedPrepArea");
    if (selectprep) {
      this.SelectedPrepArea = selectprep;
    }

    this.giftCardService.giftCard.subscribe(
      (success) => (this.GiftCards = success)
    );
    this.LoadEmpData();
    this.LoadPOSActiveShiftSummary();

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(async count => {
      console.log("Reset Time.", count);
      this.userIdle.resetTimer();

      await this.locationService.getLocation().subscribe(async (loc) => {
        if (!loc) {
          console.log("getLocation", loc);
          console.log("Component location Id", this.location.Location_ID);
          this.router.navigate(["/locations/setting/" + this.location.Location_ID]);
        }
        else {
          window.location.reload();
        }
      });

    });

  }
  async sendNotification(saleorderId: any, prepareaId,prepareaname) {
    let temparr = [];
    let isSameSaleorder = false;
    temparr = await this.QrOrderingOrder.filter(f => (f.SalesOrderId == saleorderId && f.PrepAreaId != prepareaId && f.PrepAreaId != "" && f.Status !=5));
    //temparr = await temparr.filter(f => f.PrepAreaId != prepareaId)
    console.log(temparr);
    if (temparr.length > 0) {
      isSameSaleorder = true;
      Swal.fire({
        text: "Do you want to send the notification for all the prep area items?",
        type: 'warning',

        showConfirmButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, only for this prep area'
      }).then((result:any) => {
        console.log(result);
        if (result.value) {
          
          this.dineinService.SendOrderNotification(saleorderId, prepareaId, true).subscribe((res: any) => {
            
            Swal.fire('Notification Sent Succesfully', '', 'success');
            this.setPrepId(prepareaname);
          })
        } else if (result.dismiss == 'cancel') {
          this.dineinService.SendOrderNotification(saleorderId, prepareaId, false).subscribe((res: any) => {
            Swal.fire('Notification Sent Succesfully', '', 'success');
            this.setPrepId(prepareaname);

          })
        }
      })
    }
    else if (temparr.length==0 && !isSameSaleorder) {

      this.dineinService.SendOrderNotification(saleorderId, prepareaId, false).subscribe((res: any) => {
        Swal.fire('Notification Sent Succesfully', '', 'success');
        this.setPrepId(prepareaname);

      })
    }
  }
  async loadConcession() {
    this.concessionService.getPOSConcessionsByLocationId().subscribe(
      async ( res) => {
        res = res.filter(
          (f) =>
            f.Visible == 1 &&
            f.SalesChannel.find((f) => f.SalesChannelName === "POS")
        );
        res = res.map((f) => {
          f.ImageURL = FileUploadService.getImageUrl(f.ImageURL);
          f.Tax = this.Taxes.find((t) => t.TaxId == f.TaxId)?.Percent || 0;
          return f;
        });
        this.categoryGroup = await this.categoryGroup.filter((con, index) => {
          let food = res.filter((f) =>

            con.CategoryId == f.ConcessionSubCategoryID
          );
          if (food.length > 0) {
            return con;
          };
        });
        if (this.counter == 0) {
          this.selectedCategory = this.categoryGroup[0].CategoryId;
          this.counter++;
        }
        this.MainConcessionArray=res;
        this.displayConcession = res.filter((f) =>
          // [this.selectedCategory].includes(f.ConcessionCategory) || [this.selectedCategory].includes(f.ConcessionSubCategory)
          [this.selectedCategory].includes(f.ConcessionSubCategoryID)  && f.ParentId == ""
        );
        this.displayConcession.filter((f) => f.Complimentary == false);
        console.log(" this.displayConcession", this.displayConcession);
        // this.drinkItems = res.filter((f) =>
        //   ["drink"].includes(f.ConcessionCategory)
        // );
        // this.snackItems = res.filter((f) =>
        //   ["snack"].includes(f.ConcessionCategory)
        // );
        // this.comboItems = res.filter((f) =>
        //   ["combo"].includes(f.ConcessionCategory)
        // );
        this.addonItems = res.filter((f) =>
          ["addon"].includes(f.ConcessionCategory)
        );
        // this.regularFoods = res
        //   .filter(
        //     (f) => f.Visible == 1 && ["food"].includes(f.ConcessionCategory)
        //   )
        //   .map((f) => {
        //     f.ImageURL = FileUploadService.getImageUrl(f.ImageURL);
        //     return f;
        //   });
        // this.regularFoods.filter((f) => f.Complimentary == false);
        // this.drinkItems.filter((f) => f.Complimentary == false);
        // this.snackItems.filter((f) => f.Complimentary == false);
        // this.comboItems.filter((f) => f.Complimentary == false);
        this.addonItems.filter((f) => f.Complimentary == false);
      },
      (err) => (this.error = err)
    );
  }
  selectCategory(cat) {
    console.log("category----------", cat);
    this.selectedCategory = cat;
    this.loadConcession();
  }
  setDate(evt, tabName) {
    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    this.startDateForKitchen = moment(this.SelectedDate)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:00.000[Z]");
    this.endDateForKitchen = moment(this.SelectedDate)
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:00.000[Z]");

    this.statsService.getKOrders(
      this.startDateForKitchen,
      this.endDateForKitchen
    );

    var endDate = '';
    var endtimeDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
    endDate = endtimeDate.split('T')[0];

    this.statsService.getKitchenOrders.subscribe(
      (data: any) => {
        var count = 0;
        this.data = '';
        kitchenorderdata = [];
        this.kitchen = [];
        this.kitchenorders = [];
        this.innerActiveTab = '';
        this.currorder = [];
        var kitchenorderdata = data.sort(this.sortKitchenOrders);
        if (kitchenorderdata.length > 0) {
          kitchenorderdata.forEach(orderobject => {
            let index = this.kitchen.findIndex((f) => f.KitchenId === orderobject.KitchenId);
            var orderDate = orderobject.DateTimeSold.split('T')[0];
            if (orderDate == endDate) {
              if (index === -1) {
                this.kitchen.push(orderobject);
                this.kitchenorders = this.kitchen;
              }
              else {
                if (count == 0) {
                  this.data = "No data found";
                  count++;
                }
              }
            }
          });
        }
        else {
          this.data = "No data found";
        }
        var tabValue = tabName ?? "null";
        if (tabValue !== "null") {
          if (tabName != "UnpaidOrders") {
            this.innerActiveTab = '';
            this.data = '';
            // let currentActiveTab = $(".KitchenMain").hasClass('active') ? 'Kitchen' : 'Bar';
            if (this.SelectedPrepArea) {
              this.innerActiveTab = tabName;
              if (this.innerActiveTab == this.SelectedPrepArea) {
                this.currentActiveBarTab = this.innerActiveTab == this.SelectedPrepArea ? 'order' : 'served';
              }
              else { this.currentActiveBarTab = ''; }
              if (this.innerActiveTab == 'order' || this.currentActiveBarTab == 'order') {
                if (this.kitchen.length > 0) {
                  this.kitchenorders = [];
                  this.kitchenorders = this.kitchen.filter((ki: any) => (ki.Status == 0 && ki.PrepAreaName == this.SelectedPrepArea) || (ki.Status == 1 && ki.PrepAreaName == this.SelectedPrepArea) || (ki.Status == 2 && ki.PrepAreaName == this.SelectedPrepArea));

                  //  this.currorder = this.kitchenorders.filter((ki: any) => (ki.Status == 0 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 1 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 2 && ki.PrepAreaName == this.SelectedPrepArea ));

                  this.currorder = this.kitchenorders;
                  if (this.currorder.length == 0) {
                    this.data = "No data found";
                    this.kitchenorders = [];
                  }

                } else {
                  this.data = "No data found";
                  this.kitchenorders = [];
                }
              }
              else {
                if (this.kitchen.length > 0) {
                  this.kitchenorders = [];
                  this.kitchenorders = this.kitchen.filter((ki: any) => (ki.Status == 3 && ki.PrepAreaName == this.SelectedPrepArea) || (ki.Status == 4 && ki.PrepAreaName == this.SelectedPrepArea));
                  // this.hisitem = this.kitchenorders.filter((ki: any) => (ki.Status == 3 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 4 && ki.PrepAreaName == this.SelectedPrepArea ));
                  this.hisitem = this.kitchenorders;
                  console.log("filter kitchen", this.kitchenorders)
                  if (this.hisitem.length == 0) {
                    this.data = "No data found";
                    this.kitchenorders = [];
                  }

                } else {
                  this.data = "No data found";
                  this.kitchenorders = [];
                }
              }
            }
            //else if (this.SelectedPrepArea == 'Bar') {
            //  this.innerActiveTab = tabName;
            //  this.currorder = '';
            //  this.hisitem = '';
            //  this.currentActiveBarTab = '';
            //  if (this.innerActiveTab == "Bar") {
            //    this.currentActiveBarTab = this.innerActiveTab == "Bar"  ? 'order' : 'served';
            //  }
            //  else { this.currentActiveBarTab = ''; }
            //  if (this.currentActiveBarTab == 'order' || this.innerActiveTab == 'order') {
            //    if (this.kitchen.length > 0) {
            //      this.kitchenorders=[];
            //      this.kitchenorders=this.kitchen.filter((ki: any) => (ki.Status == 0 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 1 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 2 && ki.PrepAreaName == this.SelectedPrepArea ));

            //     // this.currorder = this.kitchenorders.filter((ki: any) => (ki.Status == 0 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 1 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 2 && ki.PrepAreaName == this.SelectedPrepArea ));
            //     this.currorder= this.kitchenorders;
            //      if (this.currorder.length == 0) {
            //        this.data = "No data found";
            //        this.kitchenorders=[];
            //      }

            //    } else {
            //      this.data = "No data found";
            //      this.kitchenorders=[];
            //    }
            //  }
            //  else {
            //    if (this.kitchenorders.length > 0) {
            //      this.kitchenorders=[];
            //      this.kitchenorders= this.kitchen.filter((ki: any) => (ki.Status == 3 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 4 && ki.PrepAreaName == this.SelectedPrepArea ));

            //     // this.hisitem = this.kitchenorders.filter((ki: any) => (ki.Status == 3 && ki.PrepAreaName == this.SelectedPrepArea ) || (ki.Status == 4 && ki.PrepAreaName == this.SelectedPrepArea ));
            //     this.hisitem=this.kitchenorders;
            //      if (this.hisitem.length == 0) {
            //        this.data = "No data found";
            //        this.kitchenorders=[];
            //      }

            //    } else {
            //      this.data = "No data found";
            //      this.kitchenorders=[];
            //    }

            //  }
            //  // }
            //}
          }
          else {
            if (this.kitchenorders.length > 0) {
              this.unpaidOrd = [];
              this.kitchenorders = this.kitchenorders.filter((ki: any) => (ki.Status <= 3 && ki.PrepAreaName != "OpenArea" && ki.SalesChannel == "POS") || (ki.Status <= 3 && ki.PrepAreaName != "OpenArea" && ki.SalesChannel == "POS"));
              this.unpaidOrd = this.kitchenorders;

              if (this.unpaidOrd.length == 0) {
                this.data = "No data found";
                this.kitchenorders = [];
              }
            } else {
              this.data = "No data found";
              this.kitchenorders = [];
            }
          }
        }

        const groupby: any = [];

        var kitchenData = CloneObject(this.kitchenorders);
        kitchenData.forEach((order: any, index, array: any) => {

          const KOrder = order;
          const existgrouporder = groupby.filter((p: any) => p.SalesOrderId == order.SalesOrderId);

          if (existgrouporder.length == 0) {
            var existingKorder = this.kitchenorders.filter((p: any) => p.SalesOrderId == order.SalesOrderId);

            if (existingKorder) {

              KOrder.KitchenOrder = [];
              for (var i = 0; i < existingKorder.length; i++) {
                KOrder.KitchenOrder.push(existingKorder[i]);
              }

              groupby.push(KOrder)




            }
          } else {

          }





        });

        console.log("groupby", groupby);
        this.groupedKitchenOreder = groupby;
        this.LoaderService.hide();

        // this.LoaderService.hide();
        // console.log(this.kitchenorders);
      },
      (err) => {
        this.LoaderService.hide();
      }
    );
  }
  async getMerchantProviderByCompanyId() {
    this.currentMerchantDetails = await this.merchantService
      .getCompanyMerchant()
      .toPromise();
    this.selectedMerchant = this.currentMerchantDetails.MerchantName;
    console.log("this.selectedMerchant", this.selectedMerchant);
    if (this.selectedMerchant == "Stripes") {
      var url = this.ApiBaseUrl + "SalesOrder/GetStripeConnection";
      this.apiurl = url;
      this.stripeSecretToken = "";
      this.loadStripe(url);
    }
  }

  openTabs(evt, tabName) {
    if (tabName == "served") {
      $("#served").addClass('active');
      $(".served").addClass('active');
      $("#order").removeClass('active');
      $(".order").removeClass('active');
    } else if (tabName == "order") {
      $("#order").addClass('active');
      $(".order").addClass('active');
      $("#served").removeClass('active');
      $(".served").removeClass('active');
    }
    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      // this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      this.setDate(evt, tabName);

    });
    // // if(tabName == )
    // var i, tabcontent, tablinks;
    // tabcontent = document.getElementsByClassName("tabcontent");
    // for (i = 0; i < tabcontent.length; i++) {
    //   tabcontent[i].style.display = "none";
    // }
    // tablinks = document.getElementsByClassName("tablinks");
    // for (i = 0; i < tablinks.length; i++) {
    //   tablinks[i].className = tablinks[i].className.replace(" active", "");
    // }
    // document.getElementById(tabName).style.display = "block";
    // evt.currentTarget.className += " active";
  }
  openTabsBar(evt, tabName) {
    if (tabName == "servedBar") {
      $("#servedBar").addClass('active');
      $(".servedBar").addClass('active');
      $("#orderBar").removeClass('active');
      $(".orderBar").removeClass('active');
    } else if (tabName == "orderBar") {
      $("#orderBar").addClass('active');
      $(".orderBar").addClass('active');
      $("#servedBar").removeClass('active');
      $(".servedBar").removeClass('active');
    }
    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      this.setDate(evt, tabName);
    });
  }
  openMainTabs(evt, tabName) {
    if (tabName == "KitchenMain") {
      $("#KitchenMain").addClass('active');
      $(".KitchenMain").addClass('active');
      $("#BarMain").removeClass('active');
      $(".BarMain").removeClass('active');

      $("#orderBar").addClass('active');
      $(".orderBar").addClass('active');
      $("#servedBar").removeClass('active');
      $(".servedBar").removeClass('active');
    } else if (tabName == "BarMain") {
      $("#BarMain").addClass('active');
      $(".BarMain").addClass('active');
      $("#KitchenMain").removeClass('active');
      $(".KitchenMain").removeClass('active');

      $("#orderBar").addClass('active');
      $(".orderBar").addClass('active');
      $("#servedBar").removeClass('active');
      $(".servedBar").removeClass('active');
    }
    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      this.setDate(evt, tabName);
    });
  }
  changeStatus(status: number, kitchenId: string, payType: string) {
    if (status == 4) {
      Swal.fire("Success", this.es.dineInErrorMsgList[21].message);
      return;
    }
    this.LoaderService.show();
    let updatedStatus: number;

    if (status == 3) {
      if (payType == "payNow") {
        updatedStatus = status + 1;
      } else {
        updatedStatus = 0;
      }
    } else {
      updatedStatus = status + 1;
    }

    this.dineinService
      .updateKitchenStatus(kitchenId, updatedStatus, {})
      .subscribe(
        (data) => {
          this.statsService.getKOrders(
            this.startDateForKitchen,
            this.endDateForKitchen
          );
        },
        (err) => {
          this.LoaderService.hide();
        }
      );
  }
  getBySubcategory(cat: string): Array<Concession> {
    return this.regularFoods.filter((f) => f.ConcessionSubCategory === cat);
  }
  getDrinksBySubcategory(cat: string): Array<Concession> {
    return this.drinkItems.filter((f) => f.ConcessionSubCategory === cat);
  }

  sidenavtabclick(tab: string) {
    $(".sidenavtab").attr("aria-selected", "false");
    $(".sidenavtab").removeClass("active");
    // $(".main-content-section").attr("style", "width: 93% !important");
    $(".main-content-section").addClass("main-content-section-wor");
    $(".headerTab").attr("aria-selected", "false");
    $(".headerTab").removeClass("active");
    $("#mainPOSMenu").removeClass("show");
    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    if (tab == "UnpaidOrders")
      this.previousActiveTab = tab;
    else
      this.previousActiveTab = "kitchen";

    this.activeTab = tab;
    this.router.navigate(["./DineIn", this.activeTab]);
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      if (tab == 'kitchen') {
        this.SelectedPrepArea = 'Kitchen';
        this.setDate("", 'Kitchen');
      }
      else {
        this.setDate("", tab);
      }
    });
    if (tab == "activeSummary") {
      this.LoadPOSActiveShiftSummary();
    }
  }
  headertabclick() {
    $(".sidenavtab").attr("aria-selected", "false");
    $(".sidenavtab").removeClass("active");
    // $(".main-content-section").css("width", "calc(100% - 498px)");
    $(".headerTab").attr("aria-selected", "false");
    $(".headerTab").removeClass("active");
    $("#mainPOSMenu").removeClass("show");
    $(".main-content-section").removeClass("main-content-section-wor");
  }
  ToggleSidebar() {
    $(".sidebar-section").toggleClass("active");
  }
  manageFood(operation, food) {
    // if ((operation == "plus" || operation == "addonplus") &&
    // ( (food.ConcessionCategory == "addon" && food.ParentType.Inventory?.length !=0) || food.ConcessionCategory == "drink" || food.ConcessionCategory == "snack" || food.ConcessionCategory == "combo") && food.TotalInventoryQty == 0) {
    //&& food.ParentType.food?.length == 0
    //manageFood
    if (food.IsParent) {
        $("#ModiFierPopup").show();
        this.DisplayModifier = this.MainConcessionArray.filter(f=>f.IsParent == false && f.ParentId == food.ConcessionId)
        console.log("DISPLAY MODIFIER",this.DisplayModifier)
      }else{
    if ((operation == "plus" || operation == "addonplus") &&
      ((food.ParentType.Inventory?.length != 0)) && food.TotalInventoryQty == 0) {
      Swal.fire({
        title: this.es.dineInErrorMsgList[7].message,
        text: this.es.dineInErrorMsgList[22].message,
        showCancelButton: false,
        type: "warning",
      })
      return;
    } else if (
      (operation == "plus" || operation == "addonplus") &&
      ((food.ParentType.Inventory?.length != 0)) &&
      this.foodItemsCount[food.ConcessionId] + 1 > food.TotalInventoryQty
    ) {
      Swal.fire({
        title: this.es.dineInErrorMsgList[4].message,
        text: this.es.dineInErrorMsgList[23].message + food.TotalInventoryQty + this.es.dineInErrorMsgList[24].message,
        showCancelButton: false,
        type: "warning",
      })
      return;
    } else {
      // If it is addon plus, we need to add parent concession name and unique id to food item
      if (operation == "addonplus") {
        var addonItemUniqueId = this.AddonItemUniqueId;
        this.foodItems.forEach(function (part, index) {
          if (this[index].itemUniqueId == addonItemUniqueId) {
            food.ParentItemUniqueId = this[index].itemUniqueId;
            food.AddonConcessionName = this[index].ConcessionName + "_" + index;
          }
        }, this.foodItems);
      } else if (food.AddonConcessionName === undefined) {
        food.ParentItemUniqueId = null;
        food.AddonConcessionName = null;
      }

      console.log("food", food.ConcessionId);
      this.dineinService.changeFoodItemsInCart(operation, CloneObject(food));

      // If we remove food item we need to find addon items and remove
      if (operation == "minus") {
        var minusItems = this.foodItems.filter(
          (x) => x.ParentItemUniqueId == food.itemUniqueId
        );
        for (let item in minusItems) {
          this.dineinService.changeFoodItemsInCart(
            operation,
            CloneObject(minusItems[item])
          );
        }
      }

      this.dineinService.foodItemsInCart.subscribe((success) => {
        this.foodItems = success;
      });
      this.dineinService.totalPrice.subscribe((success) => {
        this.totalPrice = Number(success.toFixed(2));

      });
    }
  }
    // this.dineinService.itemCount.subscribe((success) => {
    //   this.foodItemsCount = success;
    // });
  }
  async editOrder() {
    $("#SchreenSeatsPopup").modal("show");
  }

  async confirmOrder() {
    this.SelectDateTimeSold = "";
    var FoodItemsClone = CloneObject(this.foodItems);
    let checkinvPostVal = [];
    await FoodItemsClone.forEach((fi) => {
      if (
        fi.AddonConcessionName == null &&
        FoodItemsClone.filter((x) => x.ParentItemUniqueId == fi.itemUniqueId)
          .length == 0
      ) {
        fi.Quantity = FoodItemsClone.filter(
          (ef) =>
            ef.ConcessionId === fi.ConcessionId &&
            !(
              FoodItemsClone.filter(
                (x) => x.ParentItemUniqueId == ef.itemUniqueId
              ).length > 0
            )
        ).length;

        if (
          !checkinvPostVal.find(
            (ef) =>
              ef.ConcessionId === fi.ConcessionId &&
              !(
                FoodItemsClone.filter(
                  (x) => x.ParentItemUniqueId == ef.itemUniqueId
                ).length > 0
              )
          )
        ) {
          checkinvPostVal.push(fi);
        }
      } else {
        fi.Quantity = FoodItemsClone.filter(
          (ef) => ef.itemUniqueId === fi.itemUniqueId
        ).length;
        if (!checkinvPostVal.find((ef) => ef.itemUniqueId === fi.itemUniqueId)) {
          checkinvPostVal.push(fi);
        }
      }
    });
    this.kioskPOSService.checkConcessionInventory(checkinvPostVal).subscribe(
      (res: any) => {
        if (res.length > 0) {
          // let isAre = res.length == 1 ? " is " : " are ";
          // let items = res.join(", ");
          // this.LoaderService.hidePaymentloader();
          // Swal.fire({
          //   title: "OOPS..!",
          //   text: items + isAre + "out of stock in the inventory",
          //   showCancelButton: false,
          //   type: "warning",
          // });
          let items = res.join(", ");
          console.log("items", items)
          let scentence = [];
          for (var i = 0; i <= (res.length - 1); i++) {
            scentence.push(res[i])
          }
          let fullScentence = '';
          scentence.forEach((f, i) => {
            f = f.split('_')
            fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
          });
          this.LoaderService.hidePaymentloader();
          Swal.fire({
            title: this.es.dineInErrorMsgList[25].message,
            text: this.es.dineInErrorMsgList[26].message + fullScentence + this.es.dineInErrorMsgList[27].message,
            showCancelButton: false,
            type: "warning",
          });
          this.router.navigate(["./DineIn", this.activeTab]);
        } else {
          $("#SchreenSeatsPopup").modal("show");
        }
      },
      (err) => {
        this.LoaderService.hidePaymentloader();
        Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
        console.log(err);
      }
    );
  }
  async loadShowForthisScreen(screen_Id) {
    this.selectedShowId = "";
    this.selectedScreenShowList = [];
    this.selectedScreenSeatList = [];
    this.selectedScreenId = screen_Id;
    this.selectedScreenNumber = await this.locationScreenList.find(
      (screen) => screen.Screen_ID == screen_Id
    ).Screen_Number;
    this.selectedSeat = "";
    await this.commonServ.GetDateTimeByTimeZone().subscribe(async (dt) => {
      var date = new Date(dt);
      const strDate = moment(date).format();
      let startDate = strDate.split("T")[0] + "T00:00:00.000Z";
      let startDateForFilter =
        strDate.split("T")[0] + "T" + strDate.split("T")[1].split("+")[0];
      let endDate = strDate.split("T")[0] + "T23:59:59.999Z";
      await this.movieScheduleService
        .geMovieSchedulesByLocationIdAndDateRange(startDate, endDate)
        .subscribe(async (res) => {
          res = res.filter((s) => s.EndDateTime >= startDateForFilter);
          startDate = startDate.replace("Z", "");
          await res.forEach((f) => {
            var isPOSMovie = f.SalesChannel.find(
              (s) => s.SalesChannelName == "POS"
            );
            if (
              f.ScreenID == screen_Id &&
              isPOSMovie &&
              f.Visible == 1 &&
              f.Status == MVStatus.open &&
              moment(f.EndDateTime).diff(startDate, "seconds") > 0
            ) {
              this.selectedScreenShowList.push(f);
            }
          });
          this.selectedScreenShowList = this.selectedScreenShowList.sort(
            (a, b) => {
              return moment(a.StartDateTime).diff(b.StartDateTime, "minutes");
            }
          );
        });
    });
  }

  async getSoldSeatsForShow(showId) {
    if (this.selectedShowId == "") {
      this.createScreenLayout();
    }
    this.selectedShowId = showId;
    this.selectedSeat = "";
    this.selectedScreenSeatList = [];
  }
  async createScreenLayout() {
    this.screen = await this.locationScreenList.find(
      (scr) => scr.Screen_ID === this.selectedScreenId
    );
    this.drawScrn = {};
    this.asile = [];
    this.screen.Rows.forEach((f) => {
      this.rows = f.replace(/[0-9]/g, "").charCodeAt(0) - 64;
      this.cols = parseInt(f.replace(/[A-Z]|[a-z]/g, ""));
      this.generateScreen();
    });
    this.rows = null;
    this.cols = null;
    (this.screen.Hadicapped_Seats || []).forEach((f) => {
      this.updateSeatState(
        f.replace(/[0-9]/g, ""),
        parseInt(f.replace(/[A-Z]|[a-z]/g, "")),
        SeatType.Handicap
      );
    });
    (this.screen.House_Seats || []).forEach((f) => {
      this.updateSeatState(
        f.replace(/[0-9]/g, ""),
        parseInt(f.replace(/[A-Z]|[a-z]/g, "")),
        SeatType.House
      );
    });
    (this.screen.Broken_Seats || []).forEach((f) => {
      this.updateSeatState(
        f.replace(/[0-9]/g, ""),
        parseInt(f.replace(/[A-Z]|[a-z]/g, "")),
        SeatType.Broken
      );
    });
    (this.screen.Companion || []).forEach((f) => {
      this.updateSeatState(
        f.replace(/[0-9]/g, ""),
        parseInt(f.replace(/[A-Z]|[a-z]/g, "")),
        SeatType.Companion
      );
    });
    (this.screen.Unavailable || []).forEach((f) => {
      this.updateSeatState(
        f.replace(/[0-9]/g, ""),
        parseInt(f.replace(/[A-Z]|[a-z]/g, "")),
        SeatType.Unavailable
      );
    });
    this.isRowsReverse = this.screen.IsRowsReverse;
    this.isColsReverse = this.screen.IsColsReverse;
    this.upAisles = this.screen.UpAisle || [];

    this.screen.BlankSeats?.forEach((e) => {
      if (!this.blankSeats[e.Row]) this.blankSeats[e.Row] = {};

      this.blankSeats[e.Row][e.SeatNumber] = e.Count;
    });
  }
  generateScreen() {
    let char = "";
    char = String.fromCharCode(64 + this.rows);
    this.drawScrn[char] = {};
    while (Object.keys(this.drawScrn[char]).length < this.cols) {
      let no = Object.keys(this.drawScrn[char]).length + 1;
      this.drawScrn[char][no] = SeatType.Available;
    }
  }
  async SelectThisSeat(seat) {
    this.selectedSeat = seat;
  }
  async postSalesOrder() {
    this.LoaderService.show();
    let postOrder: SalesOrder = new SalesOrder();
    if (this.updatedSales != "") {
      this.UpdateSalesOrder();
      return;
    }
    postOrder.SalesChannel = "POS";
    postOrder.TotalAmount = this.totalPrice;
    postOrder.TotalTax = 0;
    if (this.SelectDateTimeSold != "") {
      postOrder.DateTimeSold = this.SelectDateTimeSold;
    }
    /* else {
       postOrder.DateTimeSold = new Date().toISOString();
     }*/
    // postOrder.DateTimeSold = new Date().toISOString();
    postOrder.LocationId = this.location.Location_ID;
    postOrder.PaymentMode = PaymentMode.Cash;
    postOrder.ShiftId = this.shiftId ? this.shiftId : postOrder.ShiftId;
    postOrder.LaneId = this.poslaneId ? this.poslaneId : postOrder.LaneId;

    var TotalTax = 0;
    var FoodItemsClone = CloneObject(this.foodItems);
    // this.foodItems = [];
    if (this.selectedMerchant == "Worldpay") {
      postOrder.MerchantName = "Worldpay";
      postOrder.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : postOrder.MerchantCredentialId;
      postOrder.LaneId = this.poslaneId ? this.poslaneId : postOrder.LaneId;
    }

    // ConcessionId is not unique to we need to check with unique id as we implement Add-on functionality
    // await FoodItemsClone.forEach((fi) => {
    //   console.log("fi", fi.Quantity);

    //   if (
    //     fi.AddonConcessionName == null &&
    //     FoodItemsClone.filter((x) => x.ParentItemUniqueId == fi.itemUniqueId)
    //       .length == 0
    //   ) {
    //     fi.Quantity = FoodItemsClone.filter(
    //       (ef) =>
    //         ef.ConcessionId === fi.ConcessionId &&
    //         !(
    //           FoodItemsClone.filter(
    //             (x) => x.ParentItemUniqueId == ef.itemUniqueId
    //           ).length > 0
    //         )
    //     ).length;

    //     if (
    //       !this.foodItems.find(
    //         (ef) =>
    //           ef.ConcessionId === fi.ConcessionId &&
    //           !(
    //             FoodItemsClone.filter(
    //               (x) => x.ParentItemUniqueId == ef.itemUniqueId
    //             ).length > 0
    //           )
    //       )
    //     ) {
    //       this.foodItems.push(fi);
    //     }
    //   } else {
    //     fi.Quantity = FoodItemsClone.filter(
    //       (ef) => ef.itemUniqueId === fi.itemUniqueId
    //     ).length;
    //     if (!this.foodItems.find((ef) => ef.itemUniqueId === fi.itemUniqueId)) {
    //       this.foodItems.push(fi);
    //     }
    //   }
    // });
    console.log("foodItemsAfter", this.foodItems);
    await this.foodItems.forEach((el) => {
      TotalTax =
        TotalTax +
        Number(
          (el.RelateTo !== undefined
            ? el.ComboTax * 1
            : el.taxAmount * 1
          ).toFixed(2)
        );
      var objtopush = new SalesOrderConcession();
      let actualprice = el.RelateTo !== undefined ? el.Price : el.TotalPrice;
      let actualtax = RoundAmount(
        el.RelateTo !== undefined ? el.ComboTax : el.unitConcessionTaxAmount
      );
      objtopush.ConcessionId = el.RelateTo !== undefined ? el.Concession_ID : el.ConcessionId;
      objtopush.ConcessionName = el.ConcessionName;
      objtopush.ComboID = el.ComboID || "";
      objtopush.Price = actualprice - actualtax;
      objtopush.Status = SalesStatus.OnHold;
      objtopush.RefundPrice = 0;
      objtopush.NumberSold = 1;
      objtopush.Tax = actualtax;
      objtopush.ActualQuantity = 1;
      objtopush.Complimentary = el.Complimentary;
      objtopush.SeatNumbers = this.selectedSeat;
      objtopush.ScreenNumber = this.selectedScreenNumber;
      objtopush.ScreeId = this.selectedScreenId;
      objtopush.AddonConcessionName = el.AddonConcessionName;
      objtopush.PrepAreaId = el.PrepAreaId;
      objtopush.PrepAreaName = el.PrepAreaName;
      objtopush.ItemLoyaltyCredit = el.LoyaltyPoints;
      postOrder.Concession.push(objtopush);

      el.NumberSold = 1;
    });
    postOrder.TotalTax = TotalTax;
    let clonefi = CloneObject(this.foodItems)
    this.kioskPOSService.checkConcessionInventory(clonefi).subscribe(
      (res: any) => {
        if (res.length > 0) {
          let items = res.join(", ");
          console.log("items", items)
          let scentence = [];
          for (var i = 0; i <= (res.length - 1); i++) {
            scentence.push(res[i])
          }
          let fullScentence = '';
          scentence.forEach((f, i) => {
            f = f.split('_')
            fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
          });
          this.LoaderService.hidePaymentloader();
          Swal.fire({
            title: this.es.dineInErrorMsgList[25].message,
            text: this.es.dineInErrorMsgList[26].message + fullScentence + this.es.dineInErrorMsgList[27].message,
            showCancelButton: false,
            type: "warning",
          });
          this.router.navigate(["./DineIn", this.activeTab]);
        } else {
          if (postOrder.Concession.length > 0) {
            // use this info for Dine-In SignalR call 
            postOrder.CreditCardinfo = "DineIn";
            this.dineinService.postSalesOrder(postOrder).subscribe(
              async (response) => {
                if (response) {
                  if (this.SelectDateTimeSold != "") {
                    response.DateTimeSold = this.SelectDateTimeSold;
                  }
                  // Update Onhold status, so refund or reports not affected
                  response.Concession.forEach(x => { x.Status = SalesStatus.OnHold });
                  await this.dineinService.patchSalesOrder(response).subscribe();

                  this.toastService.success(
                    this.es.dineInErrorMsgList[3].message,
                    "Success",
                    {
                      timeOut: 2000,
                    }
                  );
                  if (response.Concession.length > 0) {
                    let kitchenArray = [];
                    let kitchenObject = {};
                    this.sidenavtabclick('kitchen');
                    // remove addon items
                    FoodItemsClone = FoodItemsClone.filter(
                      (x) => x.AddonConcessionName == null
                    );
                    for (let index in FoodItemsClone) {
                      console.log(index)
                      kitchenObject = {
                        SalesOrderId: response.ConfirmationCode,
                        Food: this.getAllFoodNames(FoodItemsClone[index]), // Add all addon items of that food item
                        Notes: FoodItemsClone[index].FoodNotes,
                        ScreenNumber: this.selectedScreenNumber,
                        SeatNumber: this.selectedSeat,
                        Status: SalesStatus.OnHold,
                        DateTimeSold: response.DateTimeSold,
                        UniqueOrderNumber: response.UniqueOrderNumber,
                        LocationId: this.location.Location_ID,
                        LocationName: this.location.Location_Name,
                        PrepAreaId: FoodItemsClone[index].PrepAreaId,
                        PrepAreaName: FoodItemsClone[index].PrepAreaName
                      };
                      kitchenArray.push(kitchenObject);
                    }
                    await this.dineinService
                      .postOrderToKitchen(kitchenArray)
                      .subscribe(
                        async (kitchenresponse) => {
                          if (kitchenresponse) {
                            this.toastService.success(
                              this.es.dineInErrorMsgList[2].message,
                              "Success",
                              {
                                timeOut: 2000,
                              }
                            );
                            kitchenArray = [];
                            this.foodItems = [];
                            //if (postOrder.ConfirmationCode != "") {

                            //  if (this.previousActiveTab == "UnpaidOrders")
                            //    this.sidenavtabclick(this.previousActiveTab);
                            //  else
                            //    this.sidenavtabclick("kitchen");
                            //}
                          }
                        },
                        (err) => {
                          this.LoaderService.hide();
                          $("#payBtnModal").removeAttr("disabled");
                          Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
                        }
                      );
                  }
                }
                this.resetValuesAll();
              },
              (err) => {
                this.LoaderService.hide();
                $("#payBtnModal").removeAttr("disabled");
                Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
              }
            );
          }
        }
      },
      (err) => {
        this.LoaderService.hidePaymentloader();
        Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
        console.log(err);
      }
    );
  }


  async UpdateSalesOrder() {
    await this.SalesorderService.getSalesOrder(this.updatedSales).subscribe(async (res: any) => {
      let postOrder: SalesOrder = new SalesOrder();
      postOrder = res;
      if (this.updatedSales != "") {

        postOrder.ConfirmationCode = this.updatedSales;
        this.updatedSales = "";

      }
      postOrder.SalesChannel = "POS";
      postOrder.TotalAmount = this.totalPrice;
      postOrder.TotalTax = 0;
      if (this.SelectDateTimeSold != "") {
        postOrder.DateTimeSold = this.SelectDateTimeSold;
      }
      /* else {
         postOrder.DateTimeSold = new Date().toISOString();
       }*/
      // postOrder.DateTimeSold = new Date().toISOString();
      postOrder.LocationId = this.location.Location_ID;
      postOrder.PaymentMode = PaymentMode.Cash;
      postOrder.ShiftId = this.shiftId ? this.shiftId : postOrder.ShiftId;
      postOrder.LaneId = this.poslaneId ? this.poslaneId : postOrder.LaneId;

      var TotalTax = 0;
      var totalLoyaltyPoints: any = 0;
      var FoodItemsClone = CloneObject(this.foodItems);
      // this.foodItems = [];
      if (this.selectedMerchant == "Worldpay") {
        postOrder.MerchantName = "Worldpay";
        postOrder.MerchantCredentialId = this.merchantInternalId ? this.merchantInternalId : postOrder.MerchantCredentialId;
        postOrder.LaneId = this.poslaneId ? this.poslaneId : postOrder.LaneId;
      }
      await postOrder.Concession.forEach((c) => {
        if (c.Status != SalesStatus.Void && c.Status != SalesStatus.Failed) {
          totalLoyaltyPoints += c.ItemLoyaltyCredit;
        }

      })
      // ConcessionId is not unique to we need to check with unique id as we implement Add-on functionality
      // await FoodItemsClone.forEach((fi) => {
      //   console.log("fi", fi.Quantity);

      //   if (
      //     fi.AddonConcessionName == null &&
      //     FoodItemsClone.filter((x) => x.ParentItemUniqueId == fi.itemUniqueId)
      //       .length == 0
      //   ) {
      //     fi.Quantity = FoodItemsClone.filter(
      //       (ef) =>
      //         ef.ConcessionId === fi.ConcessionId &&
      //         !(
      //           FoodItemsClone.filter(
      //             (x) => x.ParentItemUniqueId == ef.itemUniqueId
      //           ).length > 0
      //         )
      //     ).length;

      //     if (
      //       !this.foodItems.find(
      //         (ef) =>
      //           ef.ConcessionId === fi.ConcessionId &&
      //           !(
      //             FoodItemsClone.filter(
      //               (x) => x.ParentItemUniqueId == ef.itemUniqueId
      //             ).length > 0
      //           )
      //       )
      //     ) {
      //       this.foodItems.push(fi);
      //     }
      //   } else {
      //     fi.Quantity = FoodItemsClone.filter(
      //       (ef) => ef.itemUniqueId === fi.itemUniqueId
      //     ).length;
      //     if (!this.foodItems.find((ef) => ef.itemUniqueId === fi.itemUniqueId)) {
      //       this.foodItems.push(fi);
      //     }
      //   }
      // });
      console.log("foodItemsAfter", this.foodItems);
      await this.foodItems.forEach((el) => {
        TotalTax =
          TotalTax +
          Number(
            (el.RelateTo !== undefined
              ? el.ComboTax * 1
              : el.taxAmount * 1
            ).toFixed(2)
          );
        var objtopush = new SalesOrderConcession();
        let actualprice = el.RelateTo !== undefined ? el.Price : el.TotalPrice;
        let actualtax = RoundAmount(
          el.RelateTo !== undefined ? el.ComboTax : el.unitConcessionTaxAmount
        );
        totalLoyaltyPoints += el.LoyaltyPoints;
        objtopush.ConcessionId = el.RelateTo !== undefined ? el.Concession_ID : el.ConcessionId;
        objtopush.ConcessionName = el.ConcessionName;
        objtopush.ComboID = el.ComboID || "";
        objtopush.Price = actualprice - actualtax;
        objtopush.Status = SalesStatus.OnHold;
        objtopush.RefundPrice = 0;
        objtopush.NumberSold = 1;
        objtopush.Tax = actualtax;
        objtopush.ActualQuantity = 1;
        objtopush.Complimentary = el.Complimentary;
        objtopush.SeatNumbers = this.selectedSeat;
        objtopush.ScreenNumber = this.selectedScreenNumber;
        objtopush.ScreeId = this.selectedScreenId;
        objtopush.AddonConcessionName = el.AddonConcessionName;
        objtopush.PrepAreaId = el.PrepAreaId;
        objtopush.PrepAreaName = el.PrepAreaName;
        objtopush.ItemLoyaltyCredit = el.LoyaltyPoints;
        postOrder.Concession.push(objtopush);

        el.NumberSold = 1;
      });
      postOrder.TotalTax = TotalTax;
      postOrder.OrderLoyaltyCredit = totalLoyaltyPoints;
      let clonefi = CloneObject(this.foodItems)
      this.kioskPOSService.checkConcessionInventory(clonefi).subscribe(
        (res: any) => {
          if (res.length > 0) {
            let items = res.join(", ");
            console.log("items", items)
            let scentence = [];
            for (var i = 0; i <= (res.length - 1); i++) {
              scentence.push(res[i])
            }
            let fullScentence = '';
            scentence.forEach((f, i) => {
              f = f.split('_')
              fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
            });
            this.LoaderService.hidePaymentloader();
            Swal.fire({
              title: this.es.dineInErrorMsgList[25].message,
              text: this.es.dineInErrorMsgList[26].message + fullScentence + this.es.dineInErrorMsgList[27].message,
              showCancelButton: false,
              type: "warning",
            });
            this.router.navigate(["./DineIn", this.activeTab]);
          } else {
            if (postOrder.Concession.length > 0) {
              // use this info for Dine-In SignalR call 
              postOrder.CreditCardinfo = "DineIn";
              this.dineinService.postSalesOrder(postOrder).subscribe(
                async (response) => {
                  if (response) {
                    if (this.SelectDateTimeSold != "") {
                      response.DateTimeSold = this.SelectDateTimeSold;
                    }
                    // Update Onhold status, so refund or reports not affected
                    // response.Concession.forEach(x => { x.Status = SalesStatus.OnHold }); for lyalty point i comment 
                    await this.dineinService.patchSalesOrder(response).subscribe();

                    this.toastService.success(
                      this.es.dineInErrorMsgList[3].message,
                      "Success",
                      {
                        timeOut: 2000,
                      }
                    );
                    if (response.Concession.length > 0) {
                      let kitchenArray = [];
                      let kitchenObject = {};
                      this.sidenavtabclick('kitchen');
                      // remove addon items
                      FoodItemsClone = FoodItemsClone.filter(
                        (x) => x.AddonConcessionName == null
                      );
                      for (let index in FoodItemsClone) {
                        console.log(index)
                        kitchenObject = {
                          SalesOrderId: response.ConfirmationCode,
                          Food: this.getAllFoodNames(FoodItemsClone[index]), // Add all addon items of that food item
                          Notes: FoodItemsClone[index].FoodNotes,
                          ScreenNumber: this.selectedScreenNumber,
                          SeatNumber: this.selectedSeat,
                          Status: SalesStatus.OnHold,
                          DateTimeSold: response.DateTimeSold,
                          UniqueOrderNumber: response.UniqueOrderNumber,
                          LocationId: this.location.Location_ID,
                          LocationName: this.location.Location_Name,
                          PrepAreaId: FoodItemsClone[index].PrepAreaId,
                          PrepAreaName: FoodItemsClone[index].PrepAreaName
                        };
                        kitchenArray.push(kitchenObject);
                      }
                      await this.dineinService
                        .postOrderToKitchen(kitchenArray)
                        .subscribe(
                          async (kitchenresponse) => {
                            if (kitchenresponse) {
                              this.toastService.success(
                                this.es.dineInErrorMsgList[2].message,
                                "Success",
                                {
                                  timeOut: 2000,
                                }
                              );
                              kitchenArray = [];
                              this.foodItems = [];
                              //if (postOrder.ConfirmationCode != "") {

                              //  if (this.previousActiveTab == "UnpaidOrders")
                              //    this.sidenavtabclick(this.previousActiveTab);
                              //  else
                              //    this.sidenavtabclick("kitchen");
                              //}
                            }
                          },
                          (err) => {
                            this.LoaderService.hide();
                            $("#payBtnModal").removeAttr("disabled");
                            Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
                          }
                        );
                    }
                  }
                  this.resetValuesAll();
                },
                (err) => {
                  this.LoaderService.hide();
                  $("#payBtnModal").removeAttr("disabled");
                  Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
                }
              );
            }
          }
        },
        (err) => {
          this.LoaderService.hidePaymentloader();
          Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
          console.log(err);
        }
      );
    });
  }

  // If consession has addon item then need to add all for single food
  getAllFoodNames(foodItem) {
    var food = foodItem.ConcessionName;
    var addonItems = this.foodItems.filter(
      (x) => x.ParentItemUniqueId == foodItem.itemUniqueId
    );

    for (let item in addonItems)
      food += " \n " + addonItems[item].ConcessionName;

    return food;
  }

  /** keypad */
  loadKeypad() {
    setTimeout(() => {
      this.keyboard = new Keyboard("PaymentKeyboard", {
        onChange: (input) => this.onChange(input),
        layout: {
          default: CommonSettings.keybSetting.numpadDef,
        },
        theme: CommonSettings.keybSetting.theme,
      });
    }, 1000);
  }
  loadNotesKey() {
    setTimeout(() => {
      this.keyboard = new Keyboard("NotesKeyboard", {
        onChange: (input) => this.onNoteChange(input),
        layout: {
          default: CommonSettings.keybSetting.EmailKeyDef,
        },
        theme: CommonSettings.keybSetting.theme,
      });
    }, 1500);
  }
  onChangeNew(error) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    // this.card.detectChanges();
  }
  onChange = (input: any) => {
    let ele = document.querySelector(this.selectedInput);
    if (ele.maxLength !== -1 && input.length > ele.value.length) {
      if (ele.value.length >= ele.maxLength) return;
    }
    $(this.selectedInput).val(input);

    // document.querySelector(this.selectedInput || ".input").value = input;
    let event: Event = new Event("input");
    ele.dispatchEvent(event);
  };

  onNoteChange = (input: any) => {
    let ele = document.querySelector(this.selectedInput);
    if (ele.maxLength !== -1 && input.length > ele.value.length) {
      if (ele.value.length >= ele.maxLength) return;
    }
    $(this.selectedInput).val(input);

    // document.querySelector(this.selectedInput || ".input").value = input;
    let event: Event = new Event("input");
    ele.dispatchEvent(event);
  };
  NoteInputFocus(event) {
    this.selectedInput = `#${event.target.id}`;

    event.target.addEventListener("input", this.onNotesInputChange);
    if (!this.keyboard) {
      this.loadNotesKey();
      setTimeout(() => {
        let def = CommonSettings.keybSetting.Payment;
        this.keyboard.setOptions({
          layout: {
            default: def,
          },
          inputName: event.target.id,
        });
        this.keyboard.setOptions({
          layout: {
            default: def,
          },

          inputName: event.target.id,
        });
      }, 1500);
    } else {
      let def = CommonSettings.keybSetting.Payment;
      this.keyboard.setOptions({
        layout: {
          default: def,
        },
        inputName: event.target.id,
      });
      this.keyboard.setOptions({
        layout: {
          default: def,
        },

        inputName: event.target.id,
      });
    }
  }
  NoteInputBlur(evt) {
    if (!this.myKeyboard.nativeElement.contains(evt.target)) {
      this.keyboard.destroy();
      this.keyboard = null;
    }
  }

  PaymentInputFocus(event) {
    this.selectedInput = `#${event.target.id}`;

    event.target.addEventListener("input", this.onInputChange);

    let def = CommonSettings.keybSetting.Payment;
    if (event.target.getAttribute("ktype") == "number")
      def = CommonSettings.keybSetting.numpadDef;
    else if (event.target.getAttribute("ktype") == "email")
      def = CommonSettings.keybSetting.Payment;
    else if (event.target.getAttribute("ktype") == "expiryDate")
      def = CommonSettings.keybSetting.ExpryDef;

    this.keyboard.setOptions({
      layout: {
        default: def,
      },

      inputName: event.target.id,
    });
  }

  checkForValidCashFigure(event) {
    if (
      event.charCode != 46 &&
      event.charCode > 31 &&
      (event.charCode < 48 || event.charCode > 57)
    )
      event.preventDefault();
  }

  onInputChange = (event: any) => {
    this.keyboard?.setInput(event.target.value, event.target.id);
  };
  onNotesInputChange(event: any) {
    this.keyboard?.setInput(event.target.value, event.target.id);
  }
  loadKeypadCCManual() {
    setTimeout(() => {
      this.keyboard = new Keyboard({
        onChange: (input) => this.onChangeCCManual(input),
        layout: {
          default: CommonSettings.keybSetting.ANKeyDef,
        },
        theme: CommonSettings.keybSetting.theme,
      });
      this.cc = {};
    }, 1500);
  }

  onChangeCCManual = (input) => {
    // if (this.selectedInput === `input[name="Name"]`) {
    // 	this.cc.Name = input;
    // } else
    if (this.selectedInput === `input[name="Number"]`) {
      this.cc.Number = input;
    } else if (this.selectedInput === `input[name="ExpiryDate"]`) {
      this.cc.ExpiryDate = input;
    } else if (this.selectedInput === `input[name="zipcode"]`) {
      this.cc.zipcode = input;
    } else if (this.selectedInput === `input[name="Cvv"]`) {
      this.cc.Cvv = input;
    }
    document.querySelector(this.selectedInput || ".input").value = input;
  };
  onInputFocusCCManual = (event) => {
    this.selectedInput = `input[name="${event.target.name}"]`;

    this.keyboard.setOptions({
      inputName: event.target.name,
    });
  };
  onInputChangeCCManual = (event) => {
    this.keyboard.setInput(event.target.value, event.target.name);
  };

  CData() {
    this.ccForm?.form.reset();
    this.keyboard = null;
  }

  selectAmount(amount) {
    if (amount == "Other") {
      this.customerGivenCash = null;
      this.isOtherSelectedFLag = true;
      setTimeout(() => {
        this.dinecashinput.nativeElement.focus();
      }, 0);
    } else {
      this.isOtherSelectedFLag = false;
      this.customerGivenCash = amount;
    }
  }

  async getCashChangePosibilities() {
    this.ccForm?.form.reset();
    $("#payBtnModal").removeAttr("disabled");
    // this.card.clear();
    this.paymentError = "";
    this.error = "";
    this.loadKeypad();
    this.amountSuggestions = [];
    this.customerGivenCash = null;
    this.amountSuggestions.push(this.priceKitchenItem);
    if (Math.ceil(this.priceKitchenItem) != this.priceKitchenItem) {
      this.amountSuggestions.push(Math.ceil(this.priceKitchenItem));
    }
    if (this.priceKitchenItem > 0) {
      // if (this.totalPrice % 5 != 0) {
      if (Math.ceil(this.priceKitchenItem) % 2 != 0) {
        var nextMultipleof_two =
          this.priceKitchenItem + (2 - (this.priceKitchenItem % 2));
        if (this.amountSuggestions.indexOf(nextMultipleof_two) == -1) {
          nextMultipleof_two
            ? this.amountSuggestions.push(nextMultipleof_two)
            : "";
        }
      }
      var nextMultipleof_five = Math.ceil(this.priceKitchenItem / 5) * 5;
      if (this.amountSuggestions.indexOf(nextMultipleof_five) == -1) {
        nextMultipleof_five
          ? this.amountSuggestions.push(nextMultipleof_five)
          : "";
      }

      var nextMultipleof_ten =
        this.priceKitchenItem + (10 - (this.priceKitchenItem % 10));
      if (this.amountSuggestions.indexOf(nextMultipleof_ten) == -1) {
        nextMultipleof_ten
          ? this.amountSuggestions.push(nextMultipleof_ten)
          : "";
      }
      //
      var nextMultipleof_fifty =
        this.priceKitchenItem + (50 - (this.priceKitchenItem % 50));
      if (this.amountSuggestions.indexOf(nextMultipleof_fifty) == -1) {
        nextMultipleof_fifty
          ? this.amountSuggestions.push(nextMultipleof_fifty)
          : "";
      }
      var nextMultipleof_hundred =
        this.priceKitchenItem + (100 - (this.priceKitchenItem % 100));
      if (this.amountSuggestions.indexOf(nextMultipleof_hundred) == -1) {
        nextMultipleof_hundred
          ? this.amountSuggestions.push(nextMultipleof_hundred)
          : "";
      }
      var nextMultipleof_twenty =
        this.priceKitchenItem + (20 - (this.priceKitchenItem % 20));
      if (
        this.amountSuggestions.indexOf(nextMultipleof_twenty) == -1 &&
        nextMultipleof_twenty < nextMultipleof_hundred
      ) {
        nextMultipleof_twenty
          ? this.amountSuggestions.push(nextMultipleof_twenty)
          : "";
      }
      this.amountSuggestions.sort(function (a, b) {
        return a - b;
      });
      this.amountSuggestions.push("Other");
      if (this.selectedMerchant == "Authorize.net") {
        this.paymentCCInnerMode = "SwipeAuthorize";
      }
    }
  }

  ccMask: any = "";

  getCCNumberMask(event: any) {
    let type = this.getCreditCardType(event.value);
    this.ccMask = this.getMaskType(type);
  }

  getMaskType(cardType) {
    var masks = {
      mastercard: "9999 9999 9999 9999",
      visa: "9999 9999 9999 9999",
      amex: "9999 999999 99999",
      diners: "9999 9999 9999 99",
      discover: "9999 9999 9999 9999",
      unknown: "9999 9999 9999 9999",
    };
    return masks[cardType];
  }

  getCreditCardType(creditCardNumber) {
    // start without knowing the credit card type
    var result = "unknown";

    // first check for MasterCard
    if (/^5[1-5]/.test(creditCardNumber)) {
      result = "mastercard";
    }
    // then check for Visa
    else if (/^4/.test(creditCardNumber)) {
      result = "visa";
    }
    // then check for AmEx
    else if (/^3[47]/.test(creditCardNumber)) {
      result = "amex";
    }
    // then check for Diners
    else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
      result = "diners";
    }
    // then check for Discover
    else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
      result = "discover";
    }

    return result;
  }

  // paymentMode: PaymentMode = PaymentMode.CC;
  paymentMode: PaymentMode = PaymentMode.Cash;

  paymentCCInnerMode: string = "Swipe";

  ngAfterViewInit(): void {
    // const input: HTMLInputElement = this.dinetipsinput.first.nativeElement as HTMLInputElement;
    // input.focus();
  }

  PaymentMode(type: PaymentMode) {
    if (type == PaymentMode.CC) {
      this.ccFormDisabled = true;
    }
    this.CardNumber = null;
    this.paymentMode = type;
    if (type == PaymentMode.GiftCard) {
      setTimeout(() => {
        this.dinegcinput.nativeElement.focus();
      }, 0);
    }
  }

  PaymentCCInnerMode(type: string) {
    this.paymentCCInnerMode = type;
    if (type == "CCManualAuthorize") {
      setTimeout(() => {
        this.dinemeinput.nativeElement.focus();
      }, 0);
    }
    if (type == "SwipeAuthorize" && this.selectedMerchant == 'Worldpay') {
      this.sendSwipeIndication()
    }
  }
  sendSwipeIndication() {
    //if (this.selectedMerchant == 'Worldpay') {
    //  this.merchantService.WorldpaySale(this.merchantInternalId, this.posData.LaneId, Number(this.priceKitchenItem.toFixed(2)), "POS").subscribe(result => {
    //    if (result.statusCode == "Approved") {
    //      this.transactionid = result.transactionId;
    //      this.cardTypeWorldpay = result.paymentType;
    //      this.cc_last_four = result.accountNumber.substring(result.accountNumber.length - 4);
    //      this.doPayment();
    //    } else if (result.statusCode.indexOf("insufficient") !== -1 || result.statusCode.indexOf("Merchant Credential") !== -1) {
    //      Swal.fire("Transaction failed", result.statusCode, "error");
    //      this.postFailedSlaesOrder();
    //    } else {
    //      // Swal.fire("Error", result.statusCode, "error");
    //      Swal.fire("Transaction failed", this.es.dineInErrorMsgList[49].message, "error");
    //      this.postFailedSlaesOrder();
    //    }
    //  }, err => {
    //    Swal.fire("Transaction failed", this.es.dineInErrorMsgList[49].message, "error");
    //    this.postFailedSlaesOrder();

    //  });
    //}
  }
  async postFailedSlaesOrder() {
    this.LoaderService.show();
    let postOrder: SalesOrder = new SalesOrder();
    if (this.updatedSales != "") {
      postOrder.ConfirmationCode = this.updatedSales;
      this.updatedSales = "";
    }
    postOrder.SalesChannel = "POS";
    postOrder.TotalAmount = this.totalPrice;
    postOrder.TotalTax = 0;
    if (this.SelectDateTimeSold != "") {
      postOrder.DateTimeSold = this.SelectDateTimeSold;
    }
    /* else {
       postOrder.DateTimeSold = new Date().toISOString();
     }*/
    // postOrder.DateTimeSold = new Date().toISOString();
    postOrder.LocationId = this.location.Location_ID;
    postOrder.PaymentMode = PaymentMode.Cash;
    postOrder.ShiftId = this.shiftData.ShiftId;
    var TotalTax = 0;
    var FoodItemsClone = CloneObject(this.foodItems);
    // this.foodItems = [];
    // await FoodItemsClone.forEach((fi) => {
    //   if (
    //     fi.AddonConcessionName == null &&
    //     FoodItemsClone.filter((x) => x.ParentItemUniqueId == fi.itemUniqueId)
    //       .length == 0
    //   ) {
    //     fi.Quantity = FoodItemsClone.filter(
    //       (ef) =>
    //         ef.ConcessionId === fi.ConcessionId &&
    //         !(
    //           FoodItemsClone.filter(
    //             (x) => x.ParentItemUniqueId == ef.itemUniqueId
    //           ).length > 0
    //         )
    //     ).length;

    //     if (
    //       !this.foodItems.find(
    //         (ef) =>
    //           ef.ConcessionId === fi.ConcessionId &&
    //           !(
    //             FoodItemsClone.filter(
    //               (x) => x.ParentItemUniqueId == ef.itemUniqueId
    //             ).length > 0
    //           )
    //       )
    //     ) {
    //       this.foodItems.push(fi);
    //     }
    //   } else {
    //     fi.Quantity = FoodItemsClone.filter(
    //       (ef) => ef.itemUniqueId === fi.itemUniqueId
    //     ).length;
    //     if (!this.foodItems.find((ef) => ef.itemUniqueId === fi.itemUniqueId)) {
    //       this.foodItems.push(fi);
    //     }
    //   }
    // });
    await this.foodItems.forEach((el) => {
      // TotalTax =
      //   TotalTax +
      //   Number(
      //     (el.RelateTo !== undefined
      //       ? el.ComboTax * el.Quantity
      //       : el.taxAmount * el.Quantity
      //     ).toFixed(2)
      //   );
      TotalTax =
        TotalTax +
        Number(
          (el.RelateTo !== undefined
            ? el.ComboTax * 1
            : el.taxAmount * 1
          ).toFixed(2)
        );
      var objtopush = new SalesOrderConcession();
      objtopush.ConcessionId = el.RelateTo !== undefined ? el.Concession_ID : el.ConcessionId;
      objtopush.ConcessionName = el.ConcessionName;
      objtopush.ComboID = el.ComboID || "";
      objtopush.Price = el.RelateTo !== undefined ? el.Price : el.TotalPrice;
      objtopush.Status = SalesStatus.Failed;
      objtopush.RefundPrice = 0;
      objtopush.NumberSold = 1;
      objtopush.Tax = RoundAmount(
        el.RelateTo !== undefined ? el.ComboTax : el.unitConcessionTaxAmount
      );
      objtopush.ActualQuantity = el.Quantity;
      objtopush.Complimentary = el.Complimentary;
      objtopush.SeatNumbers = this.selectedSeat;
      objtopush.ScreenNumber = this.selectedScreenNumber;
      objtopush.ScreeId = this.selectedScreenId;
      objtopush.AddonConcessionName = el.AddonConcessionName;
      objtopush.PrepAreaId = el.PrepAreaId;
      objtopush.PrepAreaName = el.PrepAreaName;
      objtopush.ItemLoyaltyCredit = el.LoyaltyPoints;
      postOrder.Concession.push(objtopush);
    });
    postOrder.TotalTax = TotalTax;

    if (postOrder.Concession.length > 0) {
      this.dineinService.postSalesOrder(postOrder).subscribe(
        async (response) => {
          if (this.SelectDateTimeSold != "") {
            response.DateTimeSold = this.SelectDateTimeSold;
          }
          this.posService.cancelSales(response).subscribe(
            async (result) => {

            })
        },
        (err) => {
          this.LoaderService.hide();
          $("#payBtnModal").removeAttr("disabled");
          Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
        }
      );
    }

  }
  sortKitchenOrders(a, b) {
    const date1 = new Date(a.DateTimeSold).getTime();
    const date2 = new Date(b.DateTimeSold).getTime();
    if (date1 < date2) {
      return 1;
    } else if (date1 > date2) {
      return -1;
    } else {
      return 0;
    }
  }
  async addItems(salesOrder) {
    this.updatedSales = salesOrder.SalesOrderId;
    this.SelectDateTimeSold = salesOrder.DateTimeSold;
    this.shiftId = salesOrder.ShiftId;
    this.poslaneId = salesOrder.LaneId;

    this.selectedScreenNumber = salesOrder.ScreenNumber;
    this.selectedSeat = salesOrder.SeatNumber;
    this.activeTab = "dinein";
  }
  loadPinKeypadvoid() {
    if (this.keyboard) this.keyboard = null;
    setTimeout(() => {
      this.keyboard = new Keyboard("PinKeybvoid", {
        onChange: (input) => (this.ManagerPin = input),
        layout: {
          default: CommonSettings.keybSetting.numpadDef,
        },
        inputName: "#EmpPinVoid",
        theme: CommonSettings.keybSetting.theme,
      });
    }, 1000);
  }
  destroyKB() {
    this.keyboard?.destroy();
  }
  refundOrder(kitchenobject) {
    this.SalesorderService.getSalesOrder(kitchenobject[0].SalesOrderId).subscribe(async (res: any) => {

      let voidsalesobject: any = {};
      voidsalesobject.ConfirmationCode = res.ConfirmationCode;
      let soconcessionArray = [];
      for (let i = 0; i < kitchenobject.length; i++) {
        if (kitchenobject[i].Food.split('\n').length > 0) {
          let addon_concessionArray = [];
          await kitchenobject[i].Food.split('\n').forEach((ac) => {
            let ConcessionObject = res.Concession.find(c => (c.ConcessionName.trim() == ac.trim()));
            addon_concessionArray.push(ConcessionObject)
          })

          voidsalesobject.RefundAmount = 0;

          await addon_concessionArray.forEach((aca) => {
            let refconceccionobject = aca;
            refconceccionobject.TtlPrice = refconceccionobject.Price;
            refconceccionobject.Price = refconceccionobject.Price + refconceccionobject.Tax;
            refconceccionobject.ActualQuantity = 1;
            refconceccionobject.NumberSold = 0;
            refconceccionobject.isConcession = true;
            refconceccionobject.ConfirmationCode = res.ConfirmationCode;
            refconceccionobject.Status = SalesStatus.Reserved;
            refconceccionobject.ConcessionName = !refconceccionobject.AddonConcessionName ? kitchenobject[i].Food : refconceccionobject.ConcessionName;
            soconcessionArray.push(refconceccionobject)
            if (res.PaymentMode == PaymentMode.GiftCard) {
              voidsalesobject.RefundAmount += Number(voidsalesobject.RefundAmount) + (Number(refconceccionobject.Price) + Number(refconceccionobject.Tax));
            } else {
              voidsalesobject.RefundAmount += Number(voidsalesobject.RefundAmount) + Number(refconceccionobject.Price);
            }
          })


          console.log("resConcessionobject", addon_concessionArray);
        } else {


          let resConcessionobject = res.Concession.find(c => (c.ConcessionName == kitchenobject[i].Food));
          resConcessionobject.TtlPrice = resConcessionobject.Price;
          resConcessionobject.Price = resConcessionobject.Price + resConcessionobject.Tax;

          resConcessionobject.ActualQuantity = 1;
          resConcessionobject.NumberSold = 0;
          resConcessionobject.isConcession = true;
          resConcessionobject.ConfirmationCode = res.ConfirmationCode;
          resConcessionobject.Status = SalesStatus.Reserved;
          if (res.PaymentMode == PaymentMode.GiftCard) {
            voidsalesobject.RefundAmount = resConcessionobject.Price + resConcessionobject.Tax;
          } else {
            voidsalesobject.RefundAmount = resConcessionobject.Price;
          }
          soconcessionArray.push(resConcessionobject);



        }
      }
      voidsalesobject.Concession = soconcessionArray;
      voidsalesobject.Tickets = [];

      voidsalesobject.RefundReason = "customer requested";
      voidsalesobject.PaymentMode = res.PaymentMode;
      voidsalesobject.PaymentID = res.PaymentID;
      voidsalesobject.PaymentType = res.PaymentType;
      voidsalesobject.MerchantCredentialId = res.MerchantCredentialId;
      voidsalesobject.LaneId = res.LaneId;
      voidsalesobject.AdditionalCharges = res.AdditionalCharges.filter(x => x.Index == 11);
      voidsalesobject.SalesSplit = res.SalesSplit;
      voidsalesobject.WorldpayTransactionDetails = res.WorldpayTransactionDetails;
      let resultArray = [voidsalesobject];
      voidsalesobject.SalesSplit = [];
      let posData = JSON.parse(sessionStorage.getItem("shiftData"));
      let posId
      if (posData) {
         posId = posData.ShiftId;
      }
      voidsalesobject.ShiftId = posId;
      console.log(resultArray)
      this.posService.refundSalesOrder(resultArray, "WorldpayRefund").subscribe(
        (response) => {
          console.log("response", response);
          this.removeLoyaltyCredit(resultArray);
          this.statsService.getKOrders(
            this.startDateForKitchen,
            this.endDateForKitchen
          );
          this.toastService.success(
            this.es.dineInErrorMsgList[28].message,
            "Success",
            {
              timeOut: 2000,
            }
          );
          this.LoaderService.hide();
        })

    });
  }
  async removeLoyaltyCredit(array) {
    let LoyaltyPoints = 0;
    await array[0].Concession.forEach((ci, index) => {
      // LoyaltyPoints += ci.ItemLoyaltyCredit;
      if (!ci.ComboID || ci.ComboID == "") {
        if (ci.NumberSold == 0) {
          LoyaltyPoints += (ci.ItemLoyaltyCredit * ci.ActualQuantity);
        } else {
          LoyaltyPoints += (ci.ItemLoyaltyCredit * ci.NumberSold);
        }
      }
    });
    await array[0].Tickets.forEach((ti, index) => {
      LoyaltyPoints += ti.ItemLoyaltyCredit;
    });
    this.posService.GetLoyaltyOrderBySalesOrderId(array[0].ConfirmationCode).subscribe(async result => {
      if (result.length > 0) {
        let memberid = result[0].LoyaltyMemberId;
        let memeberreward = LoyaltyPoints;
        let companyid = JSON.parse(sessionStorage.location).Company_ID;
        console.log('memberid, memeberreward, companyid', memberid, memeberreward, companyid);
        this.posService.RemoveMemberRewards(memberid, memeberreward, companyid).subscribe(f => {
          console.log("Response", f);
          sessionStorage.removeItem('MemberDetail');
        }, err => {

        });
      }
    });
  }
  async cancelOrder(kitchenobject) {
    let sw = Object.assign(CommonSettings.warningSwal);
    sw.title = ''
    sw.html = "";
    sw.text = this.es.dineInErrorMsgList[9].message;
    const pm = await Swal.fire(sw);
    if (pm.value) {
      $("#CancelKorder").modal("show");
      var filterkorder = this.kitchen.filter(l => l.SalesOrderId == kitchenobject.SalesOrderId && l.Status != 5);
      var korders = CloneObject(filterkorder);
      this.cancelKOrders = [];
      korders.forEach(element => {
        element.Checked = false;
        this.cancelKOrders.push(element);
      });
      // this.cancelOrderId = kitchenobject.KitchenId;
      // this.cancelKitchenObj = kitchenobject;
      setTimeout(() => {
        this.loadPinKeypadvoid();
      }, 100);
    } else {
      this.cancelOrderId = null;
      this.cancelKitchenObj = null;
    }
  }
  async verifyManagerCode() {
    this.posService.getPinVerify(
      this.location.Location_ID,
      Number(this.ManagerPin)
    ).subscribe(
      async (success) => {
        if (success) {
          $("#qrcodemodal").modal("hide");
          this.keyboard?.destroy();
          this.error = '';
          this.ManagerPin = '';
          this.LoaderService.show();
          // if (this.cancelKitchenObj.SalesChannel == 'Dinein') {
          let tempcancelorder = this.cancelKOrders.filter(l => l.Checked);
          let OnlineOrder = tempcancelorder.filter(l => l.Checked == true && l.SalesChannel == 'Dinein' || l.SalesChannel == 'QR ordering');
          console.log("changed status.......", this.cancelKOrders.length)
          if (OnlineOrder.length > 0) {
            await this.refundOrder(OnlineOrder);

          } else {

            let flagforload = false;


            for (let i = 0; i < tempcancelorder.length; i++) {
              console.log("changed status.......", this.cancelKOrders[i].Checked)
              if (tempcancelorder[i].Checked) {
                await this.dineinService.updateKitchenStatus(tempcancelorder[i].KitchenId, 5, {}).toPromise().then(
                  (data) => {

                    if (i == (tempcancelorder.length - 1)) {
                      console.log("changed status.......", this.startDateForKitchen)
                      console.log("changed status.......", this.endDateForKitchen)
                      flagforload = true;
                      this.statsService.getKOrders(
                        this.startDateForKitchen,
                        this.endDateForKitchen
                      );
                      console.log("tostservice")
                      this.toastService.success(
                        this.es.dineInErrorMsgList[28].message,
                        "Success",
                        {
                          timeOut: 2000,
                        }
                      );
                      this.LoaderService.hide();
                      this.allChecked = false;
                    }

                  },
                  (err) => {
                    this.LoaderService.hide();
                  }
                );
              }

            }
            if (flagforload) {

            }


          }

        } else {
          this.error = "Invalid pin. Please try again";
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }
  public onBlurCardNumber() {//needtoremove
    if (
      this.cc_auth_card_number &&
      this.cc_auth_card_number.endsWith("?") &&
      this.cc_auth_card_number.split("?").length >= 3
    ) {
      var details = this.cc_auth_card_number.split(";");
      var cardnumber = details[1]?.split("=")[0];
      var cc = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
      var testflag = details[1] ? cc.test(cardnumber) : (testflag = false);
      if (!testflag) {
        Swal.fire({
          title: this.es.dineInErrorMsgList[29].message,
          type: "warning",
        });
        this.cc_auth_card_number = "";
        $("#auth_card_number_swipe").val('');
        return null;
      }
      // this.doPayment();
      // this.CreatePaymentSalesOrder();
    }
  }
  CreatePaymentSalesOrder(data) {
    console.log("CreatePaymentSalesOrder", this, this.servedSalesOrderId);
    var postObj: any = {};

    var Kitchen_Id: any = this.kitchenorders.find(
      (ki: any) => ki.SalesOrderId == this.servedSalesOrderId
    );
    postObj.saleskitchenOrders = this.kitchenorders.filter(
      (ki: any) => ki.SalesOrderId == this.servedSalesOrderId
    );
    Kitchen_Id = Kitchen_Id.KitchenId;
    // postObj.PaymentMode = this.paymentMode;
    // postObj.ShiftId = this.shiftData.ShiftId;
    postObj.Kitchen_Id = Kitchen_Id;
    // postObj.TotalAmount = this.priceKitchenItem;
    // postObj.TipAmount = this.tipamount;
    postObj.servedSalesOrderId = this.servedSalesOrderId;
    // postObj.GiftCardSale = [];
    postObj.concessionPaymentItem = this.concessionPaymentItem;
    postObj.saleOrderPay = data;
    this.paymentOptionService.KitchenPostObjectObservable.next(postObj);
  }
  async doPayment() {
    // this.LoaderService.showPaymentloader();
    // var postObj: any = {};
    // var Kitchen_Id: any = this.kitchenorders.find(
    //   (ki: any) => ki.SalesOrderId == this.servedSalesOrderId
    // );
    // Kitchen_Id = Kitchen_Id.KitchenId;
    // postObj.PaymentMode = this.paymentMode;
    // postObj.ShiftId = this.shiftData.ShiftId;
    // if (
    //   this.paymentMode == PaymentMode.CC &&
    //   (this.selectedMerchant == "Authorize.net" || this.selectedMerchant == "Worldpay")
    // ) {
    //   postObj.MerchantName = this.selectedMerchant;
    //   if (this.selectedMerchant == "Worldpay") {
    //     postObj.MerchantCredentialId = this.merchantInternalId;
    //     postObj.LaneId = this.posData.LaneId;
    //     postObj.CustomerInfo = new CustomerInfo();
    //     postObj.CustomerInfo.CardNumber = this.ccAuthorize["cardNumber"];
    //     postObj.CustomerInfo.Expiry = this.ccAuthorize["mm"];
    //     postObj.CustomerInfo.ExpiryYear = this.ccAuthorize["yy"];
    //     postObj.CustomerInfo.Cvv = this.ccAuthorize["cvv"];
    //   }
    //   if (this.paymentCCInnerMode == "SwipeAuthorize") {
    //     postObj.PaymentMode = PaymentMode.CC;

    //     if (this.selectedMerchant == "Authorize.net") {
    //       postObj.CreditCardinfo = this.cc_auth_card_number;
    //       postObj.TotalAmount = this.priceKitchenItem;
    //       this.cc_last_four = this.cc_auth_card_number
    //         ?.split(";")[1]
    //         ?.split("=")[0];
    //       this.cc_last_four = this.cc_last_four?.substring(
    //         this.cc_last_four.length - 4
    //       );
    //     } else {
    //       postObj.MerchantName = this.selectedMerchant;
    //       postObj.CreditCardinfo = this.transactionid;
    //       postObj.MerchantCredentialId = this.merchantInternalId;
    //       postObj.LaneId = this.posData.LaneId;
    //       postObj.PaymentID = this.transactionid;
    //       postObj.PaymentType = this.cardTypeWorldpay;
    //       postObj.CCLastFour = this.cc_last_four;
    //     }
    //     this.proccesscreditcardPayment(postObj, Kitchen_Id);
    //   } else if (this.paymentCCInnerMode == "CCManualAuthorize") {
    //     if (this.ccAuthForm.form.invalid) {
    //       $("#payBtnModal").removeAttr("disabled");
    //       this.LoaderService.hidePaymentloader();
    //       this.error = this.es.dineInErrorMsgList[50].message;
    //       return;
    //     }
    //     this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
    //       this.ccAuthorize["cardNumber"].length - 4
    //     );
    //     postObj.PaymentMode = PaymentMode.ManualCC;
    //     postObj.TotalAmount = this.priceKitchenItem;
    //     // postObj.CardNumber=this.ccAuthorize["cardNumber"];
    //     this.cc_last_four = this.ccAuthorize["cardNumber"].substring(
    //       this.cc_last_four.length - 4
    //     );
    //     postObj.CreditCardinfo =
    //       this.ccAuthorize["fullName"].trim() +
    //       "^" +
    //       this.ccAuthorize["cardNumber"] +
    //       "^" +
    //       this.ccAuthorize["mm"] +
    //       "^" +
    //       this.ccAuthorize["yy"] +
    //       "^" +
    //       this.ccAuthorize["cvv"] +
    //       "^" +
    //       this.ccAuthorize["zipcode"];
    //     this.proccesscreditcardPayment(postObj, Kitchen_Id);
    //     this.ccAuthForm?.form.reset();
    //   }
    // }
    // if (
    //   this.paymentMode == PaymentMode.CC &&
    //   this.selectedMerchant == "Stripes"
    // ) {
    //   if (this.paymentCCInnerMode == "CCManual") {
    //     var ccData = "";
    //     postObj.MerchantName = "Stripes";
    //     await (<any>window).Stripe.card.createToken(
    //       {
    //         number: this.cc["Number"],
    //         exp_month: this.cc["Expirymonth"],
    //         exp_year: this.cc["Expiryyear"],
    //         cvc: this.cc["Cvv"],
    //         address_zip: this.cc["address_zip"],
    //       },
    //       async (status: number, response: any) => {
    //         console.log("RESPONSE-------", response, status);
    //         if (status === 200) {
    //           postObj.CCLastFour = response.card.last4;
    //           var amountcc = (Number(this.totalPrice) * 100).toFixed();
    //           ccData = "stripes^" + amountcc + "^usd^" + response.id;
    //           postObj.CreditCardinfo = ccData;
    //           postObj.PaymentMode = PaymentMode.ManualCC;
    //         } else {
    //           this.LoaderService.hidePaymentloader();
    //           $("#payBtnModal").removeAttr("disabled");
    //           this.error = response.error.message;
    //           return;
    //         }
    //       }
    //     );
    //   } else if (this.paymentCCInnerMode == "Swipe") {
    //     postObj.MerchantName = "Stripes";
    //     if (this.isSwipeReaderExist) {
    //       var amount = (Number(postObj.TotalAmount) * 100).toFixed();
    //       var ccData = "stripes^" + amount + "^usd";
    //       postObj.CreditCardinfo = ccData;
    //     } else {
    //       Swal.fire({
    //         title: this.es.dineInErrorMsgList[30].message,
    //         text: this.es.dineInErrorMsgList[31].message,
    //         showCancelButton: false,
    //         type: "warning",
    //       });
    //       this.LoaderService.hidePaymentloader();
    //       return;
    //     }
    //   }
    // }
    // if (this.paymentMode == PaymentMode.Cash) {
    //   if (
    //     !this.customerGivenCash.toString().match("^[0-9]*\\.?[0-9]*$") ||
    //     this.customerGivenCash.toString().indexOf(".") == 0
    //   ) {
    //     Swal.fire(this.es.dineInErrorMsgList[30].message, this.es.dineInErrorMsgList[32].message);
    //     this.LoaderService.hidePaymentloader();
    //     return;
    //   }
    //   let res = await this.dineinService
    //     .updateKitchenStatus(Kitchen_Id, 4, {
    //       PaymentMode: this.paymentMode,
    //       TotalAmount: this.priceKitchenItem,
    //       TipAmount: this.tipamount,
    //       ShiftId: this.shiftData.ShiftId,
    //     })
    //     .toPromise();
    //   if (res && res != "success") {
    //     Swal.fire(this.es.dineInErrorMsgList[30].message, res);
    //     this.LoaderService.hidePaymentloader();
    //     return;
    //   } else {
    //     $("#paymentModel").modal("hide");
    //     $("#kitchenPaymentModal").modal("hide");
    //     this.LoaderService.hidePaymentloader();
    //   }
    //   this.statsService.getKOrders(
    //     this.startDateForKitchen,
    //     this.endDateForKitchen
    //   );
    //   this.toastService.success(this.es.dineInErrorMsgList[1].message, "Success", {
    //     timeOut: 2000,
    //   });
    //   this.printService.printDocument("ticket", [this.servedSalesOrderId]);
    // }
    // if (this.paymentMode == PaymentMode.GiftCard) {
    //   let res = await this.dineinService
    //     .updateKitchenStatus(Kitchen_Id, 4, {
    //       PaymentMode: this.paymentMode,
    //       CardNumber: this.CardNumber,
    //       TotalAmount: this.priceKitchenItem,
    //       TipAmount: this.tipamount,
    //       ShiftId: this.shiftData.ShiftId,
    //     })
    //     .toPromise();
    //   if (res && res != "success") {
    //     Swal.fire("", res);
    //     this.LoaderService.hidePaymentloader();
    //     return;
    //   } else {
    //     $("#paymentModel").modal("hide");
    //     $("#kitchenPaymentModal").modal("hide");
    //     this.LoaderService.hidePaymentloader();
    //   }
    //   this.statsService.getKOrders(
    //     this.startDateForKitchen,
    //     this.endDateForKitchen
    //   );
    //   this.toastService.success(this.es.dineInErrorMsgList[1].message, "Success", {
    //     timeOut: 2000,
    //   });
    //   this.printService.printDocument("ticket", [this.servedSalesOrderId]);
    // }
  }
  // async proccesscreditcardPayment(postObj, Kitchen_Id) {
  //   // postObj.CCLastFour=this.cc_last_four;
  //   postObj.TipAmount = this.tipamount;
  //   await this.dineinService
  //     .updateKitchenStatus(Kitchen_Id, 4, postObj)
  //     .subscribe(
  //       async (response) => {
  //         if (response == "success") {
  //           postObj.TotalAmount = 0;
  //           this.cc_auth_card_number = "";
  //           this.cc_last_four = "";
  //           this.LoaderService.hidePaymentloader();
  //           $("#paymentModel").modal("hide");
  //           $("#kitchenPaymentModal").modal("hide");
  //           this.toastService.success(
  //             this.es.dineInErrorMsgList[51].message,
  //             "Success",
  //             {
  //               timeOut: 2000,
  //             }
  //           );
  //           this.printService.printDocument("ticket", [
  //             this.servedSalesOrderId,
  //           ]);
  //           this.statsService.getKOrders(
  //             this.startDateForKitchen,
  //             this.endDateForKitchen
  //           );
  //         } else {
  //           this.cc_auth_card_number = "";
  //           this.cc_last_four = "";
  //           // Swal.fire("Error", response);
  //           Swal.fire(
  //             this.es.dineInErrorMsgList[39].message,
  //             this.es.dineInErrorMsgList[40].message,
  //             "error"
  //           );
  //           this.LoaderService.hidePaymentloader();
  //         }
  //       },
  //       (err) => {
  //         this.cc_auth_card_number = "";
  //         this.cc_last_four = "";
  //         // Swal.fire("Error", err);
  //         Swal.fire(this.es.dineInErrorMsgList[19].message, err, "error");
  //         this.LoaderService.hidePaymentloader();
  //         return;
  //       }
  //     );
  // }
  checkforallServedItems(id) {
    for (let f of this.kitchen) {
      if (id.trim() == f["SalesOrderId"] && f["Status"] < 4) {
        return false;
      }
    }
    return true;
  }
  proceedToPay(id: string) {
    this.servedSalesOrderId = id;
    this.priceKitchenItem = 0;
    this.tipamount = 0;
    this.activeTipsButton = "";
    this.LoaderService.show();
    this.dineinService.getAmountBySalesOrderId(id).subscribe(
      async (data) => {
        var totalcreditloyaltyPoints = 0;
        var slaesorderobject = CloneObject(data);
        await slaesorderobject.Concession.forEach((c) => {
          if (c.Status != SalesStatus.Void && c.Status != SalesStatus.Failed) {
            totalcreditloyaltyPoints += c.ItemLoyaltyCredit;
          }
        });
        slaesorderobject.OrderLoyaltyCredit = totalcreditloyaltyPoints;
        data = CloneObject(slaesorderobject);
        console.log('data-------------------------', data);
        this.concessionPaymentItem = data["Concession"];
        console.log("this.concessionPaymentItem", this.concessionPaymentItem);
        this.concessionPaymentItem = this.concessionPaymentItem.filter(
          (i) => i.Status == SalesStatus.OnHold
        );
        var addonConcessionItems = [];
        for (let p of this.concessionPaymentItem) {
          this.priceKitchenItem += Number(
            ((p["Price"] + p["Tax"]) * p["NumberSold"]).toFixed(2)
          );
          // this.priceKitchenItem += Number(((p['Price'] + (p['Price'] * (p['Tax']/100))) * p['NumberSold']).toFixed(2));

          // Merge all addon items to parent food item to show on payment popup
          // NumberSold is 1 for add-on parent items
          if (p.AddonConcessionName == null && p.NumberSold == 1) {
            var addonConcession = this.concessionPaymentItem.filter(
              (x) =>
                x.AddonConcessionName != null &&
                x.AddonConcessionName.indexOf(p.ConcessionName) !== -1 &&
                addonConcessionItems.filter((a) => a == x.AddonConcessionName)
                  .length == 0
            );
            // Need to check if we have two concession and diffrent addon items
            if (addonConcession.length > 0) {
              addonConcessionItems.push(addonConcession[0].AddonConcessionName);
              p.addonItems = this.concessionPaymentItem.filter(
                (x) =>
                  x.AddonConcessionName ==
                  addonConcession[0].AddonConcessionName
              );
            }
          }
        }
        this.priceKitchenItem = RoundAmount(this.priceKitchenItem);
        // $("#kitchenPaymentModal").modal("show");
        this.paymentOptionService.hasPaymentModelOpen = true;
        $("#payMethodModel").modal("show");
        this.CreatePaymentSalesOrder(data);
        setTimeout(() => {
          document.getElementById("otherTips").focus();
        }, 0);
        this.LoaderService.hide();
      },
      (err) => {
        this.priceKitchenItem = 0;
        this.tipamount = 0;
        console.log(err);
        this.LoaderService.hide();
      }
    );
  }

  getAmount(id: string, kitchenId: string) {
    console.log('SalesOrderId', id)
    this.SalesOrderId = id;
    if (this.checkforallServedItems(id)) {
      this.proceedToPay(id);
    } else {
      this.paymentNotServedConfirmation(id);
    }
  }

  // Confirmation before user also paid for non served items
  async paymentNotServedConfirmation(id: string) {
    var notServedCount = 1;
    var itemStatus = ''
    // var notServedItemsstr = "<div style='white-space: pre;'>";
    var notServedItemsstr = "<div style='white-space: break-spaces;'>";
    for (let f of this.kitchen) {
      if (id.trim() == f["SalesOrderId"]) {
        if (f["Status"] < 4) {
          if (notServedCount != 1) notServedItemsstr += "\n";
          notServedItemsstr +=
            "<b>" +
            notServedCount++ +
            ".</b> " +
            f["Food"].replace("\n", "with").split(" \n").join(",");
        }
        if (f["Status"] == 3) {
          var itemStatus = 'Served'
        } else {
          var itemStatus = 'Unserved'
        }
      }
    }
    var itemsstr = notServedItemsstr + "\n </div>";

    let sw = Object.assign(CommonSettings.warningSwal);
    sw.title = '';
    sw.html =
      'You have ' + itemStatus + ' items: ' +
      itemsstr +
      this.es.dineInErrorMsgList[34].message;
    const pm = await Swal.fire(sw);
    if (pm.value) {
      this.proceedToPay(id);
    }
  }

  addTips(event) {
    this.priceKitchenItem = 0;
    let priceWithTax = 0;
    let tips = event.target.value;
    if (this.activeTipsButton != "" && this.activeTipsButton == tips) {
      event.target.classList.remove("active");
      tips = "";
    } else {
      this.activeTipsButton = tips;
    }
    let strNum = 0;
    if (tips.indexOf("%") == -1) {
      strNum = Number(tips);
    } else {
      strNum = tips.replace("%", "");
    }
    strNum = Number(strNum);
    for (let p of this.concessionPaymentItem) {
      priceWithTax +=
        Number((p["Price"] + p["Tax"]).toFixed(2)) * p["NumberSold"];
    }
    this.tipamount = Number((priceWithTax * (strNum / 100)).toFixed(2));
    this.priceKitchenItem = RoundAmount(Number(priceWithTax) + this.tipamount);
  }

  removeActiveForButtons() {
    var header = document.getElementById("tips");
    var btns = header.getElementsByClassName("btn");
    var current = document.getElementsByClassName("active");
    current[0].className = current[0].className.replace(" active", "");
  }
  resetValuesAll() {
    this.selectedSeat = "";
    this.LoaderService.hide();
    this.dineinService.foodItemsInCartObservable.next([]);
    this.dineinService.totalPriceObservable.next(0);
    this.dineinService.totalCountObservable.next({});
    this.dineinService.selectedItemCount = 0;
    for (var i = this.regularFoods.length - 1; i >= 0; i--) {
      this.regularFoods[i].Quantity = 0;
    }
    for (var i = this.comboItems.length - 1; i >= 0; i--) {
      this.comboItems[i].Quantity = 0;
    }
    for (var i = this.snackItems.length - 1; i >= 0; i--) {
      this.snackItems[i].Quantity = 0;
    }
    for (var i = this.drinkItems.length - 1; i >= 0; i--) {
      this.drinkItems[i].Quantity = 0;
    }
  }
  onBlurMonth() {//needtoremove
    this.commonServ.GetDateTimeByTimeZone().subscribe((tz) => {
      // if (!this.ccAuthorize.yy) {
      if (
        moment(tz).get("month") + 1 > this.ccAuthorize.mm &&
        this.ccAuthorize.yy &&
        moment(tz).get("year") >= this.ccAuthorize.yy
      ) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      } else {
        if (moment(tz).get("year") > this.ccAuthorize.yy) {
          this.ccAuthForm.controls["auth_yy"].setErrors({ pattern: true });
        } else {
          this.ccAuthForm.controls["auth_mm"].clearAsyncValidators();
          this.ccAuthForm.controls["auth_mm"].setErrors(null);
        }
        if (moment(tz).get("year") == this.ccAuthorize.yy) {
          if (moment(tz).get("month") + 1 > this.ccAuthorize.mm) {
            this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
          }
        }
      }
      // }
      if (this.ccAuthorize.mm > 12) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      }
    });
  }
  onBlurYear() {//needtoremove
    this.commonServ.GetDateTimeByTimeZone().subscribe((tz) => {
      if (moment(tz).get("year") > this.ccAuthorize.yy) {
        this.ccAuthForm.controls["auth_yy"].setErrors({ pattern: true });
      } else {
        this.ccAuthForm.controls["auth_mm"].clearAsyncValidators;
        this.ccAuthForm.controls["auth_mm"].setErrors(null);
      }
      if (moment(tz).get("year") == this.ccAuthorize.yy) {
        if (moment(tz).get("month") + 1 > this.ccAuthorize.mm) {
          this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
        }
      }
      if (this.ccAuthorize.mm > 12) {
        this.ccAuthForm.controls["auth_mm"].setErrors({ pattern: true });
      }
    });
  }
  loadStripe(url) {
    this.client = new Client(url);
    this.terminal = (<any>window).StripeTerminal.create({
      onFetchConnectionToken: async () => {
        let connectionTokenResult = await this.client.createConnectionToken();
        this.stripeSecretToken = connectionTokenResult.secret;
        this.stripeSecretToken = connectionTokenResult.secret;
        return connectionTokenResult.secret;
      },
      onUnexpectedReaderDisconnect: async () => {
        let onUnexpectedReaderDisconnect = await this.client.onUnexpectedReaderDisconnect();
        return onUnexpectedReaderDisconnect;
      },
    });
    Logger.watchObject(this.client, "backend", {
      createConnectionToken: {
        docsUrl: this.apiurl,
      },
      onUnexpectedReaderDisconnect: {
        docsUrl: this.apiurl,
      },
    });
    this.connectToSimulator();
    this.discoverReaders();
  }
  discoverReaders = async () => {
    this.setState({
      discoveryWasCancelled: false,
    });
    const discoverResult = await this.terminal.discoverReaders();
    this.isSwipeReaderExist =
      discoverResult.discoveredReaders.length > 0 ? true : false;
    console.log("discoverReaders", discoverResult);
    if (discoverResult.error) {
      console.log("Failed to discover: ", discoverResult.error);
      return discoverResult.error;
    } else {
      this.setState({
        discoveredReaders: discoverResult.discoveredReaders,
      });
      return discoverResult.discoveredReaders;
    }
  };
  setState = async (attr) => {
    return attr;
  };
  connectToSimulator = async () => {
    const simulatedResult = await this.terminal.discoverReaders({
      simulated: true,
    });
    await this.connectToReader(simulatedResult.discoveredReaders[0]);
  };
  connectToReader = async (selectedReader) => {
    const connectResult = await this.terminal.connectReader(selectedReader);
    if (connectResult.error) {
      console.log("Failed to connect:", connectResult.error);
    } else {
      this.setState({
        status: "workflows",
        discoveredReaders: [],
        reader: connectResult.reader,
      });
      return connectResult;
    }
  };
  getAsileLoc(row, col, loc) {
    let asile: Array<AsileData> = this.getAsile(row, col);
    if (asile.length > 0) {
      let flag = false;
      asile.forEach((f) => {
        if (f.location === loc) {
          flag = true;
          return false;
        }
      });
      return flag;
    }
    return false;
  }
  getAsile(row, col): Array<AsileData> {
    let asiles: Array<AsileData> = [];
    this.asile.forEach((f) => {
      row >= f.row.from &&
        row <= f.row.to &&
        col >= f.col.from &&
        col <= f.col.to
        ? asiles.push(f)
        : null;
    });
    return asiles;
  }
  getBlankSeats(row, col) {
    if (!this.blankSeats[row]) return [];
    if (this.blankSeats[row])
      return Array(this.blankSeats[row][col] || 0).fill(1);
  }
  getSeatClass(row, col) {
    delay(0);
    switch (this.drawScrn[row][col]) {
      case SeatType.House:
        return "seat available house";
      case SeatType.Broken:
        return "seat available broken";
      case SeatType.Handicap:
        return "seat available handicap";
      case SeatType.Companion:
        return "seat available companion";
      case SeatType.Unavailable:
        return "seat available unavailable";
      default:
        return "seat available";
    }
  }
  getAsileStyle(row, col) {
    let asile: Array<AsileData> = this.getAsile(row, col);
    if (asile.length > 0) {
      let cls = "";
      asile.forEach((f) => {
        cls += " mar-" + f.location;
      });
      return cls;
    }
    return {};
  }
  updateSeatState(row, col, ST: SeatType) {
    this.drawScrn[row][col] = ST;
  }

  signOfDinIn() {
    this.resetValuesAll();
    if (this.dineinService.isShiftOpen) {
      this.closeShiftConfirmation();
    } else {
      this.router.navigate(["/locations/setting/" + this.location.Location_ID]);
    }
    // this.router.navigate(['/locations/setting/' +  this.location.Location_ID]);
  }
  async closeShiftConfirmation() {
    let sw = Object.assign(CommonSettings.warningSwal);
    sw.html = "";
    sw.text =
      this.es.dineInErrorMsgList[48].message;
    const pm = await Swal.fire(sw);
    if (pm.value) {
      this.resetValuesAll();
      this.error = "";
      this.ccForm?.form.reset();
      this.GiftCards.forEach(async (g) => {
        this.giftCardService.GiftCardToCart(g, "minus");
      });
      await this.shiftService
        .endShift(this.shiftData.ShiftId)
        .toPromise()
        .then(
          async (s) => {
            //await this.posService
            //  .updatePOSStatus(this.posData.POSId, 0)
            //  .toPromise()
            //  .then(async (rezstatus) => { });

            this.router.navigate(["dineinendshift", this.shiftData.ShiftId], {
              state: {
                endShiftData: s,
              },
            });
          },
          (error) => {
            this.error = error;
          }
        );
    }
  }
  async LoadPOSActiveShiftSummary() {
    if (this.shiftData && this.shiftData.ShiftId) {
      await this.shiftService
        .getNetShiftSummary(this.shiftData.ShiftId)
        .toPromise()
        .then(
          async (s: Summary) => {
            this.summaryData = s;
          },
          (error) => {
            this.error = error;
          }
        );
    }
  }
  async LoadEmpData() {
    await this.shiftService
      .getCurrentUser()
      .toPromise()
      .then(
        async (us) => {
          await this.shiftService
            .getEmpByEmail(us.Email)
            .toPromise()
            .then(
              (emp) => {
                this.empData = emp;
                console;
              },
              (error) => {
                this.error = error;
              }
            );
        },
        (error) => {
          this.error = error;
        }
      );
  }

  //Add-on items poup show
  async OpenAddOnModel(itemUniqueId) {
    $("#AddOnPopup").modal("show");
    this.AddonItemUniqueId = itemUniqueId;
  }
  ngOnDestroy(): void {
    // this.paymentOptionService.KitchenPostObjectObservable.unsubscribe();

  }

  printUnPaidOrder(salesOrderId) {

    let param1 = 'salesOrder' + '#' + salesOrderId;
    this.printService.printDocument("unPaid", [param1])
  }
  async printQrPaidOrder(saleorderid, prepareaid) {
    let temparr = [];
    let isSameSaleorder = false;
    temparr = await this.QrOrderingOrder.filter(f => (f.SalesOrderId == saleorderid && f.PrepAreaId != prepareaid && f.PrepAreaId != "" && f.Status !=5));
    //temparr = await temparr.filter(f => f.PrepAreaId != prepareaId)
    console.log(temparr);
    if (temparr.length > 0) {
      isSameSaleorder = true;
      Swal.fire({
        text: "Do you want to print for all the preparea Items?",
        type: 'warning',

        showConfirmButton: true,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No ,only for this preparea'
      }).then((result:any) => {
        console.log(result);
      
        if (result.value) {

          let param1 = 'salesOrder' + '#' + saleorderid;
          this.printService.printDocument("unPaid", [param1])
          
        } else if (result.dismiss == "cancel") {
          let param1 = 'salesOrder' + '#' + saleorderid + '#'+ this.SelectedPrepArea ;
          this.printService.printDocument("unPaid", [param1])
          
        }
      })
    }
    else if (temparr.length == 0 && !isSameSaleorder) {

      let param1 = 'salesOrder' + '#' + saleorderid + '#' + this.SelectedPrepArea;
      this.printService.printDocument("unPaid", [param1])
    }
  }
  clickOnDots(index) {
    console.log("ele:", index);
    $('.btn-group-' + index + ' .expand-btn-group').addClass('hideDots');
    $('.btn-group-' + index + ' .btn-group-item').addClass('showbtn');
  }


  setPrepId(val) {
    this.SelectedPrepArea = val;
    //  this.currentActiveBarTab=val;
    // console.log(this.SelectedPrepArea);

    sessionStorage.setItem('SelectedPrepArea', this.SelectedPrepArea);


    $("#order").addClass('active');
    $(".order").addClass('active');
    $("#served").removeClass('active');
    $(".served").removeClass('active');


    //if (this.locKitchen == false) {
    //  Swal.fire({
    //    html: "OOPS! Session expired. Please login again.<br><br>" +
    //      '<a class="swal2-confirm swal2-styled" href="/authorization/SignOut">LOGOUT</a>',
    //    showCancelButton: false,
    //    showConfirmButton: false,
    //    type: "warning",
    //    allowOutsideClick: false,
    //    allowEscapeKey: false
    //  });
    //}
    this.commonServ.GetDateTimeByTimeZone().subscribe(tz => {
      this.timezoneDate = tz;
      this.SelectedDate = moment(this.timezoneDate).startOf("day").format("YYYY-MM-DD[T]HH:mm:ss");
      this.previous = moment(this.timezoneDate).subtract(1, 'days').format("YYYY-MM-DD[T]HH:mm:ss");
      this.endDate = moment(this.SelectedDate).format("YYYY-MM-DDT23:59:59");
      console.log("func", "setPrepId")
      this.setDate("", val);

    });
  }





  async UpdateKitchenOrderStatus(Order: any) {
    var KitchenOrders = CloneObject(Order.KitchenOrder);
    if (KitchenOrders) {
      console.log("staus change", KitchenOrders)
      await this.dineinService
        .updateKitchenOrderComplteStatus(4, KitchenOrders)
        .subscribe(
          (data) => {
            this.statsService.getKOrders(
              this.startDateForKitchen,
              this.endDateForKitchen
            );
          },
          (err) => {
            this.LoaderService.hide();
          }
        );
      // for (let i = 0; i < KitchenOrders.length; i++) {
      //   var kitchen=KitchenOrders[i];
      // await this.dineinService
      // .updateKitchenStatus(kitchen.KitchenId, 4, {})
      // .subscribe(
      //   (data) => {
      //     this.statsService.getKOrders(
      //       this.startDateForKitchen,
      //       this.endDateForKitchen
      //     );
      //   },
      //   (err) => {
      //     this.LoaderService.hide();
      //   }
      // );
      // }

    }
  }
  CalculateDiffCount(dateSent): any {
    dateSent = new Date(dateSent);
    // console.log("datesent",dateSent);
    var currentdate = new Date(this.currentDateTime);
    //console.log("selected",this.currentDateTime);
    // var currentdate= new Date();
    var diff = currentdate.getTime() - dateSent.getTime();

    var minutes = diff ? diff / 60000 : 0;
    //  console.log("minutes",minutes);
    // console.log("------");

    if (minutes > 15) return Number(minutes.toFixed(0));
    else return Number(minutes.toFixed(0));
  }
  setAll(completed: boolean) {
    this.allChecked = completed;
    if (this.cancelKOrders == null) {
      return;
    }
    this.cancelKOrders.forEach(t => (t.Checked = completed));
  }
  updateAllComplete() {
    this.allChecked = this.cancelKOrders != null && this.cancelKOrders.every(t => t.Checked);
  }
  ContinueSelectedCancelOrder() {
    if (this.cancelKOrders.some(t => (t.Checked == true))) {
      $("#CancelKorder").modal("hide");
      $("#qrcodemodal").modal("show");
    } else {
      console.log("not captured");
    }

  }
  paymentCancelNo() {
    this.LoaderService.popClose();
    this.LoaderService.startTimer();
  }
  async paymentCancelYes() {
    this.LoaderService.isCancelOrder.next(true);
    this.LoaderService.popClose();
    this.LoaderService.hidePaymentloader()   

    await this.SalesorderService.getSalesOrder(this.SalesOrderId).subscribe(async (res: any) => {
      console.log('by Id', res)
      this.salesOrderData = res
      this.tempArr = this.salesOrderData;
      var refundobj: any = {};
      console.log('tempArr', this.tempArr)
      this.tempArr.Concession.map(async (x) => {
        x.TtlPrice = x.Price;
        x.Price = x.Price + x.Tax
        x.ActualQuantity = 1;
        x.NumberSold = 0;
        x.isConcession = true;
        x.ConfirmationCode = this.salesOrderData.ConfirmationCode;
})

      refundobj.ConfirmationCode = this.salesOrderData.ConfirmationCode
      refundobj.Tickets = this.salesOrderData.Tickets;
      refundobj.Concession = this.tempArr.Concession;
      refundobj.RefundReason = "customer requested";
      refundobj.ShiftId = this.salesOrderData.ShiftId;
      refundobj.PaymentMode = this.salesOrderData.PaymentMode;
      refundobj.PaymentID = this.salesOrderData.PaymentID;
      refundobj.PaymentType = this.salesOrderData.PaymentType;
      refundobj.MerchantCredentialId = this.salesOrderData.MerchantCredentialId;
      refundobj.RefundAmount = this.salesOrderData.TotalAmount + this.salesOrderData.TotalTax;
      refundobj.LaneId = this.salesOrderData.LaneId;
      refundobj.CClastFour = this.salesOrderData.CClastFour;
      refundobj.SalesSplit = this.salesOrderData.SalesSplit;
      refundobj.AdditionalCharges = this.salesOrderData.AdditionalCharges.filter(x => x.Index == 11);
      refundobj.WorldpayTransactionDetails = this.salesOrderData.WorldpayTransactionDetails;




      this.RefundArray.push(refundobj);

      console.log(this.RefundArray, "RefundArray)")
      console.log('this.salesOrderData.PaymentMode', this.salesOrderData.PaymentMode, this.salesOrderData.PaymentID, this.salesOrderData.GiftCardNumber)
      if (this.salesOrderData.PaymentMode == PaymentMode.Cash) {
        this.posService.cancelSales(this.salesOrderData).subscribe(
          async (result) => {
            console.log('result', result);
           // window.location.reload();
          })
      }
      else if (this.salesOrderData.PaymentMode == PaymentMode.CC && this.salesOrderData.PaymentID) {
        await this.posService.refundSalesOrder(this.RefundArray, "WorldpayRefund").subscribe(async (res: any) => {
          console.log('refund', res);
          this.LoaderService.isCancelOrder.next(true);
         // window.location.reload();
        }, (err) => {
        })
      }
      else if (this.salesOrderData.PaymentMode == PaymentMode.GiftCard && this.salesOrderData.GiftCardNumber) {
        await this.posService.refundSalesOrder(this.RefundArray, "WorldpayRefund").subscribe(async (res: any) => {
          console.log('refund', res);
          this.LoaderService.isCancelOrder.next(true);
         // window.location.reload();
        }, (err) => {
        })
      }
      else if (this.salesOrderData.PaymentMode == PaymentMode.ManualCC && this.salesOrderData.PaymentID) {
        await this.posService.refundSalesOrder(this.RefundArray, "WorldpayRefund").subscribe(async (res: any) => {
          console.log('refund', res);
          this.LoaderService.isCancelOrder.next(true);
       //   window.location.reload();
        }, (err) => {
        })
      } else {
        this.posService.cancelSales(this.salesOrderData).subscribe(
          async (result) => {
            console.log('result', result);
          //  window.location.reload();
          })
      }

    })




  }
}

