import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImageType } from '../models/AddHelperModal';

@Injectable({
  providedIn: 'root'
})

export class FileUploadService extends ApiService {
  ApiBaseUrl: string;
  CloudFareApiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') url: string) {
    super(url);
    this.ApiBaseUrl = this.ApiEndPoint + "Upload";
    this.CloudFareApiBaseUrl = this.ApiEndPoint + "Cloudflare";
  }

  async uploadImage(image: ImageType, old_filename: string, errObj: Object) {
    if (image.file === null) {
      return;
    }
    const formData = new FormData();
    let filename = new Date().getTime().toString() + "." + image.file.name.split(".")[1];
    formData.append("Image", image.file, filename);
    formData.append("Old_Filename", old_filename);

    await this.uploadFile(formData, image).catch(
      error => {
        errObj['error'] = error;
      }
    );
  }


  async uploadFile(formData: FormData, image: ImageType) {
    return this.http.post(this.ApiBaseUrl, formData, {
      reportProgress: true,
      observe: "events"
    })
      .pipe(map(events => {
        if (events.type === HttpEventType.UploadProgress) {
          image.progress = Math.round((events.loaded / events.total) * 100);
        } else if (events.type === HttpEventType.Response) {
          image.url = "https://cinema360blobendpoint.azureedge.net/cinema360/" + events.body["name"];
          image.file = null;
        }
      })).toPromise().catch(this.errorHandler);
  }

  async uploadCompanyLogoFile(formData: FormData, image: ImageType) {
    return this.http.post(this.ApiBaseUrl + "/UploadCompanyLogo", formData, {
      reportProgress: true,
      observe: "events"
    })
      .pipe(map(events => {
        if (events.type === HttpEventType.UploadProgress) {
          image.progress = Math.round((events.loaded / events.total) * 100);
        } else if (events.type === HttpEventType.Response) {
          let filename: string = events.body["filename"].split("$");
          image.url = "https://cinema360blobendpoint.azureedge.net/cinema360/" + filename[1];
          image.file = null;
        }
      })).toPromise().catch(this.errorHandler);
  }

  async DeleteFile(filename: string) {
    return this.http.delete(this.ApiBaseUrl, { params:{FileName: filename }}).toPromise().catch(this.errorHandler);
  }
  static getImageUrl(filename){
    if(filename)
        return filename;
    else
    return '';
  }

   //cloudfare upload

   async uploadFileToCloudFlare(image: ImageType, oldFilename: string, errObj: Object): Promise<void> {
    if (image.file === null) {
      return;
    }

    const formData = new FormData();
    formData.append("Image", image.file, image.file.name);
    formData.append("OldFilename", oldFilename);

    try {
      const response: any = await this.http.post(`${this.CloudFareApiBaseUrl}/Upload`, formData, {
        reportProgress: true,
        observe: 'events'
      }).toPromise();

      console.log('response from cloudflare', response);

      if (response.body.ImageUrl) {
        image.url = response.body.ImageUrl; // Use the provided ImageUrl from the response
        image.file = null;
      }
    } catch (error) {
      this.errorHandler(error);
      errObj['error'] = error;
    }
  }

  async uploadCompanyLogoFileToCloudFlare(image: ImageType, oldFilename: string, newHeight: number, newWidth: number, errObj: Object): Promise<void> {
    try {
      const formData = new FormData();
      formData.append("Image", image.file, image.file.name);
      formData.append("OldFilename", oldFilename);
      formData.append("newHeight", newHeight.toString());
      formData.append("newWidth", newWidth.toString());

      const response: any = await this.http.post(`${this.CloudFareApiBaseUrl}/UploadCompanyLogo`, formData, {
        reportProgress: true,
        observe: 'events'
      }).toPromise();

      if (response.body.ImageUrl) {
        image.url = response.body.ImageUrl; // Use the provided ImageUrl from the response
        image.file = null;
      }
    } catch (error) {
      this.errorHandler(error);
      errObj['error'] = error;
      return null;
    }
  }

  async DeleteFileFromCloudFlare(filename: string) {
    return this.http.delete(this.CloudFareApiBaseUrl, { params:{FileName: filename }}).toPromise().catch(this.errorHandler);
  }

}

