import { HttpClient, HttpBackend } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DailySummaryInput } from '../../report/report-daily-summary/report-daily-summary.component';
import { ApiService } from './api.service';
import { ConcessionReportInput } from '../../report/report-concession/report-concession.component';
import { ShiftReportInput } from '../../report/report-shift/report-shift.component';
import { SalesOrder } from '../models/SalesOrder';

@Injectable({
  providedIn: 'root'
})
export class SalesorderService extends ApiService {
  ApiBaseUrl: string;
  httpBackedurl: HttpClient;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private backend: HttpBackend) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + "api/Salesorder";
    this.httpBackedurl = new HttpClient(backend);
  }

  GetDailySummary(data: DailySummaryInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/GetDailySummary', data)
      .pipe(catchError(this.errorHandler));
  }

  DailyTransactionSummary(data: DailySummaryInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/DailyTransactionSummary', data)
      .pipe(catchError(this.errorHandler));
  }

  ConcessionTransactionSummary(data: ConcessionReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/ConcessionTransactionSummary', data)
      .pipe(catchError(this.errorHandler));
  }

  ShiftTransactionSummary(data: ShiftReportInput): Observable<Array<any>> {
    return this.http.post<Array<any>>(this.ApiBaseUrl + '/ShiftTransactionSummary', data)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrder(ID: String): Observable<SalesOrder> {
    return this.http.get<SalesOrder>(this.ApiBaseUrl + "/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByCreditCard(ID: String): Observable<Array<SalesOrder>> {
    return this.http.get<Array<SalesOrder>>(this.ApiBaseUrl + "/GetByCreditCardInfo/" + ID)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByUserName(FirstName: string, LastName: string): Observable<Array<SalesOrder>> {
    return this.http.get<Array<SalesOrder>>(this.ApiBaseUrl + "/GetByFirstLastName/" + FirstName + "/" + LastName)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByPhoneNumber(phone: String): Observable<Array<SalesOrder>> {
    return this.http.get<Array<SalesOrder>>(this.ApiBaseUrl + "/GetByPhone/" + phone)
      .pipe(catchError(this.errorHandler));
  }
  public getSalesOrderByEmail(email: String): Observable<Array<SalesOrder>> {
    return this.http.get<Array<SalesOrder>>(this.ApiBaseUrl + "/GetByEmail/" + email)
      .pipe(catchError(this.errorHandler));
  }

  public changeTicketStatus(ID: String, seatnumber: String, status: number): Observable<SalesOrder> {
    return this.http.patch<SalesOrder>(this.ApiEndPoint + "/ChangeTicketStatus/" + ID + "/" + seatnumber + "/" + status, null)
      .pipe(catchError(this.errorHandler));
  }

  GetGiftCardSalesReport(giftCardNumber: any): Observable<Array<SalesOrder>> {
    return this.http.get<Array<SalesOrder>>(this.ApiBaseUrl + '/GetGiftCardSalesReport/' + giftCardNumber)
      .pipe(catchError(this.errorHandler));
  }

  PostSalesorderFormData(salesorder: any) {
    return this.http.post<SalesOrder>("/api/SalesOrder", salesorder).pipe(catchError(this.errorHandler));
  }

  PostSalesorderFormDataWithoutLoader(salesorder: any) {
    return this.httpBackedurl.post<SalesOrder>("/api/SalesOrder", salesorder).pipe(catchError(this.errorHandler));
  }
}
