import { Component, OnInit, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Pos } from '../../POS/pos';
import { Shift, Summary, ActualSummary } from '../../shift/shift';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonSettings } from 'src/app/helper/commonSettings';
import { POSService } from 'src/app/pos/pos.service';
import { ShiftService } from 'src/app/shift/shift.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { CurrentUserService } from '../../helper/services/current-user.service';
@Component({
  selector: 'app-dineincloseshift',
  templateUrl: './dineincloseshift.component.html',
  styleUrls: ['./dineincloseshift.component.css']
})
export class DineincloseshiftComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true })
	dtElement: DataTableDirective;
	dtTrigger: Subject<any> = new Subject<any>();
	dtOptions: DataTables.Settings = {};

	modalRef: BsModalRef;
	viewPos: Pos = new Pos();
	pos: Pos[] = [];
	loading: boolean = true;
	error: string = '';
	deleteSwal = CommonSettings.deleteSwal;
	LocationId: string;

	posData: Pos = new Pos();
	shiftData: Shift = new Shift();
	summaryData: Summary = new Summary();
	empData: any = new Object();
	endShiftData: Shift = new Shift();
	printTitle: string;

	empModel: any = {
		empEmail: "",
		empPIN: "",
		empStartingBal: 0
	};

  constructor(private _AR: ActivatedRoute, private posService: POSService, private modalService: BsModalService, private router: Router, private shiftService: ShiftService, private currUserService: CurrentUserService) {
    this.currUserService.getContextLocationId().subscribe(locId => {
      this.LocationId = locId;
    });

		this.posData = JSON.parse(sessionStorage.getItem("posData"));
		this.shiftData = JSON.parse(sessionStorage.getItem("shiftData"));
	}

	async ngOnInit() {
		this.endShiftData = history.state.endShiftData;
		this.endShiftData.StartTime = new Date(history.state.endShiftData.StartTime);
		this.endShiftData.EndTime = new Date(history.state.endShiftData.EndTime);

		this.LoadPOSSalesShiftSummary();
		this.LoadEmpData();
		this.printTitle = "shift_summary_" + moment(this.endShiftData.EndTime).format("MMDDYYYYHHmmss");
		Object.assign(this.dtOptions, CommonSettings.dtOptions);
		this.dtOptions.order = [[3, 'desc'], [0, 'asc']];
  }
  unsetSession(){
    delete sessionStorage.shiftData;
    delete sessionStorage.posData;
	// setTimeout(() => {
	// 	window.location.reload();
	// },2000);
  }
	openViewModal(template: TemplateRef<any>, pos: Pos) {
		this.viewPos = pos;
		this.modalRef = this.modalService.show(template);
	}

	async LoadPOSSalesShiftSummary() {
		if(this.shiftData && this.shiftData.ShiftId){
			await this.shiftService.getNetShiftSummary(this.shiftData.ShiftId).toPromise().then(async (s: Summary) => {
				this.summaryData = s;
				console.log("this.summaryData",this.summaryData)
			}, error => {
				this.error = error;
			});
		}
	}

	async LoadEmpData() {
		await this.shiftService.getCurrentUser().toPromise().then(async (us) => {
			await this.shiftService.getEmpByEmail(us.Email).toPromise().then((emp) => {
				this.empData = emp;
			}, error => {
				this.error = error;
			});

		}, error => {
			this.error = error;
		});
	}
}
