import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { ClientCompany } from './client-company.model';

@Injectable({
  providedIn: 'root'
})

export class ClientCompanyService extends ApiService {

  ApiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/ClientCompany';
  }

  getClientCompany(id: string): Observable<any> {
    return this.http
      .get<any>(this.ApiBaseUrl + '/' + id)
      .pipe(catchError(this.errorHandler));
  }

  registerCompany(newClientCompany: ClientCompany): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl, newClientCompany)
      .pipe(catchError(this.errorHandler));
  }
}
