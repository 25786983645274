jQuery(document).ready(function () {
  Metronic.init(); // init metronic core componets
  Layout.init(); // init layout
  // UIToastr.init();
});
$(document).on("click", ".animate-menu-button", function () {
  if ($("body").hasClass("menu_toggel_dev"))
    $("body").removeClass("menu_toggel_dev")
  else
    $("body").addClass("menu_toggel_dev")
});

function IsNumeric(evt) {
  var theEvent = evt || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode(key);
  if (key.length == 0)
    return;
  var regex = /^[0-9]+$/;
  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault)
      theEvent.preventDefault();
  }
}

$(document).on("beforeinput", "input[type='number']", function (e) {
  // if (e.originalEvent.data == '-' || e.originalEvent.data == ' ' || e.originalEvent.data == ',') {
  //   e.preventDefault();
  //   return false;
  // }
  // if (!(
  //     e.keyCode == 38 || e.keyCode == 9 || e.keyCode == 40 || e.keyCode == 190 || e.keyCode == 110 ||
  //     (e.keyCode > 95 && e.keyCode < 106) ||
  //     (e.keyCode > 47 && e.keyCode < 58) ||
  //     e.keyCode == 8)) {
  //   return false;
  // }
});

$(document).on("mouseover", ".main_menu", function (e) {
  //   $(e.target).popover({
  //     trigger: 'hover',
  //     html: true,
  //     content: function () {
  //       var menu_name = $(e.target).find("span").text();
  //       console.log("menu ::=>", menu_name);
  //       return menu_name;
  //     },
  //     placement: 'right',
  //     delay: {
  //       show: 0,
  //       hide: 1000
  //     }
  //   });
  $(".left-menu").addClass("left-menu-showed");
  $("body").removeClass("menu_toggel_dev");
});

$(document).on("mouseout", ".main_menu", function (e) {
  $(".left-menu").removeClass("left-menu-showed");
  $("body").addClass("menu_toggel_dev");
});