export class Location {
  Location_ID: string = '';
  Company_ID: string = '';
  Location_Name: string = '';
  Location_Legal_Name: string = '';
  Nick_Name: string = '';
  Address: string = '';
  Country: string = '';
  State: string = '';
  City: string = '';
  Zipcode: string = '';
  PhoneNumber: string = '';
  Fax: string = '';
  Email: string = '';
  Timezone: string = '';
  Visible: number;
  Retrack_ID: string = '';
  CreatedBy: string = '';
  IsDineInEnabled :boolean = false;
  //CreatedOn: string = '';
  UpdatedBy: string = '';
  UpdatedOn: string = '';
 IsDigitalPrintEnabled: boolean = false;
 LogoImage: string = '';
}
export class Country {
name: string = '';
code: string = '';
}
export class CountLocationModule {
PriceCard: number = 0;
Employee: number = 0;
EmployeeRole: number = 0;
Concession: number = 0;
FoodItem: number = 0;
Inventory: number = 0;
MovieSchedule: number = 0;
SalesChannel: number = 0;
Screen: number = 0;
Tax: number = 0;
TicketType: number = 0;
LocationMovie: number = 0
}
export class LmsorTms {
  featureId: string = '';
  name: string = '';
  value: number = 0;
}
export class LocationFeature {
  Location_ID: string = '';
  Features: Array<Features> = new Array<Features>();
}
export class Features {
  name: string = '';
  value: number = 0;
  LocalFTPDetail: LocalFtpDetail  
}
export class LocalFtpDetail {
  FTPUrl: string = '';
  FTPUsername: string = '';
  FTPPassword: string = '';
}
export class Plan {
  planId: string = '';
  name: string = '';
  monthlyCost: number = 0;
  yearlyCost: number = 0;
  Property:Properties
}
export class Properties {
  noOfPOSAllowed: number = 0;
  dineInIncluded: boolean = false;
}
