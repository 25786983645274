import { Injectable, Inject } from '@angular/core';
import { Employee, EmployeeOverrideAccess } from './employee';
import { Observable } from 'rxjs';
import {
  HttpClient,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../helper/services/api.service';
import { CompanyRole } from '../company/company-role';

@Injectable({
  providedIn: 'root'
})

export class EmployeeService extends ApiService{
  ApiBaseUrl: string;
  BaseUri: string;
  ApiCompanyRoleBaseUrl: string;
  Employees: Employee[];


  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    super(baseUrl);
    this.ApiBaseUrl = baseUrl + 'api/Employee';
    this.ApiCompanyRoleBaseUrl = baseUrl + 'api/CompanyRole';
    this.BaseUri = baseUrl;
  }
  getEmployeeExist(emailAddress: string, locationId: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/CheckEmployee/' + emailAddress +'/'+ locationId)
      .pipe(catchError(this.errorHandler));
  }
  getEmailExist(emailAddress: string): Observable<boolean> {
    return this.http
      .get<boolean>(this.ApiBaseUrl + '/CheckEmail/' + emailAddress)
      .pipe(catchError(this.errorHandler));
  }

  getEmployees(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.ApiBaseUrl + '/GetEmployeesByLocationId')
      .pipe(catchError(this.errorHandler));
  }

  getEmployeeOverrideAccess(): Observable<EmployeeOverrideAccess> {
    return this.http
      .get<EmployeeOverrideAccess>(this.ApiBaseUrl + '/GetEmpOverrideAccess')
      .pipe(catchError(this.errorHandler));
  }

  GetAllEmployeesByCompanyId(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.ApiBaseUrl + '/GetAllEmployeesByCompanyId')
      .pipe(catchError(this.errorHandler));
  }

  getActiveEmployees(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.ApiBaseUrl + '/GetActiveEmployeesByLocationId')
      .pipe(catchError(this.errorHandler));
  }

  getEmployeesWithRoles(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.ApiBaseUrl + '/GetEmployeesByLocationIdWithRoles')
      .pipe(catchError(this.errorHandler));
  }
  getEmployeesWithallRoles(): Observable<Employee[]> {
    return this.http
      .get<Employee[]>(this.ApiBaseUrl + '/GetEmployeesByLocationIdWithallRoles')
      .pipe(catchError(this.errorHandler));
  } 

  getEmployeeByID(id: string): Observable<Employee> {
    if (id) {
      return this.http
        .get<Employee>(this.ApiBaseUrl + '/' + id)
        .pipe(catchError(this.errorHandler));
    }
  }

  GetByEmail(email: string): Observable<Employee> {
    if (email) {
      return this.http
        .get<Employee>(this.ApiBaseUrl + '/GetByEmail/' + email)
        .pipe(catchError(this.errorHandler));
    }
  }

  addEmployee(NewEmployee: Employee): Observable<any> {
    return this.http
      .post(this.ApiBaseUrl, NewEmployee)
      .pipe(catchError(this.errorHandler));
  }

  editEmployee(EditEmployee: Employee): Observable<any> {
    return this.http
      .put(this.ApiBaseUrl + '/' + EditEmployee.EmployeeId, EditEmployee)
      .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: number, Loc_Id:string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiBaseUrl + '/' + ID + '/' + Loc_Id + '/' +visible, null)
      .pipe(catchError(this.errorHandler));
  }

  getCompanyFeatures(): Observable<Array<CompanyRole>> {
    return this.http
      .get<Array<CompanyRole>>(this.ApiCompanyRoleBaseUrl)
      .pipe(catchError(this.errorHandler));
  }
}
