import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { KioskPOSService } from "../services/kiosk-pos.service";
import Swal from "sweetalert2";
import { GiftCard, GiftCardStatus } from "src/app/giftcard/giftcard";
import { GiftcardService } from "src/app/giftcard/giftcard.service";
import { GiftCardSales } from "src/app/giftcard/GiftCardSales";
import {
  SalesOrder,
  PaymentMode,
  SalesStatus,
} from "src/app/helper/models/SalesOrder";
import { POSService } from "src/app/pos/pos.service";
import { Concession } from "src/app/concession/concession";
import { FileUploadService } from "src/app/helper/services/file-upload.service";
import { ConcessionService } from "src/app/concession/concession.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { Shift } from "src/app/shift/shift";
import Keyboard from "simple-keyboard";
import { KioskLayoutComponent } from "../kiosk-layout/kiosk-layout.component";
import { CommonSettings } from "src/app/helper/commonSettings";
import { CommonService } from "src/app/helper/services/common.service";
import { LoaderService } from "../../helper/services/loader/loader.service";
import { PrintService } from "src/app/helper/services/print.service";
import {
  CloneObject,
  RoundAmount,
  TaxAmountCalculation,
} from "src/app/helper/models/AddHelperModal";
import { async } from "@angular/core/testing";
import { MerchantService } from "src/app/merchants/merchant.service";
import { PaymentService } from "../../helper/services/payment/payment.service";
import { SalesorderService } from "../../helper/services/salesorder.service";
import { forEachChild } from "typescript";
import { ErrorMessagesService } from "src/app/helper/services/error-messages.service";

@Component({
  selector: "app-kiosk-checkout",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./kiosk-checkout.component.html",
  styleUrls: [
    "./kiosk-checkout.component.css",
    "../../../../node_modules/simple-keyboard/build/css/index.css",
  ],
})
export class KioskCheckoutComponent implements OnInit {
  @ViewChild("gcinput", { static: false }) gcinput: ElementRef;
  public subscribed: any = [];
  public movieData: any = [];
  public moviewScreenData: any = {};
  public TotalServiceTax: number = 0;
  public finalPayment: number = 0;
  public finalTicketsPrice: number = 0;
  public TotalTickets: any;
  public totalAdditionalCharges: number = 0;
  public additionalCharges: any;
  public percentageAmount: number = 0;
  public giftCard = new GiftCardSales();
  public selectedgiftCard = new GiftCardSales();
  concessionFoods: Concession[] = new Array<Concession>();
  regularFoods: Concession[] = new Array<Concession>();
  public isConfirmedForPayment: number = 0;
  public customerGivenCash = 0;
  public amountSuggestions = [];
  public step: number = 0;
  public CardNumber: string;
  public keyboard: Keyboard;
  shiftData: Shift = new Shift();
  public Amount: number;
  PayMode = PaymentMode;
  public OperationType: GiftCardStatus = GiftCardStatus.Sold;
  GiftCardStatus = GiftCardStatus;
  public IsValidCard: boolean = false;
  public _GiftCardCheck: number = 0;
  public LocationId: string = "";
  public isOtherSelectedFLag = false;
  public GiftCards: GiftCardSales[] = new Array<GiftCardSales>();
  public _checkBalance: any = {};
  currentMerchantDetails: any;
  selectedMerchant: string;
  cc_card_number: any;
  selectedInput: any;
  cc_last_four: any;
  isBluredAllowed: boolean = true;
  printTitle: string;
  transactionid: string = null;
  cardTypeWorldpay: any = null;
  /*0 => not confirmed , 1 => Ask For Confirmation, 2 => Confirmed*/
  public paymentDetails = new paymentDetails();

  constructor(
    public router: Router,
    private KioskLayoutComponent: KioskLayoutComponent,
    public posService: POSService,
    private concessionService: ConcessionService,
    public kioskPOSService: KioskPOSService,
    private GC: GiftcardService,
    private printService: PrintService,
    private CommonService: CommonService,
    private LoaderService: LoaderService,
    private merchantService: MerchantService,
    private PaymentService: PaymentService,
    private toastrService: ToastrService,
    private salesOrderService: SalesorderService,
    private renderer: Renderer2,
    private es: ErrorMessagesService
  ) {
    this.movieData = this.kioskPOSService.movieData;
    this.getTotalTicketsPrice();
    this.getFinalPrice();
    this.getScreensDataForMovie();
    // this.shiftData = JSON.parse(sessionStorage.getItem("shiftData"));
    // console.log("1qaaaaaaa",this.shiftData)
    // this.shiftData.StartTime = moment(new Date(this.shiftData.StartTime)).utc().format("MM/DD/YYYY h:mm A");
    // this.shiftData.EndTime = moment(new Date(this.shiftData.EndTime)).format("MM/DD/YYYY h:mm A");
  }

  ngOnInit() {
    this.additionalCharges = this.kioskPOSService.getAdditionalCharges();
    this.TotalTickets = this.kioskPOSService.getTicketsTotalTickets();
    this.step = 0;
    this._GiftCardCheck = 0;

    this.CommonService.GetDateTimeByTimeZone().subscribe(
      (tz) => {
        this.printTitle =
          "shift_summary_" + moment(tz).format("MMDDYYYYHHmmss");
      },
      (error: string) => Swal.fire(CommonSettings.commonErrorSwal)
    );

    this.concessionService.getConcessionsbyStatus(1).subscribe(
      (res) => {
        // this.concessionFoods = res
        //   .filter(
        //     (f) =>
        //       f.Visible == 1 &&
        //       (["drink", "snack", "food", "combo"].includes(
        //         f.ConcessionCategory
        //       ) ||
        //         (f.ConcessionCategory == "combo" &&
        //           f.ParentType.Food?.length > 0))
        //   )
        //   .map((f) => {
        //     f.ImageURL = FileUploadService.getImageUrl(f.ImageURL);
        //     return f;
        //   });
        this.concessionFoods = res.filter((f) =>f.Visible == 1).map((f) => {f.ImageURL = FileUploadService.getImageUrl(f.ImageURL);return f;});
        this.concessionFoods = res.sort((a, b) => {
          return -Concession.getCategory().findIndex(
            (c) => c.value === a.ConcessionCategory
          );
        });

        this.regularFoods = res
          .filter(
            (f) =>
              f.Visible == 1 &&
              (["food"].includes(f.ConcessionCategory) ||
                (f.ConcessionCategory == "combo" &&
                  f.ParentType.Food?.length == 0))
          )
          .map((f) => {
            f.ImageURL = FileUploadService.getImageUrl(f.ImageURL);
            return f;
          });
      },
      (err) => console.log("error", err)
    );
    this.GC.giftCard.subscribe((success) => (this.GiftCards = success));
    console.log("this.GiftCards", this.GiftCards);
    this.getMerchantProviderByCompanyId();
  }
  async getMerchantProviderByCompanyId() {
    this.currentMerchantDetails = await this.merchantService
      .getCompanyMerchant()
      .toPromise();
    this.selectedMerchant = this.currentMerchantDetails.MerchantName;
    console.log("selectedMerchant", this.selectedMerchant);
  }

  ngAfterViewInit() {
    this.gcinput.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subscribed.forEach((element) => {
      element.unsubscribe();
    });
  }

  // loadKeypadPayment(classname = 'PaymentKeyboard') {
  //   setTimeout(() => {
  //     this.keyboard = new Keyboard(classname, {
  //       onChange: input => this.onChangePayment(input),
  //       layout: {
  //         default: CommonSettings.keybSetting.numpadDef,
  //       },
  //       maxLength:16,
  //       theme: CommonSettings.keybSetting.theme
  //     });
  //   }, 1000);

  // }
  onChangePayment = (input: any) => {
    this.cc_card_number = input;
  };
  loadKeypad(classname = "simple-keyboard") {
    setTimeout(() => {
      this.keyboard = new Keyboard(classname, {
        onChange: (input) => this.onChange(input),
        layout: {
          default: CommonSettings.keybSetting.numpadDef,
        },
        maxLength: 16,
        theme: CommonSettings.keybSetting.theme,
      });
    }, 1000);
  }
  loadKeypad1(classname = "simple-keyboard") {
    setTimeout(() => {
      this.keyboard = new Keyboard(classname, {
        onChange: (input) => this.onChange1(input),
        layout: {
          default: CommonSettings.keybSetting.numpadDef,
        },
        theme: CommonSettings.keybSetting.theme,
      });
    }, 1000);
  }

  onChange = (input: any) => {
    if (input && input.length > 16) {
      Swal.fire(this.es.kioskErrorMsgList[17].message, this.es.kioskErrorMsgList[18].message, "error");
      //this.keyboard.clearInput();
      //this.keyboard = new Keyboard('GCNM3-keyboard', {
      //  onChange: input => this.onChange(input),
      //  layout: {
      //    default: CommonSettings.keybSetting.numpadDef,
      //  },
      //  theme: CommonSettings.keybSetting.theme
      //});

      // this.keyboard.setInput(this.CardNumber.toString());
      //return;
    } else {
      this.CardNumber = input;
    }
  };
  onChange1 = (input: any) => {
    this.Amount = input;
  };
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  onGcInputChange = (event: any) => {
    if (
      event &&
      event.target &&
      event.target.value &&
      event.target.value.length > 16
    ) {
      return;
    } else {
      this.keyboard.setInput(event.target.value);
    }
  };

  checkBalance() {
    this.IsValidCard = false;
    this.GC.getGiftCardbyCardNumber(this.CardNumber).then(
      (res: GiftCard) => {
        if (res != null) {
          if (res.Status != GiftCardStatus.Registered) {
            this._checkBalance = res;
            this.paymentDetails.isCardPayment = 8;
          } else {
            this.kioskPOSService.showSnackBar(
             this.es.kioskErrorMsgList[50].message
            );
          }
        } else {
          this.kioskPOSService.showSnackBar(
            this.es.kioskErrorMsgList[51].message
          );
        }
      },
      (error) => {
        this.kioskPOSService.showSnackBar(
          this.es.kioskErrorMsgList[52].message
        );
      }
    );
  }
  public getTotalTicketsPrice() {
    this.finalTicketsPrice = parseFloat(
      (
        this.kioskPOSService.getTicketsTotalPrice() +
        this.getAdditionalCharges()
      ).toFixed(2)
    );
    let FoodItems = this.kioskPOSService.getFoodItems();
    console.log(this.kioskPOSService.getTicketsTotalPrice());
    console.log(FoodItems);
    FoodItems.forEach((fItem, fIndex) => {
      if (fItem.PriceCardComboQty > 0) {
        let foodQty = fItem.Qty - fItem.PriceCardComboQty;
        this.finalTicketsPrice +=
          fItem.PriceCardComboQty * fItem.PriceCardComboUnitPrice;
      }
    });
  }

  public getAdditionalCharges() {
    this.additionalCharges = this.kioskPOSService.getAdditionalCharges();
    this.TotalTickets = this.kioskPOSService.getTicketsTotalTickets();
    let total = 0;
    if (this.additionalCharges && this.additionalCharges.length >= 0) {
      for (let data of this.additionalCharges) {
        total += data.AdditionalPrice + data.Tax;
      }
    }
    return total * this.TotalTickets;
  }

  public getScreensDataForMovie() {
    this.movieData.forEach((item, index) => {
      if (
        item.LocationId == this.kioskPOSService.getLocationId() &&
        item.MovieID == this.kioskPOSService.getMovieId()
      ) {
        this.moviewScreenData = item;
        this.moviewScreenData.ScreenName = "";
        this.moviewScreenData.ShowTime = "";
        this.moviewScreenData.Seats = "-";
        this.moviewScreenData.ScreenID = this.kioskPOSService.getScreenId();
        this.moviewScreenData.ShowID = this.kioskPOSService.getShowId();
        this.moviewScreenData.ShowProperties = this.kioskPOSService.getShowProperties();

        item.Screens.forEach((screenItem, screenIndex) => {
          if (screenItem.ScreenID == this.moviewScreenData.ScreenID) {
            this.moviewScreenData.ScreenName = screenItem.ScreenName;
            screenItem.ShowTimes.forEach((showItem, showIndex) => {
              if (showItem.ShowID == this.moviewScreenData.ShowID) {
                console.log(showItem.ShowTime);
                console.log(
                  this.kioskPOSService.covertTimeToFormat(
                    showItem.ShowTime,
                    "MMMM DD YYYY, hh:mm"
                  )
                );
                console.log(
                  this.kioskPOSService.covertTimeToFormat(
                    showItem.ShowTime,
                    "A"
                  )
                );
                this.moviewScreenData.ShowTime =
                  this.kioskPOSService.covertTimeToFormat(
                    showItem.ShowTime,
                    "MMMM DD YYYY, hh:mm"
                  ) +
                  " " +
                  this.kioskPOSService.covertTimeToFormat(
                    showItem.ShowTime,
                    "A"
                  );
                this.moviewScreenData.ShowTimeFinal = showItem.ShowTime;
              }
            });
          }
        });
        let seatsArray = this.kioskPOSService.getSeats();
        if (seatsArray.length > 0) {
          this.moviewScreenData.Seats = seatsArray.join(", ");
        }
      }
    });
  }
  async getCashChangePosibilities() {
    this.amountSuggestions = [];
    console.log("Math.ceil(this.totalPrice); ", Math.ceil(this.Amount));
    this.amountSuggestions.push(this.Amount);
    if (Math.ceil(this.Amount) != this.Amount) {
      this.amountSuggestions.push(Math.ceil(this.Amount));
    }
    if (this.Amount > 0) {
      // if (this.totalPrice % 5 != 0) {
      if (Math.ceil(this.Amount) % 2 != 0) {
        var nextMultipleof_two = this.Amount + (2 - (this.Amount % 2));
        if (this.amountSuggestions.indexOf(nextMultipleof_two) == -1) {
          nextMultipleof_two
            ? this.amountSuggestions.push(nextMultipleof_two)
            : "";
        }
      }
      var nextMultipleof_five = Math.ceil(this.Amount / 5) * 5;
      console.log("nextMultipleof_five", nextMultipleof_five);
      if (this.amountSuggestions.indexOf(nextMultipleof_five) == -1) {
        nextMultipleof_five
          ? this.amountSuggestions.push(nextMultipleof_five)
          : "";
      }

      var nextMultipleof_ten = this.Amount + (10 - (this.Amount % 10));
      if (this.amountSuggestions.indexOf(nextMultipleof_ten) == -1) {
        nextMultipleof_ten
          ? this.amountSuggestions.push(nextMultipleof_ten)
          : "";
      }
      console.log("nextMultipleof_ten", nextMultipleof_ten);
      //
      var nextMultipleof_fifty = this.Amount + (50 - (this.Amount % 50));
      if (this.amountSuggestions.indexOf(nextMultipleof_fifty) == -1) {
        nextMultipleof_fifty
          ? this.amountSuggestions.push(nextMultipleof_fifty)
          : "";
      }
      console.log("nextMultipleof_fifty", nextMultipleof_fifty);
      var nextMultipleof_hundred = this.Amount + (100 - (this.Amount % 100));
      if (this.amountSuggestions.indexOf(nextMultipleof_hundred) == -1) {
        nextMultipleof_hundred
          ? this.amountSuggestions.push(nextMultipleof_hundred)
          : "";
      }
      console.log("nextMultipleof_hundred", nextMultipleof_hundred);
      var nextMultipleof_twenty = this.Amount + (20 - (this.Amount % 20));
      if (
        this.amountSuggestions.indexOf(nextMultipleof_twenty) == -1 &&
        nextMultipleof_twenty < nextMultipleof_hundred
      ) {
        nextMultipleof_twenty
          ? this.amountSuggestions.push(nextMultipleof_twenty)
          : "";
      }
      console.log("nextMultipleof_twenty", nextMultipleof_twenty);
      // var nextMultipleof_fivehundred = this.totalPrice + (500 - this.totalPrice % 500);
      // // console.log("nextMultipleof_fivehundred",nextMultipleof_fivehundred);
      // if (nextMultipleof_fivehundred != nextMultipleof_hundred) {
      // 	nextMultipleof_fivehundred ? this.amountSuggestions.push(nextMultipleof_fivehundred) : '';
      // }
      // this.amountSuggestions.push(nextMultipleof_fivehundred);
      // var nextMultipleof_thousand = this.totalPrice + (1000 - this.totalPrice % 1000);
      // if (nextMultipleof_fivehundred != nextMultipleof_thousand) {
      // 	nextMultipleof_thousand ? this.amountSuggestions.push(nextMultipleof_thousand) : '';
      // }
      // if (this.amountSuggestions.length > 4) {
      // 	this.amountSuggestions = this.amountSuggestions.slice(0, 3)
      // }
      this.amountSuggestions.sort(function (a, b) {
        return a - b;
      });
      this.amountSuggestions.push("Other");
    }
  }
  selectAmount(amount) {
    console.log("amount", amount);
    if (amount == "Other") {
      this.customerGivenCash = null;
      this.isOtherSelectedFLag = true;
    } else {
      this.isOtherSelectedFLag = false;
      this.customerGivenCash = amount;
    }
  }
  async sellCard() {
    this.GC.giftCard
      .subscribe((f) => {
        if (f.find((f) => f.CardNumber === this.CardNumber)) {
          this.kioskPOSService.showSnackBar(
           this.es.kioskErrorMsgList[53].message
          );
          return;
        } else {
          this.GC.getGiftCardbyCardNumber(this.CardNumber).then(
            (res: GiftCard) => {
              if (res != null) {
                console.log("GiftCardSELECTED", res);
                if (res.Status != this.OperationType) {
                  let giftCard = new GiftCardSales();
                  giftCard.GiftCardId = res.GiftCardId;
                  giftCard.CardNumber = this.CardNumber;
                  giftCard.Amount = this.Amount;
                  giftCard.LocationId = this.kioskPOSService.getLocationId();
                  giftCard.CompanyId = res.CompanyId;
                  this.GC.GiftCardToCart(giftCard, "plus");
                  this.getCashChangePosibilities();
                  this.giftCard = giftCard;
                  this.paymentDetails.isCardPayment = 7;
                  this.selectedgiftCard = giftCard;
                  // this.CardNumber = null;
                  // this.Amount = null;
                  // this.step = 0;
                } else {
                  this.kioskPOSService.showSnackBar(
                    `${this.OperationType === GiftCardStatus.Sold
                      ? this.es.kioskErrorMsgList[54].message
                      : this.es.kioskErrorMsgList[55].message
                    }. ${this.OperationType === GiftCardStatus.Sold
                      ? this.es.kioskErrorMsgList[56].message
                      : this.es.kioskErrorMsgList[57].message
                    } `
                  );
                }
              } else {
                this.kioskPOSService.showSnackBar(
                 this.es.kioskErrorMsgList[51].message
                );
              }
            },
            (error) => {
              this.kioskPOSService.showSnackBar(
                this.es.kioskErrorMsgList[52].message
              );
            }
          );
        }
      })
      .unsubscribe();
  }

  paymentMode = PaymentMode.GiftCard;

  ChangePaymentMode(type: PaymentMode) {
    this.paymentMode = type;
    setTimeout(() => {
      this.gcinput.nativeElement.focus();
    }, 0);

    // let onElement = this.renderer.selectRootElement('gcinput');
    // onElement.focus();
  }

  async giftCardRecharge(Amount) {
    let PayMode: PaymentMode = PaymentMode.CC;
    if (this.GiftCards.length === 0) {
      if (this.CardNumber != null) {
        const res: GiftCard = await this.GC.getGiftCardbyCardNumber(
          this.CardNumber
        );

        if (res != null) {
          if (res.Status === GiftCardStatus.Registered) {
            this.kioskPOSService.showSnackBar(
              this.es.kioskErrorMsgList[4].message
            );
            return;
          } else {
            PayMode = PaymentMode.GiftCard;
            const res = await this.GC.patchGiftCard(this.CardNumber, Amount);
            if (!res) {
              this.kioskPOSService.showSnackBar(
                this.es.kioskErrorMsgList[58].message
              );
              return;
            }
            this.customerGivenCash = null;
            this.isOtherSelectedFLag = false;
            this.checkBalance();
          }
        } else {
          this.kioskPOSService.showSnackBar(
            this.es.kioskErrorMsgList[59].message
          );
          return;
        }
      }
    }
    //postMovie.POSID = null;
    if (this.GiftCards.length > 0) {
      this.GiftCards.forEach(async (g) => {
        g.ShiftId = this.shiftData.ShiftId;
        g.PaymentType = PaymentMode.CC;
        g.CompanyId = this.selectedgiftCard.CompanyId;
        g.LastDigits = "1234";
        g.SalesChannel = "Kiosk";
        console.log("GiftCards222");
        if (this.kioskPOSService.isSwipeReaderExist) {
          var amount = (Number(this.Amount) * 100).toFixed();
          var ccData = "stripes^" + amount + "^usd";
          g.CreditCardinfo = ccData;
        } else {
          Swal.fire({
            title: this.es.kioskErrorMsgList[19].message,
            text: this.es.kioskErrorMsgList[20].message,
            showCancelButton: false,
            type: "warning",
          });
          return;
        }
        // g.ShiftId = this.shiftData.ShiftId;
        let res = await this.GC.addGiftCardSales(g);
        if (res) {
          if (this.kioskPOSService.isSwipeReaderExist) {
            res.ConfirmationCode = res.TransactionId;
            await this.KioskLayoutComponent.CollectPaymentMethod(res).then(
              async (resCollectpayment) => {
                if (resCollectpayment) {
                  this.GC.GiftCardToCart(g, "minus");
                  // this.paymentDetails.isCardPayment = 0;
                  this.customerGivenCash = null;
                  this.isOtherSelectedFLag = false;
                  // this.paymentDetails = new paymentDetails();
                  this.checkBalance();
                  if (res) {
                    this.toastrService
                      .success(this.es.kioskErrorMsgList[43].message, "Success")
                      .onHidden.subscribe((f) => {
                        this.printService.printGCS = res;
                        this.printService
                          .printDocument("giftcardrecharge", null)
                          .subscribe((res) => {
                            //if (!res)
                            //this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
                          });
                      });
                  }
                } else {
                  Swal.fire(this.es.kioskErrorMsgList[21].message, this.es.kioskErrorMsgList[22].message, "error");
                }
              }
            );
            // this.KioskLayoutComponent.CollectPaymentMethod(res);
          } else {
            this.GC.GiftCardToCart(g, "minus");
            // this.paymentDetails.isCardPayment = 0;
            this.customerGivenCash = null;
            this.isOtherSelectedFLag = false;
            // this.paymentDetails = new paymentDetails();
            this.checkBalance();
            if (res) {
              this.toastrService
                .success(this.es.kioskErrorMsgList[43].message, "Success")
                .onHidden.subscribe((f) => {
                  this.printService.printGCS = res;
                  this.printService
                    .printDocument("giftcardrecharge", null)
                    .subscribe((res) => {
                      //if (!res)
                      //this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
                    });
                });
            }
          }
        }
      });
    }
  }

  async placeOrder(isPaid = false, paymentType, Amount) {
    this.LoaderService.showPaymentloader();
    this.CommonService.GetDateTimeByTimeZone().subscribe(
      async (tz) => {
        let body = {
          Tickets: [],
          Concession: [],
          AdditionalCharges: [],
          TotalAmount: Amount,
          SalesChannel: "Kiosk",
          TotalTax: 0,
          CurrencyType: "usd",
          PaymentMode: PaymentMode.CC,
          LocationId: this.kioskPOSService.getLocationId(),
          LocationName: this.moviewScreenData.LocationName,
          DateTimeSold: moment(tz).format("YYYY-MM-DDThh:mm:ss.000[Z]"),
          BarcodeImage: "",
          GiftCardNumber: "",
        };

        if (this.GiftCards.length === 0) {
          if (this.CardNumber != null) {
            body.GiftCardNumber = this.CardNumber;
            try {
              const res: GiftCard = await this.GC.getGiftCardbyCardNumber(
                this.CardNumber
              );
              if (res != null) {
                if (res.Status === GiftCardStatus.Registered) {
                  this.LoaderService.hidePaymentloader();
                  Swal.fire(
                    this.es.kioskErrorMsgList[23].message,
                    this.es.kioskErrorMsgList[24].message,
                    "info"
                  );

                  return;
                } else {
                  if (res.Expiry == null) {
                    this.LoaderService.hidePaymentloader();
                    Swal.fire(this.es.kioskErrorMsgList[25].message, "", "info");

                    return;
                  } else if (moment(tz).diff(res.Expiry, "days") > 0) {
                    this.LoaderService.hidePaymentloader();
                    Swal.fire(this.es.kioskErrorMsgList[26].message, "", "info");

                    return;
                  }
                  body.PaymentMode = PaymentMode.GiftCard;
                  const pay = await this.GC.patchGiftCard(
                    this.CardNumber,
                    Amount
                  );
                  if (!pay) {
                    this.LoaderService.hidePaymentloader();
                    Swal.fire(
                      this.es.kioskErrorMsgList[27].message,
                      this.es.kioskErrorMsgList[28].message,
                      "error"
                    );

                    return;
                  }
                }
              } else {
                this.LoaderService.hidePaymentloader();
                throw "Not found";
              }
            } catch (err) {
              this.LoaderService.hidePaymentloader();
              Swal.fire(
                this.es.kioskErrorMsgList[29].message,
                this.es.kioskErrorMsgList[30].message,
                "error"
              );
              return;
            }
          }
        }
        //postMovie.POSID = null;
        if (this.GiftCards.length > 0) {
          body.GiftCardNumber = this.CardNumber;
          this.GiftCards.forEach(async (g) => {
            // g.PaymentType = body.PaymentMode;
            g.SalesChannel = body.SalesChannel;
            g.ShiftId = this.shiftData.ShiftId;
            g.PaymentType = PaymentMode.CC;
            g.CompanyId = this.selectedgiftCard.CompanyId;
            g.LastDigits = "1234";
            if (this.kioskPOSService.isSwipeReaderExist) {
              var amount = (Number(this.Amount) * 100).toFixed();
              var ccData = "stripes^" + amount + "^usd";
              g.CreditCardinfo = ccData;
            } else {
              this.LoaderService.hidePaymentloader();
              Swal.fire({
                title: this.es.kioskErrorMsgList[19].message,
                text: this.es.kioskErrorMsgList[20].message,
                showCancelButton: false,
                type: "warning",
              });
              return;
            }
            // g.ShiftId = this.shiftData.ShiftId;
            let res = await this.GC.addGiftCardSales(g);
            if (res) {
              this.LoaderService.hidePaymentloader();
              this.toastrService.success(
                this.es.kioskErrorMsgList[43].message,
                "Success"
              );
              setTimeout(() => {
                this.router.navigate([
                  "/kiosk/home/" + this.kioskPOSService.getLocationId(),
                ]);
              }, 50);
              this.GC.GiftCardToCart(g, "minus");
            }
          });
        }

        let Tickets = this.kioskPOSService.getTickets();
        let Seats = this.kioskPOSService.getSeats();
        let sk = 0;
        let totalnumberticketgc = 0;
        Tickets.Tickets.forEach((ticket, i) => {
          totalnumberticketgc = totalnumberticketgc + ticket.Qty;
          for (var jj = 1; jj <= ticket.Qty; jj++) {
            let o = {
              MovieID: this.kioskPOSService.getMovieId(),
              MovieName: this.moviewScreenData.MovieName,
              TicketType: ticket.DisplayName,
              ComboID: ticket.ComboID || "",
              // TicketTypeID: ticket.TicketId,
              TicketID: ticket.TicketId,
              Price: ticket.Price,
              ScreenId: this.kioskPOSService.getScreenId(),
              // Tax: RoundAmount(ticket.Price * (ticket.Tax / 100)),
              Tax: RoundAmount(TaxAmountCalculation(ticket.Tax, ticket.Price)),
              ScreenName: this.moviewScreenData.ScreenName,
              SeatNumber:
                this.kioskPOSService.getSeating() == "reserved"
                  ? Seats[sk]
                  : null,
              Status:
                body.PaymentMode == PaymentMode.GiftCard
                  ? SalesStatus.Reserved
                  : SalesStatus.OnHold,
              ShowId: this.kioskPOSService.getShowId(),
              Showtime: this.kioskPOSService.covertTimeToFormat(
                this.moviewScreenData.ShowTimeFinal,
                "hh:mm A"
              ),
              // Showdate: this.kioskPOSService.covertTimeToFormat(
              //   this.moviewScreenData.ShowTimeFinal,
              //   "DD/MM/YYYY"
              // ),
              Showdate: this.kioskPOSService.covertTimeToFormat(
                this.moviewScreenData.ShowTimeFinal,
                "MM/DD/YYYY"
              ),
            };
            body.TotalTax = body.TotalTax + o.Tax;
            body.Tickets.push(o);
            sk++;
          }
        });
        let addCharges = this.kioskPOSService.getAdditionalCharges();
        if (addCharges?.length >= 0) {
          addCharges.forEach((addCharge, i) => {
            if (addCharge.SalesChannel.findIndex(x => x.SalesChannelName == "Kiosk") != -1) {
              let charges = {
                Index: addCharge.Index,
                Name: addCharge.Name,
                AdditionalPrice: addCharge.AdditionalPrice,
                Tax: ((addCharge.AdditionalPrice * addCharge.Tax) / 100),
                SalesChannel: addCharge.SalesChannel,
                NumberOfTicketSold: totalnumberticketgc
              };
              body.AdditionalCharges.push(charges);
            }
          });
        }
        let FoodItems = this.kioskPOSService.getFoodItems();
        FoodItems.forEach((food, i) => {
          if (food.PriceCardComboQty > 0) {
            let o1 = {
              ConcessionID: food.ConcessionId,
              ComboID: food.ComboID || "",
              ConcessionName: food.ConcessionName,
              Price: food.PriceCardComboUnitPrice,
              NumberSold: food.PriceCardComboQty,
              // Tax: RoundAmount(food.TotalPrice * (food.Tax / 100)),
              Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
              Status:
                body.PaymentMode == PaymentMode.GiftCard
                  ? SalesStatus.Reserved
                  : SalesStatus.OnHold,
            };
            body.TotalTax = body.TotalTax + o1.Tax;
            body.Concession.push(o1);
            if (food.Qty - food.PriceCardComboQty > 0) {
              let o2 = {
                ConcessionID: food.ConcessionId,
                ConcessionName: food.ConcessionName,
                Price: food.TotalPrice,
                NumberSold: food.Qty - food.PriceCardComboQty,
                // Tax: RoundAmount(food.TotalPrice * (food.Tax / 100)),
                Tax: RoundAmount(
                  TaxAmountCalculation(food.Tax, food.TotalPrice)
                ),
                Status:
                  body.PaymentMode == PaymentMode.GiftCard
                    ? SalesStatus.Reserved
                    : SalesStatus.OnHold,
              };
              body.TotalTax = body.TotalTax + o2.Tax;
              body.Concession.push(o2);
            }
          } else {
            let o = {
              ConcessionID: food.ConcessionId,
              ConcessionName: food.ConcessionName,
              Price: food.TotalPrice,
              NumberSold: food.Qty,
              // Tax: RoundAmount(food.TotalPrice * (food.Tax / 100)),
              Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
              Status:
                body.PaymentMode == PaymentMode.GiftCard
                  ? SalesStatus.Reserved
                  : SalesStatus.OnHold,
            };
            body.TotalTax = body.TotalTax + o.Tax;
            body.Concession.push(o);
          }
        });

        // Ensure that the holded seats are still available for this transaction.
        // If not available then send back to seat selection page.
        this.kioskPOSService
          .SubmitGetFormData(
            "HoldSeats/GetHoldSeatsByShowId/" + this.kioskPOSService.getShowId()
          )
          .subscribe(
            (HoldSeatsResponse) => {
              if (HoldSeatsResponse != "[]" && HoldSeatsResponse.length > 0) {
                this.kioskPOSService.userHoldSeats.forEach(
                  (userHoldSeat, i) => {
                    if (
                      HoldSeatsResponse.find(
                        (a) => a.HoldSeatId === userHoldSeat.HoldSeatId
                      ) == undefined
                    ) {
                      Swal.fire(this.es.kioskErrorMsgList[19].message, this.es.kioskErrorMsgList[31].message, "error");
                      this.kioskPOSService.userHoldSeats = [];
                      this.LoaderService.hidePaymentloader();
                      this.router.navigate([
                        "/kiosk/show-time/" +
                        this.kioskPOSService.getLocationId(),
                      ]);
                    }
                  }
                );
              }
              let clonefi = CloneObject(body.Concession)
              this.kioskPOSService
                .checkConcessionInventory(clonefi)
                .subscribe(
                  (res: any) => {
                    if (res.length > 0) {
                      // let isAre = res.length == 1 ? " is " : " are ";
                      // let items = res.join(", ");
                      // this.LoaderService.hidePaymentloader();
                      // Swal.fire({
                      //   title: "OOPS..!",
                      //   text: items + isAre + "out of stock in the inventory",
                      //   showCancelButton: false,
                      //   type: "warning",
                      // });
                      let items = res.join(", ");
                      console.log("items", items)
                      let scentence = [];
                      for (var i = 0; i <= (res.length - 1); i++) {
                        scentence.push(res[i])
                      }
                      let fullScentence = '';
                      scentence.forEach((f, i) => {
                        f = f.split('_')
                        fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
                      });
                      this.LoaderService.hidePaymentloader();
                      Swal.fire({
                        title: this.es.kioskErrorMsgList[32].message,
                        text: this.es.kioskErrorMsgList[33].message + fullScentence + this.es.kioskErrorMsgList[34].message,
                        showCancelButton: false,
                        type: "warning",
                      });
                      this.router.navigate([
                        "/kiosk/add-food-packs/" +
                        this.kioskPOSService.getLocationId(),
                      ]);
                    } else {
                      // All seats are available and so submit the order and delete the hold.
                      this.salesOrderService
                        .PostSalesorderFormData(body)
                        .subscribe(
                          async (response) => {
                            this.kioskPOSService.setSalesOrderData(response);
                            if (this.paymentDetails.isCardPayment == 1) {
                              // this.KioskLayoutComponent.CollectPaymentMethod(response);
                              await this.KioskLayoutComponent.CollectPaymentMethod(
                                response
                              ).then(async (resCollectpayment) => {
                                this.kioskPOSService.pickupTicketData = response;
                                if (resCollectpayment) {
                                  this.LoaderService.hidePaymentloader();
                                  this.kioskPOSService.pickupTicketData = response;
                                  this.toastrService
                                    .success(
                                      this.es.kioskErrorMsgList[43].message,
                                      "Success"
                                    )
                                    .onHidden.subscribe((re) => {
                                      this.router.navigate([
                                        "/kiosk/confirmTickets/" +
                                        this.kioskPOSService.getLocationId(),
                                      ]);
                                    });
                                } else {
                                  this.LoaderService.hidePaymentloader();
                                  Swal.fire(
                                    this.es.kioskErrorMsgList[21].message,
                                    this.es.kioskErrorMsgList[22].message,
                                    "error"
                                  );
                                  this.router.navigate([
                                    "/kiosk/checkout/" +
                                    this.kioskPOSService.getLocationId(),
                                  ]);
                                }
                              });
                            } else {
                              this.LoaderService.hidePaymentloader();
                              this.kioskPOSService.pickupTicketData = response;
                              this.toastrService
                                .success(this.es.kioskErrorMsgList[43].message, "Success")
                                .onHidden.subscribe((res) => {
                                  this.router.navigate([
                                    "/kiosk/confirmTickets/" +
                                    this.kioskPOSService.getLocationId(),
                                  ]);
                                });
                            }
                          },
                          (err) => {
                            if (body.PaymentMode == PaymentMode.GiftCard) {
                              const res = this.GC.patchGiftCardRefund(
                                body.GiftCardNumber,
                                body.TotalAmount
                              );
                            }
                            this.LoaderService.hidePaymentloader();
                            Swal.fire(
                              this.es.kioskErrorMsgList[35].message,
                              this.es.kioskErrorMsgList[36].message,
                              "error"
                            );
                            this.router.navigate([
                              "/kiosk/checkout/" +
                              this.kioskPOSService.getLocationId(),
                            ]);
                          }
                        );
                    }
                  },
                  (err) => {
                    this.LoaderService.hidePaymentloader();
                    console.log(err);
                  }
                );
            },
            (error) => {
              this.LoaderService.hidePaymentloader();
              console.log(error);
            }
          );
      },
      (err) => {
        this.LoaderService.hidePaymentloader();
        Swal.fire(
          this.es.kioskErrorMsgList[35].message,
          this.es.kioskErrorMsgList[37].message,
          "error"
        );
        this.router.navigate([
          "/kiosk/checkout/" + this.kioskPOSService.getLocationId(),
        ]);
      }
    );
  }

  public getFinalPrice() {
    let getFoodItemsTotalPrice = 0;
    let FoodItems = this.kioskPOSService.getFoodItems();
    FoodItems.forEach((fItem, fIndex) => {
      console.log("fItemgetFinalPrice", fItem);
      if (fItem && fItem.PriceCardComboQty > 0) {
        let foodQty = fItem.Qty - fItem.PriceCardComboQty;
        getFoodItemsTotalPrice += foodQty * fItem.TotalPrice;
        //getFoodItemsTotalPrice += fItem.PriceCardComboQty * fItem.PriceCardComboUnitPrice;
      } else {
        getFoodItemsTotalPrice += fItem.Qty * fItem.TotalPrice;
      }
    });
    this.finalPayment = this.finalTicketsPrice + getFoodItemsTotalPrice;
    // if (this.kioskPOSService.getTax() != '') {
    //   let percentage = parseFloat(this.kioskPOSService.getTax().replace(/%/g, ""));
    //   this.percentageAmount = 0;
    //   if (percentage > 0) {
    //     this.percentageAmount = (this.finalPayment * percentage) / 100;
    //     this.finalPayment += (this.percentageAmount > 0) ? this.percentageAmount : 0;
    //   }
    // }
  }

  public removeFoodItem(
    foodItem: any,
    index: number = 0,
    isPriceCardCombo: string = "n"
  ) {
    if (isPriceCardCombo == "n") {
      this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.splice(
        index,
        1
      );
    } else {
      this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems[
        index
      ].Qty = foodItem.PriceCardComboQty;
    }
    this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalPrice = 0;
    this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalFoodItems = 0;
    this.kioskPOSService.finalSelectedMovieData.FoodItems.selectedFoodItems.forEach(
      (fItem, fIndex) => {
        this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalPrice +=
          parseFloat(fItem.TotalPrice) * parseFloat(fItem.Qty);
        this.kioskPOSService.finalSelectedMovieData.FoodItems.TotalFoodItems +=
          fItem.Qty;
      }
    );
    this.getFinalPrice();
    this.kioskPOSService.setFoodItems(
      this.kioskPOSService.finalSelectedMovieData.FoodItems
    );
  }

  public askForConfirmation(e) {
    //set 1 for showing member page
    this.isConfirmedForPayment = 2;
  }

  public cancelConfirm(e) {
    this.isConfirmedForPayment = 0;
  }

  public gotToPayment(e) {
    this.LoaderService.showPaymentloader();
    this.isConfirmedForPayment = 2;
    let is_valid = true;
    if (
      this.paymentDetails.isCinemaMember == true &&
      this.paymentDetails.memberId == ""
    ) {
      is_valid = false;
      this.kioskPOSService.showSnackBar(this.es.kioskErrorMsgList[60].message, "close");
    }
    if (is_valid == true) {
      let body = {
        CreatedBy: "",
        CreatedOn: "",
        UpdatedBy: "",
        UpdatedOn: "",
        MerchantName: "",
        MerchantCredentialId: "",
        LaneId: 0,
        Tickets: [],
        Concession: [],
        AdditionalCharges: [],
        SalesChannel: "Kiosk",
        PaymentID: "",
        CCLastFour: "",
        TotalAmount: this.finalPayment,
        TotalTax: this.percentageAmount,
        PaymentType: null,
        LocationId: this.kioskPOSService.getLocationId(),
        LocationName: this.moviewScreenData.LocationName,
        DateTimeSold: moment(new Date()).format("YYYY-MM-DDTHH:mm:00.000[Z]"),
        CreditCardinfo: "",
        CurrencyType: "usd",
        PaymentMode: PaymentMode.CC,
      };

      let Tickets = this.kioskPOSService.getTickets();
      let Seats = this.kioskPOSService.getSeats();
      let sk = 0;
      let totalnumberticket = 0;
      Tickets.Tickets.forEach((ticket, i) => {
        totalnumberticket = totalnumberticket + ticket.Qty;
        for (var jj = 1; jj <= ticket.Qty; jj++) {
          let o = {
            MovieID: this.kioskPOSService.getMovieId(),
            MovieName: this.moviewScreenData.MovieName,
            ComboID: ticket.ComboID || "",
            TicketType: ticket.DisplayName,
            TicketID: ticket.TicketId,
            Price: ticket.Price,
            ScreenId: this.kioskPOSService.getScreenId(),
            ScreenName: this.moviewScreenData.ScreenName,
            SeatNumber:
              this.kioskPOSService.getSeating() == "reserved"
                ? Seats[sk]
                : null,
            Status: SalesStatus.OnHold,
            // Tax: RoundAmount(ticket.Price * (ticket.Tax / 100)),
            Tax: RoundAmount(TaxAmountCalculation(ticket.Tax, ticket.Price)),
            ShowId: this.kioskPOSService.getShowId(),
            Showtime: this.kioskPOSService.covertTimeToFormat(
              this.moviewScreenData.ShowTimeFinal,
              "hh:mm A"
            ),
            // Showdate: this.kioskPOSService.covertTimeToFormat(
            //   this.moviewScreenData.ShowTimeFinal,
            //   "DD/MM/YYYY"
            // ),
            Showdate: this.kioskPOSService.covertTimeToFormat(
              this.moviewScreenData.ShowTimeFinal,
              "MM/DD/YYYY"
            ),
          };
          body.TotalTax = body.TotalTax + o.Tax;
          body.Tickets.push(o);
          sk++;
        }
      });
      let addCharges = this.kioskPOSService.getAdditionalCharges();
      if (addCharges?.length >= 0) {
        addCharges.forEach((addCharge, i) => {
          if (addCharge.SalesChannel.findIndex(x => x.SalesChannelName == "Kiosk") != -1) {
            let charges = {
              Index: addCharge.Index,
              Name: addCharge.Name,
              AdditionalPrice: addCharge.AdditionalPrice,
              Tax: ((addCharge.AdditionalPrice * addCharge.Tax) / 100),
              SalesChannel: addCharge.SalesChannel,
              NumberOfTicketSold: totalnumberticket
            };
            body.AdditionalCharges.push(charges);
          }
        });
      }

      let FoodItems = this.kioskPOSService.getFoodItems();
      FoodItems.forEach((food, i) => {
        if (food.PriceCardComboQty > 0) {
          let o1 = {
            ConcessionID: food.ConcessionId,
            ComboID: food.ComboID || "",
            ConcessionName: food.ConcessionName,
            Price: food.PriceCardComboUnitPrice,
            NumberSold: food.PriceCardComboQty,
            Status: SalesStatus.OnHold,
            // Tax: RoundAmount(food.PriceCardComboUnitPrice * (food.Tax / 100)),
            Tax: RoundAmount(
              TaxAmountCalculation(food.Tax, food.PriceCardComboUnitPrice)
            ),
          };
          body.TotalTax = body.TotalTax + o1.Tax;
          body.Concession.push(o1);
          if (food.Qty - food.PriceCardComboQty > 0) {
            let o2 = {
              ConcessionID: food.ConcessionId,
              ConcessionName: food.ConcessionName,
              Price: food.TotalPrice,
              NumberSold: food.Qty - food.PriceCardComboQty,
              Status: SalesStatus.OnHold,
              // Tax: RoundAmount(food.TotalPrice * (food.Tax / 100)),
              Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
            };
            body.TotalTax = body.TotalTax + o2.Tax;
            body.Concession.push(o2);
          }
        } else {
          let o = {
            ConcessionID: food.ConcessionId,
            ConcessionName: food.ConcessionName,
            Price: food.TotalPrice,
            NumberSold: food.Qty,
            Status: SalesStatus.OnHold,
            // Tax: RoundAmount(food.TotalPrice * (food.Tax / 100)),
            Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
          };
          body.TotalTax = body.TotalTax + o.Tax;
          body.Concession.push(o);
        }
      });
      if (this.paymentDetails.isCardPayment == 1) {
        if (this.selectedMerchant == "Stripes") {
          if (this.kioskPOSService.isSwipeReaderExist) {
            var amount = (Number(this.finalPayment) * 100).toFixed();
            var ccData = "stripes^" + amount + "^usd";
            body.MerchantName = "Stripes";
            body.CreditCardinfo = ccData;
            body.PaymentMode = PaymentMode.CC;
          } else {
            this.LoaderService.hidePaymentloader();
            Swal.fire({
              title: this.es.kioskErrorMsgList[19].message,
              text: this.es.kioskErrorMsgList[20].message,
              showCancelButton: false,
              type: "warning",
            });
            return;
          }
        } else {
          body.CreditCardinfo = "" + this.cc_card_number;
          body.PaymentMode = PaymentMode.CC;
          if (this.kioskPOSService.selectedMerchant == "Worldpay") {
            let posData = JSON.parse(sessionStorage.getItem("posData"));
            let merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));
            body.MerchantName = "Worldpay";
            body.MerchantCredentialId = merchantInternalId;
            body.LaneId = posData.LaneId;
            body.PaymentID = this.transactionid;
            body.CCLastFour = this.cc_last_four;
            body.PaymentType = this.cardTypeWorldpay;
          } else {
            body.MerchantName = "Authorize.net";

          }
        }
      }
      this.kioskPOSService
        .SubmitGetFormData(
          "HoldSeats/GetHoldSeatsByShowId/" + this.kioskPOSService.getShowId()
        )
        .subscribe(
          (HoldSeatsResponse) => {
            if (HoldSeatsResponse != "[]" && HoldSeatsResponse.length > 0) {
              this.kioskPOSService.userHoldSeats.forEach((userHoldSeat, i) => {
                if (
                  HoldSeatsResponse.find(
                    (a) => a.HoldSeatId === userHoldSeat.HoldSeatId
                  ) == undefined
                ) {
                  this.LoaderService.hidePaymentloader();
                  Swal.fire(this.es.kioskErrorMsgList[19].message, this.es.kioskErrorMsgList[31].message, "error");
                  this.kioskPOSService.userHoldSeats = [];
                  this.router.navigate([
                    "/kiosk/show-time/" + this.kioskPOSService.getLocationId(),
                  ]);
                }
              });
            }
            // All seats are available and so submit the order and delete the hold.
            let clonefi_2 = CloneObject(body.Concession)
            this.kioskPOSService
              .checkConcessionInventory(clonefi_2)
              .subscribe(
                (res: any) => {
                  if (res.length > 0) {
                    // let isAre = res.length == 1 ? " is " : " are ";
                    // let items = res.join(", ");
                    // this.LoaderService.hidePaymentloader();
                    // Swal.fire({
                    //   title: "OOPS..!",
                    //   text: items + isAre + "out of stock in the inventory",
                    //   showCancelButton: false,
                    //   type: "warning",
                    // });
                    let items = res.join(", ");
                    console.log("items", items)
                    let scentence = [];
                    for (var i = 0; i <= (res.length - 1); i++) {
                      scentence.push(res[i])
                    }
                    let fullScentence = '';
                    scentence.forEach((f, i) => {
                      f = f.split('_')
                      fullScentence += ' ' + (f[1]) + ' ' + f[0] + (scentence.length == i + 1 ? '.' : ' ,')
                    });
                    this.LoaderService.hidePaymentloader();
                    Swal.fire({
                      title: this.es.kioskErrorMsgList[32].message,
                      text: this.es.kioskErrorMsgList[33].message + fullScentence + this.es.kioskErrorMsgList[34].message,
                      showCancelButton: false,
                      type: "warning",
                    });
                    this.router.navigate([
                      "/kiosk/add-food-packs/" +
                      this.kioskPOSService.getLocationId(),
                    ]);
                  } else {
                    this.salesOrderService
                      .PostSalesorderFormDataWithoutLoader(body)
                      .subscribe(
                        async (response) => {
                          this.kioskPOSService.setSalesOrderData(response);
                          if (this.paymentDetails.isCardPayment == 1) {
                            // this.KioskLayoutComponent.CollectPaymentMethod(response);
                            if (this.selectedMerchant == "Stripes") {
                              await this.KioskLayoutComponent.CollectPaymentMethod(
                                response
                              ).then(async (resCollectpayment) => {
                                if (resCollectpayment) {
                                  this.LoaderService.hidePaymentloader();
                                  this.kioskPOSService.pickupTicketData = response;
                                  this.toastrService
                                    .success(
                                      this.es.kioskErrorMsgList[43].message,
                                      "Success"
                                    )
                                    .onHidden.subscribe((re) => {
                                      this.router.navigate([
                                        "/kiosk/confirmTickets/" +
                                        this.kioskPOSService.getLocationId(),
                                      ]);
                                    });
                                } else {
                                  this.LoaderService.hidePaymentloader();
                                  Swal.fire(
                                    this.es.kioskErrorMsgList[38].message,
                                    this.es.kioskErrorMsgList[39].message,
                                    "error"
                                  );
                                  this.router.navigate([
                                    "/kiosk/checkout/" +
                                    this.kioskPOSService.getLocationId(),
                                  ]);
                                }
                              });
                            } else {
                              if (response.CreditCardinfo == "succeeded" || response.CreditCardinfo == "Approved") {
                                this.LoaderService.hidePaymentloader();
                                this.PaymentService.passPaymentIntentIdAuthorize(
                                  response.ConfirmationCode,
                                  response.PaymentID,
                                  "true",
                                  this.cc_last_four.toString()
                                ).toPromise();
                                this.kioskPOSService.pickupTicketData = response;
                                this.toastrService
                                  .success(
                                    this.es.kioskErrorMsgList[43].message,
                                    "Success"
                                  )
                                  .onHidden.subscribe((re) => {
                                    this.router.navigate([
                                      "/kiosk/confirmTickets/" +
                                      this.kioskPOSService.getLocationId(),
                                    ]);
                                  });
                                this.cc_card_number = null;
                                this.cc_last_four = null;
                                this.isBluredAllowed = true;
                              } else {
                                this.cc_card_number = null;
                                this.PaymentService.passPaymentIntentIdAuthorize(
                                  response.ConfirmationCode,
                                  "failPayment",
                                  "false",
                                  this.cc_last_four.toString()
                                ).toPromise();
                                this.LoaderService.hidePaymentloader();
                                this.cc_last_four = null;
                                Swal.fire(
                                  "",
                                  this.es.kioskErrorMsgList[4].message,
                                  "error"
                                );
                                // this.toastrService.error('Request Failed Payment Declined', 'error')
                                this.isBluredAllowed = true;
                                this.router.navigate([
                                  "/kiosk/checkout/" +
                                  this.kioskPOSService.getLocationId(),
                                ]);
                              }
                            }
                          } else {
                            this.kioskPOSService.pickupTicketData = response;
                            this.toastrService
                              .success(this.es.kioskErrorMsgList[43].message, "Success")
                              .onHidden.subscribe((re) => {
                                this.LoaderService.hidePaymentloader();
                                this.router.navigate([
                                  "/kiosk/confirmTickets/" +
                                  this.kioskPOSService.getLocationId(),
                                ]);
                                this.isBluredAllowed = true;
                              });
                          }
                          // this.router.navigate(['/kiosk/home/'+this.kioskPOSService.getLocationId()]);
                          // if (response.errors && response.errors !== null && response.errors !== undefined) {
                          //     this.isConfirmedForPayment = 0;
                          //     this.paymentDetails = new paymentDetails();
                          //     this.kioskPOSService.showSnackBar(response.title, 'close');
                          // }
                          // else {
                        },
                        (error) => {
                          console.log("play error", error);
                          this.LoaderService.hidePaymentloader();
                          this.cc_last_four = null;
                          this.cc_card_number = null;
                          Swal.fire(
                            this.es.kioskErrorMsgList[38].message,
                            this.es.kioskErrorMsgList[39].message,
                            "error"
                          );
                          // this.toastrService.error('Request Failed Payment Declined', 'error')
                          //this.toastrService.error('Error processing your card. Please try again.', 'error')
                          //this.toastrService.error('Server error', error)
                          this.isBluredAllowed = true;
                        }
                      );
                  }
                },
                (err) => {
                  this.LoaderService.hidePaymentloader();
                  console.log(err);
                }
              );
          },
          (error) => {
            console.log(error);
            this.LoaderService.hidePaymentloader();
            Swal.fire(this.es.kioskErrorMsgList[19].message, this.es.kioskErrorMsgList[31].message, "error");
          }
        );
    }
  }

  public backButton(e) {
    console.log("back", this.kioskPOSService.getIsFoodItemsAvailable());
    if (this.kioskPOSService.getIsFoodItemsAvailable() == "y") {
      this.router.navigate([
        "/kiosk/add-food-packs/" + this.kioskPOSService.getLocationId(),
      ]);
    } else if (this.kioskPOSService.getSeating() == "open") {
      this.router.navigate([
        "/kiosk/add-tickets/" + this.kioskPOSService.getLocationId(),
      ]);
    } else {
      this.router.navigate([
        "/kiosk/select-seats/" + this.kioskPOSService.getLocationId(),
      ]);
    }
  }

  public isCinemaMemberChange(e, isCinemaMember) {
    this.isConfirmedForPayment = 2;
    this.paymentDetails.isCinemaMember = isCinemaMember;
  }
  public slectcardpayment(e) {
    console.log("slectcardpayment");
    if (this.selectedMerchant == "Stripes") {
      this.paymentDetails.isCardPayment = 1;
      this.gotToPayment(e);
    } else {
      this.paymentDetails.isCardPayment = 1;
      this.isConfirmedForPayment = 2;

    }
    if (this.selectedMerchant == 'Worldpay') {
      this.sendSwipeIndication()
    }
  }
  sendSwipeIndication() {
    if (this.selectedMerchant == 'Worldpay') {
      let posData = JSON.parse(sessionStorage.getItem("posData"));
      let merchantInternalId = JSON.parse(sessionStorage.getItem("MerchantInternalId"));
      //this.merchantService.WorldpaySale(merchantInternalId, posData.LaneId, Number(this.finalPayment.toFixed(2)), 'Kiosk').subscribe(result => {
      //  if (result.statusCode == "Approved") {
      //    // this.transactionid = result.PaymentID;
      //    // this.cardTypeWorldpay = result.PaymentType;
      //    // this.cc_last_four = result.CCLastFour;
      //    this.transactionid = result.transactionId;
      //    this.cardTypeWorldpay = result.paymentType;
      //    this.cc_last_four = result.binValue + result.accountNumber.substring(result.accountNumber.length - 4);
      //    this.gotToPayment(null);
      //  } else if (result.statusCode.indexOf("insufficient") !== -1 || result.statusCode.indexOf("Merchant Credential") !== -1) {
      //    Swal.fire("Transaction failed", result.statusCode, "error");
      //    this.backToCheckOut(null)
      //    this.postFailedSlaesOrder(this.finalPayment.toFixed(2));
      //  } else {
      //    // Swal.fire("Error", result.statusCode, "error");
      //    Swal.fire(this.es.kioskErrorMsgList[40].message, this.es.kioskErrorMsgList[41].message, "error");
      //    this.backToCheckOut(null)
      //    this.postFailedSlaesOrder(this.finalPayment.toFixed(2));
      //  }
      //}, err => {
      //  Swal.fire(this.es.kioskErrorMsgList[40].message, this.es.kioskErrorMsgList[41].message, "error");
      //  this.backToCheckOut(null)
      //  this.postFailedSlaesOrder(this.finalPayment.toFixed(2));
      //});
    }
  }

  async postFailedSlaesOrder(Amount) {
    this.LoaderService.showPaymentloader();
    this.CommonService.GetDateTimeByTimeZone().subscribe(
      async (tz) => {
        let body = {
          Tickets: [],
          Concession: [],
          AdditionalCharges: [],
          TotalAmount: Amount,
          SalesChannel: "Kiosk",
          TotalTax: 0,
          CurrencyType: "usd",
          PaymentMode: PaymentMode.CC,
          LocationId: this.kioskPOSService.getLocationId(),
          LocationName: this.moviewScreenData.LocationName,
          DateTimeSold: moment(tz).format("YYYY-MM-DDThh:mm:ss.000[Z]"),
          BarcodeImage: "",
          GiftCardNumber: 0,
        };

        let Tickets = this.kioskPOSService.getTickets();
        let Seats = this.kioskPOSService.getSeats();
        let sk = 0;
        let totalnumberticketgc = 0;
        Tickets.Tickets.forEach((ticket, i) => {
          totalnumberticketgc = totalnumberticketgc + ticket.Qty;
          for (var jj = 1; jj <= ticket.Qty; jj++) {
            let o = {
              MovieID: this.kioskPOSService.getMovieId(),
              MovieName: this.moviewScreenData.MovieName,
              TicketType: ticket.DisplayName,
              ComboID: ticket.ComboID || "",
              TicketID: ticket.TicketId,
              Price: ticket.Price,
              ScreenId: this.kioskPOSService.getScreenId(),
              Tax: RoundAmount(TaxAmountCalculation(ticket.Tax, ticket.Price)),
              ScreenName: this.moviewScreenData.ScreenName,
              SeatNumber:
                this.kioskPOSService.getSeating() == "reserved"
                  ? Seats[sk]
                  : null,
              Status: SalesStatus.Failed,
              ShowId: this.kioskPOSService.getShowId(),
              Showtime: this.kioskPOSService.covertTimeToFormat(
                this.moviewScreenData.ShowTimeFinal,
                "hh:mm A"
              ),
              Showdate: this.kioskPOSService.covertTimeToFormat(
                this.moviewScreenData.ShowTimeFinal,
                "MM/DD/YYYY"
              ),
            };
            body.TotalTax = body.TotalTax + o.Tax;
            body.Tickets.push(o);
            sk++;
          }
        });
        let addCharges = this.kioskPOSService.getAdditionalCharges();
        if (addCharges?.length >= 0) {
          addCharges.forEach((addCharge, i) => {
            if (addCharge.SalesChannel.findIndex(x => x.SalesChannelName == "Kiosk") != -1) {
              let charges = {
                Index: addCharge.Index,
                Name: addCharge.Name,
                AdditionalPrice: addCharge.AdditionalPrice,
                Tax: ((addCharge.AdditionalPrice * addCharge.Tax) / 100),
                SalesChannel: addCharge.SalesChannel,
                NumberOfTicketSold: totalnumberticketgc
              };
              body.AdditionalCharges.push(charges);
            }
          });
        }
        let FoodItems = this.kioskPOSService.getFoodItems();
        FoodItems.forEach((food, i) => {
          if (food.PriceCardComboQty > 0) {
            let o1 = {
              ConcessionID: food.ConcessionId,
              ComboID: food.ComboID || "",
              ConcessionName: food.ConcessionName,
              Price: food.PriceCardComboUnitPrice,
              NumberSold: food.PriceCardComboQty,
              Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
              Status: SalesStatus.Failed,
            };
            body.TotalTax = body.TotalTax + o1.Tax;
            body.Concession.push(o1);
            if (food.Qty - food.PriceCardComboQty > 0) {
              let o2 = {
                ConcessionID: food.ConcessionId,
                ConcessionName: food.ConcessionName,
                Price: food.TotalPrice,
                NumberSold: food.Qty - food.PriceCardComboQty,
                Tax: RoundAmount(
                  TaxAmountCalculation(food.Tax, food.TotalPrice)
                ),
                Status:
                  body.PaymentMode == PaymentMode.GiftCard
                    ? SalesStatus.Reserved
                    : SalesStatus.OnHold,
              };
              body.TotalTax = body.TotalTax + o2.Tax;
              body.Concession.push(o2);
            }
          } else {
            let o = {
              ConcessionID: food.ConcessionId,
              ConcessionName: food.ConcessionName,
              Price: food.TotalPrice,
              NumberSold: food.Qty,
              Tax: RoundAmount(TaxAmountCalculation(food.Tax, food.TotalPrice)),
              Status:
                body.PaymentMode == PaymentMode.GiftCard
                  ? SalesStatus.Reserved
                  : SalesStatus.OnHold,
            };
            body.TotalTax = body.TotalTax + o.Tax;
            body.Concession.push(o);
          }
        });

        this.salesOrderService
          .PostSalesorderFormData(body)
          .subscribe(
            async (response) => {
              console.log(response)
              this.LoaderService.hidePaymentloader();
              this.posService.cancelSales(response).subscribe(async (result) => {
              })
            },
            (err) => {
              this.LoaderService.hidePaymentloader();
              Swal.fire(
                this.es.kioskErrorMsgList[35].message,
                this.es.kioskErrorMsgList[36].message,
                "error"
              );
              this.router.navigate([
                "/kiosk/checkout/" +
                this.kioskPOSService.getLocationId(),
              ]);
            }
          );
      });
  }
  public onBlurCardNumber(e) {
    if (
      this.isBluredAllowed &&
      this.cc_card_number &&
      this.cc_card_number.endsWith("?") &&
      this.cc_card_number.split("?").length >= 3
    ) {
      this.isBluredAllowed = false;
      this.cc_last_four = this.cc_card_number?.split(";")[1]?.split("=")[0];
      this.cc_last_four = this.cc_last_four?.substring(
        this.cc_last_four.length - 4
      );
      this.gotToPayment(e);
    } else {
      this.cc_card_number = "";
    }
  }
  public backToCheckOut(e) {
    if (this.paymentDetails.isCinemaMember == false) {
      if (this.paymentDetails.isCardPayment == 0) {
        this.isConfirmedForPayment = 0;
        this.paymentDetails = new paymentDetails();
      } else if (
        this.paymentDetails.isCardPayment == 1 ||
        this.paymentDetails.isCardPayment == 2
      ) {
        this.paymentDetails.isCardPayment = 0;
        this.paymentDetails = new paymentDetails();
      } else if (
        this.paymentDetails.isCardPayment == 3 ||
        this.paymentDetails.isCardPayment == 4 ||
        this.paymentDetails.isCardPayment == 6
      ) {
        this.paymentDetails.isCardPayment = 2;
        this.CardNumber = null;
        this.Amount = null;
        // this.paymentDetails = new paymentDetails();
      } else if (this.paymentDetails.isCardPayment == 5) {
        this.paymentDetails.isCardPayment = 4;
        this.loadKeypad("GCEA-keyboard");
      } else if (this.paymentDetails.isCardPayment == 8) {
        this.paymentDetails.isCardPayment = 2;
        this.CardNumber = null;
        this.Amount = null;
        // this.paymentDetails = new paymentDetails();
      } else if (this.paymentDetails.isCardPayment == 7) {
        this.paymentDetails.isCardPayment = 2;
        this.CardNumber = null;
        this.Amount = null;
        this.GC.GiftCardToCart(this.giftCard, "minus");
        // this.paymentDetails = new paymentDetails();
      } else {
        this.isConfirmedForPayment = 0;
        this.paymentDetails = new paymentDetails();
      }
    } else {
      this.isConfirmedForPayment = 0;
      this.paymentDetails = new paymentDetails();
    }
  }
}

export class paymentDetails {
  constructor(
    public isCinemaMember: boolean = false,
    public isCardPayment: number = 0,
    public memberId: string = "",
    public cardNo: string = "",
    public cardHolderName: string = "",
    public cardExpiry: string = "",
    public cardCVV: string = ""
  ) { }
}
