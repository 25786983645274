import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import { SalesStatus } from 'src/app/helper/models/SalesOrder';
import * as moment from 'moment';
import { PrintService } from 'src/app/helper/services/print.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-kiosk-pickup-print',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './kiosk-pickup-print.component.html',
  styleUrls: ['./kiosk-pickup-print.component.css']
})
export class KioskPickupPrintComponent implements OnInit, AfterViewInit {
  public subscribed: any = [];
  SalesStatus: SalesStatus;
  public moviewScreenData: any = {};
  public barcode_config: any = {};
  pickupTicketData: any;
  LocName: string;
  moment = moment;
  constructor(public router: Router, public kioskPOSService: KioskPOSService, private printserv: PrintService) {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pickupTicketData = this.kioskPOSService.pickupTicketData;
      this.barcode_config = {
        barcode: 'TEST',
        barcode_format_type: 'CODE128',
        elementType: 'img',
        lineColor: '#000000',
        width: 2,
        height: 150,
        displayValue: true,
        fontOptions: '',
        font: 'monospace',
        textAlign: 'center',
        textPosition: 'bottom',
        textMargin: 2,
        fontSize: 15,
        background: '#1E2A57',
        margin: 5,
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 5,
        marginRight: 5
      };
      this.barcode_config.barcode = this.pickupTicketData.ConfirmationCode;
    }, 500);
    setTimeout(() => {
      this.print();
    }, 1000);
  }

  ngOnInit() {
    if (this.kioskPOSService.pickupTicketData && this.kioskPOSService.pickupTicketData.length == 0) {
      this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
    }
    else {
      this.pickupTicketData = this.kioskPOSService.pickupTicketData;
     // this.print();
    }
  }
  public exitPrint(e) {
    this.kioskPOSService.pickupTicketData = [];
    this.kioskPOSService.unsetFinalData(['MovieId', 'PriceCardId', 'ScreenId', 'ShowId', 'Tax', 'Tickets', 'Seats', 'Seating', 'IsFoodItemsAvailable', 'FoodItems']);
    this.router.navigate(['/kiosk/home/' + this.kioskPOSService.getLocationId()]);
  }
  public print() {
    this.printserv.printDocument('ticket', [this.pickupTicketData.ConfirmationCode]).subscribe(res => {
      if (!res) {
        this.kioskPOSService.changeTicketStatus(this.kioskPOSService.pickupTicketData.ConfirmationCode, "all", SalesStatus.Printed).subscribe(f=>{
          this.exitPrint(null);
         },err=>{
           Swal.fire('Failed',err,'error').then(r=>{
             this.exitPrint(null);
           });
         });
      }
    });
  }
}
