import { LocationBase } from "../helper/models/LocationBase";
import { PaymentMode, SalesSplit } from "../helper/models/SalesOrder";
import { GiftCardStatus } from "./giftcard";

export class GiftCardSales extends LocationBase {
  TransactionId: string;
  GiftCardId: string;
  ShiftId: string;
  CardNumber: string = "";
  Amount: number = 0;
  PaymentType: PaymentMode;
  LastDigits: string;
  CompanyId: string;
  CreatedBy: string;
  UpdatedBy: string;
  Visible: number = 0;
  CreditCardinfo: string;
  Recharged: boolean;
  SalesChannel: string;
  DateSold: Date;
  Expiry: Date;
  MerchantName: string;
  SalesSplit: SalesSplit[];
  PaymentID:string=null;
  MerchantCredentialId:any;
  LaneId:any;
  CCLastFour:any;
  //ui ref
  gcActionType:any;
  Status: GiftCardStatus;

}

export class GiftArray {
  splitGiftcardNumber: number = null;
  splitGiftcardPaidAmount: number = 0;
  splitpay_gcvalid: boolean = false;
  splitpay_gcdetail: any;
}
