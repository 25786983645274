import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocationsService } from '../locations/locations.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})

export class PrivacypolicyComponent implements OnInit {
  pptemp: string = "";

  constructor(private activatedRoute: ActivatedRoute, private locationService: LocationsService, private titleService: Title) {
    this.locationService.IshomePage = false;
  }

  ngOnInit(): void {
    this.locationService.getTermsConditions().subscribe((tccpp) => {
      if (tccpp) {
        this.pptemp = tccpp.PrivacyPolicy;
      }
    });
  }
}
