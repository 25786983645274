import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService extends ApiService {
  ApiBaseUrl: string;
  httpBackedurl:HttpClient;

  constructor(private http: HttpClient, @Inject('BASE_URL') url: string,backend : HttpBackend) {
    super(url);
    this.ApiBaseUrl = this.ApiEndPoint + 'Common';
    
    this.httpBackedurl = new HttpClient(backend);
  }

  GetDateTimeByTimeZone(): Observable<Date> {
    var timezoneSession = JSON.parse(sessionStorage.getItem("location"))
    var timezone = timezoneSession ?  timezoneSession.Timezone : "";
		return this.httpBackedurl.get<Date>(this.ApiBaseUrl+'/GetDateTimeByTimeZone/'+timezone).pipe(catchError(this.errorHandler));
  }
  
  GetTimeZonesByCountry(CountryCode:string): Observable<Array<Object>>{
    return this.http.get<Array<Object>>(this.ApiBaseUrl+'/GetTimeZonesByCountry/'+CountryCode.toUpperCase()).pipe(catchError(this.errorHandler));
  }

  GetQr(Id: string) {
    return this.http.get<any>(this.ApiEndPoint + 'Qr/' + Id).pipe(catchError(this.errorHandler));
  }

  GetOnlineQrCode(Id: string,companyId:string) {
   return this.http.get<any>(this.ApiEndPoint + 'Qr/GetOnlineQrCode/' + Id + "/" + companyId).pipe(catchError(this.errorHandler));
  }

 /* GetOnlineQrCodeByScreen(screenid: string): Observable<any> {
    return this.http.get<any>(this.ApiBaseUrl + "/Screen/GetOnlineQrCodeByScreen/" + screenid).pipe(catchError(this.errorHandler));
  }*/

  getInstrumentationKey() {
    return this.http
      .get(this.ApiBaseUrl + "/GetInstrumentationKey")
      .pipe(catchError(this.errorHandler));
  }
}
