import { Component, OnInit, ViewEncapsulation, ViewChildren, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { KioskPOSService } from '../services/kiosk-pos.service';
import Keyboard from "simple-keyboard";
import { CommonSettings } from 'src/app/helper/commonSettings';
import {SalesStatus} from 'src/app/helper/models/SalesOrder';
import { ErrorMessagesService } from 'src/app/helper/services/error-messages.service';

@Component({
  selector: 'app-kiosk-pickup-pnumber',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './kiosk-pickup-pnumber.component.html',
  styleUrls: ['./kiosk-pickup-pnumber.component.css','../../../../node_modules/simple-keyboard/build/css/index.css']
})
export class KioskPickupPnumberComponent implements OnInit {
  error: string = null;
  PNumber: string;
  SalesOrder: any = [];
  count: number = 0;
  keyboard: Keyboard;
  @ViewChildren('kioskpninput') kioskpninput: any;

  constructor(public kioskPOSService: KioskPOSService, public router: Router,private es:ErrorMessagesService) {
   }

  ngOnInit() {
    this.kioskPOSService.pickupTicketData = [];
  }


  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      layout: {
        default: CommonSettings.keybSetting.numpadDef,
      },
      theme: CommonSettings.keybSetting.theme
    });
    const input: HTMLInputElement = this.kioskpninput.first.nativeElement as HTMLInputElement;
    input.focus();
  }


  onChange = (input: any) => {
    this.PNumber = input;
  };
  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  Verify() {
    this.kioskPOSService.getSalesOrder(this.PNumber).subscribe(res => {
      this.SalesOrder = res;
      if (this.SalesOrder.ConfirmationCode != null) {
        for (const ticket of this.SalesOrder.Tickets) {
          if (ticket.Status != SalesStatus.Printed) {
            this.count++;
          }
        }
        if (this.count !== 0) {
          this.kioskPOSService.pickupTicketData = this.SalesOrder;
          this.router.navigate(['/kiosk/pickup/print/' + this.kioskPOSService.getLocationId()])
        }
        else {
          this.error = this.es.kioskErrorMsgList[6].message
          this.kioskPOSService.pickupTicketData = [];
        }
       }
      else {
        this.error = this.es.kioskErrorMsgList[70].message;
        this.kioskPOSService.pickupTicketData = [];
      }
      });

  }
}
