export enum kitchenStatus {
  OrderReceived,
  Cooking,
  ReadyToServe,
  Served,
  Paid,
  Cancelled
}

export enum PaymentStatus {
  AuthorizePayment,
  FinalizePayment,
  Paid
}
