import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscapeHtmlPipe } from './helper/pipes/html-content.pipe';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { AppBootstrapModule } from './app-bootstrap.module';
import { MaterialModule } from './material.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { OrderByPipe } from './helper/pipes/orderBy';
import { PaymentoptionComponent } from './paymentoption/paymentoption.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ParenthesisPipe } from './helper/pipes/parenthesis';
import {VarienceFormatPipe} from './helper/pipes/varience-format';
@NgModule({
    imports: [
        CommonModule,
        UiSwitchModule.forRoot({
            size: 'small',
            switchColor: '#1dc9b7',
            color: '#e8ebf1',
            defaultBgColor: '#e8ebf1',
            defaultBoColor: '#e8ebf1'
        }),
        AppBootstrapModule,
        MaterialModule,
        DataTablesModule,
        NgxTrimDirectiveModule,
      AccordionModule.forRoot(),
        SweetAlert2Module.forRoot({
            provideSwal: Swal.mixin({
                customClass: {
                    confirmButton: 'success-btn',
                    cancelButton: 'remove-btn col-sm-offset-2',
                },
                buttonsStyling: false
            })
        }),
    ],
    declarations: [
        EscapeHtmlPipe,
        OrderByPipe,
      PaymentoptionComponent,
      ParenthesisPipe,
      VarienceFormatPipe
    ],
    exports: [
        CommonModule,
        FormsModule,
        UiSwitchModule,
        AppBootstrapModule,
        MaterialModule,
        DataTablesModule,
        SweetAlert2Module,
        PaymentoptionComponent,
        EscapeHtmlPipe,
        AccordionModule,
      OrderByPipe,
      ParenthesisPipe,
      VarienceFormatPipe
    ]
})
export class SharedModule { }
