import { ClientCompany, CompanyType, RestaurantOptions, CinemaOptions } from './client-company.model';
import { ClientCompanyService } from './client-company.service';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AddHelperMethods, ImageType, RoundAmount, CloneObject } from './../helper/models/AddHelperModal';
import { NgForm } from '@angular/forms';
import { FileUploadService } from '../helper/services/file-upload.service';
import Swal from 'sweetalert2';
import { ErrorMessagesService } from '../helper/services/error-messages.service';

@Component({
  selector: 'app-client-company',
  templateUrl: './client-company.component.html',
  styleUrls: ['./client-company.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ClientCompanyComponent extends AddHelperMethods implements OnInit {

  constructor(private clientCompanyService: ClientCompanyService, private fileUploadServ: FileUploadService,private es:ErrorMessagesService) { super(); }

  @ViewChild('RegistrationForm', { static: true }) RegistrationForm: NgForm;
  clientCompany: ClientCompany = new ClientCompany();
  restaurantOption: RestaurantOptions = new RestaurantOptions();
  cinemaOption: CinemaOptions = new CinemaOptions();
  types = ClientCompany.getCompanyTypeName();
  showRestaurantOptions: boolean = false;
  clientCompanyMedia: ImageType = {
    url: "",
    file: null,
    progress: 0
  };
  error: string = '';

  ngOnInit() {
    console.log(this.clientCompany);
    console.log(this.restaurantOption);
  }

  setCompanyType() {
    if (this.clientCompany.CompanyTypeName == "restaurant") {
      this.showRestaurantOptions = true;
    }
  }

  onSubmit() {
    if (this.RegistrationForm.form.valid) {
      this.error = '';
    }

    if (this.RegistrationForm.form.valid && this.error === '') {
      this.registerCompany();
    }
  }

  async registerCompany() {
    this.clientCompany.CompanyType.Restaurant = this.restaurantOption;
    await this.uploadImage(this.clientCompanyMedia, "", this.fileUploadServ, this, 'cloudflare');
    if (this.error === "") {
      this.clientCompany.LogoImage = this.clientCompanyMedia.url;
      this.clientCompanyService.registerCompany(this.clientCompany).subscribe(
        newConcession => {
          if (newConcession.CompanyId) {
            Swal.fire("Success", this.es.adminErrorMsgList[66].message)
          }
        },
        error => {
          this.error = error;
          console.log(this.error);
          Swal.fire("OOPs..!", this.es.adminErrorMsgList[67].message)
        }
      );
    }
  }
}
