import { LocationBase } from "../helper/models/LocationBase";
import { StorageRoom } from "./storageRoom";

export class Inventory extends LocationBase{
    InternalId: string;
    InventoryId: string;
    LocationId: string = '';
    SKUNumber: string = '';
    Title: string = '';
    Supplier: string = '';
    Brand: string = '';
    Description: string = '';
    TotalQuantityInHand: number;
    TotalQuantityReceived:number;
    TotalSold: number;
    TotalDamaged: number;
    PurchasePriceUnit: number;
    PurchasePriceCase: number;
    TotalItemsInCase: number;
    Sellable: number;
    StorageRoomNumber: string = '';
    ItemImage: string = '';
    ExpireDate: string | Date = '';
    BarcodeID: string = '';
    UOM: string = '';
    LowInventoryCount:number = 0;
    PreferredStockLevel:number=0;
  StorageRoom: StorageRoom;
  StorageRoomId: string;

  // for UI Only
  ActualQuantityInHand: number = 0;
  Variance: number = 0;
  DamageQty:number=0;
  DNote:string='';
  StolenQty:number=0;
  SNote:string='';
  TransferQty:number=0;
  TNote:string='';
  TransferlocationName:string='';
  IsbuttonEnable:boolean=false;
  newUOM:string;
  newUOMActualQty: string;
  newUOMTransferQty: string;
  newUOMStolenQty: string;
  newUOMDamageQty: string;
  FranchiseId: string;
}



export class InvetorySnapshot 
{
    InvetorySnapshotID:string
    LocationID:string
    DateTime:Date 
    Inventory: Array<InventorySnapsShotDetail> =new Array<InventorySnapsShotDetail>();
}
export class InventorySnapsShotDetail
{
    InventoryID:string;
    Name:string;
    StartCount:number;
    SystemSold:number;
    Wasted :number;
    UnaccountedFor:number;
    Variance:number;
    CurrentCount:number;
    Transfer :number;
    Sellable:boolean=false
}

export class InventoryOrderReceivable extends LocationBase
{
    InventoryOrderId:string;
    InventoryId:string;
    InventoryName:string;
    Supplier:string;
  InvoicNumber: string;
  StorageRoomId: string;
    ReceivedDate:Date;
    Notes:string;
    OrderQuantity:number;
    ActualQuantityRecieved:number;
    TotalQuantityInHand:number;
    PreferredStockLevel :number;
    PurchasePriceUnit:number;
    TotalCost:number;
    UOM:string;
    OrderStatus:OrderStatus
}
export enum OrderStatus{
    Pending,
    Complete
}
