import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    HttpClient,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})

export class OverrideAccessService extends ApiService {
    ApiBaseUrl: string;
    BaseUri: string;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        super(baseUrl);
        this.ApiBaseUrl = baseUrl + 'api/overrideaccess';
        this.BaseUri = baseUrl;
    }

    getOverridePin(): Observable<string> {
        return this.http
            .get<string>(this.ApiBaseUrl + '/' + 'GerOverridePin')
            .pipe(catchError(this.errorHandler));
    }
}
