import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../helper/services/api.service';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { CompanyFeatureAndOffer } from './addfeature/addfeatures.component';

@Injectable({
  providedIn: 'root'
})
export class FeaturesAndOfferService extends ApiService {

  constructor(private http: HttpClient, @Inject('BASE_URL') base_url: string) {
    super(base_url);

    this.ApiEndPoint += "Company";
  }

  getAllCompanyFeatureAndOffer(): Observable<Array<CompanyFeatureAndOffer>> {
    return this.http.get<Array<CompanyFeatureAndOffer>>(this.ApiEndPoint +"/GetAllCompanyFeatureAndOffer")
      .pipe(catchError(this.errorHandler));
  }
  
  addFeatureAndOffer(CFO: CompanyFeatureAndOffer): Observable<any> {
    return this.http.post(this.ApiEndPoint +"/PostCompanyFeatureAndOffer", CFO)
      .pipe(catchError(this.errorHandler));
  }

  //editTax(TT: Tax): Observable<any> {
  //  return this.http.put(this.ApiEndPoint + "/" + TT.TaxId, TT)
  //    .pipe(catchError(this.errorHandler));
  //}

  //setVisible(ID: number, visible: number): Observable<any> {
  //  return this.http
  //    .patch(this.ApiEndPoint + '/' + ID + '/' + visible, null)
  //    .pipe(catchError(this.errorHandler));
  //}
}
