import { ClientCompanyComponent } from './client-company/client-company.component';

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler, Injector } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BreadcrumbModule } from "angular-crumbs";
import { AppComponent } from "./app.component";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { HomeComponent } from "./home/home.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmptyLayoutComponent } from './empty-layout/empty-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { NgxBarcodeModule } from 'ngx-barcode';
import { ToastrModule } from 'ngx-toastr';
import { CommonSettings } from "./helper/commonSettings";

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask'
import { LoaderService } from "./helper/services/loader/loader.service";
import { LoaderInterceptor } from "./helper/interceptors/loader.interceptors";
import { LoaderComponent } from "./helper/components/loader/loader.component";
 //import { PosLayoutComponent } from './POS/pos-layout/pos-layout.component';
import { PosLayoutComponent } from './pos/pos-layout/pos-layout.component';
import { NgxPopperModule } from 'ngx-popper';
import { EmployeeScheduleService } from "./employee-schedule/employee-schedule.service";
import { PrintService } from "./helper/services/print.service";
import { PrintComponent } from './print/print.component';
import { AuthGuard } from "./auth-guard.service";
import { NetworkStatusAngularModule } from 'network-status-angular';
import { DatePipe } from "@angular/common";
import { NavMenuErrorComponent } from './error-page/nav-menu-error/nav-menu-error.component';
import { SharedModule } from "./shared.module";
import { KioskPOSServiceFactory } from "./kiosk-pos/kiosk.module";
import { KioskPOSService } from "./kiosk-pos/services/kiosk-pos.service";
import { KioskLayoutComponent } from "./kiosk-pos/kiosk-layout/kiosk-layout.component";
import { DineinHomeComponent } from './DineIn/dinein-home/dinein-home.component';
import { DineinFoodComponent } from './DineIn/dinein-food/dinein-food.component';
import { DinePipePipe } from './DineIn/dine-pipe.pipe';
import { DineinModule } from './DineIn/dinein.module';
// import { DineincloseshiftComponent } from './DineIn/dineincloseshift/dineincloseshift.component';
// import { DineinshiftlistComponent } from './DineIn/dineinshiftlist/dineinshiftlist.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { CompanyComponent } from './company/company.component';
import { POSModule } from './pos/pos.module';
import { ApplicationInsightsService } from './helper/services/applicationInsights.service';
import { ErrorHandlerService } from './helper/services/errorhandlerservice.service';
import { DigitalDisplayLinkComponent } from './digital-display-link/digital-display-link.component';
import { BannersdisplayComponent } from './bannersdisplay/bannersdisplay.component';
import { EditCompanyComponent } from './company/edit-company/edit-company.component';
import { WikiComponent } from './wiki/wiki.component';
// import { OnlinebannerlistComponent } from './onlinebanners/onlinebannerlist/onlinebannerlist.component';
// import { AddonlinebannerComponent } from './onlinebanners/addonlinebanner/addonlinebanner.component';
import { SuccesspaymentComponent } from './successpayment/successpayment.component';
// import { PaymentoptionComponent } from './paymentoption/paymentoption.component';
import { CompanydashboardComponent } from './companydashboard/companydashboard.component'
import { NoCommaPipe } from './companydashboard/NoCommaPipe.pipe';
//import { CacheInterceptor } from './helper/interceptors/cache.interceptor';
//import { HttpCacheService } from './helper/services/cache/http-cache.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { ContactComponent } from './contact/contact.component';
import { TcppComponent } from './tcpp/tcpp.component';
import { NgApexchartsModule } from "ng-apexcharts";

import { ContactService } from './contact/contact.service';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { CompanydashboardpreviewComponent } from './companydashboardpreview/companydashboardpreview.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';



//import { PaginationComponent } from './pagination/pagination.component';
//import { MatMomentDateModule } from "@angular/material-moment-adapter";
// import { AdminrefundComponent } from './adminrefund/adminrefund.component'
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    KioskLayoutComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    EmptyLayoutComponent,
    AdminLayoutComponent,
    LoaderComponent,
    PrintComponent,
    PosLayoutComponent,
    NavMenuErrorComponent,
    DineinHomeComponent,
    DineinFoodComponent,
    DinePipePipe,
    ClientCompanyComponent,
    CompanyComponent,
    BannersdisplayComponent,
    EditCompanyComponent,
    TcppComponent,
    CompanydashboardComponent,
    // WikiComponent,
    SuccesspaymentComponent,
    // EditonlinebannerComponent,
    NoCommaPipe,
    ContactComponent,
    AboutusComponent,
    PrivacypolicyComponent,
    TermsconditionsComponent,
    CompanydashboardpreviewComponent
   // POSModule
    
    // PaginationComponent,
    // AdminrefundComponent,
    // OnlinebannerlistComponent,
    // AddonlinebannerComponent
    //DigitalDisplayLinkComponent,
    // PaymentoptionComponent,
    // DineincloseshiftComponent,
    // DineinshiftlistComponent
  ],
  imports: [
    NetworkStatusAngularModule,

    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    SharedModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    OverlayModule,
    Ng2SearchPipeModule,
    NgxBarcodeModule,
    NgxPaginationModule,
    DataTablesModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      // disableTimeOut: true,
      timeOut: 2000,
      progressBar: false,
      progressAnimation: 'decreasing',
      closeButton: true,
      positionClass: 'toast-top-right',
    }),
    NgxPopperModule.forRoot({ placement: 'bottom' }),
    DineinModule,

    NgIdleKeepaliveModule.forRoot(),
    MomentModule, NgMultiSelectDropDownModule.forRoot(),
    NgApexchartsModule

  ],
  exports: [PosLayoutComponent],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    /* HttpCacheService, { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },*/
    CommonSettings,
    KioskPOSService,
    AuthGuard,
    PrintService,
    {
      provide: APP_INITIALIZER,
      useFactory: KioskPOSServiceFactory,
      deps: [KioskPOSService],
      multi: true
    },
    EmployeeScheduleService,
    DatePipe,
    ApplicationInsightsService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
