import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable, ObservableInput } from "rxjs";
import { DineinService } from './dinein.service';
import { catchError } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class DineStatsService {
    getKitchenOrders: BehaviorSubject<any>
    constructor(private dineService: DineinService) {
        this.getKitchenOrders = new BehaviorSubject([]);
    }

    getKOrders(startDate, endDate) {
        return this.dineService.getKitchenOrders(startDate, endDate).subscribe((data) => {
            this.getKitchenOrders.next(data);
        });
    }
}