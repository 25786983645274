import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../helper/services/api.service';
import { HttpClient } from '@angular/common/http';
import { PriceCard } from './price-card';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceCardService extends ApiService {
  constructor(private http: HttpClient, @Inject("BASE_URL") base: string) {
    super(base);
    this.ApiEndPoint = base + "api/PriceCard";
  }

  getPriceCards(): Observable<Array<PriceCard>> {
    return this.http.get<Array<PriceCard>>(this.ApiEndPoint)
      .pipe(catchError(this.errorHandler));
  }

  getPriceCardsByLocationID(): Observable<Array<PriceCard>> {
    return this.http.get<Array<PriceCard>>(this.ApiEndPoint + "/GetByLocationId")
      .pipe(catchError(this.errorHandler));
  }

  getPriceCardById(ID: string): Observable<PriceCard> {
    return this.http.get<PriceCard>(this.ApiEndPoint + "/" + ID)
      .pipe(catchError(this.errorHandler));
  }

  getKioskPriceCards(ID: string): Observable<PriceCard> {
    return this.http.get<PriceCard>(this.ApiEndPoint + "/GetKioskPriceCards/" + ID)
      .pipe(catchError(this.errorHandler));
  }

  addPriceCard(PC: PriceCard): Observable<any> {
    return this.http.post(this.ApiEndPoint, PC)
      .pipe(catchError(this.errorHandler));
  }

  editPriceCard(PC: PriceCard): Observable<any> {
    return this.http.put(this.ApiEndPoint + "/" + PC.PriceCardId, PC)
      .pipe(catchError(this.errorHandler));
  }
  checkPriceCardAssignedMovieSchedule(Id: string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiEndPoint + "/CheckPriceCardAssignedMovieSchedule/" + Id)
      .pipe(catchError(this.errorHandler));
  }

  setVisible(ID: string, visible: number): Observable<any> {
    return this.http
      .patch(this.ApiEndPoint + '/' + ID + '/' + visible, null)
      .pipe(catchError(this.errorHandler));
  }
  checkPriceCardConcessionStatus(pricecardId: string): Observable<boolean> {
    return this.http.get<boolean>(this.ApiEndPoint + '/CheckPriceCardConcessionStatus/' + pricecardId).pipe(catchError(this.errorHandler));
  }
}
